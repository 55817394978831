import React, { forwardRef } from 'react'
import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import moment from 'moment/min/moment-with-locales'
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import EventIcon from '@mui/icons-material/Event'
import { Prompt } from 'react-router'

const centerAlign = {
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
}

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
   <IconButton onClick={onClick} ref={ref} size="large">
      <EventIcon />
   </IconButton>
))
const TimesheetHeader = ({
   selectedView,
   setSelectedView,
   chosenDate,
   chosenDateWeek,
   handleSave,
   changeDate,
   isUnsavedChanges,
   t,
}) => {
   const isToday = date => moment(date).isSame(new Date(Date.now()), 'day')
   moment.locale(t('momentLocale'))
   const handleWeekForward = () => {
      let date = new Date(chosenDate)
      date.setDate(date.getDate() + 7)
      changeDate(date)
   }
   const isCurrentWeek = () => {
      const today = new Date().setHours(0, 0, 0, 0)
      const weekStart = new Date(chosenDateWeek.weekDates[0]).setHours(0, 0, 0, 0)
      const weekEnd = new Date(
         chosenDateWeek.weekDates[chosenDateWeek.weekDates.length - 1]
      ).setHours(0, 0, 0, 0)

      if (weekStart <= today && today <= weekEnd) {
         return true
      } else {
         return false
      }
   }
   const handleWeekBackward = () => {
      let date = new Date(chosenDate)
      date.setDate(date.getDate() - 7)
      changeDate(date)
   }
   const setThisWeek = () => {
      changeDate(new Date(Date.now()))
   }

   const thisWeekButtonDisabled = () => {
      return moment(chosenDate).isSame(new Date(Date.now()), 'day')
   }

   const returnWeekLabel = () => {
      const chosenWeekNumber = chosenDateWeek.weekNumber
      const firstDateOfTheChosenWeek = moment(chosenDateWeek.weekDates[0]).format('Do MMM')
      const lastDateOfTheChosenWeek = moment(
         chosenDateWeek.weekDates[chosenDateWeek.weekDates.length - 1]
      ).format('Do MMM')
      const year = moment(chosenDateWeek.weekDates[0]).year()
      // const weekday =
      //    moment(chosenDate).format('dddd').toUpperCase()[0] +
      //    moment(chosenDate).format('dddd').slice(1)
      // const day = moment(chosenDate).format('Do')
      // const month =
      //    moment(chosenDate).format('MMMM').toUpperCase()[0] +
      //    moment(chosenDate).format('MMMM').slice(1)
      return (
         <Box>
            <Typography
               style={{ display: 'block', fontWeight: 700, color: '#454545', textAlign: 'center' }}
            >
               {t('Week')} {chosenWeekNumber}
            </Typography>
            {/* {selectedView === 0 && (
               <Typography style={{ display: 'inline', fontWeight: 700 }}>
                  {weekday}{' '}
                  <Typography style={{ display: 'inline', fontWeight: 700, color: '#7D7D7D' }}>
                     {day} {month}
                  </Typography>
               </Typography>
            )} */}
            {/* {selectedView === 1 && ( */}
            <Typography style={{ display: 'inline', fontWeight: 700 }}>
               {`${firstDateOfTheChosenWeek} - ${lastDateOfTheChosenWeek} ${year}`}
            </Typography>
            {/* )} */}
         </Box>
      )
   }

   return (
      <div
         style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
         }}
      >
         <div style={{ display: 'flex', alignItems: 'center' }}>
            <Box>
               <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={setThisWeek}
                  disabled={thisWeekButtonDisabled()}
                  style={{ marginLeft: '20px' }}
               >
                  {t('Today')}
               </Button>
            </Box>
            <Box>
               <DatePicker
                  popperPlacement="bottom-start"
                  className="datepickerMini"
                  selected={chosenDate}
                  onChange={changeDate}
                  dateFormat="d/M"
                  locale={t('datepickerLocale')}
                  showWeekNumbers
                  yearDropdownItemNumber={3}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  customInput={<ExampleCustomInput />}
               />
            </Box>
         </div>
         <div
            style={{
               ...centerAlign,
               position: 'absolute',
               left: '50%',
               transform: 'translateX(-50%)',
            }}
         >
            <FontAwesomeIcon
               color="#009be5"
               size={'lg'}
               style={{ cursor: 'pointer', marginRight: '16px' /*, marginBottom: '24px'*/ }}
               icon={faArrowCircleLeft}
               onClick={handleWeekBackward}
            />

            {returnWeekLabel()}

            <FontAwesomeIcon
               color={
                  (selectedView === 0 && isToday(chosenDate)) ||
                  (selectedView === 1 && isCurrentWeek())
                     ? 'gray'
                     : '#009be5'
               }
               size={'lg'}
               cursor={
                  (selectedView === 0 && isToday(chosenDate)) ||
                  (selectedView === 1 && isCurrentWeek())
                     ? 'default'
                     : 'pointer'
               }
               style={{ marginLeft: '16px' /*, marginBottom: '24px'*/ }}
               icon={faArrowCircleRight}
               onClick={
                  (selectedView === 0 && isToday(chosenDate)) ||
                  (selectedView === 1 && isCurrentWeek())
                     ? null
                     : handleWeekForward
               }
            />
         </div>
         <div style={{ display: 'flex', alignItems: 'center', ...centerAlign }}>
            <Box style={{ marginTop: '5px' }}>
               <div class="tsheet-heder-view-switch-btn-container">
                  <label class="switch tsheet-btn-view-mode-switch">
                     <input
                        type="checkbox"
                        name="view_mode"
                        id="view_mode"
                        checked={selectedView === 1}
                        onChange={e => setSelectedView(e.target.checked ? 1 : 0)}
                     />
                     <label
                        for="view_mode"
                        data-on={t('tsheet-view-switch-1')}
                        data-off={t('tsheet-view-switch-0')}
                        class="tsheet-btn-view-mode-switch-inner"
                     />
                  </label>
               </div>
            </Box>
            <Box>
               <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={!isUnsavedChanges}
                  style={{ marginRight: '20px' }}
               >
                  {t('Save')}
               </Button>
            </Box>
         </div>
         <Prompt when={isUnsavedChanges} message={t('leavePagePrompt')} />
      </div>
   )
}
export default withTranslation()(TimesheetHeader)
