import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
// import Link from '@mui/material/Link'
import withStyles from '@mui/styles/withStyles'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PropTypes from 'prop-types'
import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Badge from '@mui/material/Badge'
import Alert from '@mui/material/Alert'
import { Snackbar } from '@mui/material'
import { Link } from 'react-router-dom'
import WarningIcon from '@mui/icons-material/Warning'
import {
   authenticate,
   logout,
   fetchNotifs,
   setError,
   persistUserInfo,
} from './redux/actions/actions'
import { connect } from 'react-redux'
import log from './util/log'
import { withTranslation } from 'react-i18next'
import { updateUserLanguage } from './Api'
import moment from 'moment-timezone'
import { fetchCustomerData } from './redux/actions/actions'
import firebase from 'firebase/app'
import 'firebase/auth'
import { FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN } from './util/dependencies'

const lightColor = 'rgba(255, 255, 255, 0.7)'

const styles = theme => ({
   secondaryBar: {
      zIndex: 0,
   },
   menuButton: {
      marginLeft: -theme.spacing(1),
   },
   iconButtonAvatar: {
      padding: 4,
   },
   link: {
      textDecoration: 'none',
      color: lightColor,
      '&:hover': {
         color: theme.palette.common.white,
      },
   },
   button: {
      borderColor: lightColor,
   },
})

const StyledBadge = withStyles(theme => ({
   badge: {
      right: 10,
      top: 13,
      padding: '0 4px',
   },
}))(Badge)

const getNotifLink = notif => {
   switch (notif.type) {
      case 'MY_ABSENCE_REQUEST':
         return '/requestabsence'
      case 'ABSENCE_REQUEST':
         return '/absencerequests'
      case 'ACCEPT_REPORT':
         return '/mypage'
      default:
         return ''
   }
}

const getNotifColor = notif => {
   switch (notif.type) {
      case 'MY_ABSENCE':
         return '#ffcece'
      default:
         return '#FFF'
   }
}

const getNotifNumber = (notif, myAbsenceRequests, absenceRequests) => {
   switch (notif.type) {
      case 'MY_ABSENCE_REQUEST':
         return myAbsenceRequests > 1 ? ' (' + myAbsenceRequests + ')' : ''
      case 'ABSENCE_REQUEST':
         return absenceRequests > 1 ? ' (' + absenceRequests + ')' : ''
      default:
         return ''
   }
}

const notifsTooltipText = (json, t) => {
   if (json == null || json.length < 1) {
      return ''
   }

   let myAbsenceRequests = 0
   let absenceRequests = 0

   // Calculate numbers
   let notifs = []
   json.forEach(notif => {
      switch (notif.type) {
         case 'MY_ABSENCE_REQUEST':
            if (myAbsenceRequests < 1) {
               notifs.push(notif)
            }
            myAbsenceRequests++
            break
         case 'ABSENCE_REQUEST':
            if (absenceRequests < 1) {
               notifs.push(notif)
            }
            absenceRequests++
            break
         default:
            notifs.push(notif)
      }
   })

   return (
      <React.Fragment>
         {notifs.map((notif, index) => {
            let marginTop = 5
            if (index > 0) {
               marginTop = 10
            }
            return (
               <React.Fragment key={'notif_' + index}>
                  <Link style={{ color: getNotifColor(notif) }} to={getNotifLink(notif)}>
                     <Typography variant="body2" style={{ margin: 5, marginTop: marginTop }}>
                        {t(notif.type)}
                        {getNotifNumber(notif, myAbsenceRequests, absenceRequests)}
                     </Typography>
                  </Link>
               </React.Fragment>
            )
         })}
      </React.Fragment>
   )
}

const NotificationItem = ({ ...props }) => {
   return (
      <Grid item>
         <Tooltip
            style={{ whiteSpace: 'pre-line' }}
            arrow={true}
            title={notifsTooltipText(props.notifs, props.t)}
         >
            <StyledBadge
               badgeContent={props.notifs != null ? props.notifs.length : 0}
               color="error"
               overlap="rectangular"
            >
               <IconButton color="inherit" size="large">
                  <NotificationsIcon />
               </IconButton>
            </StyledBadge>
         </Tooltip>
      </Grid>
   )
}

function Header({ title, ...props }) {
   const { classes, fetchNotifs, t, userInfo, fetchCustomerData } = props
   const [anchorEl, setAnchorEl] = React.useState(null)
   const [anchorLang, setAnchorLang] = React.useState(null)
   const [showSnackbar, setShowSnackbar] = React.useState(false)
   const [snackbarText, setSnackbarText] = React.useState('')
   const [snackbarSeverity, setSnackbarSeverity] = React.useState('info')

   moment.tz.setDefault(props.customerTimezone)

   React.useEffect(() => {
      fetchNotifs(userInfo)
      fetchCustomerData(userInfo)

      if (userInfo.loginProvider === 'gip') {
         try {
            const firebaseConfig = {
               apiKey: FIREBASE_API_KEY,
               authDomain: FIREBASE_AUTH_DOMAIN,
            }
            try {
               firebase.initializeApp(firebaseConfig)
            } catch (error) {
               log.info(
                  'Failed to initalize firebase app in header, probably already initialized at login page. ' +
                     error
               )
            }

            var tenantId = userInfo.tenantId

            console.log('TENANT ID: ' + tenantId)

            firebase.auth().tenantId = tenantId
         } catch (error) {
            console.log('Error initializing firebase: ' + error)
         }
      }
   }, [userInfo, fetchNotifs, fetchCustomerData])

   const performLogout = () => {
      //remove selected filter on logout
      if (localStorage.getItem('hrAppliedFilters')) {
         localStorage.removeItem('hrAppliedFilters')
      }
      // IF we are signed in with firebase, we need to logout.
      if (userInfo.loginProvider === 'gip') {
         firebase
            .auth()
            .signOut()
            .then(() => {
               console.log('Firebase sign out successfull')
               props.logout()
            })
            .catch(error => {
               console.log('Error sign out: ' + error)
            })
      } else {
         props.logout()
      }
   }

   const sendPasswordReset = () => {
      firebase
         .auth()
         .sendPasswordResetEmail(userInfo.email)
         .then(function () {
            setSnackbarText(
               'An e-mail with information how to reset password has been sent to: ' +
                  userInfo.email
            )
            setSnackbarSeverity('info')
            setShowSnackbar(true)
            closeProfileMenu()
         })
         .catch(function (error) {
            setSnackbarText('An error occurred for email: ' + userInfo.email + ' error:' + error)
            setSnackbarSeverity('error')
            setShowSnackbar(true)
            closeProfileMenu()
         })
   }

   const clickProfileMenu = event => {
      setAnchorEl(event.currentTarget)
   }

   const closeProfileMenu = () => {
      setAnchorEl(null)
   }

   const clickLangMenu = event => {
      setAnchorLang(event.currentTarget)
   }
   const closeLangMenu = () => {
      setAnchorLang(null)
   }

   const changeLang = async lang => {
      props.i18n.changeLanguage(lang)

      // update backend language
      props.userInfo.Language = lang
      await updateUserLanguage(props.userInfo.EmployeeID, lang, props.userInfo)
      props.persistUserInfo({ ...props.userInfo })

      closeLangMenu()
   }

   return (
      <React.Fragment>
         <AppBar style={{ background: '#0083bb' }} position="sticky" elevation={0}>
            <Toolbar>
               <Grid container spacing={1} alignItems="center">
                  <Grid item>
                     <Typography color="inherit" variant="h5" component="h1">
                        {t(title)}
                     </Typography>
                  </Grid>
                  <Grid item xs />
                  <Grid item>
                     <IconButton
                        aria-controls="lang-menu"
                        aria-haspopup="true"
                        onClick={clickLangMenu}
                        color="inherit"
                        size="large"
                     >
                        <img src={t('flagIcon')} alt="Language switch" />
                     </IconButton>
                     <Menu
                        id="lang-menu"
                        anchorEl={anchorLang}
                        keepMounted
                        open={Boolean(anchorLang)}
                        onClose={closeLangMenu}
                     >
                        <MenuItem onClick={() => changeLang('en')}>
                           <img src={'/uk-flag-icon-16.png'} alt="UK flag" />{' '}
                           <Typography style={{ marginLeft: 10 }}>English</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => changeLang('bg')}>
                           <img src={'/bulgaria-flag-icon-16.png'} alt="Bulgaria flag" />{' '}
                           <Typography style={{ marginLeft: 10 }}>Bulgarian (български)</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => changeLang('sv')}>
                           <img src={'/sweden-flag-icon-16.png'} alt="Swedish flag" />{' '}
                           <Typography style={{ marginLeft: 10 }}>Swedish (Svenska)</Typography>
                        </MenuItem>
                     </Menu>
                  </Grid>
                  <Grid item>
                     <Typography variant="subtitle2" component="h1"></Typography>
                  </Grid>
                  <NotificationItem notifs={props.notifs.notifs} userInfo={props.userInfo} t={t} />
                  <Grid item>
                     <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={clickProfileMenu}
                        color="inherit"
                        className={classes.iconButtonAvatar}
                        size="large"
                     >
                        <Avatar
                           src={props.userInfo.imageUrl}
                           alt="My Avatar"
                           sx={{ width: 32, height: 32 }}
                        />
                     </IconButton>
                     <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={closeProfileMenu}
                     >
                        {userInfo.loginProvider === 'gip' ? (
                           <MenuItem onClick={sendPasswordReset}>{t('changePassword')}</MenuItem>
                        ) : null}
                        <MenuItem onClick={performLogout}>{t('titleLogout')}</MenuItem>
                     </Menu>
                  </Grid>
               </Grid>
            </Toolbar>
         </AppBar>
         <Snackbar open={props.error !== ''} autoHideDuration={6000}>
            <Alert severity={'error'}>{props.error}</Alert>
         </Snackbar>
         <Snackbar
            open={showSnackbar}
            onClose={() => setShowSnackbar(false)}
            autoHideDuration={6000}
         >
            <Alert severity={snackbarSeverity}>{snackbarText}</Alert>
         </Snackbar>
      </React.Fragment>
   )
}

Header.propTypes = {
   classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      notifs: state.notifs,
      error: state.error,
      timezone: state.timezone,
      customerTimezone: state.customerTimezone,
   }
}

export default withStyles(styles)(
   withTranslation()(
      connect(mapStateToProps, {
         authenticate,
         logout,
         fetchNotifs,
         setError,
         persistUserInfo,
         fetchCustomerData,
      })(Header)
   )
)
