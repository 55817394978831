import { default as React, useState, useEffect } from 'react'
import Select from 'react-select'
import { wrapTeam } from '../util/wrappers'
import { getTeamsForCustomer, fetchCustomerForAuthUser } from '../Api'
import { withTranslation } from 'react-i18next'

const TeamDropdown = props => {
   const [teams, setTeams] = useState([])

   const fetchTeams = async customer => {
      const teams = await getTeamsForCustomer(customer._id, props.userInfo)
      setTeams(teams.data)
   }

   useEffect(() => {
      const loadData = async () => {
         const result = await fetchCustomerForAuthUser(props.userInfo)
         if (result.success) {
            await fetchTeams(result.customer)
         }
      }
      loadData()
   }, [])

   const getOptions = () => {
      const data = teams.map(team => {
         return wrapTeam(team)
      })
      return data
   }

   return (
      <>
         <Select
            className="userfield"
            isSearchable
            isClearable
            options={teams != null ? getOptions() : []}
            value={props.value}
            placeholder={props.t('select')}
            onChange={wrappedTeam => props.handleChangeTeam(wrappedTeam)}
         />
      </>
   )
}
export default withTranslation()(TeamDropdown)
