import { faArrowAltCircleDown, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Input from '@mui/material/Input'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import ConfirmDialog from '../ConfirmDialog'
import ErrorDialog from '../ErrorDialog'
import { fetchUsers } from '../redux/actions/actions'
import { getCurrentEmployment, sortByComparison } from '../util/helpers'
import log from '../util/log'
import { withTranslation } from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import themeConfig from '../theme'
import {
   getTeamsForOverview,
   getProjectsForOverview,
   getTeamsForTL,
   getProjectsForPM,
   fetchCustomerForAdmin,
   getAllTeamLeads,
   getAllProjectManagers,
   getSkillGroupsForCustomer,
   getSkillsForCustomer,
   deleteUserFromUserList,
} from '../Api'
import UserTeamLead from '../components/UserTeamLead'
import UserProjectManagers from '../components/UserProjectManagers'
import ErrorDeletionEmployeeModal from '../components/errorDeletionEmployeeModal'
import CreateEditEmployeeModal from '../components/modals/CreateEditEmployeeModal'
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Badge,
   Box,
   Checkbox,
   DialogTitle,
   Divider,
   FormControl,
   FormControlLabel,
   FormGroup,
   Grid,
   IconButton,
   Tooltip,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LayersClearIcon from '@mui/icons-material/LayersClear'

let { styles } = themeConfig()

styles = {
   ...styles,
   filterBox: {
      padding: 10,
      position: 'absolute',
      right: 0,
      top: 0,
      height: 'calc(68vh - 1.5rem)',
      maxWidth: '300px',
      width: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
   },
   filterIconExpanded: {
      color: '#fff',
      backgroundColor: '#0083bb',
      outline: 'none !important',
      '&:hover': {
         color: '#fff',
         backgroundColor: '#0083bb',
      },
   },
   noOutline: {
      outline: 'none !important',
   },
   expandedSummary: {
      color: '#fff',
      backgroundColor: '#0083bb',
      borderRadius: '4px 4px 0px 0px',
   },
   accordion: {
      boxShadow: 'none',
      '&::before': {
         backgroundColor: 'transparent',
      },
   },
   closeIcon: {
      outline: 'none !important',
   },
}

class UserList extends Component {
   constructor(props) {
      super(props)
      this.myRef = React.createRef()
      this.state = {
         intervalIsSet: false,
         errorMessage: null,
         showError: false,
         showUserDialog: false,
         confirmButtonTitle: null,
         showConfirm: false,
         confirmMessage: null,
         confirmOnChange: null,
         confirmButtonVariant: null,
         confirmTitle: null,
         userDialogButtonTitle: null,
         updateUserData: this.emptyUpdateData(),
         userDialogTitle: null,
         performUpdate: false,
         sortBy: 'FirstName',
         sortReverse: false,
         teams: [],
         teamLeads: [],
         projects: [],
         projectManagers: [],
         skillGroups: [],
         skills: [],
         filter: '',
         openErrorDeletionModal: false,
         errorDeletionModalTeams: [],
         errorDeletionModalProjects: [],
         errorDeletionModalToggle: null,
         showFilterDialog: false,
         advancedFilters: [],
         expandSkillsAccordion: false,
         expandSkillLevelAccordion: false,
      }

      this.toggleError = this.toggleError.bind(this)
      this.toggleConfirm = this.toggleConfirm.bind(this)
      this.handleCloseUserDialog = this.handleCloseUserDialog.bind(this)
   }

   componentDidMount() {
      document.addEventListener('click', this.handleClickOutside, true)
      this.props.fetchUsers(this.props.userInfo)
      this.fetchTeams()
      this.fetchProjects()
      this.fetchTeamLeads()
      this.fetchProjectManagers()
      this.fetchSkillGroups()
      this.fetchSkills()
      this.handleSetFilterFromLocalStorage()
   }

   componentWillUnmount() {
      document.removeEventListener('click', this.handleClickOutside, true)
   }

   handleClickOutside = event => {
      const node = this.myRef.current

      if (!node || !node.contains(event.target)) {
         this.setState({
            showFilterDialog: false,
         })
      }
   }

   handleSetFilterFromLocalStorage() {
      JSON.parse(localStorage.getItem('hrAppliedFilters')) &&
         JSON.parse(localStorage.getItem('hrAppliedFilters')).employees &&
         this.setState({
            advancedFilters: JSON.parse(localStorage.getItem('hrAppliedFilters')).employees,
         })
   }

   sortUsersBy(user1, user2) {
      let firstArgument = user1[this.state.sortBy]
      let secondArgument = user2[this.state.sortBy]
      if (this.state.sortBy === 'AcceptedReports') {
         firstArgument = user1.AcceptedReports.some(rep => rep.ID === moment().format('YYYYMM'))
         secondArgument = user2.AcceptedReports.some(rep => rep.ID === moment().format('YYYYMM'))
      }
      if (this.state.sortBy === 'Employments') {
         firstArgument = getCurrentEmployment(user1.Employments, this.props.workDay)
         secondArgument = getCurrentEmployment(user2.Employments, this.props.workDay)
         firstArgument = firstArgument ? firstArgument.WorkTimePercent : 0
         secondArgument = secondArgument ? secondArgument.WorkTimePercent : 0
      }
      let result = sortByComparison(firstArgument, secondArgument)
      if (this.state.sortReverse) result *= -1
      return result
   }

   setSortBy(sortBy) {
      if (sortBy === this.state.sortBy) {
         this.setState({ sortReverse: !this.state.sortReverse })
      } else {
         this.setState({ sortBy: sortBy, sortReverse: false })
      }
   }

   deleteUser = idToDelete => {
      parseInt(idToDelete)
      deleteUserFromUserList(idToDelete, this.props.userInfo).then(
         response => {
            log.debug(response)
            this.props.fetchUsers(this.props.userInfo)
         },
         error => {
            log.error(error)
         }
      )
   }

   handleCloseUserDialog(dirty) {
      if (dirty) {
         this.toggleConfirm(
            true,
            this.props.t('CLOSE_DIALOG'),
            this.props.t('closeDialogConfirmMsg'),
            this.props.t('closeButtonTitle'),
            'warning',
            () => this.setState({ showUserDialog: false })
         )
      } else {
         this.setState({ showUserDialog: false })
      }
      document.addEventListener('click', this.handleClickOutside, true)
   }

   emptyUpdateData() {
      return {
         EmployeeID: '',
         ID: '',
         FirstName: '',
         MiddleName: '',
         LastName: '',
         CompanyEmail: '',
         PrivateEmail: '',
         CompanyMobile: '',
         CV: '',
         Address: '',
         WorkTimePercent: 100,
         Skills: [],
         ExternalUserID: '',
         ParentalLeave: false,
         ExternalUser: false,
         Admin: false,
         Manager: false,
         BackofficeAdmin: false,
         LoginAllowed: false,
         ShowInSalaryReport: true,
      }
   }

   handleShowUserAddModal(show) {
      document.removeEventListener('click', this.handleClickOutside, true)
      this.setState({
         showUserDialog: show,
         performUpdate: false,
         updateUserData: this.emptyUpdateData(),
         userDialogButtonTitle: 'Add user',
         userDialogTitle: 'ADD NEW USER',
      })
   }

   handleShowUserUpdateModal(show, data) {
      log.debug('DATA:' + JSON.stringify(data))
      document.removeEventListener('click', this.handleClickOutside, true)
      this.setState({
         showUserDialog: show,
         performUpdate: true,
         updateUserData: data,
         userDialogButtonTitle: 'Update user',
         userDialogTitle: 'UPDATE USER',
      })
   }

   toggleError(show, message) {
      this.setState({ showError: show, errorMessage: message })
   }

   async fetchTeams() {
      const response = await getTeamsForOverview(this.props.userInfo)
      if (response.success) {
         this.setState({ teams: response.data })
      }
   }

   async fetchTeamLeads() {
      const customer = await fetchCustomerForAdmin(this.props.userInfo)
      if (customer.data._id) {
         const teamLeads = await getAllTeamLeads(customer.data._id, this.props.userInfo)
         if (teamLeads.success) {
            this.setState({ teamLeads: teamLeads.teamLeads })
         }
      }
   }

   async fetchProjectManagers() {
      const customer = await fetchCustomerForAdmin(this.props.userInfo)
      if (customer.data._id) {
         const projectManagers = await getAllProjectManagers(customer.data._id, this.props.userInfo)
         if (projectManagers.success) {
            this.setState({ projectManagers: projectManagers.projectManagers })
         }
      }
   }

   async fetchSkillGroups() {
      const customer = await fetchCustomerForAdmin(this.props.userInfo)
      if (customer.data._id) {
         const skillGroups = await getSkillGroupsForCustomer(customer.data._id, this.props.userInfo)
         if (skillGroups.success) {
            this.setState({ skillGroups: skillGroups.data })
         }
      }
   }

   async fetchSkills() {
      const customer = await fetchCustomerForAdmin(this.props.userInfo)
      if (customer.data._id) {
         const skills = await getSkillsForCustomer(customer.data._id, this.props.userInfo)
         if (skills.success) {
            this.setState({ skills: skills.data })
         }
      }
   }

   updateUpdateUserData(data) {
      this.setState({ updateUserData: { ...this.state.updateUserData, ...data } })
   }

   updatePerformUpdate() {
      this.setState({ performUpdate: true })
   }

   async fetchProjects() {
      const response = await getProjectsForOverview(this.props.userInfo)
      if (response.success) {
         this.setState({ projects: response.data })
      }
   }

   toggleConfirm(show, title, message, buttonTitle, buttonVariant, onChange) {
      this.setState({
         showConfirm: show,
         confirmTitle: title,
         confirmMessage: message,
         confirmButtonTitle: buttonTitle,
         confirmButtonVariant: buttonVariant,
         confirmOnChange: onChange,
      })
   }

   toggleErrorDeletionModal(openModal, teams, projects, toggleFunction) {
      this.setState({
         openErrorDeletionModal: openModal,
         errorDeletionModalTeams: teams,
         errorDeletionModalProjects: projects,
         errorDeletionModalToggle: toggleFunction,
      })
   }

   async checkIfEmployeeCanBeDeleted(idToDelete, dialogData) {
      const teams = await getTeamsForTL(idToDelete, this.props.userInfo)
      const projects = await getProjectsForPM(idToDelete, this.props.userInfo)
      if (teams.data.length === 0 && projects.data.length === 0) {
         this.toggleConfirm(
            dialogData.showConfirm,
            dialogData.confirmTitle,
            dialogData.confirmMessage,
            dialogData.confirmButtonTitle,
            dialogData.confirmButtonVariant,
            dialogData.confirmOnChange
         )
      } else {
         this.toggleErrorDeletionModal(
            true,
            teams.data,
            projects.data,
            this.toggleErrorDeletionModal
         )
      }
   }

   handleFilter = event => {
      this.setState({ filter: event.target.value })
   }

   filterByValue(array, string) {
      return array.filter(obj => {
         return Object.keys(obj).some(key => {
            if (
               key === 'FirstName' ||
               key === 'LastName' ||
               key === 'CompanyEmail' ||
               key === 'CompanyMobile' ||
               key === 'ID' ||
               key === 'MiddleName' ||
               key === 'PrivateEmail' ||
               key === 'Address'
            )
               return obj[key].toLowerCase().includes(string.toLowerCase())
         })
      })
   }

   toggleFilterDialog() {
      this.setState({ showFilterDialog: !this.state.showFilterDialog })
   }

   addRemoveAdvancedFilterCriteria(filterGroup, newFilter) {
      let currentAdvancedFilters = this.state.advancedFilters
      if (
         currentAdvancedFilters.length > 0 &&
         currentAdvancedFilters.find(
            filterStatement => filterStatement.split(':')[0] === filterGroup
         )
      ) {
         currentAdvancedFilters = currentAdvancedFilters
            .map(currentAdvancedFilterStatements => {
               if (currentAdvancedFilterStatements.split(':')[0] === filterGroup) {
                  let filters = currentAdvancedFilterStatements.split(':')[1].split(',')
                  if (filters.includes(newFilter)) {
                     const indexOfElement = filters.indexOf(newFilter)
                     filters.splice(indexOfElement, 1)
                     if (filterGroup === 'SkillGroups' && filters.length === 0)
                        currentAdvancedFilters =
                           this.removeSkillsAndSkillLevelFromFilter(currentAdvancedFilters)
                     else if (filterGroup === 'SkillGroups' && filters.length)
                        currentAdvancedFilters = this.removeSelectedSkillsForThisGroup(
                           currentAdvancedFilters,
                           newFilter
                        )
                  } else {
                     if (newFilter === 'All' || newFilter === 'Unassigned') {
                        filters = [newFilter]
                     } else if (filters.includes('All') || filters.includes('Unassigned')) {
                        filters = []
                        filters.push(newFilter)
                     } else {
                        filters.push(newFilter)
                     }
                  }
                  return `${filterGroup}:${filters.join(',')}`
               } else {
                  return currentAdvancedFilterStatements
               }
            })
            .filter(
               currentAdvancedFilterStatements =>
                  currentAdvancedFilterStatements.split(':')[1] !== ''
            )
      } else {
         const generateSkillStatement = `${filterGroup}:${newFilter}`
         currentAdvancedFilters.push(generateSkillStatement)
      }

      const newFilterObject =
         localStorage.getItem('hrAppliedFilters') &&
         !Array.isArray(JSON.parse(localStorage.getItem('hrAppliedFilters')))
            ? JSON.parse(localStorage.getItem('hrAppliedFilters'))
            : {}
      if (currentAdvancedFilters.length > 0) {
         newFilterObject.employees = currentAdvancedFilters
         localStorage.setItem('hrAppliedFilters', JSON.stringify(newFilterObject))
      } else {
         delete newFilterObject.employees
         if (Object.keys(newFilterObject).length)
            localStorage.setItem('hrAppliedFilters', JSON.stringify(newFilterObject))
         else localStorage.removeItem('hrAppliedFilters')
      }

      this.setState({ advancedFilters: currentAdvancedFilters })
   }

   removeSkillsAndSkillLevelFromFilter(currentAdvancedFilters) {
      this.setState({ expandSkillsAccordion: false, expandSkillLevelAccordion: false })
      const skillsIndex = currentAdvancedFilters.findIndex(
         filterStatement => filterStatement.split(':')[0] === 'Skills'
      )
      if (skillsIndex > -1) currentAdvancedFilters.splice(skillsIndex, 1)

      const skillLevelIndex = currentAdvancedFilters.findIndex(
         filterStatement => filterStatement.split(':')[0] === 'SkillLevel'
      )
      if (skillLevelIndex > -1) currentAdvancedFilters.splice(skillLevelIndex, 1)
      return currentAdvancedFilters
   }

   removeSelectedSkillsForThisGroup(currentAdvancedFilters, deletedGroup) {
      let foundSkillsStatement = currentAdvancedFilters.find(
         filterStatement => filterStatement.split(':')[0] === 'Skills'
      )
      if (foundSkillsStatement === undefined) return currentAdvancedFilters

      const skillsForSkillGroup = this.state.skillGroups
         .find(skillGroup => skillGroup.Name === deletedGroup)
         .Skills.map(skill => skill.Name)
      let filteredSkills = foundSkillsStatement
         .split(':')[1]
         .split(',')
         .filter(skill => !skillsForSkillGroup.includes(skill))
      let foundSkillsIndex = currentAdvancedFilters.findIndex(
         filterStatement => filterStatement.split(':')[0] === 'Skills'
      )
      if (filteredSkills.length === 0) {
         const skillLevelIndex = currentAdvancedFilters.findIndex(
            filterStatement => filterStatement.split(':')[0] === 'SkillLevel'
         )
         if (skillLevelIndex > -1) currentAdvancedFilters.splice(skillLevelIndex, 1)
         this.setState({ expandSkillLevelAccordion: false })
      }
      currentAdvancedFilters.splice(foundSkillsIndex, 1, `Skills:${filteredSkills.join(',')}`)

      return currentAdvancedFilters
   }

   applyAdvancedFiltersByAttribute(users, filters) {
      let filteredUsers = []
      filters.split(',').forEach(filter => {
         const [key, value] = filter.split('=')
         if (key === 'CV' && value === 'maintainedCV') {
            filteredUsers = [
               ...filteredUsers,
               ...users.filter(user => {
                  return user[key].length > 0
               }),
            ]
         } else if (key === 'CV') {
            filteredUsers = [
               ...filteredUsers,
               ...users.filter(user => {
                  return user[key].length === 0
               }),
            ]
         } else if (key === 'Employments' && value === 'activeEmployments') {
            filteredUsers = [
               ...filteredUsers,
               ...users.filter(user => {
                  if (user[key].length === 0) {
                     return false
                  } else {
                     const today = new Date(Date.now())
                     const mostRecentEmployment = user[key].reduce((mostRecent, current) =>
                        mostRecent.StartDate > current.StartDate ? mostRecent : current
                     )
                     return mostRecentEmployment.EndDate !== null
                        ? new Date(mostRecentEmployment.EndDate).getTime() > today.getTime()
                        : true
                  }
               }),
            ]
         } else if (key === 'Employments') {
            filteredUsers = [
               ...filteredUsers,
               ...users.filter(user => {
                  if (user[key].length === 0) {
                     return true
                  } else {
                     const today = new Date(Date.now())
                     const mostRecentEmployment = user[key].reduce((mostRecent, current) =>
                        mostRecent.StartDate > current.StartDate ? mostRecent : current
                     )
                     return mostRecentEmployment.EndDate !== null
                        ? new Date(mostRecentEmployment.EndDate).getTime() < today.getTime()
                        : false
                  }
               }),
            ]
         } else if (key === 'Certificates' && value === 'notEmpty') {
            filteredUsers = [
               ...filteredUsers,
               ...users.filter(user => {
                  return user[key].length > 0
               }),
            ]
         } else if (key === 'Certificates') {
            filteredUsers = [
               ...filteredUsers,
               ...users.filter(user => {
                  return user[key].length === 0
               }),
            ]
         } else {
            filteredUsers = [
               ...filteredUsers,
               ...users.filter(user => {
                  return user[key].toString() === value
               }),
            ]
         }
      })
      return filteredUsers
   }

   applyAdvancedFiltersByTeamLead(users, filters) {
      let filteredUsers = []
      let teamsMembers = []
      let singleFilterStorage = ''
      filters.split(',').forEach(filter => {
         if (filter === 'All' || filter === 'Unassigned') {
            singleFilterStorage = filter
            teamsMembers = [
               ...teamsMembers,
               ...this.state.teams.map(teamLeadsTeam => [...teamLeadsTeam.MemberIDs]),
            ]
         } else {
            const teamLeadsTeams = this.state.teams.filter(team => team.TeamLead === filter)
            teamsMembers = [
               ...teamsMembers,
               ...teamLeadsTeams.map(teamLeadsTeam => [...teamLeadsTeam.MemberIDs]),
            ]
         }
      })
      const modifyTeamsMembers = teamsMembers
         .flat()
         .filter((ID, index, arr) => arr.findIndex(innerID => ID === innerID) === index)
      filteredUsers = users.filter(user => {
         if (singleFilterStorage === 'Unassigned') return !modifyTeamsMembers.includes(user._id)
         return modifyTeamsMembers.includes(user._id)
      })
      return filteredUsers
   }

   applyAdvancedFiltersByProjectManager(users, filters) {
      let filteredUsers = []
      let projectsMembers = []
      let singleFilterStorage = ''
      filters.split(',').forEach(filter => {
         if (filter === 'All' || filter === 'Unassigned') {
            singleFilterStorage = filter
            projectsMembers = [
               ...projectsMembers,
               ...this.state.projects.map(projectManagersProject => [
                  ...projectManagersProject.MemberIDs,
               ]),
            ]
         } else {
            const projectManagersProjects = this.state.projects.filter(
               project => project.ProjectManager === filter
            )
            projectsMembers = [
               ...projectsMembers,
               ...projectManagersProjects.map(projectManagersProject => [
                  ...projectManagersProject.MemberIDs,
               ]),
            ]
         }
      })
      const modifyProjectsMembers = projectsMembers
         .flat()
         .filter((ID, index, arr) => arr.findIndex(innerID => ID === innerID) === index)
      filteredUsers = users.filter(user => {
         if (singleFilterStorage === 'Unassigned') return !modifyProjectsMembers.includes(user._id)
         return modifyProjectsMembers.includes(user._id)
      })
      return filteredUsers
   }

   applyAdvancedFiltersForSkillAttributes(users, allFilters) {
      let filteredUsers = []
      let skillGroups = allFilters
         .find(filterStatement => filterStatement.split(':')[0] === 'SkillGroups')
         .split(':')[1]
         .split(',')
      let skills =
         allFilters.find(filterStatement => filterStatement.split(':')[0] === 'Skills') !==
         undefined
            ? allFilters
                 .find(filterStatement => filterStatement.split(':')[0] === 'Skills')
                 .split(':')[1]
                 .split(',')
            : undefined
      let skillLevels =
         allFilters.find(filterStatement => filterStatement.split(':')[0] === 'SkillLevel') !==
         undefined
            ? allFilters
                 .find(filterStatement => filterStatement.split(':')[0] === 'SkillLevel')
                 .split(':')[1]
                 .split(',')
            : undefined
      skillGroups.forEach(skillGroup => {
         filteredUsers = [
            ...filteredUsers,
            ...users.filter(user => {
               return user.Skills.find(
                  skill =>
                     skill.GroupName === skillGroup &&
                     (skills !== undefined ? skills.includes(skill.Name) : true) &&
                     (skillLevels !== undefined
                        ? skillLevels.includes(skill.SkillLevel.toString())
                        : true)
               )
            }),
         ]
      })
      return filteredUsers
   }

   checkTheCheckbox(skillGroup, filter) {
      if (this.state.advancedFilters.length === 0) {
         return false
      } else {
         const foundSkillStatement = this.state.advancedFilters.find(
            skillStatement => skillStatement.split(':')[0] === skillGroup
         )
         if (foundSkillStatement === undefined) return false
         else {
            return foundSkillStatement.split(':')[1].split(',').includes(filter)
         }
      }
   }

   returnGroupFilterCount(skillGroup) {
      const foundSkillStatement = this.state.advancedFilters.find(
         skillStatement => skillStatement.split(':')[0] === skillGroup
      )
      if (foundSkillStatement === undefined) return 0
      else {
         return foundSkillStatement.split(':')[1].split(',').length
      }
   }

   advanceFilterUsers(users, advancedFilters) {
      if (advancedFilters.length) {
         let newUsers = users
         advancedFilters.forEach(advancedFilterStatement => {
            const [filterGroup, filters] = advancedFilterStatement.split(':')

            switch (filterGroup) {
               case 'TeamLeads':
                  newUsers = this.applyAdvancedFiltersByTeamLead(newUsers, filters)
                  return
               case 'ProjectManagers':
                  newUsers = this.applyAdvancedFiltersByProjectManager(newUsers, filters)
                  return
               case 'SkillGroups':
                  newUsers = this.applyAdvancedFiltersForSkillAttributes(newUsers, advancedFilters)
                  return
               case 'Permissions':
                  newUsers = this.applyAdvancedFiltersByAttribute(newUsers, filters)
                  return
               case 'OtherCriteria':
                  newUsers = this.applyAdvancedFiltersByAttribute(newUsers, filters)
                  return
               default:
                  return
            }
         })
         newUsers = newUsers.filter(
            (user, index, arr) =>
               arr.findIndex(innerUser => innerUser.EmployeeID === user.EmployeeID) === index
         )
         return newUsers
      } else {
         return users
      }
   }

   toggleSkillsAccordion() {
      this.setState({ expandSkillsAccordion: !this.state.expandSkillsAccordion })
   }

   toggleSkillLevelAccordion() {
      this.setState({ expandSkillLevelAccordion: !this.state.expandSkillLevelAccordion })
   }

   clearAllFilters() {
      const newFilterObject =
         localStorage.getItem('hrAppliedFilters') &&
         !Array.isArray(JSON.parse(localStorage.getItem('hrAppliedFilters')))
            ? JSON.parse(localStorage.getItem('hrAppliedFilters'))
            : {}
      if (Object.keys(newFilterObject).length) {
         delete newFilterObject.employees
         if (Object.keys(newFilterObject).length)
            localStorage.setItem('hrAppliedFilters', JSON.stringify(newFilterObject))
         else localStorage.removeItem('hrAppliedFilters')
      }
      this.setState({ advancedFilters: [] })
   }

   render() {
      if (this.props.users == null) {
         return (
            <React.Fragment>
               <Typography variant="body2">{this.props.t('fetchingData')}</Typography>
            </React.Fragment>
         )
      }

      const filteredData = this.filterByValue(this.props.users, this.state.filter)
      const advancedFilteredData = this.advanceFilterUsers(filteredData, this.state.advancedFilters)

      return (
         <React.Fragment>
            <Paper
               className="contentPaper"
               style={{
                  maxWidth: '80vw',
                  height: '75vh',
                  maxHeight: '75vh',
                  marginTop: '-3rem',
                  boxShadow: 'none',
               }}
            >
               <Grid container className={'m-4 p-4'} style={{ height: '7vh' }}>
                  <Grid item xs={10}>
                     <div>
                        {this.props.userInfo.permissions['employeesManagement'].create && (
                           <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => this.handleShowUserAddModal(true)}
                           >
                              {this.props.t('newPersonnel')}
                           </Button>
                        )}
                        <Input
                           style={{ maxWidth: 128, marginLeft: 32 }}
                           placeholder={this.props.t('searchPersonnel')}
                           onChange={this.handleFilter}
                        />
                     </div>
                  </Grid>

                  <Grid item xs={2}>
                     <Box style={{ float: 'right' }} className={'mr-3'}>
                        <Tooltip arrow title={this.props.t('clearAllFilters')}>
                           <IconButton
                              style={{ margin: '0 6px' }}
                              className={this.props.classes.noOutline}
                              onClick={() => this.clearAllFilters()}
                              size="large"
                           >
                              <LayersClearIcon />
                           </IconButton>
                        </Tooltip>
                        <Tooltip arrow title={this.props.t('filterPersonnelPlaceholder')}>
                           <IconButton
                              style={{ margin: '0 6px' }}
                              className={
                                 this.state.showFilterDialog
                                    ? this.props.classes.filterIconExpanded
                                    : this.props.classes.noOutline
                              }
                              onClick={() => this.toggleFilterDialog()}
                              size="large"
                           >
                              <Badge
                                 badgeContent={this.state.advancedFilters.length}
                                 color="secondary"
                                 overlap="rectangular"
                              >
                                 <FilterListIcon />
                              </Badge>
                           </IconButton>
                        </Tooltip>
                     </Box>
                  </Grid>
               </Grid>
               <div
                  className={'contentWrapper'}
                  style={{ height: '68vh', maxHeight: '68vh', position: 'relative' }}
               >
                  <TableContainer
                     className="userTable"
                     component={Paper}
                     style={{ maxHeight: '68vh', boxShadow: 'none' }}
                  >
                     <Table size="small">
                        <TableHead>
                           <TableRow>
                              <TableCell />
                              <TableCell
                                 onClick={() => this.setSortBy('ID')}
                                 style={{ cursor: 'pointer' }}
                              >
                                 {this.props.t('ID')}{' '}
                                 {this.state.sortBy === 'ID' ? (
                                    <FontAwesomeIcon
                                       color={
                                          this.state.sortBy === 'ID' ? 'deeppink' : 'deepskyblue'
                                       }
                                       icon={faArrowAltCircleDown}
                                       rotation={this.state.sortReverse ? 180 : 0}
                                    />
                                 ) : null}
                              </TableCell>
                              <TableCell
                                 onClick={() => this.setSortBy('FirstName')}
                                 style={{ cursor: 'pointer' }}
                              >
                                 {this.props.t('nameLabel')}{' '}
                                 {this.state.sortBy === 'FirstName' ? (
                                    <FontAwesomeIcon
                                       color={
                                          this.state.sortBy === 'FirstName'
                                             ? 'deeppink'
                                             : 'deepskyblue'
                                       }
                                       icon={faArrowAltCircleDown}
                                       rotation={this.state.sortReverse ? 180 : 0}
                                    />
                                 ) : null}
                              </TableCell>
                              <TableCell
                                 onClick={() => this.setSortBy('LastName')}
                                 style={{ cursor: 'pointer' }}
                              >
                                 {this.props.t('lastNameLabel')}{' '}
                                 {this.state.sortBy === 'LastName' ? (
                                    <FontAwesomeIcon
                                       color={
                                          this.state.sortBy === 'LastName'
                                             ? 'deeppink'
                                             : 'deepskyblue'
                                       }
                                       icon={faArrowAltCircleDown}
                                       rotation={this.state.sortReverse ? 180 : 0}
                                    />
                                 ) : null}
                              </TableCell>
                              <TableCell
                                 onClick={() => this.setSortBy('CompanyEmail')}
                                 style={{ cursor: 'pointer' }}
                              >
                                 {this.props.t('emailLabel')}{' '}
                                 {this.state.sortBy === 'CompanyEmail' ? (
                                    <FontAwesomeIcon
                                       color={
                                          this.state.sortBy === 'CompanyEmail'
                                             ? 'deeppink'
                                             : 'deepskyblue'
                                       }
                                       icon={faArrowAltCircleDown}
                                       rotation={this.state.sortReverse ? 180 : 0}
                                    />
                                 ) : null}
                              </TableCell>
                              <TableCell
                                 onClick={() => this.setSortBy('CompanyMobile')}
                                 style={{ cursor: 'pointer' }}
                              >
                                 {this.props.t('mobileLabel')}{' '}
                                 {this.state.sortBy === 'CompanyMobile' ? (
                                    <FontAwesomeIcon
                                       color={
                                          this.state.sortBy === 'CompanyMobile'
                                             ? 'deeppink'
                                             : 'deepskyblue'
                                       }
                                       icon={faArrowAltCircleDown}
                                       rotation={this.state.sortReverse ? 180 : 0}
                                    />
                                 ) : null}
                              </TableCell>
                              <TableCell
                                 onClick={() => this.setSortBy('CV')}
                                 style={{ cursor: 'pointer' }}
                              >
                                 {this.props.t('CVLabel')}{' '}
                                 {this.state.sortBy === 'CV' ? (
                                    <FontAwesomeIcon
                                       color={
                                          this.state.sortBy === 'CV' ? 'deeppink' : 'deepskyblue'
                                       }
                                       icon={faArrowAltCircleDown}
                                       rotation={this.state.sortReverse ? 180 : 0}
                                    />
                                 ) : null}
                              </TableCell>
                              <TableCell
                                 onClick={() => this.setSortBy('TeamLead')}
                                 style={{ cursor: 'pointer' }}
                              >
                                 {this.props.t('TeamLead')}{' '}
                                 {this.state.sortBy === 'TeamLead' ? (
                                    <FontAwesomeIcon
                                       color={
                                          this.state.sortBy === 'TeamLead'
                                             ? 'deeppink'
                                             : 'deepskyblue'
                                       }
                                       icon={faArrowAltCircleDown}
                                       rotation={this.state.sortReverse ? 180 : 0}
                                    />
                                 ) : null}
                              </TableCell>
                              <TableCell
                                 onClick={() => this.setSortBy('ProjectManager')}
                                 style={{ cursor: 'pointer' }}
                              >
                                 {this.props.t('ProjectManager')}{' '}
                                 {this.state.sortBy === 'ProjectManager' ? (
                                    <FontAwesomeIcon
                                       color={
                                          this.state.sortBy === 'ProjectManager'
                                             ? 'deeppink'
                                             : 'deepskyblue'
                                       }
                                       icon={faArrowAltCircleDown}
                                       rotation={this.state.sortReverse ? 180 : 0}
                                    />
                                 ) : null}
                              </TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {this.props.users != null && this.props.users.length <= 0 ? (
                              <TableRow key={1}>
                                 <TableCell colSpan={9}>{this.props.t('NO_DB_DATA')}</TableCell>
                              </TableRow>
                           ) : (
                              advancedFilteredData
                                 .sort((dat1, dat2) => this.sortUsersBy(dat1, dat2))
                                 .map(dat => (
                                    <TableRow key={dat.EmployeeID}>
                                       <TableCell>
                                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                             <FontAwesomeIcon
                                                style={{ cursor: 'pointer' }}
                                                icon={faEdit}
                                                onClick={() =>
                                                   this.handleShowUserUpdateModal(true, dat)
                                                }
                                             />
                                             {this.props.userInfo.permissions['employeesManagement']
                                                .delete && (
                                                <FontAwesomeIcon
                                                   style={{ marginLeft: 4, cursor: 'pointer' }}
                                                   icon={faTrash}
                                                   onClick={() =>
                                                      this.checkIfEmployeeCanBeDeleted(
                                                         dat.EmployeeID,
                                                         {
                                                            showConfirm: true,
                                                            confirmTitle:
                                                               this.props.t('DELETE_PERSONNEL'),
                                                            confirmMessage:
                                                               this.props.t('deleteConfirmMsg') +
                                                               ': ' +
                                                               dat.FirstName +
                                                               ' ' +
                                                               dat.LastName +
                                                               ' ID:' +
                                                               dat.ID +
                                                               '?',
                                                            confirmButtonTitle:
                                                               this.props.t('deleteButtonTitle'),
                                                            confirmButtonVariant: 'danger',
                                                            confirmOnChange: () =>
                                                               this.deleteUser(dat.EmployeeID),
                                                         }
                                                      )
                                                   }
                                                />
                                             )}
                                          </Box>
                                       </TableCell>
                                       <TableCell>
                                          <Link to={'/employments/?id=' + dat.EmployeeID}>
                                             {dat.ID}
                                          </Link>
                                       </TableCell>
                                       <TableCell>{dat.FirstName}</TableCell>
                                       <TableCell>{dat.LastName}</TableCell>
                                       <TableCell>{dat.CompanyEmail}</TableCell>
                                       <TableCell>{dat.CompanyMobile}</TableCell>
                                       {dat.CV ? (
                                          <TableCell>
                                             <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={dat.CV}
                                             >
                                                {this.props.t('Open')}
                                             </a>
                                          </TableCell>
                                       ) : (
                                          <TableCell />
                                       )}
                                       <TableCell>
                                          <UserTeamLead
                                             teams={this.state.teams.filter(
                                                team => dat._id !== team.TeamLead
                                             )}
                                             userId={dat._id}
                                             users={this.props.users}
                                             t={this.props.t}
                                          />
                                       </TableCell>
                                       <TableCell sx={{ width: '100px' }}>
                                          <UserProjectManagers
                                             projects={this.state.projects}
                                             userId={dat._id}
                                             users={this.props.users}
                                             t={this.props.t}
                                          />
                                       </TableCell>
                                    </TableRow>
                                 ))
                           )}
                        </TableBody>
                     </Table>
                  </TableContainer>
                  {this.state.showFilterDialog && (
                     <Paper className={this.props.classes.filterBox} square={true} ref={this.myRef}>
                        <DialogTitle
                           style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              padding: '8px 4px 16px 16px',
                           }}
                        >
                           <Typography style={{ fontSize: '24px' }}>
                              {this.props.t('filters')}
                           </Typography>
                           <IconButton
                              className={this.props.classes.closeIcon}
                              onClick={() => this.toggleFilterDialog()}
                              size="large"
                           >
                              <CloseIcon />
                           </IconButton>
                        </DialogTitle>
                        <Accordion className={this.props.classes.accordion}>
                           <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              classes={{ expanded: this.props.classes.expandedSummary }}
                           >
                              <Typography>
                                 {this.props.t('filterBy')}{' '}
                                 <b>{`${this.props
                                    .t('filterAccordionTextTeamLead')
                                    .toUpperCase()} ${
                                    this.returnGroupFilterCount('TeamLeads') !== 0
                                       ? ` (${this.returnGroupFilterCount('TeamLeads')})`
                                       : ''
                                 }`}</b>
                              </Typography>
                           </AccordionSummary>
                           <AccordionDetails>
                              <Box display="flex" flexDirection="column">
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox('TeamLeads', 'All')}
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'TeamLeads',
                                                      'All'
                                                   )
                                                }}
                                             />
                                          }
                                          label={this.props.t('filterCheckboxTextAll')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'TeamLeads',
                                                   'Unassigned'
                                                )}
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'TeamLeads',
                                                      'Unassigned'
                                                   )
                                                }}
                                             />
                                          }
                                          label={this.props.t('filterCheckboxTextUnassigned')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 {this.state.teamLeads.map(teamLead => (
                                    <FormControl component="fieldset" key={teamLead._id}>
                                       <FormGroup aria-label="position">
                                          <FormControlLabel
                                             value={teamLead}
                                             control={
                                                <Checkbox
                                                   color="default"
                                                   checked={this.checkTheCheckbox(
                                                      'TeamLeads',
                                                      teamLead._id
                                                   )}
                                                   onChange={() => {
                                                      this.addRemoveAdvancedFilterCriteria(
                                                         'TeamLeads',
                                                         teamLead._id
                                                      )
                                                   }}
                                                />
                                             }
                                             label={teamLead.Name}
                                             labelPlacement="end"
                                          ></FormControlLabel>
                                       </FormGroup>
                                    </FormControl>
                                 ))}
                              </Box>
                           </AccordionDetails>
                        </Accordion>
                        <Divider style={{ background: '#E0E0E0' }} />
                        <Accordion className={this.props.classes.accordion}>
                           <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              classes={{ expanded: this.props.classes.expandedSummary }}
                           >
                              <Typography>
                                 {this.props.t('filterBy')}{' '}
                                 <b>
                                    {`${this.props
                                       .t('filterAccordionTextProjectManager')
                                       .toUpperCase()} ${
                                       this.returnGroupFilterCount('ProjectManagers') !== 0
                                          ? ` (${this.returnGroupFilterCount('ProjectManagers')})`
                                          : ''
                                    }`}
                                 </b>
                              </Typography>
                           </AccordionSummary>
                           <AccordionDetails>
                              <Box display="flex" flexDirection="column">
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'ProjectManagers',
                                                   'All'
                                                )}
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'ProjectManagers',
                                                      'All'
                                                   )
                                                }}
                                             />
                                          }
                                          label={this.props.t('filterCheckboxTextAll')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'ProjectManagers',
                                                   'Unassigned'
                                                )}
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'ProjectManagers',
                                                      'Unassigned'
                                                   )
                                                }}
                                             />
                                          }
                                          label={this.props.t('filterCheckboxTextUnassigned')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 {this.state.projectManagers.map(projectManager => (
                                    <FormControl component="fieldset" key={projectManager._id}>
                                       <FormGroup aria-label="position">
                                          <FormControlLabel
                                             value={projectManager}
                                             control={
                                                <Checkbox
                                                   color="default"
                                                   checked={this.checkTheCheckbox(
                                                      'ProjectManagers',
                                                      projectManager._id
                                                   )}
                                                   onChange={() => {
                                                      this.addRemoveAdvancedFilterCriteria(
                                                         'ProjectManagers',
                                                         projectManager._id
                                                      )
                                                   }}
                                                />
                                             }
                                             label={projectManager.Name}
                                             labelPlacement="end"
                                          ></FormControlLabel>
                                       </FormGroup>
                                    </FormControl>
                                 ))}
                              </Box>
                           </AccordionDetails>
                        </Accordion>
                        <Divider style={{ background: '#E0E0E0' }} />
                        <Accordion className={this.props.classes.accordion}>
                           <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              classes={{ expanded: this.props.classes.expandedSummary }}
                           >
                              <Typography>
                                 {this.props.t('filterBy')}{' '}
                                 <b>
                                    {`${this.props
                                       .t('filterAccordionTextPermissions')
                                       .toUpperCase()} ${
                                       this.returnGroupFilterCount('Permissions') !== 0
                                          ? ` (${this.returnGroupFilterCount('Permissions')})`
                                          : ''
                                    }`}
                                 </b>
                              </Typography>
                           </AccordionSummary>
                           <AccordionDetails>
                              <Box display="flex" flexDirection="column">
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'Permissions',
                                                   'Admin=true'
                                                )}
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'Permissions',
                                                      'Admin=true'
                                                   )
                                                }}
                                             />
                                          }
                                          label={this.props.t('employeeModalAdmin')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'Permissions',
                                                      'Manager=true'
                                                   )
                                                }}
                                             />
                                          }
                                          label={this.props.t('employeeModalManager')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'Permissions',
                                                      'BackofficeAdmin=true'
                                                   )
                                                }}
                                             />
                                          }
                                          label={this.props.t('employeeModalBackofficeAdmin')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          value={'teamLeadAll'}
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'Permissions',
                                                   'LoginAllowed=true'
                                                )}
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'Permissions',
                                                      'LoginAllowed=true'
                                                   )
                                                }}
                                             />
                                          }
                                          label={this.props.t('employeeModalLoginAllowed')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          value={'teamLeadAll'}
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'Permissions',
                                                   'LoginAllowed=false'
                                                )}
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'Permissions',
                                                      'LoginAllowed=false'
                                                   )
                                                }}
                                             />
                                          }
                                          label={this.props.t('filterCheckboxTextLoginDisabled')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                              </Box>
                           </AccordionDetails>
                        </Accordion>
                        <Divider style={{ background: '#E0E0E0' }} />
                        <Accordion className={this.props.classes.accordion}>
                           <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              classes={{ expanded: this.props.classes.expandedSummary }}
                           >
                              <Typography>
                                 {this.props.t('filterBy')}{' '}
                                 <b>
                                    {`${this.props
                                       .t('filterAccordionTextOtherCriteria')
                                       .toUpperCase()} ${
                                       this.returnGroupFilterCount('OtherCriteria') !== 0
                                          ? ` (${this.returnGroupFilterCount('OtherCriteria')})`
                                          : ''
                                    }`}
                                 </b>
                              </Typography>
                           </AccordionSummary>
                           <AccordionDetails>
                              <Box display="flex" flexDirection="column">
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'OtherCriteria',
                                                   'ExternalUser=true'
                                                )}
                                                onChange={() =>
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'OtherCriteria',
                                                      'ExternalUser=true'
                                                   )
                                                }
                                             />
                                          }
                                          label={this.props.t('employeeModalExternalUser')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'OtherCriteria',
                                                   'ParentalLeave=true'
                                                )}
                                                onChange={() =>
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'OtherCriteria',
                                                      'ParentalLeave=true'
                                                   )
                                                }
                                             />
                                          }
                                          label={this.props.t('employeeModalParentalLeave')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'OtherCriteria',
                                                   'Employments=activeEmployments'
                                                )}
                                                onChange={() =>
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'OtherCriteria',
                                                      'Employments=activeEmployments'
                                                   )
                                                }
                                             />
                                          }
                                          label={this.props.t('filterCheckboxTextWithAE')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'OtherCriteria',
                                                   'Employments=[]'
                                                )}
                                                onChange={() =>
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'OtherCriteria',
                                                      'Employments=[]'
                                                   )
                                                }
                                             />
                                          }
                                          label={this.props.t('filterCheckboxTextWAE')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'OtherCriteria',
                                                   'CV=maintainedCV'
                                                )}
                                                onChange={() =>
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'OtherCriteria',
                                                      'CV=maintainedCV'
                                                   )
                                                }
                                             />
                                          }
                                          label={this.props.t('filterCheckboxTextCVM')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'OtherCriteria',
                                                   "CV=''"
                                                )}
                                                onChange={() =>
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'OtherCriteria',
                                                      "CV=''"
                                                   )
                                                }
                                             />
                                          }
                                          label={this.props.t('filterCheckboxTextCVNM')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'OtherCriteria',
                                                   'Certificates=notEmpty'
                                                )}
                                                onChange={() =>
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'OtherCriteria',
                                                      'Certificates=notEmpty'
                                                   )
                                                }
                                             />
                                          }
                                          label={this.props.t('filterCheckboxTextWithCertificates')}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox(
                                                   'OtherCriteria',
                                                   "Certificates=''"
                                                )}
                                                onChange={() =>
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'OtherCriteria',
                                                      "Certificates=''"
                                                   )
                                                }
                                             />
                                          }
                                          label={this.props.t(
                                             'filterCheckboxTextWithoutCertificates'
                                          )}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                              </Box>
                           </AccordionDetails>
                        </Accordion>
                        <Divider style={{ background: '#E0E0E0' }} />
                        <Accordion className={this.props.classes.accordion}>
                           <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              classes={{ expanded: this.props.classes.expandedSummary }}
                           >
                              <Typography>
                                 {this.props.t('filterBy')}{' '}
                                 <b>
                                    {`${this.props
                                       .t('filterAccordionTextSkillGroup')
                                       .toUpperCase()} ${
                                       this.returnGroupFilterCount('SkillGroups') !== 0
                                          ? ` (${this.returnGroupFilterCount('SkillGroups')})`
                                          : ''
                                    }`}
                                 </b>
                              </Typography>
                           </AccordionSummary>
                           <AccordionDetails>
                              <Box display="flex" flexDirection="column">
                                 {this.state.skillGroups.map(skillGroup => (
                                    <FormControl component="fieldset" key={skillGroup._id}>
                                       <FormGroup aria-label="position">
                                          <FormControlLabel
                                             value={skillGroup}
                                             control={
                                                <Checkbox
                                                   color="default"
                                                   checked={this.checkTheCheckbox(
                                                      'SkillGroups',
                                                      skillGroup.Name
                                                   )}
                                                   onChange={() => {
                                                      this.addRemoveAdvancedFilterCriteria(
                                                         'SkillGroups',
                                                         skillGroup.Name
                                                      )
                                                   }}
                                                />
                                             }
                                             label={skillGroup.Name}
                                             labelPlacement="end"
                                          ></FormControlLabel>
                                       </FormGroup>
                                    </FormControl>
                                 ))}
                              </Box>
                           </AccordionDetails>
                        </Accordion>
                        <Divider style={{ background: '#E0E0E0' }} />
                        <Accordion
                           className={this.props.classes.accordion}
                           disabled={
                              !this.state.advancedFilters.find(
                                 filterStatement => filterStatement.split(':')[0] === 'SkillGroups'
                              )
                           }
                           expanded={this.state.expandSkillsAccordion}
                        >
                           <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              classes={{ expanded: this.props.classes.expandedSummary }}
                              onClick={() => this.toggleSkillsAccordion()}
                           >
                              <Typography>
                                 {this.props.t('filterBy')}{' '}
                                 <b>
                                    {`${this.props.t('filterAccordionTextSkills').toUpperCase()} ${
                                       this.returnGroupFilterCount('Skills') !== 0
                                          ? ` (${this.returnGroupFilterCount('Skills')})`
                                          : ''
                                    }`}
                                 </b>
                              </Typography>
                           </AccordionSummary>
                           <AccordionDetails>
                              <Box display="flex" flexDirection="column">
                                 {this.state.advancedFilters.find(
                                    filterStatement =>
                                       filterStatement.split(':')[0] === 'SkillGroups'
                                 ) !== undefined &&
                                    this.state.skills
                                       .filter(skill => {
                                          const skillGroups = this.state.advancedFilters
                                             .find(
                                                filterStatement =>
                                                   filterStatement.split(':')[0] === 'SkillGroups'
                                             )
                                             .split(':')[1]
                                             .split(',')
                                          return skillGroups.includes(skill.GroupName)
                                       })
                                       .map(skill => (
                                          <FormControl component="fieldset" key={skill._id}>
                                             <FormGroup aria-label="position">
                                                <FormControlLabel
                                                   value={skill}
                                                   control={
                                                      <Checkbox
                                                         color="default"
                                                         checked={this.checkTheCheckbox(
                                                            'Skills',
                                                            skill.Name
                                                         )}
                                                         onChange={() => {
                                                            this.addRemoveAdvancedFilterCriteria(
                                                               'Skills',
                                                               skill.Name
                                                            )
                                                         }}
                                                      />
                                                   }
                                                   label={skill.Name}
                                                   labelPlacement="end"
                                                ></FormControlLabel>
                                             </FormGroup>
                                          </FormControl>
                                       ))}
                              </Box>
                           </AccordionDetails>
                        </Accordion>
                        <Divider style={{ background: '#E0E0E0' }} />
                        <Accordion
                           className={this.props.classes.accordion}
                           disabled={
                              !this.state.advancedFilters.find(
                                 filterStatement => filterStatement.split(':')[0] === 'Skills'
                              ) ||
                              !this.state.advancedFilters.find(
                                 filterStatement => filterStatement.split(':')[0] === 'SkillGroups'
                              )
                           }
                           expanded={this.state.expandSkillLevelAccordion}
                        >
                           <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              classes={{ expanded: this.props.classes.expandedSummary }}
                              onClick={() => this.toggleSkillLevelAccordion()}
                           >
                              <Typography>
                                 {this.props.t('filterBy')}{' '}
                                 <b>
                                    {`${this.props
                                       .t('filterAccordionTextSkillRating')
                                       .toUpperCase()} ${
                                       this.returnGroupFilterCount('SkillLevel') !== 0
                                          ? ` (${this.returnGroupFilterCount('SkillLevel')})`
                                          : ''
                                    }`}
                                 </b>
                              </Typography>
                           </AccordionSummary>
                           <AccordionDetails>
                              <Box display="flex" flexDirection="column">
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox('SkillLevel', '1')}
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'SkillLevel',
                                                      '1'
                                                   )
                                                }}
                                             />
                                          }
                                          label={'Trainee'}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox('SkillLevel', '2')}
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'SkillLevel',
                                                      '2'
                                                   )
                                                }}
                                             />
                                          }
                                          label={'Junior'}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox('SkillLevel', '3')}
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'SkillLevel',
                                                      '3'
                                                   )
                                                }}
                                             />
                                          }
                                          label={'Mid'}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox('SkillLevel', '4')}
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'SkillLevel',
                                                      '4'
                                                   )
                                                }}
                                             />
                                          }
                                          label={'Advanced'}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                                 <FormControl component="fieldset">
                                    <FormGroup aria-label="position">
                                       <FormControlLabel
                                          control={
                                             <Checkbox
                                                color="default"
                                                checked={this.checkTheCheckbox('SkillLevel', '5')}
                                                onChange={() => {
                                                   this.addRemoveAdvancedFilterCriteria(
                                                      'SkillLevel',
                                                      '5'
                                                   )
                                                }}
                                             />
                                          }
                                          label={'Senior'}
                                          labelPlacement="end"
                                       ></FormControlLabel>
                                    </FormGroup>
                                 </FormControl>
                              </Box>
                           </AccordionDetails>
                        </Accordion>
                        <Divider style={{ background: '#E0E0E0' }} />
                     </Paper>
                  )}
               </div>
            </Paper>
            <ErrorDialog
               {...{
                  showError: this.state.showError,
                  message: this.state.errorMessage,
                  toggleError: this.toggleError,
               }}
            />

            <ConfirmDialog
               {...{
                  confirmTitle: this.state.confirmTitle,
                  confirmButtonTitle: this.state.confirmButtonTitle,
                  confirmButtonVariant: this.state.confirmButtonVariant,
                  showConfirm: this.state.showConfirm,
                  message: this.state.confirmMessage,
                  handleConfirm: this.state.confirmOnChange,
                  toggleConfirm: this.toggleConfirm,
               }}
            />

            <CreateEditEmployeeModal
               openModal={this.state.showUserDialog}
               toggleModal={() => this.handleCloseUserDialog()}
               userData={this.state.updateUserData}
               performUpdate={this.state.performUpdate}
               reloadUsers={() => this.props.fetchUsers(this.props.userInfo)}
               updateUpdateUserData={employeeID => this.updateUpdateUserData(employeeID)}
               updatePerformUpdate={() => this.updatePerformUpdate()}
               userInfo={this.props.userInfo}
            />
            <ErrorDeletionEmployeeModal
               openModal={this.state.openErrorDeletionModal}
               teams={this.state.errorDeletionModalTeams}
               projects={this.state.errorDeletionModalProjects}
               toggleModal={() => this.toggleErrorDeletionModal(false, [], [], null)}
            />
         </React.Fragment>
      )
   }
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      users: state.users,
      workDay: state.workDay,
   }
}

export default withStyles(styles)(
   withTranslation()(connect(mapStateToProps, { fetchUsers })(UserList))
)
