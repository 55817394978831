import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { Component } from 'react';
import log from './util/log';

class ErrorDialog extends Component{

    render(){
            log.debug(this.props);
            return (
                <Modal show={this.props.showError} onHide={() => this.props.toggleError(false, null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Error</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>{this.props.message}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.props.toggleError(false, null)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            );

    }

}

export default ErrorDialog;
