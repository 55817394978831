/**
 * redux-saga
 * https://redux-saga.js.org/
 * https://redux-saga.js.org/docs/introduction/BeginnerTutorial.html
 * "The mental model is that a saga is like a separate thread in your application that's solely responsible for side effects. redux-saga is a redux middleware"
 */
import { put, takeLatest } from 'redux-saga/effects'
import * as api from '../Api'
import * as types from '../redux/actions/actionTypes'
import log from '../util/log';

export function* fetchNotifs(params) {
  log.debug('Saga reloadNotifs!')

  let notifs = null;
  yield api.fetchNotifs(params)
    .then( (result) => {
      log.debug("Saga fetchNotifs got: " + notifs);
      notifs = result;
    })
    .catch( (error) => {
      log.error("Error occured in fetchNotifs: "+error);
      notifs = null;
    });;

    if (!notifs){
      yield put({ type: types.ERROR, error: "Internal server error, please try again or logout and login again." });
    }
    else{
      yield put({ type: types.FETCH_NOTIFS_SUCCEEDED, notifs })
      yield put({ type: types.ERROR, error: "" });
    }
    
  
}

/**
 * This should be triggered whenever absences change, so we can update the UI such as notifs etc.
 * @param {*} userInfo 
 */
export function* absenceChange(params) {
  log.debug('Saga absence change!');

  yield fetchNotifs(params);
}

export function* callFetchUsers(params) {
  try {
    log.debug('Saga fetchUsers');
    const result = yield api.fetchAllUsers(params);
    if (!result.success) {
      log.debug("Saga failed to fetchAllUsers from api : " + JSON.stringify(result));
      if (result.status === 403) {
        log.debug("Authorization error, redirecting to login screen.");
        yield put({ type: types.LOGOUT });
      }
    }
    else {
      log.debug("saga fetch all users: " + JSON.stringify(result));
      yield put({ type: types.FETCH_USERS_SUCCEEDED, result })
      //log.debug(JSON.stringify(result));
    }


  } catch (err) {
    log.error(err);
  }
}

export function* callFetchActiveUsers(params) {
  try {
    log.debug('Saga fetchActiveUsers');
    const result = yield api.fetchActiveUserOverview(params.userInfo);
    if (!result.success) {
      if (result.status === 403) {
        log.debug("Authorization error, redirecting to login screen.");
        yield put({ type: types.LOGOUT });
      }
    }
    else {
      yield put({ type: types.FETCH_ACTIVE_USERS_SUCCEEDED, result })
    }
  } catch (err) {
    log.error(err);
  }
}

export function* callFetchFutureRequests(params) {
  try {
    log.debug('Saga fetchFutureRequests');
    const result = yield api.fetchFutureAbsenceRequests(params.userInfo, new Date());
    if (!result.success) {
      if (result.status === 403) {
        log.debug("Authorization error, redirecting to login screen.");
        yield put({ type: types.LOGOUT });
      }
    }
    else {
      yield put({ type: types.FETCH_FUTURE_REQUESTS_SUCCEEDED, result })
    }
  } catch (err) {
    log.error(err);
  }
}

export function* callFetchSalaryReports(params) {
  try {
    const result = yield api.fetchSalaryReports(params);

    log.debug("callFetchSalaryReports: " +JSON.stringify(result));

    if (!result.success) {
      if (result.status === 403) {
        log.debug("Authorization error, redirecting to login screen.");
        yield put({ type: types.LOGOUT });
      }
    }
    else {
      yield put({ type: types.FETCH_SALARY_REPORTS_SUCCEEDED, result })
    }
  } catch (err) {
    log.error(err);
  }
}

export function* callFetchCustomerData(params) {
  try {
    const result = yield api.fetchCustomerForAuthUser(params.userInfo);

    log.debug("callFetchCustomerData: " +JSON.stringify(result));

    if (!result.success) {
      if (result.status === 403) {
        log.debug("Authorization error, redirecting to login screen.");
        yield put({ type: types.LOGOUT });
      }
    }
    else {
      yield put({ type: types.FETCH_CUSTOMER_DATA_SUCCEEDED, result })
    }
  } catch (err) {
    log.error(err);
  }
}

/****** WATCHERS ******/

/**
 * Watch for changes in absences. This shoudl trigger a fetch notifications.
 */
export function* watchAbsenceChange() {
  log.debug("CALLING watchAbsenceChange");
  yield takeLatest(types.ABSENCE_CHANGE, absenceChange);
}

export function* watchFetchNotifs() {
  log.debug("CALLING watchFetchNotifs");
  yield takeLatest(types.FETCH_NOTIFS, fetchNotifs);
}

export function* watchFetchUsers() {
  log.debug("CALLING watchFetchUsers");
  yield takeLatest(types.FETCH_USERS, callFetchUsers);
}

export function* watchFetchActiveUsers() {
  log.debug("CALLING watchFetchActiveUsers");
  yield takeLatest(types.FETCH_ACTIVE_USERS, callFetchActiveUsers);
}

export function* watchFetchFutureRequests() {
  log.debug("CALLING watchFetchFutureRequests");
  yield takeLatest(types.FETCH_FUTURE_REQUESTS, callFetchFutureRequests);
}

export function* watchFetchSalaryReports() {
  log.debug("CALLING watchFetchSalaryReports");
  yield takeLatest(types.FETCH_SALARY_REPORTS, callFetchSalaryReports);
}

export function* watchFetchCustomerData() {
  log.debug("CALLING watchFetchCustomerData");
  yield takeLatest(types.FETCH_CUSTOMER_DATA, callFetchCustomerData);
}
