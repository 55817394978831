import React, { Fragment } from 'react'
import TableRow from '@mui/material/TableRow'
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'
import { withTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Tooltip, Typography, IconButton, TableCell } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ProjectsIcon from './ProjectsIcon'
import TeamLeadIcon from './TeamLeadIcon'
import FilterTitle from './FilterTitle'
import { getAndWrapUser } from '../../../../../util/wrappers'
import { getNickOrLabel } from '../../../../../util/userProps'

const useStyles = makeStyles({
   noOutline: {
      outline: 'none !important',
   },
   tableCellItems: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   tooltip: {
      backgroundColor: '#616161',
   },
   childIcon: {
      color: '#FF8038',
   },
   noBorder: {
      border: 'none',
      boxShadow: 'none',
      zIndex: 0,
   },
   crissHover: {
      '&:hover': {
         color: '#',
         backgroundColor: '#FFeedd',
      },
   },
   crossHover: {
      backgroundColor: '#f6f6fd',
      '&:hover': {
         color: '#',
         backgroundColor: '#FFeedd',
      },
   },
   weekIndicator: {
      backgroundColor: '#f1f1fb',
      color: '#bbaaee',
      padding: 2,
      maxWidth: 16,
   },
})

const FilteredRows = ({ t, rowsProps }) => {
   const {
      teams,
      projects,
      weekInfo,
      filter,
      filterArray,
      characterFilter,
      wrappedUserData,
      stripeRowCheck,
      getWeekDayAbsence,
   } = rowsProps

   const classes = useStyles()
   return filterArray.map(item => {
      const activeMemberIds = item.membersDetails
         ? item.membersDetails.filter(member => member.isActive === true).map(member => member._id)
         : item.members.map(member => member)

      return (
         <Fragment key={item.id}>
            <FilterTitle item={item} wrappedUserData={wrappedUserData} />
            {item.members
               .filter(
                  id =>
                     characterFilter(getAndWrapUser(wrappedUserData, id), filter) &&
                     activeMemberIds.includes(id)
               )
               .map((id, index) => (
                  <TableRow
                     key={id}
                     className={clsx(
                        stripeRowCheck(index) ? classes.crossHover : classes.crissHover
                     )}
                  >
                     <TableCell
                        className={clsx(classes.noBorder, classes.tableCellItems)}
                        style={{ minWidth: 200 }}
                     >
                        {getNickOrLabel(getAndWrapUser(wrappedUserData, id))}
                        <div className={classes.tableCellItems}>
                           {getAndWrapUser(wrappedUserData, id).parentalLeave ? (
                              <Tooltip
                                 classes={{ tooltip: classes.tooltip }}
                                 arrow
                                 title={
                                    <Typography variant="caption" style={{ margin: '16px 8px' }}>
                                       {t('inParentalLeave')}
                                    </Typography>
                                 }
                              >
                                 <IconButton className={classes.noOutline} size="large">
                                    <ChildFriendlyIcon className={classes.childIcon} />
                                 </IconButton>
                              </Tooltip>
                           ) : (
                              <ProjectsIcon
                                 userId={id}
                                 projects={projects}
                                 wrappedUserData={wrappedUserData}
                              />
                           )}
                           <TeamLeadIcon
                              userId={id}
                              teams={teams}
                              wrappedUserData={wrappedUserData}
                           />
                        </div>
                     </TableCell>

                     {weekInfo.weeksSorted.map(week =>
                        getWeekDayAbsence(week, getAndWrapUser(wrappedUserData, id), weekInfo)
                     )}

                     <TableCell
                        align={'center'}
                        className={clsx(classes.weekIndicator, classes.noBorder)}
                        style={{ minWidth: 20 }}
                     />
                  </TableRow>
               ))}
         </Fragment>
      )
   })
}

export default withTranslation()(FilteredRows)
