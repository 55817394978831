import { Avatar, AvatarGroup, Box, Button, Divider, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { formatDate } from '../../../../../../util/helpers'
import { useHistory } from 'react-router-dom'
import DatePointer from '../../components/DatePointer'
import {
   calculateBarWidthPercent,
   calculateEffectiveMarginLeftPercent,
   calculateRoadmapStartAndEndDate,
} from '../../helper'

const RoadmapBody = ({ projectFilters, projects, users, period, nameFilter }) => {
   const history = useHistory()
   const redirect = link => {
      history.push(link)
   }

   const [filteredProjects, setFilteredProjects] = useState([])
   const [expandedProjectId, setExpandedProjectId] = useState(null)

   const { roadmapStartDate, roadmapEndDate } = calculateRoadmapStartAndEndDate(period)

   const isOpportunity = project => {
      return project.Status?.includes('Opportunity') || false
   }

   useEffect(() => {
      const filtered = projects.filter(project => {
         const projectStartDate = new Date(project.StartDate)
         const projectEndDate = new Date(project.EndDate)
         const isWithinRoadmap =
            (projectStartDate <= roadmapEndDate && projectEndDate >= roadmapStartDate) ||
            projectStartDate <= roadmapEndDate
         const isActive = projectFilters.active && project.Status === 'Active'
         const isClosed = projectFilters.closed && project.Status === 'Closed'
         const matchesDateAndStatus =
            isWithinRoadmap &&
            (isActive || isClosed || (!projectFilters.active && !projectFilters.closed))

         const nameMatchesFilter = nameFilter
            ? project.Name.toLowerCase().includes(nameFilter.toLowerCase())
            : true

         return matchesDateAndStatus && nameMatchesFilter
      })

      setFilteredProjects(filtered)
   }, [projectFilters, projects, period, nameFilter])

   const getInitials = name => {
      const names = name?.split(/\s+/) || []
      // check the length of the names, as some employees have 3 names while others have 2
      if (names.length == 3) {
         return `${names[0][0]}${names[2][0]}`
      } else if (names.length == 2) {
         return `${names[0][0]}${names[1][0]}`
      }
   }

   const getProfilePicture = employeeId => {
      const user = users.find(user => user._id == employeeId)
      return user?.Avatar
   }

   return (
      <Box sx={{ display: 'flex' }}>
         <Box sx={{ width: 130, height: 500, mb: 3, mt: 3, ml: 3 }}>
            <Box sx={{ border: '4px solid #52B3DD', width: 35, borderRadius: 50 }} />
            <Typography sx={{ fontSize: 12, fontWeight: 500, mb: 3 }}>External</Typography>
            <Box sx={{ border: '4px solid #DA52DD', width: 35, borderRadius: 50 }} />
            <Typography sx={{ fontSize: 12, fontWeight: 500, mb: 3 }}>Internal</Typography>
            <Box sx={{ border: '4px solid #FFB400', width: 35, borderRadius: 50 }} />
            <Typography sx={{ fontSize: 12, fontWeight: 500, mb: 3 }}>Support</Typography>
            <Box sx={{ border: '4px solid #7BC67E', width: 35, borderRadius: 50 }} />
            <Typography sx={{ fontSize: 12, fontWeight: 500, mb: 3 }}>Opportunities</Typography>
            <Divider sx={{ mb: 3 }} />
            <Button
               variant="contained"
               sx={{
                  width: '100%',
                  fontSize: 10,
                  boxShadow: 'none',
                  mb: 2,
                  bgcolor: '#52B3DD',
                  p: '10px 0',
               }}
               onClick={() => redirect(`/reportsAndPlaning/resourcePlanning/availableEmployees`)}
            >
               Free employees
            </Button>
            <Button
               variant="contained"
               sx={{
                  width: '100%',
                  fontSize: 10,
                  boxShadow: 'none',
                  bgcolor: '#52B3DD',
                  p: '10px 0',
               }}
               onClick={() => redirect(`/reportsAndPlaning/resourcePlanning/projectPlanning`)}
            >
               Plan project
            </Button>
         </Box>
         <Box sx={{ width: '100%', padding: '0 24px' }}>
            <Box sx={{ width: '100%', position: 'relative' }}>
               <Box sx={{ zIndex: 1 }}>
                  <DatePointer startDate={roadmapStartDate} endDate={roadmapEndDate} />
               </Box>
               <Box
                  sx={{
                     border: '1px solid #DDE6E9',
                     bgcolor: '#F1F4F5',
                     borderRadius: '8px',
                     width: '100%',
                     height: 500,
                     p: 1,
                     position: 'relative',

                     overflowY: 'scroll',
                     '&::-webkit-scrollbar': {
                        display: 'none',
                     },
                  }}
               >
                  {filteredProjects.map(project => {
                     const projectColor = isOpportunity(project)
                        ? '#7BC67E'
                        : project.Type === 'External'
                          ? '#52B3DD'
                          : project.Type === 'Internal'
                            ? '#DA52DD'
                            : ' #FFB400'
                     const isExpanded = expandedProjectId === project._id

                     const projectWidthPercent = calculateBarWidthPercent(
                        roadmapStartDate,
                        roadmapEndDate,
                        project.EndDate
                     )
                     const effectiveMarginLeftPercent = calculateEffectiveMarginLeftPercent(
                        roadmapStartDate,
                        roadmapEndDate,
                        project.StartDate
                     )

                     return (
                        <Box
                           key={project._id}
                           onClick={() =>
                              setExpandedProjectId(expandedProjectId => {
                                 if (expandedProjectId == project._id) {
                                    return null
                                 }
                                 return project._id
                              })
                           }
                           sx={{
                              marginLeft: `${effectiveMarginLeftPercent}%`,
                              width: `${Math.abs(projectWidthPercent - effectiveMarginLeftPercent)}%`,
                              height: isExpanded ? 150 : 50,
                              bgcolor: 'white',
                              mb: 1,
                              borderRadius: '8px',
                              alignItems: 'center',
                              display: 'flex',
                              p: 1,
                              justifyContent: 'space-between',
                              transition: 'all 0.3s ease',
                              boxShadow: isExpanded ? '0 4px 20px rgba(0,0,0,0.2)' : 'none',
                              overflow: 'hidden',
                              cursor: 'pointer',
                           }}
                        >
                           <Box
                              sx={{
                                 display: 'flex',
                                 minWidth: isExpanded ? 170 : 300,
                                 alignItems: isExpanded ? 'start' : 'center',
                              }}
                           >
                              <Box
                                 sx={{
                                    border: `4px solid ${projectColor}`,
                                    height: isExpanded ? 135 : 35,
                                    transition: 'all 0.3s ease',
                                    borderRadius: 50,
                                    width: '1%',
                                 }}
                              />
                              {isExpanded ? (
                                 <Box sx={{ ml: '8px', height: '100%', textAlign: 'start' }}>
                                    <Typography sx={{ fontSize: 14 }}>{project.Name}</Typography>
                                    <Box sx={{ display: 'flex' }}>
                                       <Typography sx={{ mr: '4px', fontSize: 12 }}>
                                          Start Date:
                                       </Typography>
                                       <Typography
                                          sx={{
                                             fontSize: 12,
                                             color: '#B9B9B9',
                                          }}
                                       >
                                          {formatDate(project.StartDate)}
                                       </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                       <Typography sx={{ mr: '4px', fontSize: 12 }}>
                                          End Date:
                                       </Typography>
                                       <Typography
                                          sx={{
                                             fontSize: 12,
                                             color: !project.EndDate ? 'orange' : '#B9B9B9',
                                          }}
                                       >
                                          {formatDate(project.EndDate)}
                                       </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                       <Typography sx={{ mr: '4px', fontSize: 12 }}>PM:</Typography>
                                       <Typography
                                          sx={{
                                             fontSize: 12,
                                             color: project.PMname === 'N/A' ? 'orange' : '#B9B9B9',
                                          }}
                                       >
                                          {project.PMname}
                                       </Typography>
                                    </Box>
                                 </Box>
                              ) : (
                                 <Box sx={{ ml: '8px' }}>
                                    <Typography sx={{ fontSize: 14 }}>{project.Name}</Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                       <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                          <Typography sx={{ mr: '4px', fontSize: 12 }}>
                                             End:
                                          </Typography>
                                          <Typography
                                             sx={{
                                                fontSize: 12,
                                                color: !project.EndDate ? 'orange' : '#B9B9B9',
                                             }}
                                          >
                                             {formatDate(project.EndDate)}
                                          </Typography>
                                       </Box>
                                       <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                          <Typography sx={{ mr: '4px', fontSize: 12 }}>
                                             PM:
                                          </Typography>
                                          <Typography
                                             sx={{
                                                fontSize: 12,
                                                color:
                                                   project.PMname === 'N/A' ? 'orange' : '#B9B9B9',
                                             }}
                                          >
                                             {project.PMname}
                                          </Typography>
                                       </Box>
                                    </Box>
                                 </Box>
                              )}
                           </Box>
                           {isExpanded ? (
                              <Box>
                                 <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                                    Employees:
                                 </Typography>
                                 {project.MemberDetails.length > 0 ? (
                                    <Box
                                       sx={{
                                          height: 115,
                                          overflowY: 'scroll',
                                          '&::-webkit-scrollbar': {
                                             display: 'none',
                                          },
                                       }}
                                    >
                                       {project.MemberDetails.map((member, index) => {
                                          // show max 5 members
                                          if (index < 5) {
                                             return (
                                                <Box sx={{ display: 'flex' }}>
                                                   <Typography sx={{ fontSize: 12, mr: '3px' }}>
                                                      {member.Name}
                                                   </Typography>{' '}
                                                   {member.Assignments[0]?.ProjectRole && (
                                                      <Typography
                                                         sx={{ fontSize: 12, color: '#B9B9B9' }}
                                                      >
                                                         ({member.Assignments[0]?.ProjectRole})
                                                      </Typography>
                                                   )}
                                                </Box>
                                             )
                                          }
                                       })}
                                       {/* if the members are more than 5, show + n more*/}
                                       {project.MemberDetails.length > 5 && (
                                          <Box sx={{ display: 'flex' }}>
                                             <Typography sx={{ fontSize: 12, mr: '3px' }}>
                                                + {project.MemberDetails.length - 5} more
                                             </Typography>
                                          </Box>
                                       )}
                                    </Box>
                                 ) : (
                                    'None'
                                 )}
                              </Box>
                           ) : (
                              <AvatarGroup
                                 total={project.MemberDetails.length}
                                 spacing={-3}
                                 sx={{
                                    '.MuiAvatarGroup-avatar': {
                                       width: 24,
                                       height: 24,
                                       fontSize: 11,
                                       borderColor: '#52B3DD',
                                       bgcolor: '#52B3DD',
                                    },
                                 }}
                              >
                                 <Avatar
                                    alt={project.MemberDetails[0]?.Name}
                                    src={getProfilePicture(project.MemberDetails[0]?._id)}
                                 >
                                    {getInitials(project.MemberDetails[0]?.Name)}
                                 </Avatar>
                                 <Avatar
                                    alt={project.MemberDetails[1]?.Name}
                                    src={getProfilePicture(project.MemberDetails[1]?._id)}
                                 >
                                    {getInitials(project.MemberDetails[1]?.Name)}
                                 </Avatar>
                              </AvatarGroup>
                           )}
                           {isExpanded && (
                              <Button
                                 variant="outlined"
                                 onClick={e => {
                                    e.stopPropagation()
                                    redirect(
                                       `/reportsAndPlaning/projectReport/projectOverview/${project._id}`
                                    )
                                 }}
                              >
                                 Project Details
                              </Button>
                           )}
                        </Box>
                     )
                  })}
               </Box>
            </Box>
         </Box>
      </Box>
   )
}
export default withTranslation()(RoadmapBody)
