import { withTranslation } from 'react-i18next'
import { CalendarToday, Groups, Handyman } from '@mui/icons-material'
import { Box } from '@mui/material'
import Step from '../ui/Step'

const ProjectPlanningSteps = ({
   step,
   selectedPeriod,
   selectedSkillGroups,
   selectedSkills,
   skillGroups,
   skills,
}) => {
   const showSelectedSkillGroups = () => {
      if (selectedSkillGroups.length == 0) return null
      const selectedSkillGroupNames = selectedSkillGroups.map(selectedSkillGroupId => {
         return skillGroups.find(skillGroup => skillGroup._id == selectedSkillGroupId)?.Name
      })
      return selectedSkillGroupNames.join(', ')
   }

   const showSelectedSkills = () => {
      if (selectedSkills.length == 0) return null
      const selectedSkillNames = selectedSkills.map(skillId => {
         return skills.find(skill => skill._id == skillId)?.Name
      })
      return selectedSkillNames.join(', ')
   }

   return (
      <Box
         sx={{
            width: '100%',
            height: '150px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
         }}
      >
         <Step
            stepNumber={1}
            icon={<CalendarToday />}
            status={step > 2 ? 'done' : step <= 2 ? 'in-progress' : 'todo'} // this is active during state step 1 and 2
         >
            {!selectedPeriod.startDate
               ? 'Select start/end date'
               : selectedPeriod.startDate?.format("Do MMM 'YY")}

            {selectedPeriod.endDate && ` - ${selectedPeriod.endDate?.format("Do MMM 'YY")}`}
         </Step>
         <Step
            stepNumber={2}
            icon={<Groups />}
            status={step > 3 ? 'done' : step == 3 ? 'in-progress' : 'todo'}
         >
            {showSelectedSkillGroups() ?? 'Select a skill group.'}
         </Step>
         <Step
            stepNumber={3}
            icon={<Handyman />}
            status={step > 4 ? 'done' : step == 4 ? 'in-progress' : 'todo'}
         >
            {showSelectedSkills() ?? 'Select a technology.'}
         </Step>
      </Box>
   )
}
export default withTranslation()(ProjectPlanningSteps)
