import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Paper, Tooltip, Typography, Modal } from '@mui/material'
import moment from 'moment/min/moment-with-locales'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { fetchBankHolidays, getTimesheetForEmployee } from '../../../../Api'
import { convertTimesheetHoursAndMinutes } from '../../../../util/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye, faCircle } from '@fortawesome/free-solid-svg-icons'
import TimesheetModalRow from './TimesheetModalRow'

const centerAlign = {
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
}

const modalStyle = {
   position: 'relative',
   maxWidth: '1250px',
   backgroundColor: 'white',
   borderRadius: 4,
   top: `50%`,
   left: `50%`,
   transform: `translate(-50%, -50%)`,
}

const TimesheetModal = ({ t, userInfo, week, employee, handleClose, open }) => {
   const [bankHoliday, setBankHoliday] = useState([])
   const [userTimesheet, setUserTimesheet] = useState(null)
   moment.locale(t('momentLocale'))
   useEffect(() => {
      fetchBankHolidays(userInfo)
         .then(res => {
            setBankHoliday(res)
         })
         .catch(() => console.log('Error fetching holidays.'))
   }, [userInfo])
   useEffect(() => {
      async function getData() {
         const weekStart = new Date(week.dates[0])
         const weekEnd = new Date(week.dates[week.dates.length - 1])
         await getTimesheetForEmployee(
            {
               EmployeeID: employee.EmployeeID,
               WeekStart: weekStart,
               WeekEnd: weekEnd,
            },
            userInfo
         )
            .then(response => {
               setUserTimesheet(response.data.data[0])
            })
            .catch(err => console.log(err))
      }
      if (week) {
         getData()
      }
   }, [week])
   const getReportedHoursForDay = date => {
      let dayHours = 0
      let dayMinutes = 0

      userTimesheet.Projects.forEach(project => {
         const foundAllReportedDates = project.ReportedDates.filter(reportedDate =>
            moment(reportedDate.Date).isSame(date, 'day')
         )
         foundAllReportedDates.forEach(reportedDate => {
            dayHours += Number(reportedDate.ReportedHours.split(':')[0]) || 0
            dayMinutes += Number(reportedDate.ReportedHours.split(':')[1]) || 0
         })
      })

      return convertTimesheetHoursAndMinutes(dayHours, dayMinutes)
   }
   const getReportedHoursForWeek = () => {
      let weekHours = 0
      let weekMinutes = 0

      userTimesheet.Projects.forEach(project => {
         project.ReportedDates.forEach(reportedDate => {
            weekHours += Number(reportedDate.ReportedHours.split(':')[0]) || 0
            weekMinutes += Number(reportedDate.ReportedHours.split(':')[1]) || 0
         })
      })

      return convertTimesheetHoursAndMinutes(weekHours, weekMinutes)
   }
   const returnWeekLabel = () => {
      const firstDateOfTheChosenWeek = moment(week.dates[0]).format('Do MMM')
      const lastDateOfTheChosenWeek = moment(week.dates[week.dates.length - 1]).format('Do MMM')
      const year = moment(week.dates[0]).year()
      return `${firstDateOfTheChosenWeek} - ${lastDateOfTheChosenWeek} ${year}`
   }
   const isDateAHoliday = date =>
      bankHoliday.find(holiday => moment(date).isSame(holiday.Date, 'day'))
   const isToday = date => moment(date).isSame(new Date(Date.now()), 'day')
   const dayHeader = weekDate => {
      if (new Date(weekDate).getDay() === 6 || new Date(weekDate).getDay() === 0) {
         return (
            <>
               {`${getReportedHoursForDay(weekDate)}${t('h')}`}
               <Tooltip title={t('timesheetTooltipMessageWeekend')}>
                  <Box>
                     <FontAwesomeIcon icon={faCircle} color={'#B7B7B7'} style={{ marginLeft: 6 }} />
                  </Box>
               </Tooltip>
            </>
         )
      }
      const foundHoliday = isDateAHoliday(weekDate)
      if (foundHoliday) {
         return (
            <>
               {`${getReportedHoursForDay(weekDate)}${t('h')}`}
               <Tooltip title={foundHoliday.Name}>
                  <Box>
                     <FontAwesomeIcon
                        icon={faBullseye}
                        color={'#e60000'}
                        style={{ marginLeft: 6 }}
                     />
                  </Box>
               </Tooltip>
            </>
         )
      }

      if (employee.absences) {
         for (let element of employee.absences) {
            if (
               new Date(element.StartDate).getDate() <= new Date(weekDate).getDate() &&
               new Date(weekDate).getDate() <= new Date(element.EndDate).getDate()
            ) {
               return (
                  <>
                     {`${getReportedHoursForDay(weekDate)}${t('h')}`}
                     <Tooltip title={t(`${element.AbsenceType}`)}>
                        <Box>
                           <FontAwesomeIcon
                              icon={faCircle}
                              color={'#e60000'}
                              style={{ marginLeft: 6 }}
                           />
                        </Box>
                     </Tooltip>
                  </>
               )
            }
         }
      }

      return `${getReportedHoursForDay(weekDate)}${t('h')}`
   }
   return (
      <Modal
         open={open}
         onClose={handleClose}
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
      >
         <Box style={modalStyle}>
            {userTimesheet?.Projects.length > 0 ? (
               <Box
                  style={{
                     padding: '15px 0px',
                     borderRadius: 5,
                     backgroundColor: 'white',
                  }}
               >
                  <Box
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: 10,
                     }}
                  >
                     <b style={{ color: '#454545', fontSize: 17, fontFamily: 'sans-serif' }}>{`${
                        employee.FirstName
                     } ${employee.LastName}  ${t('timesheet')}`}</b>
                     <Typography sx={{ color: '#ABABAB' }}>{returnWeekLabel()}</Typography>
                  </Box>
                  <Paper
                     style={{
                        maxHeight: 500,
                        boxShadow: 'none',
                        borderTop: '1px solid rgba(224, 224, 224, 1)',
                        borderRadius: 0,
                        overflow: 'auto',
                     }}
                  >
                     <Grid
                        container
                        style={{
                           borderBottom: '1px solid rgba(224, 224, 224, 1)',
                           minHeight: 40,
                           position: 'sticky',
                           top: 0,
                           zIndex: 3,
                           backgroundColor: 'white',
                        }}
                     >
                        <Grid
                           item
                           xs={4}
                           style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'center',
                              paddingLeft: 52,
                           }}
                        >
                           <Typography style={{ fontWeight: 700 }}>
                              {t('timesheetProjectsText')}
                           </Typography>
                        </Grid>
                        {week.dates.map(weekDate => {
                           const dateString = moment(weekDate).format('ddd')
                           const dateStringFirstCapital =
                              dateString.charAt(0).toUpperCase() + dateString.slice(1)
                           return (
                              <Grid
                                 key={new Date(weekDate).toDateString()}
                                 style={
                                    isToday(weekDate)
                                       ? {
                                            backgroundColor: '#8ACCE6',
                                            borderBottom: '3px solid red',
                                            marginBottom: '-3px',
                                            ...centerAlign,
                                         }
                                       : { ...centerAlign }
                                 }
                                 item
                                 xs={1}
                              >
                                 {dateStringFirstCapital}, {moment(weekDate).format('D')}
                              </Grid>
                           )
                        })}
                        <Grid
                           item
                           xs={1}
                           style={{
                              ...centerAlign,
                           }}
                        >
                           <Typography sx={{ fontWeight: 600 }}>{t('totalAmountHours')}</Typography>
                        </Grid>
                     </Grid>
                     <Grid
                        container
                        style={{
                           borderBottom: '1px solid rgba(224, 224, 224, 1)',
                           minHeight: 40,
                           position: 'sticky',
                           top: 40,
                           zIndex: 3,
                           backgroundColor: 'white',
                        }}
                     >
                        <Grid item xs={4} sx={{ alignSelf: 'center' }}>
                           <Typography sx={{ fontWeight: 700, ml: '50px' }}>
                              Total hours per day
                           </Typography>
                        </Grid>
                        {week.dates.map(weekDate => (
                           <Grid
                              key={new Date(weekDate).toDateString()}
                              style={
                                 isToday(weekDate)
                                    ? {
                                         backgroundColor: '#8ACCE6',
                                         borderBottom: '2px solid #8ACCE6',
                                         marginBottom: '-2px',
                                         ...centerAlign,
                                      }
                                    : { ...centerAlign }
                              }
                              item
                              xs={1}
                           >
                              <Typography sx={{ fontWeight: 700, display: 'flex' }}>
                                 {dayHeader(weekDate)}
                              </Typography>
                           </Grid>
                        ))}
                        <Grid
                           item
                           xs={1}
                           style={{
                              ...centerAlign,
                           }}
                        >
                           <Typography sx={{ fontWeight: 600 }}>
                              {`${getReportedHoursForWeek()}${t('h')}`}
                           </Typography>
                        </Grid>
                     </Grid>
                     {userTimesheet.Projects.map(project => (
                        <React.Fragment key={project._id ? project._id : uuidv4()}>
                           <TimesheetModalRow
                              project={project}
                              week={week}
                              userTimesheet={userTimesheet}
                              getReportedHoursForDay={getReportedHoursForDay}
                           />
                        </React.Fragment>
                     ))}
                  </Paper>
                  <Box style={{ display: 'flex', justifyContent: 'end', marginTop: '13px' }}>
                     <Button
                        className={'ml-2'}
                        variant="contained"
                        sx={{ backgroundColor: '#008DC6', mr: 2, borderRadius: '4px' }}
                        onClick={() => handleClose()}
                     >
                        {t('close')}
                     </Button>
                  </Box>
               </Box>
            ) : (
               <Box
                  style={{
                     marginLeft: 'auto',
                     marginRight: 'auto',
                     marginTop: 50,
                     width: 600,
                  }}
               ></Box>
            )}
         </Box>
      </Modal>
   )
}
const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}

export default withTranslation()(connect(mapStateToProps)(TimesheetModal))
