import React from 'react'
import { SvgIcon } from '@mui/material'

const PerformanceIcon = props => {
   return (
      <SvgIcon {...props}>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110 110">
            <path
               d="M50,98.9c24.2,0,43.9-19.7,43.9-43.9c0-10.8-3.9-20.6-10.4-28.3l1-1h5.8c1,0,1.9-0.4,2.6-1.1l7.3-7.3
	c1.1-1.1,1.4-2.6,0.8-4c-0.5-1.4-1.9-2.3-3.4-2.3h-3.7V7.5c0-1.5-0.9-2.8-2.3-3.4c-1.4-0.5-2.9-0.3-4,0.8l-7.3,7.3
	c-0.7,0.7-1.1,1.6-1.1,2.6v5.8l-1,1C70.7,15.1,60.8,11.2,50,11.2C25.8,11.2,6.2,30.8,6.2,55C6.2,79.2,25.8,98.9,50,98.9L50,98.9z
	 M50,18.5c8.8,0,16.8,3.1,23.1,8.3l-7.8,7.8c-4.3-3.2-9.5-5.1-15.3-5.1c-14.1,0-25.6,11.5-25.6,25.6S35.9,80.6,50,80.6
	S75.6,69.1,75.6,55c0-5.7-1.9-11-5.1-15.3l7.8-7.8c5.2,6.3,8.3,14.4,8.3,23.1c0,20.1-16.4,36.5-36.5,36.5S13.5,75.1,13.5,55
	C13.5,34.9,29.9,18.5,50,18.5L50,18.5z M50,62.3c4,0,7.3-3.3,7.3-7.3c0-0.7-0.1-1.3-0.3-1.9l8.2-8.2c1.9,2.9,3,6.4,3,10.1
	c0,10.1-8.2,18.3-18.3,18.3S31.7,65.1,31.7,55S39.9,36.7,50,36.7c3.7,0,7.2,1.1,10.1,3l-8.2,8.2c-0.6-0.1-1.2-0.3-1.9-0.3
	c-4,0-7.3,3.3-7.3,7.3S46,62.3,50,62.3L50,62.3z"
               fill={props.color}
            />
         </svg>
      </SvgIcon>
   )
}

export default PerformanceIcon
