import * as types from './actionTypes'

export const setError = (error) => ({
    type: types.ERROR,
    error: error
})

export const setTimeZone = (timezone) => ({
    type: types.TIMEZONE,
    timezone: timezone
})

export const authenticate = (userInfo) => ({
    type: types.AUTHENTICATE,
    userInfo: userInfo,
    loggedIn: true
})

export const logout = () => ({
    type: types.LOGOUT,
    userInfo: null,
    loggedIn: false
})

// action to trigger the fetch notif saga
export const fetchNotifs = (userInfo) => ({
    type: types.FETCH_NOTIFS,
    userInfo: userInfo
})

export const absenceChange = (userInfo) => ({
    type: types.ABSENCE_CHANGE,
    userInfo: userInfo
})

export const fetchUsers = (userInfo) => ({
    type: types.FETCH_USERS,
    userInfo: userInfo
})

export const fetchActiveUsers = (userInfo) => ({
    type: types.FETCH_ACTIVE_USERS,
    userInfo: userInfo
})

export const fetchFutureRequests = (userInfo) => ({
    type: types.FETCH_FUTURE_REQUESTS,
    userInfo: userInfo
})

export const fetchSalaryReports = (userInfo) => ({
    type: types.FETCH_SALARY_REPORTS,
    userInfo: userInfo
})
export const persistUserInfo = (userInfo) => ({
    type: types.PERSIST_USERINFO,
    userInfo: userInfo
})
export const fetchCustomerData = (userInfo) => ({
    type: types.FETCH_CUSTOMER_DATA,
    userInfo: userInfo
})
