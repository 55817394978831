import React, { useEffect, useCallback } from 'react'
import themeConfig from '../theme'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles'
import { withTranslation } from 'react-i18next'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Alert from 'react-bootstrap/Alert'
import Paper from '@mui/material/Paper'
import { fetchSuperAdmins, addSuperAdmin, removeSuperAdmin } from '../Api'
import log from '../util/log'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import { Input, Typography } from '@mui/material'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { styles } = themeConfig()

const SuperAdmins = ({ ...props }) => {
   const { t, userInfo } = props

   const [admins, setAdmins] = React.useState([])
   const [showModal, setShowModal] = React.useState(false)

   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      variant: 'info',
   })
   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ open: false })
   }

   const loadData = useCallback(async () => {
      try {
         const result = await fetchSuperAdmins(userInfo)
         setAdmins(result.data)
      } catch (err) {
         log.error(err)
      }
   }, [userInfo])

   useEffect(() => {
      loadData()
   }, [loadData])

   const clickRemove = async email => {
      try {
         const response = await removeSuperAdmin(email, userInfo)
         if (!response.data.success) {
            setSnackbarState({
               open: true,
               message: response.data.message,
               variant: 'danger',
            })
            return
         }

         setSnackbarState({
            open: true,
            message: t('removedSuperAdminMsg'),
            variant: 'success',
         })
         loadData()
      } catch (err) {
         setSnackbarState({
            open: true,
            message: err,
            variant: 'danger',
         })
      }
   }

   const AddModal = props => {
      const [email, setEmail] = React.useState('')
      const [alert, setAlert] = React.useState({
         message: null,
         variant: null,
      })

      const clickAdd = async email => {
         try {
            const response = await addSuperAdmin(email, userInfo)
            if (!response.data.success) {
               setAlert({
                  message: response.data.message,
                  variant: 'danger',
               })
               return
            }

            setShowModal(false)
            setSnackbarState({
               open: true,
               message: t('addedSuperAdminMsg'),
               variant: 'success',
            })
            loadData()
         } catch (err) {
            setAlert({
               message: err,
               variant: 'danger',
            })
         }
      }

      return (
         <React.Fragment>
            <Dialog
               open={showModal}
               onClose={event => this.handleClose(event)}
               aria-labelledby="form-dialog-title"
            >
               <DialogTitle id="form-dialog-title">{t('newSuperAdmin')}</DialogTitle>
               <DialogContent>
                  <DialogContentText>{t('newSuperAdminInfo')}</DialogContentText>
                  {alert.message != null ? (
                     <Alert
                        key={'dialogAlert'}
                        variant={'danger'}
                        onClose={() => setAlert({ message: null, variant: null })}
                        dismissible
                     >
                        {alert.message}
                     </Alert>
                  ) : (
                     ''
                  )}
                  <Typography style={{ marginTop: 10 }}>
                     <b>{t('emailLabel')}:</b>
                  </Typography>
                  <Input
                     autoComplete="off"
                     style={{ width: 500 }}
                     placeholder={t('typeHereToChange')}
                     onChange={event => {
                        setEmail(event.target.value)
                     }}
                  ></Input>
               </DialogContent>
               <DialogActions>
                  <Button
                     size="small"
                     variant="contained"
                     color="secondary"
                     onClick={() => setShowModal(false)}
                  >
                     {t('closeButtonTitle')}
                  </Button>
                  <Button
                     size="small"
                     variant="contained"
                     color="primary"
                     onClick={() => clickAdd(email)}
                  >
                     {t('newSuperAdmin')}
                  </Button>
               </DialogActions>
            </Dialog>
         </React.Fragment>
      )
   }

   return (
      <React.Fragment>
         <AddModal></AddModal>
         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} variant={snackbarState.variant}>
               {snackbarState.message}
            </Alert>
         </Snackbar>
         <Paper className="contentPaper" style={{ maxWidth: 1200, boxShadow: 'none' }}>
            <div className={'contentWrapper'}>
               <div style={{ padding: 10 }}>
                  <div>
                     <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => setShowModal(true)}
                     >
                        {t('newSuperAdmin')}
                     </Button>
                  </div>
               </div>

               <TableContainer
                  className="employmentTable"
                  component={Paper}
                  style={{ maxHeight: 500 }}
               >
                  <Table size="small">
                     <TableHead>
                        <TableRow>
                           <TableCell align="left"></TableCell>
                           <TableCell align="center">{t('CustomerID')}</TableCell>
                           <TableCell align="center">{t('ID')}</TableCell>
                           <TableCell align="center">{t('emailLabel')}</TableCell>
                           <TableCell align="center">{t('nameLabel')}</TableCell>
                           <TableCell align="center">{t('lastNameLabel')}</TableCell>
                        </TableRow>
                     </TableHead>

                     <TableBody>
                        {admins.map((admin, index) => (
                           <React.Fragment key={index}>
                              <TableRow key={'row_' + index}>
                                 <TableCell align="left">
                                    <FontAwesomeIcon
                                       style={{ marginLeft: 2 }}
                                       icon={faTrash}
                                       onClick={() => clickRemove(admin.CompanyEmail)}
                                    />
                                 </TableCell>
                                 <TableCell align="center">{admin.CustomerID}</TableCell>
                                 <TableCell align="center">{admin.EmployeeID}</TableCell>
                                 <TableCell align="center">{admin.CompanyEmail}</TableCell>
                                 <TableCell align="center">{admin.FirstName}</TableCell>
                                 <TableCell align="center">{admin.MiddleName}</TableCell>
                                 <TableCell align="center">{admin.LastName}</TableCell>
                              </TableRow>
                           </React.Fragment>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            </div>
         </Paper>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
   }
}

export default withStyles(styles)(withTranslation()(connect(mapStateToProps)(SuperAdmins)))
