import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Button, Modal } from '@mui/material'
import { withTranslation } from 'react-i18next'

function getModalStyle() {
   return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
   }
}

const useStyles = makeStyles(theme => ({
   paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
   },
   body: {
      marginBottom: '20px',
   },
   buttonsPosition: {
      float: 'right',
   },
}))

const DeleteModal = ({ title, body, helperBody, openModal, toggleModal, deleteAction, t }) => {
   const classes = useStyles()
   const [modalStyle] = React.useState(getModalStyle)

   return (
      <Modal
         open={openModal}
         onClose={toggleModal}
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
      >
         <div style={modalStyle} className={classes.paper}>
            <div className={classes.body}>
               <h5 id="simple-modal-title">{title}</h5>
            </div>
            <div>{body}</div>
            {helperBody !== '' && <div className={'my-1'}>{helperBody}</div>}
            <div className={classes.buttonsPosition + ' mt-3'}>
               <Button
                  onClick={() => {
                     toggleModal()
                     deleteAction()
                  }}
                  size="small"
                  variant="contained"
                  color="primary"
                  className={'mx-1'}
               >
                  {t('deleteButtonTitle')}
               </Button>
               <Button
                  className={'mx-1'}
                  onClick={toggleModal}
                  size="small"
                  variant="contained"
                  color="secondary"
               >
                  {t('cancelButtonTitle')}
               </Button>
            </div>
         </div>
      </Modal>
   )
}

export default withTranslation()(DeleteModal)
