// Helper file to read from props.userInfo and check if a NEW setting added to the
// model is missing on a logged in user. To prevent "white error" screen after a
// code merge and re-deploy of servers

export const getNumberOfWeeksShown = userInfo => {
   if (userInfo.Settings.NumberOfWeeksShown) {
      return userInfo.Settings.NumberOfWeeksShown
   } else {
      return 2 // Failsafe default value
   }
}

export const getNickOrLabel = person => {
   return person?.nick
      ? person?.nick
      : person?.label
      ? person?.label
      : person?.FirstName
      ? person?.FirstName + ' ' + person?.LastName
      : person?.FirstName
}
