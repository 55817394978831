import React, { useEffect, useState } from 'react'
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Checkbox,
   FormControlLabel,
   FormGroup,
   Grid,
   IconButton,
   MenuItem,
   Select,
   Slider,
   TextField,
   Tooltip,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import DatePicker from 'react-datepicker'
import { parseJSON } from 'date-fns'
import InfoIcon from '@mui/icons-material/Info'
import makeStyles from '@mui/styles/makeStyles'
import { withTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import moment from 'moment/moment'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import Button from '@mui/material/Button'

const useStyles = makeStyles(theme => ({
   paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
   },
   body: {
      marginBottom: '20px',
   },
   buttonsPosition: {
      float: 'right',
   },
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
   select: {
      marginTop: '0.25rem',
      width: '100%',
      height: '40px',
      fontSize: '0.875rem',
      borderRadius: '4px',
      '& .MuiOutlinedInput-input': {
         padding: '5px',
         paddingBottom: '2px',
         paddingLeft: '10px',
      },
   },
   notes: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
   },
   autocomplete: {
      marginTop: '0.25rem',
      width: '100%',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiInputBase-root': {
         height: '40px',
         padding: 0,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
         padding: 0,
         paddingLeft: '10px',
      },
   },
   datepickerField: {
      '& .react-datepicker-wrapper': {
         width: '100%',
      },
   },
   infoIcon: {
      fontSize: '18px',
      color: 'gray',
      marginLeft: '5px',
      marginTop: '-5px',
   },
   actionButtonContainer: {
      display: 'flex',
      height: 55,
      alignItems: 'center',
      justifyContent: 'center',
   },
}))

const Assignment = ({
   assignment,
   updateUserDetailsData,
   selectedProject,
   isInNewAssignmentProcess,
   onDeleteAssignment,
   index,
   userDetailsData,
   isPastMember,
   t,
   activeUsers,
   inactiveUsers,
   userAvailability,
}) => {
   const [disableDailyRateSelect, setDisableDailyRateSelect] = useState(false)
   const [expandAccordion, setExpandAccordion] = useState(false)
   const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
   const classes = useStyles()
   const allUsers = activeUsers.concat(inactiveUsers)
   const findUser = allUsers.find(user => user._id === userDetailsData._id)
   const maximumAvailablePercentsPerProject = userAvailability
      ? 100 -
        userAvailability
           .filter(
              userAvailabilityProject => userAvailabilityProject.projectId !== selectedProject._id
           )
           .reduce((accumulator, userAvailabilityProject) => {
              accumulator += Number(userAvailabilityProject?.workPercent ?? 0)
              return accumulator
           }, 0)
      : 0

   useEffect(() => {
      if (
         isInNewAssignmentProcess &&
         assignment.hasOwnProperty('generatedId') &&
         Object.keys(assignment).length === 1
      ) {
         toggleAccordion()
      }
   }, [isInNewAssignmentProcess, assignment])

   let rateOptions

   if (selectedProject.Type === 'Internal' || selectedProject.FixedPrice) {
      rateOptions = ['A1', 'A2', 'A3', 'A4', 'A5', 'A6']
   } else if (selectedProject.Type === 'External' || selectedProject.FixedPrice === false) {
      rateOptions = ['E1', 'E2', 'E3', 'E4', 'E5', 'E6']
   } else {
      rateOptions = []

      disableDailyRateSelect === false && setDisableDailyRateSelect(true)
   }

   const toggleDeleteConfirmation = () => setShowDeleteConfirmation(prevState => !prevState)
   const toggleAccordion = () => setExpandAccordion(prevState => !prevState)

   const startDateAssignmentMinDate = () => {
      if (index === userDetailsData.Assignments.length - 1) {
         if (selectedProject?.StartDate) {
            return parseJSON(selectedProject.StartDate)
         } else {
            return null
         }
      } else if (userDetailsData.Assignments[index + 1]?.EndDate) {
         return parseJSON(userDetailsData.Assignments[index + 1]?.EndDate)
      } else {
         return null
      }
   }

   const startDateAssignmentMaxDate = () => {
      if (assignment?.EndDate) {
         return parseJSON(assignment.EndDate)
      } else {
         return null
      }
   }

   const endDateAssignmentMinDate = () => {
      if (assignment?.StartDate) {
         return parseJSON(assignment.StartDate)
      } else {
         return null
      }
   }

   const endDateAssignmentMaxDate = () => {
      if (userDetailsData.Assignments[index - 1]?.StartDate) {
         return parseJSON(userDetailsData.Assignments[index - 1].StartDate)
      } else {
         return null
      }
   }

   return (
      <Accordion expanded={expandAccordion}>
         <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleAccordion}>
            <Typography variant={'h6'} style={{ fontSize: 16 }}>{`${
               assignment.StartDate
                  ? moment(assignment.StartDate).format('DD.MM.YYYY')
                  : t('editProjectMemberAssignmentStartDateText')
            } - ${
               assignment?.EndDate &&
               new Date(assignment.EndDate) < new Date(findUser.ActiveEmployment.EndDate)
                  ? moment(assignment.EndDate).format('DD.MM.YYYY')
                  : findUser?.ActiveEmployment?.EndDate &&
                    new Date(assignment.EndDate) > new Date(findUser.ActiveEmployment.EndDate)
                  ? moment(findUser.ActiveEmployment.EndDate).format('DD.MM.YYYY')
                  : findUser.ActiveEmployment.EndDate
                  ? moment(findUser.ActiveEmployment.EndDate).format('DD.MM.YYYY')
                  : assignment?.EndDate
                  ? moment(assignment.EndDate).format('DD.MM.YYYY')
                  : assignment.StartDate
                  ? t('editProjectMemberAssignmentPresentText')
                  : t('editProjectMemberAssignmentEndDateText')
            }`}</Typography>
         </AccordionSummary>
         <AccordionDetails>
            <form>
               <Grid container direction="row" alignItems="flex-start" spacing={1}>
                  <Grid item xs={6} className={'pb-1 mb-1'}>
                     <Typography variant="body2">
                        {t('manageMemberDetailsStartDateField')}
                     </Typography>
                     <div className={classes.datepickerField}>
                        <DatePicker
                           selected={assignment?.StartDate ? parseJSON(assignment.StartDate) : null}
                           onChange={date => updateUserDetailsData('StartDate', date)}
                           dateFormat="yyyy-MM-dd"
                           locale={t('datepickerLocale')}
                           showWeekNumbers
                           yearDropdownItemNumber={3}
                           showMonthDropdown={true}
                           showYearDropdown={true}
                           disabled={isPastMember}
                           className={'datepicker-field mt-1'}
                           openToDate={
                              assignment?.StartDate ? parseJSON(assignment.StartDate) : null
                           }
                           minDate={startDateAssignmentMinDate()}
                           maxDate={
                              findUser.ActiveEmployment.EndDate
                                 ? parseJSON(findUser.ActiveEmployment.EndDate)
                                 : startDateAssignmentMaxDate()
                           }
                           placeholderText={t('manageMemberDetailsStartDateField')}
                        />
                     </div>
                  </Grid>
                  <Grid item xs={6} className={'pb-1 mb-1'}>
                     <Typography variant="body2">{t('manageMemberDetailsEndDateField')}</Typography>
                     <div className={classes.datepickerField}>
                        <Tooltip
                           disableHoverListener={Boolean(assignment?.StartDate)}
                           disableFocusListener={Boolean(assignment?.StartDate)}
                           disableTouchListener={Boolean(assignment?.StartDate)}
                           title={t('editProjectMemberAssignmentDisabledEndDateText')}
                        >
                           <Box>
                              <DatePicker
                                 selected={
                                    assignment?.EndDate &&
                                    new Date(assignment.EndDate) <
                                       new Date(findUser.ActiveEmployment.EndDate)
                                       ? parseJSON(assignment.EndDate)
                                       : findUser?.ActiveEmployment?.EndDate &&
                                         new Date(assignment.EndDate) >
                                            new Date(findUser.ActiveEmployment.EndDate)
                                       ? parseJSON(findUser.ActiveEmployment.EndDate)
                                       : findUser.ActiveEmployment.EndDate
                                       ? parseJSON(findUser.ActiveEmployment.EndDate)
                                       : assignment?.EndDate
                                       ? parseJSON(assignment.EndDate)
                                       : null
                                 }
                                 onChange={date => updateUserDetailsData('EndDate', date)}
                                 dateFormat="yyyy-MM-dd"
                                 locale={t('datepickerLocale')}
                                 showWeekNumbers
                                 yearDropdownItemNumber={3}
                                 showMonthDropdown={true}
                                 showYearDropdown={true}
                                 className={'datepicker-field mt-1'}
                                 disabled={!assignment?.StartDate || isPastMember}
                                 openToDate={
                                    assignment?.EndDate ? parseJSON(assignment.EndDate) : null
                                 }
                                 minDate={endDateAssignmentMinDate()}
                                 maxDate={
                                    findUser.ActiveEmployment.EndDate
                                       ? parseJSON(findUser.ActiveEmployment.EndDate)
                                       : endDateAssignmentMaxDate()
                                 }
                                 placeholderText={t('manageMemberDetailsEndDateField')}
                              />
                           </Box>
                        </Tooltip>
                     </div>
                  </Grid>
                  <Grid item xs={6} className={'pb-1 my-1'}>
                     <Typography variant="body2">
                        {t('manageMemberDetailsDailyRateField')}
                        <Tooltip
                           title={t('manageMemberDetailsTooltipText')}
                           placement="top"
                           leaveDelay={700}
                           arrow
                        >
                           <InfoIcon className={`${classes.infoIcon}`} />
                        </Tooltip>
                     </Typography>
                     <Select
                        value={assignment?.DailyRate ? assignment.DailyRate : ''}
                        onChange={event => {
                           updateUserDetailsData('DailyRate', event.target.value)
                        }}
                        variant={'outlined'}
                        className={classes.select}
                        placeholder={t('manageMemberDetailsDailyRateField')}
                        disabled={disableDailyRateSelect}
                        readOnly={isPastMember}
                     >
                        {rateOptions.map(rateOption => (
                           <MenuItem value={rateOption} key={rateOption}>
                              {rateOption}
                           </MenuItem>
                        ))}
                     </Select>
                  </Grid>
                  <Grid item xs={6} className={'pb-1 my-1'}>
                     <Typography variant="body2">
                        {t('manageMemberDetailsWorkPercentField')}
                     </Typography>

                     <Box sx={{ p: '9px 10px 0' }}>
                        <Slider
                           aria-label="Work Percent"
                           onChange={event =>
                              updateUserDetailsData('WorkPercent', event.target.value)
                           }
                           value={assignment?.WorkPercent ? Number(assignment.WorkPercent) : 0}
                           step={5}
                           min={0}
                           max={maximumAvailablePercentsPerProject}
                           valueLabelDisplay={isPastMember ? 'on' : 'auto'}
                           disabled={isPastMember}
                        />
                     </Box>
                  </Grid>
                  <Grid item xs={6} className={'pb-1 my-1'}>
                     <Typography variant="body2">{t('manageMemberDetailsLevelField')}</Typography>
                     <Select
                        value={assignment?.Level ? assignment.Level : ''}
                        onChange={event => updateUserDetailsData('Level', event.target.value)}
                        variant={'outlined'}
                        className={classes.select}
                        placeholder={t('manageMemberDetailsLevelField')}
                        readOnly={isPastMember}
                     >
                        <MenuItem value={'Trainee'}>Trainee</MenuItem>
                        <MenuItem value={'Junior'}>Junior</MenuItem>
                        <MenuItem value={'Mid'}>Mid</MenuItem>
                        <MenuItem value={'Advanced'}>Advanced</MenuItem>
                        <MenuItem value={'Senior'}>Senior</MenuItem>
                     </Select>
                  </Grid>
                  <Grid item xs={6} className={'pb-1 my-1'}>
                     <Typography variant="body2">
                        {t('manageMemberDetailsProjectRoleField')}
                     </Typography>
                     <Select
                        value={assignment?.ProjectRole ? assignment.ProjectRole : ''}
                        onChange={event => updateUserDetailsData('ProjectRole', event.target.value)}
                        variant={'outlined'}
                        className={classes.select}
                        placeholder={t('manageMemberDetailsProjectRoleField')}
                        readOnly={isPastMember}
                     >
                        <MenuItem value={'Frontend Developer'}>Frontend Developer</MenuItem>
                        <MenuItem value={'Backend Developer'}>Backend Developer</MenuItem>
                        <MenuItem value={'Full Stack Developer'}>Full Stack Developer</MenuItem>
                        <MenuItem value={'Technical Lead'}>Technical Lead</MenuItem>
                        <MenuItem value={'Java Developer'}>Java Developer</MenuItem>
                        <MenuItem value={'Hybris Developer'}>Hybris Developer</MenuItem>
                        <MenuItem value={'QA Specialist'}>QA Specialist</MenuItem>
                        <MenuItem value={'Product Owner'}>Product Owner</MenuItem>
                        <MenuItem value={'Business Analyst'}>Business Analyst</MenuItem>
                        <MenuItem value={'Project Manager'}>Project Manager</MenuItem>
                        <MenuItem value={'UX/UI Designer'}>UX/UI Designer</MenuItem>
                        <MenuItem value={'Cloud Architect'}>Cloud Architect</MenuItem>
                        <MenuItem value={'Solutions Architect'}>Solutions Architect</MenuItem>
                        <MenuItem value={'ByD Consultant'}>ByD Consultant</MenuItem>
                     </Select>
                  </Grid>
                  <Grid item xs={12} className={'pb-1 my-1'}>
                     <Typography variant="body2">{t('projectManagementCommentText')}</Typography>
                     <TextField
                        id="outlined-multiline-static"
                        multiline
                        minRows={2}
                        variant="outlined"
                        className={classes.notes}
                        value={assignment?.Comment ? assignment.Comment : ''}
                        onChange={e => updateUserDetailsData('Comment', e.target.value)}
                        placeholder={t('projectManagementCommentText')}
                        disabled={isPastMember}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <FormGroup>
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={assignment.SubTasksPermission || false}
                                 onChange={e =>
                                    updateUserDetailsData('SubTasksPermission', e.target.checked)
                                 }
                              />
                           }
                           label={t('manageMemberDetailsPermissionCheckbox')}
                           sx={{
                              '&:hover': {
                                 color: '#52B3DD',
                              },
                           }}
                        />
                        <FormControlLabel
                           control={
                              <Checkbox
                                 checked={assignment.TasksBillability || false}
                                 onChange={e =>
                                    updateUserDetailsData('TasksBillability', e.target.checked)
                                 }
                                 disabled={!assignment.SubTasksPermission}
                              />
                           }
                           label={t('manageMemberDetailsBillabilityCheckbox')}
                           sx={{
                              '&:hover': {
                                 color: '#52B3DD',
                              },
                           }}
                        />
                     </FormGroup>
                  </Grid>

                  {!isPastMember && (
                     <Grid item xs={12}>
                        <Box className={classes.actionButtonContainer}>
                           {showDeleteConfirmation ? (
                              <Box className={'text-center'}>
                                 <Typography>
                                    {t('deleteProjectAssignmentConfirmationText')}
                                 </Typography>
                                 <IconButton onClick={onDeleteAssignment} size="large">
                                    <CheckIcon />
                                 </IconButton>
                                 <IconButton onClick={toggleDeleteConfirmation} size="large">
                                    <CloseIcon />
                                 </IconButton>
                              </Box>
                           ) : (
                              <Button
                                 onClick={toggleDeleteConfirmation}
                                 variant={'outlined'}
                                 sx={{ color: '#E58C9C', borderColor: '#E58C9C' }}
                                 color={'secondary'}
                              >
                                 <DeleteIcon sx={{ mr: 0.5 }} />
                                 {t('manageMemberDetailsDeleteAssignmentText')}
                              </Button>
                           )}
                        </Box>
                     </Grid>
                  )}
               </Grid>
            </form>
         </AccordionDetails>
      </Accordion>
   )
}

export default withTranslation()(Assignment)
