import React, { useState } from 'react'
import { Box, Collapse, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import moment from 'moment/moment'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TimesheetModalTimeInput from './TimesheetModalTimeInput'
import { withTranslation } from 'react-i18next'
import {
   checkForNonWorkingOrSubtaskHours,
   convertTimesheetHoursAndMinutes,
} from '../../../../util/helpers'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const additionalWorkTypes = [
   { name: 'timesheetProjectWorkTypeIdleTime', id: 'A2', Description: '' },
   { name: 'timesheetProjectWorkTypeTraining', id: 'A3', Description: '' },
   { name: 'timesheetProjectWorkTypeTravelTime', id: 'A6', Description: '' },
]

const centerAlign = {
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
}
const TimesheetModalRow = ({ project, week, userTimesheet, t }) => {
   const [openRow, setOpenRow] = useState(false)
   const [temporaryInputValue, setTemporaryInputValue] = useState({})

   const isToday = date => moment(date).isSame(new Date(Date.now()), 'day')

   const calculateProjectActualHours = reportedDates => {
      let projectHours = 0
      let projectMinutes = 0
      reportedDates.forEach(reportedDate => {
         projectHours += Number(reportedDate.ReportedHours.split(':')[0]) || 0
         projectMinutes += Number(reportedDate.ReportedHours.split(':')[1]) || 0
      })

      return convertTimesheetHoursAndMinutes(projectHours, projectMinutes)
   }

   const getReportedInputHours = (projectID, date, workType, description) => {
      const foundReportedDate = userTimesheet.Projects.find(
         project => project.ProjectID === projectID
      ).ReportedDates.find(
         reportedDate =>
            moment(reportedDate.Date).isSame(date, 'day') &&
            reportedDate.WorkType === workType &&
            reportedDate.Description === description
      )
      return !!foundReportedDate ? foundReportedDate.ReportedHours : ''
   }

   const returnInputValue = (projectID, date, workType, description) => {
      const foundTemporaryInputValue = isTemporaryInputValue(projectID, date, workType)
         ? temporaryInputValue
         : null

      if (foundTemporaryInputValue) {
         return temporaryInputValue.value
      } else {
         return getReportedInputHours(projectID, date, workType, description)
      }
   }

   const isTemporaryInputValue = (projectID, date, workType) => {
      return (
         !!temporaryInputValue &&
         temporaryInputValue.projectID === projectID &&
         temporaryInputValue.date === date &&
         temporaryInputValue.workType === workType
      )
   }
   const groupedReports = project.ReportedDates.reduce((acc, report) => {
      if (report.Description !== '') {
         if (!acc[report.Description]) {
            acc[report.Description] = report
         }
      }
      return acc
   }, {})

   return (
      <>
         <Grid
            container
            style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)', minHeight: 60 }}
         >
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', paddingLeft: 13 }}>
               <IconButton onClick={() => setOpenRow(prevState => !prevState)} size="large">
                  {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
               </IconButton>
               <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontWeight: 600 }}>{project.Name}</Typography>
                  {checkForNonWorkingOrSubtaskHours(project.ReportedDates) && (
                     <Tooltip
                        componentsProps={{
                           tooltip: {
                              sx: {
                                 bgcolor: 'orange',
                                 '& .MuiTooltip-arrow': {
                                    color: 'orange',
                                 },
                              },
                           },
                        }}
                        arrow
                        title="There are reported hours in the drop down menu!"
                     >
                        <ErrorOutlineIcon sx={{ ml: 1, color: 'orange' }} />
                     </Tooltip>
                  )}
               </Box>
            </Grid>
            {week?.dates.map(weekDate => (
               <Grid
                  key={new Date(weekDate).toDateString()}
                  item
                  xs={1}
                  style={
                     isToday(weekDate)
                        ? {
                             backgroundColor: '#8ACCE6',
                             borderBottom: '1px solid #8ACCE6',
                             marginBottom: '-1px',
                             ...centerAlign,
                          }
                        : { ...centerAlign }
                  }
               >
                  <TimesheetModalTimeInput
                     value={returnInputValue(project.ProjectID, weekDate, 'A1', '')}
                     isPlaceholder={!isTemporaryInputValue(project.ProjectID, weekDate, 'A1')}
                  />
               </Grid>
            ))}
            <Grid item xs={1} style={{ ...centerAlign }}>
               <Typography sx={{ fontWeight: 600 }}>
                  {`${calculateProjectActualHours(project.ReportedDates)}${t('h')}`}
               </Typography>
            </Grid>
         </Grid>
         <Collapse in={openRow} timeout="auto" unmountOnExit>
            {additionalWorkTypes.map(workType => (
               <Grid
                  key={workType.id}
                  container
                  style={{
                     borderBottom: '1px solid rgba(224, 224, 224, 1)',
                     minHeight: 60,
                  }}
               >
                  <Grid
                     item
                     xs={4}
                     style={{
                        backgroundColor: '#def1fa',
                        ...centerAlign,
                        justifyContent: 'left',
                        paddingLeft: 52,
                     }}
                  >
                     {t(workType.name)}
                  </Grid>
                  {week?.dates.map(weekDate => (
                     <Grid
                        key={weekDate}
                        style={
                           isToday(weekDate)
                              ? {
                                   backgroundColor: '#8ACCE6',
                                   borderBottom: '1px solid #8ACCE6',
                                   marginBottom: '-1px',
                                   ...centerAlign,
                                }
                              : { ...centerAlign, background: '#def1fa' }
                        }
                        item
                        xs={1}
                     >
                        <TimesheetModalTimeInput
                           value={returnInputValue(
                              project.ProjectID,
                              weekDate,
                              workType.id,
                              workType.Description
                           )}
                           isPlaceholder={
                              !isTemporaryInputValue(project.ProjectID, weekDate, workType.id)
                           }
                        />
                     </Grid>
                  ))}
               </Grid>
            ))}
            {Object.values(groupedReports).map(report => (
               <Grid
                  key={report._id}
                  container
                  style={{
                     borderBottom: '1px solid rgba(224, 224, 224, 1)',
                     minHeight: 60,
                  }}
               >
                  <Grid
                     item
                     xs={4}
                     style={{
                        backgroundColor: '#def1fa',
                        ...centerAlign,
                        justifyContent: 'left',
                        paddingLeft: 52,
                     }}
                  >
                     {report.Description}
                  </Grid>
                  {week?.dates.map(weekDate => (
                     <Grid
                        key={new Date(weekDate).toDateString()}
                        style={
                           isToday(weekDate)
                              ? {
                                   backgroundColor: '#8ACCE6',
                                   borderBottom: '1px solid #8ACCE6',
                                   marginBottom: '-1px',
                                   ...centerAlign,
                                }
                              : { ...centerAlign, background: '#def1fa' }
                        }
                        item
                        xs={1}
                     >
                        {
                           <TimesheetModalTimeInput
                              value={returnInputValue(
                                 project.ProjectID,
                                 weekDate,
                                 report.WorkType,
                                 report.Description
                              )}
                              isPlaceholder={
                                 !isTemporaryInputValue(
                                    project.ProjectID,
                                    weekDate,
                                    report.WorkType
                                 )
                              }
                           />
                        }
                     </Grid>
                  ))}
               </Grid>
            ))}
         </Collapse>
      </>
   )
}

export default React.memo(withTranslation()(TimesheetModalRow))
