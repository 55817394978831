import { Box, Divider, Typography } from '@mui/material'
import { withTranslation } from 'react-i18next'

const ProjectPlanningHeader = () => {
   return (
      <>
         <Box sx={{ padding: '0 30px 15px 20px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
               <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ fontSize: 30, fontWeight: 600 }}>Project Planning</Typography>
               </Box>
            </Box>
            <Divider sx={{ my: '10px' }} />
         </Box>
      </>
   )
}
export default withTranslation()(ProjectPlanningHeader)
