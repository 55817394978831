import { CircularProgress, Paper, Snackbar, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import React, { Fragment, useState, useEffect, useCallback } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import { connect } from 'react-redux'
import { fetchAllAbsenceRequests, fetchBankHolidays } from '../Api'
import { absenceChange, fetchUsers, fetchFutureRequests } from '../redux/actions/actions'
import themeConfig from '../theme'
import TeamDropdown from '../components/TeamDropdown'
import ProjectDropdown from '../components/ProjectDropdown'
import RequestRow from '../components/RequestRow'
import { sortDesc } from '../util/helpers'
import Alert from '@mui/material/Alert'
import moment from 'moment'
import { AccordionDetails, Accordion, AccordionSummary } from '@mui/material'
import AbsenceOverview from '../overview/AbsenceOverview'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import log from '../util/log'
import { withTranslation } from 'react-i18next'

const { styles } = themeConfig()

const AbsenceRequests = ({ ...props }) => {
   const { userInfo, fetchUsers, fetchFutureRequests, t } = props
   const [snackbarState, setSnackbarState] = useState({
      open: false,
      message: '',
      severity: 'info',
   })
   const [currentTeamState, setCurrentTeamState] = useState({
      wrappedTeam: null,
   })
   const [currentProjectState, setCurrentProjectState] = useState({
      wrappedProject: null,
   })
   const [requestState, setRequestState] = useState({ requests: [] })
   const [holidays, setHolidays] = useState([])
   const [isLoading, setIsLoading] = useState(false)

   let teamMembers = []
   let projectMembers = []
   let filterByMembers = []

   const filterRelevant = requests =>
      requests.filter(
         req =>
            req.Status === 'REQUESTED' ||
            req.AbsenceIntervals.some(
               abs => moment(abs.EndDate).format('YYYYMMDD') > moment().format('YYYYMMDD')
            )
      )

   const fetchHolidays = useCallback(async () => {
      try {
         const responseData = await fetchBankHolidays(userInfo)
         setHolidays(responseData)
      } catch (error) {
         log.error('Failure to load data; try logging out and in again: ')
      }
   }, [userInfo])

   const loadData = useCallback(async () => {
      setIsLoading(true)
      try {
         await fetchFutureRequests(userInfo)
         await fetchUsers(userInfo)

         const json = await fetchAllAbsenceRequests(userInfo, 'REQUESTED')
         setRequestState({
            requests: filterRelevant(json.requests),
         })
      } catch (error) {
         console.error('Error loading data:', error)
      } finally {
         setIsLoading(false)
      }
   }, [userInfo, fetchUsers, fetchFutureRequests])

   useEffect(() => {
      const fetchData = async () => {
         await loadData()
         await fetchHolidays()
      }
      fetchData()
   }, [loadData, fetchHolidays])

   const handleChangeTeam = wrappedTeam => {
      log.debug('    handleChangeTeam: ' + JSON.stringify(wrappedTeam))
      setCurrentTeamState({
         wrappedTeam: wrappedTeam,
      })
      teamMembers = []
   }

   const handleChangeProject = wrappedProject => {
      log.debug('    handleChangeProject: ' + JSON.stringify(wrappedProject))
      setCurrentProjectState({
         wrappedProject: wrappedProject,
      })
      projectMembers = []
   }

   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ open: false, message: '', severity: 'info' })
   }

   const CurrentRequests = p => {
      if (requestState.requests === null || requestState.requests.length < 1) {
         return (
            <Typography variant="body2" className={'text-center'}>
               {t('noRequestsFound')}
            </Typography>
         )
      }

      let requests

      if (
         (!currentTeamState.wrappedTeam || currentTeamState.wrappedTeam.value === null) &&
         (!currentProjectState.wrappedProject || currentProjectState.wrappedProject.value === null)
      ) {
         // Show all requests.
         requests = requestState.requests
      } else {
         if (currentTeamState.wrappedTeam && currentTeamState.wrappedTeam.members !== []) {
            teamMembers = props.users
               .filter(user => {
                  return currentTeamState.wrappedTeam.members.includes(user._id)
               })
               .map(member => member.EmployeeID)
         }
         if (
            currentProjectState.wrappedProject &&
            currentProjectState.wrappedProject.members !== []
         ) {
            projectMembers = props.users
               .filter(user => {
                  return currentProjectState.wrappedProject.members.includes(user._id)
               })
               .map(member => member.EmployeeID)
         }

         if (teamMembers.length !== 0 && projectMembers.length !== 0) {
            filterByMembers = teamMembers.filter(id => projectMembers.includes(id))
         } else if (currentTeamState.wrappedTeam && currentTeamState.wrappedTeam.members !== []) {
            filterByMembers = teamMembers
         } else if (
            currentProjectState.wrappedProject &&
            currentProjectState.wrappedProject.members !== []
         ) {
            filterByMembers = projectMembers
         }

         requests = requestState.requests.filter(request => {
            return filterByMembers.includes(request.EmployeeID)
         })
      }

      if (requests !== null && requests.length > 0) {
         if (p.status != null) {
            log.debug('Filtering requests on status ' + p.status)
            requests = requests.filter(request => {
               return request.Status === p.status
            })
         }

         if (p.history) {
            requests = requests.filter(request => {
               return request.Status === 'APPROVED' || request.Status === 'REJECTED'
            })
         }

         log.debug('Rendering ' + requests.length + ' requests')
         return requests
            .sort((r1, r2) => sortDesc(r1.RequestedDate, r2.RequestedDate))
            .map(request => {
               return (
                  <RequestRow
                     setSnackbarState={setSnackbarState}
                     canDelete={p.canDelete}
                     canApprove={p.canApprove}
                     canReject={p.canReject}
                     key={request._id}
                     userInfo={props.userInfo}
                     request={request}
                     users={props.users}
                     holidays={holidays}
                     absenceChange={props.absenceChange}
                     reload={() => loadData()}
                  />
               )
            })
      } else {
         return <Typography variant="body2">{t('noRequestsFound')}</Typography>
      }
   }

   if (props.users == null) {
      return (
         <Fragment>
            <Typography varixant="body2">{t('loadingUserData')}</Typography>
         </Fragment>
      )
   }

   return (
      <Fragment>
         <Paper className="contentPaper" style={{ maxWidth: 900, boxShadow: 'none' }}>
            <Typography variant="h5" style={{ padding: '20px 0 10px 25px', fontSize: '22px' }}>
               {t('titleAbsenceRequests')}
            </Typography>
            <div className={'contentWrapper'}>
               <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
                  <div style={{ padding: 10, marginLeft: 15 }}>
                     {t('filterByTeam')}
                     <div style={{ paddingTop: 5 }}>
                        <TeamDropdown
                           userInfo={props.userInfo}
                           handleChangeTeam={handleChangeTeam}
                           value={
                              currentTeamState.wrappedTeam != null
                                 ? currentTeamState.wrappedTeam
                                 : null
                           }
                        />
                     </div>
                  </div>

                  <div style={{ padding: 10, marginLeft: 65 }}>
                     {t('filterByProject')}
                     <div style={{ paddingTop: 5 }}>
                        <ProjectDropdown
                           userInfo={props.userInfo}
                           handleChangeProject={handleChangeProject}
                           value={
                              currentProjectState.wrappedProject != null
                                 ? currentProjectState.wrappedProject
                                 : null
                           }
                        />
                     </div>
                  </div>
               </div>

               <div style={{ padding: 10, marginLeft: 15, textAlign: 'center' }}>
                  {isLoading ? (
                     <CircularProgress />
                  ) : (
                     <CurrentRequests
                        requestState={props.requestState}
                        status={'REQUESTED'}
                        canDelete={true}
                        canReject={true}
                        canApprove={true}
                     />
                  )}
               </div>
            </div>
         </Paper>

         <Paper
            className="contentPaper"
            style={{ maxWidth: 900, marginTop: 20, boxShadow: 'none' }}
         >
            <Accordion sx={{ boxShadow: 'none' }}>
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={'history'}
                  id={'history'}
                  style={{ margin: '0 15px 0 10px' }}
               >
                  <Typography variant="h5" style={{ fontSize: '22px' }}>
                     {t('absenceHistory')}
                  </Typography>
               </AccordionSummary>
               <AccordionDetails style={{ marginLeft: 10 }}>
                  <div className={'contentWrapper'}>
                     <div>
                        <CurrentRequests
                           requestState={props.requestState}
                           canDelete={false}
                           canReject={false}
                           canApprove={false}
                           history={true}
                        />
                     </div>
                  </div>
               </AccordionDetails>
            </Accordion>
         </Paper>

         <div style={{ marginTop: 50 }}>
            <AbsenceOverview />
         </div>

         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>
      </Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      requests: state.requests,
      users: state.users,
   }
}

export default withStyles(styles)(
   withTranslation()(
      connect(mapStateToProps, { absenceChange, fetchUsers, fetchFutureRequests })(AbsenceRequests)
   )
)
