import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import 'typeface-roboto'
import AbsenceList from './admin/AbsenceList'
import AbsenceRequests from './admin/AbsenceRequests'
import PrintRequestHTML from './admin/PrintRequestHTML'
import UserList from './admin/UserList'
import TeamManagement from './admin/TeamManagement'
import ProjectManagement from './admin/projectManagement/ProjectManagement'
import './custom.scss'
import history from './history'
import Home from './Home'
import Login from './Login'
import MyPage from './myservices/MyPage'
import PrivateRoute from './PrivateRoute'
import configureStore from './redux/store'
import themeConfig from './theme'
import RequestAbsence from './myservices/RequestAbsence'
import ReportFlex from './myservices/ReportFlex'
import EmploymentList from './admin/EmploymentList'
import MyEmployments from './myservices/MyEmployments'
import ProjectReports from './admin/ProjectReports'
import SalaryReports from './admin/SalaryReports'
import SkillManagement from './admin/SkillManagement'
import SalaryReportJSON from './admin/SalaryReportJSON'
import SalaryReportHTML from './admin/SalaryReportHTML'
import CostCenters from './admin/CostCenters'
import BankHolidays from './admin/BankHolidays'
import CustomerSettings from './admin/CustomerSettings'
import Customers from './sadmin/Customers'
import SuperAdmins from './sadmin/SuperAdmins'
import CustomerFeatures from './sadmin/CustomerFeatures'
import Privacy from './privacy_and_TC'
import HillsLogin from './login/Hills'
import TenantLogin from './login/TenantLogin'
import Timesheet from './timesheet/Timesheet'
import ReportsAndPlaning from './admin/reportsAndPlanning/ReportsAndPlaning'
import Roadmap from './admin/reportsAndPlanning/components/ResourcePlanning/Roadmap/Roadmap'
import AvailableEmployees from './admin/reportsAndPlanning/components/ResourcePlanning/AvailableEmployees/AvailableEmployees'
import ProjectPlanning from './admin/reportsAndPlanning/components/ResourcePlanning/ProjectPlanning/ProjectPlanning'
import ProjectReport from './admin/reportsAndPlanning/components/ProjectReport/ProjectReport'
import TimesheetManagement from './admin/timesheetManagement/TimesheetManagement'
import ProjectOverview from './admin/reportsAndPlanning/components/ProjectOverview/ProjectOverview'
import { OAUTH_CLIENT_ID } from './util/dependencies'
const { theme } = themeConfig()

const { persistor, store } = configureStore()

const App = () => {
   return (
      <Provider store={store}>
         <PersistGate loading={null} persistor={persistor}>
            <StyledEngineProvider injectFirst>
               <ThemeProvider theme={theme}>
                  <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
                     <Router history={history}>
                        <Switch>
                           <PrivateRoute
                              path="/users"
                              component={UserList}
                              title="titleUsers"
                              navItem="users"
                              permissionComponent={'employeesManagement'}
                           />
                           <PrivateRoute
                              path="/teams"
                              component={TeamManagement}
                              title="titleTeams"
                              navItem="teams"
                              permissionComponent={'teamsManagement'}
                           />
                           <PrivateRoute
                              path="/projects"
                              component={ProjectManagement}
                              title="projectManagement"
                              navItem="projects"
                              permissionComponent={'projectsManagement'}
                           />
                           <PrivateRoute
                              path="/timesheetManagement"
                              component={TimesheetManagement}
                              title="timesheetManagementTitle"
                              navItem="timesheetManagement"
                              permissionComponent={'timesheetManagement'}
                           />
                           <PrivateRoute
                              path="/skills"
                              component={SkillManagement}
                              title="titleSkills"
                              navItem="skills"
                              permissionComponent={'skillsManagement'}
                           />
                           <PrivateRoute
                              path="/absences/:id?"
                              component={AbsenceList}
                              title="titleAbsences"
                              navItem="absences"
                              permissionComponent={'absencesManagement'}
                           />
                           <PrivateRoute
                              path="/absencerequests"
                              component={AbsenceRequests}
                              title="titleAbsenceRequests"
                              navItem="absencerequests"
                              permissionComponent={'absenceRequests'}
                              admin={true}
                           />
                           <PrivateRoute
                              path="/reportsAndPlaning"
                              component={ReportsAndPlaning}
                              title="reportsAndPlaningTitle"
                              navItem="reportsAndPlaning"
                              permissionComponent={'reportsAndPlaning'}
                              exact
                           />
                           <PrivateRoute
                              path="/reportsAndPlaning/projectReport"
                              component={ProjectReport}
                              title="reportsAndPlaningTitle"
                              navItem="projectReport"
                              permissionComponent={'reportsAndPlaning'}
                              exact
                           />
                           <PrivateRoute
                              path="/reportsAndPlaning/projectReport/projectOverview/:id"
                              component={ProjectOverview}
                              title="reportsAndPlaningTitle"
                              navItem="projectOverview"
                              permissionComponent={'reportsAndPlaning'}
                           />
                           <PrivateRoute
                              path="/reportsAndPlaning/resourcePlanning"
                              component={Roadmap}
                              title="reportsAndPlaningTitle"
                              navItem="reportsAndPlaning"
                              permissionComponent={'reportsAndPlaning'}
                              exact
                           />
                           <PrivateRoute
                              path="/reportsAndPlaning/resourcePlanning/availableEmployees"
                              component={AvailableEmployees}
                              title="reportsAndPlaningTitle"
                              navItem="reportsAndPlaning"
                              permissionComponent={'reportsAndPlaning'}
                              exact
                           />
                           <PrivateRoute
                              path="/reportsAndPlaning/resourcePlanning/projectPlanning"
                              component={ProjectPlanning}
                              title="reportsAndPlaningTitle"
                              navItem="reportsAndPlaning"
                              permissionComponent={'reportsAndPlaning'}
                              exact
                           />
                           <Route path={'/showrequest/html/:id/:lng/:imgindex'}>
                              <PrintRequestHTML />
                           </Route>

                           <PrivateRoute
                              path="/employments"
                              component={EmploymentList}
                              title="titleEmployments"
                              navItem="employments"
                              permissionComponent={'employmentsManagement'}
                              admin={true}
                           />
                           <PrivateRoute
                              path="/costcenters"
                              component={CostCenters}
                              title="titleCostCenters"
                              navItem="costcenters"
                              permissionComponent={'costCenters'}
                              admin={true}
                           />
                           <PrivateRoute
                              path="/bankholidays"
                              component={BankHolidays}
                              title="titleBankHolidays"
                              navItem="bankholidays"
                              permissionComponent={'bankHolidays'}
                           />
                           <PrivateRoute
                              path="/customersettings"
                              component={CustomerSettings}
                              title="titleCustomerSettings"
                              navItem="customersettings"
                              permissionComponent={'settings'}
                           />

                           <PrivateRoute
                              path="/customers"
                              component={Customers}
                              title="titleCustomers"
                              navItem="customers"
                              superAdmin={true}
                           />

                           <PrivateRoute
                              path="/superadmins"
                              component={SuperAdmins}
                              title="titleSuperAdmins"
                              navItem="superadmins"
                              superAdmin={true}
                           />
                           <PrivateRoute
                              path="/customerfeatures/:id"
                              component={CustomerFeatures}
                              title="titleCustomerFeatures"
                              navItem="customerfeatures"
                              superAdmin={true}
                           />

                           <PrivateRoute
                              path="/projectreports"
                              component={ProjectReports}
                              title="titleProjectReports"
                              navItem="projectreports"
                              permissionComponent={'projectReports'}
                           />

                           <PrivateRoute
                              path="/salaryreports"
                              component={SalaryReports}
                              title="titleSalaryReports"
                              navItem="salaryreports"
                              permissionComponent={'salaryReports'}
                           />

                           <Route path={'/showreport/json/:id'}>
                              <SalaryReportJSON />
                           </Route>
                           <Route
                              path={'/showreport/html/:id'}
                              render={props => <SalaryReportHTML {...props} />}
                           />

                           <PrivateRoute
                              path="/mypage"
                              component={MyPage}
                              title="titleMypage"
                              navItem="mypage"
                           />
                           <PrivateRoute
                              path="/timesheet"
                              component={Timesheet}
                              title="timesheetComponentName"
                              navItem="timesheet"
                           />
                           <PrivateRoute
                              path="/requestabsence"
                              component={RequestAbsence}
                              title="titleRequestAbsence"
                              navItem="requestabsence"
                           />
                           <PrivateRoute
                              path="/reportabsence"
                              component={ReportFlex}
                              title="titleReportAbsence"
                              navItem="reportabsence"
                           />
                           <PrivateRoute
                              path="/myemployments"
                              component={MyEmployments}
                              title="titleMyEmployments"
                              navItem="myemployments"
                           />
                           <PrivateRoute
                              path="/privacy"
                              component={Privacy}
                              title="titlePrivacy"
                              navItem=""
                           />

                           <Route path="/login" component={Login} />
                           <Route path="/hills/login" component={HillsLogin} />
                           <Route path="/:tenant/login" component={TenantLogin} />
                           <Route path={'/privacy_and_TC'}>
                              <Privacy />
                           </Route>

                           <PrivateRoute
                              path="/"
                              component={Home}
                              title="titleDashboard"
                              navItem="dashboard"
                           />
                        </Switch>
                     </Router>
                  </GoogleOAuthProvider>
               </ThemeProvider>
            </StyledEngineProvider>
         </PersistGate>
      </Provider>
   )
}

export default App
