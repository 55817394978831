import React, { useCallback, useState } from 'react'
import { Button, Grid } from '@mui/material'
import { updateProject } from '../../../Api'
import { withTranslation } from 'react-i18next'
import ManageProjectMembersModal from '../../../components/modals/ManageProjectMembersModal'
import { wrapDeletedUser, wrapUser } from '../../../util/wrappers'
import { connect } from 'react-redux'
import ProjectManagementProjectForm from './ProjectManagementProjectForm'
import ProjectManagementProjectMembers from './ProjectManagementProjectMembers'

const ProjectManagementProjectView = ({
   selectedProject,
   setSelectedProject,
   haveUnsavedChanges,
   setHaveUnsavedChanges,
   fetchProjects,
   showSuccessfulSnackbar,
   showErrorSnackBar,
   openManageMembersModal,
   customer,
   toggleManageMembersModal,
   userInfo,
   activeUsers,
   inactiveUsers,
   t,
}) => {
   const [nameFieldError, setNameFieldError] = useState(false)

   const updateProjectHandler = () => {
      if (selectedProject.Name === '') {
         !nameFieldError && setNameFieldError(true)
      } else {
         haveUnsavedChanges && setHaveUnsavedChanges(false)

         const data = {
            ...selectedProject,
            updateDate: new Date(),
         }
         data.ProjectID = data._id
         delete data._id

         updateProject(data, userInfo)
            .then(() => {
               fetchProjects(customer, selectedProject._id)
               showSuccessfulSnackbar(t('successToastUpdatedProject'))
            })
            .catch(() => showErrorSnackBar(t('errorToastUpdatedProject')))
      }
   }

   const updateSelectedProject = (property, value) => {
      !haveUnsavedChanges && setHaveUnsavedChanges(true)
      setSelectedProject(prevState => {
         return { ...prevState, [property]: value }
      })
   }

   const isProjectManager = user => {
      if (user?._id === selectedProject.ProjectManager) {
         return true
      }
      return false
   }
   const getAndWrapUser = useCallback(
      (id, projectUser) => {
         const user = activeUsers.find(user => user._id === id)
         return user ? wrapUser(user) : wrapDeletedUser(projectUser)
      },
      [activeUsers]
   )
   const getAndWrapPastUser = useCallback(
      id => {
         const user = inactiveUsers.find(user => user._id === id)
         return user ? wrapUser(user) : null
      },
      [inactiveUsers]
   )

   return (
      <>
         <Grid item xs={6} className={'pl-0'}>
            <ProjectManagementProjectForm
               nameFieldError={nameFieldError}
               selectedProject={selectedProject}
               updateSelectedProject={updateSelectedProject}
               setNameFieldError={setNameFieldError}
            />
         </Grid>
         <Grid item xs={6} className={'mt-2'}>
            <ProjectManagementProjectMembers
               selectedProject={selectedProject}
               updateSelectedProject={updateSelectedProject}
               getAndWrapUser={getAndWrapUser}
               getAndWrapPastUser={getAndWrapPastUser}
               isProjectManager={isProjectManager}
               inactiveUsers={inactiveUsers}
            />
         </Grid>
         <Grid item xs={12}>
            <div style={{ float: 'right', marginRight: '-4px' }}>
               <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={updateProjectHandler}
                  disabled={nameFieldError}
               >
                  {t('saveButtonTitle')}
               </Button>
            </div>
         </Grid>

         {Object.keys(selectedProject).length > 0 && (
            <ManageProjectMembersModal
               openModal={openManageMembersModal}
               toggleModal={toggleManageMembersModal}
               activeUsers={activeUsers}
               selectedProject={selectedProject}
               getAndWrapUser={getAndWrapUser}
               isProjectManager={isProjectManager}
               updateSelectedProject={updateSelectedProject}
            />
         )}
      </>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      activeUsers: state.activeUsers,
   }
}

export default withTranslation()(connect(mapStateToProps)(ProjectManagementProjectView))
