export const wrapUser = user => {
   if (!user) {
      return { label: '', value: 'null', id: 'null' }
   } else {
      return {
         label:
            user.FirstName + ' ' + (user.MiddleName ? user.MiddleName + ' ' : '') + user.LastName,
         value: user.EmployeeID,
         nick:
            user.FirstName +
            ' ' +
            (user.MiddleName ? user.MiddleName.charAt(0) + '. ' : '') +
            user.LastName,
         id: user.EmployeeID,
         email: user.CompanyEmail,
         initials: user.FirstName.charAt(0) + user.LastName.charAt(0),
         parentalLeave: user.ParentalLeave,
         _id: user._id,
      }
   }
}
export const wrapDeletedUser = user => {
   if (!user) {
      return { label: '', value: 'null', id: 'null' }
   } else {
      const name = user.Name.split(' ')

      return {
         label: name[0] + ' ' + name[1],
         nick: name[0] + ' ' + name[1],
         initials: name[0].charAt(0) + name[1].charAt(0),
         _id: user._id,
         isDeleted: true,
      }
   }
}

export const wrapTeam = team => {
   if (!team) {
      return { label: 'null', value: 'null', id: 'null', members: 'null' }
   } else {
      return {
         label: team.Name,
         value: team._id,
         id: team._id,
         members: team.MemberIDs,
         pastMembers: team.PastMemberIDs,
         TeamLead: team.TeamLead,
      }
   }
}

export const wrapProject = project => {
   if (!project) {
      return { label: 'null', value: 'null', id: 'null', members: 'null' }
   } else {
      return {
         label: project.Name,
         value: project._id,
         id: project._id,
         membersDetails: project.MemberDetails,
         members: project.MemberIDs,
         ProjectManager: project.ProjectManager,
      }
   }
}

export const wrapReportStatus = report => {
   switch (report.Status) {
      case 'OPEN':
         return { label: 'OPEN', value: 'OPEN', color: 'black' }
      case 'LOCKED':
         return { label: 'LOCKED', value: 'LOCKED', color: 'black' }
      default:
         return { label: 'DEFAULT', value: 'DEFAULT', color: 'black' }
   }
}

export const getAndWrapUser = (wrappedUserData, id) => {
   return wrappedUserData?.find(user => user._id === id)
}
