import React, { useEffect, useCallback } from 'react'
import themeConfig from '../theme'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles'
import { withTranslation } from 'react-i18next'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Alert from 'react-bootstrap/Alert'
import Paper from '@mui/material/Paper'
import { fetchCustomers, newCustomer, switchCustomer } from '../Api'
import log from '../util/log'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Input, Link, Typography } from '@mui/material'
import Snackbar from '@mui/material/Snackbar'
import '../css/userform.css'
import { faRandom, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fetchActiveUsers, fetchUsers } from '../redux/actions/actions'

const { styles } = themeConfig()

const Customers = ({ ...props }) => {
   const { t, userInfo, fetchUsers, fetchActiveUsers } = props

   const [customers, setCustomers] = React.useState([])
   const [showModal, setShowModal] = React.useState(false)

   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      variant: 'info',
   })
   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ open: false })
   }

   const loadData = useCallback(async () => {
      try {
         const result = await fetchCustomers(userInfo)
         setCustomers(result.data)
      } catch (err) {
         log.error(err)
      }
   }, [userInfo])

   useEffect(() => {
      loadData()
   }, [loadData])

   const switchCustomer_ = async (CustomerID, Name) => {
      try {
         const response = await switchCustomer(CustomerID, userInfo)
         if (!response.data.success) {
            setSnackbarState({
               open: true,
               message: response.data.message,
               variant: 'danger',
            })
            return
         }

         setSnackbarState({
            open: true,
            message: t('switchedToCustomerMessage') + ': ' + Name + '. ' + t('pageReloading'),
            variant: 'success',
         })
         fetchActiveUsers(userInfo)
         await fetchUsers(userInfo)

         setTimeout(() => {
            window.location.reload()
         }, 2000)
      } catch (err) {
         setSnackbarState({
            open: true,
            message: err,
            variant: 'danger',
         })
      }
   }

   const AddModal = props => {
      const [newAdminEmail, setNewAdminEmail] = React.useState('')
      const [newCustomerName, setNewCustomerName] = React.useState('')
      const [alert, setAlert] = React.useState({
         message: null,
         variant: null,
      })

      const clickNew = async () => {
         if (!newAdminEmail || newAdminEmail.length < 1) {
            setAlert({
               message: t('missingNewCustomerAdminEmail'),
               variant: 'danger',
            })
            return
         }

         if (!newCustomerName || newCustomerName.length < 1) {
            setAlert({
               message: t('missingNewCustomerName'),
               variant: 'danger',
            })
            return
         }

         try {
            const response = await newCustomer(userInfo, {
               Name: newCustomerName,
               Email: newAdminEmail,
            })
            if (!response.data.success) {
               setAlert({
                  message: response.data.message,
                  variant: 'danger',
               })
               return
            }
         } catch (err) {
            setAlert({
               message: err,
               variant: 'danger',
            })
            return
         }

         // success
         setSnackbarState({
            open: true,
            message: t('newCustomerAddedMessage'),
            variant: 'success',
         })
         await loadData()
         setShowModal(false)
      }

      return (
         <React.Fragment>
            <Dialog
               open={showModal}
               onClose={event => this.handleClose(event)}
               aria-labelledby="form-dialog-title"
            >
               <DialogTitle id="form-dialog-title">{t('newCustomer')}</DialogTitle>
               <DialogContent>
                  <DialogContentText>{t('newCustomerInfo')}</DialogContentText>
                  {alert.message != null ? (
                     <Alert
                        key={'addCustomerAlert'}
                        variant={'danger'}
                        onClose={() => setAlert({ message: null, variant: null })}
                        dismissible
                     >
                        {alert.message}
                     </Alert>
                  ) : (
                     ''
                  )}
                  <Typography style={{ marginTop: 10 }}>
                     <b>{t('customerNameLabel')}:</b>
                  </Typography>
                  <Input
                     autoComplete="off"
                     style={{ width: 500 }}
                     placeholder={t('typeHereToChange')}
                     onChange={event => {
                        setNewCustomerName(event.target.value)
                     }}
                  ></Input>

                  <Typography style={{ marginTop: 10 }}>
                     <b>{t('newCustomerAdminEmailLabel')}:</b>
                  </Typography>
                  <Typography style={{ fontSize: 12 }}>
                     ({t('newCustomerAdminEmailInfo')})
                  </Typography>
                  <Input
                     autoComplete="off"
                     style={{ width: 500 }}
                     placeholder={t('typeHereToChange')}
                     onChange={event => {
                        setNewAdminEmail(event.target.value)
                     }}
                  ></Input>
               </DialogContent>
               <DialogActions>
                  <Button
                     size="small"
                     variant="contained"
                     color="secondary"
                     onClick={() => setShowModal(false)}
                  >
                     {t('closeButtonTitle')}
                  </Button>
                  <Button
                     size="small"
                     variant="contained"
                     color="primary"
                     onClick={() => clickNew()}
                  >
                     {t('newCustomer')}
                  </Button>
               </DialogActions>
            </Dialog>
         </React.Fragment>
      )
   }

   return (
      <React.Fragment>
         <AddModal></AddModal>
         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} variant={snackbarState.variant}>
               {snackbarState.message}
            </Alert>
         </Snackbar>
         <Paper className="contentPaper" style={{ maxWidth: 580, boxShadow: 'none' }}>
            <div className={'contentWrapper'}>
               <div style={{ padding: 10 }}>
                  <div>
                     <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => setShowModal(true)}
                     >
                        {t('newCustomer')}
                     </Button>
                  </div>
               </div>

               <TableContainer
                  className="employmentTable"
                  component={Paper}
                  style={{ maxHeight: 500 }}
               >
                  <Table size="small">
                     <TableHead>
                        <TableRow>
                           <TableCell align="left">{t('switchToCustomer')}</TableCell>
                           <TableCell align="left">{t('Features')}</TableCell>
                           <TableCell align="center">{t('ID')}</TableCell>
                           <TableCell align="center">{t('customerNameLabel')}</TableCell>
                        </TableRow>
                     </TableHead>

                     <TableBody>
                        {customers.map((customer, index) => (
                           <React.Fragment key={index}>
                              <TableRow key={'row_' + index}>
                                 <TableCell
                                    align="left"
                                    onClick={() =>
                                       switchCustomer_(customer.CustomerID, customer.Name)
                                    }
                                 >
                                    <FontAwesomeIcon
                                       style={{ cursor: 'pointer' }}
                                       color={'deepskyblue'}
                                       icon={faRandom}
                                    />
                                 </TableCell>
                                 <TableCell>
                                    <Link href={'/customerfeatures/' + customer.CustomerID}>
                                       <FontAwesomeIcon
                                          style={{ cursor: 'pointer' }}
                                          color={'deepskyblue'}
                                          icon={faEdit}
                                       />
                                    </Link>
                                 </TableCell>
                                 <TableCell align="center">{customer.CustomerID}</TableCell>
                                 <TableCell align="center">{customer.Name}</TableCell>
                              </TableRow>
                           </React.Fragment>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            </div>
         </Paper>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
   }
}

export default withStyles(styles)(
   withTranslation()(connect(mapStateToProps, { fetchActiveUsers, fetchUsers })(Customers))
)
