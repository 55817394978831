import moment from 'moment'

export function calculateRoadmapStartAndEndDate(period) {
   const roadmapDurationMonths = period.length

   let roadmapStartDate = new Date(period[0]?.dates[0]) || new Date()

   const periodLastMonth = period[period.length - 1]
   let roadmapEndDate =
      new Date(periodLastMonth?.dates[periodLastMonth.dates.length - 1]) || new Date()

   roadmapStartDate.setHours(0, 0, 0)
   roadmapEndDate.setHours(23, 59, 59)

   // if the period is more than 1 year
   if (period.length > 13) {
      const startDateQuarter = moment(roadmapStartDate).quarter()
      const startDateYear = moment(roadmapStartDate).format('YYYY')

      roadmapStartDate = moment(`Q${startDateQuarter} ${startDateYear}`, 'Q YYYY').toDate() // get the start of the current quarter

      roadmapEndDate = new Date(
         roadmapStartDate.getFullYear(),
         roadmapStartDate.getMonth() + roadmapDurationMonths,
         1
      )
      const endQuarter = moment(roadmapEndDate).quarter()
      const endYear = moment(roadmapEndDate).format('YYYY')
      roadmapEndDate = moment(`Q${endQuarter} ${endYear}`, 'Q YYYY').endOf('quarter').toDate()
   }

   return { roadmapStartDate, roadmapEndDate }
}

export function calculateBarWidthPercent(roadmapStartDate, roadmapEndDate, barEndDate) {
   const roadmapDurationMs = calculateRoadmapDuration(roadmapStartDate, roadmapEndDate)

   let projectWidthPercent

   if (barEndDate) {
      const projectEndDate = new Date(barEndDate)
      projectEndDate.setHours(23, 59, 59)
      let timeUntilEndMs = projectEndDate.getTime() - roadmapStartDate.getTime()

      if (projectEndDate > roadmapEndDate) {
         timeUntilEndMs = roadmapDurationMs
      }

      projectWidthPercent = (timeUntilEndMs / roadmapDurationMs) * 100
   } else {
      projectWidthPercent = 100
   }

   return projectWidthPercent
}

export function calculateEffectiveMarginLeftPercent(
   roadmapStartDate,
   roadmapEndDate,
   barStartDate
) {
   const roadmapDurationMs = calculateRoadmapDuration(roadmapStartDate, roadmapEndDate)

   const projectStartDate = new Date(barStartDate)
   const startDiffMs = projectStartDate - roadmapStartDate
   const startDiffFraction = startDiffMs / roadmapDurationMs
   const marginLeftPercent = Math.max(0, startDiffFraction * 100)
   const effectiveMarginLeftPercent = Math.min(Math.max(marginLeftPercent, 0), 100)

   return effectiveMarginLeftPercent
}

function calculateRoadmapDuration(roadmapStartDate, roadmapEndDate) {
   return moment(roadmapEndDate).diff(moment(roadmapStartDate), 'ms')
}
