import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { withTranslation } from 'react-i18next'

moment.updateLocale('en', {
   week: {
      dow: 1, // set monday as the first day of the week
   },
})

const Calendar = ({ minDate, onChange }) => {
   return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
         <DateCalendar
            views={['year', 'month', 'day']}
            openTo="day"
            dayOfWeekFormatter={day => day.format('ddd')}
            minDate={minDate}
            slotProps={{
               calendarHeader: { sx: { marginBottom: '15px', padding: '0' } },
            }}
            onChange={onChange}
            sx={{
               fontSize: '16px',
               fontWeight: '500',
            }}
         />
      </LocalizationProvider>
   )
}
export default withTranslation()(Calendar)
