import {
   Box,
   Divider,
   InputAdornment,
   MenuItem,
   Select,
   TextField,
   Typography,
} from '@mui/material'
import { withTranslation } from 'react-i18next'
import { ExpandMore as ExpandMoreIcon, Search as SearchIcon } from '@mui/icons-material'
import moment from 'moment'
import { getAllDatesOfAMonth } from '../../../../../../util/helpers'
const RoadmapHeader = ({ setProjectFilters, setPeriod, setNameFilter }) => {
   const statusFilterProjects = filter => {
      setProjectFilters(prev => ({
         ...prev,
         all: false,
         active: false,
         closed: false,
         [filter]: !prev[filter],
      }))
   }
   function getMonthsUntilFutureDate(selection) {
      const [amount, unit] = selection.split(' ')
      let startDate = moment()
      let endDate = moment().add(parseInt(amount), unit)
      let periodList = []
      while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'month')) {
         const year = startDate.format('YYYY')
         const month = startDate.format('M')
         const dates = getAllDatesOfAMonth(year, month)

         periodList.push({
            month: startDate.format('MMMM'),
            dates: dates,
            quarter: startDate.quarter(),
            year: startDate.format('YYYY'),
         })
         startDate.add(1, 'month')
      }
      setPeriod(periodList)
   }

   return (
      <>
         <Box sx={{ p: 3, display: 'flex', justifyContent: 'space-between' }}>
            <Box>
               <Typography sx={{ fontSize: 30, fontWeight: 600 }}>Novarto Roadmap</Typography>
            </Box>
            <Box sx={{ display: 'flex', width: 800, alignItems: 'center' }}>
               <Typography sx={{ mr: 1, color: '#6D6D6D', fontWeight: 500 }}>Projects:</Typography>
               <Select
                  sx={{ width: 200, height: 34, borderRadius: '4px' }}
                  onChange={(e, value) => statusFilterProjects(value.props.value)}
                  MenuProps={{
                     PaperProps: {
                        sx: {
                           padding: '10px',
                           boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                           bgcolor: '#fff',
                           '& .MuiMenuItem-root': {
                              padding: 1,
                              m: '5px 0',
                              color: '#6D6D6D',
                           },
                           '& .MuiMenuItem-root:hover': {
                              backgroundColor: '#EDF6FA',
                              color: '#6D6D6D',
                              borderRadius: 1,
                           },
                           '& .Mui-selected': {
                              backgroundColor: '#52B3DD',
                              color: '#fff',
                              borderRadius: 1,
                           },
                        },
                     },
                  }}
                  defaultValue={'all'}
                  IconComponent={ExpandMoreIcon}
               >
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'closed'}>Closed</MenuItem>
               </Select>
               <Typography sx={{ mr: 1, ml: 1, color: '#6D6D6D', fontWeight: 500 }}>
                  Timeline:
               </Typography>
               <Select
                  onChange={e => getMonthsUntilFutureDate(e.target.value)}
                  sx={{ width: 200, height: 34, borderRadius: '4px' }}
                  MenuProps={{
                     PaperProps: {
                        sx: {
                           padding: '10px',
                           boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                           bgcolor: '#fff',
                           '& .MuiMenuItem-root': {
                              padding: 1,
                              m: '5px 0',
                              color: '#6D6D6D',
                           },
                           '& .MuiMenuItem-root:hover': {
                              backgroundColor: '#EDF6FA',
                              color: '#6D6D6D',
                              borderRadius: 1,
                           },
                           '& .Mui-selected': {
                              backgroundColor: '#52B3DD',
                              color: '#fff',
                              borderRadius: 1,
                           },
                        },
                     },
                  }}
                  defaultValue={'month'}
                  IconComponent={ExpandMoreIcon}
               >
                  <MenuItem value={'month'}>Month</MenuItem>
                  <MenuItem value={'3 months'}>4 Month</MenuItem>
                  <MenuItem value={'7 months'}>8 Months</MenuItem>
                  <MenuItem value={'1 year'}>1 Year</MenuItem>
                  <MenuItem value={'2 years'}>2 Years</MenuItem>
               </Select>

               <TextField
                  size="small"
                  variant="outlined"
                  onChange={event => setNameFilter(event.target.value.toLowerCase())}
                  InputProps={{
                     style: {
                        borderRadius: '4px',
                        height: '32px',
                     },
                     startAdornment: (
                        <InputAdornment position="start">
                           <SearchIcon />
                        </InputAdornment>
                     ),
                  }}
                  sx={{
                     maxWidth: 264,
                     marginLeft: 4,
                  }}
                  placeholder={'Find Project'}
                  fullWidth
               />
            </Box>
         </Box>
         <Divider sx={{ m: '0 px' }} />
      </>
   )
}
export default withTranslation()(RoadmapHeader)
