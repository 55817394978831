import { wrapUser } from '../util/wrappers'
import { Avatar, Box, Tooltip, Typography } from '@mui/material'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
   tooltip: {
      backgroundColor: '#616161',
      padding: '6px 10px',
   },
})

const UserTeamLead = ({ teams, userId, users, t }) => {
   const classes = useStyles()
   const userTeam = () => {
      return teams.filter(team => team.MemberIDs.includes(userId))
   }

   const getAndWrapUser = id => {
      const user = users.find(user => user._id === id)
      return user ? wrapUser(user) : null
   }

   const isUserActive = userId => {
      const user = users.find(user => user._id === userId)
      const isEmploymentActive = user.Employments.some(employment => {
         const assignmentStartDate = new Date(employment.StartDate)
         const assignmentEndDate = new Date(employment.EndDate)

         return (
            (assignmentStartDate <= new Date() && !employment.EndDate) ||
            (assignmentStartDate <= new Date() && assignmentEndDate >= new Date())
         )
      })
      return isEmploymentActive
   }

   return (
      <Box display="flex" alignItems="center">
         {isUserActive(userId)
            ? userTeam().length
               ? userTeam().map(team =>
                    team.TeamLead ? (
                       <Tooltip
                          key={team._id}
                          classes={{ tooltip: classes.tooltip }}
                          arrow
                          title={
                             <Box display="flex" flexDirection="column" alignItems="start">
                                <Typography variant="caption" style={{ fontSize: 10 }}>
                                   {t('TL')}: <b>{getAndWrapUser(team.TeamLead)?.nick}</b>
                                </Typography>
                                <Typography variant="caption" style={{ fontSize: 10 }}>
                                   {team.Name}
                                </Typography>
                             </Box>
                          }
                       >
                          <Avatar
                             style={{
                                color: '#fff',
                                backgroundColor: '#0D2540',
                                padding: '15px',
                                marginLeft: '4px',
                                height: '32px',
                                width: '32px',
                             }}
                          >
                             <Typography>
                                {getAndWrapUser(team.TeamLead)?.initials
                                   ? getAndWrapUser(team.TeamLead).initials
                                   : ''}
                             </Typography>
                          </Avatar>
                       </Tooltip>
                    ) : (
                       t('unassignedToTeam')
                    )
                 )
               : t('unassignedToTeam')
            : t('inactiveEmployment')}
      </Box>
   )
}

export default UserTeamLead
