import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';
import themeConfig from './theme';
import AbsenceOverview from './overview/AbsenceOverview';
import log from './util/log';

const { styles } = themeConfig();

/*
const value=queryString.parse(this.props.location.search);
const token=value.token;
log.debug('token',token)//123
*/
function Home(props) {

    log.debug(JSON.stringify(props));

    return (
        <React.Fragment>
            <AbsenceOverview/>
        </React.Fragment>
    );
}

Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);