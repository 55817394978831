import {
   faArrowAltCircleDown,
   faEdit,
   faLock,
   faPen,
   faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Link, MenuItem, Select, Snackbar, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import axios from 'axios'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import bg from 'date-fns/locale/bg'
import React, { useCallback } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { Prompt } from 'react-router'
import { useRouteMatch } from 'react-router-dom'
import {
   postNewAbsence,
   fetchBankHolidays,
   fetchAllAbsenceRequests,
   fetchCustomerForAuthUser,
   deleteRequest,
   updateRequest,
   updateRecurrence,
   deleteAbsenceRecurrence,
   createAbsenceRequestByAdmin,
   createAbsenceRecurrenceByAdmin,
   fetchActiveUserOverview,
   fetchAbsencesForAbsenceList,
   deleteAbsenceFromAbsenceList,
   updateAbsenceForAbsenceList,
   fetchInactiveUsers,
} from '../Api'
import UserDropdown from '../components/UserDropdown'
import ConfirmDialog from '../ConfirmDialog'
import absenceCodes from '../enums/absenceCode'
import ErrorDialog from '../ErrorDialog'
import { absenceChange, fetchUsers, fetchCustomerData } from '../redux/actions/actions'
import { highlightDaysInRed, sortByComparison, useEffectOnlyOnce } from '../util/helpers'
import {
   calculateAbsenceBetweenDates,
   setDateToEndOfWorkDay,
   setDateToStartOfWorkDay,
} from 'novarto-time'
import themeConfig from '../theme'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { wrapUser } from '../util/wrappers'
import log from '../util/log'
import { withTranslation } from 'react-i18next'
import VacationPaper from '../components/VacationPaper'
import { OpenInNew } from '@mui/icons-material'

registerLocale('sv', sv)
registerLocale('en-GB', en)
registerLocale('bg', bg)

const { styles } = themeConfig()

const useStyles = makeStyles(theme => ({
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
   select: {
      marginTop: '0.25rem',
      width: '100%',
      height: '40px',
      fontSize: '0.875rem',
      borderRadius: '4px',
      '& .MuiOutlinedInput-input': {
         padding: '5px',
         paddingBottom: '2px',
         paddingLeft: '10px',
      },
   },
   datepickerField: {
      '& .react-datepicker-wrapper': {
         width: '100%',
      },
   },
}))

const AbsenceList = ({ ...props }) => {
   const { t, workDay } = props
   const absenceRequestTypes = [
      'SEMESTERFULLDAG',
      'TJANSTLEDIGFULLDAG',
      'TJANSTERESA',
      'LEGAL',
      'DISTANS',
   ]
   const absenceRecurrenceTypes = ['SJUKFULLDAG']
   const classes = useStyles()
   let match = useRouteMatch('/absences/:id')

   const emptyUpdateData = () => {
      return {
         EmployeeID: '',
         AbsenceType: 'SJUK',
         StartDate: new Date(),
      }
   }

   const [state, setState] = React.useState({
      intervalIsSet: false,
      errorMessage: null,
      showError: false,
      showAbsenceDialog: false,
      confirmButtonTitle: null,
      showConfirm: false,
      confirmMessage: null,
      confirmOnChange: null,
      confirmButtonVariant: null,
      confirmTitle: null,
      absenceDialogButtonTitle: null,
      updateAbsenceData: emptyUpdateData(),
      absenceDialogTitle: null,
      performUpdate: false,
      alertMessage: null,
      alertVariant: null,
      sortBy: 'EmployeeID',
      sortReverse: false,
      publicComment: '',
      privateComment: '',
      userComment: '',

      EmployeeID: null,

      absenceType: undefined,
      startDate: null,
      endDate: null,

      editAbsenceId: null,
      editAbsenceRequestId: null,
      editRecurrenceId: null,

      startDateSearch: new Date().setDate(1),
      endDateSearch: null,
      includeLocked: false,
      includeNonSalary: true,

      splitDate: null,
      pickupDate: null,

      dirty: false,
      isFlexOrDistance: false,
   })

   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      severity: 'info',
   })

   const [holidays, setHolidays] = React.useState([])
   const [data, setData] = React.useState([])
   const [requests, setRequests] = React.useState([])
   const [handleFullDates, setHandleFullDates] = React.useState(false)
   const [isFullDayOnly, setIsFullDayOnly] = React.useState(false)
   const [currentUserState, setCurrentUserState] = React.useState({
      wrappedUser: null,
      rawUser: null,
   })
   const [hourOverride, setHourOverride] = React.useState('')
   const [dayOverride, setDayOverride] = React.useState('')
   const [approxDays, setApproxDays] = React.useState(1)
   const [customer, setCustomer] = React.useState(null)
   const [vacationPaper, setVacationPaper] = React.useState(null)

   const [vacationCalc, setVacationCalc] = React.useState([
      {
         year: 2020,
         used: 0,
         requested: 0,
         available: 0,
      },
      {
         year: 2021,
         used: 0,
         requested: 0,
         available: 0,
      },
   ])

   const [userRequests, setUserRequests] = React.useState([])
   const [userAbsences, setUserAbsences] = React.useState([])
   const [activeUsers, setActiveUsers] = React.useState([])
   const [inactiveUsers, setInactiveUsers] = React.useState([])

   const wrapAbsence = (absence, t) => {
      return { label: t(absence.code), value: absence }
   }

   const options = [
      ...Object.values(absenceCodes)
         .filter(absence => !props.inactiveAbsences.includes(absence.code))
         .map(absence => wrapAbsence(absence, t))
         .sort((a1, a2) => (a1.label === a2.label ? 0 : a1.label < a2.label ? -1 : 1)),
   ]

   const nonSalaryOptions = [
      ...Object.values(absenceCodes)
         .filter(absence => !absence.affectsSalary)
         .map(absence => absence.code),
   ]

   const handleFullDateCheckbox = useCallback(() => {
      setHandleFullDates(!handleFullDates)
   }, [handleFullDates])

   const getDayStart = () => {
      return new Date(setDateToStartOfWorkDay(new Date(), workDay))
   }

   const getDayEnd = () => {
      return new Date(setDateToEndOfWorkDay(new Date(), workDay))
   }

   const showMessage = (message, severity) => {
      setSnackbarState({
         open: true,
         message: message,
         severity: severity ? severity : 'info',
      })
   }

   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ open: false, message: '', severity: 'info' })
   }

   const isFormClear = () =>
      state.absenceType === undefined &&
      state.startDate === null &&
      state.editAbsenceId === null &&
      state.editAbsenceRequestId === null &&
      state.editRecurrenceId === null &&
      state.splitDate === null &&
      state.publicComment === '' &&
      state.privateComment === '' &&
      state.userComment === '' &&
      dayOverride === '' &&
      hourOverride === '' &&
      approxDays === 1

   const clearForm = useCallback(() => {
      setState(s => ({
         ...s,
         absenceType: undefined,
         startDate: null,
         endDate: null,
         editAbsenceId: null,
         editAbsenceRequestId: null,
         editRecurrenceId: null,
         updateAbsenceData: emptyUpdateData(),
         splitDate: null,
         pickupDate: null,
         publicComment: '',
         privateComment: '',
         userComment: '',
         dirty: false,
         isFlexOrDistance: false,
      }))
      setDayOverride('')
      setHourOverride('')
      setApproxDays(1)
      setIsFullDayOnly(false)
   }, [setState])

   const calculateTotalTime = useCallback(
      (startDate, endDate) => {
         if (!startDate || !endDate || startDate > endDate) return
         const time = calculateAbsenceBetweenDates(startDate, endDate, holidays, workDay)
         setDayOverride(time.days)
         setHourOverride(time.hours)
      },
      [holidays, workDay]
   )

   const openAbsenceForEdit = useCallback(
      (dat, t) => {
         clearForm()
         const absenceRequest = requests.find(request => request._id === dat.RequestID)
         const userComment = absenceRequest?.CommentByUser ? absenceRequest.CommentByUser : ''
         const absenceType = wrapAbsence(
            Object.values(absenceCodes)
               .filter(absence => !props.inactiveAbsences.includes(absence.code))
               .find(code => code.code === dat.AbsenceType),
            t
         )
         const isFlexOrDistance =
            absenceType.value.code === 'FLEX' ||
            absenceType.value.code === 'DISTANS' ||
            absenceType.value.code === 'FRISKVARD'
         const rawUser = props.users.find(user => user.EmployeeID === dat.EmployeeID)
         const wrappedUser = wrapUser(rawUser)
         setCurrentUserState({ wrappedUser: wrappedUser, rawUser: rawUser })
         setUserAbsences(data.filter(dat => dat.EmployeeID === wrappedUser.value))
         setUserRequests(requests.filter(req => req.EmployeeID === wrappedUser.value))
         calculateTotalTime(Date.parse(dat.StartDate), Date.parse(dat.EndDate))
         setState(s => ({
            ...s,
            EmployeeID: dat.EmployeeID,
            absenceType: absenceType,
            startDate: Date.parse(dat.StartDate),
            endDate: Date.parse(dat.EndDate),
            editAbsenceId: dat._id,
            editAbsenceRequestId: dat.RequestID,
            editRecurrenceId: dat.RecurrenceID,
            publicComment: dat.PublicComment ? dat.PublicComment : '',
            privateComment: dat.PrivateComment ? dat.PrivateComment : '',
            userComment: userComment,
            isFlexOrDistance: isFlexOrDistance,
            dirty: false,
         }))
         setIsFullDayOnly(absenceType.value.fullDayOnly)
         if (dat.HourOverride) setHourOverride(dat.HourOverride)
         if (dat.DayOverride) setDayOverride(dat.DayOverride)
         if (dat.ApproximateDays) setApproxDays(dat.ApproximateDays)
         if (
            (absenceType.value.code === 'FLEX' || absenceType.value.code === 'FRISKVARD') ===
               handleFullDates ||
            (absenceType.value.fullDayOnly && !handleFullDates)
         ) {
            handleFullDateCheckbox()
         }
      },
      [
         clearForm,
         props.users,
         setState,
         handleFullDateCheckbox,
         handleFullDates,
         calculateTotalTime,
         props.inactiveAbsences,
         data,
         requests,
      ]
   )

   const fetchAbsences = (inclusive = false) => {
      fetchAbsencesForAbsenceList(inclusive, props.userInfo).then(res => {
         setData(res.data)
         if (match && match.params && match.params.id) {
            const abs = res.data.find(abs => abs._id === match.params.id)
            if (abs) openAbsenceForEdit(abs, props.t)
         } else if (currentUserState.wrappedUser !== null) {
            setUserAbsences(
               res.data.filter(dat => dat.EmployeeID === currentUserState.wrappedUser.value)
            )
         }
      })
   }

   const fetchCustomer = useCallback(async () => {
      let result = await fetchCustomerForAuthUser(props.userInfo)
      setCustomer(result.customer)
   }, [props.userInfo])

   const fetchActiveUsers = async () => {
      await fetchActiveUserOverview(props.userInfo).then(res => {
         if (res.success) {
            setActiveUsers(res.data)
         } else {
            log.error('Failure to load data; try logging out and in again')
         }
      })
   }
   const getInactiveUsers = async () => {
      await fetchInactiveUsers(props.userInfo).then(res => {
         if (res.success) {
            setInactiveUsers(res.data)
         } else {
            log.error('Failure to load data; try logging out and in again')
         }
      })
   }

   const getDataFromDb = useCallback(async () => {
      await fetchActiveUsers()
      await getInactiveUsers()
      await fetchAbsences(false)
      await fetchBankHolidays(props.userInfo).then(res => {
         if (res.success === 'false') {
            log.error('Failure to load data; try logging out and in again: ')
         } else {
            setHolidays(res)
         }
      })

      const requestsJson = await fetchAllAbsenceRequests(props.userInfo)
      setRequests(requestsJson.requests)
      await fetchCustomer()
   }, [props, fetchAbsences, fetchCustomer])

   useEffectOnlyOnce(() => {
      getDataFromDb()
      props.fetchCustomerData(props.userInfo)
      props.fetchUsers(props.userInfo)
   })

   const sortAbsencesBy = (abs1, abs2) => {
      let firstArgument = abs1[state.sortBy]
      let secondArgument = abs2[state.sortBy]
      if (props.users.length > 0 && state.sortBy === 'EmployeeID') {
         const u1 = props.users.find(u => u.EmployeeID === firstArgument)
         if (u1) firstArgument = u1.FirstName
         const u2 = props.users.find(u => u.EmployeeID === secondArgument)
         if (u2) secondArgument = u2.FirstName
      }
      let result = sortByComparison(firstArgument, secondArgument)
      if (state.sortReverse) result *= -1
      return result
   }

   const setSortBy = sortBy => {
      if (sortBy === state.sortBy) {
         setState(s => ({ ...s, sortReverse: !state.sortReverse }))
      } else {
         setState(s => ({ ...s, sortBy: sortBy, sortReverse: false }))
      }
   }

   const deleteAbsence = idToDelete => {
      deleteAbsenceFromAbsenceList(idToDelete, props.userInfo).then(
         response => {
            log.debug(response)
            clearForm()
            setState(s => ({ ...s, dirty: false }))
            fetchAbsences()
            props.absenceChange(props.userInfo)
         },
         error => {
            log.error(error)
         }
      )
   }

   const deleteRecurrence = async recurrenceID => {
      try {
         const res = await deleteAbsenceRecurrence(recurrenceID, props.userInfo)
         log.debug(res)
      } catch (error) {
         log.error(error)
      }
   }

   const handleChangeEmployee = wrappedUser => {
      if (!wrappedUser) {
         setCurrentUserState({
            wrappedUser: null,
            rawUser: null,
         })
         clearForm()
         setUserAbsences([])
         setUserRequests([])
         setState(s => ({ ...s, EmployeeID: null }))
      } else {
         setState(s => ({ ...s, EmployeeID: wrappedUser.value }))
         setCurrentUserState({
            wrappedUser: wrappedUser,
            rawUser: props.users.find(dat => dat.EmployeeID === wrappedUser.value),
         })
         setUserAbsences(data.filter(dat => dat.EmployeeID === wrappedUser.value))
         setUserRequests(requests.filter(req => req.EmployeeID === wrappedUser.value))
         clearForm()
         setState(s => ({ ...s, dirty: false }))
      }
   }

   const handleChangeAbsenceType = type => {
      const isFlexOrDistance =
         type.value.code === 'FLEX' ||
         type.value.code === 'DISTANS' ||
         type.value.code === 'FRISKVARD'
      if (
         !isFlexOrDistance &&
         ((state.endDate != null && new Date(state.endDate).getMinutes() === 30) ||
            (state.startDate != null && new Date(state.startDate).getMinutes() === 30))
      ) {
         handleChangeStartDate(null)
         handleChangeEndDate(null)
         handleChangeSplitDate(null)
         handleChangePickupDate(null)
         setAlert(props.t('emptyingInvalidDateFieldsWarning'), 'warning')
      }
      setState(s => ({ ...s, absenceType: type, dirty: true, isFlexOrDistance: isFlexOrDistance }))
      setIsFullDayOnly(type.value.fullDayOnly)
      if (
         (type.value.code === 'FLEX' || type.value.code === 'FRISKVARD') === handleFullDates ||
         (type.value.fullDayOnly && !handleFullDates)
      ) {
         handleFullDateCheckbox()
      }
   }

   const handleChangeStartDate = date => {
      if (handleFullDates) {
         date = new Date(setDateToStartOfWorkDay(date, workDay))
      }
      setState(s => ({ ...s, dirty: true, startDate: date }))
      setHourOverride('')
      setDayOverride('')
      calculateTotalTime(date, state.endDate)
   }

   const handleChangeEndDate = date => {
      if (!date) {
         setState(s => ({ ...s, endDate: null }))
      } else {
         if (handleFullDates) {
            date = new Date(setDateToEndOfWorkDay(date, workDay))
         }
         setState(s => ({ ...s, endDate: date }))
      }
      setState(s => ({ ...s, dirty: true }))
      setHourOverride('')
      setDayOverride('')
      calculateTotalTime(state.startDate, date)
   }

   const handleChangeApproxDays = event => {
      setState(s => ({ ...s, dirty: true }))
      setApproxDays(event.target.value)
   }

   const handleChangePublicComment = msg => {
      setState(s => ({ ...s, dirty: true, publicComment: msg }))
   }

   const handleChangePrivateComment = msg => {
      setState(s => ({ ...s, dirty: true, privateComment: msg }))
   }

   const handleChangeUserComment = msg => {
      setState(prevState => ({ ...prevState, dirty: true, userComment: msg }))
   }

   const handleSearchStartDate = date => {
      setState(s => ({
         ...s,
         startDateSearch: date ? new Date(setDateToStartOfWorkDay(date, workDay)) : null,
      }))
   }

   const handleSearchEndDate = date => {
      setState(s => ({
         ...s,
         endDateSearch: date ? new Date((date = setDateToEndOfWorkDay(date, workDay))) : null,
      }))
   }

   const overrideDays = event => {
      setState(s => ({ ...s, dirty: true }))
      setDayOverride(event.target.value)
   }

   const overrideHours = event => {
      setState(s => ({ ...s, dirty: true }))
      setHourOverride(event.target.value)
   }

   const handleIncludeLocked = event => {
      setState(s => ({ ...s, includeLocked: event.target.checked }))

      if (event.target.checked && !data.some(dat => dat.Locked)) {
         fetchAbsences(event.target.checked)
      }
   }

   const handleIncludeNonSalary = event => {
      setState(s => ({ ...s, includeNonSalary: event.target.checked }))
   }

   const handleChangeSplitDate = date => {
      if (date == null) {
         setState(s => ({ ...s, splitDate: null }))
      } else {
         if (handleFullDates) {
            date = new Date(setDateToStartOfWorkDay(date, workDay))
         }
         setState(s => ({ ...s, splitDate: date }))
      }
      setState(s => ({ ...s, dirty: true }))
   }

   const handleChangePickupDate = date => {
      if (date == null) {
         setState(s => ({ ...s, pickupDate: null }))
      } else {
         if (handleFullDates) {
            date = new Date(setDateToEndOfWorkDay(date, workDay))
         }
         setState(s => ({ ...s, pickupDate: date }))
      }
      setState(s => ({ ...s, dirty: true }))
   }

   const handleSaveAbsence = async () => {
      if (state.endDate && state.startDate > state.endDate) {
         setAlert(props.t('invalidIntervalDates'), 'error')
         return
      }

      const calc = calculateAbsenceBetweenDates(state.startDate, state.endDate, holidays, workDay)
      const override = dayOverride !== calc.days || hourOverride !== calc.hours
      const recurrenceID = props.userInfo.EmployeeID + Date.now()

      const input = {
         EmployeeID: state.EmployeeID,
         AbsenceType: state.absenceType.value.code,
         StartDate: state.startDate,
         EndDate: state.endDate,
         DayOverride: override ? dayOverride : null,
         HourOverride: override ? hourOverride : null,
         ApproximateDays: approxDays,
         PublicComment: state.publicComment,
         PrivateComment: state.privateComment,
      }
      if (absenceRequestTypes.includes(state.absenceType.value.code)) {
         const data = {
            EmployeeID: state.EmployeeID,
            AbsenceIntervals: [
               {
                  StartDate: state.startDate,
                  EndDate: state.endDate,
                  Type: state.absenceType.value.code,
               },
            ],
            Status: 'APPROVED',
            RequestedDate: new Date(),
            CommentByUser: state.userComment,
            CreatedByAdmin: true,
         }
         try {
            const res = await createAbsenceRequestByAdmin(data, props.userInfo)
            input.RequestID = res.data.data._id
            input.RequestNumber = res.data.data.RequestNumber
            input.RemoteRequestNumber = res.data.data.RemoteRequestNumber
         } catch (error) {
            log.error(error)
         }
      } else if (absenceRecurrenceTypes.includes(state.absenceType.value.code)) {
         input.RecurrenceID = recurrenceID
         const data = {
            RecurrenceID: recurrenceID,
            EmployeeID: state.EmployeeID,
            AbsenceType: state.absenceType.value.code,
            StartDate: state.startDate,
            PeriodEndDate: state.endDate,
            Periodicity: 'once',
            CommentByAdmin: state.publicComment || state.privateComment,
            CreatedByAdmin: true,
         }
         try {
            await createAbsenceRecurrenceByAdmin(data, props.userInfo)
         } catch (error) {
            log.error(error)
         }
      }
      await postNewAbsence(props.userInfo, input).then(response => {
         log.debug(response)
         if (response.data.error) {
            if (response.data.error.errmsg) {
               setAlert(response.data.error.errmsg, 'danger')
            } else if (response.data.error.message) {
               setAlert(response.data.error.message, 'danger')
            }
            toggleError(true, state.alertMessage)
         } else {
            clearForm()
            setState(s => ({ ...s, dirty: false }))
            fetchAbsences()
            setAlert(t('newAbsenceSaved'), 'success')
            props.absenceChange(props.userInfo)
         }
      })
   }

   const setAlert = (message, variant) => {
      setState(s => ({ ...s, alertMessage: message, alertVariant: variant }))
      showMessage(message, variant)
   }

   const toggleError = (show, message) => {
      setState(s => ({ ...s, showError: show, errorMessage: message }))
   }

   const toggleConfirm = (show, title, message, buttonTitle, buttonVariant, onChange) => {
      setState(s => ({
         ...s,
         showConfirm: show,
         confirmTitle: title,
         confirmMessage: message,
         confirmButtonTitle: buttonTitle,
         confirmButtonVariant: buttonVariant,
         confirmOnChange: onChange,
      }))
   }

   const updateAbsence = () => {
      if (state.endDate && state.startDate > state.endDate) {
         setAlert(props.t('invalidIntervalDates'), 'error')
         return
      }

      log.info('    UPDATE: ' + state.startDate)

      const calc = calculateAbsenceBetweenDates(state.startDate, state.endDate, holidays, workDay)
      const override = dayOverride !== calc.days || hourOverride !== calc.hours

      const data = {
         id: state.editAbsenceId,
         AbsenceType: state.absenceType.value.code,
         StartDate: state.startDate,
         EndDate: state.endDate,
         DayOverride: override ? dayOverride : null,
         HourOverride: override ? hourOverride : null,
         ApproximateDays: approxDays,
         PublicComment: state.publicComment,
         PrivateComment: state.privateComment,
         Locked: false,
      }

      updateAbsenceForAbsenceList(data, props.userInfo).then(response => {
         log.debug(response)
         if (response.data.error) {
            if (response.data.error.errmsg) {
               setAlert(response.data.error.errmsg, 'danger')
            } else if (response.data.error.message) {
               setAlert(response.data.error.message, 'danger')
            }
         } else {
            setAlert(t('Updated!'), 'success')
            clearForm()
            setState(s => ({ ...s, dirty: false }))
            fetchAbsences()
            props.absenceChange(props.userInfo)
         }
      })
      getDataFromDb()
   }

   const updateAbsenceRequest = async () => {
      const data = {
         _id: state.editAbsenceRequestId,
         AbsenceIntervals: [
            {
               StartDate: state.startDate,
               EndDate: state.endDate,
               Type: state.absenceType.value.code,
            },
         ],
         CommentByUser: state.userComment,
      }
      try {
         await updateRequest(props.userInfo, data)
      } catch (error) {
         log.error(error)
      }
   }

   const updateAbsenceRecurrence = async () => {
      const data = {
         RecurrenceID: state.editRecurrenceId,
         AbsenceType: state.absenceType.value.code,
         PeriodEndDate: state.endDate,
      }
      try {
         await updateRecurrence(data, props.userInfo)
      } catch (error) {
         log.error(error)
      }
   }

   const TotalCalc = props => {
      const { dat } = props
      const negative = dat.EndDate && dat.StartDate > dat.EndDate
      let calc = calculateAbsenceBetweenDates(dat.StartDate, dat.EndDate, holidays, workDay)

      let result = <Grid></Grid>

      if (dat.EndDate) {
         const d = dat.DayOverride ? dat.DayOverride : calc.days
         const h = dat.HourOverride ? dat.HourOverride : calc.hours
         const m = dat.DayOverride || dat.HourOverride ? 0 : calc.mins
         result = (
            <React.Fragment>
               <Grid item style={{ width: 15 }}>
                  {negative ? '-' : ''}
               </Grid>
               <Grid item style={{ width: 15 }}>
                  {' '}
                  {d}
               </Grid>
               <Grid item style={{ width: 15 }}>
                  |
               </Grid>
               <Grid item style={{ width: 15 }}>
                  {h}
               </Grid>
               <Grid item style={{ width: 15 }}>
                  |
               </Grid>
               <Grid item style={{ width: 15 }}>
                  {m}
               </Grid>
            </React.Fragment>
         )
      }

      return (
         <React.Fragment>
            <Grid container direction="row" spacing={1} justifyContent="center" alignItems="center">
               {result}
            </Grid>
         </React.Fragment>
      )
   }

   React.useEffect(() => {
      if (customer !== null && currentUserState.rawUser !== null) {
         setVacationPaper(
            <VacationPaper
               t={t}
               setVacationCalc={setVacationCalc}
               user={currentUserState.rawUser}
               absences={userAbsences}
               requests={userRequests}
               holidays={holidays}
               workDay={workDay}
               VacationRefillMMDD={customer.VacationSettings.VacationRefillMMDD}
               ShowNextYearMMDD={customer.VacationSettings.ShowNextYearMMDD}
            />
         )
      }
   }, [
      currentUserState.rawUser,
      setVacationPaper,
      setVacationCalc,
      userAbsences,
      userRequests,
      holidays,
      workDay,
      customer,
      t,
   ])

   if (!data) {
      return null
   }

   if (props.users == null) {
      return (
         <React.Fragment>
            <Typography variant="body2">{t('loadingUserData')}</Typography>
         </React.Fragment>
      )
   }

   return (
      <React.Fragment>
         <ErrorDialog
            {...{
               showError: state.showError,
               message: state.errorMessage,
               toggleError: toggleError,
            }}
         />

         <ConfirmDialog
            {...{
               confirmTitle: state.confirmTitle,
               confirmButtonTitle: state.confirmButtonTitle,
               confirmButtonVariant: state.confirmButtonVariant,
               showConfirm: state.showConfirm,
               message: state.confirmMessage,
               handleConfirm: state.confirmOnChange,
               toggleConfirm: toggleConfirm,
            }}
         />

         <Paper className="contentPaper" style={{ maxWidth: 1200, boxShadow: 'none' }}>
            <div className={'contentWrapper'}>
               <div style={{ padding: 10 }}>
                  <Typography variant="subtitle2" style={{ paddingLeft: 10 }}>
                     {t('Employee')}
                  </Typography>
                  <div style={{ padding: 10 }}>
                     <UserDropdown
                        users={activeUsers}
                        inactiveUsers={inactiveUsers}
                        handleChangeEmployee={handleChangeEmployee}
                        value={currentUserState.wrappedUser}
                     />
                  </div>
               </div>

               {props.useVacationDays && state.EmployeeID && vacationPaper !== null ? (
                  <div style={{ margin: 20 }}>{vacationPaper}</div>
               ) : null}

               {props.userInfo.permissions['absencesManagement']['create'] && (
                  <>
                     <div style={{ padding: 20 }}>
                        <Typography variant="h5" style={{ paddingBottom: 10, fontSize: 22 }}>
                           {t('manageAbsences')}
                        </Typography>
                        <Grid
                           container
                           justifyContent="flex-start"
                           alignItems="center"
                           spacing={1}
                           style={{ paddingBottom: 10 }}
                        >
                           <Grid item xs={4} className={'p-2'}>
                              <Typography variant="body2">{t('absenceReason')}</Typography>
                              <Select
                                 className={classes.select}
                                 variant={'outlined'}
                                 renderValue={() => {
                                    if (!state.absenceType) return t('selectReasonPlaceholder')
                                    return options.find(
                                       option => option.value.code === state.absenceType.value.code
                                    ).label
                                 }}
                                 value={''}
                                 displayEmpty
                                 onChange={event => handleChangeAbsenceType(event.target.value)}
                              >
                                 {options.map((option, i) => (
                                    <MenuItem key={i} value={option}>
                                       {option.label}
                                    </MenuItem>
                                 ))}
                              </Select>
                           </Grid>
                        </Grid>
                        <Grid
                           container
                           justifyContent="flex-start"
                           alignItems="center"
                           spacing={1}
                           style={{ paddingBottom: 10 }}
                        >
                           <Grid item xs={4} className={'p-2'}>
                              <Typography variant="body2">{t('From')}</Typography>
                              <div className={classes.datepickerField}>
                                 <DatePicker
                                    selected={state.startDate}
                                    onChange={date => handleChangeStartDate(date)}
                                    showTimeSelect={!handleFullDates}
                                    timeIntervals={state.isFlexOrDistance ? '30' : '60'}
                                    highlightDates={highlightDaysInRed(holidays)}
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    timeFormat="HH:mm"
                                    locale={t('datepickerLocale')}
                                    showWeekNumbers
                                    yearDropdownItemNumber={3}
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    maxDate={state.endDate}
                                    minTime={getDayStart()}
                                    maxTime={getDayEnd()}
                                    openToDate={state.startDate}
                                    className={'datepicker-field mt-1'}
                                    placeholderText={t('From')}
                                 />
                              </div>
                           </Grid>
                           <Grid item xs={4} className={'p-2'}>
                              <Typography variant="body2">{t('To')}</Typography>
                              <div className={classes.datepickerField}>
                                 <DatePicker
                                    disabled={!state.startDate}
                                    selected={state.endDate}
                                    onChange={date => handleChangeEndDate(date)}
                                    showTimeSelect={!handleFullDates}
                                    timeIntervals={state.isFlexOrDistance ? '30' : '60'}
                                    highlightDates={highlightDaysInRed(holidays)}
                                    dateFormat={'yyyy-MM-dd HH:mm'}
                                    timeFormat="HH:mm"
                                    locale={t('datepickerLocale')}
                                    showWeekNumbers
                                    yearDropdownItemNumber={3}
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    minDate={state.startDate}
                                    minTime={getDayStart()}
                                    maxTime={getDayEnd()}
                                    openToDate={state.startDate}
                                    className={'datepicker-field mt-1'}
                                    placeholderText={t('To')}
                                 />
                              </div>
                           </Grid>
                        </Grid>
                        <Grid
                           container
                           justifyContent="flex-start"
                           alignItems="center"
                           spacing={1}
                           style={{ paddingBottom: 10 }}
                        >
                           <Grid item xs={4} className={'p-2'}>
                              <Typography variant="body2">{t('totalAmountDays')}</Typography>
                              <TextField
                                 id="dO"
                                 variant="outlined"
                                 value={dayOverride}
                                 onChange={event => overrideDays(event)}
                                 className={classes.textField}
                                 placeholder="0"
                              />
                           </Grid>
                           <Grid item xs={4} className={'p-2'}>
                              <Typography variant="body2">{t('totalAmountHours')}</Typography>
                              <TextField
                                 id="hO"
                                 variant="outlined"
                                 value={hourOverride}
                                 onChange={event => overrideHours(event)}
                                 className={classes.textField}
                                 placeholder="0"
                              />
                           </Grid>
                        </Grid>
                        <Grid
                           container
                           justifyContent="flex-start"
                           alignItems="center"
                           spacing={1}
                           style={{ paddingBottom: 10 }}
                        >
                           {absenceRecurrenceTypes.includes(state.absenceType?.value?.code) && (
                              <>
                                 <Grid item xs={4} className={'p-2'}>
                                    <Typography variant="body2">{t('publicComment')}</Typography>
                                    <TextField
                                       variant="outlined"
                                       value={state.publicComment}
                                       onChange={event =>
                                          handleChangePublicComment(event.target.value)
                                       }
                                       className={classes.textField}
                                    />
                                 </Grid>
                                 <Grid item xs={4} className={'p-2'}>
                                    <Typography variant="body2">{t('privateComment')}</Typography>
                                    <TextField
                                       variant="outlined"
                                       value={state.privateComment}
                                       onChange={event =>
                                          handleChangePrivateComment(event.target.value)
                                       }
                                       className={classes.textField}
                                    />
                                 </Grid>
                              </>
                           )}
                           {absenceRequestTypes.includes(state.absenceType?.value?.code) && (
                              <Grid item xs={8} className={'p-2'}>
                                 <Typography variant="body2">{t('commentLabel')}</Typography>
                                 <TextField
                                    variant="outlined"
                                    value={state.userComment}
                                    onChange={event => handleChangeUserComment(event.target.value)}
                                    className={classes.textField}
                                 />
                              </Grid>
                           )}
                        </Grid>
                        <Button
                           size="small"
                           style={{ marginLeft: 5 }}
                           disabled={
                              !(
                                 currentUserState.wrappedUser &&
                                 state.absenceType &&
                                 state.startDate
                              )
                           }
                           variant="contained"
                           color="primary"
                           onClick={() => handleSaveAbsence()}
                        >
                           {t('saveAsNewAbsence')}
                        </Button>

                        <Button
                           size="small"
                           style={{ marginLeft: 10 }}
                           disabled={
                              !state.dirty ||
                              !state.editAbsenceId ||
                              data.filter(dat => dat._id === state.editAbsenceId)[0].Locked
                           }
                           variant={
                              state.editAbsenceId &&
                              !data.filter(dat => dat._id === state.editAbsenceId)[0].Locked
                                 ? 'outlined'
                                 : 'contained'
                           }
                           color={'primary'}
                           onClick={() => {
                              updateAbsence()
                              absenceRequestTypes.includes(state.absenceType.value.code) &&
                                 state.editAbsenceRequestId &&
                                 updateAbsenceRequest()
                              absenceRecurrenceTypes.includes(state.absenceType.value.code) &&
                                 state.editRecurrenceId &&
                                 updateAbsenceRecurrence()
                           }}
                        >
                           {t('saveAsUpdatedAbsence')}
                        </Button>

                        <Button
                           size="small"
                           style={{ marginLeft: 400 }}
                           disabled={isFormClear()}
                           variant="outlined"
                           color="secondary"
                           onClick={() => clearForm()}
                        >
                           {t('clearFields')}
                        </Button>
                     </div>
                  </>
               )}

               <div style={{ padding: 20 }}>
                  <Typography variant="h5" style={{ paddingBottom: 10, fontSize: 22 }}>
                     {t('filterAbsences')}
                  </Typography>
                  <Grid
                     container
                     justifyContent="flex-start"
                     alignItems="center"
                     spacing={1}
                     style={{ paddingBottom: 10 }}
                  >
                     <Grid item xs={4} className={'p-2'}>
                        <Typography variant="body2">{t('startDate')}</Typography>
                        <div className={classes.datepickerField}>
                           <DatePicker
                              className={'datepicker-field mt-1'}
                              selected={state.startDateSearch}
                              onChange={date => handleSearchStartDate(date)}
                              dateFormat="yyyy-MM-dd"
                              locale={t('datepickerLocale')}
                              showWeekNumbers
                              yearDropdownItemNumber={3}
                              showMonthDropdown={true}
                              showYearDropdown={true}
                              maxDate={state.endDateSearch}
                           />
                        </div>
                     </Grid>
                     <Grid item xs={4} className={'p-2'}>
                        <Typography variant="body2">{t('endDate')}</Typography>
                        <div className={classes.datepickerField}>
                           <DatePicker
                              className={'datepicker-field mt-1'}
                              selected={state.endDateSearch}
                              onChange={date => handleSearchEndDate(date)}
                              dateFormat={'yyyy-MM-dd'}
                              locale={t('datepickerLocale')}
                              showWeekNumbers
                              yearDropdownItemNumber={3}
                              showMonthDropdown={true}
                              showYearDropdown={true}
                              minDate={state.startDateSearch}
                              openToDate={state.startDateSearch}
                           />
                        </div>
                     </Grid>
                  </Grid>

                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                     <div>
                        <Checkbox
                           checked={state.includeLocked}
                           onChange={event => handleIncludeLocked(event)}
                           color={'secondary'}
                        />
                        {t('includeLocked')}
                     </div>
                     <div>
                        <Checkbox
                           checked={state.includeNonSalary}
                           onChange={event => handleIncludeNonSalary(event)}
                           color={'secondary'}
                        />
                        {t('includeNonSalary')}
                     </div>
                  </div>
               </div>

               <TableContainer
                  className="absenceTable"
                  component={Paper}
                  style={{ maxHeight: 500, boxShadow: 'none' }}
               >
                  <Table size="small">
                     <TableHead style={{ backgroundColor: 'white', position: 'sticky', top: 0 }}>
                        <TableRow>
                           <TableCell></TableCell>
                           <TableCell onClick={() => setSortBy('EmployeeID')}>
                              {t('Employee')}{' '}
                              {state.sortBy === 'EmployeeID' ? (
                                 <FontAwesomeIcon
                                    color={
                                       state.sortBy === 'EmployeeID' ? 'deeppink' : 'deepskyblue'
                                    }
                                    icon={faArrowAltCircleDown}
                                    rotation={state.sortReverse ? 180 : 0}
                                 />
                              ) : null}
                           </TableCell>
                           <TableCell onClick={() => setSortBy('AbsenceType')}>
                              {t('absenceReason')}{' '}
                              {state.sortBy === 'AbsenceType' ? (
                                 <FontAwesomeIcon
                                    color={
                                       state.sortBy === 'AbsenceType' ? 'deeppink' : 'deepskyblue'
                                    }
                                    icon={faArrowAltCircleDown}
                                    rotation={state.sortReverse ? 180 : 0}
                                 />
                              ) : null}
                           </TableCell>
                           <TableCell onClick={() => setSortBy('StartDate')}>
                              {t('From')}{' '}
                              {state.sortBy === 'StartDate' ? (
                                 <FontAwesomeIcon
                                    color={
                                       state.sortBy === 'StartDate' ? 'deeppink' : 'deepskyblue'
                                    }
                                    icon={faArrowAltCircleDown}
                                    rotation={state.sortReverse ? 180 : 0}
                                 />
                              ) : null}
                           </TableCell>
                           <TableCell onClick={() => setSortBy('EndDate')}>
                              {t('To')}{' '}
                              {state.sortBy === 'EndDate' ? (
                                 <FontAwesomeIcon
                                    color={state.sortBy === 'EndDate' ? 'deeppink' : 'deepskyblue'}
                                    icon={faArrowAltCircleDown}
                                    rotation={state.sortReverse ? 180 : 0}
                                 />
                              ) : null}
                           </TableCell>
                           <TableCell onClick={() => setSortBy('CreatedBy')}>
                              {t('createdBy')}{' '}
                              {state.sortBy === 'CreatedBy' ? (
                                 <FontAwesomeIcon
                                    color={
                                       state.sortBy === 'CreatedBy' ? 'deeppink' : 'deepskyblue'
                                    }
                                    icon={faArrowAltCircleDown}
                                    rotation={state.sortReverse ? 180 : 0}
                                 />
                              ) : null}
                           </TableCell>
                           <TableCell onClick={() => setSortBy('CreatedAt')}>
                              {t('createdAt')}{' '}
                              {state.sortBy === 'CreatedAt' ? (
                                 <FontAwesomeIcon
                                    color={
                                       state.sortBy === 'CreatedAt' ? 'deeppink' : 'deepskyblue'
                                    }
                                    icon={faArrowAltCircleDown}
                                    rotation={state.sortReverse ? 180 : 0}
                                 />
                              ) : null}
                           </TableCell>
                           <TableCell align="center">
                              <Typography variant="subtitle2" color="textSecondary">
                                 <i>{t('totalAbsenceText')}</i>
                              </Typography>
                           </TableCell>
                           <TableCell>{t('аpproverText')}</TableCell>
                           <TableCell></TableCell>
                           <TableCell>{t('PrintForm')}</TableCell>
                        </TableRow>
                     </TableHead>

                     <TableBody>
                        {data != null &&
                        (data.length <= 0 ||
                           (state.EmployeeID &&
                              !(
                                 data.filter(dat => dat.EmployeeID === state.EmployeeID).length > 0
                              ))) ? (
                           <TableRow key={1}>
                              <TableCell colSpan={13} variant="footer">
                                 {t('noAbsence')}
                              </TableCell>
                           </TableRow>
                        ) : (
                           data
                              .filter(dat =>
                                 props.users.find(user => user.EmployeeID === dat.EmployeeID)
                              )
                              .filter(
                                 dat => !state.EmployeeID || dat.EmployeeID === state.EmployeeID
                              )
                              .filter(
                                 dat =>
                                    !state.startDateSearch ||
                                    new Date(dat.StartDate) >= state.startDateSearch ||
                                    new Date(dat.EndDate) >= state.startDateSearch
                              )
                              .filter(
                                 dat =>
                                    !state.endDateSearch ||
                                    !dat.EndDate ||
                                    new Date(dat.EndDate) <= state.endDateSearch ||
                                    new Date(dat.StartDate) <= state.endDateSearch
                              )
                              .filter(
                                 dat =>
                                    state.includeNonSalary ||
                                    !nonSalaryOptions.includes(dat.AbsenceType)
                              )
                              .filter(dat => state.includeLocked || !dat.Locked)
                              .sort((dat1, dat2) => sortAbsencesBy(dat1, dat2))
                              .map(dat => (
                                 <TableRow
                                    key={dat._id}
                                    selected={!dat.EndDate}
                                    onClick={() => openAbsenceForEdit(dat, t)}
                                 >
                                    <TableCell>
                                       <div style={{ display: 'flex', flexDirection: 'row' }}>
                                          {props.userInfo.permissions['absencesManagement'][
                                             'upgrade'
                                          ] && (
                                             <FontAwesomeIcon
                                                icon={
                                                   state.editAbsenceId === dat._id ? faPen : faEdit
                                                }
                                                color={
                                                   state.editAbsenceId === dat._id
                                                      ? 'green'
                                                      : 'black'
                                                }
                                                onClick={() => openAbsenceForEdit(dat, t)}
                                             />
                                          )}

                                          {props.userInfo.permissions['absencesManagement'][
                                             'delete'
                                          ] &&
                                             !dat.Locked && (
                                                <FontAwesomeIcon
                                                   style={{ marginLeft: 5 }}
                                                   icon={faTrash}
                                                   onClick={event => {
                                                      event.stopPropagation()
                                                      toggleConfirm(
                                                         true,
                                                         t('confirmDeleteTitle'),
                                                         t('confirmDeleteAbsenceText') +
                                                            ': [' +
                                                            dat.AbsenceType +
                                                            ', ' +
                                                            t('period') +
                                                            ': ' +
                                                            new Date(dat.StartDate).toLocaleString(
                                                               'sv-SE'
                                                            ) +
                                                            ' ~ ' +
                                                            new Date(dat.EndDate).toLocaleString(
                                                               'sv-SE'
                                                            ) +
                                                            '] ' +
                                                            t('for') +
                                                            ' ' +
                                                            wrapUser(
                                                               props.users.find(
                                                                  user =>
                                                                     user.EmployeeID ===
                                                                     dat.EmployeeID
                                                               )
                                                            ).label +
                                                            '?',
                                                         t('deleteAbsenceButtonTitle'),
                                                         'danger',
                                                         async () => {
                                                            absenceRecurrenceTypes.includes(
                                                               dat.AbsenceType
                                                            ) &&
                                                               dat.RecurrenceID &&
                                                               data.filter(
                                                                  absence =>
                                                                     absence.RecurrenceID ===
                                                                     dat.RecurrenceID
                                                               ).length === 1 &&
                                                               (await deleteRecurrence(
                                                                  dat.RecurrenceID
                                                               ))
                                                            absenceRequestTypes.includes(
                                                               dat.AbsenceType
                                                            ) &&
                                                               dat.RequestID &&
                                                               (await deleteRequest(
                                                                  dat.RequestID,
                                                                  props.userInfo,
                                                                  dat.CommentByAdmin,
                                                                  true
                                                               ))
                                                            deleteAbsence(dat._id)
                                                         }
                                                      )
                                                   }}
                                                />
                                             )}
                                       </div>
                                    </TableCell>

                                    <TableCell
                                       variant={state.editAbsenceId === dat._id ? 'head' : 'body'}
                                    >
                                       {
                                          wrapUser(
                                             props.users.find(u => u.EmployeeID === dat.EmployeeID)
                                          ).label
                                       }
                                    </TableCell>

                                    <TableCell
                                       variant={state.editAbsenceId === dat._id ? 'head' : 'body'}
                                    >
                                       {t(dat.AbsenceType)}
                                    </TableCell>

                                    <TableCell
                                       variant={state.editAbsenceId === dat._id ? 'head' : 'body'}
                                    >
                                       <Moment date={dat.StartDate} format="YYYY-MM-DD HH:mm" />
                                    </TableCell>

                                    <TableCell
                                       variant={state.editAbsenceId === dat._id ? 'head' : 'body'}
                                    >
                                       {dat.EndDate ? (
                                          <Moment date={dat.EndDate} format="YYYY-MM-DD HH:mm" />
                                       ) : (
                                          ''
                                       )}
                                    </TableCell>

                                    <TableCell
                                       variant={state.editAbsenceId === dat._id ? 'head' : 'body'}
                                    >
                                       {dat.CreatedBy
                                          ? wrapUser(
                                               props.users.find(u => u.EmployeeID === dat.CreatedBy)
                                            ).label
                                          : ''}
                                    </TableCell>

                                    <TableCell
                                       variant={state.editAbsenceId === dat._id ? 'head' : 'body'}
                                    >
                                       {dat.CreatedAt ? (
                                          <Moment date={dat.CreatedAt} format="YYYY-MM-DD HH:mm" />
                                       ) : (
                                          ''
                                       )}
                                    </TableCell>

                                    <TableCell
                                       variant={state.editAbsenceId === dat._id ? 'head' : 'body'}
                                       align="center"
                                    >
                                       <TotalCalc dat={dat} />
                                    </TableCell>
                                    <TableCell
                                       variant={state.editAbsenceId === dat._id ? 'head' : 'body'}
                                       align="center"
                                    >
                                       {dat.Approver && dat.Approver !== '' ? dat.Approver : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                       {dat.Locked ? (
                                          <FontAwesomeIcon
                                             icon={faLock}
                                             color={
                                                state.editAbsenceId === dat._id ? 'red' : 'black'
                                             }
                                          />
                                       ) : null}
                                    </TableCell>
                                    <TableCell>
                                       {Object.values(absenceCodes).find(
                                          code => code.code === dat.AbsenceType
                                       )?.isPrintable &&
                                          dat.RequestID && (
                                             <Link
                                                target={'_blank'}
                                                href={`/showrequest/html/${dat.RequestID}/${t(
                                                   'locale'
                                                )}/0`}
                                             >
                                                <OpenInNew />
                                             </Link>
                                          )}
                                    </TableCell>
                                 </TableRow>
                              ))
                        )}
                     </TableBody>
                  </Table>
               </TableContainer>
            </div>
         </Paper>
         <Snackbar
            open={snackbarState.open}
            autoHideDuration={6000}
            onClose={(event, reason) => closeSnackbar(event, reason)}
         >
            <Alert
               onClose={(event, reason) => closeSnackbar(event, reason)}
               severity={snackbarState.severity}
            >
               {snackbarState.message}
            </Alert>
         </Snackbar>
         <Prompt when={state.dirty} message={t('leavePagePrompt')} />
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      users: state.users,
      timezone: state.timezone,
      inactiveAbsences: state.inactiveAbsences,
      useVacationDays: state.useVacationDays,
      workDay: state.workDay,
   }
}

export default withStyles(styles)(
   withTranslation()(
      connect(mapStateToProps, { absenceChange, fetchUsers, fetchCustomerData })(AbsenceList)
   )
)
