import CssBaseline from '@mui/material/CssBaseline'
import Hidden from '@mui/material/Hidden'
import withStyles from '@mui/styles/withStyles'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import Copyright from './Copyright'
import Header from './Header'
import Navigator from './Navigator'
import themeConfig from './theme'
import { withTranslation } from 'react-i18next'
import './css/overflow.scss'
import { authenticate, logout } from './redux/actions/actions'
import axios from 'axios'
import log from './util/log'
import { BACKEND_URL } from './util/dependencies'
import firebase from 'firebase'

const { styles, drawerWidth } = themeConfig()

const PrivateRoute = ({
   path,
   component: Component,
   title,
   navItem,
   loggedIn,
   userInfo,
   permissionComponent,
   superAdmin,
   ServerVersion,
   ...otherProps
}) => {
   const { classes, t, i18n } = otherProps

   useEffect(() => {
      console.log('************** PrivateRoute useEffect')

      async function setLang() {
         // set language from userInfo, required for reload of pages
         if (t('locale') !== userInfo.Language) {
            i18n.changeLanguage(userInfo.Language)
         }
      }

      if (userInfo) {
         setLang()
      }
   }, [userInfo, t, i18n])

   const checkLogin = async () => {
      // Make sure we still have a valid login. Otherwise redirect to
      // login page.
      if (userInfo != null) {
         await axios({
            method: 'GET',
            url: BACKEND_URL + '/auth/isValidLogin',
            headers: {
               Authorization: userInfo.googleAccessToken,
               'Cache-Control': 'no-store',
            },
            data: { token: userInfo.googleAccessToken },
         })
            .then(response => {
               //console.log("AXIOS response" + JSON.stringify(response));

               if (!response.data.valid) {
                  otherProps.logout()
               }

               // do nothing, login is still valid
            })
            .catch(error => {
               console.log('ERROR: ' + error)
               otherProps.logout()
            })
      }
   }

   const performLogout = () => {
      //remove selected filter on logout
      if (localStorage.getItem('hrAppliedFilters')) {
         localStorage.removeItem('hrAppliedFilters')
      }
      // IF we are signed in with firebase, we need to logout.
      if (userInfo.loginProvider === 'gip') {
         firebase
            .auth()
            .signOut()
            .then(() => {
               console.log('Firebase sign out successfull')
               otherProps.logout()
            })
            .catch(error => {
               console.log('Error sign out: ' + error)
            })
      } else {
         otherProps.logout()
      }
   }
   // Make sure login is still valid
   checkLogin()

   let loginPath = '/login'

   if (!loggedIn) {
      return <Redirect to={loginPath} />
   } else if (!userInfo.permissions || !Object.keys(userInfo.permissions).length) {
      performLogout()
      return <Redirect to={loginPath} />
   } else if (!userInfo.permissions.reportsAndPlaning) {
      performLogout()
      return <Redirect to={loginPath} />
   } else if (permissionComponent && !userInfo.permissions[permissionComponent]['read']) {
      return <Redirect to={loginPath} />
   } else if (superAdmin && !userInfo.superAdmin) {
      return <Redirect to={loginPath} />
   } else if (!localStorage.getItem('novartoHrNewLogin')) {
      performLogout()
      return <Redirect to={loginPath} />
   } else {
      log.debug('************* PrivateRoute render')
      return (
         <Route
            path={path}
            render={otherProps => (
               <>
                  <div className={classes.root}>
                     <CssBaseline />
                     <nav className={classes.drawer}>
                        <Navigator
                           navItem={navItem}
                           PaperProps={{ style: { width: drawerWidth } }}
                        />
                     </nav>
                     <div className={classes.app}>
                        <Header
                           title={title}
                           buttonLink={
                              window.location.href.includes('/dashboard') ? '/' : '/dashboard'
                           }
                        />

                        <main className={classes.main}>
                           <Component {...otherProps} />
                        </main>

                        <footer className={classes.footer}>
                           <Copyright ServerVersion={ServerVersion} />
                        </footer>
                     </div>
                  </div>
               </>
            )}
         />
      )
   }
}

PrivateRoute.propTypes = {
   classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      ServerVersion: state.ServerVersion,
   }
}

export default withStyles(styles)(
   withTranslation()(connect(mapStateToProps, { authenticate, logout })(PrivateRoute))
)
