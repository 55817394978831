import React from 'react'
import { withTranslation } from 'react-i18next'
import { Typography, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { getAndWrapUser } from '../../../../../util/wrappers'
import { getNickOrLabel } from '../../../../../util/userProps'

const useStyles = makeStyles({
   noBorder: {
      border: 'none',
      boxShadow: 'none',
      zIndex: 0,
   },
})

const FilterTitle = ({ t, item, wrappedUserData }) => {
   return (
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
         <Typography sx={{ color: 'ProjectManager' in item ? '#C49CD3' : '#008DC6' }} variant="h6">
            {item.label}
         </Typography>

         {'ProjectManager' in item && (
            <Typography sx={{ color: '#C49CD3' }} variant="overline">
               <Typography>
                  {t('PM')}:{' '}
                  {item.ProjectManager
                     ? getNickOrLabel(getAndWrapUser(wrappedUserData, item.ProjectManager))
                     : 'Not Assigned'}
               </Typography>
            </Typography>
         )}
         {'TeamLead' in item && (
            <Typography sx={{ color: '#008DC6' }} variant="overline">
               <Typography>
                  {t('TL')}:{' '}
                  {item.TeamLead
                     ? getNickOrLabel(getAndWrapUser(wrappedUserData, item.TeamLead))
                     : 'Not Assigned'}
               </Typography>
            </Typography>
         )}
      </Box>
   )
}

export default withTranslation()(FilterTitle)
