import React from 'react'
import { withTranslation } from 'react-i18next'
import TimesheetManagementTableRow from '../../../UI/TimesheetManagementTableRow'
import FilterTitle from '../../../../../../overview/components/table/dataRows/components/FilterTitle'
import { TableCell } from '@mui/material'

const TimesheetManagementFilteredRows = React.memo(
   ({
      t,
      filteredUsers,
      chosenDateWeek,
      getRelevantColourClass,
      reportDay,
      openModal,
      isHaveReports,
      calculateProjectActualHours,
      calculateProjectExpectedHours,
      isTodayAfterLastWeek,
      teamProjectFilters,
      tabIndex,
      newDataFetching,
   }) => {
      const selectedTabOption = [teamProjectFilters[tabIndex]]
      const filteredMembersId = selectedTabOption[0].members
      const members = selectedTabOption[0].membersDetails
         ? selectedTabOption[0].membersDetails
              .filter(member => member.isActive === true)
              .map(member => member._id)
         : filteredMembersId

      const matchedUsers = filteredUsers.filter(user => members.includes(user._id))

      const props = {
         t,
         pageData: matchedUsers,
         filteredUsers,
         chosenDateWeek,
         getRelevantColourClass,
         reportDay,
         openModal,
         isHaveReports,
         calculateProjectActualHours,
         calculateProjectExpectedHours,
         isTodayAfterLastWeek,
         newDataFetching,
      }
      return (
         <>
            <TableCell colSpan={18} sx={{ border: 'none' }}>
               <FilterTitle item={selectedTabOption[0]} wrappedUserData={filteredUsers} />
            </TableCell>
            <TimesheetManagementTableRow {...props} />
         </>
      )
   }
)

export default withTranslation()(TimesheetManagementFilteredRows)
