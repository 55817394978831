import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Button, Grid, Modal, TextField, Typography } from '@mui/material'
import { withTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import { createTeam } from '../../Api'
import { Autocomplete } from '@mui/material';
import { wrapUser } from '../../util/wrappers'

function getModalStyle() {
   return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
   }
}

const useStyles = makeStyles(theme => ({
   paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
   },
   buttonsPosition: {
      float: 'right',
   },
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
   mainAutocomplete: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiInputBase-root': {
         height: '55px',
         padding: 0,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
         padding: 0,
         paddingLeft: '10px',
      },
   },
}))

const CreateTeamModal = ({
   openModal,
   toggleModal,
   userInfo,
   showSuccessfulSnackbar,
   showErrorSnackbar,
   fetchTeams,
   setSelectedTeam,
   activeUsers,
   setSelectedTeamMemberForDetails,
   t,
}) => {
   const initialTeamData = {
      Name: '',
   }
   const [teamData, setTeamData] = useState(initialTeamData)
   const [errorField, setErrorField] = useState(false)
   const [teamLeadId, setTeamLeadId] = useState(null)
   const [value, setValue] = useState(null)
   const classes = useStyles()
   const [modalStyle] = React.useState(getModalStyle)

   const updateTeamData = (property, data) => {
      setTeamData(prevState => {
         return { ...prevState, [property]: data }
      })
   }

   const handleCreateTeam = () => {
      if (teamData.Name.trim() === '') {
         setErrorField(true)
      } else {
         innerToggleModal()
         createTeam(teamData.Name, teamLeadId ? [teamLeadId] : [], teamLeadId, userInfo)
            .then(res => {
               setSelectedTeam({
                  id: res.data._id,
                  label: res.data.Name,
                  members: res.data.MemberIDs,
                  TeamLead: res.data.TeamLead,
                  PastMemberIDs: [],
               })
               fetchTeams(res.data.CustomerID)
               showSuccessfulSnackbar(t('successfullyCreatedTeam'))
            })
            .catch(() => showErrorSnackbar(t('errorCreatingTeam')))
      }
   }

   const innerToggleModal = () => {
      setTeamData(initialTeamData)
      setTeamLeadId(null)
      setValue(null)
      setSelectedTeamMemberForDetails(null)
      errorField && setErrorField(false)
      toggleModal()
   }

   return (
      <Modal
         open={openModal}
         onClose={innerToggleModal}
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
         style={{ overflow: 'scroll' }}
      >
         <Paper className={classes.paper} style={modalStyle}>
            <h5 id="simple-modal-title">{t('createNewTeam')}</h5>
            <Grid
               container
               direction="row"
               justifyContent="flex-start"
               alignItems="flex-start"
               spacing={1}
               style={{ padding: 10, paddingBottom: 10 }}
            >
               <Grid item xs={12} className={'pb-1 my-1'}>
                  <Typography variant="body2">{t('teamName')}</Typography>
                  <TextField
                     id="outlined-multiline-static"
                     variant="outlined"
                     className={classes.textField}
                     value={teamData.Name}
                     onChange={e => {
                        if (e.target.value.trim() === '') {
                           setErrorField(true)
                        } else {
                           setErrorField(false)
                        }
                        updateTeamData('Name', e.target.value)
                     }}
                     placeholder={
                        !errorField ? t('teamName') : t('createProjectModalTitleErrorField')
                     }
                     error={errorField}
                  />
               </Grid>
               <Grid item xs={12} className={'pb-1 my-1'}>
                  <Typography variant="body2">{t('TeamLead')}</Typography>
                  <Autocomplete
                     value={value}
                     getOptionLabel={option => option.label}
                     isOptionEqualToValue={(option, value) => option.label === value.label}
                     onChange={(event, newValue) => {
                        setTeamLeadId(newValue?._id)
                        setValue(newValue)
                     }}
                     disablePortal
                     id="combo-box-demo"
                     options={activeUsers.map(user => wrapUser(user))}
                     style={{ marginTop: '0.25rem' }}
                     renderInput={params => (
                        <TextField {...params} variant={'outlined'} style={{ height: '42px' }} />
                     )}
                     className={classes.mainAutocomplete}
                  />
               </Grid>
            </Grid>
            <div className={classes.buttonsPosition}>
               <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleCreateTeam()}
                  disabled={errorField}
               >
                  {t('saveButtonTitle')}
               </Button>
               <Button
                  color={'secondary'}
                  size="small"
                  variant="contained"
                  onClick={innerToggleModal}
                  className={'ml-2'}
               >
                  {t('cancelButtonTitle')}
               </Button>
            </div>
         </Paper>
      </Modal>
   );
}

export default withTranslation()(CreateTeamModal)
