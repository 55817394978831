import { Avatar, Box, Grid, Typography, Skeleton, Tooltip, CircularProgress } from '@mui/material'
import { withTranslation } from 'react-i18next'
import ColorSquare from './UI/ColorSquare'
import underTimeIcon from '../../../../timesheetManagement/icon/Vector (1).svg'
import overTimeIcon from '../../../../timesheetManagement/icon/Vector.svg'
import { formatNumber } from '../../../../../util/helpers'
import { Error as ErrorIcon } from '@mui/icons-material'

const ProjectOverviewMemberDetails = ({ project, isFetching, t }) => {
   const isExternal = project.Type === 'External'
   const Deviation = (logged, planned) => {
      if (logged - planned < 0) {
         return true
      } else {
         return false
      }
   }

   const isTimeLogged = member => {
      if (
         member.billable === 0 &&
         member.nonBillable === 0 &&
         member.logged === 0 &&
         member.idleTime === 0
      ) {
         return false
      } else {
         return true
      }
   }

   return (
      <Box sx={{ p: '0 20px' }}>
         <Grid container>
            <Grid item xs={isExternal ? 5 : 7}>
               <Typography sx={{ fontSize: 11, fontWeight: 500 }}>{t('employeeName')}</Typography>
            </Grid>
            <Grid item xs={1}>
               <Typography sx={{ fontSize: 11, textAlign: 'center', fontWeight: 500 }}>
                  {t('plannedHours')}
               </Typography>
            </Grid>
            <Grid item xs={1}>
               <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <ColorSquare color={'#008DC6'} />
                  <Typography sx={{ fontSize: 11, fontWeight: 500 }}> {t('logged')}</Typography>
               </Box>
            </Grid>
            {isExternal && (
               <>
                  <Grid item xs={1}>
                     <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <ColorSquare color={'#00B8B8'} />
                        <Typography sx={{ fontSize: 11, fontWeight: 500 }}>
                           {t('billable')}
                        </Typography>
                     </Box>
                  </Grid>
                  <Grid item xs={1}>
                     <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <ColorSquare color={'#2D3A73'} />
                        <Typography sx={{ fontSize: 11, fontWeight: 500 }}>
                           {t('nonBillable')}
                        </Typography>
                     </Box>
                  </Grid>
               </>
            )}
            <Grid item xs={1}>
               <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <ColorSquare color={'#FFAD5B'} />
                  <Typography sx={{ fontSize: 11, fontWeight: 500 }}>
                     {t('idleTimeProjectReport')}
                  </Typography>
               </Box>
            </Grid>
            <Grid item xs={1}>
               <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <ColorSquare color={'deviation'} />
                  <Typography sx={{ fontSize: 11, fontWeight: 500 }}>{t('deviation')}</Typography>
               </Box>
            </Grid>
            <Grid item xs={1}>
               <Typography sx={{ fontSize: 11, fontWeight: 500, textAlign: 'center' }}>
                  {t('workPercent')}
               </Typography>
            </Grid>
         </Grid>

         {project.MemberDetails.length > 0 ? (
            project.MemberDetails.map(member =>
               isFetching ? (
                  <Skeleton sx={{ m: '13px 0' }} variant="rounded" height={50} />
               ) : (
                  <Grid
                     key={member._id}
                     container
                     sx={{
                        border: isTimeLogged(member)
                           ? '1.6px solid #E0E0E0'
                           : '1.6px solid #E58C9C',
                        borderRadius: '4px',
                        m: '10px 0',
                        alignItems: 'center',
                        p: '5px 0',
                        '&:hover': {
                           bgcolor: '#EDF6FA',
                        },
                     }}
                  >
                     <Grid item xs={isExternal ? 5 : 7}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                           <Box sx={{ alignSelf: 'center', p: '0 15px' }}>
                              <Avatar
                                 sx={{
                                    border: isTimeLogged(member)
                                       ? '0.1px solid #8ACCE6'
                                       : '0.1px solid #E58C9C',
                                    bgcolor: '#fff',
                                    color: isTimeLogged(member) ? '#8ACCE6' : '#E58C9C',
                                 }}
                              >
                                 {member.Name.charAt(0)}
                              </Avatar>
                           </Box>
                           <Box sx={{ mt: '3px' }}>
                              <Typography sx={{ fontSize: 14, fontWeight: 600, lineHeight: 1 }}>
                                 {member.Name}
                              </Typography>
                              <Typography sx={{ fontSize: 10, color: '#757575', fontWeight: 500 }}>
                                 {member.AssignmentForPeriod?.ProjectRole
                                    ? member.AssignmentForPeriod.ProjectRole
                                    : 'none'}
                              </Typography>
                           </Box>
                        </Box>
                     </Grid>
                     <Grid item xs={1}>
                        <Typography sx={{ textAlign: 'center', fontSize: 15, fontWeight: 600 }}>
                           {isNaN(member.PlannedHours) || member.Assignments[0]?.WorkPercent === '0'
                              ? 'N/A'
                              : formatNumber(member.PlannedHours)}
                        </Typography>
                     </Grid>
                     <Grid item xs={1}>
                        <Typography
                           sx={{
                              textAlign: 'center',
                              fontSize: 15,
                              fontWeight: 600,
                              color: '#008DC6',
                           }}
                        >
                           {formatNumber(member.logged)}
                        </Typography>
                     </Grid>
                     {isExternal && (
                        <Grid item xs={1}>
                           <Typography
                              sx={{
                                 textAlign: 'center',
                                 fontSize: 15,
                                 fontWeight: 600,
                                 color: '#00B8B8',
                              }}
                           >
                              {formatNumber(member.billable)}
                           </Typography>
                        </Grid>
                     )}
                     {isExternal && (
                        <Grid item xs={1}>
                           <Typography
                              sx={{
                                 textAlign: 'center',
                                 fontSize: 15,
                                 fontWeight: 600,
                                 color: '#2D3A73',
                              }}
                           >
                              {formatNumber(member.nonBillable)}
                           </Typography>
                        </Grid>
                     )}
                     <Grid item xs={1}>
                        <Typography
                           sx={{
                              textAlign: 'center',
                              fontSize: 15,
                              fontWeight: 600,
                              color: '#FFAD5B',
                           }}
                        >
                           {formatNumber(member.idleTime)}
                        </Typography>
                     </Grid>
                     <Grid item xs={1}>
                        <Typography
                           sx={{
                              textAlign: 'center',
                              fontSize: 15,
                              fontWeight: 600,
                              color: Deviation(member.logged, member.PlannedHours)
                                 ? '#D93F4C'
                                 : '#A89AF9',
                           }}
                        >
                           {isNaN(member.PlannedHours) || member.Assignments[0]?.WorkPercent === '0'
                              ? 'N/A'
                              : formatNumber(Math.abs(member.logged - member.PlannedHours))}

                           {member.logged - member.PlannedHours === 0 ||
                           isNaN(member.PlannedHours) ||
                           member.Assignments[0]?.WorkPercent === '0' ? null : Deviation(
                                member.logged,
                                member.PlannedHours
                             ) ? (
                              <img
                                 src={underTimeIcon}
                                 alt="Overtime icon"
                                 style={{ marginLeft: 2, height: 14, marginBottom: 3 }}
                              />
                           ) : (
                              <img
                                 src={overTimeIcon}
                                 alt="Overtime icon"
                                 style={{ marginLeft: 2, height: 14, marginBottom: 4 }}
                              />
                           )}
                        </Typography>
                     </Grid>
                     <Grid
                        item
                        xs={1}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                     >
                        <Typography sx={{ textAlign: 'center', fontSize: 15, fontWeight: 600 }}>
                           {member.Assignments[0]?.WorkPercent
                              ? member.Assignments[0]?.WorkPercent
                              : '0'}
                           %
                        </Typography>
                        {(isNaN(member.PlannedHours) ||
                           member.Assignments[0]?.WorkPercent === '0') && (
                           <Tooltip
                              componentsProps={{
                                 tooltip: {
                                    sx: {
                                       p: '12px 15px',
                                       color: 'white',
                                       backgroundColor: '#E58C9C',
                                       '& .MuiTooltip-arrow': {
                                          color: '#E58C9C',
                                       },
                                    },
                                 },
                              }}
                              placement="bottom-start"
                              title={t('percentWarningText')}
                              arrow
                           >
                              <ErrorIcon
                                 sx={{ color: '#E58C9C', width: 18, height: 18, ml: '1px' }}
                              />
                           </Tooltip>
                        )}
                     </Grid>
                  </Grid>
               )
            )
         ) : (
            <Box sx={{ border: '1px solid #E58C9C', p: 2, mb: 1, mt: 1, textAlign: 'center' }}>
               There is no data for this period.
            </Box>
         )}
      </Box>
   )
}

export default withTranslation()(ProjectOverviewMemberDetails)
