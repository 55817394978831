import { default as React, useState, useEffect } from 'react'
import Select from 'react-select'
import { wrapProject } from '../util/wrappers'
import { getProjectsForCustomer, fetchCustomerForAuthUser } from '../Api'
import { withTranslation } from 'react-i18next'

const ProjectDropdown = props => {
   const [projects, setProjects] = useState([])

   const fetchProjects = async customer => {
      const projects = await getProjectsForCustomer(customer._id, props.userInfo)
      const activeProjects = projects.data.length
         ? projects.data.filter(
              project => project.Status === 'Active Opportunity' || project.Status === 'Active'
           )
         : []
      setProjects(activeProjects)
   }

   useEffect(() => {
      const loadData = async () => {
         const result = await fetchCustomerForAuthUser(props.userInfo)
         if (result.success) {
            await fetchProjects(result.customer)
         }
      }
      loadData()
   }, [])

   const getOptions = () => {
      const data = projects.map(project => {
         return wrapProject(project)
      })
      return data
   }

   return (
      <>
         <Select
            className="userfield"
            isSearchable
            isClearable
            options={projects != null ? getOptions() : []}
            value={props.value}
            placeholder={props.t('select')}
            onChange={wrappedProject => props.handleChangeProject(wrappedProject)}
         />
      </>
   )
}
export default withTranslation()(ProjectDropdown)
