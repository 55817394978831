import React, { useState, Fragment } from 'react'
import clsx from 'clsx'
import {
   Input,
   Paper,
   Table,
   TableHead,
   TableBody,
   TableCell,
   TableRow,
   TableContainer,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux'
import RotateLoader from 'react-spinners/RotateLoader'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment/min/moment-with-locales'
import DataRows from './dataRows/DataRows'

const useStyles = makeStyles({
   noBorder: {
      border: 'none',
      boxShadow: 'none',
      zIndex: 0,
   },
   white: {
      backgroundColor: '#fff',
   },
   narrow: {
      maxWidth: 16,
   },
   today: {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: '#FFeedd',
   },
   overlayParent: {
      position: 'relative',
   },
   overlayChild: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,0.5)',
      zIndex: 3,
      display: 'flex',
      justifyContent: 'center',
   },
})

const TableOverview = ({
   weekState,
   showSpinner,
   t,
   displayDate,
   handleChangeDisplayDate,
   filterArray,
   teamOptions,
   wrappedUserData,
   isToday,
   colourState,
   setAbsenceDialogState,
   iconState,
   getTouchingAbsences,
   absenceSeverity,
   getTooltip,
   projects,
   state,
   teams,
}) => {
   const [filter, setFilter] = useState('')
   const classes = useStyles()

   moment.locale(t('momentLocale'))

   const handleFilter = newFilter => {
      setFilter(newFilter)
   }

   const getRelevantColourClass = date => {
      const compare = new Date(date)
      if (isToday(compare)) return classes.today
      return classes.noBorder
   }

   const getColour = severity => {
      return colourState.currentColourScheme.scheme[severity]
   }

   const getWeekDayNameRow = week => {
      return (
         <Fragment key={'weekDayNameRow-' + week.number}>
            <TableCell className={clsx(classes.narrow, classes.noBorder, classes.white)} />
            {week.dates.map(date => {
               const dateString = moment(date).format('ddd')
               return (
                  <TableCell
                     className={clsx(classes.noBorder, classes.white)}
                     align={'center'}
                     style={{ padding: 1, maxWidth: 24 }}
                     key={'weekDayName' + date}
                  >
                     {moment(date).format('ddd').charAt(0).toUpperCase() + dateString.slice(1)}
                  </TableCell>
               )
            })}
         </Fragment>
      )
   }

   const getDateRow = (week, index) => {
      const firstWeek = index === 0
      const lastWeek = index === weekState.weeksSorted.length - 1
      return (
         <Fragment key={`daterow${week.number}${index}`}>
            {firstWeek ? (
               <TableCell
                  className={clsx(classes.narrow, classes.noBorder, classes.white)}
                  align={'center'}
                  style={{ padding: 1 }}
               >
                  <FontAwesomeIcon
                     color="deeppink"
                     style={{ cursor: 'pointer' }}
                     icon={faArrowCircleLeft}
                     onClick={() => handleWeekBackward()}
                  />
               </TableCell>
            ) : (
               <TableCell className={clsx(classes.narrow, classes.noBorder, classes.white)} />
            )}
            {week.dates.map(date => {
               return (
                  <TableCell
                     className={clsx(classes.white, getRelevantColourClass(date))}
                     align={'center'}
                     style={{ padding: 1, maxWidth: 24 }}
                     key={date}
                  >
                     {moment(date).format('D')}
                  </TableCell>
               )
            })}
            {!!lastWeek && (
               <TableCell
                  className={clsx(classes.narrow, classes.noBorder, classes.white)}
                  align={'center'}
                  style={{ padding: 1 }}
               >
                  <FontAwesomeIcon
                     color="deeppink"
                     style={{ cursor: 'pointer' }}
                     icon={faArrowCircleRight}
                     onClick={() => handleWeekForward()}
                  />
               </TableCell>
            )}
         </Fragment>
      )
   }

   const handleWeekForward = () => {
      let date = new Date(displayDate)
      date.setDate(date.getDate() + 7)
      handleChangeDisplayDate(date)
   }

   const handleWeekBackward = () => {
      let date = new Date(displayDate)
      date.setDate(date.getDate() - 7)
      handleChangeDisplayDate(date)
   }

   return (
      <TableContainer
         component={Paper}
         style={{ maxHeight: '75vh', minHeight: 200, width: '100%' }}
         className={clsx(classes.noBorder, classes.overlayParent)}
      >
         {showSpinner && (
            <div
               id="overlay"
               className={clsx(classes.overlayChild)}
               style={{ alignItems: 'center', marginTop: 50 }}
            >
               <RotateLoader color={getColour(0)} loading={true} size={16} margin={8} />
            </div>
         )}
         <Table
            stickyHeader
            size="small"
            className={clsx(classes.noBorder)}
            style={{ width: '100%' }}
         >
            <TableHead style={{height: 75}}>
               {!!state.userInfo.Settings.ShowDayNames && (
                  <TableRow>
                     <TableCell className={clsx(classes.noBorder, classes.white)} />

                     {weekState.weeksSorted.map(week => getWeekDayNameRow(week))}

                     <TableCell className={clsx(classes.narrow, classes.noBorder, classes.white)} />
                  </TableRow>
               )}
               {weekState.weekDates != null && (
                  <TableRow>
                     <TableCell
                        className={clsx(classes.noBorder, classes.white)}
                        style={{ zIndex: 2 }}
                     >
                        <Input
                           style={{ maxWidth: 158, marginRight: 32 }}
                           inputProps={{ 'aria-label': 'description' }}
                           placeholder={t('filterPersonnelPlaceholder')}
                           onChange={event => handleFilter(event.target.value.toLowerCase())}
                           fullWidth
                        />
                     </TableCell>
                     {weekState.weeksSorted.map((week, index) => getDateRow(week, index))}
                  </TableRow>
               )}
            </TableHead>
            {!showSpinner && (
               <TableBody>
                  <DataRows
                     filter={filter}
                     weekInfo={weekState}
                     filterArray={filterArray}
                     teamOptions={teamOptions}
                     wrappedUserData={wrappedUserData}
                     getRelevantColourClass={getRelevantColourClass}
                     getColour={getColour}
                     setAbsenceDialogState={setAbsenceDialogState}
                     iconState={iconState}
                     getTouchingAbsences={getTouchingAbsences}
                     absenceSeverity={absenceSeverity}
                     getTooltip={getTooltip}
                     projects={projects}
                     teams={teams}
                  />
               </TableBody>
            )}
         </Table>
      </TableContainer>
   )
}

const mapStateToProps = state => {
   return {
      state,
   }
}

export default withTranslation()(connect(mapStateToProps)(TableOverview))
