import { connect } from 'react-redux'
import { dateWithoutTime } from 'novarto-time'
import { fetchActiveUsers, fetchUsers } from '../../redux/actions/actions'
import themeConfig from '../../theme'
import { withTranslation } from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
   getActiveUserTimesheetManagement,
   fetchCustomerForAuthUser,
   getTeamsForOverview,
   getProjectsForOverview,
   fetchCustomerForAdmin,
   getSkillGroupsForCustomer,
} from '../../Api'
import { Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { getAllDatesOfAWeek, sortByLabel } from '../../util/helpers'
import TimesheetManagementHeader from './components/TimesheetManagementHeader'
import TimesheetManagementTable from './components/table/TimesheetManagementTable'
import { wrapProject, wrapTeam } from '../../util/wrappers'
import TimesheetMangementFilters from './components/TimesheetManagementFilters'
import log from '../../util/log'
import { Alert } from '@mui/material'
import RotateLoader from 'react-spinners/RotateLoader'
import clsx from 'clsx'

const { styles } = themeConfig()
const useStyles = makeStyles({
   overlayChild: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,0.5)',
      zIndex: 3,
      display: 'flex',
      justifyContent: 'center',
   },
})

const TimesheetManagement = ({ userInfo, t }) => {
   const [chosenDate, setChosenDate] = useState(new Date().setDate(new Date().getDate() - 7))
   const [chosenDateWeek, setChosenDateWeek] = useState(null)
   const [isDataFetching, setIsDataFetching] = useState(true)
   const [users, setUsers] = useState(null)
   const [data, setData] = useState([])
   const [today, setToday] = useState(dateWithoutTime(new Date()))
   const [displayDate, setDisplayDate] = useState(chosenDate)
   const [teamProjectFilters, setTeamProjectFilters] = useState([])
   const [isFilterVisible, setIsFilterVisible] = useState(false)
   const [teams, setTeams] = useState([])
   const [projects, setProjects] = useState([])
   const [skills, setSkills] = useState([])
   const [newDataFetching, setNewDataFetching] = useState(true)
   const [errorAlert, setErrorAlert] = useState(false)
   const [errorMsg, setErrorMsg] = useState('')
   const [filter, setFilter] = useState('')
   const [tabIndex, setTabIndex] = useState(0)
   const [userFilters, setUserFilters] = useState({
      skills: [],
      onlyFreeEmployees: false,
      missingLog: false,
   })
   const classes = useStyles()
   const ref = useRef(null)

   const teamOptions = [
      { label: t('defaultTeam'), value: null, id: null, members: [] },
      ...teams.map(wrapTeam).sort(sortByLabel),
   ]

   const projectOptions = [...projects.map(wrapProject).sort(sortByLabel)]
   useEffect(() => {
      fetchData()
   }, [])

   useEffect(() => {
      setChosenDateWeek(getAllDatesOfAWeek(new Date(chosenDate), 2))
   }, [chosenDate])

   useEffect(() => {
      if (
         localStorage.getItem('hrAppliedFilters') &&
         JSON.parse(localStorage.getItem('hrAppliedFilters')).timesheetManagement
      ) {
         setTeamProjectFilters(
            JSON.parse(localStorage.getItem('hrAppliedFilters')).timesheetManagement
         )
      }
   }, [])

   useEffect(() => {
      const storedFilters = localStorage.getItem('hrAppliedFilters')
      if (storedFilters) {
         const timesheetManagementEmployee = JSON.parse(storedFilters).timesheetManagementEmployee

         if (timesheetManagementEmployee) {
            const { skills, onlyFreeEmployees, missingLog } = timesheetManagementEmployee

            setUserFilters(prev => ({
               ...prev,
               skills: skills || prev.skills,
               onlyFreeEmployees: onlyFreeEmployees ? true : prev.onlyFreeEmployees,
               missingLog: missingLog ? true : prev.missingLog,
            }))
         }
      }
   }, [])

   const getStartAndEndOfWeek = date => {
      const dayOfWeek = date.getUTCDay()
      const startOfWeek = new Date(date)
      startOfWeek.setDate(date.getDate() - dayOfWeek - 7)
      const endOfWeek = new Date(date)
      endOfWeek.setDate(date.getDate() - dayOfWeek - 1)
      return [startOfWeek, endOfWeek]
   }

   useEffect(() => {
      if (!!chosenDateWeek) {
         setNewDataFetching(true)
         async function getData() {
            try {
               const response = await getActiveUserTimesheetManagement(
                  {
                     WeekStart: chosenDateWeek.weekDates[0],
                     WeekEnd: chosenDateWeek.weekDates[chosenDateWeek.weekDates.length - 1],
                  },
                  userInfo
               )
               setData(
                  response.data.data.sort((a, b) => {
                     const nameA = a.FirstName.toUpperCase()
                     const nameB = b.FirstName.toUpperCase()
                     return nameA.localeCompare(nameB)
                  })
               )
            } catch (err) {
               setIsDataFetching(false)
               setErrorAlert(true)
               setErrorMsg(t('timesheetManagementError'))
               console.log(err)
            } finally {
               setNewDataFetching(false)
               setIsDataFetching(false)
            }
         }

         getData()

         const interval = setInterval(() => {
            log.info('Refreshed data at: ' + Date.now())
            fetchData()
            getData()
            setToday(dateWithoutTime(new Date()))
         }, 500000)
         return () => {
            clearInterval(interval)
         }
      }
   }, [chosenDateWeek])

   const filterFreeEmployees = users => {
      return users.filter(user => user.projectReports.length === 0)
   }

   const filterMissingLog = (users, startOfWeek, endOfWeek) => {
      return users.filter(
         user =>
            user.projectReports.length > 0 &&
            user.projectReports.every(
               report =>
                  report.reportedDates.some(dateObj => {
                     const reportDate = new Date(dateObj.Date)
                     return reportDate >= startOfWeek && reportDate <= endOfWeek
                  }) === false
            )
      )
   }

   const filterBySkills = (users, skills) => {
      return users.filter(user =>
         skills.some(skill => user.Skills.some(userSkill => userSkill.GroupID === skill._id))
      )
   }

   const sortByFirstName = users => {
      return users.sort((a, b) => {
         const nameA = a.FirstName.toUpperCase()
         const nameB = b.FirstName.toUpperCase()
         return nameA.localeCompare(nameB)
      })
   }

   useEffect(() => {
      let usersCopy = [...data]

      const [startOfWeek, endOfWeek] = getStartAndEndOfWeek(new Date())

      if (userFilters.onlyFreeEmployees) {
         usersCopy = filterFreeEmployees(usersCopy)
      }
      if (userFilters.missingLog) {
         usersCopy = filterMissingLog(usersCopy, startOfWeek, endOfWeek)
      }
      if (userFilters.skills.length > 0) {
         usersCopy = filterBySkills(usersCopy, userFilters.skills)
      }

      setUsers(sortByFirstName(usersCopy))
   }, [data, userFilters])

   const fetchData = async () => {
      try {
         const [customerForAdminResponse, customerResponse, projectResponse, teamResponse] =
            await Promise.all([
               fetchCustomerForAdmin(userInfo),
               fetchCustomerForAuthUser(userInfo),
               getProjectsForOverview(userInfo),
               getTeamsForOverview(userInfo),
            ])
         const { success: customerAdminSuccess, data: customer } = customerForAdminResponse
         const { success: customerSuccess } = customerResponse
         const { success: projectSuccess, data: projects } = projectResponse
         const { success: teamSuccess, data: teams } = teamResponse || {}

         if (customerSuccess) {
            if (projectSuccess) {
               setProjects(projects)
            }
            if (teamSuccess) {
               setTeams(teams)
            }
            if (customerAdminSuccess) {
               const result = await getSkillGroupsForCustomer(customer._id, userInfo)
               setSkills(result.data)
            }
         }
      } catch (error) {
         console.error(error)
         setErrorAlert(true)
         setErrorMsg(t('timesheetManagementError'))
      }
   }
   const changeDate = useCallback(newDate => {
      const date = new Date(newDate)
      setChosenDate(date)
      setDisplayDate(date)
   }, [])

   const isToday = useCallback(
      date => {
         if (date.getDate() !== today.getDate()) return false
         if (date.getMonth() !== today.getMonth()) return false
         if (date.getFullYear() !== today.getFullYear()) return false
         return true
      },
      [today]
   )
   const characterFilter = (person, filter) => {
      return person
         ? `${person?.FirstName}|${person?.LastName}|${person.CompanyEmail.split('@')[0]}`
              .toLowerCase()
              .includes(filter)
         : ''
   }
   const filteredUsers = users?.filter(person => characterFilter(person, filter))

   return (
      <Paper
         style={{
            minHeight: '300px',
            maxWidth: '2000px',
            margin: 'auto',
            position: 'relative',
            boxShadow: 'none',
         }}
         elevation={2}
      >
         {isDataFetching && (
            <div
               id="overlay"
               className={clsx(classes.overlayChild)}
               style={{ alignItems: 'center', marginTop: 50 }}
            >
               <RotateLoader color="green" loading={true} size={16} margin={8} />
            </div>
         )}
         {!!chosenDateWeek && (
            <>
               {errorAlert && (
                  <div style={{ marginTop: 20 }}>
                     <Alert severity="error">{errorMsg}</Alert>
                  </div>
               )}
               <TimesheetManagementHeader
                  chosenDateWeek={chosenDateWeek}
                  chosenDate={chosenDate}
                  changeDate={changeDate}
                  setIsFilterVisible={setIsFilterVisible}
                  teamProjectFilters={teamProjectFilters}
                  setChosenDate={setChosenDate}
                  userFilters={userFilters}
                  userInfo={userInfo}
               />

               <div
                  style={{
                     borderTop: isFilterVisible ? '' : '1px solid #C8C8C8',
                     marginLeft: '25px',
                     marginRight: '25px',
                  }}
               >
                  {isFilterVisible && (
                     <TimesheetMangementFilters
                        reference={ref}
                        teamProjectFilters={teamProjectFilters}
                        setIsFilterVisible={setIsFilterVisible}
                        setTeamProjectFilters={setTeamProjectFilters}
                        teamOptions={teamOptions}
                        projectOptions={projectOptions}
                        timesheetManagement={true}
                        filteredUsers={filteredUsers}
                        userFilters={userFilters}
                        setUserFilters={setUserFilters}
                        skills={skills}
                        setTabIndex={setTabIndex}
                     />
                  )}
               </div>
               <div
                  className={'contentWrapper'}
                  style={{
                     overflowX: 'auto',
                     overflowY: 'hidden',
                     height: 'calc(100% - 80px)',
                     padding: '4px 25px 20px ',
                     position: 'relative',
                     marginTop: 5,
                  }}
               >
                  <TimesheetManagementTable
                     isDataFetching={isDataFetching}
                     chosenDateWeek={chosenDateWeek}
                     isToday={isToday}
                     changeDate={changeDate}
                     displayDate={displayDate}
                     users={users}
                     filteredUsers={filteredUsers}
                     teamOptions={teamOptions}
                     teams={teams}
                     projects={projects}
                     teamProjectFilters={teamProjectFilters}
                     setNewDataFetching={setNewDataFetching}
                     setFilter={setFilter}
                     filter={filter}
                     setTabIndex={setTabIndex}
                     tabIndex={tabIndex}
                     setTeamProjectFilters={setTeamProjectFilters}
                     newDataFetching={newDataFetching}
                  />
               </div>
            </>
         )}
      </Paper>
   )
}
const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      notifs: state.notifs,
      users: state.users,
      activeUsers: state.activeUsers,
   }
}

export default withStyles(styles)(
   withTranslation()(
      connect(mapStateToProps, { fetchUsers, fetchActiveUsers })(TimesheetManagement)
   )
)
