import { withTranslation } from 'react-i18next'
import ProjectReportHeader from './components/ProjectReportHeader'
import ProjectReportProjects from './components/ProjectReportProjects'
import { Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { fetchCustomerForAuthUser, getProjectsForCustomer } from '../../../../Api'
import { connect } from 'react-redux'

const ProjectReport = ({ userInfo }) => {
   const [projects, setProjects] = useState([])
   const [characterFilter, setCharacterFilter] = useState('')
   const [projectFilters, setProjectFilters] = useState({
      sortBy: 'recentlyUpdated',
      active: false,
      closed: false,
      notUpdated: false,
   })
   const [isLoading, setIsLoading] = useState(false)
   useEffect(() => {
      setIsLoading(true)
      const loadData = async () => {
         try {
            const result = await fetchCustomerForAuthUser(userInfo)

            if (result.success) {
               const data = await getProjectsForCustomer(result.customer._id, userInfo)
               setProjects(data.data)
               setIsLoading(false)
            }
         } catch (error) {
            setIsLoading(false)
            console.error('Error loading data:', error)
         }
      }

      loadData()
   }, [userInfo])
   return (
      <Paper sx={{ width: '100%', height: '100%', boxShadow: 'none' }}>
         <ProjectReportHeader
            setProjectFilters={setProjectFilters}
            projectFilters={projectFilters}
            setCharacterFilter={setCharacterFilter}
         />
         <ProjectReportProjects
            projects={projects}
            projectFilters={projectFilters}
            filter={characterFilter}
            isLoading={isLoading}
         />
      </Paper>
   )
}
const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}

export default withTranslation()(connect(mapStateToProps)(ProjectReport))
