import React from 'react'
import { TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
   textField: {
      width: '80px',
      padding: 0,
      backgroundColor: 'white',
      borderRadius: '7px',
   },
   input: {
      '&::placeholder': {
         color: 'black',
         opacity: 0.6,
      },
   },
}))

const todayInputStyle = {
   fontWeight: 500,
}

const TimesheetTimeInput = ({
   value,
   onChange,
   disabled,
   onBlur,
   isPlaceholder,
   style = {},
   isTodayInput = false,
}) => {
   const classes = useStyles()

   return (
      <TextField
         value={value}
         onChange={onChange}
         disabled={!!disabled}
         onBlur={onBlur}
         variant="outlined"
         size="small"
         className={classes.textField}
         placeholder={isPlaceholder ? '00:00' : ''}
         inputProps={{
            style: {
               textAlign: 'center',

               ...(isTodayInput ? todayInputStyle : {}),
            },
         }}
         InputProps={{
            classes: { input: !disabled ? classes.input : null },
         }}
         style={style}
      />
   )
}

export default TimesheetTimeInput
