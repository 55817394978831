import React from 'react'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-US'
import bg from 'date-fns/locale/bg'
import { withTranslation } from 'react-i18next'
import DatePicker, { registerLocale } from 'react-datepicker'
import Paper from '@mui/material/Paper'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { fetchCustomerData } from '../redux/actions/actions'
import {
   Button,
   Input,
   InputAdornment,
   Typography,
   FormControl,
   FormControlLabel,
   Radio,
   RadioGroup,
} from '@mui/material'
import { updateCustomer, fetchCustomerForAdmin } from '../Api'
import { useEffectOnlyOnce } from '../util/helpers'
import {
   parseDateFromHHmm,
   getHHmmFromDate,
   calculateWorkDayMinutes,
   hoursAndMinutesLabel,
} from 'novarto-time'
import clsx from 'clsx'
import '../css/userform.css'
import log from '../util/log'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import TimezoneSelect from 'react-timezone-select'
import absenceCodes from '../enums/absenceCode'
import moment from 'moment-timezone'

registerLocale('sv', sv)
registerLocale('en', en)
registerLocale('bg', bg)

//const { styles } = themeConfig();
const styles = theme => ({
   alignedContainer: {
      alignItems: 'center',
      justifyContent: 'flex-start',
   },
   hidden: {
      visibility: 'hidden',
   },
})
const CustomerSettings = ({ ...props }) => {
   const { t, classes } = props

   const CustomerNameAdornmentRef = React.useRef()
   const CustomerEmailAdornmentRef = React.useRef()
   const CustomerFromEmailAdornmentRef = React.useRef()
   const RequestCounterAdornmentRef = React.useRef()

   const [anchorLang, setAnchorLang] = React.useState(null)

   const clickLangMenu = event => {
      setAnchorLang(event.currentTarget)
   }
   const closeLangMenu = () => {
      setAnchorLang(null)
   }

   const [requestCounter, setRequestCounter] = React.useState(0)

   const [state, setState] = React.useState({
      Name: '',
      DefaultLanguage: '',
      EmailSettings: {
         AdminEmails: '',
         FromMail: '',
         SendEmailForNewAbsences: false,
         SendEmailForUpdatedAbsences: false,
         SendEmailForEndedAbsences: false,
         SendEmailForNewAbsenceRequests: false,
         SendEmailForHandledAbsenceRequests: false,
         SendAbsenceVerifyMail: false,
         SendNotificationsToPMsAndTLs: false,
      },
      TimeZone: 'Europe/Stockholm',
      WorkDay: {
         StartTime: '08:00',
         EndTime: '17:00',
         UseLunchBreak: true,
         LunchBreak: {
            StartTime: '12:00',
            EndTime: '13:00',
         },
      },
      InactiveAbsences: [],
      VacationSettings: {
         UseVacationDays: false,
         VacationRefillMMDD: '01-01',
         ShowNextYearMMDD: '01-01',
      },
   })
   const [reportPresenceHidden, setReportPresenceHidden] = React.useState(false)
   const [emailsOption, setEmailsOption] = React.useState(
      state.EmailSettings.SendNotificationsToPMsAndTLs
   )

   useEffectOnlyOnce(() => {
      const loadData = async () => {
         try {
            const result = await fetchCustomerForAdmin(props.userInfo)

            console.log('RESULT:      ' + JSON.stringify(result))

            setState({ ...result.data })

            setRequestCounter(result.data.RequestCounter)

            setReportPresenceHidden(
               !result.data.Features.MenuOptionsHidden?.find(entry => entry === 'reportpresence')
            )
         } catch (err) {}
      }
      loadData()
   })

   const handleEmailsOption = event => {
      setEmailsOption(event.target.value)
      updateEmailSettings(
         'SendNotificationsToPMsAndTLs',
         !state.EmailSettings.SendNotificationsToPMsAndTLs
      )
   }

   const handleUpdate = async update => {
      await updateCustomer(update, props.userInfo).then(
         response =>
            setSnackbarState({
               open: true,
               message: t('Saved'),
               severity: 'success',
            }),
         response =>
            setSnackbarState({
               open: true,
               message: t('errorText1'),
               severity: 'error',
            })
      )
   }

   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      severity: 'info',
   })
   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ open: false })
   }

   const changeLang = async lang => {
      setState({ ...state, DefaultLanguage: lang })

      const update = { DefaultLanguage: lang }

      log.info('Updating customer: ' + JSON.stringify(update))

      await handleUpdate(update)
      closeLangMenu()
   }

   const updateVacationDays = async newValue => {
      setState({
         ...state,
         VacationSettings: {
            ...state.VacationSettings,
            UseVacationDays: newValue,
         },
      })

      const update = {
         ...state,
         VacationSettings: {
            ...state.VacationSettings,
            UseVacationDays: newValue,
         },
      }
      log.info('Updating customer: ' + JSON.stringify(update))

      await handleUpdate(update)
   }

   const updateRequestCounter = async () => {
      const update = {
         ...state,
         RequestCounter: requestCounter,
      }
      log.info('Updating customer: ' + JSON.stringify(update))

      await handleUpdate(update)
   }

   const updateEmailSettings = async (key, newValue) => {
      log.info('newValue: ' + newValue)

      // this will chage state on next render so we cannot use
      // the state for the update, we need to set the new value in
      // an object to update
      setState({
         ...state,
         EmailSettings: {
            ...state.EmailSettings,
            [key]: newValue,
         },
      })

      const update = {
         ...state,
         EmailSettings: {
            ...state.EmailSettings,
            [key]: newValue,
         },
      }

      log.info('Updating customer: ' + JSON.stringify(update))

      await handleUpdate(update)
   }

   const updateWorkDay = async update => {
      setState({ ...state, WorkDay: update })
      const updateWithWorkDay = { ...state, WorkDay: update }

      log.info('Updating customer: ' + JSON.stringify(updateWithWorkDay))

      await handleUpdate(updateWithWorkDay)

      props.fetchCustomerData(props.userInfo)
   }

   const updateTimeZone = async zone => {
      console.log('Zone changed: ' + JSON.stringify(zone))

      setState({ ...state, TimeZone: zone.value })

      const update = { ...state, TimeZone: zone.value }

      log.info('Updating customer: ' + JSON.stringify(update))

      await handleUpdate(update)
   }

   const handleEnterOnField = async () => {
      console.log('Updating customer: ' + JSON.stringify(state))

      await handleUpdate(state)

      props.fetchCustomerData(props.userInfo)
   }

   const handleUpdateVacationRefillDate = async date => {
      setState({
         ...state,
         VacationSettings: {
            ...state.VacationSettings,
            VacationRefillMMDD: moment(date).format('MM-DD'),
         },
      })

      const update = {
         ...state,
         VacationSettings: {
            ...state.VacationSettings,
            VacationRefillMMDD: moment(date).format('MM-DD'),
         },
      }

      await handleUpdate(update)
   }

   const handleUpdateShowNextYearDate = async date => {
      setState({
         ...state,
         VacationSettings: {
            ...state.VacationSettings,
            ShowNextYearMMDD: moment(date).format('MM-DD'),
         },
      })

      const update = {
         ...state,
         VacationSettings: {
            ...state.VacationSettings,
            ShowNextYearMMDD: moment(date).format('MM-DD'),
         },
      }

      await handleUpdate(update)
   }

   const handleUpdateHiddenOptions = async value => {
      setReportPresenceHidden(value)
      value = !value
      let newOptions = state.Features.MenuOptionsHidden ? state.Features.MenuOptionsHidden : []
      if (value) {
         newOptions.push('reportpresence')
      } else {
         const index = newOptions.indexOf('reportpresence')
         newOptions.splice(index, 1)
      }

      const update = { ...state, Features: { ...state.Features, MenuOptionsHidden: newOptions } }
      log.info('Updating customer: ' + JSON.stringify(update))
      setState({ ...state, Features: { ...state.Features, MenuOptionsHidden: newOptions } })
      await handleUpdate(update)

      props.fetchCustomerData(props.userInfo)
   }

   const handleUpdateAllowVerifyAbsence = async value => {
      const update = { ...state, Features: { ...state.Features, VerifyAbsence: value } }
      setState(update)
      await handleUpdate(update)

      props.fetchCustomerData(props.userInfo)
   }

   const activate = async absence => {
      const inactive = state.InactiveAbsences.filter(abs => abs.AbsenceType !== absence)

      setState({ ...state, InactiveAbsences: inactive })

      const update = { InactiveAbsences: inactive }

      log.info('Updating customer: ' + JSON.stringify(update))

      await handleUpdate(update)

      props.fetchCustomerData(props.userInfo)
   }

   const inactivate = async absence => {
      let inactive = state.InactiveAbsences
      inactive.push({ AbsenceType: absence })

      setState({ ...state, InactiveAbsences: inactive })

      const update = { InactiveAbsences: inactive }

      log.info('Updating customer: ' + JSON.stringify(update))

      await handleUpdate(update)

      props.fetchCustomerData(props.userInfo)
   }

   const getFlagIcon = lang => {
      switch (lang) {
         case 'en':
            return '/uk-flag-icon-16.png'
         case 'sv':
            return '/sweden-flag-icon-16.png'
         case 'bg':
            return '/bulgaria-flag-icon-16.png'
         default:
            return '/uk-flag-icon-16.png'
      }
   }

   const FormRowCheckBox = props => {
      return (
         <React.Fragment>
            <div className={'user-form-row'}>
               <div className={'customer-checkbox-title'}>{props.title}</div>
               <div className={'customer-checkbox-content'}>
                  <input
                     type="checkbox"
                     checked={props.value}
                     value={props.value}
                     onChange={e => props.onChange(e.target.checked)}
                  />
               </div>
            </div>
            <div className={'clear'}></div>
         </React.Fragment>
      )
   }

   return (
      <React.Fragment>
         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>

         <Paper
            className="contentPaper"
            style={{ maxWidth: 580, paddingBottom: 20, boxShadow: 'none' }}
         >
            <div className={'contentWrapper'}>
               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Typography>
                     <b>{t('customerNameLabel')}:</b> {state.Name}
                  </Typography>
                  <Input
                     autoComplete="off"
                     style={{ width: 500 }}
                     placeholder={t('typeHereToChange')}
                     id="input_field_CustomerName"
                     key="input_field_CustomerName"
                     onFocus={() => {
                        if (CustomerNameAdornmentRef.current) {
                           CustomerNameAdornmentRef.current.style.visibility = 'visible'
                        }
                     }}
                     onBlur={() => {
                        if (CustomerNameAdornmentRef.current) {
                           CustomerNameAdornmentRef.current.style.visibility = 'hidden'
                        }
                     }}
                     endAdornment={
                        <InputAdornment
                           position="end"
                           className={clsx(classes.hidden)}
                           ref={CustomerNameAdornmentRef}
                        >
                           {t('enterToSave')}
                        </InputAdornment>
                     }
                     onChange={event => {
                        setState({ ...state, Name: event.target.value })
                     }}
                     onKeyPress={event => {
                        if (event.key === 'Enter') {
                           handleEnterOnField()
                           event.target.value = null
                        }
                     }}
                  ></Input>
               </div>

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Grid container spacing={1}>
                     <Grid item>
                        <Typography>
                           <b>{t('defaultLanguageLabel')}:</b> {state.DefaultLanguage}
                        </Typography>
                     </Grid>
                     <Grid item>
                        <IconButton
                           aria-controls="lang-menu"
                           aria-haspopup="true"
                           onClick={clickLangMenu}
                           color="inherit"
                           size="medium"
                        >
                           <img src={getFlagIcon(state.DefaultLanguage)} alt="Language switch" />
                        </IconButton>
                        <Menu
                           id="lang-menu"
                           anchorEl={anchorLang}
                           keepMounted
                           open={Boolean(anchorLang)}
                           onClose={closeLangMenu}
                        >
                           <MenuItem onClick={() => changeLang('en')}>
                              <img src={'/uk-flag-icon-16.png'} alt="USA flag" />{' '}
                              <Typography style={{ marginLeft: 10 }}>English</Typography>
                           </MenuItem>
                           <MenuItem onClick={() => changeLang('bg')}>
                              <img src={'/bulgaria-flag-icon-16.png'} alt="Bulgaria flag" />{' '}
                              <Typography style={{ marginLeft: 10 }}>
                                 Bulgarian (български)
                              </Typography>
                           </MenuItem>
                        </Menu>
                     </Grid>
                  </Grid>
                  <Typography style={{ fontSize: 12 }}>({t('clickFlagToChange')})</Typography>
               </div>

               <div style={{ width: 450, padding: 10, marginBottom: 10 }}>
                  <Grid container spacing={1}>
                     <Grid item>
                        <Typography>
                           <b>{t('timezoneLabel')}:</b>
                        </Typography>
                     </Grid>
                     <Grid item style={{ width: 400 }}>
                        <TimezoneSelect
                           style={{ width: 450 }}
                           value={state.TimeZone}
                           onChange={async zone => {
                              updateTimeZone(zone)
                           }}
                        />
                     </Grid>
                  </Grid>
               </div>

               <div style={{ width: 450, padding: 10, marginBottom: 10 }}>
                  <Grid container spacing={1}>
                     <Grid item>
                        <Typography>
                           <b>{t('workdayLabel')}:</b>
                        </Typography>
                     </Grid>
                     <Grid item style={{ width: 400 }}>
                        <Grid container spacing={2} direction="row">
                           <Grid item>
                              <DatePicker
                                 style={{ width: 30 }}
                                 selected={new Date(parseDateFromHHmm(state.WorkDay.StartTime))}
                                 onChange={date => {
                                    updateWorkDay({
                                       ...state.WorkDay,
                                       StartTime: getHHmmFromDate(new Date(date)),
                                    })
                                 }}
                                 showTimeSelect
                                 showTimeSelectOnly
                                 timeIntervals={60}
                                 timeCaption={t('Time')}
                                 dateFormat="HH:mm"
                                 locale={t('datepickerLocale')}
                              />
                           </Grid>
                           <Grid item>
                              <DatePicker
                                 style={{ width: 30 }}
                                 selected={new Date(parseDateFromHHmm(state.WorkDay.EndTime))}
                                 onChange={date => {
                                    updateWorkDay({
                                       ...state.WorkDay,
                                       EndTime: getHHmmFromDate(new Date(date)),
                                    })
                                 }}
                                 showTimeSelect
                                 showTimeSelectOnly
                                 timeIntervals={60}
                                 timeCaption={t('Time')}
                                 dateFormat="HH:mm"
                                 locale={t('datepickerLocale')}
                              />
                           </Grid>
                        </Grid>
                        <Grid
                           container
                           spacing={2}
                           direction="row"
                           className={classes.alignedContainer}
                           sx={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '1px',
                           }}
                        >
                           <Grid item>
                              <input
                                 type="checkbox"
                                 checked={state.WorkDay.UseLunchBreak}
                                 value={state.WorkDay.UseLunchBreak}
                                 onChange={e => {
                                    updateWorkDay({
                                       ...state.WorkDay,
                                       UseLunchBreak: e.target.checked,
                                    })
                                 }}
                              />
                           </Grid>
                           <Grid item>{t('useLunchBreak')}</Grid>
                        </Grid>
                        {state.WorkDay.UseLunchBreak ? (
                           <Grid container spacing={2} direction="row" sx={{ marginTop: '1px' }}>
                              <Grid item>
                                 <DatePicker
                                    style={{ width: 30 }}
                                    selected={
                                       new Date(
                                          parseDateFromHHmm(state.WorkDay.LunchBreak.StartTime)
                                       )
                                    }
                                    onChange={date => {
                                       updateWorkDay({
                                          ...state.WorkDay,
                                          LunchBreak: {
                                             ...state.WorkDay.LunchBreak,
                                             StartTime: getHHmmFromDate(new Date(date)),
                                          },
                                       })
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption={t('Time')}
                                    dateFormat="HH:mm"
                                    locale={t('datepickerLocale')}
                                 />
                              </Grid>
                              <Grid item style={{ width: 30, maxWidth: 30 }}>
                                 <DatePicker
                                    selected={
                                       new Date(parseDateFromHHmm(state.WorkDay.LunchBreak.EndTime))
                                    }
                                    onChange={date => {
                                       updateWorkDay({
                                          ...state.WorkDay,
                                          LunchBreak: {
                                             ...state.WorkDay.LunchBreak,
                                             EndTime: getHHmmFromDate(new Date(date)),
                                          },
                                       })
                                    }}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    timeCaption={t('Time')}
                                    dateFormat="HH:mm"
                                    locale={t('datepickerLocale')}
                                 />
                              </Grid>
                           </Grid>
                        ) : null}
                        <Grid style={{ marginTop: 10 }} item>
                           {t('totalWorkday')}:{' '}
                           <b>{hoursAndMinutesLabel(calculateWorkDayMinutes(state.WorkDay), t)}</b>
                        </Grid>
                     </Grid>
                  </Grid>
               </div>

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                     <Grid item>
                        <Typography>
                           <b>{t('useVacationDays')}:</b>
                        </Typography>
                     </Grid>
                     <Grid style={{ marginLeft: 20 }}>
                        <input
                           type="checkbox"
                           checked={state.VacationSettings.UseVacationDays}
                           value={state.VacationSettings.UseVacationDays}
                           onChange={e => {
                              updateVacationDays(e.target.checked)
                           }}
                        />
                     </Grid>
                  </Grid>
               </div>

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Typography>
                     <b>{t('vacationDayRefillDate')}:</b>
                  </Typography>
                  <DatePicker
                     selected={new Date(moment(state.VacationSettings.VacationRefillMMDD, 'MM-DD'))}
                     onChange={date => {
                        handleUpdateVacationRefillDate(date)
                     }}
                     showMonthDropdown={true}
                     dateFormat="MMMM dd"
                     openToDate={
                        new Date(moment(state.VacationSettings.VacationRefillMMDD, 'MM-DD'))
                     }
                     locale={t('datepickerLocale')}
                  />
               </div>

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Typography>
                     <b>{t('showNextYearMMDD')}:</b>
                  </Typography>
                  <DatePicker
                     selected={new Date(moment(state.VacationSettings.ShowNextYearMMDD, 'MM-DD'))}
                     onChange={date => {
                        handleUpdateShowNextYearDate(date)
                     }}
                     showMonthDropdown={true}
                     dateFormat="MMMM dd"
                     openToDate={new Date(moment(state.VacationSettings.ShowNextYearMMDD, 'MM-DD'))}
                     locale={t('datepickerLocale')}
                  />
               </div>

               <FormControl style={{ padding: 10, marginBottom: 10 }}>
                  <RadioGroup value={emailsOption} onChange={handleEmailsOption}>
                     <div>
                        <FormControlLabel
                           value="SendNotificationsToPMsAndTLs"
                           control={<Radio color={'secondary'} />}
                           label={t('sendMailsToTLsAndPMs')}
                           checked={state.EmailSettings.SendNotificationsToPMsAndTLs}
                        />
                        <FormControlLabel
                           value="SendNotificationsToAdminEmails"
                           control={<Radio color={'secondary'} />}
                           label={t('sendMailsToAllAdmins')}
                           checked={!state.EmailSettings.SendNotificationsToPMsAndTLs}
                        />
                     </div>
                  </RadioGroup>
               </FormControl>
               {!state.EmailSettings.SendNotificationsToPMsAndTLs && (
                  <div style={{ padding: 10, marginBottom: 10 }}>
                     <Typography>
                        <b>{t('customerEmailLabel')}:</b> {state.EmailSettings.AdminEmails}
                     </Typography>
                     <Typography style={{ fontSize: 12 }}>({t('customerEmailInfo')})</Typography>
                     <Input
                        autoComplete="off"
                        style={{ width: 500 }}
                        placeholder={t('typeHereToChange')}
                        id="input_field_CustomerEmail"
                        key="input_field_CustomerEmail"
                        onFocus={() => {
                           if (CustomerEmailAdornmentRef.current) {
                              CustomerEmailAdornmentRef.current.style.visibility = 'visible'
                           }
                        }}
                        onBlur={() => {
                           if (CustomerEmailAdornmentRef.current) {
                              CustomerEmailAdornmentRef.current.style.visibility = 'hidden'
                           }
                        }}
                        endAdornment={
                           <InputAdornment
                              position="end"
                              className={clsx(classes.hidden)}
                              ref={CustomerEmailAdornmentRef}
                           >
                              {t('enterToSave')}
                           </InputAdornment>
                        }
                        onChange={event => {
                           setState({
                              ...state,
                              EmailSettings: {
                                 ...state.EmailSettings,
                                 AdminEmails: event.target.value,
                              },
                           })
                        }}
                        onKeyPress={event => {
                           if (event.key === 'Enter') {
                              handleEnterOnField()
                              event.target.value = null
                           }
                        }}
                     ></Input>
                  </div>
               )}

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Typography>
                     <b>{t('customerFromEmailLabel')}:</b> {state.EmailSettings.FromMail}
                  </Typography>
                  <Typography style={{ fontSize: 12 }}>({t('customerFromEmailInfo')})</Typography>
                  <Input
                     autoComplete="off"
                     style={{ width: 500 }}
                     placeholder={t('typeHereToChange')}
                     id="input_field_CustomerFrom"
                     key="input_field_CustomerFrom"
                     onFocus={() => {
                        if (CustomerFromEmailAdornmentRef.current) {
                           CustomerFromEmailAdornmentRef.current.style.visibility = 'visible'
                        }
                     }}
                     onBlur={() => {
                        if (CustomerFromEmailAdornmentRef.current) {
                           CustomerFromEmailAdornmentRef.current.style.visibility = 'hidden'
                        }
                     }}
                     endAdornment={
                        <InputAdornment
                           position="end"
                           className={clsx(classes.hidden)}
                           ref={CustomerFromEmailAdornmentRef}
                        >
                           {t('enterToSave')}
                        </InputAdornment>
                     }
                     onChange={event => {
                        setState({
                           ...state,
                           EmailSettings: {
                              ...state.EmailSettings,
                              FromMail: event.target.value,
                           },
                        })
                     }}
                     onKeyPress={event => {
                        if (event.key === 'Enter') {
                           handleEnterOnField()
                           event.target.value = null
                        }
                     }}
                  ></Input>
               </div>

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Typography>
                     <b>{t('menuOptionSettings')}:</b>
                  </Typography>
                  <FormRowCheckBox
                     {...{
                        title: t('allowReportingPresence'),
                        value: reportPresenceHidden,
                        onChange: value => handleUpdateHiddenOptions(value),
                     }}
                  />
               </div>

               {!state.Features ? null : (
                  <div style={{ padding: 10, marginBottom: 10 }}>
                     <Typography>
                        <b>{t('otherOptionSettings')}:</b>
                     </Typography>
                     <FormRowCheckBox
                        {...{
                           title: t('verifyAbsenceOption'),
                           value: state.Features.VerifyAbsence,
                           onChange: value => handleUpdateAllowVerifyAbsence(value),
                        }}
                     />
                  </div>
               )}

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Typography>
                     <b>{t('absenceTypeSettings')}:</b>
                  </Typography>
                  <Grid
                     container
                     direction="column"
                     justifyContent="flex-start"
                     alignItems="flex-start"
                  >
                     {Object.values(absenceCodes).map(absence => (
                        <Grid
                           container
                           key={`activation-${absence.code}`}
                           direction="row"
                           justifyContent="space-between"
                           alignItems="center"
                           style={{ maxWidth: 350 }}
                           spacing={1}
                        >
                           <Typography style={{ margin: 10 }}>{t(absence.code)}</Typography>
                           {props.inactiveAbsences.includes(absence.code) ? (
                              <Button
                                 size="small"
                                 variant="contained"
                                 color="primary"
                                 style={{ margin: 10 }}
                                 onClick={() => activate(absence.code)}
                              >
                                 {t('ACTIVATE')}
                              </Button>
                           ) : (
                              <Button
                                 size="small"
                                 variant="contained"
                                 color="secondary"
                                 style={{ margin: 10 }}
                                 onClick={() => inactivate(absence.code)}
                              >
                                 {t('REMOVE')}
                              </Button>
                           )}
                        </Grid>
                     ))}
                  </Grid>
               </div>

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Typography>
                     <b>{t('mailSettings')}:</b>
                  </Typography>
                  <FormRowCheckBox
                     {...{
                        title: t('SendEmailForNewAbsences'),
                        value: state.EmailSettings.SendEmailForNewAbsences,
                        onChange: value => updateEmailSettings('SendEmailForNewAbsences', value),
                     }}
                  />
                  <FormRowCheckBox
                     {...{
                        title: t('SendEmailForUpdatedAbsences'),
                        value: state.EmailSettings.SendEmailForUpdatedAbsences,
                        onChange: value =>
                           updateEmailSettings('SendEmailForUpdatedAbsences', value),
                     }}
                  />
                  <FormRowCheckBox
                     {...{
                        title: t('SendEmailForEndedAbsences'),
                        value: state.EmailSettings.SendEmailForEndedAbsences,
                        onChange: value => updateEmailSettings('SendEmailForEndedAbsences', value),
                     }}
                  />
                  <FormRowCheckBox
                     {...{
                        title: t('SendEmailForNewAbsenceRequests'),
                        value: state.EmailSettings.SendEmailForNewAbsenceRequests,
                        onChange: value =>
                           updateEmailSettings('SendEmailForNewAbsenceRequests', value),
                     }}
                  />
                  <FormRowCheckBox
                     {...{
                        title: t('SendEmailForHandledAbsenceRequests'),
                        value: state.EmailSettings.SendEmailForHandledAbsenceRequests,
                        onChange: value =>
                           updateEmailSettings('SendEmailForHandledAbsenceRequests', value),
                     }}
                  />
                  <FormRowCheckBox
                     {...{
                        title: t('SendAbsenceVerifyMail'),
                        value: state.EmailSettings.SendAbsenceVerifyMail,
                        onChange: value => updateEmailSettings('SendAbsenceVerifyMail', value),
                     }}
                  />
               </div>

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Typography>
                     <b>{t('absenceRequestCounter')}:</b> {requestCounter}
                  </Typography>
                  <Typography style={{ fontSize: 12 }}>
                     ({t('absenceRequestCounterInfo')})
                  </Typography>
                  <Input
                     autoComplete="off"
                     style={{ width: 500 }}
                     placeholder={t('typeHereToChange')}
                     id="input_field_RequestCounter"
                     key="input_field_RequestCounter"
                     onFocus={() => {
                        if (RequestCounterAdornmentRef.current) {
                           RequestCounterAdornmentRef.current.style.visibility = 'visible'
                        }
                     }}
                     onBlur={() => {
                        if (RequestCounterAdornmentRef.current) {
                           RequestCounterAdornmentRef.current.style.visibility = 'hidden'
                        }
                     }}
                     endAdornment={
                        <InputAdornment
                           position="end"
                           className={clsx(classes.hidden)}
                           ref={RequestCounterAdornmentRef}
                        >
                           {t('enterToSave')}
                        </InputAdornment>
                     }
                     onChange={event => {
                        setRequestCounter(event.target.value)
                     }}
                     onKeyPress={event => {
                        if (event.key === 'Enter') {
                           // Do some validation, New value should not be lower than
                           // the old. And it must be a number.
                           const parsed = parseInt(requestCounter)
                           if (isNaN(parsed)) {
                              setRequestCounter(state.RequestCounter)
                              setSnackbarState({
                                 open: true,
                                 message: t('inputMustBeNumber'),
                                 severity: 'error',
                              })
                              event.target.value = null
                              return
                           }
                           if (parsed < state.RequestCounter) {
                              setRequestCounter(state.RequestCounter)
                              setSnackbarState({
                                 open: true,
                                 message:
                                    t('inputCannotBeLowerThan') + ' ' + state.RequestCounter + '.',
                                 severity: 'error',
                              })
                              event.target.value = null
                              return
                           }

                           updateRequestCounter()
                           event.target.value = null
                        }
                     }}
                  ></Input>
               </div>
            </div>
         </Paper>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      inactiveAbsences: state.inactiveAbsences,
      hiddenMenuOptions: state.hiddenMenuOptions,
   }
}

export default withStyles(styles)(
   withTranslation()(connect(mapStateToProps, { fetchCustomerData })(CustomerSettings))
)
