import { Select as MuiSelect, OutlinedInput, Typography, Box } from '@mui/material'
import { withTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
   select: {
      marginTop: '0.25rem',
      width: '100%',
      height: '40px',
      fontSize: '0.875rem',
      borderRadius: '4px',
      '& .MuiSelect-icon': {
         right: '10px',
      },
   },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
}

const Select = ({
   title,
   placeholder,
   width,
   margin,
   handleChange,
   value,
   multiple,
   renderValue,
   children,
}) => {
   const classes = useStyles()

   return (
      <Box sx={{ width: width, margin: margin }}>
         <Typography variant="body2">{title}</Typography>
         <MuiSelect
            className={classes.select}
            placeholder={placeholder}
            multiple={multiple}
            value={value}
            onChange={handleChange}
            input={<OutlinedInput />}
            renderValue={renderValue}
            MenuProps={MenuProps}
         >
            {children}
         </MuiSelect>
      </Box>
   )
}

export default withTranslation()(Select)
