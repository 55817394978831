import { Box, Paper } from '@mui/material'
import { withTranslation } from 'react-i18next'
import AvailableEmployeesHeader from './components/AvailableEmployeesHeader'
import AvailableEmployeesBody from './components/AvailableEmployeesBody'
import BackToRoadmapButton from '../components/BackToRoadmapButton'
import PeriodBar from '../components/PeriodBar'
import {
   fetchAllUsers,
   fetchCustomerForAuthUser,
   getProjectsForCustomer,
   getSkillGroupsForCustomer,
   getSkillsForCustomer,
} from '../../../../../Api'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { getAllDatesInAPeriod } from '../../../../../util/helpers'
import { useLocation } from 'react-router-dom'

const AvailableEmployees = ({ userInfo }) => {
   const location = useLocation()
   const stateStartDate = location.state?.startDate && new Date(location.state?.startDate)
   const stateEndDate = location.state?.endDate && new Date(location.state?.endDate)
   const stateSkillGroups = location.state?.skillGroups
   const stateSkills = location.state?.skills

   const [selectedPeriod, setSelectedPeriod] = useState({
      startDate: stateStartDate || new Date().setHours(0, 0, 0),
      endDate: stateEndDate || new Date(moment().add(30, 'days')),
   })
   const [isLoading, setIsLoading] = useState(false)

   const [filterBySkillGroup, setFilterBySkillGroup] = useState(stateSkillGroups ?? [])
   const [skillGroups, setSkillGroups] = useState([])

   const [filterBySkill, setFilterBySkill] = useState(stateSkills ?? [])
   const [skills, setSkills] = useState([])

   const [filterByProject, setFilterByProject] = useState(null)
   const [projects, setProjects] = useState([])

   const [employees, setEmployees] = useState([])
   const [activeEmployees, setActiveEmployees] = useState([])

   const [filteredProjects, setFilteredProjects] = useState([])
   const [filteredEmployees, setFilteredEmployees] = useState([])

   const [period, setPeriod] = useState([])

   // calculate period
   useEffect(() => {
      const dates = getAllDatesInAPeriod(selectedPeriod.startDate, selectedPeriod.endDate)
      const periodMap = new Map()

      dates.map(date => {
         const monthString = moment(date).format('MMMM')
         const yearString = moment(date).format('YYYY')

         const existingMonth = periodMap.get(monthString + yearString)
         if (existingMonth) {
            existingMonth.dates.push(date)
         } else {
            periodMap.set(monthString + yearString, {
               month: monthString,
               dates: [date],
               quarter: moment(date).quarter(),
               year: moment(date).format('YYYY'),
            })
         }
      })

      setPeriod(Array.from(periodMap.values()))
   }, [selectedPeriod])

   // fetch the data
   useEffect(() => {
      setIsLoading(true)
      const loadData = async () => {
         try {
            const result = await fetchCustomerForAuthUser(userInfo)

            if (result.success) {
               const skillGroupResponse = await getSkillGroupsForCustomer(
                  result.customer._id,
                  userInfo
               )
               const skillsResponse = await getSkillsForCustomer(result.customer._id, userInfo)
               const employeesResponse = await fetchAllUsers({ userInfo: userInfo })
               const projectsResponse = await getProjectsForCustomer(result.customer._id, userInfo)

               setSkillGroups(skillGroupResponse.data)
               setSkills(skillsResponse.data)
               setEmployees(employeesResponse.data)
               setProjects(projectsResponse.data)

               setIsLoading(false)
            }
         } catch (error) {
            setIsLoading(false)
            console.error('Error loading data:', error)
         }
      }

      loadData()
   }, [userInfo])

   // filter only active employees for the selected period
   useEffect(() => {
      const onlyActiveEmployees = employees.filter(employee => {
         for (const employment of employee.Employments) {
            if (employment.EndDate == null) return true
            if (
               new Date(employment.StartDate) <= new Date(selectedPeriod.endDate) &&
               new Date(employment.EndDate) >= new Date(selectedPeriod.startDate)
            ) {
               return true
            }
         }
      })
      setActiveEmployees(onlyActiveEmployees)
   }, [employees, selectedPeriod])

   // filter employees and projects
   useEffect(() => {
      let employeesFiltered = activeEmployees
      let projectsFiltered = projects

      if (filterBySkillGroup.length > 0) {
         const filteredEmployees = activeEmployees.filter(employee => {
            const employeeSkillGroupIds = employee.Skills.reduce(
               (accumulatedSkillGroups, skill) => {
                  if (filterBySkillGroup.includes(skill.GroupID)) {
                     accumulatedSkillGroups.push(skill.GroupID)
                  }
                  return accumulatedSkillGroups
               },
               []
            )

            return filterBySkillGroup.some(skillGroup => employeeSkillGroupIds.includes(skillGroup))
         })
         employeesFiltered = filteredEmployees
      }

      if (filterBySkill.length > 0) {
         const filteredEmployees = employeesFiltered.filter(employee => {
            const employeeSkillIds = employee.Skills.reduce((accumulatedSkills, skill) => {
               if (filterBySkill.includes(skill._id)) {
                  accumulatedSkills.push(skill._id)
               }
               return accumulatedSkills
            }, [])

            return filterBySkill.some(skill => employeeSkillIds.includes(skill))
         })
         employeesFiltered = filteredEmployees
      }

      if (filterByProject != undefined) {
         const selectedProject = projects.find(project => project._id == filterByProject)
         const employeesWorkingInSelectedProject = employeesFiltered.filter(employee => {
            const member = selectedProject?.MemberDetails.find(member => member._id == employee._id)
            return member?.Assignments.find(assignment => {
               return (
                  new Date(assignment.StartDate) <= selectedPeriod.endDate &&
                  (new Date(assignment.EndDate) >= selectedPeriod.startDate ||
                     assignment.EndDate == undefined) // if the end date is not defined, it means that the assignment is still active
               )
            })
         })
         projectsFiltered = [selectedProject]
         employeesFiltered = employeesWorkingInSelectedProject
      }

      setFilteredEmployees(employeesFiltered)
      setFilteredProjects(projectsFiltered)
   }, [activeEmployees, filterBySkillGroup, filterBySkill, filterByProject])

   return (
      <Paper sx={{ width: '100%', height: '100%', boxShadow: 'none' }}>
         <BackToRoadmapButton />
         <AvailableEmployeesHeader
            selectedPeriod={selectedPeriod}
            filterBySkillGroup={filterBySkillGroup}
            filterBySkill={filterBySkill}
            setSelectedPeriod={setSelectedPeriod}
            setFilterBySkillGroup={setFilterBySkillGroup}
            setFilterBySkill={setFilterBySkill}
            setFilterByProject={setFilterByProject}
            skillGroups={skillGroups}
            skills={skills}
            projects={projects}
            employees={filteredEmployees}
         />
         <Box sx={{ ml: '265px', mr: '25px' }}>
            <PeriodBar period={period} />
         </Box>
         <AvailableEmployeesBody
            period={period}
            employees={filteredEmployees}
            projects={filteredProjects}
         />
      </Paper>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}

export default withTranslation()(connect(mapStateToProps)(AvailableEmployees))
