import { Paper } from '@mui/material'
import { withTranslation } from 'react-i18next'
import ProjectPlanningHeader from './components/ProjectPlanningHeader'
import ProjectPlanningBody from './components/ProjectPlanningBody'
import BackToRoadmapButton from '../components/BackToRoadmapButton'
import {
   fetchCustomerForAuthUser,
   getSkillGroupsForCustomer,
   getSkillsForCustomer,
} from '../../../../../Api'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

const ProjectPlanning = ({ userInfo }) => {
   const [skillGroups, setSkillGroups] = useState([])
   const [skills, setSkills] = useState([])

   const [isLoading, setIsLoading] = useState(false)

   // fetch the data
   useEffect(() => {
      setIsLoading(true)
      const loadData = async () => {
         try {
            const result = await fetchCustomerForAuthUser(userInfo)

            if (result.success) {
               const skillGroupResponse = await getSkillGroupsForCustomer(
                  result.customer._id,
                  userInfo
               )
               const skillsResponse = await getSkillsForCustomer(result.customer._id, userInfo)

               setSkillGroups(skillGroupResponse.data)
               setSkills(skillsResponse.data)

               setIsLoading(false)
            }
         } catch (error) {
            setIsLoading(false)
            console.error('Error loading data:', error)
         }
      }

      loadData()
   }, [userInfo])

   return (
      <Paper sx={{ width: '100%', height: '100%', boxShadow: 'none' }}>
         <BackToRoadmapButton />
         <ProjectPlanningHeader />
         <ProjectPlanningBody skillGroups={skillGroups} skills={skills} />
      </Paper>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}

export default withTranslation()(connect(mapStateToProps)(ProjectPlanning))
