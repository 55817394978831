import {
   Checkbox,
   FormControl,
   FormControlLabel,
   Radio,
   RadioGroup,
   TextField,
} from '@mui/material'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { parseJSON } from 'date-fns'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import bg from 'date-fns/locale/bg'
import moment from 'moment'
import React, { useEffect, useCallback } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Moment from 'react-moment'
import { Prompt } from 'react-router'
import { approveRequest, deleteRequest, updateRequest } from '../Api'
import { getUserByEmployeeID, highlightDaysInRed } from '../util/helpers'
import {
   setDateToEndOfWorkDay,
   setDateToStartOfWorkDay,
   calculateAbsenceMinsBetweenDates,
   calculateMinutesToDaysBasedOnWorkDay,
   getAbsenceCalcLabel,
} from 'novarto-time'
import { wrapUser } from '../util/wrappers'
import log from '../util/log'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import absenceCodes from '../enums/absenceCode'

registerLocale('sv', sv)
registerLocale('en-GB', en)
registerLocale('bg', bg)

const RequestRow = ({ comment = '', ...props }) => {
   const { request, holidays, t } = props

   const [recurrenceFrequency, setRecurrenceFrequency] = React.useState(props.request.Periodicity)

   const [periodEnd, setPeriodEnd] = React.useState(
      props.request.PeriodEnd ? new Date(props.request.PeriodEnd) : null
   )

   const [vacationSpent, setVacationSpent] = React.useState({ days: 0, hours: 0, mins: 0 })

   const [handleFullDates, setHandleFullDates] = React.useState(true)

   const [isVacation, setIsVacation] = React.useState(false)

   const [passe, setPasse] = React.useState(false)

   const workDay = useSelector(state => state.workDay)

   const [formState, setFormState] = React.useState({
      RequestNumber:
         props.request.AbsenceIntervals[0].Type === 'DISTANS'
            ? props.request.RemoteRequestNumber
            : props.request.RequestNumber,
      IsApprovedByPO: props.request.IsApprovedByPO,
      absenceIntervals: props.request.AbsenceIntervals,
      CommentByUser: props.request.CommentByUser,
      CommentByAdmin: props.request.CommentByAdmin ? props.request.CommentByAdmin : '',
   })

   const [dirty, setDirty] = React.useState(false)

   const calculateTotalTime = useCallback(
      segments => {
         let totalMins = 0
         segments.forEach(segment => {
            if (segment.end && segment.start > segment.end) return
            let mins = calculateAbsenceMinsBetweenDates(
               segment.start,
               segment.end,
               holidays,
               workDay
            )
            totalMins += mins
         })

         return calculateMinutesToDaysBasedOnWorkDay(totalMins, workDay)
      },
      [holidays, workDay]
   )

   useEffect(() => {
      const usesVacationDays = Object.values(absenceCodes).find(
         code => code.code === request.AbsenceIntervals[0].Type
      ).usesVacationDays
      setIsVacation(usesVacationDays)
      if (
         request.AbsenceIntervals &&
         (usesVacationDays || request.AbsenceIntervals[0].Type === 'LEGAL')
      ) {
         setVacationSpent(
            calculateTotalTime(
               calculateRecurrenceAgnostic(
                  request.AbsenceIntervals[0],
                  request.PeriodEnd,
                  request.Periodicity
               )
            )
         )
      }
      setPasse(
         !request.AbsenceIntervals.some(
            abs => moment(abs.EndDate).format('YYYYMMDD') > moment().format('YYYYMMDD')
         )
      )
      const start = new Date(request.AbsenceIntervals[0].StartDate)
      const end = new Date(request.AbsenceIntervals[0].EndDate)
      if (
         start.getHours() !== new Date(setDateToStartOfWorkDay(start, workDay)).hours ||
         end.getHours() !== new Date(setDateToEndOfWorkDay(end, workDay)).hours
      ) {
         setHandleFullDates(false)
      }
   }, [
      request.AbsenceIntervals,
      request.PeriodEnd,
      request.Periodicity,
      calculateTotalTime,
      workDay,
   ])

   // const clearIntervals = () => {
   //   setDirty(false);
   //   setFormState({ IsApprovedByPO: false, absenceIntervals: [{ StartDate: new Date(), EndDate: null, Type: "" }], CommentByUser: "" });
   //   props.setErrorAlert(null);
   // }

   const handleDeleteRequest = async () => {
      log.debug('Deleting request: ' + props.request._id)
      log.debug('Request: ' + JSON.stringify(props.request))
      try {
         await deleteRequest(props.request._id, props.userInfo, formState.CommentByAdmin)
         props.reload()
         props.setSnackbarState({
            open: true,
            message: t('requestDeletedMsg'),
            severity: 'success',
         })
      } catch {
         props.setSnackbarState({
            open: true,
            message: t('absenceRequestErrorText'),
            severity: 'error',
         })
      }

      props.absenceChange(props.userInfo)
      setDirty(false)
   }

   const handleUpdateRequest = async data => {
      // Make sure you cannot set start date to date that has already passed
      let idag = new Date()
      idag = new Date(setDateToStartOfWorkDay(idag, workDay))
      if (
         data.AbsenceIntervals &&
         (formState.absenceIntervals[0].StartDate < idag ||
            formState.absenceIntervals[0].EndDate < idag)
      ) {
         props.setSnackbarState({
            open: true,
            message: t('dateBeforeTodayNotAllowed'),
            severity: 'error',
         })
         return
      }

      if (handleFullDates && data.AbsenceIntervals) {
         let start = new Date(data.AbsenceIntervals[0].StartDate)
         let end = new Date(data.AbsenceIntervals[0].EndDate)
         data.AbsenceIntervals[0].StartDate = new Date(setDateToStartOfWorkDay(start, workDay))
         data.AbsenceIntervals[0].EndDate = new Date(setDateToEndOfWorkDay(end, workDay))
      }

      log.debug('Updating request: ' + props.request._id)
      log.debug('Request: ' + JSON.stringify(props.request))
      log.debug('Data: ' + JSON.stringify(data))
      try {
         await updateRequest(props.userInfo, data)
         props.reload()
         props.setSnackbarState({
            open: true,
            message: t('requestUpdatedMsg'),
            severity: 'success',
         })
      } catch (err) {
         log.error(err)
         props.setSnackbarState({
            open: true,
            message: t('errorText2') + ': ' + err,
            severity: 'error',
         })
      }

      props.absenceChange(props.userInfo)
      setDirty(false)
   }

   const handleApproveRequest = async data => {
      data.url = `/showrequest/html/${props.request._id}`

      log.debug('Approving request: ' + props.request._id)
      log.debug('Request: ' + JSON.stringify(props.request))
      log.debug('Data: ' + JSON.stringify(data))
      try {
         await approveRequest(props.userInfo, data)
         props.reload()
         props.setSnackbarState({
            open: true,
            message: t('requestApprovedMsg'),
            severity: 'success',
         })
      } catch (err) {
         log.error(err)
         props.setSnackbarState({
            open: true,
            message: t('absenceRequestErrorText'),
            severity: 'error',
         })
      }

      props.absenceChange(props.userInfo)
      setDirty(false)
   }

   const startDateChanged = (date, index) => {
      log.debug('setting startdate: ' + date)
      const intervals = formState.absenceIntervals.map((interval, i) => {
         if (index === i) {
            interval.StartDate = handleFullDates
               ? new Date(setDateToStartOfWorkDay(date, workDay))
               : date
         }
         return interval
      })
      setDirty(true)
      setFormState({ ...formState, absenceIntervals: intervals })
      setVacationSpent(
         calculateTotalTime(
            calculateRecurrenceAgnostic(
               intervals[0],
               props.request.PeriodEnd,
               props.request.Periodicity
            )
         )
      )
   }
   const endDateChanged = (date, index) => {
      log.debug('setting enddate: ' + date)
      const intervals = formState.absenceIntervals.map((interval, i) => {
         if (index === i) {
            interval.EndDate = handleFullDates
               ? new Date(setDateToEndOfWorkDay(date, workDay))
               : date
         }
         return interval
      })
      setDirty(true)
      setFormState({ ...formState, absenceIntervals: intervals })
      setVacationSpent(
         calculateTotalTime(
            calculateRecurrenceAgnostic(
               intervals[0],
               props.request.PeriodEnd,
               props.request.Periodicity
            )
         )
      )
   }

   const handleRecurrenceFrequency = event => {
      setDirty(true)
      setRecurrenceFrequency(event.target.value)
      setVacationSpent(
         calculateTotalTime(
            calculateRecurrenceAgnostic(
               props.request.AbsenceIntervals[0],
               props.request.PeriodEnd,
               event.target.value
            )
         )
      )
   }

   const handlePeriod = date => {
      let newPeriodEnd = new Date(setDateToEndOfWorkDay(new Date(), workDay))
      setDirty(true)
      setPeriodEnd(newPeriodEnd)
      setVacationSpent(
         calculateTotalTime(
            calculateRecurrenceAgnostic(
               props.request.AbsenceIntervals[0],
               newPeriodEnd,
               props.request.Periodicity
            )
         )
      )
   }

   const calculateRecurrenceAgnostic = (absence, cutoff, periodicity) => {
      // Agnostic means this calculation does not factor in holidays or weekends
      let segments = []
      if (periodicity !== 'once') {
         let segmentStart = new Date(absence.StartDate)
         let segmentEnd = new Date(absence.EndDate)
         let cutoffTime = new Date(cutoff)
         let frequency = periodicity === 'daily' ? 1 : periodicity === 'weekly' ? 7 : 0
         while (segmentEnd <= cutoffTime) {
            segments.push({ start: new Date(segmentStart), end: new Date(segmentEnd) })
            if (frequency > 0) {
               segmentStart.setDate(segmentStart.getDate() + frequency)
               segmentEnd.setDate(segmentEnd.getDate() + frequency)
            } else {
               segmentStart.setMonth(segmentStart.getMonth() + 1)
               segmentEnd.setMonth(segmentEnd.getMonth() + 1)
            }
         }
      } else {
         segments.push({ start: new Date(absence.StartDate), end: new Date(absence.EndDate) })
      }
      return segments
   }

   const getBackgroundColor = request => {
      switch (request.Status) {
         case 'REJECTED':
            return '#ffcccc'
         case 'APPROVED':
            return request.CreatedByAdmin ? '#87CEFA' : '#ddffee'
         case 'REQUESTED':
            return passe ? '#eaeaef' : '#ffffea'
         default:
            return '#FFF'
      }
   }

   return (
      <React.Fragment>
         <div className={'requestWrapper'}>
            <Accordion
               style={{
                  backgroundColor: getBackgroundColor(props.request),
               }}
            >
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={'request-content-' + props.request._id}
                  id={'request-header-' + props.request._id}
               >
                  <Typography variant="body2">
                     <b>{t(props.request.AbsenceIntervals[0].Type)}</b> {`${t('requestCreated')} `}
                     <Moment date={props.request.RequestedDate} format="DD.MM.YYYY" />{' '}
                     {` ${t('for')} `}
                     {
                        wrapUser(getUserByEmployeeID(props.request.EmployeeID, props.users)).label
                     }{' '}
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Grid
                     container
                     direction="column"
                     justifyContent="flex-start"
                     alignItems="center"
                     style={{ padding: 10 }}
                     spacing={2}
                  >
                     {props.canUpdate ? (
                        <Grid
                           container
                           direction="column"
                           justifyContent="flex-start"
                           alignItems="flex-start"
                           spacing={2}
                        >
                           <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={1}
                              style={{ margin: '0 0 10px 4px' }}
                           >
                              <Grid item>
                                 <Typography variant="body2">{t('absenceRequestID')}:</Typography>
                              </Grid>
                              <Grid item>
                                 <Typography variant="body2">{formState.RequestNumber}</Typography>
                              </Grid>
                           </Grid>
                           <React.Fragment>
                              {formState.absenceIntervals
                                 ? formState.absenceIntervals.map((interval, index) => (
                                      <React.Fragment key={`${index}`}>
                                         <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            style={{ maxWidth: 480 }}
                                            spacing={1}
                                         >
                                            <Grid item xs style={{ marginLeft: 8 }}>
                                               <Typography variant="body2">{t('from')}</Typography>
                                            </Grid>
                                            <Grid item xs>
                                               <DatePicker
                                                  className="datepicker"
                                                  locale={t('datepickerLocale')}
                                                  dateFormat={
                                                     handleFullDates
                                                        ? 'yyyy-MM-dd'
                                                        : 'yyyy-MM-dd HH:mm'
                                                  }
                                                  showTimeSelect={!handleFullDates}
                                                  timeIntervals="60"
                                                  highlightDates={highlightDaysInRed(holidays)}
                                                  minDate={new Date()}
                                                  maxDate={parseJSON(interval.EndDate)}
                                                  minTime={setDateToStartOfWorkDay(
                                                     new Date(),
                                                     workDay
                                                  )}
                                                  maxTime={new Date().setHours(
                                                     new Date(
                                                        setDateToEndOfWorkDay(new Date(), workDay)
                                                     ).getHours() - 1
                                                  )}
                                                  selected={parseJSON(interval.StartDate)}
                                                  onChange={date => startDateChanged(date, index)}
                                               />
                                            </Grid>
                                            <Grid item xs style={{ maxWidth: 40 }}>
                                               <Typography variant="body2">{t('to')}</Typography>
                                            </Grid>
                                            <Grid item xs>
                                               <DatePicker
                                                  className="datepicker"
                                                  locale={t('datepickerLocale')}
                                                  dateFormat={
                                                     handleFullDates
                                                        ? 'yyyy-MM-dd'
                                                        : 'yyyy-MM-dd HH:mm'
                                                  }
                                                  showTimeSelect={!handleFullDates}
                                                  timeIntervals="60"
                                                  highlightDates={highlightDaysInRed(holidays)}
                                                  minDate={parseJSON(interval.StartDate)}
                                                  minTime={
                                                     new Date(
                                                        setDateToStartOfWorkDay(new Date(), workDay)
                                                     )
                                                  }
                                                  maxTime={
                                                     new Date(
                                                        setDateToEndOfWorkDay(new Date(), workDay)
                                                     )
                                                  }
                                                  selected={
                                                     interval.EndDate != null
                                                        ? parseJSON(interval.EndDate)
                                                        : null
                                                  }
                                                  onChange={date => endDateChanged(date, index)}
                                               />
                                            </Grid>
                                         </Grid>
                                      </React.Fragment>
                                   ))
                                 : null}
                           </React.Fragment>
                           <Grid item style={{ paddingLeft: 0 }}>
                              <Checkbox
                                 checked={formState.IsApprovedByPO}
                                 onChange={() => {
                                    setFormState({
                                       ...formState,
                                       IsApprovedByPO: !formState.IsApprovedByPO,
                                    })
                                    setDirty(true)
                                 }}
                              />
                              {t('POApprovedCheckbox')}
                           </Grid>
                           <Grid item style={{ paddingLeft: 4 }}>
                              <TextField
                                 value={formState.CommentByUser}
                                 onChange={event => {
                                    setFormState({
                                       ...formState,
                                       CommentByUser: event.target.value,
                                    })
                                    setDirty(true)
                                 }}
                                 label={t('commentLabel')}
                                 variant="outlined"
                                 size="small"
                                 style={{ width: 500 }}
                              />
                           </Grid>
                           {isVacation ? null : (
                              <Accordion
                                 style={{
                                    width: 425,
                                    marginTop: 20,
                                    backgroundColor: getBackgroundColor(props.request),
                                 }}
                              >
                                 <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={'schedule-content-' + props.request._id}
                                    id={'schedule-header-' + props.request._id}
                                 >
                                    <Typography variant="body2">
                                       {props.request.Periodicity === 'once'
                                          ? t('noRecurrence')
                                          : props.request.Periodicity === 'daily'
                                          ? t('labelDaily') + ' '
                                          : props.request.Periodicity === 'weekly'
                                          ? t('labelWeekly') + ' '
                                          : t('labelMonthly') + ' '}{' '}
                                       {props.request.Periodicity === 'once'
                                          ? null
                                          : t('until') +
                                            ' ' +
                                            moment(props.request.PeriodEnd).format(
                                               'YYYY-MM-DD'
                                            )}{' '}
                                    </Typography>
                                 </AccordionSummary>
                                 <AccordionDetails>
                                    <FormControl>
                                       <RadioGroup
                                          value={recurrenceFrequency}
                                          onChange={handleRecurrenceFrequency}
                                       >
                                          <FormControlLabel
                                             value="once"
                                             control={<Radio />}
                                             label={t('labelOnce')}
                                          />
                                          <div>
                                             <FormControlLabel
                                                value="daily"
                                                control={<Radio />}
                                                label={t('labelDaily')}
                                             />
                                             <FormControlLabel
                                                value="weekly"
                                                control={<Radio />}
                                                label={t('labelWeekly')}
                                             />
                                             <FormControlLabel
                                                value="monthly"
                                                control={<Radio />}
                                                label={t('labelMonthly')}
                                             />
                                          </div>
                                          {t('Until')}:{' '}
                                          <DatePicker
                                             placeholderText={t('periodEndPlaceholder')}
                                             disabled={recurrenceFrequency === 'once'}
                                             className="datepicker"
                                             dateFormat="yyyy-MM-dd"
                                             locale={t('datepickerLocale')}
                                             highlightDates={highlightDaysInRed(holidays)}
                                             showWeekNumbers
                                             minDate={
                                                new Date(props.request.AbsenceIntervals[0].EndDate)
                                             }
                                             selected={periodEnd}
                                             onChange={date => handlePeriod(date)}
                                          />
                                       </RadioGroup>
                                    </FormControl>
                                 </AccordionDetails>
                              </Accordion>
                           )}
                        </Grid>
                     ) : (
                        <Grid
                           container
                           direction="column"
                           justifyContent="flex-start"
                           alignItems="center"
                           spacing={2}
                           style={{ marginLeft: 0 }}
                        >
                           <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={1}
                           >
                              <Grid item>
                                 <Typography variant="body2">{t('absenceRequestID')}:</Typography>
                              </Grid>
                              <Grid item>
                                 <Typography variant="body2">
                                    {props.request.AbsenceIntervals[0].Type === 'DISTANS'
                                       ? props.request.RemoteRequestNumber
                                       : props.request.RequestNumber}
                                 </Typography>
                              </Grid>
                           </Grid>

                           <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                           >
                              {props.request.AbsenceIntervals.map((interval, index) => (
                                 <Grid key={'interval_' + index + '_' + props.request._id} item xs>
                                    <div>
                                       <Typography variant="body2">
                                          {`${t('From')} `}
                                          <Moment date={interval.StartDate} format={'DD.MM.YYYY'} />
                                          {` ${t('to')} `}
                                          <Moment date={interval.EndDate} format={'DD.MM.YYYY'} />
                                       </Typography>
                                    </div>
                                 </Grid>
                              ))}
                           </Grid>
                           <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              style={{ padding: 3 }}
                           >
                              <Typography variant="body2">
                                 {isVacation || formState.absenceIntervals[0].Type === 'LEGAL'
                                    ? `${t('totalAmountDays')}: 
                                      ${getAbsenceCalcLabel(vacationSpent, t)}`
                                    : ''}
                              </Typography>
                           </Grid>
                           <br />
                           <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={1}
                           >
                              <Grid item>
                                 <Typography variant="body2">{t('isApprovedByPO')}:</Typography>
                              </Grid>
                              <Grid item>
                                 <Typography variant="body2">
                                    {props.request.IsApprovedByPO ? t('YES') : t('NO')}
                                 </Typography>
                              </Grid>
                           </Grid>

                           {props.request.CommentByUser && (
                              <Grid
                                 container
                                 direction="row"
                                 justifyContent="flex-start"
                                 alignItems="center"
                                 spacing={1}
                              >
                                 <Grid item>
                                    <Typography variant="body2">{t('commentLabel')}:</Typography>
                                 </Grid>
                                 <Grid item>
                                    <Typography variant="body2">
                                       {props.request.CommentByUser}
                                    </Typography>
                                 </Grid>
                              </Grid>
                           )}

                           {props.request.CommentByAdmin != null &&
                           props.request.CommentByAdmin.length > 0 ? (
                              <Grid
                                 container
                                 direction="row"
                                 justifyContent="flex-start"
                                 alignItems="center"
                                 spacing={1}
                              >
                                 <Grid item>
                                    <Typography variant="body2">
                                       {t('commentByAdminLabel')}:
                                    </Typography>
                                 </Grid>
                                 <Grid item>
                                    <Typography variant="body2">
                                       {props.request.CommentByAdmin}
                                    </Typography>
                                 </Grid>
                              </Grid>
                           ) : (
                              ''
                           )}
                        </Grid>
                     )}

                     <div className="spacer20"></div>

                     {props.canApprove || props.canReject ? (
                        <>
                           <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={1}
                              style={{ marginLeft: -15 }}
                           >
                              <Grid item>
                                 <TextField
                                    value={formState.CommentByAdmin}
                                    onChange={event => {
                                       setFormState({
                                          ...formState,
                                          CommentByAdmin: event.target.value,
                                       })
                                    }}
                                    label={t('commentToPersonnel')}
                                    variant="outlined"
                                    style={{ width: 500 }}
                                    size="small"
                                 />
                              </Grid>
                           </Grid>
                           <div className="spacer20"></div>
                        </>
                     ) : (
                        ''
                     )}

                     <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                     >
                        <Grid item>
                           {props.canPrint ? (
                              <Link
                                 target={'_blank'}
                                 to={`/showrequest/html/${props.request._id}/${t('locale')}${
                                    props.printForm === 'HillsPrintForm' ? '/1' : '/0'
                                 }`}
                              >
                                 <Button
                                    size="small"
                                    variant="outlined"
                                    style={{ backgroundColor: '#FFFFFF' }}
                                 >
                                    {t('print')}
                                 </Button>
                              </Link>
                           ) : (
                              ''
                           )}{' '}
                        </Grid>
                        <Grid item>
                           {props.canDelete ? (
                              <Button
                                 onClick={handleDeleteRequest}
                                 color="secondary"
                                 size="small"
                                 variant="contained"
                              >
                                 {t('Remove')}
                              </Button>
                           ) : (
                              ''
                           )}{' '}
                        </Grid>
                        <Grid item>
                           {props.canReject ? (
                              <Button
                                 onClick={() =>
                                    handleUpdateRequest({
                                       _id: props.request._id,
                                       Status: 'REJECTED',
                                       CommentByAdmin: formState.CommentByAdmin,
                                    })
                                 }
                                 color="secondary"
                                 size="small"
                                 variant="contained"
                              >
                                 {t('Reject')}
                              </Button>
                           ) : (
                              ''
                           )}
                        </Grid>
                        {/*
                <Grid item>{props.canUpdate ? <Button disabled={!dirty} onClick={() => handleUpdateRequest({ _id: props.request._id, AbsenceIntervals: formState.absenceIntervals, IsApprovedByPO: formState.IsApprovedByPO, CommentByUser: formState.CommentByUser, PeriodEnd: periodEnd, Periodicity: recurrenceFrequency })} color="primary" size="small" variant="contained">{t("saveChanges")}</Button> : ""}</Grid>
                */}
                        <Grid item>
                           {props.canApprove ? (
                              <Button
                                 onClick={() =>
                                    handleApproveRequest({
                                       _id: props.request._id,
                                       EmployeeID: props.userInfo.EmployeeID,
                                       Status: 'APPROVED',
                                       CommentByAdmin: formState.CommentByAdmin,
                                       PeriodEnd: props.request.PeriodEnd,
                                       Periodicity: props.request.Periodicity,
                                       Approver: wrapUser(
                                          getUserByEmployeeID(
                                             props.userInfo.EmployeeID,
                                             props.users
                                          )
                                       )?.label,
                                    })
                                 }
                                 color="primary"
                                 size="small"
                                 variant="contained"
                              >
                                 {t('Approve')}
                              </Button>
                           ) : (
                              ''
                           )}
                        </Grid>
                     </Grid>
                  </Grid>
               </AccordionDetails>
            </Accordion>
         </div>
         <Prompt when={dirty} message={t('leavePagePrompt')} />
      </React.Fragment>
   )
}

export default withTranslation()(RequestRow)
