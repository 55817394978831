import { Box } from '@mui/material'
import { withTranslation } from 'react-i18next'

const DatePointer = ({ startDate, endDate }) => {
   const currentDate = new Date()
   const roadmapTotalDurationMs = endDate.getTime() - startDate.getTime()
   const elapsedTimeMs = currentDate.getTime() - startDate.getTime()
   const elapsedFraction = Math.min(Math.max(elapsedTimeMs / roadmapTotalDurationMs, 0), 1)
   const elapsedPercentage = elapsedFraction * 100

   return (
      <Box
         sx={{
            border: '2px solid #0083BB',
            position: 'absolute',
            height: 500,
            top: 0,
            marginLeft: `${elapsedPercentage}%`,
            zIndex: 'inherit',
         }}
      />
   )
}

export default withTranslation()(DatePointer)
