import {
   FormControl,
   FormControlLabel,
   FormLabel,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
   TextField,
} from '@mui/material'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Snackbar from '@mui/material/Snackbar'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import axios from 'axios'
import { parseJSON } from 'date-fns'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import bg from 'date-fns/locale/bg'
import moment from 'moment'
import React, { useCallback } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import { connect } from 'react-redux'
import { Prompt } from 'react-router'
import {
   fetchRequestsForEmployee,
   fetchBankHolidays,
   fetchCustomerForAuthUser,
   fetchAllAbsencesForUser,
   getUserByEmailAddress,
   postAbsenceRequest,
} from '../Api'
import RequestRow from '../components/RequestRow'
import '../css/vacation.css'
import absenceCodes from '../enums/absenceCode'
import { absenceChange, fetchUsers } from '../redux/actions/actions'
import themeConfig from '../theme'
import { highlightDaysInRed, sortDesc, useEffectOnlyOnce } from '../util/helpers'
import {
   calculateAbsenceBetweenDates,
   setDateToEndOfWorkDay,
   setDateToStartOfWorkDay,
   getAbsenceCalcLabel,
   dayIntervalContainsDate,
} from 'novarto-time'
import log from '../util/log'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import VacationPaper from '../components/VacationPaper'
registerLocale('sv', sv)
registerLocale('en-GB', en)
registerLocale('bg', bg)

const { styles } = themeConfig()

const useStyles = makeStyles(theme => ({
   datepickerField: {
      width: '100%',
      '& .react-datepicker-wrapper': {
         width: '100%',
      },
   },
   select: {
      marginTop: '0.25rem',
      width: '100%',
      height: '40px',
      fontSize: '0.875rem',
      borderRadius: '4px',
   },
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
}))

const OngoingVacations = ({ ...props }) => {
   return (
      <React.Fragment>
         {props.requestState
            .sort((r1, r2) => sortDesc(r1.RequestedDate, r2.RequestedDate))
            .map(request =>
               request.Status === 'REQUESTED' ? (
                  <RequestRow
                     setSnackbarState={props.setSnackbarState}
                     canDelete={true}
                     key={request._id}
                     userInfo={props.userInfo}
                     request={request}
                     users={props.users}
                     absenceChange={props.absenceChange}
                     canUpdate={false}
                     holidays={props.holidays}
                     setErrorAlert={msg => props.setErrorAlert(msg)}
                     reload={() => props.fetchRequests()}
                  />
               ) : (
                  ''
               )
            )}
      </React.Fragment>
   )
}

const HistoryVacations = ({ ...props }) => {
   return (
      <React.Fragment>
         {props.requestState
            .sort((r1, r2) => sortDesc(r1.RequestedDate, r2.RequestedDate))
            .map(request =>
               request.Status !== 'REQUESTED' ? (
                  <RequestRow
                     setSnackbarState={props.setSnackbarState}
                     canDelete={false}
                     canPrint={
                        (props.variant === 'BulgariaPrintForm' ||
                           props.variant === 'HillsPrintForm') &&
                        request.Status === 'APPROVED' &&
                        Object.values(absenceCodes).find(
                           code => code.code === request.AbsenceIntervals[0].Type
                        )?.isPrintable
                     }
                     printForm={props.variant}
                     key={request._id}
                     userInfo={props.userInfo}
                     request={request}
                     users={props.users}
                     holidays={props.holidays}
                     absenceChange={props.absenceChange}
                     reload={() => props.fetchRequests()}
                  />
               ) : (
                  ''
               )
            )}
      </React.Fragment>
   )
}

const RequestAbsence = ({ ...props }) => {
   const classes = useStyles()
   const { userInfo, fetchUsers, t } = props
   const [errorAlert, setErrorAlert] = React.useState(null)

   const [recurrenceFrequency, setRecurrenceFrequency] = React.useState('once')

   const [periodEnd, setPeriodEnd] = React.useState(null)
   const [customer, setCustomer] = React.useState(null)

   const workDay = useSelector(state => state.workDay)

   const [formState, setFormState] = React.useState({
      IsApprovedByPO: false,
      absenceIntervals: [{ StartDate: null, EndDate: null, Type: '' }],
      CommentByUser: '',
   })
   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      severity: 'info',
   })

   const [disableRequestFunction, setDisableRequestFunction] = React.useState(false)
   const [requestState, setRequestState] = React.useState(null)
   const filterRelevant = requests =>
      requests.filter(
         req =>
            req.Status === 'REQUESTED' ||
            req.Status === 'APPROVED' ||
            req.AbsenceIntervals.some(
               abs => moment(abs.EndDate).format('YYYYMMDD') > moment().format('YYYYMMDD')
            )
      )
   const [dirty, setDirty] = React.useState(false)

   const [holidays, setHolidays] = React.useState([])
   const [userAbsences, setUserAbsences] = React.useState([])
   const [userRequests, setUserRequests] = React.useState([])
   const [thisUser, setThisUser] = React.useState(null)

   const now = new Date()
   const [requestNextYearOpen, setRequestNextYearOpen] = React.useState(null)
   const [lastEmploymentDate, setLastEmploymentDate] = React.useState(null)

   const options = [
      ...Object.values(absenceCodes)
         .filter(absence => !props.inactiveAbsences.includes(absence.code))
         .filter(absence => absence.approvalRequired)
         .map(absence => {
            return { value: absence.code, label: t(absence.code) }
         }),
   ]

   const [vacationCalc, setVacationCalc] = React.useState([
      {
         year: 2020,
         used: 0,
         requested: 0,
         available: 0,
      },
      {
         year: 2021,
         used: 0,
         requested: 0,
         available: 0,
      },
   ])

   const fetchCustomer = useCallback(async () => {
      let result = await fetchCustomerForAuthUser(userInfo)
      setCustomer(result.customer)
      setRequestNextYearOpen(
         new Date(moment(result.customer.VacationSettings.ShowNextYearMMDD, 'MM-DD'))
      )
   }, [userInfo])

   const fetchHolidays = useCallback(async () => {
      log.debug('Fetch Holidays running')
      return await fetchBankHolidays(userInfo).then(res => {
         if (res.success === 'false') {
            log.error('Failure to load data; try logging out and in again: ')
            return []
         } else {
            setHolidays(res)
            return res
         }
      })
   }, [userInfo])

   const fetchRequests = useCallback(async () => {
      let requestsJson = await fetchRequestsForEmployee(userInfo)
      setRequestState(filterRelevant(requestsJson.requests))
      return requestsJson
   }, [userInfo])

   const loadData = useCallback(async () => {
      const userResult = await getUserByEmailAddress(userInfo)
      const user = userResult.data
      console.log('USER: ' + JSON.stringify(user))
      setThisUser(user)
      setLastEmploymentDate(
         user.Employments.map(emp => emp.EndDate).reduce((acc, date) => (acc < date ? date : acc))
      )

      await fetchUsers(userInfo)
      await fetchCustomer()

      await fetchHolidays()
      const requestsJson = await fetchRequests()
      setUserRequests(requestsJson.requests)

      const json = await fetchAllAbsencesForUser(userInfo)
      setUserAbsences(json.data)
   }, [fetchCustomer, fetchHolidays, fetchRequests, fetchUsers, userInfo])

   useEffectOnlyOnce(() => {
      log.debug('     RequestAbsence useEffect')
      loadData()
   })

   const getCalculatedTime = () => {
      const negative =
         formState.absenceIntervals[0].EndDate &&
         formState.absenceIntervals[0].StartDate > formState.absenceIntervals[0].EndDate
      if (formState.absenceIntervals[0].StartDate && formState.absenceIntervals[0].EndDate) {
         const calc = calculateAbsenceBetweenDates(
            setDateToStartOfWorkDay(new Date(formState.absenceIntervals[0].StartDate), workDay),
            setDateToEndOfWorkDay(new Date(formState.absenceIntervals[0].EndDate), workDay),
            holidays,
            workDay
         )
         return (negative ? '-' : '') + getAbsenceCalcLabel(calc, t)
      }
      const calc = calculateAbsenceBetweenDates(
         formState.absenceIntervals[0].StartDate,
         formState.absenceIntervals[0].EndDate,
         holidays,
         workDay
      )
      return (negative ? '-' : '') + getAbsenceCalcLabel(calc, t)
   }

   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ open: false, message: '', severity: 'info' })
   }

   // const addInterval = () => {
   //   setErrorAlert(null);
   //   let intervals = formState.absenceIntervals.concat({ StartDate: null, EndDate: null, Type: "" });
   //   setDirty(true);
   //   setFormState({ ...formState, absenceIntervals: intervals });
   //   log.debug(formState.absenceIntervals);
   // }

   const clearIntervals = () => {
      setDirty(false)
      setFormState({
         IsApprovedByPO: false,
         absenceIntervals: [{ StartDate: null, EndDate: null, Type: '' }],
         CommentByUser: '',
      })
      setErrorAlert(null)
   }

   const startDateChanged = (date, index) => {
      log.debug('setting startdate: ' + date)
      const intervals = formState.absenceIntervals.map((interval, i) => {
         if (index === i) {
            interval.StartDate =
               date !== null && moment(date).isBefore(setDateToStartOfWorkDay(date, workDay))
                  ? new Date(setDateToStartOfWorkDay(date, workDay))
                  : date
         }
         return interval
      })
      setDirty(true)
      setFormState({ ...formState, absenceIntervals: intervals })
   }
   const endDateChanged = (date, index) => {
      log.debug('setting enddate: ' + date)
      const intervals = formState.absenceIntervals.map((interval, i) => {
         if (index === i) {
            interval.EndDate =
               date !== null && date.getHours() === 0
                  ? new Date(setDateToEndOfWorkDay(date, workDay))
                  : date
         }
         return interval
      })
      setDirty(true)
      setFormState({ ...formState, absenceIntervals: intervals })
   }
   const absenceTypeChanged = (type, index) => {
      const intervals = formState.absenceIntervals.map((interval, i) => {
         if (index === i) {
            interval.Type = type
         }
         return interval
      })
      setDirty(true)
      setFormState({ ...formState, absenceIntervals: intervals })
   }

   // const removeInterval = (index) => {
   //   const intervals = formState.absenceIntervals.filter((interval, i) => i != index);
   //   setDirty(true);
   //   setFormState({ ...formState, absenceIntervals: intervals });
   // }

   const handleRecurrenceFrequency = event => {
      setDirty(true)
      setRecurrenceFrequency(event.target.value)
   }

   const handlePeriod = date => {
      let newPeriodEnd = new Date(setDateToEndOfWorkDay(date, workDay))
      setDirty(true)
      setPeriodEnd(newPeriodEnd)
   }

   const sendRequest = async () => {
      const currentDate = new Date()
      currentDate.setHours(currentDate.getHours() - 3)
      setErrorAlert(null)

      // Validate at least 1 interval exists
      if (formState.absenceIntervals.length < 1) {
         setErrorAlert(t('missingDatesForRequest'))
         return
      }

      if (recurrenceFrequency !== 'once' && !periodEnd) {
         setErrorAlert(t('missingPeriodEnd'))
         return
      }

      if (
         formState.absenceIntervals[0] &&
         formState.absenceIntervals[0].Type === 'LEGAL' &&
         formState.CommentByUser === ''
      ) {
         setErrorAlert(t('commentRequired'))
         return
      }

      // Validate dates in all intervals
      let alertMsg = ''
      formState.absenceIntervals.map((interval, index) => {
         if (interval.Type === '') {
            alertMsg += t('absenceTypeNotSelected') + ' ' + (index + 1) + '. '
            return null
         }

         if (interval.StartDate === null || interval.EndDate === null) {
            alertMsg += t('intervalMissingDates') + ' ' + (index + 1) + '. '
            return null
         }
         if (interval.StartDate > interval.EndDate) {
            alertMsg += t('invalidIntervalDates') + ' ' + (index + 1) + '. '
            return null
         }

         if (interval.StartDate < currentDate) {
            alertMsg += t('requestsForPrevDatesNotAllowed')
            return null
         }

         if (
            requestState
               .filter(request => request.Status === 'REQUESTED')
               .find(request =>
                  request.AbsenceIntervals.find(
                     requestInterval =>
                        interval.StartDate < new Date(requestInterval.EndDate) &&
                        new Date(requestInterval.StartDate) < interval.EndDate
                  )
               ) ||
            userAbsences
               .filter(
                  absence =>
                     Object.values(absenceCodes).find(
                        absenceCode => absenceCode.code === absence.AbsenceType
                     ).affectsSalary
               )
               .find(
                  absence =>
                     interval.StartDate < new Date(absence.EndDate) &&
                     new Date(absence.StartDate) < interval.EndDate
               )
         ) {
            alertMsg += t('absenceOverlapDisallowed')
            return null
         }

         interval.StartDate = new Date(setDateToStartOfWorkDay(interval.StartDate, workDay))
         interval.EndDate = new Date(setDateToEndOfWorkDay(interval.EndDate, workDay))

         return null
      })

      // Validate available days
      // TODO only handles full days for bulgaria right now
      let totalVacay = 0
      if (
         props.useVacationDays &&
         formState.absenceIntervals[0].Type !== '' &&
         Object.values(absenceCodes).find(code => code.code === formState.absenceIntervals[0].Type)
            .usesVacationDays
      ) {
         if (
            (vacationCalc[3] &&
               formState.absenceIntervals.some(abs =>
                  moment(abs.EndDate).isAfter(vacationCalc[3].span.End)
               )) ||
            (now < requestNextYearOpen &&
               formState.absenceIntervals.some(abs =>
                  moment(abs.EndDate).isAfter(vacationCalc[vacationCalc.length - 1].span.End)
               ))
         ) {
            alertMsg += t('futureRequestDisallowed')
         }
         if (
            formState.absenceIntervals.some(abs =>
               moment(abs.EndDate).isAfter(moment(lastEmploymentDate))
            )
         ) {
            alertMsg += t('outsideEmployment')
         }

         const vacation = calculateAbsenceBetweenDates(
            formState.absenceIntervals[0].StartDate,
            formState.absenceIntervals[0].EndDate,
            holidays,
            workDay
         )
         totalVacay = vacation.days

         // Check which calculated vaccation days we should use
         // to determine if days are available.
         if (
            vacationCalc[0] != null &&
            vacationCalc[0].span &&
            dayIntervalContainsDate(
               moment(vacationCalc[0].span.Start),
               moment(vacationCalc[0].span.End),
               moment(formState.absenceIntervals[0].StartDate)
            )
         ) {
            //const available = (vacationCalc[0].available + vacationCalc[0].init - vacationCalc[0].requested.days - vacationCalc[0].used.days);

            if (vacation.days > vacationCalc[0].saved) {
               alertMsg += t('insufficentVacationDays')
            }
         } else if (
            vacationCalc[1] != null &&
            vacationCalc[1].span &&
            dayIntervalContainsDate(
               moment(vacationCalc[1].span.Start),
               moment(vacationCalc[1].span.End),
               moment(formState.absenceIntervals[0].StartDate)
            )
         ) {
            //const available = (vacationCalc[1].available - vacationCalc[1].requested.days - vacationCalc[1].used.days);

            if (vacation.days > vacationCalc[1].saved) {
               alertMsg += t('insufficentVacationDays')
            }
         } else if (
            vacationCalc[2] != null &&
            vacationCalc[2].span &&
            dayIntervalContainsDate(
               moment(vacationCalc[2].span.Start),
               moment(vacationCalc[2].span.End),
               moment(formState.absenceIntervals[0].StartDate)
            )
         ) {
            //const available = (vacationCalc[2].available - vacationCalc[2].requested.days - vacationCalc[2].used.days);

            if (vacation.days > vacationCalc[2].saved) {
               alertMsg += t('insufficentVacationDays')
            }
         } else if (
            vacationCalc[3] != null &&
            vacationCalc[3].span &&
            dayIntervalContainsDate(
               moment(vacationCalc[3].span.Start),
               moment(vacationCalc[3].span.End),
               moment(formState.absenceIntervals[0].StartDate)
            )
         ) {
            //const available = (vacationCalc[2].available - vacationCalc[2].requested.days - vacationCalc[2].used.days);

            if (vacation.days > vacationCalc[3].saved) {
               alertMsg += t('insufficentVacationDays')
            }
         } else {
            alertMsg += t('vacationNotWithinAllowedYears')
         }
      }

      const remainingVacay = vacationCalc
         .map(vac => (vac && vac.saved ? vac.saved : 0))
         .reduce((acc, value) => acc + value)
      if (totalVacay > remainingVacay) alertMsg += t('insufficentVacationDays')

      if (alertMsg.length > 0) {
         setErrorAlert(alertMsg)
         return
      }
      if (disableRequestFunction) {
         return
      }
      setDisableRequestFunction(true)

      const data = {
         EmployeeID: props.userInfo.EmployeeID,
         AbsenceIntervals: formState.absenceIntervals,
         Status: 'REQUESTED',
         IsApprovedByPO: formState.IsApprovedByPO,
         ApprovedByEmployeeID: null,
         Comment: null,
         RequestedDate: new Date(),
         CommentByUser: formState.CommentByUser,
         Periodicity: Object.values(absenceCodes).find(
            code => code.code === formState.absenceIntervals[0].Type
         ).usesVacationDays
            ? 'once'
            : recurrenceFrequency,
         PeriodEnd: periodEnd,
      }

      postAbsenceRequest(data, userInfo)
         .then(async response => {
            setDisableRequestFunction(false)
            if (response.data.error) {
               if (response.data.error.errmsg) {
                  setErrorAlert(response.data.error.errmsg)
               } else if (response.data.error.message) {
                  setErrorAlert(response.data.error.message)
               } else {
                  setErrorAlert(JSON.stringify(response))
               }
            } else {
               clearIntervals()
               setDirty(false)
               setSnackbarState({
                  open: true,
                  message: t('requestHasBeenSent'),
                  severity: 'success',
               })
               const requestsJson = await fetchRequests()
               setUserRequests(requestsJson.requests)

               /*
        calculateVacation(
          userAbsences,
          requestsJson.requests,
          thisUser,
          holidays,
          workDay
        );
        */

               props.absenceChange(props.userInfo)
            }
         })
         .catch(err => {
            setDisableRequestFunction(false)
            setErrorAlert(JSON.parse(JSON.stringify(err.message)))
         })
   }

   if (props.users == null) {
      return (
         <React.Fragment>
            <Typography variant="body2">{t('loadingUserData')}</Typography>
         </React.Fragment>
      )
   }
   if (requestState == null) {
      return (
         <React.Fragment>
            <Typography variant="body2">{t('loadingData')}</Typography>
         </React.Fragment>
      )
   }

   return (
      <React.Fragment>
         {props.useVacationDays ? (
            <VacationPaper
               t={t}
               setVacationCalc={setVacationCalc}
               user={thisUser}
               absences={userAbsences}
               requests={userRequests}
               holidays={holidays}
               workDay={workDay}
               VacationRefillMMDD={customer.VacationSettings.VacationRefillMMDD}
               ShowNextYearMMDD={customer.VacationSettings.ShowNextYearMMDD}
            />
         ) : null}

         <Paper className="contentPaper" style={{ maxWidth: 900, boxShadow: 'none' }}>
            <div className="conentWrapper">
               {errorAlert != null ? (
                  <div style={{ marginTop: 20 }}>
                     <Alert onClose={() => setErrorAlert(null)} severity="error">
                        {errorAlert}
                     </Alert>
                  </div>
               ) : (
                  <></>
               )}

               <div style={{ padding: 20 }}>
                  <Typography variant="h5" style={{ padding: '0 0 10px 0', fontSize: '22px' }}>
                     {t('absenceRequest')}
                  </Typography>

                  {formState.absenceIntervals.map((dat, index) => (
                     <div key={'date_div_' + index}>
                        <Grid
                           container
                           direction="row"
                           justifyContent="flex-start"
                           alignItems="center"
                           spacing={1}
                           style={{ paddingBottom: 10 }}
                        >
                           <Grid item xs={6} className={'p-2'}>
                              <Typography variant="body2">{t('absenceTypePlaceholder')}</Typography>
                              <Select
                                 className={classes.select}
                                 variant={'outlined'}
                                 value={formState.absenceIntervals[index].Type}
                                 renderValue={() => {
                                    if (!formState.absenceIntervals[index].Type) return t('select')
                                    return options.find(
                                       option =>
                                          option.value === formState.absenceIntervals[index].Type
                                    ).label
                                 }}
                                 displayEmpty
                                 onChange={event =>
                                    absenceTypeChanged(event.target.value.value, index)
                                 }
                              >
                                 {options.map((option, i) => (
                                    <MenuItem key={i} value={option}>
                                       {option.label}
                                    </MenuItem>
                                 ))}
                              </Select>
                           </Grid>
                           <Grid item xs={6} className={'p-2'}>
                              <div style={{ float: 'right', fontSize: '10px', paddingLeft: 300 }}>
                                 {getCalculatedTime()
                                    ? `${t('totalAmountDays')}: ${getCalculatedTime()}`
                                    : ''}
                              </div>
                           </Grid>
                        </Grid>
                        <Grid
                           key={'date_grid_' + index}
                           container
                           justifyContent="flex-start"
                           alignItems="flex-start"
                           spacing={1}
                           style={{ paddingBottom: 10 }}
                        >
                           <Grid item xs={6} className={'p-2'}>
                              <Typography variant="body2">{t('From')}</Typography>
                              <div className={classes.datepickerField}>
                                 <DatePicker
                                    className={'datepicker-field mt-1'}
                                    locale={t('datepickerLocale')}
                                    dateFormat={'dd.MM.yyyy'}
                                    showTimeSelect={false}
                                    timeIntervals="60"
                                    highlightDates={highlightDaysInRed(holidays)}
                                    minDate={new Date()}
                                    maxDate={parseJSON(dat.EndDate)}
                                    minTime={new Date().setHours(
                                       new Date(
                                          setDateToStartOfWorkDay(new Date(), workDay)
                                       ).getHours() - 1
                                    )}
                                    maxTime={new Date().setHours(
                                       new Date(
                                          setDateToEndOfWorkDay(new Date(), workDay)
                                       ).getHours() - 1
                                    )}
                                    selected={
                                       dat.StartDate != null ? parseJSON(dat.StartDate) : null
                                    }
                                    onChange={date => startDateChanged(date, index)}
                                 />
                              </div>
                           </Grid>
                           <Grid item xs={6} className={'p-2'}>
                              <Typography variant="body2">{t('To')}</Typography>
                              <div className={classes.datepickerField}>
                                 <DatePicker
                                    className={'datepicker-field mt-1'}
                                    disabled={!dat.StartDate}
                                    locale={t('datepickerLocale')}
                                    dateFormat={'dd.MM.yyyy'}
                                    showTimeSelect={false}
                                    timeIntervals="60"
                                    highlightDates={highlightDaysInRed(holidays)}
                                    minDate={parseJSON(dat.StartDate)}
                                    minTime={new Date(setDateToStartOfWorkDay(new Date(), workDay))}
                                    maxTime={new Date(setDateToEndOfWorkDay(new Date(), workDay))}
                                    openToDate={
                                       dat.StartDate
                                          ? parseJSON(formState.absenceIntervals[index].StartDate)
                                          : new Date()
                                    }
                                    selected={dat.EndDate != null ? parseJSON(dat.EndDate) : null}
                                    onChange={date => endDateChanged(date, index)}
                                 />
                              </div>
                           </Grid>
                        </Grid>

                        <Grid>
                           <div style={{ marginTop: 10, marginBottom: 20 }}>
                              <Checkbox
                                 checked={formState.IsApprovedByPO}
                                 onChange={() => {
                                    setFormState({
                                       ...formState,
                                       IsApprovedByPO: !formState.IsApprovedByPO,
                                    })
                                 }}
                              />
                              {t('POApprovedCheckbox')}
                           </div>
                        </Grid>
                        <Grid
                           container
                           justifyContent="flex-start"
                           alignItems="flex-start"
                           spacing={1}
                           style={{ paddingBottom: 20 }}
                        >
                           <Grid item xs={12} className={'p-2'}>
                              <Typography variant="body2">{t('commentLabel')}</Typography>
                              <TextField
                                 value={formState.CommentByUser}
                                 onChange={event => {
                                    setFormState({
                                       ...formState,
                                       CommentByUser: event.target.value,
                                    })
                                 }}
                                 required={
                                    formState.absenceIntervals[0] &&
                                    formState.absenceIntervals[0].Type === 'LEGAL'
                                 }
                                 variant="outlined"
                                 className={classes.textField}
                              />
                           </Grid>
                        </Grid>
                        <Grid
                           container
                           justifyContent="flex-start"
                           alignItems="flex-start"
                           spacing={1}
                           style={{ paddingBottom: 10 }}
                        >
                           {formState.absenceIntervals[index].Type &&
                           (Object.values(absenceCodes).find(
                              code => code.code === formState.absenceIntervals[index].Type
                           ).usesVacationDays ||
                              formState.absenceIntervals[index].Type === 'LEGAL' ||
                              formState.absenceIntervals[index].Type === 'DISTANS') ? null : (
                              <>
                                 <Grid item xs={6} className={'p-2'}>
                                    <FormControl>
                                       <FormLabel style={{ paddingTop: 8 }}>
                                          {t('schedulePeriods')}
                                       </FormLabel>
                                       <RadioGroup
                                          row
                                          value={recurrenceFrequency}
                                          onChange={handleRecurrenceFrequency}
                                       >
                                          <FormControlLabel
                                             value="once"
                                             control={<Radio />}
                                             label={t('labelOnce')}
                                          />
                                          <FormControlLabel
                                             value="weekly"
                                             control={<Radio />}
                                             label={t('labelWeekly')}
                                          />
                                          <FormControlLabel
                                             value="monthly"
                                             control={<Radio />}
                                             label={t('labelMonthly')}
                                          />
                                       </RadioGroup>
                                    </FormControl>
                                 </Grid>
                                 <Grid item xs={6} className={'p-2'} style={{ marginTop: 10 }}>
                                    <Typography variant="body2">{t('Until')}</Typography>
                                    <div className={classes.datepickerField}>
                                       <DatePicker
                                          placeholderText={t('endDate')}
                                          disabled={recurrenceFrequency === 'once'}
                                          className={'datepicker-field mt-1'}
                                          dateFormat="yyyy-MM-dd"
                                          locale={t('datepickerLocale')}
                                          highlightDates={highlightDaysInRed(holidays)}
                                          popperPlacement="right"
                                          showWeekNumbers
                                          minDate={
                                             dat.StartDate ? parseJSON(dat.StartDate) : new Date()
                                          }
                                          selected={periodEnd}
                                          onChange={date => handlePeriod(date)}
                                       />
                                    </div>
                                 </Grid>
                              </>
                           )}
                        </Grid>
                     </div>
                  ))}
                  <div style={{ marginTop: 15 }}>
                     <div className={'buttonContainer'} style={{ maxWidth: 400, paddingLeft: 10 }}>
                        <Grid
                           container
                           direction="row"
                           justifyContent="flex-start"
                           alignItems="center"
                           spacing={1}
                        >
                           <Grid item>
                              <Button
                                 size="small"
                                 variant="contained"
                                 color="primary"
                                 onClick={() => sendRequest()}
                              >
                                 {t('sendRequest')}
                              </Button>
                           </Grid>
                           <Grid item>
                              <Button
                                 size="small"
                                 variant="contained"
                                 color="secondary"
                                 onClick={() => clearIntervals()}
                              >
                                 {t('Clear')}
                              </Button>
                           </Grid>
                        </Grid>
                     </div>
                  </div>
               </div>
            </div>
         </Paper>

         {requestState.filter((r, i) => r.Status === 'REQUESTED').length > 0 ? (
            <Paper
               className="contentPaper"
               style={{ maxWidth: 900, marginTop: 20, boxShadow: 'none' }}
            >
               <div className="conentWrapper" style={{ marginLeft: 10 }}>
                  <div style={{ padding: 10 }}>
                     <Typography
                        align="left"
                        variant="h5"
                        style={{ paddingTop: 10, fontSize: '22px' }}
                     >
                        {t('waitingApproval')}
                     </Typography>
                  </div>
                  <div style={{ padding: 10 }}>
                     <OngoingVacations
                        absenceChange={props.absenceChange}
                        setSnackbarState={setSnackbarState}
                        userInfo={props.userInfo}
                        requestState={requestState}
                        users={props.users}
                        holidays={holidays}
                        setErrorAlert={setErrorAlert}
                        fetchRequests={loadData}
                     />
                  </div>
               </div>
            </Paper>
         ) : null}

         {requestState.filter((r, i) => r.Status !== 'REQUESTED').length > 0 ? (
            <Paper
               className="contentPaper"
               style={{ maxWidth: 900, marginTop: 20, boxShadow: 'none' }}
            >
               <div className="conentWrapper" style={{ marginLeft: 10 }}>
                  <div style={{ padding: 10 }}>
                     <Typography
                        align="left"
                        variant="h5"
                        style={{ paddingTop: 10, fontSize: '22px' }}
                     >
                        {t('History')}
                     </Typography>
                  </div>

                  <div style={{ padding: 10 }}>
                     <HistoryVacations
                        absenceChange={props.absenceChange}
                        setSnackbarState={setSnackbarState}
                        userInfo={props.userInfo}
                        requestState={requestState}
                        users={props.users}
                        holidays={holidays}
                        variant={
                           customer && customer.Features ? customer.Features.PrintFormVariant : ''
                        }
                        fetchRequests={fetchRequests}
                     />
                  </div>
               </div>
            </Paper>
         ) : null}

         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>
         <Prompt when={dirty} message={t('leavePagePrompt')} />
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      notifs: state.notifs,
      users: state.users,
      inactiveAbsences: state.inactiveAbsences,
      useVacationDays: state.useVacationDays,
   }
}

export default withStyles(styles)(
   withTranslation()(connect(mapStateToProps, { absenceChange, fetchUsers })(RequestAbsence))
)
