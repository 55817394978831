import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Snackbar from '@mui/material/Snackbar'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import React, { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import { connect } from 'react-redux'
import ConfirmDialog from '../ConfirmDialog'
import { fetchSalaryReports, fetchUsers } from '../redux/actions/actions'
import themeConfig from '../theme'
import { newSalaryReport, deleteSalaryReport, lockSalaryReport } from '../Api'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Moment from 'react-moment'
import { wrapUser, wrapReportStatus } from '../util/wrappers'
import { getUserByEmployeeID, sortDesc } from '../util/helpers'
import { dateIntervalsTouches, setDateToEndOfWorkDay } from 'novarto-time'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faLock, faFolder, faCode } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import bg from 'date-fns/locale/bg'
import log from '../util/log'
import { withTranslation } from 'react-i18next'
import absenceCodes from '../enums/absenceCode'
import clsx from 'clsx'
import RotateLoader from 'react-spinners/RotateLoader'
import makeStyles from '@mui/styles/makeStyles'

registerLocale('sv', sv)
registerLocale('en-GB', en)
registerLocale('bg', bg)

const useStyles = makeStyles({
   overlayChild: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255,255,255,0.5)',
      zIndex: 3,
      display: 'flex',
      justifyContent: 'center',
   },
})
const { styles } = themeConfig()

const SalaryReports = ({ ...props }) => {
   const { userInfo, fetchUsers, fetchSalaryReports, t, workDay } = props

   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      severity: 'info',
   })

   // Default month is the previous month
   let date = new Date()
   date.setMonth(date.getMonth() - 1)
   const [dateState, setDateState] = React.useState(date)

   const [confirmState, setConfirmState] = React.useState({
      confirmButtonTitle: null,
      showConfirm: false,
      confirmMessage: null,
      confirmOnChange: null,
      confirmButtonVariant: null,
      confirmTitle: null,
   })

   const [isLoadingData, setIsLoadingData] = React.useState(false)
   const classes = useStyles()

   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ open: false })
   }

   const toggleConfirm = (show, title, message, buttonTitle, buttonVariant, onChange) => {
      setConfirmState({
         showConfirm: show,
         confirmTitle: title,
         confirmMessage: message,
         confirmButtonTitle: buttonTitle,
         confirmButtonVariant: buttonVariant,
         confirmOnChange: onChange,
      })
   }

   useEffect(() => {
      async function loadData() {
         try {
            setIsLoadingData(true)
            await fetchSalaryReports(userInfo)
            await fetchUsers(userInfo)
            setIsLoadingData(false)
         } catch {
            setIsLoadingData(false)
            setSnackbarState({
               open: true,
               message: 'Error fetching salary reports',
               severity: 'error',
            })
         }
      }
      loadData()
   }, [userInfo, fetchUsers, fetchSalaryReports])

   const handleNew = async () => {
      try {
         setIsLoadingData(true)
         let date = dateState
         let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
         let lastDay = new Date(date.getFullYear(), date.getMonth(), 0)
         lastDay = new Date(setDateToEndOfWorkDay(lastDay, workDay))

         const user = props.users.find(u => u.EmployeeID === props.userInfo.EmployeeID)

         const data = {
            EmployeeID: props.userInfo.EmployeeID,
            CreatedByName:
               user.FirstName +
               ' ' +
               (user.MiddleName ? user.MiddleName + ' ' : '') +
               user.LastName,
            RelevantAbsences: Object.values(absenceCodes)
               .filter(absence => absence.affectsSalary || absence.code === 'DISTANS')
               .map(absence => absence.code),
            StartDate: firstDay,
            EndDate: lastDay,
         }

         const result = await newSalaryReport(props.userInfo, data)
         log.debug('handleNew result: ' + JSON.stringify(result))

         if (result && result.data.success) {
            setSnackbarState({ open: true, message: t('newReportMsg'), severity: 'success' })
            // Reload reports
            await props.fetchSalaryReports(props.userInfo)
            setIsLoadingData(false)
         } else {
            setIsLoadingData(false)
            setSnackbarState({ open: true, message: result.data.error, severity: 'error' })
         }
      } catch {
         setIsLoadingData(false)
         setSnackbarState({
            open: true,
            message: 'Error creating salary report',
            severity: 'error',
         })
      }
   }

   const deleteReport = async (id, spreadsheetId) => {
      try {
         setIsLoadingData(true)
         const result = await deleteSalaryReport(props.userInfo, id, spreadsheetId)
         if (result && result.data.success) {
            setSnackbarState({ open: true, message: t('reportDeletedMsg'), severity: 'success' })
            // Reload reports
            await props.fetchSalaryReports(props.userInfo)
            setIsLoadingData(false)
         } else {
            setIsLoadingData(false)
            setSnackbarState({ open: true, message: result.data.error, severity: 'error' })
         }
      } catch {
         setIsLoadingData(false)
         setSnackbarState({
            open: true,
            message: 'Error deleting salary report.',
            severity: 'error',
         })
      }
   }

   const lockReport = async id => {
      setIsLoadingData(true)
      let date = dateState
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

      let existsReportForInterval = false
      props.salaryReports.forEach(report => {
         if (
            report.Status === 'LOCKED' &&
            dateIntervalsTouches(report.StartDate, report.EndDate, firstDay, lastDay)
         ) {
            existsReportForInterval = true
         }
      })
      if (existsReportForInterval) {
         setSnackbarState({
            open: true,
            message: t('existingLockedReportForDates'),
            severity: 'error',
         })
         return
      }

      try {
         const result = await lockSalaryReport(props.userInfo, id)
         if (result && result.data.success) {
            setSnackbarState({ open: true, message: t('lockedReportMsg'), severity: 'success' })
            // Reload reports
            await props.fetchSalaryReports(props.userInfo)
            setIsLoadingData(false)
         } else {
            setIsLoadingData(false)
            setSnackbarState({ open: true, message: result.data.error, severity: 'error' })
         }
      } catch {
         setIsLoadingData(false)
         setSnackbarState({
            open: true,
            message: 'Error locking salary report.',
            severity: 'error',
         })
      }
   }

   if (props.users == null) {
      return t('loadingData')
   }

   return (
      <React.Fragment>
         <ConfirmDialog
            {...{
               confirmTitle: confirmState.confirmTitle,
               confirmButtonTitle: confirmState.confirmButtonTitle,
               confirmButtonVariant: confirmState.confirmButtonVariant,
               showConfirm: confirmState.showConfirm,
               message: confirmState.confirmMessage,
               handleConfirm: confirmState.confirmOnChange,
               toggleConfirm: toggleConfirm,
            }}
         />
         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>
         <Paper
            className="contentPaper"
            style={{ maxWidth: 1200, padding: '10px 20px', boxShadow: 'none' }}
         >
            {isLoadingData && (
               <div
                  id="overlay"
                  className={clsx(classes.overlayChild)}
                  style={{ alignItems: 'center', marginTop: 50 }}
               >
                  <RotateLoader color="green" loading={true} size={16} margin={8} />
               </div>
            )}
            <div className={'contentWrapper'}>
               <div style={{ padding: 10 }}>
                  <Typography variant="h5" style={{ paddingBottom: 10, fontSize: '22px' }}>
                     {t('createSalaryReportTitle')}
                  </Typography>
                  <Alert severity="info">{t('salaryReportInfoText')}</Alert>
               </div>
               <div style={{ padding: 10 }}>
                  <DatePicker
                     selected={dateState}
                     onChange={date => setDateState(date)}
                     dateFormat="yyyy - MMMM"
                     showMonthYearPicker
                     locale={t('datepickerLocale')}
                  />
               </div>

               <div style={{ padding: 10 }}>
                  <div>
                     <Button
                        size="small"
                        disabled={!dateState}
                        variant="contained"
                        color="primary"
                        onClick={() => handleNew()}
                     >
                        {t('createSalaryReport')}
                     </Button>
                  </div>
               </div>
            </div>
         </Paper>

         <Paper
            className="contentPaper"
            style={{ maxWidth: 1200, marginTop: 20, padding: '10px 20px', boxShadow: 'none' }}
         >
            <div className={'contentWrapper'}>
               <div style={{ padding: 10 }}>
                  <Typography variant="h5" style={{ fontSize: '22px' }}>
                     {t('archiveSalaryReport')}
                  </Typography>
               </div>
               <div style={{ padding: 10 }}>
                  {props.salaryReports == null ? (
                     <div
                        id="overlay"
                        className={clsx(classes.overlayChild)}
                        style={{ alignItems: 'center', marginTop: 50 }}
                     >
                        <RotateLoader color="green" loading={true} size={16} margin={8} />
                     </div>
                  ) : props.salaryReports.length < 1 ? (
                     t('noSalaryReportInDb')
                  ) : (
                     <React.Fragment>
                        <TableContainer
                           className="employmentTable"
                           component={Paper}
                           style={{ maxHeight: 500 }}
                        >
                           <Table size="small">
                              <TableHead>
                                 <TableRow>
                                    <TableCell align="center">{t('ID')}</TableCell>
                                    <TableCell align="center">{t('Created')}</TableCell>
                                    <TableCell align="center">{t('From')}</TableCell>
                                    <TableCell align="center">{t('To')}</TableCell>
                                    <TableCell align="center">{t('createdBy')}</TableCell>
                                    <TableCell align="center">{t('Status')}</TableCell>
                                    <TableCell>{t('Actions')}</TableCell>
                                 </TableRow>
                              </TableHead>

                              <TableBody>
                                 {props.salaryReports
                                    .sort((r1, r2) => sortDesc(r1.CreatedDate, r2.CreatedDate))
                                    .map((report, index) => (
                                       <React.Fragment key={index}>
                                          <TableRow
                                             selected={report.Status === 'OPEN'}
                                             style={
                                                report.SpreadsheetUrl
                                                   ? { backgroundColor: '#e4ede7' }
                                                   : {}
                                             }
                                          >
                                             <TableCell>{report._id}</TableCell>
                                             <TableCell align="center">
                                                {report.CreatedDate != null ? (
                                                   <Moment
                                                      date={report.CreatedDate}
                                                      format="YYYY-MM-DD HH:mm"
                                                   />
                                                ) : (
                                                   ''
                                                )}
                                             </TableCell>
                                             <TableCell align="center">
                                                {report.StartDate != null ? (
                                                   <Moment
                                                      date={report.StartDate}
                                                      format="YYYY-MM-DD"
                                                   />
                                                ) : (
                                                   ''
                                                )}
                                             </TableCell>
                                             <TableCell align="center">
                                                {report.EndDate != null ? (
                                                   <Moment
                                                      date={report.EndDate}
                                                      format="YYYY-MM-DD"
                                                   />
                                                ) : (
                                                   ''
                                                )}
                                             </TableCell>
                                             <TableCell align="center">
                                                {
                                                   wrapUser(
                                                      getUserByEmployeeID(
                                                         report.CreatedByEmployeeID,
                                                         props.users
                                                      )
                                                   ).label
                                                }
                                             </TableCell>
                                             <TableCell align="center">
                                                <Typography
                                                   variant="body2"
                                                   style={{ color: wrapReportStatus(report).color }}
                                                >
                                                   {t(wrapReportStatus(report).label)}
                                                </Typography>
                                             </TableCell>
                                             <TableCell>
                                                {report.Status === 'OPEN' ? (
                                                   <FontAwesomeIcon
                                                      style={{ marginRight: 6 }}
                                                      icon={faTrash}
                                                      onClick={() =>
                                                         toggleConfirm(
                                                            true,
                                                            t('deleteReport'),
                                                            t('deleteReportMsg') +
                                                               ': ' +
                                                               report._id +
                                                               '?',
                                                            t('deleteReport'),
                                                            'danger',
                                                            () =>
                                                               deleteReport(
                                                                  report._id,
                                                                  report.SpreadsheetID
                                                               )
                                                         )
                                                      }
                                                   />
                                                ) : (
                                                   <FontAwesomeIcon
                                                      style={{ marginLeft: 2 }}
                                                      color={'#ebebeb'}
                                                      icon={faTrash}
                                                   />
                                                )}

                                                {report.SpreadsheetUrl ? (
                                                   <>
                                                      <Link
                                                         target={'_blank'}
                                                         to={{ pathname: report.DriveFolderUrl }}
                                                      >
                                                         <FontAwesomeIcon
                                                            style={{ marginRight: 6 }}
                                                            icon={faFolder}
                                                         />
                                                      </Link>

                                                      <Link
                                                         target={'_blank'}
                                                         to={{ pathname: report.SpreadsheetUrl }}
                                                      >
                                                         <FontAwesomeIcon
                                                            style={{ marginRight: 6 }}
                                                            icon={faFile}
                                                         />
                                                      </Link>
                                                   </>
                                                ) : (
                                                   <Link
                                                      target={'_blank'}
                                                      to={`/showreport/html/${report._id}?lng=en`}
                                                   >
                                                      <FontAwesomeIcon
                                                         style={{ marginRight: 6 }}
                                                         icon={faCode}
                                                      />
                                                   </Link>
                                                )}
                                                {report.Status === 'OPEN' ? (
                                                   <FontAwesomeIcon
                                                      style={{ marginRight: 6 }}
                                                      icon={faLock}
                                                      onClick={() =>
                                                         toggleConfirm(
                                                            true,
                                                            t('lockReport'),
                                                            t('lockReportMsg'),
                                                            t('lockReport'),
                                                            'danger',
                                                            () => lockReport(report._id)
                                                         )
                                                      }
                                                   />
                                                ) : (
                                                   ''
                                                )}
                                             </TableCell>
                                          </TableRow>
                                       </React.Fragment>
                                    ))}
                              </TableBody>
                           </Table>
                        </TableContainer>
                     </React.Fragment>
                  )}
               </div>
            </div>
         </Paper>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      salaryReports: state.salaryReports,
      users: state.users,
      workDay: state.workDay,
   }
}

export default withStyles(styles)(
   withTranslation()(connect(mapStateToProps, { fetchSalaryReports, fetchUsers })(SalaryReports))
)
