import { Box, Button, CircularProgress, Divider, Tooltip, Typography } from '@mui/material'

import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { formatDate } from '../../../../../util/helpers'
import {
   ErrorOutlineOutlined as ErrorOutlineOutlinedIcon,
   SimCardDownloadOutlined as SimCardDownloadOutlinedIcon,
} from '@mui/icons-material'
import { connect } from 'react-redux'
import { createProjectReportExcel } from '../../../../../Api'
const ProjectOverviewHeader = ({ project, projectID, selectedPeriod, userInfo, t }) => {
   const [isCreatingExcel, setIsCreatingExcel] = useState(false)
   const generateReport = async () => {
      setIsCreatingExcel(true)
      const reportObj = {
         StartDate: new Date(selectedPeriod.startDate),
         EndDate: new Date(selectedPeriod.endDate),
         projectID,
      }

      try {
         const url = await createProjectReportExcel(reportObj, userInfo)
         window.open(url.data.folderUrl, '_blank')
         setIsCreatingExcel(false)
      } catch (error) {
         console.error('Error generating report:', error)
      }
   }
   return (
      <Box
         sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: ' 20px 20px',
            alignItems: 'center',
         }}
      >
         <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
               <Tooltip
                  componentsProps={{
                     tooltip: {
                        sx: {
                           p: '12px 15px',
                           color: 'white',
                           backgroundColor: '#52B3DD',
                           '& .MuiTooltip-arrow': {
                              color: '#52B3DD',
                           },
                        },
                     },
                  }}
                  placement="bottom-start"
                  title={t('warningInfo')}
                  arrow
               >
                  <ErrorOutlineOutlinedIcon sx={{ color: '#0083BB' }} />
               </Tooltip>
               <Typography sx={{ color: '#000000', fontWeight: 600, fontSize: 21, pl: '5px' }}>
                  {project.Name}
               </Typography>
            </Box>
            <Box sx={{ display: 'flex', width: 500, alignItems: 'center', pl: '27px' }}>
               <Typography sx={{ color: '#454545', fontWeight: 400, fontSize: 13 }}>
                  PM : {project?.PMname}
               </Typography>
               <Divider
                  sx={{ bgcolor: '#8ACCE6', height: '20px', m: '0 10px' }}
                  orientation="vertical"
                  variant="middle"
                  flexItem
               />
               <Typography sx={{ color: '#454545', fontWeight: 400, fontSize: 13 }}>
                  {`${t('lastUpdated')} : ${
                     project?.UpdateDate ? formatDate(project.UpdateDate) : 'N/A'
                  }`}
               </Typography>
            </Box>
         </Box>
         <Button
            variant="outlined"
            onClick={() => generateReport()}
            sx={{
               height: 30,
               borderRadius: '4px',
               border: '2px solid #008DC6',

               color: '#008DC6',
               '&:hover': {
                  bgcolor: '#2D3A73',
                  color: '#fff',
                  border: '2px solid #2D3A73',
               },
            }}
            startIcon={
               isCreatingExcel ? <CircularProgress size={20} /> : <SimCardDownloadOutlinedIcon />
            }
         >
            {t('downloadReport')}
         </Button>
      </Box>
   )
}
const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}

export default withTranslation()(connect(mapStateToProps)(ProjectOverviewHeader))
