import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
   Checkbox,
   Grid,
   Table,
   TableBody,
   TableCell,
   TableRow,
   Tooltip,
   Button,
   Accordion,
   AccordionSummary,
   AccordionDetails,
   Input,
   Snackbar,
   TextField,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import moment from 'moment'
import React, { useCallback } from 'react'
import Dropdown from 'react-dropdown'
import { connect } from 'react-redux'
import {
   fetchAllAbsencesForUser,
   updateUserSettings,
   splitAbsence,
   updateUserAcceptedReports,
   updateUserInfo,
   fetchBankHolidays,
   fetchDevices,
   fetchRequestsForEmployee,
   fetchCustomerForAuthUser,
   getUserByEmailAddress,
} from '../Api'
import absenceCodes from '../enums/absenceCode.js'
import { colourSchemes, iconSchemes } from '../scheme.js'
import themeConfig from '../theme'
import { sortByLabel, sortByStartDate, useEffectOnlyOnce } from '../util/helpers'
import {
   calculateAbsenceBetweenDates,
   isHoliday,
   isWeekend,
   setDateToEndOfWorkDay,
   setDateToStartOfWorkDay,
   calculateAbsenceMinsBetweenDates,
   calculateMinutesToDaysBasedOnWorkDay,
   getAbsenceCalcLabel,
} from 'novarto-time'
import { absenceChange, fetchNotifs, persistUserInfo, setTimeZone } from '../redux/actions/actions'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { Prompt } from 'react-router'
import log from '../util/log'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import VacationPaper from '../components/VacationPaper'

const { styles } = themeConfig()

const MyPage = ({ ...props }) => {
   const { classes, userInfo, fetchNotifs, t } = props

   const workDay = useSelector(state => state.workDay)
   const [customer, setCustomer] = React.useState(null)

   const [thisUser, setThisUser] = React.useState(null)

   const [formState, setFormState] = React.useState({
      PrivateEmail: '',
      CompanyMobile: '',
      Address: '',
   })

   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      severity: 'info',
   })

   const [holidays, setHolidays] = React.useState([])
   const [devices, setDevices] = React.useState([])

   const [absenceState, setAbsenceState] = React.useState([])
   const [userRequests, setUserRequests] = React.useState([])

   const [yearlyAbsence, setYearlyAbsence] = React.useState([])

   const [monthlyAbsence, setMonthlyAbsence] = React.useState([])

   const [competingAbsenceState, setCompetingAbsenceState] = React.useState([])

   const [choicesLeft, setChoicesLeft] = React.useState(true)

   const [vacationCalc, setVacationCalc] = React.useState([
      {
         year: 2020,
         used: 0,
         requested: 0,
         available: 0,
      },
      {
         year: 2021,
         used: 0,
         requested: 0,
         available: 0,
      },
   ])

   const [reportCheck, setReportCheck] = React.useState(false)

   const today = new Date()

   const [showSettings, setShowSettings] = React.useState(false)

   const [showDevices, setShowDevices] = React.useState(false)

   const [showMyInfo, setShowMyInfo] = React.useState(false)

   const [colourState, setColourState] = React.useState({
      currentColourScheme: colourSchemes.trafficLight,
   })

   const [iconState, setIconState] = React.useState({
      currentIconScheme: iconSchemes.dotsDefault,
   })

   const [state, setState] = React.useState({
      stripesChecked: true,
      spacesChecked: false,
      dayNamesDisplay: true,
      weekTop: true,
      numberOfWeeks: 2,
      pushExercise: true,
   })

   const healthCareAbsenceTypes = [
      ...Object.values(absenceCodes)
         .filter(absence => !props.inactiveAbsences.includes(absence.code))
         .filter(absence => absence.isHealthCare)
         .map(absence => absence.code),
   ]

   const startOfMonth = () => {
      let date = new Date()
      date.setDate(1)
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setMilliseconds(0)
      return date
   }

   const PrivateEmailRef = React.useRef()
   const PrivateEmailAdornmentRef = React.useRef()

   const CVRef = React.useRef()
   const CVAdornmentRef = React.useRef()

   const ExternalUserIDRef = React.useRef()
   const ExternalUserIDAdornmentRef = React.useRef()

   const isUnlockedAndAffectsSalary = absence =>
      !absence.Locked &&
      Object.values(absenceCodes).find(code => code.code === absence.AbsenceType).affectsSalary
   const isLastMonth = absence =>
      new Date(absence.StartDate).getMonth() <= today.getMonth() - 1 &&
      (!absence.EndDate || new Date(absence.EndDate).getMonth() >= today.getMonth() - 1)

   const loadUserSettings = useCallback(() => {
      const settings = userInfo.Settings
      let userColourScheme = Object.values(colourSchemes).find(
         s => s.label === settings.ColourScheme
      )
      let userIconScheme = Object.values(iconSchemes).find(s => s.label === settings.IconScheme)
      if (userColourScheme) setColourState({ currentColourScheme: userColourScheme })
      if (userIconScheme) setIconState({ currentIconScheme: userIconScheme })
      setState(state => ({
         ...state,
         stripesChecked: settings.StripedOverview,
         spacesChecked: settings.GroupedOverview,
         dayNamesDisplay: settings.ShowDayNames,
         weekTop: settings.ShowWeekTop,
         numberOfWeeks: settings.NumberOfWeeksShown ? `${settings.NumberOfWeeksShown}` : `2`,
         pushExercise: settings.PushExercise,
      }))
   }, [userInfo])

   const calculateReportTime = useCallback(
      (user, optionalCustomer) => {
         let verifyAbsence = true
         if (customer && customer.Features.VerifyAbsence !== undefined)
            verifyAbsence = customer.Features.VerifyAbsence
         if (optionalCustomer && optionalCustomer.Features.VerifyAbsence !== undefined)
            verifyAbsence = optionalCustomer.Features.VerifyAbsence
         setReportCheck(
            verifyAbsence &&
               today.getDate() <= 12 &&
               (!user.AcceptedReports ||
                  user.AcceptedReports.filter(rep => rep.ID === moment().format('YYYYMM')).length <
                     1)
         )
      },
      [today, customer]
   )

   const getCompetingAbsences = useCallback(
      absences => {
         let problems = []
         let currDate = new Date()
         currDate.setMonth(today.getMonth() - 1)
         currDate.setDate(1)
         currDate = new Date(setDateToStartOfWorkDay(currDate, workDay))

         // TODO maybe?
         // Check for times over workDay.StarTime and under workDay.EndTime and ignore these days specifically somehow... ... ...
         // Might also be issues with hour-long absences in general

         while (currDate.getMonth() !== today.getMonth()) {
            if (!isWeekend(currDate) && !isHoliday(currDate, holidays)) {
               absences
                  .filter(
                     abs =>
                        isUnlockedAndAffectsSalary(abs) &&
                        // Starts before end of current day
                        moment(abs.StartDate).isBefore(
                           moment(setDateToEndOfWorkDay(currDate, workDay))
                        ) &&
                        // Ends after start of current day
                        (!abs.EndDate ||
                           moment(abs.EndDate).isAfter(
                              moment(setDateToStartOfWorkDay(currDate, workDay))
                           ))
                  )
                  .forEach(abs =>
                     problems.length > 0 &&
                     problems.some(p => p.date.getDate() === currDate.getDate())
                        ? problems
                             .find(p => p.date.getDate() === currDate.getDate())
                             .absences.push({ absence: abs, priority: false })
                        : problems.push({
                             date: new Date(currDate),
                             absences: [{ absence: abs, priority: false }],
                          })
                  )
            }
            currDate.setDate(currDate.getDate() + 1)
         }

         problems
            .filter(p => p.absences.length > 1)
            .forEach(p => {
               for (let i = 0; i < p.absences.length - 1; i++) {
                  let remainder = p.absences.slice(i + 1)
                  let currAbs = p.absences[i]
                  let start = new Date(currAbs.absence.StartDate)
                  let end = new Date(currAbs.absence.EndDate)
                  if (
                     !remainder.some(
                        abs =>
                           new Date(abs.absence.StartDate) < end &&
                           new Date(abs.absence.EndDate) > start
                     )
                  ) {
                     currAbs.noncompetitive = true
                  }
               }
               p.absences = p.absences.filter(abs => !abs.noncompetitive)
            })

         return problems.filter(p => p.absences.length > 1)
      },
      [today, holidays, workDay]
   )

   const fullDayAbsence = useCallback(
      absence => {
         if (moment.duration(moment(absence.EndDate).diff(moment(absence.StartDate))).asDays() > 1)
            return true
         if (absence.EndDate && absence.StartDate > absence.EndDate) return false
         return calculateAbsenceBetweenDates(absence.StartDate, absence.EndDate, holidays, workDay)
            .days === 0
            ? false
            : true
      },
      [holidays, workDay]
   )

   const getAbsenceScore = useCallback(
      code => {
         let relevantAbsences = absenceState.filter(abs => abs.AbsenceType === code.code)
         return relevantAbsences.length + relevantAbsences.filter(abs => fullDayAbsence(abs)).length
      },
      [absenceState, fullDayAbsence]
   )

   const fetchCustomer = useCallback(async () => {
      let result = await fetchCustomerForAuthUser(userInfo)
      setCustomer(result.customer)
      return result.customer
   }, [userInfo])

   const fetchHolidays = useCallback(async () => {
      log.debug('Fetch Holidays running')
      return await fetchBankHolidays(userInfo).then(res => {
         if (res.success === 'false') {
            log.error('Failure to load data; try logging out and in again: ')
            return []
         } else {
            setHolidays(res)
            return res
         }
      })
   }, [userInfo])

   const loadData = useCallback(async () => {
      const user = await getUserByEmailAddress(userInfo)
      setThisUser(user.data)
      setFormState({
         PrivateEmail: user.data.PrivateEmail,
         CompanyMobile: user.data.CompanyMobile,
         Address: user.data.Address,
      })

      const initialHolidays = await fetchHolidays()
      const initialCustomer = await fetchCustomer()

      const json = await fetchAllAbsencesForUser(userInfo)
      const absences = json.data.filter(absence => {
         if (moment.duration(moment(absence.EndDate).diff(moment(absence.StartDate))).asDays() > 1)
            return true
         if (absence.EndDate && absence.StartDate > absence.EndDate) return false
         const duration = calculateAbsenceBetweenDates(
            absence.StartDate,
            absence.EndDate,
            initialHolidays,
            workDay
         )
         return duration.days > 0 || duration.hours > 0 || duration.mins > 0
      })

      setAbsenceState(absences)

      const requestsJson = await fetchRequestsForEmployee(userInfo)
      setUserRequests(requestsJson.requests)

      calculateReportTime(user.data, initialCustomer)
      setCompetingAbsenceState(getCompetingAbsences(absences))
      setMonthlyAbsence(
         absences
            .filter(
               abs =>
                  !abs.Locked &&
                  (!abs.EndDate ||
                     new Date(abs.StartDate) >= startOfMonth() ||
                     new Date(abs.EndDate) >= startOfMonth())
            )
            .sort(sortByStartDate)
      )
      setYearlyAbsence(
         Object.values(absenceCodes)
            .filter(code =>
               absences.some(
                  abs =>
                     (thisYear(abs.StartDate) || thisYear(abs.EndDate)) &&
                     abs.AbsenceType === code.code
               )
            )
            .sort((c1, c2) => getAbsenceScore(c2) - getAbsenceScore(c1))
      )
      fetchNotifs(userInfo)
   }, [
      userInfo,
      fetchHolidays,
      fetchNotifs,
      calculateReportTime,
      getAbsenceScore,
      getCompetingAbsences,
      fetchCustomer,
      workDay,
   ])

   const loadDevices = useCallback(async () => {
      await fetchDevices(userInfo).then(res => {
         if (res.success === 'false') {
            log.error('Failure to load data; try logging out and in again: ')
         } else {
            console.log('DEVICES: ' + JSON.stringify(res.data.data))
            setDevices(res.data.data)
         }
      })
   }, [userInfo])

   useEffectOnlyOnce(() => {
      loadUserSettings()
      loadData()
      loadDevices()
   })

   const thisYear = date => {
      let yearStart = new Date()
      yearStart.setFullYear(yearStart.getFullYear(), 0, 1)
      yearStart.setHours(0)
      yearStart.setMinutes(0)
      yearStart.setSeconds(0)
      yearStart.setMilliseconds(0)
      return new Date(date) >= yearStart
   }

   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ ...state, open: false })
   }

   const changeColours = async scheme => {
      let settings = { ...props.userInfo.Settings, ColourScheme: scheme.label }

      // Update backend
      await updateUserSettings(props.userInfo.EmployeeID, settings, props.userInfo)

      // Update userInfo
      props.userInfo.Settings = settings

      let trueScheme = Object.values(colourSchemes).find(
         s => s.label === props.userInfo.Settings.ColourScheme
      )
      setColourState({ currentColourScheme: trueScheme })
      props.persistUserInfo({ ...props.userInfo })
   }

   const changeIcons = async scheme => {
      let settings = { ...props.userInfo.Settings, IconScheme: scheme.label }
      props.userInfo.Settings = settings
      await updateUserSettings(props.userInfo.EmployeeID, settings, props.userInfo)
      let trueScheme = Object.values(iconSchemes).find(
         s => s.label === props.userInfo.Settings.IconScheme
      )
      setIconState({ currentIconScheme: trueScheme })
      props.persistUserInfo({ ...props.userInfo })
   }

   const handleChangeStripes = async event => {
      let newValue = event.target.checked
      let settings = { ...props.userInfo.Settings, StripedOverview: newValue }
      props.userInfo.Settings = settings
      await updateUserSettings(props.userInfo.EmployeeID, settings, props.userInfo)
      setState({ ...state, stripesChecked: newValue })
      props.persistUserInfo({ ...props.userInfo })
   }

   const handleChangeSpaces = async event => {
      let newValue = event.target.checked
      let settings = { ...props.userInfo.Settings, GroupedOverview: newValue }
      props.userInfo.Settings = settings
      await updateUserSettings(props.userInfo.EmployeeID, settings, props.userInfo)
      setState({ ...state, spacesChecked: newValue })
      props.persistUserInfo({ ...props.userInfo })
   }

   const handleChangeDayNames = async event => {
      let newValue = event.target.checked
      let settings = { ...props.userInfo.Settings, ShowDayNames: newValue }
      props.userInfo.Settings = settings
      await updateUserSettings(props.userInfo.EmployeeID, settings, props.userInfo)
      setState({ ...state, dayNamesDisplay: newValue })
      props.persistUserInfo({ ...props.userInfo })
   }

   const handleChangeNumberOfWeeksShown = async event => {
      let value = event.target.value
      let newValue = parseInt(value)
      if (isNaN(newValue)) return
      if (newValue > 6) newValue = 6
      if (newValue < 1) newValue = 1
      let settings = { ...props.userInfo.Settings, NumberOfWeeksShown: newValue }
      props.userInfo.Settings = settings
      await updateUserSettings(props.userInfo.EmployeeID, settings, props.userInfo)
      setState({ ...state, numberOfWeeks: value })
      props.persistUserInfo({ ...props.userInfo })
   }

   const handleChangePushExercise = async event => {
      let newValue = event.target.checked
      let settings = { ...props.userInfo.Settings, PushExercise: newValue }
      props.userInfo.Settings = settings
      await updateUserSettings(props.userInfo.EmployeeID, settings, props.userInfo)
      setState({ ...state, pushExercise: newValue })
      props.persistUserInfo({ ...props.userInfo })
   }

   const handleChangePreferredAbsence = (preferred, problem) => {
      problem.absences.forEach(abs => (abs.priority = false))
      preferred.value.priority = true
      if (competingAbsenceState.every(p => p.absences.find(abs => abs.priority)))
         setChoicesLeft(false)
   }

   const handleAcceptPriority = async () => {
      setChoicesLeft(true)
      let changes = []

      competingAbsenceState.forEach(problem => {
         const preferred = problem.absences.find(abs => abs.priority)
         let start =
            new Date(preferred.absence.StartDate) < problem.date
               ? new Date(problem.date)
               : new Date(preferred.absence.StartDate)
         let end = new Date(problem.date)

         end = setDateToEndOfWorkDay(end, workDay)

         if (end > new Date(preferred.absence.EndDate))
            end.setHours(new Date(preferred.absence.EndDate).getHours())

         problem.absences
            .filter(a => !a.priority)
            .map(a => a.absence)
            .forEach(abs =>
               changes.find(ch => ch.absenceToChange._id === abs._id)
                  ? changes
                       .find(ch => ch.absenceToChange._id === abs._id)
                       .changesInbound.push({ splitDate: start, pickupDate: end })
                  : changes.push({
                       absenceToChange: abs,
                       changesInbound: [{ splitDate: start, pickupDate: end }],
                    })
            )
      })

      await Promise.all(
         changes.map(async change => {
            let currAbsenceId = change.absenceToChange._id
            let changesInbound = change.changesInbound.sort((c1, c2) =>
               c1.splitDate === c2.splitDate ? 0 : c1.splitDate < c2.splitDate ? -1 : 1
            )
            for (let i = 0; i < changesInbound.length; i++) {
               let result = await splitAbsence(props.userInfo, {
                  id: currAbsenceId,
                  splitDate: changesInbound[i].splitDate,
                  pickupDate: changesInbound[i].pickupDate,
               })
               if (result.data.data) {
                  currAbsenceId = result.data.data.id2
               } else {
                  log.debug(
                     `No data returned. Index: ${i}; changesInbound.length: ${changesInbound.length}`
                  )
                  log.debug(result.data)
               }
            }
            return Promise.resolve()
         })
      ).then(() => loadData())
      props.absenceChange(props.userInfo)
   }

   const handleAcceptConsequences = async () => {
      let reports = thisUser.AcceptedReports ? thisUser.AcceptedReports : []
      const newReport = { ID: moment().format('YYYYMM') }
      reports.push(newReport)
      await updateUserAcceptedReports(props.userInfo.EmployeeID, reports, props.userInfo).then(
         response => {
            setReportCheck(false)
            props.absenceChange(props.userInfo)
            setSnackbarState({
               open: true,
               message: t('thanksForVerify'),
               severity: 'success',
            })
         },
         response =>
            setSnackbarState({
               open: true,
               message: t('errorText1'),
               severity: 'error',
            })
      )
   }

   const getIcon = severity => {
      log.debug('     getIcon')
      return iconState.currentIconScheme.scheme[severity]
   }

   const getColour = severity => {
      return colourState.currentColourScheme.scheme[severity]
   }

   const getTooltip = absence => {
      let tooltip = []
      let start = moment(absence.StartDate).format('D/M HH:mm')
      let end = absence.EndDate
         ? ' ~ ' + moment(absence.EndDate).format('D/M HH:mm')
         : ` ${t('ongoing')}`
      tooltip.push(
         <Typography key={'tooltip_' + absence._id}>
            {start}
            {end}
         </Typography>
      )
      if (absence.PublicComment)
         tooltip.push(
            <Typography key={'tooltip_public_comment_' + absence._id}>
               {absence.PublicComment}
            </Typography>
         )
      if (absence.PrivateComment)
         tooltip.push(
            <Typography key={'tooltip_private_comment_' + absence._id}>
               {absence.PrivateComment}
            </Typography>
         )
      return tooltip
   }

   const getIconPlurality = absence => {
      let result = []
      let plurality = 0
      const duration = moment
         .duration(moment(absence.EndDate).diff(moment(absence.StartDate)))
         .asDays()
      if (duration > 365) {
         plurality = 365
      } else if (absence.EndDate && absence.StartDate > absence.EndDate) {
         plurality = 0
      } else {
         plurality = calculateAbsenceBetweenDates(
            absence.StartDate,
            absence.EndDate,
            holidays,
            workDay
         ).days
      }
      if (plurality === 0) plurality++
      for (let i = 0; i < plurality; i++) {
         result.push(
            <Tooltip
               key={'iconPluralityTooltip_' + absence._id + '_' + i}
               style={{ whiteSpace: 'pre-line' }}
               arrow={true}
               title={getTooltip(absence)}
            >
               <Grid key={'grid_' + absence._id} item xs>
                  <FontAwesomeIcon
                     icon={getIcon(
                        state.pushExercise && healthCareAbsenceTypes.includes(absence.AbsenceType)
                           ? 5
                           : fullDayAbsence(absence)
                           ? 2
                           : 1
                     )}
                     color={getColour(
                        state.pushExercise && healthCareAbsenceTypes.includes(absence.AbsenceType)
                           ? 5
                           : fullDayAbsence(absence)
                           ? 2
                           : 1
                     )}
                  />
               </Grid>
            </Tooltip>
         )
      }
      return result
   }

   const getAbsenceTally = absences => {
      let totalMins = 0

      absences.forEach(abs => {
         if (abs.EndDate && abs.StartDate > abs.EndDate) return
         let mins = calculateAbsenceMinsBetweenDates(abs.StartDate, abs.EndDate, holidays, workDay)
         totalMins += mins
      })
      return calculateMinutesToDaysBasedOnWorkDay(totalMins, workDay)
   }

   const getPreferredAbsence = absences => {
      return absences.find(abs => abs.priority)
   }

   const wrapCompetingAbsence = entry => {
      if (!entry) return null
      let label = `${t(entry.absence.AbsenceType)} ${t('from')} ${moment(
         entry.absence.StartDate
      ).format('HH:mm D MMM YYYY')}
            ${t('to')} ${
               entry.absence.EndDate
                  ? moment(entry.absence.EndDate).format('HH:mm D MMM YYYY')
                  : t('ongoing')
            }`
      return { label: label, value: entry }
   }

   const formatAbsence = absence => {
      let dateFormat = 'YYYY-MM-DD'
      let periodData = { days: 0, hours: 0, mins: 0 }
      const duration = moment
         .duration(moment(absence.EndDate).diff(moment(absence.StartDate)))
         .asDays()
      const negative = absence.EndDate && absence.StartDate > absence.EndDate
      if (duration > 365) {
         periodData.days = 365
      } else {
         periodData = calculateAbsenceBetweenDates(
            absence.StartDate,
            absence.EndDate,
            holidays,
            workDay
         )
      }
      if (periodData.days < 1 && periodData.hours < 1 && periodData.mins < 1) return null
      return (
         <Grid container key={`${absence._id}`}>
            <Grid item xs>
               <Typography>
                  {moment(absence.StartDate).format(dateFormat)}
                  {absence.EndDate ? ` ~ ${moment(absence.EndDate).format(dateFormat)} ` : null}
               </Typography>
            </Grid>
            <Grid item xs>
               {t(absence.AbsenceType)} {negative ? '-' : ''}
               {getAbsenceCalcLabel(periodData, t)} {absence.EndDate ? '' : ` ${t('ongoing')}`}
            </Grid>
         </Grid>
      )
   }

   const handleEnterOnField = async (key, newValue) => {
      let data = { EmployeeID: props.userInfo.EmployeeID }
      data[key] = newValue

      await updateUserInfo(data, props.userInfo).then(
         response =>
            setSnackbarState({
               open: true,
               message: t('Saved'),
               severity: 'success',
            }),
         response =>
            setSnackbarState({
               open: true,
               message: t('errorText1'),
               severity: 'error',
            })
      )
      loadData()
   }

   const saveAll = async () => {
      let data = {
         EmployeeID: props.userInfo.EmployeeID,
         ...formState,
      }

      log.debug(' SAVING DATA: ' + JSON.stringify(data))

      await updateUserInfo(data, props.userInfo).then(
         response =>
            setSnackbarState({
               open: true,
               message: t('Saved'),
               severity: 'success',
            }),
         response =>
            setSnackbarState({
               open: true,
               message: t('errorText1'),
               severity: 'error',
            })
      )

      clearFields()
   }

   const clearFields = () => {
      loadData()
   }

   const unsavedDataOnPage = () => {
      return (
         (thisUser && formState.PrivateEmail !== thisUser.PrivateEmail) ||
         (thisUser && formState.CompanyMobile !== thisUser.CompanyMobile) ||
         (thisUser && formState.Address !== thisUser.Address)
      )
   }

   return (
      <React.Fragment>
         {reportCheck ? (
            <Paper
               className="contentPaper"
               style={{ maxWidth: 900, paddingTop: 20, marginBottom: 20, boxShadow: 'none' }}
            >
               <Paper style={{ paddingLeft: 40, paddingRight: 40, boxShadow: 'none' }}>
                  <Typography variant="h6">{t('titleComingSalaryReport')}</Typography>
                  <Alert severity="warning">{t('doubleCheckAbsence')}</Alert>
                  <Typography style={{ paddingBottom: 10 }} />

                  {absenceState.some(abs => isUnlockedAndAffectsSalary(abs) && isLastMonth(abs)) ? (
                     absenceState
                        .filter(abs => isUnlockedAndAffectsSalary(abs) && isLastMonth(abs))
                        .sort(sortByStartDate)
                        .map(abs => formatAbsence(abs))
                  ) : (
                     <Typography>{t('noAbsencePreviousMonth')}</Typography>
                  )}
                  {competingAbsenceState.length > 0 ? (
                     <React.Fragment>
                        <Alert severity="error">{t('competingAbsences')}</Alert>
                        {competingAbsenceState.map(problem => (
                           <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              style={{ maxWidth: 500, paddingTop: 10 }}
                           >
                              <Grid item xs>
                                 <Typography>
                                    <b>{moment(problem.date).format('D MMM')}</b>
                                 </Typography>
                              </Grid>
                              <Grid item xs>
                                 <Dropdown
                                    options={[
                                       ...Object.values(problem.absences).map(abs =>
                                          wrapCompetingAbsence(abs)
                                       ),
                                    ]}
                                    value={wrapCompetingAbsence(
                                       getPreferredAbsence(problem.absences)
                                    )}
                                    placeholder={t('selectAbsence')}
                                    onChange={selection =>
                                       handleChangePreferredAbsence(selection, problem)
                                    }
                                 />
                              </Grid>
                           </Grid>
                        ))}
                        <Button
                           disabled={choicesLeft}
                           size="small"
                           variant="contained"
                           color="primary"
                           style={{ padding: 10, marginTop: 10, marginRight: 10 }}
                           onClick={handleAcceptPriority}
                        >
                           {t('Send')}
                        </Button>
                     </React.Fragment>
                  ) : null}
                  <Button
                     disabled={competingAbsenceState.length > 0}
                     size="small"
                     variant="outlined"
                     color="primary"
                     style={{ padding: 10, marginTop: 10 }}
                     onClick={handleAcceptConsequences}
                  >
                     {t('Verify')}
                  </Button>
                  <Typography style={{ paddingBottom: 40 }} />
               </Paper>
            </Paper>
         ) : null}

         {props.useVacationDays && customer != null ? (
            <VacationPaper
               t={t}
               setVacationCalc={setVacationCalc}
               user={thisUser}
               absences={absenceState}
               requests={userRequests}
               holidays={holidays}
               workDay={workDay}
               VacationRefillMMDD={customer.VacationSettings.VacationRefillMMDD}
               ShowNextYearMMDD={customer.VacationSettings.ShowNextYearMMDD}
            />
         ) : null}

         <Paper
            key={'absenceContentPaper'}
            className="contentPaper"
            style={{
               maxWidth: 900,
               paddingTop: 20,
               paddingBottom: 20,
               marginBottom: 20,
               boxShadow: 'none',
            }}
         >
            <Paper
               key={'absencePaper'}
               style={{ paddingLeft: 25, paddingRight: 25, boxShadow: 'none' }}
            >
               <Typography variant="h5" style={{ paddingBottom: 10, fontSize: '22px' }}>
                  {t('monthAbsence')}
               </Typography>
               {monthlyAbsence.length > 0 ? (
                  monthlyAbsence.map(abs => formatAbsence(abs))
               ) : (
                  <Typography>{t('noAbsence')}</Typography>
               )}

               <Typography
                  variant="h5"
                  style={{ paddingBottom: 10, paddingTop: 40, fontSize: '22px' }}
               >
                  {t('yearTotalAbsence')}
               </Typography>

               <Table key={'totalYearTable'} style={{ maxWidth: 800 }} align="start">
                  <TableBody key={'totalYearTableBody'}>
                     {yearlyAbsence.length < 1 ? (
                        <React.Fragment>
                           <TableRow>
                              <TableCell>
                                 <Typography>{t('noAbsenceData')}</Typography>
                              </TableCell>
                           </TableRow>
                        </React.Fragment>
                     ) : (
                        yearlyAbsence.map((code, index) => (
                           <React.Fragment key={'tablekeyfrag_' + index}>
                              {props.userInfo.Settings.GroupedOverview &&
                              absenceState.filter(abs => abs.AbsenceType === code).length > 10 ? (
                                 <TableRow key={'tablekeygroup_' + index}>
                                    <TableCell style={{ border: 'none' }} colSpan={2} />
                                 </TableRow>
                              ) : null}

                              <TableRow
                                 key={'tablekeyrow_' + index}
                                 style={
                                    props.userInfo.Settings.StripedOverview && index % 2 !== 0
                                       ? { backgroundColor: '#fbfbff' }
                                       : null
                                 }
                              >
                                 <TableCell
                                    key={'tablecelltitle_' + index}
                                    style={{ border: 'none' }}
                                 >
                                    {t(code.code)}
                                 </TableCell>
                                 <TableCell
                                    key={'tablecellcontent_' + index}
                                    style={{ border: 'none' }}
                                    align="center"
                                 >
                                    <Grid
                                       key={'tablecell_grid_' + index}
                                       container
                                       direction="row"
                                       justifyContent="flex-start"
                                       alignItems="center"
                                    >
                                       {absenceState
                                          .filter(
                                             abs =>
                                                (thisYear(abs.StartDate) ||
                                                   thisYear(abs.EndDate)) &&
                                                abs.AbsenceType === code.code
                                          )
                                          .map(abs => getIconPlurality(abs))}
                                    </Grid>
                                 </TableCell>

                                 <TableCell
                                    key={'tablecellcontent2_' + index}
                                    style={{ border: 'none' }}
                                    align="center"
                                 >
                                    <Typography>
                                       {getAbsenceCalcLabel(
                                          getAbsenceTally(
                                             absenceState.filter(
                                                abs =>
                                                   (thisYear(abs.StartDate) ||
                                                      thisYear(abs.EndDate)) &&
                                                   abs.AbsenceType === code.code
                                             )
                                          ),
                                          t
                                       )}
                                    </Typography>
                                 </TableCell>
                              </TableRow>

                              {props.userInfo.Settings.GroupedOverview &&
                              absenceState.filter(abs => abs.AbsenceType === code).length > 10 ? (
                                 <TableRow key={'tablekeygroup2_' + index}>
                                    <TableCell style={{ border: 'none' }} colSpan={3} />
                                 </TableRow>
                              ) : null}
                           </React.Fragment>
                        ))
                     )}
                  </TableBody>
               </Table>
            </Paper>
         </Paper>

         <Paper
            className="contentPaper"
            style={{ maxWidth: 900, marginBottom: 20, boxShadow: 'none' }}
         >
            <Accordion onChange={() => setShowMyInfo(!showMyInfo)} sx={{ boxShadow: 'none' }}>
               <AccordionSummary
                  expandIcon={
                     <FontAwesomeIcon
                        icon={faCog}
                        color={showMyInfo ? 'deeppink' : 'black'}
                        style={{ marginRight: 15 }}
                     />
                  }
               >
                  <Typography variant="h6" style={{ paddingLeft: 15 }}>
                     {t('myInformation')}
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  {thisUser ? (
                     <Grid
                        key={'myInformationGridContainer'}
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={3}
                        style={{ padding: '0 15px 15px 15px' }}
                     >
                        <Grid item xs={4}>
                           <Typography variant="body2">{t('Firstname')}</Typography>
                           <TextField
                              value={thisUser.FirstName}
                              variant="outlined"
                              className={classes.textField}
                              disabled
                              placeholder={t('Firstname')}
                              style={{ backgroundColor: '#eee' }}
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <Typography variant="body2">{t('Middlename')}</Typography>
                           <TextField
                              value={thisUser.MiddleName}
                              variant="outlined"
                              className={classes.textField}
                              disabled
                              placeholder={t('Middlename')}
                              style={{ backgroundColor: '#eee' }}
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <Typography variant="body2">{t('Lastname')}</Typography>
                           <TextField
                              value={thisUser.LastName}
                              variant="outlined"
                              className={classes.textField}
                              disabled
                              placeholder={t('Lastname')}
                              style={{ backgroundColor: '#eee' }}
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <Typography variant="body2">{t('emailLabel')}</Typography>
                           <TextField
                              value={thisUser.CompanyEmail}
                              variant="outlined"
                              className={classes.textField}
                              disabled
                              placeholder={t('emailLabel')}
                              style={{ backgroundColor: '#eee' }}
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <Typography variant="body2">{t('Private email')}</Typography>
                           <TextField
                              value={formState.PrivateEmail}
                              variant="outlined"
                              className={classes.textField}
                              onChange={event => {
                                 setFormState({ ...formState, PrivateEmail: event.target.value })
                              }}
                              placeholder={t('Private email')}
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <Typography variant="body2">{t('phoneNumber')}</Typography>
                           <TextField
                              value={formState.CompanyMobile}
                              variant="outlined"
                              className={classes.textField}
                              onChange={event => {
                                 setFormState({ ...formState, CompanyMobile: event.target.value })
                              }}
                              placeholder={t('phoneNumber')}
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <Typography variant="body2">{t('Address')}</Typography>
                           <TextField
                              value={formState.Address}
                              variant="outlined"
                              className={classes.textField}
                              onChange={event => {
                                 setFormState({ ...formState, Address: event.target.value })
                              }}
                              placeholder={t('Address')}
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Typography variant="body2">{t('internalCV')}</Typography>
                              <Typography variant="body2">
                                 {thisUser.CV ? (
                                    <a target="_blank" rel="noopener noreferrer" href={thisUser.CV}>
                                       {t('Open')}
                                    </a>
                                 ) : (
                                    ''
                                 )}
                              </Typography>
                           </div>
                           <TextField
                              value={thisUser.CV}
                              variant="outlined"
                              className={classes.textField}
                              disabled
                              placeholder={t('internalCV')}
                              style={{ backgroundColor: '#eee' }}
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <Typography variant="body2">{t('ExternalUserID')}</Typography>
                           <TextField
                              value={thisUser.ExternalUserID}
                              variant="outlined"
                              className={classes.textField}
                              disabled
                              placeholder="C-USER/S-USER"
                              style={{ backgroundColor: '#eee' }}
                           />
                        </Grid>
                        {/*
                                <Grid item xs key="grid_TimeZone" style={{width:450}}>
                                    <Typography><b>{t("Timezone")}:</b></Typography>
                                    <TimezoneSelect
                                        style={{ width:450 }}
                                        value={thisUser.TimeZone}
                                        onChange={(zone) => {
                                            handleEnterOnField("TimeZone", zone.value);
                                            props.setTimeZone(zone.value);

                                        }}
                                    />
                                </Grid>
                                    */}
                        <Button
                           style={{ padding: '5px 25px', marginTop: 20, marginLeft: 15 }}
                           size="small"
                           variant="contained"
                           color="primary"
                           onClick={() => saveAll()}
                        >
                           {t('Save')}
                        </Button>
                        <Button
                           style={{ padding: '5px 20px', marginTop: 20, marginLeft: 15 }}
                           size="small"
                           variant="contained"
                           color="secondary"
                           onClick={() => clearFields()}
                        >
                           {t('cancelButtonTitle')}
                        </Button>
                     </Grid>
                  ) : null}
               </AccordionDetails>
            </Accordion>
         </Paper>

         <Paper
            className="contentPaper"
            style={{ maxWidth: 900, marginBottom: 20, boxShadow: 'none' }}
         >
            <Accordion onChange={() => setShowSettings(!showSettings)} sx={{ boxShadow: 'none' }}>
               <AccordionSummary
                  expandIcon={
                     <FontAwesomeIcon
                        icon={faCog}
                        color={showSettings ? 'deeppink' : 'black'}
                        style={{ marginRight: 15 }}
                     />
                  }
               >
                  <Typography variant="h6" style={{ paddingLeft: 15 }}>
                     {t('dashboardSettings')}
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Grid
                     container
                     direction="row"
                     justifyContent="flex-start"
                     alignItems="center"
                     style={{ maxWidth: 550, marginLeft: 40 }}
                     spacing={1}
                  >
                     <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{ maxWidth: 550, marginLeft: 30 }}
                        spacing={1}
                     >
                        <Grid style={{ maxWidth: 30 }} item xs>
                           <Tooltip
                              style={{ whiteSpace: 'pre-line' }}
                              arrow={true}
                              title={<Typography>{t('noDeviations')}</Typography>}
                              placement="top"
                           >
                              <div>
                                 <FontAwesomeIcon icon={getIcon(0)} color={getColour(0)} />
                              </div>
                           </Tooltip>
                        </Grid>
                        <Grid style={{ maxWidth: 30 }} item xs>
                           <Tooltip
                              style={{ whiteSpace: 'pre-line' }}
                              arrow={true}
                              title={<Typography>{t('occasionallyAbsent')}</Typography>}
                              placement="top"
                           >
                              <div>
                                 <FontAwesomeIcon icon={getIcon(1)} color={getColour(1)} />
                              </div>
                           </Tooltip>
                        </Grid>
                        <Grid style={{ maxWidth: 30 }} item xs>
                           <Tooltip
                              style={{ whiteSpace: 'pre-line' }}
                              arrow={true}
                              title={<Typography>{t('notPresent')}</Typography>}
                              placement="top"
                           >
                              <div>
                                 <FontAwesomeIcon icon={getIcon(2)} color={getColour(2)} />
                              </div>
                           </Tooltip>
                        </Grid>
                        <Grid style={{ maxWidth: 30 }} item xs>
                           <Tooltip
                              style={{ whiteSpace: 'pre-line' }}
                              arrow={true}
                              title={<Typography>{t('redDay')}</Typography>}
                              placement="top"
                           >
                              <div>
                                 <FontAwesomeIcon icon={getIcon(3)} color={getColour(3)} />
                              </div>
                           </Tooltip>
                        </Grid>
                     </Grid>

                     <Grid>
                        <div style={{ padding: 10 }}>
                           <Dropdown
                              options={[...Object.values(colourSchemes).sort(sortByLabel)]}
                              value={colourState.currentColourScheme}
                              placeholder={t('chooseColorScheme')}
                              onChange={scheme => changeColours(scheme)}
                           />
                        </div>

                        <div style={{ padding: 10 }}>
                           <Dropdown
                              options={[...Object.values(iconSchemes).sort(sortByLabel)]}
                              value={iconState.currentIconScheme}
                              placeholder={t('chooseIconScheme')}
                              onChange={scheme => changeIcons(scheme)}
                           />
                        </div>
                        <Grid
                           container
                           direction="row"
                           justifyContent="flex-start"
                           alignItems="center"
                           style={{ maxWidth: 350 }}
                           spacing={1}
                        >
                           <Grid item xs style={{ marginRight: -132 }}>
                              <Input
                                 placeholder={`${state.numberOfWeeks}`}
                                 id="input_field_NumberOfWeeksShown"
                                 key="input_field_NumberOfWeeksShown"
                                 style={{ maxWidth: 18, marginLeft: 22 }}
                                 onChange={event => handleChangeNumberOfWeeksShown(event)}
                              />
                           </Grid>
                           <Grid item xs align="center" style={{ width: 300 }}>
                              {t('numberWeeksShown')}
                           </Grid>
                        </Grid>
                        <div align="left" style={{ paddingLeft: 10 }}>
                           <Checkbox
                              checked={state.stripesChecked}
                              onChange={handleChangeStripes}
                           />
                           {t('Stripes')}
                        </div>
                        <div align="left" style={{ paddingLeft: 10 }}>
                           <Checkbox
                              checked={state.dayNamesDisplay}
                              onChange={handleChangeDayNames}
                           />
                           {t('weekDayNames')}
                        </div>
                        {healthCareAbsenceTypes.length > 0 ? (
                           <div align="left" style={{ paddingLeft: 10 }}>
                              <Checkbox
                                 checked={state.pushExercise}
                                 onChange={handleChangePushExercise}
                              />
                              {t('pushExercise')}?
                           </div>
                        ) : null}
                     </Grid>
                  </Grid>
               </AccordionDetails>
            </Accordion>
         </Paper>

         <Paper
            className="contentPaper"
            style={{ maxWidth: 900, marginBottom: 20, boxShadow: 'none' }}
         >
            <Accordion onChange={() => setShowDevices(!showDevices)} sx={{ boxShadow: 'none' }}>
               <AccordionSummary
                  expandIcon={
                     <FontAwesomeIcon
                        icon={faCog}
                        color={showDevices ? 'deeppink' : 'black'}
                        style={{ marginRight: 15 }}
                     />
                  }
               >
                  <Typography variant="h6" style={{ paddingLeft: 15 }}>
                     {t('dashboardDevices')}
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Grid container direction="column">
                     <Typography style={{ color: 'grey', paddingBottom: 8, paddingLeft: 15 }}>
                        {t('dashboardDevicesInfo')}
                     </Typography>

                     {devices.map((device, index) => (
                        <React.Fragment key={`${index}`}>
                           <Grid>
                              <Paper
                                 className="contentPaper"
                                 style={{
                                    maxWidth: 400,
                                    marginBottom: 20,
                                    padding: 20,
                                    boxShadow: 'none',
                                 }}
                              >
                                 <Typography>{device.UserAgent.OS}</Typography>
                                 <Typography>{device.UserAgent.Browser}</Typography>
                                 <Typography>IP: {device.IP}</Typography>
                                 {device.current ? (
                                    <React.Fragment>
                                       <Typography style={{ color: 'green' }}>
                                          {t('currentDevice')}
                                       </Typography>
                                    </React.Fragment>
                                 ) : (
                                    ''
                                 )}
                              </Paper>
                           </Grid>
                        </React.Fragment>
                     ))}
                  </Grid>
               </AccordionDetails>
            </Accordion>
         </Paper>

         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>

         <Prompt when={unsavedDataOnPage()} message={t('leavePagePrompt')} />
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      inactiveAbsences: state.inactiveAbsences,
      useVacationDays: state.useVacationDays,
   }
}

export default withStyles(styles)(
   withTranslation()(
      connect(mapStateToProps, {
         absenceChange,
         fetchNotifs,
         persistUserInfo,
         setTimeZone,
      })(MyPage)
   )
)
