import { Box, Paper } from '@mui/material'
import { withTranslation } from 'react-i18next'
import RoadmapHeader from './components/RoadmapHeader'
import RoadmapBody from './components/RoadmapBody'
import PeriodBar from '../components/PeriodBar'
import { fetchAllUsers, fetchCustomerForAuthUser, getProjectsForCustomer } from '../../../../../Api'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { getAllDatesOfAMonth } from '../../../../../util/helpers'
const Roadmap = ({ userInfo }) => {
   const currentYear = moment().format('YYYY')
   const currentMonth = moment().format('M')
   const dates = getAllDatesOfAMonth(currentYear, currentMonth)

   const [projects, setProjects] = useState([])
   const [users, setUsers] = useState([])
   const [projectFilters, setProjectFilters] = useState({
      all: true,
      active: false,
      closed: false,
   })
   const [nameFilter, setNameFilter] = useState('')
   const [isLoading, setIsLoading] = useState(false)
   const [period, setPeriod] = useState([
      {
         month: moment(currentMonth).format('MMMM'),
         dates: dates,
         quarter: moment(dates[0]).quarter(),
         year: moment(dates[0]).format('YYYY'),
      },
   ])

   useEffect(() => {
      setIsLoading(true)
      const loadData = async () => {
         try {
            const result = await fetchCustomerForAuthUser(userInfo)

            if (result.success) {
               const projectsResult = await getProjectsForCustomer(result.customer._id, userInfo)

               const now = new Date()
               const onlyOngoingAndFutureProjects = projectsResult.data.filter(project => {
                  if (project.EndDate == null) return true
                  if (new Date(project.EndDate) >= now) return true
                  return false
               })

               setProjects(onlyOngoingAndFutureProjects)

               const usersResult = await fetchAllUsers({ userInfo: userInfo })
               setUsers(usersResult.data)

               setIsLoading(false)
            }
         } catch (error) {
            setIsLoading(false)
            console.error('Error loading data:', error)
         }
      }

      loadData()
   }, [userInfo])
   return (
      <Paper sx={{ width: '100%', height: '100%', boxShadow: 'none' }}>
         <RoadmapHeader
            setProjectFilters={setProjectFilters}
            setPeriod={setPeriod}
            setNameFilter={setNameFilter}
         />
         <Box sx={{ ml: '165px', mr: '25px', py: '5px' }}>
            <PeriodBar period={period} />
         </Box>
         <RoadmapBody
            projectFilters={projectFilters}
            projects={projects}
            users={users}
            period={period}
            nameFilter={nameFilter}
         />
      </Paper>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}

export default withTranslation()(connect(mapStateToProps)(Roadmap))
