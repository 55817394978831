import { withTranslation } from 'react-i18next'
import { Box, Divider, Typography } from '@mui/material'
import React, { useState } from 'react'
import { formatDate, isUpdated } from '../../../../../util/helpers'
import { FiberManualRecord as FiberManualRecordIcon, Error as ErrorIcon } from '@mui/icons-material'

import { useHistory } from 'react-router-dom'
const ProjectBanner = ({ project, type }) => {
   const [hovered, setHovered] = useState(false)
   const history = useHistory()
   const updateDate = project.UpdateDate ? project.UpdateDate : null
   const redirect = link => {
      history.push(link)
   }
   return (
      <Box
         onMouseEnter={() => setHovered(true)}
         onMouseLeave={() => setHovered(false)}
         onClick={() => redirect(`/reportsAndPlaning/projectReport/projectOverview/${project._id}`)}
         sx={{
            display: 'flex',
            alignItems: 'flex-end',
            cursor: 'pointer',
            width: '100%',
            maxWidth: 211,
            minHeight: '108px',
            padding: '16px 8px 16px 8px',
            borderRadius: '8px',
            border: '2px solid',
            borderColor: type === 'external' ? '#FFC183' : '#8ACCE6',
            backgroundColor: type === 'external' ? '#FFF2E633' : '#EDF6FA80',
            '&:hover': {
               borderColor: '#2D3A73',
               bgcolor: '#2D3A73',
            },
         }}
      >
         <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', overflowWrap: 'anywhere' }}>
               <FiberManualRecordIcon
                  sx={{
                     color: project.Status === 'Active' ? '#9BE58C' : '#E58C9C',
                  }}
               />
               <Typography
                  sx={{
                     color: hovered ? '#fff' : '#3D3D3D',
                     fontWeight: 500,
                  }}
               >
                  {project?.Name}
               </Typography>
            </Box>
            <Divider
               sx={{
                  border: type === 'external' ? '1px solid #FFDEBE' : '1px solid #5AAED0',
                  borderRadius: '2px',
                  margin: '8px 25px',
               }}
            />
            <Box>
               <Typography
                  sx={{
                     textAlign: 'center',
                     fontSize: 10,
                     color: hovered ? '#fff' : '#3D3D3D',
                     alignItems: 'center',
                  }}
               >
                  PM: {project.PMname}
               </Typography>
               <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  {!isUpdated(updateDate) && (
                     <ErrorIcon sx={{ color: '#E58C9C', width: 14, height: 14 }} />
                  )}
                  <Typography
                     sx={{
                        textAlign: 'center',
                        alignItems: 'center',
                        fontSize: 10,
                        ml: '2px',
                        color: hovered ? '#fff' : !isUpdated(updateDate) ? '#E58C9C' : '#8F8F8F',
                     }}
                  >
                     Updated: {formatDate(updateDate)}
                  </Typography>
               </Box>
            </Box>
         </Box>
      </Box>
   )
}

export default withTranslation()(ProjectBanner)
