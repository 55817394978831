import { Box } from '@mui/material'
import { withTranslation } from 'react-i18next'

const ColorSquare = ({ color }) => {
   return (
      <Box
         sx={{
            width: 15,
            height: 15,
            border: '1px solid none',
            backgroundImage:
               color === 'deviation'
                  ? 'linear-gradient(to right, #D93F4C 50%, #A89AF9 50%)'
                  : `linear-gradient(to right, ${color} 50%, ${color} 50%)`,
            borderRadius: '4px',
            mr: '5px',
         }}
      />
   )
}

export default withTranslation()(ColorSquare)
