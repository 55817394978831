import { Button, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

const BackToRoadmapButton = () => {
   const history = useHistory()

   return (
      <Button
         variant="text"
         size="small"
         onClick={() => history.push('/reportsAndPlaning/resourcePlanning')}
         sx={{ margin: '10px 0 0 10px', textTransform: 'none' }}
      >
         <ArrowBack />
         <Typography>Back to Roadmap</Typography>
      </Button>
   )
}

export default withTranslation()(BackToRoadmapButton)
