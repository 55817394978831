import React, { useEffect, useCallback } from 'react'
import themeConfig from '../theme'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Snackbar from '@mui/material/Snackbar'
import {
   fetchGenericData,
   deleteGenericDataById,
   updateGenericDataById,
   createGenericData,
} from '../Api'
import Alert from '@mui/material/Alert'
import { TextField, Typography } from '@mui/material'
import { faEdit, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sortAsc } from '../util/helpers'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import log from '../util/log'
import { withTranslation } from 'react-i18next'

const { styles } = themeConfig()

const CostCenters = ({ ...props }) => {
   const { t } = props

   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      severity: 'info',
   })

   const initialFormState = {
      ID: '',
      DisplayID: '',
      Name: '',
   }

   const [formState, setFormState] = React.useState(initialFormState)

   /**
    * which index in the employment array on the user to edit
    * null if no row has been selected
    */
   const [editState, setEditState] = React.useState({
      index: null,
      CostCenter: null,
   })

   const [confirmState, setConfirmState] = React.useState({
      confirmButtonTitle: null,
      showConfirm: false,
      confirmMessage: null,
      confirmOnChange: null,
      confirmButtonVariant: null,
      confirmTitle: null,
   })

   const [dataState, setDataState] = React.useState([])

   const loadData = useCallback(async () => {
      const data = await fetchGenericData(props.userInfo, 'costCenterModel')
      setDataState(data)
   }, [props.userInfo])

   useEffect(() => {
      loadData()
   }, [loadData])

   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ open: false })
   }

   const create = async () => {
      try {
         const result = await createGenericData(props.userInfo, 'costCenterModel', {
            DisplayID: formState.DisplayID,
            Name: formState.Name,
         })
         log.debug('RESULT: ' + JSON.stringify(result))
         if (!result.success) {
            setSnackbarState({
               open: true,
               message: JSON.stringify(result.error),
               severity: 'error',
            })
         } else {
            setFormState(initialFormState)
         }
         loadData()
      } catch (err) {
         setSnackbarState({ open: true, message: err, severity: 'error' })
      }
   }

   const update = async () => {
      try {
         const result = await updateGenericDataById(
            props.userInfo,
            'costCenterModel',
            editState.CostCenter._id,
            formState
         )
         if (!result.success) {
            setSnackbarState({ open: true, message: result.message, severity: 'error' })
         } else {
            setFormState(initialFormState)
         }
         loadData()
      } catch (err) {
         setSnackbarState({ open: true, message: err, severity: 'error' })
      }
   }

   const deleteRow = async (CostCenter, index) => {
      log.debug('deleteRow: ' + JSON.stringify(CostCenter) + ' index: ' + index)
      try {
         const result = await deleteGenericDataById(
            props.userInfo,
            'costCenterModel',
            CostCenter._id
         )
         if (!result.success) {
            log.error('NOT success: ' + JSON.stringify(result.message))
            setSnackbarState({
               open: true,
               message: JSON.stringify(result.message),
               severity: 'error',
            })
         }
         loadData()
      } catch (err) {
         log.error('Catched error: ' + JSON.stringify(err))
         setSnackbarState({ open: true, message: JSON.stringify(err), severity: 'error' })
      }
   }

   const openRowForEdit = (CostCenter, index) => {
      setEditState({ CostCenter: CostCenter, index: index })

      setFormState({
         ...formState,
         DisplayID: CostCenter.DisplayID,
         ID: CostCenter.ID,
         Name: CostCenter.Name,
      })
   }

   return (
      <React.Fragment>
         <Dialog
            open={confirmState.showConfirm}
            onClose={() => setConfirmState({ ...confirmState, showConfirm: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">{confirmState.confirmTitle}</DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description">
                  {confirmState.confirmMessage}
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                     confirmState.confirmOnChange()
                     setConfirmState({ ...confirmState, showConfirm: false })
                  }}
                  autoFocus
               >
                  {confirmState.confirmButtonTitle}
               </Button>
               <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => setConfirmState({ ...confirmState, showConfirm: false })}
               >
                  {t('cancelButtonTitle')}
               </Button>
            </DialogActions>
         </Dialog>

         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>

         <Paper className="contentPaper" style={{ maxWidth: 580, padding: 10, boxShadow: 'none' }}>
            <div className={'contentWrapper'}>
               <Typography variant="h5" style={{ padding: 10, fontSize: 22 }}>
                  {t('titleCostCenters')}
               </Typography>

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <TextField
                     value={formState.DisplayID}
                     onChange={event => {
                        setFormState({ ...formState, DisplayID: event.target.value })
                     }}
                     label={t('ID')}
                     variant="outlined"
                     multiline
                     maxRows="5"
                     style={{ width: 80 }}
                  />

                  <TextField
                     value={formState.Name}
                     onChange={event => {
                        setFormState({ ...formState, Name: event.target.value })
                     }}
                     label={t('costCenterNameLabel')}
                     variant="outlined"
                     multiline
                     maxRows="5"
                     style={{ width: 410, marginLeft: 10 }}
                  />
               </div>

               <div style={{ padding: 10 }}>
                  <div>
                     <Button
                        size="small"
                        disabled={!(formState.DisplayID && formState.Name)}
                        variant="contained"
                        color="primary"
                        onClick={() => create()}
                     >
                        {t('saveNewCostCenter')}
                     </Button>

                     <Button
                        size="small"
                        style={{ marginLeft: 15 }}
                        disabled={!(formState.ID && formState.Name && editState.index !== null)}
                        variant={editState.index !== null ? 'outlined' : 'contained'}
                        color={'primary'}
                        onClick={() => update()}
                     >
                        {t('saveUpdatedCostCenter')}
                     </Button>
                  </div>
               </div>

               <TableContainer
                  className="table"
                  style={{ maxHeight: 500, width: 500, marginLeft: 10 }}
               >
                  <Table size="small">
                     <TableHead>
                        <TableRow>
                           <TableCell> </TableCell>
                           <TableCell align="center">{t('ID')}</TableCell>
                           <TableCell align="center">{t('costCenterNameLabel')}</TableCell>
                        </TableRow>
                     </TableHead>

                     <TableBody>
                        {dataState != null && dataState.length > 0
                           ? dataState
                                .sort((a, b) => sortAsc(a.DisplayID, b.DisplayID))
                                .map((cc, index) => (
                                   <React.Fragment key={index}>
                                      <TableRow
                                         key={'row_' + index}
                                         onClick={() => openRowForEdit(cc, index)}
                                      >
                                         <TableCell>
                                            <FontAwesomeIcon
                                               icon={
                                                  editState.cc != null && editState.index === index
                                                     ? faPen
                                                     : faEdit
                                               }
                                               color={
                                                  editState.cc != null && editState.index === index
                                                     ? 'green'
                                                     : 'black'
                                               }
                                               onClick={() => openRowForEdit(cc, index)}
                                            />

                                            <FontAwesomeIcon
                                               style={{ marginLeft: 5 }}
                                               icon={faTrash}
                                               onClick={event => {
                                                  event.stopPropagation()
                                                  setConfirmState({
                                                     showConfirm: true,
                                                     confirmTitle: t('confirmDeleteTitle'),
                                                     confirmMessage:
                                                        t('confirmDeleteCostCenterMsg') +
                                                        ': [' +
                                                        cc.DisplayID +
                                                        ' - ' +
                                                        cc.Name +
                                                        '] ' +
                                                        '?',
                                                     confirmButtonTitle: t('deleteButtonTitle'),
                                                     confirmButtonVariant: 'danger',
                                                     confirmOnChange: () => deleteRow(cc, index),
                                                  })
                                               }}
                                            />
                                         </TableCell>
                                         <TableCell
                                            variant={editState.index === index ? 'head' : 'body'}
                                            align="center"
                                         >
                                            {cc.DisplayID}
                                         </TableCell>
                                         <TableCell
                                            variant={editState.index === index ? 'head' : 'body'}
                                            align="center"
                                         >
                                            {cc.Name}
                                         </TableCell>
                                      </TableRow>
                                   </React.Fragment>
                                ))
                           : null}
                     </TableBody>
                  </Table>
               </TableContainer>
            </div>
         </Paper>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
   }
}

export default withStyles(styles)(withTranslation()(connect(mapStateToProps)(CostCenters)))
