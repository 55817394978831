import React, { useEffect, useCallback } from 'react'
import themeConfig from '../theme'
import { connect } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Snackbar from '@mui/material/Snackbar'
import {
   fetchGenericData,
   deleteGenericDataById,
   updateGenericDataById,
   createGenericData,
} from '../Api'
import Alert from '@mui/material/Alert'
import { Grid, TextField } from '@mui/material'
import { faEdit, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { highlightDaysInRed, sortDesc } from '../util/helpers'
import Moment from 'react-moment'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import log from '../util/log'
import DatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import bg from 'date-fns/locale/bg'
import { withTranslation } from 'react-i18next'
import moment from 'moment-timezone'

registerLocale('sv', sv)
registerLocale('en-GB', en)
registerLocale('bg', bg)

const { styles } = themeConfig()

const useStyles = makeStyles(theme => ({
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
   datepickerField: {
      '& .react-datepicker-wrapper': {
         width: '100%',
      },
   },
}))

const BankHolidays = ({ ...props }) => {
   const { t } = props

   const classes = useStyles()

   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      severity: 'info',
   })

   const initialFormState = {
      Date: null,
      Name: '',
   }

   const [formState, setFormState] = React.useState(initialFormState)

   // Default year is the current year
   let date = new Date()
   const [dateState, setDateState] = React.useState(date)

   /**
    * which index in the employment array on the user to edit
    * null if no row has been selected
    */
   const [editState, setEditState] = React.useState({
      index: null,
      BankHoliday: null,
   })

   const [confirmState, setConfirmState] = React.useState({
      confirmButtonTitle: null,
      showConfirm: false,
      confirmMessage: null,
      confirmOnChange: null,
      confirmButtonVariant: null,
      confirmTitle: null,
   })

   const [dataState, setDataState] = React.useState([])

   const loadData = useCallback(async () => {
      const data = await fetchGenericData(props.userInfo, 'bankHolidayModel')
      log.debug('DATA: ' + JSON.stringify(data))
      setDataState(data)
   }, [props.userInfo])

   useEffect(() => {
      loadData()
   }, [loadData])

   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ open: false })
   }

   const create = async () => {
      try {
         const result = await createGenericData(props.userInfo, 'bankHolidayModel', {
            Date: moment(formState.Date).format('YYYY-MM-DD'),
            Name: formState.Name,
         })
         log.debug('RESULT: ' + JSON.stringify(result))
         if (!result.success) {
            setSnackbarState({
               open: true,
               message: JSON.stringify(result.error),
               severity: 'error',
            })
         } else {
            setFormState(initialFormState)
         }
         loadData()
      } catch (err) {
         setSnackbarState({ open: true, message: err, severity: 'error' })
      }
   }

   const update = async () => {
      console.log('formState: ' + JSON.stringify(formState))
      try {
         const result = await updateGenericDataById(
            props.userInfo,
            'bankHolidayModel',
            editState.BankHoliday._id,
            formState
         )
         if (!result.success) {
            setSnackbarState({
               open: true,
               message: result.message,
               severity: 'error',
            })
         } else {
            setFormState(initialFormState)
         }
         loadData()
      } catch (err) {
         setSnackbarState({ open: true, message: err, severity: 'error' })
      }
   }

   const deleteRow = async (BankHoliday, index) => {
      log.debug('deleteRow: ' + JSON.stringify(BankHoliday) + ' index: ' + index)
      try {
         const result = await deleteGenericDataById(
            props.userInfo,
            'bankHolidayModel',
            BankHoliday._id
         )
         if (!result.success) {
            log.error('NOT success: ' + JSON.stringify(result.message))
            setSnackbarState({
               open: true,
               message: JSON.stringify(result.message),
               severity: 'error',
            })
         }
         loadData()
      } catch (err) {
         log.error('Catched error: ' + JSON.stringify(err))
         setSnackbarState({
            open: true,
            message: JSON.stringify(err),
            severity: 'error',
         })
      }
   }

   const openRowForEdit = (BankHoliday, index) => {
      setEditState({ BankHoliday: BankHoliday, index: index })

      setFormState({
         ...formState,
         Date: BankHoliday.Date,
         Name: BankHoliday.Name,
      })
   }

   return (
      <React.Fragment>
         <Dialog
            open={confirmState.showConfirm}
            onClose={() => setConfirmState({ ...confirmState, showConfirm: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">{confirmState.confirmTitle}</DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description">
                  {confirmState.confirmMessage}
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => setConfirmState({ ...confirmState, showConfirm: false })}
               >
                  {t('cancelButtonTitle')}
               </Button>
               <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                     confirmState.confirmOnChange()
                     setConfirmState({ ...confirmState, showConfirm: false })
                  }}
                  autoFocus
               >
                  {confirmState.confirmButtonTitle}
               </Button>
            </DialogActions>
         </Dialog>

         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>

         <Paper className="contentPaper" style={{ maxWidth: 1200, boxShadow: 'none' }}>
            <div className={'contentWrapper'}>
               <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  style={{ padding: 10, marginTop: 18 }}
               >
                  <Grid item xs={4} className={'p-2'}>
                     <Typography variant="h5" style={{ padding: 10, fontSize: 22 }}>
                        {t('manageHolidays')}
                     </Typography>
                     <div style={{ padding: 10 }}>
                        <Typography variant="body2">{t('selectYearToShow')}</Typography>
                        <div className={classes.datepickerField}>
                           <DatePicker
                              className={'datepicker-field mt-1'}
                              selected={dateState}
                              onChange={date => setDateState(date)}
                              dateFormat="yyyy"
                              showYearPicker
                              locale={t('datepickerLocale')}
                           />
                        </div>
                     </div>

                     <div style={{ padding: 10 }}>
                        <Typography variant="body2">{t('bankHolidayDateLabel')}</Typography>
                        <div className={classes.datepickerField}>
                           <DatePicker
                              selected={!formState.Date ? null : new Date(moment(formState.Date))}
                              highlightDates={highlightDaysInRed(dataState)}
                              className={'datepicker-field mt-1'}
                              dateFormat="yyyy-MM-dd"
                              locale={t('datepickerLocale')}
                              yearDropdownItemNumber={3}
                              showMonthDropdown={true}
                              showYearDropdown={true}
                              openToDate={!formState.Date ? null : new Date(moment(formState.Date))}
                              placeholderText={t('bankHolidayDateLabel')}
                              onChange={date =>
                                 setFormState({
                                    ...formState,
                                    Date: moment(new Date(date)).format('YYYY-MM-DD'),
                                 })
                              }
                           />
                        </div>
                     </div>
                     <div style={{ padding: 10 }}>
                        <Typography variant="body2">{t('bankHolidayNameLabel')}</Typography>
                        <TextField
                           value={formState.Name}
                           onChange={event => {
                              setFormState({ ...formState, Name: event.target.value })
                           }}
                           variant="outlined"
                           className={classes.textField}
                        />
                     </div>
                     <div style={{ padding: 10 }}>
                        <Button
                           size="small"
                           disabled={!(formState.Date && formState.Name)}
                           variant="contained"
                           color="primary"
                           onClick={() => create()}
                        >
                           {t('saveNewBankHoliday')}
                        </Button>

                        <Button
                           size="small"
                           style={{ marginLeft: 15 }}
                           disabled={
                              !(formState.Date && formState.Name && editState.index !== null)
                           }
                           variant={editState.index !== null ? 'outlined' : 'contained'}
                           color={'primary'}
                           onClick={() => update()}
                        >
                           {t('saveUpdatedBankHoliday')}
                        </Button>
                     </div>
                  </Grid>

                  <Grid item xs={8} className={'p-2'} style={{ marginTop: 90 }}>
                     <TableContainer
                        className="table"
                        style={{ maxHeight: 500, width: 750, marginLeft: 10 }}
                     >
                        <Table size="small">
                           <TableHead>
                              <TableRow>
                                 <TableCell></TableCell>
                                 <TableCell align="center">{t('bankHolidayDateLabel')}</TableCell>
                                 <TableCell align="center">{t('bankHolidayNameLabel')}</TableCell>
                              </TableRow>
                           </TableHead>

                           <TableBody>
                              {dataState != null && dataState.length > 0
                                 ? dataState
                                      .filter(
                                         a =>
                                            new Date(a.Date).getFullYear() ===
                                            dateState.getFullYear()
                                      )
                                      .sort((a, b) => sortDesc(a.Date, b.Date))
                                      .map((cc, index) => (
                                         <React.Fragment key={index}>
                                            <TableRow
                                               key={'row_' + index}
                                               onClick={() => openRowForEdit(cc, index)}
                                            >
                                               <TableCell>
                                                  <FontAwesomeIcon
                                                     icon={
                                                        editState.cc != null &&
                                                        editState.index === index
                                                           ? faPen
                                                           : faEdit
                                                     }
                                                     color={
                                                        editState.cc != null &&
                                                        editState.index === index
                                                           ? 'green'
                                                           : 'black'
                                                     }
                                                     onClick={() => openRowForEdit(cc, index)}
                                                  />

                                                  <FontAwesomeIcon
                                                     style={{ marginLeft: 5 }}
                                                     icon={faTrash}
                                                     onClick={event => {
                                                        event.stopPropagation()
                                                        setConfirmState({
                                                           showConfirm: true,
                                                           confirmTitle: t('confirmDeleteTitle'),
                                                           confirmMessage:
                                                              t('confirmDeleteBankHolidayMsg') +
                                                              ': [' +
                                                              cc.Date +
                                                              ' - ' +
                                                              cc.Name +
                                                              '] ' +
                                                              '?',
                                                           confirmButtonTitle:
                                                              t('deleteButtonTitle'),
                                                           confirmButtonVariant: 'danger',
                                                           confirmOnChange: () =>
                                                              deleteRow(cc, index),
                                                        })
                                                     }}
                                                  />
                                               </TableCell>
                                               <TableCell
                                                  variant={
                                                     editState.index === index ? 'head' : 'body'
                                                  }
                                                  align="center"
                                               >
                                                  <Moment
                                                     date={moment(cc.Date)}
                                                     format="YYYY-MM-DD"
                                                  />
                                               </TableCell>
                                               <TableCell
                                                  variant={
                                                     editState.index === index ? 'head' : 'body'
                                                  }
                                                  align="center"
                                               >
                                                  {cc.Name}
                                               </TableCell>
                                            </TableRow>
                                         </React.Fragment>
                                      ))
                                 : null}
                           </TableBody>
                        </Table>
                     </TableContainer>
                  </Grid>
               </Grid>
            </div>
         </Paper>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
   }
}

export default withStyles(styles)(withTranslation()(connect(mapStateToProps)(BankHolidays)))
