import React, { useState, Fragment, useMemo } from 'react'
import clsx from 'clsx'
import {
   Paper,
   Table,
   TableHead,
   TableBody,
   TableCell,
   TableRow,
   TableContainer,
   TextField,
   InputAdornment,
   Typography,
   Tabs,
   Tab,
   IconButton,
   Box,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { connect } from 'react-redux'
import PaginationItem from '@mui/material/PaginationItem'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment/min/moment-with-locales'
import TimesheetManagementDataRows from './dataRows/TimesheetManagementDataRows'
import Pagination from '@mui/material/Pagination'
import {
   ArrowBack as ArrowBackIcon,
   ArrowForward as ArrowForwardIcon,
   Search as SearchIcon,
   Close as CloseIcon,
} from '@mui/icons-material'

const useStyles = makeStyles({
   noBorder: {
      border: 'none',
      boxShadow: 'none',
      zIndex: 2,
   },
   white: {
      backgroundColor: '#fff',
   },
   narrow: {
      maxWidth: 16,
   },
   today: {
      border: 'none !important',
      boxShadow: 'none !important',
      backgroundColor: '#E0E0E0 !important',
   },
   overlayParent: {
      position: 'relative',
   },
})

const TimesheetManagementTable = React.memo(
   ({
      chosenDateWeek,
      changeDate,
      t,
      displayDate,
      users,
      filteredUsers,
      isToday,
      state,
      teams,
      projects,
      teamProjectFilters,
      setNewDataFetching,
      setFilter,
      filter,
      setTabIndex,
      tabIndex,
      setTeamProjectFilters,
      isDataFetching,
      newDataFetching,
   }) => {
      const [currentPage, setCurrentPage] = useState(1)
      const [hovered, setHovered] = useState(null)
      const classes = useStyles()

      const handleFilter = newFilter => {
         setFilter(newFilter)
         setCurrentPage(1)
      }

      const handleTabClose = data => {
         setTabIndex(0)
         setTeamProjectFilters(teamProjectFilters.filter(option => option.id !== data.id))
      }

      const handleChange = (event, option) => {
         setTabIndex(option)
      }

      moment.locale(t('momentLocale'))
      const pageSize = 10

      const handleChangePage = (event, page) => {
         setCurrentPage(page)

         const windowElement = document.querySelector('#scrollableDiv')
         if (windowElement) {
            windowElement.scrollTo({
               top: 0,
               behavior: 'smooth',
            })
         }
      }

      const startIndex = (currentPage - 1) * pageSize
      const endIndex = startIndex + pageSize
      const pageData = filteredUsers?.slice(startIndex, endIndex)

      const getRelevantColourClass = date => {
         const compare = new Date(date)
         if (isToday(compare)) return classes.today
         return classes.noBorder
      }
      function isWeekend(dateStr) {
         const date = new Date(dateStr)
         const day = date.getDay()
         return day === 0 || day === 6
      }

      const getWeekDayNameRow = week => {
         return (
            <Fragment key={'weekDayNameRow-' + week.number}>
               <TableCell className={clsx(classes.narrow, classes.noBorder, classes.white)} />
               {week.dates.map(date => {
                  const dateString = moment(date).format('ddd')
                  return (
                     <TableCell
                        className={clsx(classes.noBorder, classes.white)}
                        align={'center'}
                        style={{ padding: 1, maxWidth: 24 }}
                        key={'weekDayName' + date}
                     >
                        {moment(date).format('ddd').charAt(0).toUpperCase() + dateString.slice(1)}
                     </TableCell>
                  )
               })}
            </Fragment>
         )
      }

      const getDateRow = useMemo(() => {
         return (week, index) => {
            const firstWeek = index === 0
            const lastWeek = index === chosenDateWeek.weekDates.length - 13

            return (
               <Fragment key={`daterow${week.number}${index}`}>
                  {firstWeek ? (
                     <TableCell
                        className={clsx(classes.narrow, classes.noBorder, classes.white)}
                        align={'center'}
                        style={{ padding: 1 }}
                     >
                        <FontAwesomeIcon
                           color="#8FB9CA"
                           style={{ cursor: 'pointer' }}
                           icon={faArrowCircleLeft}
                           onClick={() => handleWeekBackward()}
                        />
                     </TableCell>
                  ) : (
                     <TableCell className={clsx(classes.narrow, classes.noBorder, classes.white)} />
                  )}
                  {week.dates.map(date => {
                     return (
                        <TableCell
                           className={clsx(classes.white, getRelevantColourClass(date))}
                           align={'center'}
                           style={{
                              padding: 1,
                              maxWidth: 24,
                              color: isWeekend(date) ? '#9E9E9E' : '',
                           }}
                           key={date}
                        >
                           {moment(date).format('D')}
                        </TableCell>
                     )
                  })}
                  {!!lastWeek && (
                     <TableCell
                        className={clsx(classes.narrow, classes.noBorder, classes.white)}
                        align={'center'}
                        style={{ padding: 1 }}
                     >
                        <FontAwesomeIcon
                           color="#8FB9CA"
                           style={{ cursor: 'pointer' }}
                           icon={faArrowCircleRight}
                           onClick={() => handleWeekForward()}
                        />
                     </TableCell>
                  )}
               </Fragment>
            )
         }
      }, [chosenDateWeek])

      const handleWeekForward = () => {
         setNewDataFetching(true)
         let date = new Date(displayDate)
         date.setDate(date.getDate() + 7)
         changeDate(date)
      }

      const handleWeekBackward = () => {
         setNewDataFetching(true)
         let date = new Date(displayDate)
         date.setDate(date.getDate() - 7)
         changeDate(date)
      }

      const isSelected = data => {
         const i = teamProjectFilters.findIndex(option => option.id === data.id)
         return tabIndex === i
      }

      const tabIdnicatorColor = () => {
         if (teamProjectFilters[tabIndex].hasOwnProperty('TeamLead')) {
            return '#008DC6'
         } else {
            return '#C49CD3'
         }
      }

      const determineTextColor = data => {
         if (data.hasOwnProperty('ProjectManager') && isSelected(data)) {
            return '#C49CD3'
         } else if (data.hasOwnProperty('TeamLead') && isSelected(data)) {
            return '#008DC6'
         } else {
            return '#B8B8B8'
         }
      }

      const determineIconButtonColor = data => {
         if (data.hasOwnProperty('ProjectManager')) {
            return '#C49CD3'
         } else {
            return '#77A6B6'
         }
      }
      return (
         <>
            <TableContainer
               component={Paper}
               style={{ width: '100%' }}
               className={clsx(classes.noBorder, classes.overlayParent)}
               id="scrollableDiv"
            >
               {teamProjectFilters.length !== 0 && (
                  <Tabs
                     scrollButtons
                     allowScrollButtonsMobile
                     variant="scrollable"
                     value={tabIndex}
                     onChange={handleChange}
                     sx={{ pb: 2, '.MuiTabs-flexContainer': { maxWidth: 930 } }}
                     TabIndicatorProps={{
                        style: {
                           background: tabIdnicatorColor(),
                           padding: '2px',
                        },
                     }}
                  >
                     {teamProjectFilters.map(data => {
                        return (
                           <Tab
                              onMouseEnter={() => setHovered(data)}
                              onMouseLeave={() => setHovered(null)}
                              sx={{
                                 position: 'relative',
                                 minWidth: 215,
                                 width: 200,
                                 m: '0 15px',
                                 p: '2px 16px',
                              }}
                              label={
                                 <Box sx={{ display: 'flex' }}>
                                    {hovered?.id === data?.id && (
                                       <Box
                                          sx={{
                                             position: 'absolute',
                                             bottom: 0,
                                             left: 0,
                                             backgroundColor: '#B8B8B8',
                                             width: '100%',
                                             height: '4px',
                                          }}
                                       ></Box>
                                    )}
                                    <Typography
                                       sx={{
                                          color: determineTextColor(data),
                                          fontSize: '16px',
                                          fontWeight: isSelected(data) ? 700 : 400,
                                          lineHeight: '22px',
                                          letterSpacing: '0.02em',
                                          textAlign: 'center',
                                       }}
                                    >
                                       {data.label}
                                    </Typography>
                                    {isSelected(data) && hovered?.id === data?.id && (
                                       <IconButton
                                          sx={{
                                             position: 'absolute',
                                             right: 0,
                                             p: 0,
                                             color: determineIconButtonColor(data),
                                             '&:hover': {
                                                background: 'none',
                                                color: '#E58C9C',
                                             },
                                          }}
                                          size="small"
                                          component="span"
                                          onClick={() => {
                                             handleTabClose(data)
                                          }}
                                       >
                                          <CloseIcon />
                                       </IconButton>
                                    )}
                                 </Box>
                              }
                           ></Tab>
                        )
                     })}
                  </Tabs>
               )}
               <Table
                  stickyHeader
                  size="small"
                  className={clsx(classes.noBorder)}
                  sx={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 4px' }}
               >
                  <TableHead>
                     {!!state.userInfo.Settings.ShowDayNames && (
                        <TableRow>
                           <TableCell className={clsx(classes.noBorder, classes.white)} />

                           {chosenDateWeek.weeksSorted.map(week => getWeekDayNameRow(week))}

                           <TableCell
                              className={clsx(classes.narrow, classes.noBorder, classes.white)}
                           />
                        </TableRow>
                     )}
                     {chosenDateWeek.weekDates != null && (
                        <TableRow>
                           <TableCell
                              className={clsx(classes.noBorder, classes.white)}
                              sx={{ width: 300, p: 0 }}
                           >
                              <TextField
                                 size="small"
                                 variant="outlined"
                                 InputProps={{
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <SearchIcon />
                                       </InputAdornment>
                                    ),
                                 }}
                                 style={{
                                    maxWidth: 290,
                                    marginRight: 32,
                                    paddingBottom: '5px',
                                 }}
                                 placeholder={t('filterPersonnelPlaceholder')}
                                 onChange={event => handleFilter(event.target.value.toLowerCase())}
                                 fullWidth
                              />
                           </TableCell>
                           {chosenDateWeek.weeksSorted.map((week, index) =>
                              getDateRow(week, index)
                           )}
                        </TableRow>
                     )}
                  </TableHead>
                  <TableBody>
                     <TimesheetManagementDataRows
                        users={users}
                        filter={filter}
                        filteredUsers={filteredUsers}
                        pageData={pageData}
                        chosenDateWeek={chosenDateWeek}
                        teams={teams}
                        projects={projects}
                        teamProjectFilters={teamProjectFilters}
                        getRelevantColourClass={getRelevantColourClass}
                        tabIndex={tabIndex}
                        newDataFetching={newDataFetching}
                     />
                  </TableBody>
               </Table>
            </TableContainer>
            {!isDataFetching && !filteredUsers.length ? (
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     flexFlow: 'column',
                     width: '100%',
                     height: 280,
                     m: '55px 0',
                     borderRadius: '8px',
                     bgcolor: '#EDF6FA',
                  }}
               >
                  <SearchIcon sx={{ color: '#5AAED0', fontSize: 35 }} />
                  <Typography sx={{ fontSize: 18, fontWeight: 600, color: '#008DC6' }}>
                     "{filter}" did not match any employee name in our system.
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: 600, color: '#008DC6B2' }}>
                     Make sure you type the name correctly.
                  </Typography>
               </Box>
            ) : !isDataFetching &&
              teamProjectFilters.length &&
              !teamProjectFilters[tabIndex]?.members?.length ? (
               <Box
                  sx={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     flexFlow: 'column',
                     width: '100%',
                     height: 280,
                     m: '55px 0',
                     borderRadius: '8px',
                     bgcolor: '#EDF6FA',
                  }}
               >
                  <Typography sx={{ fontSize: 18, fontWeight: 600, color: '#008DC6' }}>
                     No active employees for {teamProjectFilters[tabIndex]?.label}
                  </Typography>
               </Box>
            ) : !isDataFetching ? (
               <>
                  {teamProjectFilters.length === 0 && (
                     <Typography sx={{ textAlign: 'center', mt: 2, mb: 1, color: '#77A6B6' }}>
                        {t('viewing')} {''}
                        {filteredUsers?.length < currentPage * 10
                           ? filteredUsers?.length
                           : currentPage * 10}{' '}
                        {t('of')} {filteredUsers?.length}
                     </Typography>
                  )}
                  {teamProjectFilters.length === 0 && (
                     <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Pagination
                           siblingCount={0}
                           boundaryCount={1}
                           color="primary"
                           size="small"
                           renderItem={item => (
                              <PaginationItem
                                 sx={{ color: '#77A6B6' }}
                                 slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                 {...item}
                              />
                           )}
                           count={Math.ceil(filteredUsers?.length / pageSize) || 0}
                           page={currentPage}
                           onChange={handleChangePage}
                        />
                     </div>
                  )}
               </>
            ) : (
               ''
            )}
         </>
      )
   }
)

const mapStateToProps = state => {
   return {
      state,
   }
}

export default withTranslation()(connect(mapStateToProps)(TimesheetManagementTable))
