import { Box, Divider, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

const PeriodBar = ({ period }) => {
   const [twoYearsPeriod, setTwoYearsPeriod] = useState([])

   const periodDaysLength = period.reduce((acc, { dates }) => {
      acc = Number(acc) + dates.length
      return acc
   }, [])

   useEffect(() => {
      if (period.length > 13) {
         const twoYearsPeriod = period.reduce((acc, { year, quarter }) => {
            const yearEntry = acc.find(entry => entry.year === year)

            if (yearEntry) {
               if (!yearEntry.quarters.includes(quarter)) {
                  yearEntry.quarters.push(quarter)
               }
            } else {
               acc.push({ year, quarters: [quarter] })
            }

            return acc
         }, [])

         setTwoYearsPeriod(twoYearsPeriod)
      }
   }, [period])

   return (
      <Box
         sx={{
            height: 50,
            display: 'flex',
            alignItems: period.length <= 2 && periodDaysLength <= 31 ? 'flex-end' : 'center',
         }}
      >
         {/* When the period is less than or equal to 1 year */}
         {period.length <= 13 &&
            period.map((currentPeriod, index) => (
               <>
                  <Box
                     key={index}
                     sx={{
                        width: '100%',
                        textAlign: 'center',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                        flex: currentPeriod.dates.length,
                     }}
                  >
                     <Typography
                        sx={{ fontWeight: 500, textOverflow: 'ellipsis', overflow: 'hidden' }}
                     >
                        {period.length > 10
                           ? currentPeriod.month.substr(0, 3)
                           : currentPeriod.month}
                     </Typography>
                     {/* When the period less than or equal to 31 days } */}
                     {period.length <= 2 && periodDaysLength <= 31 && (
                        <Box
                           sx={{
                              display: 'flex',
                              textAlign: 'center',
                              overflow: 'hidden',
                           }}
                        >
                           {currentPeriod.dates.map((date, index) => (
                              <Box
                                 sx={{
                                    width: '100%',
                                    borderRight:
                                       index < currentPeriod.dates.length - 1
                                          ? '1px solid #A1A1A1'
                                          : 'none',
                                 }}
                              >
                                 <Typography
                                    sx={{
                                       fontSize: 14,
                                       fontWeight: 500,
                                       color: moment(date).isSame(moment(), 'day')
                                          ? '#0083BB'
                                          : '#636363',
                                    }}
                                 >
                                    {moment(date).format('DD')}
                                 </Typography>
                              </Box>
                           ))}
                        </Box>
                     )}
                  </Box>
                  {index < period.length - 1 && (
                     <Divider orientation="vertical" sx={{ height: 40 }} />
                  )}
               </>
            ))}

         {/* When the period more than 1 year */}
         {period.length > 13 &&
            twoYearsPeriod.map((currentPeriod, index) => (
               <>
                  <Box
                     sx={{
                        textAlign: 'center',
                        m: '0 8px',
                        flex: currentPeriod.quarters.length, // change the year size based on the quarters in it
                     }}
                  >
                     <Typography
                        sx={{ fontWeight: 500, textOverflow: 'ellipsis', overflow: 'hidden' }}
                     >
                        {currentPeriod.year}
                     </Typography>
                     <Box
                        sx={{
                           display: 'flex',
                           width: '100%',
                           textAlign: 'center',
                           justifyContent: 'space-around',
                        }}
                     >
                        {currentPeriod.quarters.map(quarter => (
                           <Typography sx={{ fontSize: 14 }}>Q{quarter}</Typography>
                        ))}
                     </Box>
                  </Box>
                  {index < twoYearsPeriod.length - 1 && (
                     <Divider orientation="vertical" sx={{ height: 35 }} />
                  )}
               </>
            ))}
      </Box>
   )
}

export default withTranslation()(PeriodBar)
