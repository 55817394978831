import React from 'react'
import { Divider, Grid } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
   border: {
      backgroundColor: '#E0E0E0'
   }
}))

const DividerWithText = ({ children }) => {
   const classes = useStyles()
   return (
      <Grid container alignItems='center' spacing={1}>
         <Grid item xs> <Divider className={classes.border} /> </Grid>   
         <Grid item> {children} </Grid>   
         <Grid item xs> <Divider className={classes.border} /> </Grid>       
      </Grid>
   
   )
}
export default DividerWithText
