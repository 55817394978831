import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Link from '@mui/material/Link';

const styles = theme => ({
  text: {
    marginBottom:10  
  },
  header: {
    fontSize:24,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 10
  }
  
});


const Privacy = (props) => {

  const {classes} = props;

return (
  <React.Fragment>
    <div style={{paddingTop:10, paddingLeft:100, paddingRight: 100}}>

<Typography className={{}}>These General Terms and Conditions shall regulate the access and use of the Website of the HR-system, Owned and managed by Novarto Ltd., hereinafter referred to as the Company. We kindly ask you to read them carefully because by visiting and/or using the Website, you acknowledge that you have read and understood the General Terms and Conditions and agree to be bound by them.
</Typography>

<Typography className={clsx(classes.header)}>Copyright</Typography>


<Typography className={clsx(classes.text)}>All texts, photo images, video and other material posted on the Website, separately and together, shall be subject to special protection under the Copyright and Related Rights Act.
</Typography>

<Typography className={clsx(classes.text)}>All copyrights and other rights over the materials posted on the Website are property of the Company. Any use, copying, and distribution of these materials are not allowed.
</Typography>

<Typography className={clsx(classes.text)}>This Website may contain links to third party websites, either as system functions or as user provided links. These are for your convenience and collaboration only, and the Company shall not be liable for their content and the information contained therein. The Company shall not be bond and makes no commitments to any conditions or warranties associated with third party websites.
</Typography>

<Typography className={clsx(classes.header)}>Personal Data & Privacy policy</Typography>

<Typography className={clsx(classes.text)}>This system is part of your employers salary system and the information managed in the system is regulated in your agreement with your employer. If you have issues with the personal data managed in the system, it should be taken up with, and managed by your employer. 
</Typography>

<Typography className={clsx(classes.text)}>The Company is committed to ensuring the integrity of the information containing personal data, as well as to protect any personal data, provided by users during use, against manipulation and unauthorized access.
</Typography>

<Typography className={clsx(classes.text)}>All our associates, as well as third parties, involved in the personal data processing, are required to comply with the Personal Data Protection Act, the General Data Protection Regulation of the European Economic Area (EEA) and the personal data statutory regulations. The data is not exported or used for any other purpose than what is necessary for the system operation. The Employer can at any time have data removed from the system in compliance with the GDPR regulations.
</Typography>

<Typography className={clsx(classes.header)}>Misuse</Typography>

<Typography className={clsx(classes.text)}>The users of the Company Website shall not use the Website or any of its content in a way that impedes the other visitors, violates their rights, interrupts or disrupts the proper functioning of the Website or adversely affects the information provided and/or the software that controls its operation.
</Typography>

<Typography className={clsx(classes.text)}>The use of the Website by circumventing any measures to protect its reserved sections is not allowed.
</Typography>

<Typography className={clsx(classes.text)}>It is forbidden to use the Website in a way that is reasonably considered to constitute or promote malicious conduct that could be considered a criminal offence, give rise to civil liability, or otherwise contravene the laws of any country.
</Typography>

<Typography className={clsx(classes.header)}>Cookies</Typography>

<Typography className={clsx(classes.text)}>The Company Website may use cookies. The cookies are small files that are stored on your computer hard drive and are required to use the Website. They do not contain personally identifiable information but are used to facilitate Website visitors. For example, cookies give us information about whether you have already visited the Website or you are a new visitor, and whether a page on the Website has previously been visited.
</Typography>

<Typography className={clsx(classes.text)}>Cookies do not store personal data and do not collect any other information that might identify you. If you wish, you can turn off the use of cookies in your browser. However, it would help if you keep in mind that turning off cookies can make it challenging to use Website functionality.
</Typography>

<Typography className={clsx(classes.text)}>Some cookies contain personal data – for example, if you clicked “Remember me” when logging in, the cookie will remember your username. We do not use such cookies. 
</Typography>

<Typography className={clsx(classes.text)}>Most cookies do not collect information that can identify you, but instead, collect general information about how you have reached our Website or where in the world you are located.
</Typography>

<Typography className={clsx(classes.text)}>What types of cookies does the Company Website use?
</Typography>

<Typography className={clsx(classes.text)}>Generally, cookies perform four different functions:
</Typography>

<b>1. Essential Cookies</b>

<Typography className={clsx(classes.text)}>We are not using such cookies on the Company Website. 
</Typography>

<Typography className={clsx(classes.text)}>Some cookies are vital to the functioning of the Website. They allow us to identify users who are registered as well as those who are our paid subscribers. They ensure that these users can comment, for example, under blog posts, and that paid subscribers have the necessary level of access to the Website sections. If a subscribed user chooses to stop these cookies, they will lose access to subscribers-only content.
</Typography>

<b>2. Website Performance Cookies</b>

<Typography className={clsx(classes.text)}>We use other cookies to analyze how visitors use our Website and monitor the performance of our Website. This allows us to offer you high-quality experience by customizing the content provided and quickly identifying and resolving various issues. For example, we could use cookies to track which pages are most popular. We may also use cookies to show you services that we think may be of interest to you, depending on how you use the Website.
</Typography>

<b>3. Website Functionality Cookies</b>

<Typography className={clsx(classes.text)}>The functionality cookies allow us to remember your preferences, such as font size and other personal preferences. For example, cookies save you the time and effort of entering a username every time you visit our Website. We do not currently use such cookies.
</Typography>

<b>4. Behaviour Targeted Advertising Cookies</b>

<Typography className={clsx(classes.text)}>In order to analyze our consumers, some sites use the Google Inc. service called Analytics. Under their own policies, Google uses Google Analytics data to provide its customers with Google Analytics measurement services. Identifiers, such as cookies and application instance IDs, are used to measure user interactions with clients’ websites and/or applications, while IPs are used to provide the service and ensure its security, and to give us an idea of ​​where our users are located in the world.
</Typography>

<Typography className={clsx(classes.text)}>In addition, when you share a page using social networks buttons (e.g. Facebook), the social network created the button will remember that action.
</Typography>

<Typography className={clsx(classes.text)}>Please find the full data protection terms and conditions at <Link href="https://support.google.com/analytics/answer/6004245?hl=en">https://support.google.com/analytics/answer/6004245?hl=en</Link>.
</Typography>

<b>How to turn cookies off?</b>

<Typography className={clsx(classes.text)}>All modern browsers allow you to change your cookie settings. You can usually find these settings in the Options or Preferences menu of your browser. To understand these settings, visiting the following links may be helpful, or you can use the Help option in your browser for more details.
</Typography>

<Typography className={clsx(classes.text)}><Link href="http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies#ie=ie-10">http://windows.microsoft.com/en-GB/internet-explorer/delete-manage-cookies#ie=ie-10</Link> Cookie settings in Internet Explorer
</Typography>

<Typography className={clsx(classes.text)}><Link href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies">https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies</Link> Cookie settings in Firefox
</Typography>

<Typography className={clsx(classes.text)}><Link href="https://support.google.com/chrome/answer/95647?hl=en&amp;ref_topic=14666">https://support.google.com/chrome/answer/95647?hl=en&amp;ref_topic=14666</Link> Cookie settings in Chrome
</Typography>

<Typography className={clsx(classes.text)}><Link href="https://support.apple.com/kb/PH5042?locale=en_US">https://support.apple.com/kb/PH5042?locale=en_US</Link> Cookie settings in Safari web
</Typography>

<Typography className={clsx(classes.text)}><Link href="https://support.apple.com/en-us/HT201265">https://support.apple.com/en-us/HT201265</Link>   - iOS
</Typography>

<Typography className={clsx(classes.text)}>If you are primarily concerned about third party cookies generated by advertisers, you can turn these off as follows:
</Typography>

<Typography className={clsx(classes.text)}><Link href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</Link> Your Online Choices site.
</Typography>

<Typography className={clsx(classes.text)}>Please note that if you choose to stop cookies, some sections of our Website may not work correctly.
</Typography>

<b>Does the Website use cookies that contain my personal data?</b>

<Typography className={clsx(classes.text)}>No, the cookies we use are anonymous and do not contain any personal data.
</Typography>

<Typography className={clsx(classes.header)}>Amendments</Typography>

<Typography className={clsx(classes.text)}>The Company shall reserve the right to make amendments to these General Terms and Conditions at any time by promptly posting these amendments on the Website, together with a notice of amendments in the General Terms and Conditions. By visiting our Website, you agree to the currently valid terms and conditions.
</Typography>

<Typography className={clsx(classes.text)}>The applicable Bulgarian law shall govern all outstanding issues in these General Terms and Conditions.
</Typography>

<Typography className={clsx(classes.header)}>Contact us</Typography>

<Typography className={clsx(classes.text)}>If you have any further questions, you can contact us at <Link href="info@givegetservice.com">info@givegetservice.com</Link>
</Typography>

</div>
</React.Fragment>
);
}

export default withStyles(styles)(Privacy);