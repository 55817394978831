import {
   FormControl,
   FormControlLabel,
   FormLabel,
   MenuItem,
   Radio,
   RadioGroup,
   Select,
   Snackbar,
   TextField,
} from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import { parseJSON } from 'date-fns'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-US'
import bg from 'date-fns/locale/bg'
import { default as React, useEffect, useState, useCallback } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import { connect } from 'react-redux'
import { Prompt } from 'react-router'
import {
   fetchAllAbsencesForRecurrence,
   fetchAllRecurrencesForEmployee,
   postNewAbsence,
   fetchBankHolidays,
   fetchAllAbsencesForUser,
   postReportedAbsence,
   newRecurrenceNotification,
} from '../Api'
import RecurrenceRow from '../components/RecurrenceRow'
import '../css/vacation.css'
import absenceCodes from '../enums/absenceCode'
import { fetchUsers } from '../redux/actions/actions'
import themeConfig from '../theme'
import { sortByLabel, highlightDaysInRed } from '../util/helpers'
import {
   isHoliday,
   calculateAbsenceBetweenDates,
   getAbsenceCalcLabel,
   setDateToStartOfWorkDay,
   setDateToEndOfWorkDay,
} from 'novarto-time'
import moment from 'moment'
import log from '../util/log'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

registerLocale('sv', sv)
registerLocale('en', en)
registerLocale('bg', bg)

const { styles } = themeConfig()

const useStyles = makeStyles(theme => ({
   datepickerField: {
      width: '100%',
      '& .react-datepicker-wrapper': {
         width: '100%',
      },
   },
   select: {
      marginTop: '0.25rem',
      width: '100%',
      height: '40px',
      fontSize: '0.875rem',
      borderRadius: '4px',
   },
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
}))

const OngoingRecurrences = ({ ...props }) => {
   return (
      <React.Fragment>
         {props.recurrenceState.map(recurrence => (
            <RecurrenceRow
               popupMessage={props.popupMessage}
               key={recurrence.recurrenceInstance.RecurrenceID}
               userInfo={props.userInfo}
               timeContraints={props.timeConstraints}
               recurrence={recurrence.recurrenceInstance}
               absences={recurrence.absences}
               users={props.users}
               reload={() => props.fetchRecurrences()}
               workDay={props.workDay}
            />
         ))}
      </React.Fragment>
   )
}

const ReportFlex = ({ ...props }) => {
   const classes = useStyles()

   const { userInfo, fetchUsers, t } = props

   const workDay = useSelector(state => state.workDay)

   const [recurrenceFrequency, setRecurrenceFrequency] = useState('once')

   const [periodEnd, setPeriodEnd] = useState(null)

   const [errorAlert, setErrorAlert] = React.useState(null)

   const [formState, setFormState] = React.useState({
      absenceInterval: {
         Date: null,
         StartTime: null,
         EndTime: null,
         Type: '',
         OptionalEndDate: null,
      },
   })

   const [recurrenceState, setRecurrenceState] = React.useState(null)

   const [salaryAffectingAbsences, setSalaryAffectingAbsences] = React.useState([])

   const [publicComment, setPublicComment] = React.useState('')

   const [privateComment, setPrivateComment] = React.useState('')

   const [dirty, setDirty] = useState(false)

   const [holidays, setHolidays] = React.useState([])

   const [disableRecurrenceFunction, setDisableRecurrenceFunction] = React.useState(false)

   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      severity: 'info',
   })

   const options = [
      ...Object.values(absenceCodes)
         .filter(absence => !props.inactiveAbsences.includes(absence.code))
         .filter(absence => !absence.approvalRequired)
         .map(absence => {
            return { value: absence.code, label: t(absence.code) }
         })
         .sort(sortByLabel),
   ]

   const isValidPeriod = formState.absenceInterval.OptionalEndDate
      ? formState.absenceInterval.Date <= formState.absenceInterval.OptionalEndDate
      : true

   const isFlexOrDistance =
      formState.absenceInterval.Type === 'DISTANS' ||
      formState.absenceInterval.Type === 'FLEX' ||
      formState.absenceInterval.Type === 'FRISKVARD'

   const getTimeConstraints = WorkDay => {
      return {
         now: new Date(),
         constrainPast: new Date().setMonth(new Date().getMonth() - 1),
         constrainFuture: new Date().setMonth(new Date().getMonth() + 3),
         dayStart: new Date(setDateToStartOfWorkDay(new Date(), WorkDay)),
         dayEnd: new Date(setDateToEndOfWorkDay(new Date(), WorkDay)),
      }
   }

   const getCalculatedTime = () => {
      let result = ''
      let negative = false

      if (
         formState.absenceInterval.Date &&
         formState.absenceInterval.OptionalEndDate &&
         isValidPeriod
      ) {
         if (formState.absenceInterval.Date > formState.absenceInterval.OptionalEndDate)
            negative = true
         const calc = calculateAbsenceBetweenDates(
            new Date(
               moment(
                  moment(formState.absenceInterval.Date).format('YYYY-MM-DD') +
                     ' ' +
                     workDay.StartTime
               )
            ),
            new Date(
               moment(
                  moment(formState.absenceInterval.OptionalEndDate).format('YYYY-MM-DD') +
                     ' ' +
                     workDay.EndTime
               )
            ),
            holidays,
            workDay
         )
         result = getAbsenceCalcLabel(calc, t)
      } else if (formState.absenceInterval.Date && isValidPeriod) {
         if (
            formState.absenceInterval.OptionalEndDate &&
            formState.absenceInterval.Date > formState.absenceInterval.OptionalEndDate
         )
            negative = true
         const calc = calculateAbsenceBetweenDates(
            formState.absenceInterval.Date,
            formState.absenceInterval.OptionalEndDate,
            holidays,
            workDay
         )
         result = getAbsenceCalcLabel(calc, t)
      }
      if (result.length > 0) {
         result = t('totalTime') + ': ' + (negative ? '-' : '') + result
      }
      return result
   }

   const fetchSalaryAffectingAbsences = useCallback(async () => {
      let result = await fetchAllAbsencesForUser(userInfo)
      result = result.data.filter(
         abs =>
            Object.values(absenceCodes).find(code => code.code === abs.AbsenceType).affectsSalary
      )
      setSalaryAffectingAbsences(result)
   }, [userInfo])

   const fetchRecurrences = useCallback(async () => {
      let parents = await fetchAllRecurrencesForEmployee(userInfo)
      const relevantAbsences = Object.values(absenceCodes)
         .filter(absence => !props.inactiveAbsences.includes(absence.code))
         .filter(absence => !absence.approvalRequired)
         .map(absence => absence.code)
      let result = []
      await Promise.all(
         parents.data
            .filter(dat => relevantAbsences.includes(dat.AbsenceType))
            .map(recur => fetchAllAbsencesForRecurrence(userInfo, recur))
      ).then(task =>
         task.forEach(absenceResult => {
            if (absenceResult.data.length < 1) return
            result.push({
               recurrenceInstance: parents.data.find(
                  recur => recur.RecurrenceID === absenceResult.data[0].RecurrenceID
               ),
               absences: absenceResult.data,
            })
         })
      )

      setRecurrenceState(result)
   }, [userInfo, props.inactiveAbsences])

   const fetchHolidays = useCallback(async () => {
      await fetchBankHolidays(props.userInfo).then(res => {
         if (res.success === 'false') {
            log.error('Failure to load data; try logging out and in again: ')
         } else {
            setHolidays(res)
         }
      })
   }, [props.userInfo])

   useEffect(() => {
      async function loadData() {
         await fetchUsers(userInfo)
         await fetchRecurrences()
         await fetchHolidays()
         await fetchSalaryAffectingAbsences()
      }

      loadData()
   }, [userInfo, fetchUsers, fetchHolidays, fetchRecurrences, fetchSalaryAffectingAbsences])

   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ ...snackbarState, open: false })
   }

   const clearForm = () => {
      setFormState({
         absenceInterval: {
            Date: null,
            StartTime: null,
            EndTime: null,
            Type: '',
            OptionalEndDate: null,
         },
      })
      setRecurrenceFrequency('once')
      setPeriodEnd(null)
      setErrorAlert(null)
      setPublicComment('')
      setPrivateComment('')
      setDirty(false)
   }

   const dateChanged = date => {
      // Limit date workday hours
      if (moment(date).isBefore(setDateToStartOfWorkDay(date, workDay))) {
         date = date === null ? null : new Date(setDateToStartOfWorkDay(date, workDay))
      }
      formState.absenceInterval.Date = date
      setDirty(true)
      setFormState({ absenceInterval: formState.absenceInterval })
   }
   const optionalDateChanged = date => {
      if (date.getHours() === 0 || moment(date).isAfter(setDateToEndOfWorkDay(date, workDay))) {
         date = date === null ? null : new Date(setDateToEndOfWorkDay(date, workDay))
      }
      formState.absenceInterval.OptionalEndDate = date
      setDirty(true)
      setFormState({ absenceInterval: formState.absenceInterval })
   }
   const startTimeChanged = time => {
      if (moment(time).isBefore(setDateToStartOfWorkDay(time, workDay))) {
         time = new Date(setDateToStartOfWorkDay(time, workDay))
      }
      formState.absenceInterval.StartTime = time
      setDirty(true)
      setFormState({ absenceInterval: formState.absenceInterval })
   }
   const endTimeChanged = time => {
      if (moment(time).isAfter(setDateToEndOfWorkDay(time, workDay))) {
         time = new Date(setDateToEndOfWorkDay(time, workDay))
      }
      formState.absenceInterval.EndTime = time
      setDirty(true)
      setFormState({ absenceInterval: formState.absenceInterval })
   }
   const absenceTypeChanged = type => {
      if (type !== 'DISTANS' && type !== 'FLEX' && type !== 'FRISKVARD') {
         if (
            (formState.absenceInterval.Date &&
               formState.absenceInterval.Date.getMinutes() === 30) ||
            (formState.absenceInterval.OptionalEndDate &&
               formState.absenceInterval.OptionalEndDate.getMinutes() === 30) ||
            (formState.absenceInterval.StartTime &&
               formState.absenceInterval.StartTime.getMinutes() === 30) ||
            (formState.absenceInterval.EndTime &&
               formState.absenceInterval.EndTime.getMinutes() === 30)
         ) {
            popupMessage(t('emptyingInvalidDateFieldsWarning'), {
               severity: 'warning',
            })
            dateChanged(null)
            optionalDateChanged(null)
            startTimeChanged(null)
            endTimeChanged(null)
         }
      }
      formState.absenceInterval.Type = type
      setDirty(true)
      setFormState({ absenceInterval: formState.absenceInterval })
   }

   const popupMessage = (message, args = {}) => {
      if (snackbarState.open) {
         if (args.quiet) return
         closeSnackbar()
      }
      setSnackbarState({
         open: true,
         message: message,
         severity: args.severity ? args.severity : 'success',
      })
   }

   const createRecurrence = async () => {
      setErrorAlert(null)

      // Validate
      let alertMsg = ''
      const addErrorMessage = msg => (alertMsg += `${msg}`)
      if (formState.absenceInterval.Type === '') {
         addErrorMessage(t('absenceTypeNotSelected2'))
      }
      if (
         formState.absenceInterval.StartDate === null ||
         formState.absenceInterval.EndDate === null
      ) {
         addErrorMessage(t('intervalMissingDates'))
      }
      if (
         formState.absenceInterval.StartDate > formState.absenceInterval.EndDate ||
         !isValidPeriod
      ) {
         addErrorMessage(t('invalidIntervalDates'))
      }
      if (!periodEnd && recurrenceFrequency !== 'once') {
         addErrorMessage(t('periodEndRequired'))
      }
      if (formState.absenceInterval.OptionalEndDate === null) {
         addErrorMessage(t('toDateForPeriodRequired'))
      }
      if (
         (formState.absenceInterval.OptionalEndDate &&
            formState.absenceInterval.OptionalEndDate >
               getTimeConstraints(workDay).constrainFuture) ||
         (formState.absenceInterval.OptionalEndDate &&
            formState.absenceInterval.OptionalEndDate <
               getTimeConstraints(workDay).constrainPast) ||
         (formState.absenceInterval.Date &&
            formState.absenceInterval.Date > getTimeConstraints(workDay).constrainFuture) ||
         (formState.absenceInterval.Date &&
            formState.absenceInterval.Date < getTimeConstraints(workDay).constrainPast) ||
         (formState.absenceInterval.StartDate &&
            formState.absenceInterval.StartDate > getTimeConstraints(workDay).constrainFuture) ||
         (formState.absenceInterval.StartDate &&
            formState.absenceInterval.StartDate < getTimeConstraints(workDay).constrainPast) ||
         (formState.absenceInterval.EndDate &&
            formState.absenceInterval.EndDate > getTimeConstraints(workDay).constrainFuture) ||
         (formState.absenceInterval.EndDate &&
            formState.absenceInterval.EndDate < getTimeConstraints(workDay).constrainPast)
      ) {
         addErrorMessage(t('constrainDates'))
      }
      if (alertMsg.length > 0) {
         setErrorAlert(alertMsg)
         return
      }

      const calculateTime = (time, option) => {
         let date = new Date(
            option ? formState.absenceInterval.OptionalEndDate : formState.absenceInterval.Date
         )
         date.setHours(new Date(time).getHours())
         date.setMinutes(new Date(time).getMinutes() === 30 ? 30 : 0)
         date.setSeconds(0)
         date.setMilliseconds(0)
         return date
      }

      let start = calculateTime(formState.absenceInterval.Date)
      let end = calculateTime(formState.absenceInterval.OptionalEndDate, true)

      if (moment(start).isBefore(setDateToStartOfWorkDay(start, workDay))) {
         start = new Date(setDateToStartOfWorkDay(start, workDay))
      }
      if (moment(end).isAfter(setDateToEndOfWorkDay(end, workDay))) {
         end = new Date(setDateToEndOfWorkDay(end, workDay))
      }

      const recurrenceID = '' + props.userInfo.EmployeeID + Date.now()
      const cutoff = periodEnd ? periodEnd : end

      const calculateDates = () => {
         let segments = []
         let segmentStart = new Date(start)
         let segmentEnd = new Date(end)
         let frequency =
            recurrenceFrequency === 'daily' ? 1 : recurrenceFrequency === 'weekly' ? 7 : 0
         while (segmentEnd <= cutoff) {
            if (
               segmentStart.getDay() < 6 &&
               segmentStart.getDay() > 0 &&
               !isHoliday(segmentStart, holidays)
            ) {
               segments.push({
                  start: new Date(segmentStart),
                  end: new Date(segmentEnd),
               })
            }
            if (frequency > 0) {
               segmentStart.setDate(segmentStart.getDate() + frequency)
               segmentEnd.setDate(segmentEnd.getDate() + frequency)
            } else {
               segmentStart.setMonth(segmentStart.getMonth() + 1)
               segmentEnd.setMonth(segmentEnd.getMonth() + 1)
            }
         }
         return segments
      }
      let dates = calculateDates()

      if (
         formState.absenceInterval.Type !== 'FLEX' &&
         formState.absenceInterval.Type !== 'FRISKVARD' &&
         dates.some(date =>
            salaryAffectingAbsences.some(
               abs => date.start < new Date(abs.EndDate) && date.end > new Date(abs.StartDate)
            )
         )
      ) {
         setErrorAlert(t('overlappingSalaryAffecting'))
         return
      }
      if (disableRecurrenceFunction) {
         return
      }
      setDisableRecurrenceFunction(true)

      const data = {
         RecurrenceID: recurrenceID,
         EmployeeID: props.userInfo.EmployeeID,
         AbsenceType: formState.absenceInterval.Type,
         PeriodEndDate: cutoff,
         Periodicity: recurrenceFrequency,
      }

      postReportedAbsence(data, props.userInfo)
         .then(response => {
            setDisableRecurrenceFunction(false)
            if (response.data.error) {
               if (response.data.error.errmsg) {
                  setErrorAlert(response.data.error.errmsg)
               } else if (response.data.error.message) {
                  setErrorAlert(response.data.error.message)
               } else {
                  setErrorAlert(JSON.stringify(response))
               }
               return
            }
         })
         .catch(err => {
            setDisableRecurrenceFunction(false)
            setErrorAlert(JSON.parse(JSON.stringify(err.message)))
            return
         })

      await Promise.all(
         dates.map(date =>
            postNewAbsence(props.userInfo, {
               EmployeeID: props.userInfo.EmployeeID,
               AbsenceType: formState.absenceInterval.Type,
               StartDate: date.start,
               EndDate: date.end,
               RecurrenceID: recurrenceID,
               PublicComment: publicComment,
               PrivateComment: privateComment,
            })
         )
      ).then(task => {
         task.forEach(response => {
            log.debug(response)
            if (response.data.error) {
               if (response.data.error.errmsg) {
                  setErrorAlert(response.data.error.errmsg)
               } else if (response.data.error.message) {
                  setErrorAlert(response.data.error.message)
               } else {
                  setErrorAlert(JSON.stringify(response))
               }
            }
         })

         // Success lets post new mail notify
         notifyNewRecurrence(recurrenceID)
      })

      setDirty(false)
      clearForm()
      fetchRecurrences()
      popupMessage(t('absenceSaved'))
   }

   const notifyNewRecurrence = recurrenceID => {
      newRecurrenceNotification(recurrenceID, userInfo)
         .then(response => {
            if (response.data.error) {
               if (response.data.error.errmsg) {
                  setErrorAlert(response.data.error.errmsg)
               } else if (response.data.error.message) {
                  setErrorAlert(response.data.error.message)
               } else {
                  setErrorAlert(JSON.stringify(response))
               }
               return
            }
         })
         .catch(err => {
            setErrorAlert(JSON.parse(JSON.stringify(err.message)))
            return
         })
   }

   const handleRecurrenceFrequency = event => {
      setDirty(true)
      setRecurrenceFrequency(event.target.value)
   }

   const handlePeriod = date => {
      if (!date) return
      const newPeriodEnd = new Date(setDateToEndOfWorkDay(date, workDay))
      setDirty(true)
      setPeriodEnd(newPeriodEnd)
   }

   const handlePublicComment = event => {
      setDirty(true)
      setPublicComment(event.target.value)
   }

   const handlePrivateComment = event => {
      setDirty(true)
      setPrivateComment(event.target.value)
   }

   if (props.users == null) {
      return (
         <React.Fragment>
            <Typography variant="body2">{t('fetchingData')}</Typography>
         </React.Fragment>
      )
   }
   if (recurrenceState == null) {
      return (
         <React.Fragment>
            <Typography variant="body2">{t('fetchingData')}</Typography>
         </React.Fragment>
      )
   }

   return (
      <React.Fragment>
         <Paper className="contentPaper" style={{ maxWidth: 800, boxShadow: 'none' }}>
            <div className="conentWrapper" style={{ padding: '10px 10px 20px 10px' }}>
               <div className={'formWrapper'}>
                  <div className={'listWrapper'}>
                     <Typography variant="h5" style={{ padding: '10px 0', fontSize: '22px' }}>
                        {t('absenceReport')}
                     </Typography>

                     {errorAlert != null ? (
                        <div style={{ marginTop: 20 }}>
                           <Alert onClose={() => setErrorAlert(null)} severity="error">
                              {errorAlert}
                           </Alert>
                        </div>
                     ) : (
                        <></>
                     )}

                     <Grid
                        key={'date_grid'}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                        style={{ paddingBottom: 30 }}
                     >
                        <Grid item xs={6} className={'p-2'}>
                           <Typography variant="body2">{t('absenceTypePlaceholder')}</Typography>
                           <Select
                              className={classes.select}
                              variant={'outlined'}
                              value={formState.absenceInterval.Type}
                              placeholder={t('select')}
                              renderValue={() => {
                                 if (!formState.absenceInterval.Type) return t('select')
                                 return options.find(
                                    option => option.value === formState.absenceInterval.Type
                                 ).label
                              }}
                              displayEmpty
                              onChange={event => absenceTypeChanged(event.target.value.value)}
                           >
                              {options.map((option, i) => (
                                 <MenuItem key={i} value={option}>
                                    {option.label}
                                 </MenuItem>
                              ))}
                           </Select>
                        </Grid>
                        <Grid item xs={6} className={'p-2'}>
                           <div style={{ padding: 5 }}>
                              <div style={{ float: 'right', fontSize: '10px' }}>
                                 {getCalculatedTime()}
                              </div>
                           </div>
                        </Grid>
                     </Grid>

                     <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        style={{ paddingBottom: 30 }}
                     >
                        <Grid item xs={6} className={'p-2'}>
                           <Typography variant="body2">{t('From')}</Typography>
                           <div className={classes.datepickerField}>
                              <DatePicker
                                 className={'datepicker-field mt-1'}
                                 dateFormat={'dd.MM.yyyy'}
                                 locale={t('datepickerLocale')}
                                 showTimeSelect={false}
                                 timeIntervals={isFlexOrDistance ? '30' : '60'}
                                 highlightDates={highlightDaysInRed(holidays)}
                                 showWeekNumbers
                                 minDate={getTimeConstraints(workDay).constrainPast}
                                 maxDate={parseJSON(formState.absenceInterval.OptionalEndDate)}
                                 minTime={getTimeConstraints(workDay).dayStart}
                                 maxTime={getTimeConstraints(workDay).dayEnd}
                                 selected={
                                    formState.absenceInterval.Date != null
                                       ? parseJSON(formState.absenceInterval.Date)
                                       : null
                                 }
                                 onChange={date => dateChanged(date)}
                              />
                           </div>
                        </Grid>
                        <Grid item xs={6} className={'p-2'}>
                           <Typography variant="body2">{t('To')}</Typography>
                           <div className={classes.datepickerField}>
                              <DatePicker
                                 disabled={!formState.absenceInterval.Date}
                                 className={'datepicker-field mt-1'}
                                 dateFormat={'dd.MM.yyyy'}
                                 locale={t('datepickerLocale')}
                                 showTimeSelect={false}
                                 timeIntervals={isFlexOrDistance ? '30' : '60'}
                                 highlightDates={highlightDaysInRed(holidays)}
                                 showWeekNumbers
                                 minDate={parseJSON(formState.absenceInterval.Date)}
                                 maxDate={getTimeConstraints(workDay).constrainFuture}
                                 minTime={getTimeConstraints(workDay).dayStart}
                                 maxTime={getTimeConstraints(workDay).dayEnd}
                                 openToDate={formState.absenceInterval.Date}
                                 selected={
                                    formState.absenceInterval.OptionalEndDate != null
                                       ? parseJSON(formState.absenceInterval.OptionalEndDate)
                                       : null
                                 }
                                 onChange={date => optionalDateChanged(date)}
                              />
                           </div>
                        </Grid>
                     </Grid>

                     <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                        style={{ paddingBottom: 30 }}
                     >
                        <Grid item xs={12} style={{ paddingBottom: 30 }} className={'p-2'}>
                           <Typography variant="body2">{t('publicComment')}</Typography>
                           <FormControl style={{ width: '100%', paddingTop: 5 }} autoComplete="off">
                              <TextField
                                 placeholder={t('publicMsgPlaceholder2')}
                                 inputProps={{ 'aria-label': 'description' }}
                                 value={publicComment}
                                 variant="outlined"
                                 onChange={handlePublicComment}
                                 className={classes.textField}
                              />
                           </FormControl>
                        </Grid>

                        <Grid item xs={12} style={{ paddingBottom: 30 }} className={'p-2'}>
                           <Typography variant="body2">{t('privateComment')}</Typography>
                           <FormControl style={{ width: '100%', paddingTop: 5 }} autoComplete="off">
                              <TextField
                                 placeholder={t('privateMsgPlaceholder')}
                                 inputProps={{ 'aria-label': 'description' }}
                                 value={privateComment}
                                 variant="outlined"
                                 onChange={handlePrivateComment}
                                 className={classes.textField}
                              />
                           </FormControl>
                        </Grid>
                     </Grid>

                     <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                     >
                        <Grid item xs={6} className={'p-2'}>
                           <FormControl>
                              <FormLabel style={{ paddingTop: 10 }}>
                                 {t('schedulePeriods')}
                              </FormLabel>
                              <RadioGroup
                                 value={recurrenceFrequency}
                                 onChange={handleRecurrenceFrequency}
                                 row
                              >
                                 <FormControlLabel
                                    value="once"
                                    control={<Radio />}
                                    label={t('labelOnce')}
                                 />
                                 <div>
                                    <FormControlLabel
                                       value="weekly"
                                       control={<Radio />}
                                       label={t('labelWeekly')}
                                    />
                                    <FormControlLabel
                                       value="monthly"
                                       control={<Radio />}
                                       label={t('labelMonthly')}
                                    />
                                 </div>
                              </RadioGroup>
                           </FormControl>
                        </Grid>
                        <Grid item xs={6} style={{ marginTop: 10 }}>
                           <Typography variant="body2">{t('Until')}</Typography>
                           <div className={classes.datepickerField}>
                              <DatePicker
                                 placeholderText={t('endDate')}
                                 disabled={recurrenceFrequency === 'once'}
                                 className={'datepicker-field mt-1'}
                                 dateFormat="yyyy-MM-dd"
                                 locale={t('datepickerLocale')}
                                 highlightDates={highlightDaysInRed(holidays)}
                                 popperPlacement="right"
                                 showWeekNumbers
                                 selected={periodEnd}
                                 minDate={
                                    formState.absenceInterval.Date
                                       ? parseJSON(formState.absenceInterval.Date)
                                       : getTimeConstraints(workDay).now
                                 }
                                 maxDate={getTimeConstraints(workDay).constrainFuture}
                                 onChange={date => handlePeriod(date)}
                              />
                           </div>
                        </Grid>
                     </Grid>
                  </div>
                  <div className={'buttonContainer'} style={{ maxWidth: 400, paddingLeft: 10 }}>
                     <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                     >
                        <Grid item>
                           <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => createRecurrence()}
                           >
                              {t('sendReport')}
                           </Button>
                        </Grid>
                        <Grid item>
                           <Button
                              size="small"
                              variant="contained"
                              color="secondary"
                              onClick={() => clearForm()}
                           >
                              {t('Clear')}
                           </Button>
                        </Grid>
                     </Grid>
                  </div>
               </div>
            </div>
         </Paper>

         {recurrenceState.length > 0 ? (
            <Paper
               className="contentPaper"
               style={{ maxWidth: 800, marginTop: 20, boxShadow: 'none' }}
            >
               <div className="conentWrapper" style={{ margin: 10 }}>
                  <div style={{ padding: 10 }}>
                     <Typography
                        align="left"
                        variant="h5"
                        style={{ paddingTop: 10, fontSize: '22px' }}
                     >
                        {t('scheduledAbsence')}
                     </Typography>
                  </div>
                  <div style={{ padding: 10 }}>
                     <OngoingRecurrences
                        popupMessage={popupMessage}
                        userInfo={props.userInfo}
                        recurrenceState={recurrenceState}
                        users={props.users}
                        timeConstraints={getTimeConstraints(workDay)}
                        fetchRecurrences={fetchRecurrences}
                        workDay={props.workDay}
                     />
                  </div>
               </div>
            </Paper>
         ) : null}

         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>
         <Prompt when={dirty} message={t('leavePagePrompt')} />
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      notifs: state.notifs,
      users: state.users,
      inactiveAbsences: state.inactiveAbsences,
      workDay: state.workDay,
   }
}

export default withStyles(styles)(
   withTranslation()(connect(mapStateToProps, { fetchUsers })(ReportFlex))
)
