import * as winston from "winston";
import BrowserConsole from 'winston-transport-browserconsole';
 
const level = process.env.LOG_LEVEL || "debug";
winston.configure({
    transports: [
        new BrowserConsole(
            {
                format: winston.format.simple(),
                level,
            },
        ),
        // Uncomment to compare with default Console transport
        // new winston.transports.Console({
        //     format: winston.format.simple(),
        //     level,
        // }),
    ],
});

export default winston;