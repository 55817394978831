import { withTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { formatDate } from '../../../../../../util/helpers'
import LinearProgress from '@mui/material/LinearProgress'
import { Error as ErrorIcon } from '@mui/icons-material'
const StatisticBanner = ({ project, type, percentage, t }) => {
   const informationObj = { firstRow: '', secondRow: '', thirdRow: '' }
   switch (type) {
      case 'projectStart':
         informationObj.firstRow = t('projectStart')
         informationObj.secondRow = formatDate(project.StartDate)
         informationObj.thirdRow = (
            <Box sx={{ display: 'flex' }}>
               <Typography sx={{ fontSize: 12, fontWeight: 500, color: '#3D3D3D', mr: '4px' }}>
                  {t('end')}
               </Typography>
               <Typography sx={{ fontSize: 12, fontWeight: 500, color: '#3D3D3D' }}>
                  {project.EndDate ? formatDate(project.EndDate) : 'N/A'}
               </Typography>
            </Box>
         )

         break
      case 'projectType':
         informationObj.firstRow = t('projectType')
         informationObj.secondRow = project.Type
         informationObj.thirdRow = (
            <Box sx={{ display: 'flex' }}>
               <Typography sx={{ fontSize: 12, fontWeight: 500, color: '#3D3D3D', mr: '4px' }}>
                  {t('totalBillable')}
               </Typography>
               <Typography sx={{ fontSize: 12, fontWeight: 500, color: '#00B8B8' }}>
                  {project.BillableHoursLogged ? project.BillableHoursLogged : 'N/A'}
               </Typography>
            </Box>
         )
         break
      case 'hoursSpent':
         informationObj.firstRow = t('spentHours')
         informationObj.secondRow = project.TotalHoursLogged.toFixed(0)
         informationObj.thirdRow = (
            <Box sx={{ display: 'flex' }}>
               <Typography sx={{ fontSize: 12, fontWeight: 500, color: '#3D3D3D', mr: '4px' }}>
                  {t('plannedHoursForBanner')}
               </Typography>
               <Typography sx={{ fontSize: 12, fontWeight: 500, color: '#00B8B8' }}>
                  {project.PlannedHours ? project.PlannedHours : 'N/A'}
               </Typography>
            </Box>
         )
         break
      case 'projectProgress':
         if (project.EndDate) {
            informationObj.firstRow = t('progress')
            informationObj.secondRow = `${percentage}%`

            informationObj.thirdRow = (
               <Box sx={{ width: '100%' }}>
                  <LinearProgress
                     sx={{
                        height: 15,
                        borderRadius: 4,
                        backgroundColor: '#D9D9D9',
                        '& .MuiLinearProgress-bar': {
                           backgroundColor: '#00B8B8',
                        },
                     }}
                     value={percentage}
                     variant="determinate"
                  />
               </Box>
            )
         } else {
            informationObj.firstRow = (
               <Box sx={{ display: 'flex', alignItems: 'center', ml: '18px' }}>
                  <ErrorIcon sx={{ color: '#E58C9C', width: 14, height: 14 }} />
                  <Typography sx={{ fontSize: 12 }}>{t('projectProgress')}</Typography>
               </Box>
            )
            informationObj.secondRow = ''
            informationObj.thirdRow = (
               <Typography sx={{ color: '#A54657', fontSize: 10, width: 140, ml: '19px' }}>
                  {t('noEndDateInfo')}
               </Typography>
            )
         }
         break
      case 'projectMembersSize':
         informationObj.firstRow = t('teamSize')
         informationObj.secondRow = project.MemberDetails.length
         informationObj.thirdRow = (
            <Box sx={{ display: 'flex' }}>
               <Typography sx={{ fontSize: 12, fontWeight: 500, color: '#3D3D3D', mr: '4px' }}>
                  {t('inactiveEmployees')}
               </Typography>
               <Typography sx={{ fontSize: 12, fontWeight: 500, color: '#A54657' }}>
                  {project.pastMembers.length}
               </Typography>
            </Box>
         )
         break
      default:
         return (
            <Box>
               <Typography>Something went wrong!</Typography>
            </Box>
         )
   }
   return (
      <Box
         sx={{
            flexBasis: '22%',
            maxWidth: '310px',
            minHeight: '108px',
            padding: '20px 15px',
            borderRadius: '8px',
            border: '2px solid',
            borderColor: '#8ACCE6',
            backgroundColor: '#EDF6FA80',
         }}
      >
         <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', overflowWrap: 'anywhere' }}>
               {!project.EndDate ? (
                  informationObj.firstRow
               ) : (
                  <Typography sx={{ fontSize: 12, fontWeight: 500, color: '#3D3D3D' }}>
                     {informationObj.firstRow}
                  </Typography>
               )}
            </Box>

            <Box>
               <Typography sx={{ fontSize: 20, fontWeight: 700, color: '#00B8B8' }}>
                  {informationObj.secondRow}
               </Typography>
            </Box>
            {informationObj.thirdRow}
         </Box>
      </Box>
   )
}

export default withTranslation()(StatisticBanner)
