import React, { useEffect } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { fetchSalaryReport } from '../Api'
import Alert from '@mui/material/Alert'
import { withTranslation } from 'react-i18next'

const SalaryReportJSON = (...props) => {
   const userInfo = useSelector(state => state.userInfo)
   const loggedIn = useSelector(state => state.loggedIn)
   const [report, setReport] = React.useState(null)
   const [info, setInfo] = React.useState(null)
   let { id } = useParams()
   const { t } = props[0]

   useEffect(() => {
      async function loadData() {
         const result = await fetchSalaryReport(userInfo, id)
         if (result.data && result.data.success && result.data.reports) {
            setReport(result.data.reports[0])
         } else {
            setInfo(JSON.stringify(result))
         }
      }
      loadData()
   }, [userInfo, id])

   if (!loggedIn) {
      return <Redirect to="/login" />
   }
   if (!(userInfo.admin || userInfo.backofficeAdmin)) {
      return t('unauthorizedPage')
   }
   if (report == null && info != null) {
      return (
         <React.Fragment>
            <Alert severity={'error'}>Kunde inte hämta rapport för id {id}</Alert>
            {info}
         </React.Fragment>
      )
   } else if (report == null) {
      return ''
   }

   return <React.Fragment>{JSON.stringify(report)}</React.Fragment>
}

export default withTranslation()(SalaryReportJSON)
