import { Box, Typography } from '@mui/material'
import { withTranslation } from 'react-i18next'
import StatisticBanner from './UI/StatisticBanner'
const statisticTypes = [
   'projectStart',
   'projectType',
   'hoursSpent',
   'projectProgress',
   'projectMembersSize',
]
const ProjectOverviewStatistic = ({ project, calculateOverallDetails, t }) => {
   function calculateTimePercentage(startDate, endDate) {
      const start = new Date(startDate).getTime()
      const end = new Date(endDate).getTime()
      const totalTimeDifference = end - start
      const currentTime = Date.now() - start
      let percentage = (currentTime / totalTimeDifference) * 100
      if (percentage > 100) {
         percentage = 100
      }
      return Math.floor(percentage)
   }
   const percentage = calculateTimePercentage(project.StartDate, project.EndDate)

   return (
      <Box sx={{ p: '0 20px' }}>
         <Typography sx={{ color: '#454545', mb: '5px' }}>{t('overall')}</Typography>
         <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
            {statisticTypes.map((type, index) => (
               <StatisticBanner
                  key={index}
                  project={project}
                  type={type}
                  percentage={percentage}
                  calculateOverallDetails={calculateOverallDetails}
               />
            ))}
         </Box>
      </Box>
   )
}

export default withTranslation()(ProjectOverviewStatistic)
