import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { sortByLabel } from '../../../util/helpers'
import { Grid, IconButton, Tooltip } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import makeStyles from '@mui/styles/makeStyles'
import EditMemberDetailsModal from '../../../components/modals/editMemberDetailsModal/EditMemberDetailsModal'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
   Tune as TuneIcon,
   FindInPage as FindInPageIcon,
   WarningAmber as WarningAmberIcon,
} from '@mui/icons-material'

const useStyles = makeStyles(theme => ({
   membersPaper: {
      width: '100%',
      padding: '20px',
      overflow: 'hidden auto',
      borderRadius: '4px',
      boxShadow: theme.shadows[2],
   },
   member: {
      border: '1px solid #009be5',
      borderRadius: '4px',
      padding: '5px',
      paddingLeft: '10px',
      alignItems: 'center',
   },
   pastMember: {
      border: '1px solid gray',
      borderRadius: '4px',
      padding: '5px',
      paddingLeft: '10px',
      alignItems: 'center',
   },
   settingsIcon: {
      cursor: 'pointer',
      backgroundColor: '#009be5',
      color: '#fff',
      borderRadius: '25px',
      padding: '5px',
      '&:hover': {
         color: '#fff',
         backgroundColor: '#006db3',
      },
   },
   pastMemberSettingsIcon: {
      cursor: 'pointer',
      backgroundColor: 'gray',
      color: '#fff',
      borderRadius: '25px',
      padding: '5px',
      '&:hover': {
         color: '#fff',
         backgroundColor: 'gray',
      },
   },
}))

const ProjectManagementProjectMembers = ({
   selectedProject,
   updateSelectedProject,
   isProjectManager,
   getAndWrapUser,
   getAndWrapPastUser,
   activeUsers,
   inactiveUsers,
   t,
}) => {
   const [openMemberDetailsModal, setOpenMemberDetailsModal] = useState(false)
   const [memberIDToEditDetails, setMemberIDToEditDetails] = useState('')
   const [isPastMember, setIsPastMember] = useState(false)
   const classes = useStyles()

   const toggleMemberDetailsModal = (memberID, isPastMember) => {
      if (openMemberDetailsModal) setMemberIDToEditDetails('')
      else setMemberIDToEditDetails(memberID)
      setIsPastMember(isPastMember)
      setOpenMemberDetailsModal(prevState => !prevState)
   }
   const activeMembers = selectedProject['MemberDetails']?.filter(member => member.isActive)
   return (
      <>
         <Typography variant="body2">{`${t('projectManagementProjectMembersText')}${
            activeMembers ? ` (${activeMembers.length})` : ''
         }`}</Typography>
         <Paper className={`${classes.membersPaper}`} style={{ height: '591px', marginTop: '5px' }}>
            {selectedProject['MemberDetails'].some(member => member.isActive) && (
               <b>Active members</b>
            )}
            {selectedProject['MemberDetails']
               .filter(user => user.isActive)
               .map(user => getAndWrapUser(user._id, user))
               .sort(sortByLabel)
               .map((user, i) => (
                  <Grid
                     container
                     direction="row"
                     alignItems="flex-start"
                     className={classes.member + ' m-1'}
                     key={i}
                  >
                     <Grid item xs={10}>
                        <Typography>{user?.label}</Typography>
                     </Grid>
                     <Grid item xs={1}>
                        {isProjectManager(user) && (
                           <FontAwesomeIcon
                              size="2x"
                              icon={faStar}
                              title={t('ProjectManager')}
                              color={'#009be5'}
                           />
                        )}
                     </Grid>

                     <Grid item xs={1}>
                        <IconButton
                           className={classes.settingsIcon}
                           onClick={() => toggleMemberDetailsModal(user._id, false)}
                           size="large"
                        >
                           <TuneIcon fontSize={'small'} />
                        </IconButton>
                     </Grid>
                  </Grid>
               ))}
            {!selectedProject['MemberDetails'].every(member => member.isActive) && (
               <b>Inactive members</b>
            )}
            {selectedProject['MemberDetails']
               .filter(user => !user.isActive)
               .map(user => getAndWrapUser(user._id, user))
               .sort(sortByLabel)
               .map((user, i) => (
                  <Grid
                     container
                     direction="row"
                     alignItems="flex-start"
                     className={classes.pastMember + ' m-1'}
                     key={i}
                  >
                     <Grid item xs={10}>
                        <Typography sx={{ color: user.isDeleted ? 'red' : '' }}>
                           {user?.label}
                        </Typography>
                     </Grid>
                     <Grid item xs={1}>
                        {isProjectManager(user) && (
                           <FontAwesomeIcon
                              size="2x"
                              icon={faStar}
                              title={t('ProjectManager')}
                              color={'#009be5'}
                           />
                        )}
                     </Grid>

                     <Grid item xs={1}>
                        {user.isDeleted ? (
                           <Tooltip
                              componentsProps={{
                                 tooltip: {
                                    sx: {
                                       bgcolor: 'red',
                                       '& .MuiTooltip-arrow': {
                                          color: 'red',
                                       },
                                    },
                                 },
                              }}
                              arrow
                              title="Deleted user"
                           >
                              <WarningAmberIcon sx={{ color: 'red', fontSize: 28 }} />
                           </Tooltip>
                        ) : (
                           <IconButton
                              className={classes.pastMemberSettingsIcon}
                              onClick={() => toggleMemberDetailsModal(user._id, false)}
                              size="large"
                           >
                              <TuneIcon fontSize={'small'} />
                           </IconButton>
                        )}
                     </Grid>
                  </Grid>
               ))}
            {selectedProject['pastMembers'].length > 0 && <b>Past members</b>}
            {selectedProject['pastMembers']
               .map(user => getAndWrapPastUser(user._id))
               .sort(sortByLabel)
               .map((user, i) => (
                  <Grid
                     container
                     direction="row"
                     alignItems="flex-start"
                     className={classes.pastMember + ' m-1'}
                     key={i}
                  >
                     <Grid item xs={5}>
                        <Typography>{user.label}</Typography>
                     </Grid>
                     <Grid item xs={1}>
                        {isProjectManager(user) && (
                           <FontAwesomeIcon
                              size="2x"
                              icon={faStar}
                              title={t('ProjectManager')}
                              color={'#009be5'}
                           />
                        )}
                     </Grid>

                     <Grid item xs={5} style={{ textAlign: 'center' }}>
                        <b>Contract expired</b>
                     </Grid>
                     <Grid item xs={1}>
                        <IconButton
                           className={classes.pastMemberSettingsIcon}
                           onClick={() => toggleMemberDetailsModal(user._id, true)}
                           size="large"
                        >
                           <FindInPageIcon fontSize={'small'} />
                        </IconButton>
                     </Grid>
                  </Grid>
               ))}
         </Paper>
         {selectedProject?.MemberIDs?.length > 0 && memberIDToEditDetails !== '' && (
            <EditMemberDetailsModal
               openModal={openMemberDetailsModal}
               toggleModal={toggleMemberDetailsModal}
               updateSelectedProject={updateSelectedProject}
               selectedProject={selectedProject}
               memberIDToEditDetails={memberIDToEditDetails}
               activeUsers={activeUsers}
               inactiveUsers={inactiveUsers}
               isPastMember={isPastMember}
            />
         )}
      </>
   )
}
const mapStateToProps = state => {
   return {
      activeUsers: state.activeUsers,
   }
}

export default withTranslation()(connect(mapStateToProps)(ProjectManagementProjectMembers))
