import { faAdjust, faAsterisk, faBan, faBatteryEmpty, faBatteryFull, faBatteryHalf, faCheckCircle, faCircle, faCloudRain, faCloudSun, faDotCircle, faHandPaper, faHandPeace, faHandPointDown, faInfoCircle, faMinusCircle, faMoon, faSun, faThumbsUp, faUser, faUserAstronaut, faUserCircle, faUserSlash, faUserTimes, faBullseye, faCloudMeatball, faHandHoldingHeart, faDumbbell, faRunning, faChargingStation, faStar, faHandshake, faCoffee, faBeer, faMugHot, faPrescriptionBottle, faGlassCheers, faSwimmer, faTrophy } from '@fortawesome/free-solid-svg-icons';

const colourSchemes = {
    trafficLight: {
        label: 'Traffic Light',
        scheme: [
            '#4cbb17', // Green
            '#ff7800', // Yellow
            '#e60000', // Red
            '#e60000', // Red
            '#BF00FF',
            'deepskyblue',
        ]
    },

    dotsVibrant: {
        label: 'Vibrant Dots',
        scheme: [
            'deepskyblue',
            'gold',
            'deeppink',
            'black',
            '#BF00FF',
            'chartreuse',
        ]
    },

    dotsPale: {
        label: 'Pale Dots',
        scheme: [
            'lightskyblue',
            'lemonchiffon',
            'hotpink',
            'darkgrey',
            '#BF00FF',
            'seagreen',
        ]
    },

    novartoNordic: {
        label: 'Novarto Nordic',
        scheme: [
            '#0083bb', // Novarto light
            '#6d7c8c', // Novarto font
            '#0d2540', // Novarto dark
            '#FECC00', // Swedish flag yellow
            '#BF00FF',
            'deeppink',
        ]
    },

    weatherReport: {
        label: 'Weather Report',
        scheme: [
            'gold',
            'darkorange',
            'deepskyblue',
            'paleturquoise',
            '#BF00FF',
            'deeppink',
        ]
    },

    fuel: {
        label: 'Chosen Wisely',
        scheme: [
            'black',
            'chocolate',
            'lightskyblue',
            'gold',
            '#BF00FF',
            'gold',
        ]
    },
};

const iconSchemes = {
    dotsInvariant: {
        label: 'Dots Invariant',
        scheme: [
            faCircle,
            faCircle,
            faCircle,
            faCircle,
            faCircle,
            faCircle,
        ]
    },

    dotsDefault: {
        label: 'Dots Default',
        scheme: [
            faCircle,
            faCircle,
            faCircle,
            faBullseye,
            faCircle,
            faStar,
        ]
    },

    dotsVariant: {
        label: 'Dots Variant',
        scheme: [
            faCircle,
            faCircle,
            faDotCircle,
            faDotCircle,
            faCircle,
            faStar,
        ]
    },

    verbose: {
        label: 'Verbose',
        scheme: [
            faCheckCircle,
            faInfoCircle,
            faMinusCircle,
            faDotCircle,
            faBullseye,
            faDumbbell,
        ]
    },

    verboseAlt: {
        label: 'Verbose Alt',
        scheme: [
            faUserCircle,
            faAdjust,
            faBan,
            faAsterisk,
            faBullseye,
            faDumbbell,
        ]
    },

    portraits: {
        label: 'Portraits',
        scheme: [
            faUser,
            faUserAstronaut,
            faUserSlash,
            faUserTimes,
            faCircle,
            faRunning,
        ]
    },

    batteryCharge: {
        label: 'Battery Charge',
        scheme: [
            faBatteryFull,
            faBatteryHalf,
            faBatteryEmpty,
            faBatteryEmpty,
            faBatteryEmpty,
            faChargingStation,
        ]
    },

    weatherReport: {
        label: 'Weather Report',
        scheme: [
            faSun,
            faCloudSun,
            faCloudRain,
            faMoon,
            faCloudMeatball,
            faStar,
        ]
    },

    heavyHands: {
        label: 'Heavy Hands',
        scheme: [
            faThumbsUp,
            faHandPointDown,
            faHandPaper,
            faHandPeace,
            faHandshake,
            faHandHoldingHeart,
        ]
    },

    fuel: {
        label: 'Chosen Wisely',
        scheme: [
            faMugHot,
            faCoffee,
            faPrescriptionBottle,
            faBeer,
            faGlassCheers,
            faTrophy,
        ]
    },
};

export { colourSchemes, iconSchemes };

