import React from 'react'
import Grid from '@mui/material/Grid'
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
   skill: {
      border: '1px solid #e6e6e6',
      borderRadius: '4px',
      padding: '10px',
   },
   icon: {
      float: 'right',
      cursor: 'pointer',
      color: '#b3b3b3',
      '&:hover': {
         color: '#4d4d4d',
      },
   },
}))

const Skill = ({ skills, groupID, handleEditSkill, handleDeleteSkill, t }) => {
   const classes = useStyles()
   return (
      <>
         {skills.length ? (
            skills.map(skill => {
               return (
                  <Grid container spacing={0} className={classes.skill + ' mt-1'} key={skill._id}>
                     <Grid item xs>
                        {skill.Name}
                     </Grid>
                     <Grid item xs>
                        <DeleteIcon
                           onClick={event => {
                              event.stopPropagation()
                              handleDeleteSkill(skill, groupID)
                           }}
                           className={classes.icon}
                        />
                        <EditIcon
                           onClick={event => {
                              event.stopPropagation()
                              handleEditSkill(skill)
                           }}
                           className={classes.icon}
                        />
                     </Grid>
                  </Grid>
               )
            })
         ) : (
            <h6>{t('noSkillsFound')}</h6>
         )}
      </>
   )
}

export default withTranslation()(Skill)
