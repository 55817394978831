import { createTheme, adaptV4Theme } from '@mui/material/styles'

export const drawerWidth = 256.4

let theme = createTheme(
   adaptV4Theme({
      palette: {
         primary: {
            light: '#63ccff',
            main: '#009be5',
            dark: '#006db3',
         },
         secondary: {
            main: '#f50057',
         },
      },
      typography: {
         h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
         },
      },
      shape: {
         borderRadius: 8,
      },
      props: {
         MuiTab: {
            disableRipple: true,
         },
      },
      mixins: {
         toolbar: {
            minHeight: 48,
         },
      },
   })
)

theme = {
   ...theme,
   overrides: {
      MuiDrawer: {
         paper: {
            backgroundColor: '#18202c',
         },
      },
      MuiButton: {
         text: {
            textTransform: 'none',
         },
         contained: {
            boxShadow: 'none',
            '&:active': {
               boxShadow: 'none',
            },
         },
      },
      MuiTabs: {
         root: {
            marginLeft: theme.spacing(1),
         },
         indicator: {
            height: 3,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            backgroundColor: theme.palette.common.white,
         },
      },
      MuiTab: {
         root: {
            textTransform: 'none',
            margin: '0 16px',
            minWidth: 0,
            padding: 0,
            [theme.breakpoints.up('md')]: {
               padding: 0,
               minWidth: 0,
            },
         },
      },
      MuiIconButton: {
         root: {
            padding: theme.spacing(1),
         },
      },
      MuiTooltip: {
         tooltip: {
            borderRadius: 4,
         },
      },
      MuiDivider: {
         root: {
            backgroundColor: '#404854',
         },
      },
      MuiListItemText: {
         primary: {
            fontWeight: theme.typography.fontWeightMedium,
         },
      },
      MuiListItemIcon: {
         root: {
            color: 'inherit',
            marginRight: 0,
            '& svg': {
               fontSize: 20,
            },
         },
      },
      MuiAvatar: {
         root: {
            width: 32,
            height: 32,
         },
      },
   },
}

const styles = {
   root: {
      display: 'flex',
      minHeight: '100vh',
   },
   drawer: {
      [theme.breakpoints.up('sm')]: {
         width: drawerWidth,
         flexShrink: 0,
      },
   },
   app: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
   },
   main: {
      flex: 1,
      padding: theme.spacing(6, 4),
      background: '#eaeff1',
      overflowX: 'hidden',
   },
   footer: {
      padding: theme.spacing(2),
      background: '#eaeff1',
   },

   paper: {
      maxWidth: 1200,
      margin: 'auto',
   },

   searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
   },
   searchInput: {
      fontSize: theme.typography.fontSize,
   },
   block: {
      display: 'block',
   },
   addUser: {
      marginRight: theme.spacing(1),
   },
   contentWrapper: {
      margin: '40px 16px',
   },
   navigatorAdminTag: {
      fontSize: 10,
      color: '#000',
   },
   hidden: {
      visibility: 'hidden',
   },
   today: {
      backgroundColor: '#FFeedd',
   },
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
}

export default () => {
   return { theme, styles, drawerWidth }
}
