import React, { useCallback, useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Button, Grid, Modal, TextField, Typography } from '@mui/material'
import { withTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import { sortByLabel } from '../../util/helpers'
import { wrapUser } from '../../util/wrappers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
   faStar,
   faArrowAltCircleRight,
   faArrowAltCircleLeft,
} from '@fortawesome/free-solid-svg-icons'
import { Autocomplete } from '@mui/material';

function getModalStyle() {
   return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
   }
}

const useStyles = makeStyles(theme => ({
   paper: {
      position: 'relative',
      width: '80vw',
      height: '80vh',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
   },
   body: {
      marginBottom: '20px',
   },
   buttonsPosition: {
      position: 'absolute',
      bottom: 5,
      right: 5,
      float: 'right',
      padding: 5,
   },
   memberButton: {
      width: '100%',
      borderRadius: '4px',
      padding: '5px',
      paddingLeft: '10px',
      alignItems: 'center',
   },
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
   mainAutocomplete: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiInputBase-root': {
         height: '55px',
         padding: 0,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
         padding: 0,
         paddingLeft: '10px',
      },
   },
}))

const ManageTeamMembersModal = ({
   openModal,
   toggleModal,
   activeUsers,
   selectedTeam,
   setSelectedTeam,
   getAndWrapUser,
   isTeamLead,
   filterNonMember,
   setFilterNonMember,
   setHaveUnsavedChanges,
   teams,
   t,
}) => {
   const classes = useStyles()
   const [modalStyle] = React.useState(getModalStyle)

   const [filterMember, setFilterMember] = useState('')
   const [temporaryTeamMembers, setTemporaryTeamMembers] = useState([])
   const [name, setName] = useState(selectedTeam.label)
   const [error, setError] = useState(false)
   const currentTeamLead = wrapUser(activeUsers.find(user => user._id === selectedTeam.TeamLead))
   const [teamLeadId, setTeamLeadId] = useState(null)
   const [value, setValue] = useState(currentTeamLead ?? '')

   const allMemberIds = teams.map(team => team.MemberIDs).flat()
   const allTeamLeadIds = teams.map(team => team.TeamLead)
   const teamMembersExceptTeamLeads = allMemberIds.filter(
      memberId => !allTeamLeadIds.includes(memberId)
   )

   useEffect(() => {
      setTemporaryTeamMembers([...selectedTeam.members])
      setName(selectedTeam.label)
      setTeamLeadId(currentTeamLead._id !== undefined ? currentTeamLead._id : null)
      setValue(currentTeamLead)
   }, [selectedTeam])

   const characterFilter = useCallback((person, filter) => {
      return `${person?.label}|${person?.nick}`.toLowerCase().includes(filter)
   }, [])

   const addUser = userID => {
      setTemporaryTeamMembers(prevState => [...prevState, userID])
   }

   const removeUser = newUserID => {
      setTemporaryTeamMembers(prevState => prevState.filter(userId => userId !== newUserID))
   }

   const saveHandler = async () => {
      innerToggleModal()
      setSelectedTeam({
         ...selectedTeam,
         label: name,
         members:
            teamLeadId && !temporaryTeamMembers.includes(teamLeadId)
               ? [...temporaryTeamMembers, ...[teamLeadId]]
               : temporaryTeamMembers,
         TeamLead: teamLeadId,
      })
      setHaveUnsavedChanges(true)
   }

   const innerToggleModal = () => {
      setFilterMember('')
      setFilterNonMember('')
      setTemporaryTeamMembers([...selectedTeam.members])
      setName(selectedTeam.label)
      setValue(currentTeamLead)
      setError(false)
      toggleModal()
   }

   return (
      <Modal
         open={openModal}
         onClose={innerToggleModal}
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
         style={{ overflow: 'scroll' }}
      >
         <Paper className={classes.paper} style={modalStyle}>
            <h5 id="simple-modal-title">{`${t('manageTeam')} (${selectedTeam.label})`}</h5>
            <Grid container justifyContent="flex-start" alignItems="center">
               <Grid item xs={6} className={'p-3'}>
                  <Typography variant="body2">{t('teamName')}</Typography>
                  <TextField
                     className={classes.textField}
                     placeholder={error ? t('requiredErrorText') : t('nameLabel')}
                     variant={'outlined'}
                     value={name}
                     error={error}
                     onChange={event => {
                        setName(event.target.value)
                        if (event.target.value !== '') {
                           setError(false)
                        } else {
                           setError(true)
                        }
                     }}
                  />
               </Grid>
               <Grid item xs={6} className={'p-3'}>
                  <Typography variant="body2">{t('TeamLead')}</Typography>
                  <Autocomplete
                     value={value}
                     getOptionLabel={option => option.label}
                     isOptionEqualToValue={(option, value) => option.label === value.label}
                     onChange={(event, newValue) => {
                        setTeamLeadId(newValue?._id)
                        setValue(newValue)
                     }}
                     disablePortal
                     id="combo-box-demo"
                     options={activeUsers.map(user => wrapUser(user))}
                     style={{ marginTop: '0.25rem' }}
                     renderInput={params => (
                        <TextField {...params} variant={'outlined'} style={{ height: '42px' }} />
                     )}
                     className={classes.mainAutocomplete}
                  />
               </Grid>
               <Grid item xs={6} className={'p-3'}>
                  <Paper
                     style={{
                        height: '50vh',
                        maxHeight: '50vh',
                        padding: '0 1vw 5px 1vw',
                     }}
                  >
                     <div className={'py-1'}>
                        <Typography>{`${t('teamMembers')} (${
                           activeUsers.filter(user => temporaryTeamMembers.includes(user._id))
                              .length
                        })`}</Typography>
                        <TextField
                           className={classes.textField}
                           placeholder={t('manageMembersModalSearchForMembers')}
                           onChange={event => setFilterMember(event.target.value.toLowerCase())}
                           variant={'outlined'}
                        />
                     </div>
                     <div
                        style={{
                           maxHeight: '80%',
                           height: '80%',
                           overflow: 'hidden auto',
                        }}
                        className={'py-1'}
                     >
                        {temporaryTeamMembers
                           .map(user => getAndWrapUser(user))
                           .filter(user => user && characterFilter(user, filterMember))
                           .sort(sortByLabel)
                           .map((user, i) => (
                              <Button
                                 variant="outlined"
                                 color="primary"
                                 onClick={() => (isTeamLead(user) ? null : removeUser(user._id))}
                                 className={classes.memberButton + ' my-1'}
                                 key={i}
                              >
                                 <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                 >
                                    <Grid item xs={10}>
                                       <Typography style={{ float: 'left' }}>
                                          {user.label}
                                       </Typography>
                                    </Grid>
                                 </Grid>
                                 <Grid item xs={1}>
                                    <FontAwesomeIcon
                                       size="lg"
                                       title={isTeamLead(user) ? t('TeamLead') : ''}
                                       icon={isTeamLead(user) ? faStar : faArrowAltCircleRight}
                                    />
                                 </Grid>
                              </Button>
                           ))}
                     </div>
                  </Paper>
               </Grid>
               <Grid item xs={6} className={'p-3'}>
                  <Paper>
                     <div
                        style={{
                           height: '50vh',
                           maxHeight: '50vh',
                           padding: '0 1vw 5px 1vw',
                        }}
                     >
                        <div className={'py-1'}>
                           <Typography>{t('nonParticipants')}</Typography>
                           <TextField
                              className={classes.textField}
                              placeholder={t('manageMembersModalSearchForNonMembers')}
                              onChange={event =>
                                 setFilterNonMember(event.target.value.toLowerCase())
                              }
                              variant={'outlined'}
                           />
                        </div>
                        <div
                           style={{
                              maxHeight: '80%',
                              height: '80%',
                              overflow: 'hidden auto',
                           }}
                           className={'py-1'}
                        >
                           {activeUsers
                              .filter(
                                 user =>
                                    !temporaryTeamMembers.includes(user._id) &&
                                    !teamMembersExceptTeamLeads.includes(user._id)
                              )
                              .map(user => wrapUser(user))
                              .filter(user => characterFilter(user, filterNonMember))
                              .sort(sortByLabel)
                              .map((user, i) => (
                                 <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => addUser(user._id)}
                                    className={classes.memberButton + ' my-1'}
                                    key={i}
                                 >
                                    <Grid
                                       container
                                       justifyContent="flex-start"
                                       alignItems="flex-start"
                                    >
                                       <Grid item xs={1}>
                                          <FontAwesomeIcon
                                             key={`icon-${i}`}
                                             size="lg"
                                             icon={faArrowAltCircleLeft}
                                          />
                                       </Grid>
                                       <Grid item xs={11}>
                                          <Typography style={{ float: 'left' }}>
                                             {user.label}
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 </Button>
                              ))}
                        </div>
                     </div>
                  </Paper>
               </Grid>
            </Grid>

            <div className={classes.buttonsPosition}>
               <Button size="small" variant="contained" color="primary" onClick={saveHandler}>
                  {t('saveButtonTitle')}
               </Button>
               <Button
                  color={'secondary'}
                  size="small"
                  variant="contained"
                  onClick={innerToggleModal}
                  className={'ml-2'}
               >
                  {t('cancelButtonTitle')}
               </Button>
            </div>
         </Paper>
      </Modal>
   );
}

export default withTranslation()(ManageTeamMembersModal)
