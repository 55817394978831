import React, { useCallback, useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Button, Grid, Input, Modal, TextField, Typography } from '@mui/material'
import { withTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import { sortByLabel } from '../../util/helpers'
import { wrapUser } from '../../util/wrappers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
   faStar,
   faArrowAltCircleRight,
   faArrowAltCircleLeft,
} from '@fortawesome/free-solid-svg-icons'

function getModalStyle() {
   return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
   }
}

const useStyles = makeStyles(theme => ({
   paper: {
      position: 'relative',
      width: '80vw',
      height: '80vh',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
   },
   body: {
      marginBottom: '20px',
   },
   buttonsPosition: {
      position: 'absolute',
      bottom: 5,
      right: 5,
      float: 'right',
      padding: 5,
   },
   memberButton: {
      width: '100%',
      borderRadius: '4px',
      padding: '5px',
      paddingLeft: '10px',
      alignItems: 'center',
   },
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
}))

const ManageProjectMembersModal = ({
   openModal,
   toggleModal,
   activeUsers,
   selectedProject,
   getAndWrapUser,
   isProjectManager,
   updateSelectedProject,
   t,
}) => {
   const classes = useStyles()
   const [modalStyle] = React.useState(getModalStyle)

   const [filterMember, setFilterMember] = useState('')
   const [filterNonMember, setFilterNonMember] = useState('')
   const [temporaryProjectMembers, setTemporaryProjectMembers] = useState([])
   const [temporaryProjectMemberDetails, setTemporaryProjectMemberDetails] = useState([])

   useEffect(() => {
      setTemporaryProjectMembers([...selectedProject.MemberIDs])
      setTemporaryProjectMemberDetails([...selectedProject.MemberDetails])
   }, [selectedProject])

   const characterFilter = useCallback((person, filter) => {
      return `${person?.label}|${person?.nick}`.toLowerCase().includes(filter)
   }, [])

   const addUser = userID => {
      setTemporaryProjectMembers(prevState => [...prevState, userID])
      const newMember = activeUsers.find(user => user._id === userID)
      setTemporaryProjectMemberDetails(prevState => [
         ...prevState,
         {
            _id: newMember._id,
            Name: `${newMember.FirstName}${
               newMember.MiddleName !== '' ? ` ${newMember.MiddleName} ` : ' '
            }${newMember.LastName}`,
            Assignments: [],
         },
      ])
   }

   const removeUser = newUserID => {
      setTemporaryProjectMembers(prevState => prevState.filter(userId => userId !== newUserID))
      setTemporaryProjectMemberDetails(prevState =>
         prevState.filter(member => member._id !== newUserID)
      )
   }

   const saveHandler = () => {
      innerToggleModal()
      updateSelectedProject('MemberIDs', temporaryProjectMembers)
      updateSelectedProject('MemberDetails', temporaryProjectMemberDetails)
   }

   const innerToggleModal = () => {
      setFilterMember('')
      setFilterNonMember('')
      setTemporaryProjectMembers([...selectedProject.MemberIDs])
      setTemporaryProjectMemberDetails([...selectedProject.MemberDetails])
      toggleModal()
   }

   return (
      <Modal
         open={openModal}
         onClose={innerToggleModal}
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
         style={{ overflow: 'scroll' }}
      >
         <Paper className={classes.paper} style={modalStyle}>
            <h5 id="simple-modal-title">{`${t('manageMembersModalTitle')} (${
               selectedProject.Name
            })`}</h5>
            <Grid container justifyContent="flex-start" alignItems="center">
               <Grid item xs={6} className={'p-3'}>
                  <Paper
                     style={{
                        height: '60vh',
                        maxHeight: '60vh',
                        padding: '0 1vw 5px 1vw',
                     }}
                  >
                     <div className={'py-1'}>
                        <Typography>{`${t('manageMembersModalProjectMembersText')} (${
                           activeUsers.filter(user => temporaryProjectMembers.includes(user._id))
                              .length
                        })`}</Typography>
                        <TextField
                           className={classes.textField}
                           placeholder={t('manageMembersModalSearchForMembers')}
                           onChange={event => setFilterMember(event.target.value.toLowerCase())}
                           variant={'outlined'}
                        />
                     </div>
                     <div
                        style={{
                           maxHeight: '80%',
                           height: '80%',
                           overflow: 'hidden auto',
                        }}
                        className={'py-1'}
                     >
                        {temporaryProjectMembers
                           .map(user => getAndWrapUser(user))
                           .filter(user => user && characterFilter(user, filterMember))
                           .sort(sortByLabel)
                           .map((user, i) => (
                              <Button
                                 variant="outlined"
                                 color="primary"
                                 onClick={() =>
                                    isProjectManager(user) ? null : removeUser(user._id)
                                 }
                                 className={classes.memberButton + ' my-1'}
                                 key={i}
                              >
                                 <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                 >
                                    <Grid item xs={10}>
                                       <Typography style={{ float: 'left' }}>
                                          {user.label}
                                       </Typography>
                                    </Grid>
                                 </Grid>
                                 <Grid item xs={1}>
                                    <FontAwesomeIcon
                                       size="lg"
                                       title={isProjectManager(user) ? t('ProjectManager') : ''}
                                       icon={
                                          isProjectManager(user) ? faStar : faArrowAltCircleRight
                                       }
                                    />
                                 </Grid>
                              </Button>
                           ))}
                     </div>
                  </Paper>
               </Grid>
               <Grid item xs={6} className={'p-3'}>
                  <Paper>
                     <div
                        style={{
                           height: '60vh',
                           maxHeight: '60vh',
                           padding: '0 1vw 5px 1vw',
                        }}
                     >
                        <div className={'py-1'}>
                           <Typography>{t('nonParticipants')}</Typography>
                           <TextField
                              className={classes.textField}
                              placeholder={t('manageMembersModalSearchForNonMembers')}
                              onChange={event =>
                                 setFilterNonMember(event.target.value.toLowerCase())
                              }
                              variant={'outlined'}
                           />
                        </div>
                        <div
                           style={{
                              maxHeight: '80%',
                              height: '80%',
                              overflow: 'hidden auto',
                           }}
                           className={'py-1'}
                        >
                           {activeUsers
                              .filter(user => !temporaryProjectMembers.includes(user._id))
                              .map(user => wrapUser(user))
                              .filter(user => characterFilter(user, filterNonMember))
                              .sort(sortByLabel)
                              .map((user, i) => (
                                 <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => addUser(user._id)}
                                    className={classes.memberButton + ' my-1'}
                                    key={i}
                                 >
                                    <Grid
                                       container
                                       justifyContent="flex-start"
                                       alignItems="flex-start"
                                    >
                                       <Grid item xs={1}>
                                          <FontAwesomeIcon
                                             key={`icon-${i}`}
                                             size="lg"
                                             icon={faArrowAltCircleLeft}
                                          />
                                       </Grid>
                                       <Grid item xs={11}>
                                          <Typography style={{ float: 'left' }}>
                                             {user.label}
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 </Button>
                              ))}
                        </div>
                     </div>
                  </Paper>
               </Grid>
            </Grid>

            <div className={classes.buttonsPosition}>
               <Button size="small" variant="contained" color="primary" onClick={saveHandler}>
                  {t('saveButtonTitle')}
               </Button>
               <Button
                  color={'secondary'}
                  size="small"
                  variant="contained"
                  onClick={innerToggleModal}
                  className={'ml-2'}
               >
                  {t('cancelButtonTitle')}
               </Button>
            </div>
         </Paper>
      </Modal>
   )
}

export default withTranslation()(ManageProjectMembersModal)
