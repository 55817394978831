import React, { useState } from 'react'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { authenticate, setTimeZone } from '../redux/actions/actions'
import log from '../util/log'
import { withTranslation } from 'react-i18next'
import { updateBackendWithToken, fetchCustomerTenantId } from '../Api.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import {returnCurrentUserPermissions, useEffectOnlyOnce} from '../util/helpers'
import { BACKEND_URL, FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN } from '../util/dependencies'

const useStyles = makeStyles(theme => ({
   paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
   },
   submit: {
      margin: theme.spacing(3, 0, 2),
   },
}))

function Copyright() {
   return (
      <Typography variant="body2" style={{ color: '#6d7c8c' }} align="center">
         {'Copyright © '}
         <Link color="inherit" href="">
            Novarto Nordic AB
         </Link>{' '}
         {new Date().getFullYear()}
         {'. '}
         <Link color="inherit" href="/privacy_and_TC">
            {'Privacy and T&C'}
         </Link>
      </Typography>
   )
}

function HillsLogin(props) {
   const [errorAlert, setErrorAlert] = useState(false)
   const [errorMsg, setErrorMsg] = useState('')
   const [resetEmail, setResetEmail] = useState('')
   const [alertSeverity, setAlertSeverity] = useState('error')
   const [showReset, setShowReset] = useState(false)
   const [password, setPassword] = useState('')
   const [email, setEmail] = useState('')

   const classes = useStyles()

   const { t } = props

   useEffectOnlyOnce(() => {
      document.body.style = 'background: #fff;'

      const initFirebaseTenantId = async loginPath => {
         // Get tenantId from customer since this will vary based on
         // staging and prod env.
         let tenantId = ''
         log.info('Fetching firebase tenantId...')
         const jsonResponse = await fetchCustomerTenantId('/hills/login')
         if (!jsonResponse.success) {
            console.info('Failed to get tenantId: ' + jsonResponse.message)
         } else {
            tenantId = jsonResponse.tenantId
         }
         log.info('Init firebase tenantId to: ' + tenantId)
         firebase.auth().tenantId = tenantId
      }

      const firebaseConfig = {
         apiKey: FIREBASE_API_KEY,
         authDomain: FIREBASE_AUTH_DOMAIN,
      }

      // Initialize Firebase
      firebase.initializeApp(firebaseConfig)
      initFirebaseTenantId()

      // You could also get the current user from Auth state observer.
      firebase.auth().onAuthStateChanged(async googleData => {
         if (googleData) {
            // User is signed in.
            // user.tenantId is set to 'TENANT_ID1'.

            firebase
               .auth()
               .currentUser.getIdToken(/* forceRefresh */ true)
               .then(async function (idToken) {
                  // Send token to your backend via HTTPS

                  // Perform auth from backend.
                  await fetch(BACKEND_URL + '/auth/isValidEmail?email=' + googleData.email)
                     .then(response => response.json())
                     .then(async data => {
                        log.debug('isValidEmail: ' + JSON.stringify(data))

                        if (data.valid) {
                           const updateBackendResponse = await updateBackendWithToken(
                              googleData.email,
                              'gip',
                              idToken
                           )

                           if (!updateBackendResponse.data.success) {
                              // Failed to verify token.
                              setErrorMsg('Failed to verify identity token.')
                              setAlertSeverity('error')
                              setErrorAlert(true)
                              log.info('Failed to verify identity token.')
                              return
                           }

                           let settings = data.Settings
                           if (!settings) {
                              settings = {
                                 ColourScheme: 'Traffic Light',
                                 IconScheme: 'Dots Default',
                                 StripedOverview: true,
                                 GroupedOverview: false,
                                 ShowDayNames: true,
                                 ShowWeekTop: true,
                                 NumberOfWeeksShown: 2,
                                 PushExercise: true,
                                 TeamSelect: null,
                              }
                           }

                           let reports = data.AcceptedReports
                           if (!reports) {
                              reports = []
                           }

                           let ndas = data.AcceptedNDA
                           if (!ndas) {
                              ndas = []
                           }

                           let LoginAllowed = data.LoginAllowed
                           if (!LoginAllowed) {
                              LoginAllowed = true
                           }

                           let Language = data.Language
                           if (!Language) {
                              Language = 'en'
                           }

                           let TimeZone = data.TimeZone
                           if (!TimeZone) {
                              TimeZone = 'Europe/Amsterdam'
                           }

                           const currentUserPermissions = returnCurrentUserPermissions(data)

                           // update redux store
                           if (LoginAllowed) {
                              localStorage.setItem('novartoHrNewLogin', 'true')
                              props.authenticate({
                                 EmployeeID: data.EmployeeID,
                                 Settings: settings,
                                 AcceptedReports: reports,
                                 AcceptedNDA: ndas,
                                 tokenObj: '',
                                 googleAccessToken: idToken,
                                 email: googleData.email,
                                 imageUrl: googleData.photoUrl,
                                 name: googleData.displayName,
                                 manager: data.Manager,
                                 backofficeAdmin: data.BackofficeAdmin,
                                 admin: data.Admin,
                                 superAdmin: data.SuperAdmin,
                                 LoginAllowed: LoginAllowed,
                                 Language: Language,
                                 loginProvider: 'gip',
                                 tenantId: firebase.auth().tenantId,
                                 permissions: currentUserPermissions,
                              })

                              console.log('SETTING TENANT ID: ' + firebase.auth().tenantId)

                              props.setTimeZone(TimeZone)

                              // Set language to fetched from backend
                              props.i18n.changeLanguage(Language)
                           } else {
                              setErrorMsg(t('loginNotAllowed'))
                              setErrorAlert(true)
                              setAlertSeverity('error')
                              log.info('User not allowed to login.')
                           }
                        } else {
                           setErrorMsg(t('loginNotAllowed'))
                           setErrorAlert(true)
                           setAlertSeverity('error')
                           log.info('Not valid email')
                        }
                     })
                     .catch(error => {
                        setErrorMsg('Network error: ' + error)
                        setErrorAlert(true)
                        setAlertSeverity('error')
                        log.info('Not valid email')
                     })
               })
               .catch(function (error) {
                  console.log('ID token error: ' + error)
                  setErrorMsg(error.message)
                  setAlertSeverity('error')
                  setErrorAlert(true)
               })
         } else {
            // No user is signed in.
         }
      })
   })

   const performLogin = () => {
      firebase
         .auth()
         .signInWithEmailAndPassword(email, password)
         .then(result => {
            log.info('Login success.')
         })
         .catch(error => {
            console.log('performLogin error: ' + JSON.stringify(error))
            setErrorMsg(error.message)
            setAlertSeverity('error')
            setErrorAlert(true)
         })
   }

   const sendReset = () => {
      if (!resetEmail || resetEmail.length < 1) {
         setErrorMsg('Type your e-mail in the field above.')
         setAlertSeverity('info')
         setErrorAlert(true)
         return
      }
      firebase
         .auth()
         .sendPasswordResetEmail(resetEmail)
         .then(function () {
            setErrorMsg(
               'An e-mail with information how to reset password has been sent to: ' + resetEmail
            )
            setAlertSeverity('success')
            setErrorAlert(true)
         })
         .catch(function (error) {
            setErrorMsg('An error occurred: ' + error)
            setAlertSeverity('error')
            setErrorAlert(true)
         })
   }

   return props.loggedIn ? (
      <Redirect to="/" />
   ) : (
      <Container
         style={{
            paddingLeft: 50,
            paddingRight: 50,
            paddingTop: 0,
            paddingBottom: 20,
            marginTop: 0,
            width: 500,
         }}
      >
         <CssBaseline />
         <div className={classes.paper}>
            <img
               style={{ width: 400, marginTop: 10 }}
               src="/hills/hills4.jpg"
               alt="hills logo"
            ></img>

            <Typography style={{ color: '#000', marginTop: 0 }} component="h1" variant="h5">
               Hills HR
            </Typography>

            {showReset ? (
               <form onSubmit={() => sendReset()}>
                  <div style={{ marginTop: 20 }}>
                     <Typography style={{ color: '#000' }}>{t('emailResetLabel')}:</Typography>
                     <br />
                     <input
                        style={{ width: 280 }}
                        autoComplete="off"
                        value={resetEmail}
                        type="text"
                        onChange={e => setResetEmail(e.target.value)}
                        placeholder={t('typeEmailPlaceholder')}
                     />
                  </div>
                  <div style={{ marginTop: 20 }}>
                     <center>
                        <Button variant="contained" color="primary" onClick={() => sendReset()}>
                           {t('sendPasswordReset')}
                        </Button>
                     </center>
                  </div>
                  <div style={{ marginTop: 10 }}>
                     <center>
                        <Link
                           style={{ cursor: 'pointer' }}
                           onClick={() => {
                              setShowReset(false)
                              setErrorAlert(false)
                           }}
                        >
                           Go back
                        </Link>
                     </center>
                  </div>
               </form>
            ) : (
               <form onSubmit={() => performLogin()}>
                  <div style={{ marginTop: 20 }}>
                     <Typography style={{ color: '#000' }}>{t('emailLabel')}:</Typography>
                     <br />
                     <input
                        style={{ width: 280 }}
                        autoComplete="off"
                        value={email}
                        type="text"
                        onChange={e => setEmail(e.target.value)}
                        placeholder={t('typeEmailPlaceholder')}
                     />
                  </div>
                  <div style={{ marginTop: 20 }}>
                     <Typography style={{ color: '#000' }}>{t('passwordLabel')}:</Typography>
                     <br />
                     <input
                        style={{ width: 280 }}
                        autoComplete="off"
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        placeholder={t('typePasswordPlaceholder')}
                        onKeyPress={event => {
                           if (event.key === 'Enter') {
                              performLogin()
                              event.target.value = null
                           }
                        }}
                     />
                  </div>

                  <div style={{ marginTop: 20 }}>
                     <center>
                        <Button variant="contained" color="primary" onClick={() => performLogin()}>
                           {t('Login')}
                        </Button>
                     </center>
                  </div>
                  <div style={{ marginTop: 10 }}>
                     <center>
                        <Link
                           style={{ cursor: 'pointer' }}
                           onClick={() => {
                              setShowReset(true)
                              setErrorAlert(false)
                           }}
                        >
                           {t('forgotPassword')}
                        </Link>
                     </center>
                  </div>
               </form>
            )}

            {errorAlert ? (
               <div style={{ marginTop: 20 }}>
                  <Alert severity={alertSeverity}>{errorMsg}</Alert>
               </div>
            ) : (
               <></>
            )}
         </div>
         <Box mt={8}>
            <Copyright />
         </Box>
      </Container>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      timezone: state.timezone,
   }
}

export default withTranslation()(
   connect(mapStateToProps, { authenticate, setTimeZone })(HillsLogin)
)
