import React from 'react'
import { wrapUser } from '../util/wrappers'
import { Avatar, Box, Typography, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { filterProjectsByStartAndEndDate } from '../util/helpers'

const useStyles = makeStyles({
   tooltip: {
      backgroundColor: '#616161',
      padding: '6px 10px',
   },
})

const UserProjectManagers = ({ projects, userId, users, t }) => {
   const classes = useStyles()

   const userProjects = filterProjectsByStartAndEndDate(projects, userId)

   const getAndWrapUser = id => {
      const user = users.find(user => user._id === id)
      return user ? wrapUser(user) : null
   }

   const isUserActive = userId => {
      const user = users.find(user => user._id === userId)
      const isEmploymentActive = user.Employments.some(employment => {
         const assignmentStartDate = new Date(employment.StartDate)
         const assignmentEndDate = new Date(employment.EndDate)

         return (
            (assignmentStartDate <= new Date() && !employment.EndDate) ||
            (assignmentStartDate <= new Date() && assignmentEndDate >= new Date())
         )
      })
      return isEmploymentActive
   }
   return (
      <Box display="flex" alignItems="center">
         {isUserActive(userId)
            ? userProjects.length
               ? userProjects
                    .filter(project => project.Status === 'Active')
                    .map(project =>
                       project.ProjectManager ? (
                          <Tooltip
                             key={project._id}
                             classes={{ tooltip: classes.tooltip }}
                             arrow
                             title={
                                <Box display="flex" flexDirection="column" alignItems="start">
                                   <Typography variant="caption" style={{ fontSize: 10 }}>
                                      {t('PM')}:{' '}
                                      <b>{getAndWrapUser(project.ProjectManager)?.nick}</b>
                                   </Typography>
                                   <Typography variant="caption" style={{ fontSize: 10 }}>
                                      {project.Name}
                                   </Typography>
                                </Box>
                             }
                          >
                             <Avatar
                                style={{
                                   color: '#fff',
                                   backgroundColor: '#0D2540',
                                   padding: '15px',
                                   marginLeft: '4px',
                                   height: '32px',
                                   width: '32px',
                                }}
                             >
                                <Typography>
                                   {getAndWrapUser(project.ProjectManager)?.initials
                                      ? getAndWrapUser(project.ProjectManager)?.initials
                                      : ''}
                                </Typography>
                             </Avatar>
                          </Tooltip>
                       ) : userProjects.length === 1 ? (
                          t('unassignedToProject')
                       ) : null
                    )
               : t('unassignedToProject')
            : t('inactiveEmployment')}
      </Box>
   )
}

export default UserProjectManagers
