import React, { useEffect, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Button, Fab, Modal, TextField } from '@mui/material'
import { withTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Assignment from './components/Assignment'
import AddIcon from '@mui/icons-material/Add'
import { v4 as uuidv4 } from 'uuid'
import { connect } from 'react-redux'
import { getProjectAvailabilityByUserID } from '../../../Api'

function getModalStyle() {
   return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
   }
}

const useStyles = makeStyles(theme => ({
   paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
      maxHeight: '95%',
      overflowY: 'auto',
   },
   buttonsPosition: {
      float: 'right',
   },
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
   actionButtonContainer: {
      textAlign: 'center',
   },
   assignmentContainer: {
      maxHeight: '95%',
   },
}))

const EditMemberDetailsModal = ({
   openModal,
   toggleModal,
   updateSelectedProject,
   selectedProject,
   memberIDToEditDetails,
   activeUsers,
   inactiveUsers,
   isPastMember,
   userInfo,
   t,
}) => {
   const classes = useStyles()
   const [modalStyle] = useState(getModalStyle)
   const [userDetailsData, setUserDetailsData] = useState({})
   const [isInNewAssignmentProcess, setIsInNewAssignmentProcess] = useState(false)
   const [userAvailability, setUserAvailability] = useState(null)

   useEffect(() => {
      async function getUserAvailability() {
         try {
            const userAvailability = await getProjectAvailabilityByUserID(
               userInfo,
               memberIDToEditDetails
            ).then(res => res.data)
            console.log('DATA', userAvailability)
            setUserAvailability(userAvailability)
         } catch {
            console.log('Error getting user availability.')
         }
      }

      getUserAvailability()
   }, [])

   useEffect(() => {
      const foundObject = selectedProject?.MemberDetails?.find(
         memberDetail => memberDetail._id === memberIDToEditDetails
      )
      const pastMemberFoundObj = selectedProject?.pastMembers?.find(
         memberDetail => memberDetail._id === memberIDToEditDetails
      )
      if (foundObject) {
         setUserDetailsData(foundObject)
      } else if (pastMemberFoundObj) {
         setUserDetailsData(pastMemberFoundObj)
      } else {
         if (isPastMember) {
            const foundUser = inactiveUsers.find(user => user._id === memberIDToEditDetails)
            setUserDetailsData({
               _id: foundUser._id,
               Name: `${foundUser.FirstName}${
                  foundUser.MiddleName !== '' ? ` ${foundUser.MiddleName} ` : ' '
               }${foundUser.LastName}`,
               Assignments: [],
            })
         } else {
            const foundUser = activeUsers.find(user => user._id === memberIDToEditDetails)
            setUserDetailsData({
               _id: foundUser._id,
               Name: `${foundUser.FirstName}${
                  foundUser.MiddleName !== '' ? ` ${foundUser.MiddleName} ` : ' '
               }${foundUser.LastName}`,
               Assignments: [],
            })
         }
      }
   }, [memberIDToEditDetails])

   const setIsActive = copyUserDetailsData => {
      copyUserDetailsData.isActive = copyUserDetailsData.Assignments.some(assignment => {
         const assignmentStartDate = new Date(assignment.StartDate)
         const assignmentEndDate = new Date(assignment.EndDate)
         const dateNow = new Date()
         dateNow.setHours(0, 0, 0, 0)
         assignmentStartDate.setHours(0, 0, 0, 0)
         assignmentEndDate.setHours(0, 0, 0, 0)

         return (
            (userDetailsData.Assignments.length > 0 &&
               assignmentStartDate <= dateNow &&
               !assignment.EndDate) ||
            (userDetailsData.Assignments.length > 0 &&
               assignmentStartDate <= dateNow &&
               assignmentEndDate >= dateNow)
         )
      })
      setUserDetailsData(copyUserDetailsData)
   }

   const updateUserDetailsAssignmentData = (assignmentIdToUpdate, property, value) => {
      const copyUserDetailsData = JSON.parse(JSON.stringify(userDetailsData))

      copyUserDetailsData.Assignments = copyUserDetailsData.Assignments.map(assignment => {
         if (assignment.hasOwnProperty('_id')) {
            if (assignment._id === assignmentIdToUpdate) {
               if (property === 'SubTasksPermission' && value === false) {
                  return { ...assignment, [property]: value, TasksBillability: false }
               } else {
                  return { ...assignment, [property]: value }
               }
            }
         } else {
            if (assignment.generatedId === assignmentIdToUpdate) {
               if (property === 'SubTasksPermission' && value === false) {
                  return { ...assignment, [property]: value, TasksBillability: false }
               } else {
                  return { ...assignment, [property]: value }
               }
            }
         }
         return assignment
      })
      setUserDetailsData(copyUserDetailsData)
      setIsActive(copyUserDetailsData)
   }

   const updateSelectedProjectMemberDetails = () => {
      toggleModal()
      if (!selectedProject.MemberDetails || selectedProject.MemberDetails.length === 0)
         updateSelectedProject('MemberDetails', [userDetailsData])
      else {
         const foundIndex = selectedProject.MemberDetails.findIndex(
            memberDetail => memberDetail._id === userDetailsData._id
         )
         if (foundIndex > -1) {
            selectedProject.MemberDetails.splice(foundIndex, 1, userDetailsData)
            updateSelectedProject('MemberDetails', selectedProject.MemberDetails)
         } else
            updateSelectedProject('MemberDetails', [
               ...selectedProject.MemberDetails,
               userDetailsData,
            ])
      }
   }

   const addEmptyAssignment = () => {
      setIsInNewAssignmentProcess(true)
      const copyUserDetailsData = JSON.parse(JSON.stringify(userDetailsData))
      copyUserDetailsData.Assignments.unshift({ generatedId: uuidv4() })
      setUserDetailsData(copyUserDetailsData)
   }
   const isEndDateNull = () => userDetailsData.Assignments?.some(assignment => !assignment.EndDate)

   const onDeleteAssignment = deleteAssignmentId => {
      const copyUserDetailsData = JSON.parse(JSON.stringify(userDetailsData))
      const foundAssignmentIndex = copyUserDetailsData.Assignments.findIndex(assignment => {
         if (assignment.hasOwnProperty('_id')) {
            return assignment._id === deleteAssignmentId
         } else {
            return assignment.generatedId === deleteAssignmentId
         }
      })
      copyUserDetailsData.Assignments.splice(foundAssignmentIndex, 1)
      setIsActive(copyUserDetailsData)
      setUserDetailsData(copyUserDetailsData)
   }
   return (
      <Modal
         open={openModal}
         onClose={toggleModal}
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
         style={{ overflow: 'scroll' }}
      >
         <Paper className={classes.paper} style={modalStyle}>
            <h5 id="simple-modal-title">{t('manageMemberDetailsTitle')}</h5>
            <Box style={{ padding: 10, paddingBottom: 10 }}>
               <Box className={'pb-1 my-1'}>
                  <Typography variant="body2">{t('manageMemberDetailsNameField')}</Typography>
                  <TextField
                     id="outlined-multiline-static"
                     variant="outlined"
                     className={classes.textField}
                     value={userDetailsData.Name}
                     disabled
                  />
               </Box>

               {!isPastMember && (
                  <Box className={'text-center my-3'}>
                     <Button
                        variant={'outlined'}
                        disabled={isEndDateNull()}
                        onClick={addEmptyAssignment}
                     >
                        <AddIcon sx={{ mr: 0.5 }} /> {t('editProjectMemberNewAssignmentText')}
                     </Button>
                  </Box>
               )}

               <Box className={classes.assignmentContainer}>
                  {userDetailsData.Assignments?.length > 0 ? (
                     userDetailsData.Assignments.map((assignment, index) => (
                        <React.Fragment key={assignment._id || assignment.generatedId}>
                           <Assignment
                              activeUsers={activeUsers}
                              inactiveUsers={inactiveUsers}
                              assignment={assignment}
                              isPastMember={isPastMember}
                              updateUserDetailsData={(property, value) =>
                                 updateUserDetailsAssignmentData(
                                    assignment._id || assignment.generatedId,
                                    property,
                                    value
                                 )
                              }
                              selectedProject={selectedProject}
                              isInNewAssignmentProcess={isInNewAssignmentProcess}
                              onDeleteAssignment={() =>
                                 onDeleteAssignment(assignment._id || assignment.generatedId)
                              }
                              userDetailsData={userDetailsData}
                              index={index}
                              userAvailability={userAvailability}
                           />
                        </React.Fragment>
                     ))
                  ) : (
                     <Box className={'text-center'}>{t('editProjectMemberNoAssignmentText')}</Box>
                  )}
               </Box>
            </Box>

            <div className={classes.buttonsPosition}>
               {!isPastMember && (
                  <Button
                     size="small"
                     variant="contained"
                     color="primary"
                     onClick={updateSelectedProjectMemberDetails}
                  >
                     {t('saveButtonTitle')}
                  </Button>
               )}
               <Button
                  color={'secondary'}
                  size="small"
                  variant="contained"
                  onClick={toggleModal}
                  className={'ml-2'}
               >
                  {t('cancelButtonTitle')}
               </Button>
            </div>
         </Paper>
      </Modal>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}

export default withTranslation()(connect(mapStateToProps)(EditMemberDetailsModal))
