import React from 'react'
import { TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
   textField: {
      width: '80px',
      padding: 0,
      backgroundColor: 'white',
      borderRadius: '7px',
   },
}))

const TimesheetModalTimeInput = ({ value, isPlaceholder }) => {
   const classes = useStyles()

   return (
      <TextField
         defaultValue={value}
         disabled={false}
         variant="outlined"
         size="small"
         className={classes.textField}
         placeholder={isPlaceholder ? '00:00' : ''}
         InputProps={{
            readOnly: true,
         }}
      />
   )
}

export default TimesheetModalTimeInput
