import { faEdit, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Snackbar from '@mui/material/Snackbar'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import { parseJSON } from 'date-fns'
import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { updateUserEmployments, fetchGenericData } from '../Api'
import UserDropdown from '../components/UserDropdown'
import ConfirmDialog from '../ConfirmDialog'
import { fetchActiveUsers, fetchUsers } from '../redux/actions/actions'
import themeConfig from '../theme'
import { getUserByEmployeeID, validateEmploymentDates, useEffectOnlyOnce } from '../util/helpers'
import { wrapUser } from '../util/wrappers'
import queryString from 'query-string'
import Dropdown from 'react-dropdown'
import '../css/employments.css'
import { registerLocale } from 'react-datepicker'
import { Prompt } from 'react-router'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import bg from 'date-fns/locale/bg'
import { withTranslation } from 'react-i18next'
import log from '../util/log'
import { MenuItem, Select, TextField } from '@mui/material'
import { Autocomplete } from '@mui/material'
registerLocale('sv', sv)
registerLocale('en-GB', en)
registerLocale('bg', bg)

const { styles } = themeConfig()

const useStyles = makeStyles(theme => ({
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
   select: {
      marginTop: '0.25rem',
      width: '100%',
      height: '40px',
      fontSize: '0.875rem',
      borderRadius: '4px',
      '& .MuiOutlinedInput-input': {
         padding: '5px',
         paddingBottom: '2px',
         paddingLeft: '10px',
      },
   },
   dropdown: {
      marginTop: '0.25rem',
      width: '100%',
      '& .Dropdown-control': {
         padding: '10px 52px 10px 10px',
         borderRadius: '4px',
         height: '40px',
      },
   },
   dropdownSelected: {
      '& .Dropdown-placeholder': {
         color: '#333',
      },
   },
   dropdownUnselected: {
      '& .Dropdown-placeholder': {
         color: '#b0aeae',
      },
   },
   notes: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
   },
   autocomplete: {
      marginTop: '0.25rem',
      width: '100%',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiInputBase-root': {
         height: '40px',
         padding: 0,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
         padding: 0,
         paddingLeft: '10px',
      },
   },
   datepickerField: {
      '& .react-datepicker-wrapper': {
         width: '100%',
      },
   },
}))

const EmploymentList = ({ ...props }) => {
   const { t, fetchActiveUsers } = props
   const classes = useStyles()
   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      severity: 'info',
   })

   const [currentUserState, setCurrentUserState] = React.useState({
      wrappedUser: null,
   })

   const initialFormState = {
      StartDate: null,
      EndDate: null,
      WorkTimePercent: 100,
      Title: '',
      Type: 'TILLSVIDARE',
      CostCenterID: '',
      VacationDays: 0,
      InitialVacation: 0,
      ReferredBy: '',
      Notes: '',
   }

   const [costCenters, setCostCenters] = React.useState([])

   const [formState, setFormState] = React.useState(initialFormState)

   /**
    * which index in the employment array on the user to edit
    * null if no row has been selected
    */
   const [editState, setEditState] = React.useState({
      index: null,
      Employment: null,
   })

   const [dirty, setDirty] = React.useState(false)

   const [confirmState, setConfirmState] = React.useState({
      confirmButtonTitle: null,
      showConfirm: false,
      confirmMessage: null,
      confirmOnChange: null,
      confirmButtonVariant: null,
      confirmTitle: null,
   })

   const { userInfo, fetchUsers } = props

   const options = [
      { label: t('TILLSVIDARE'), value: 'TILLSVIDARE' },
      { label: t('PROV'), value: 'PROV' },
      { label: t('PROJEKT'), value: 'PROJEKT' },
   ]

   useEffectOnlyOnce(() => {
      async function loadData() {
         await fetchUsers(userInfo)

         // If parameter is in the url lets set the user dropdown to the user
         const parsed = queryString.parse(props.location.search)

         if (parsed.id != null && parsed.id.length > 0) {
            const user = getUserByEmployeeID(parseInt(parsed.id), props.users)
            const wrappedUser = wrapUser(user)
            setCurrentUserState({ wrappedUser: wrappedUser })
         }

         // Fetch cost centers.
         const data = await fetchGenericData(userInfo, 'costCenterModel')
         setCostCenters(data)
      }
      loadData()
   })

   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ open: false })
   }

   const clearForm = () => {
      setFormState(initialFormState)
      setEditState({ index: null, Employment: null })
      setDirty(false)
   }

   const handleChangeEmployee = wrappedUser => {
      log.debug('      handleChangeEmployee ' + JSON.stringify(wrappedUser))
      log.debug('USERS: ' + JSON.stringify(props.users))
      setCurrentUserState({ wrappedUser: wrappedUser })
      clearForm()
   }

   const newEmployment = async () => {
      try {
         const user = props.users.find(u => u.EmployeeID === currentUserState.wrappedUser.value)
         let employments = user.Employments

         if (!employments) {
            // Create an empty array if not already set on user.
            employments = []
         }

         if (formState.WorkTimePercent > 100 || formState.WorkTimePercent < 0) {
            setSnackbarState({ open: true, message: t('workPerentInvalidText'), severity: 'error' })
            return
         }
         if (formState.CostCenterID === '...') {
            setSnackbarState({ open: true, message: t('costCenterNullText'), severity: 'error' })
            return
         }

         const newEmployment = {
            StartDate: formState.StartDate,
            EndDate: formState.EndDate,
            WorkTimePercent: formState.WorkTimePercent,
            Title: formState.Title,
            Type: formState.Type,
            CostCenterID: formState.CostCenterID,
            VacationDays: formState.VacationDays,
            InitialVacation: formState.InitialVacation,
            ReferredBy: formState.ReferredBy,
            Notes: formState.Notes,
         }

         const dateValidated = validateEmploymentDates(employments, newEmployment)
         if (!dateValidated.success) {
            setSnackbarState({ open: true, message: dateValidated.message, severity: 'error' })
            return
         }

         employments
            .filter(emp => new Date(emp.EndDate) > new Date(newEmployment.StartDate))
            .forEach(emp => (emp.EndDate = newEmployment.StartDate))

         employments.unshift(newEmployment)

         log.debug('NEW employments: ' + JSON.stringify(employments))

         await updateUserEmployments(
            currentUserState.wrappedUser.value,
            employments,
            props.userInfo
         ).then(
            response =>
               setSnackbarState({ open: true, message: t('doneMsg'), severity: 'success' }),
            response =>
               setSnackbarState({ open: true, message: t('errorText1'), severity: 'error' })
         )
         reload()

         clearForm()
      } catch (err) {
         log.error(err)
         // TODO show alert in UI
      }
   }

   const updateEmployment = async () => {
      try {
         const user = props.users.find(u => u.EmployeeID === currentUserState.wrappedUser.value)
         let employments = user.Employments

         log.debug('Found user: ' + JSON.stringify(user))
         log.debug('Found user employments: ' + JSON.stringify(employments))

         const updatedEmployment = {
            StartDate: formState.StartDate,
            EndDate: formState.EndDate,
            WorkTimePercent: formState.WorkTimePercent,
            Title: formState.Title,
            Type: formState.Type,
            CostCenterID: formState.CostCenterID,
            VacationDays: formState.VacationDays,
            InitialVacation: formState.InitialVacation,
            ReferredBy: formState.ReferredBy,
            Notes: formState.Notes,
         }

         if (formState.WorkTimePercent > 100 || formState.WorkTimePercent < 0) {
            setSnackbarState({
               open: true,
               message: t('workPercentInvalidText'),
               severity: 'error',
            })
            return
         }
         if (formState.CostCenterID === '...') {
            setSnackbarState({ open: true, message: t('costCenterNullText'), severity: 'error' })
            return
         }

         const dateValidated = validateEmploymentDates(employments, updatedEmployment)
         if (!dateValidated.success) {
            setSnackbarState({ open: true, message: dateValidated.message, severity: 'error' })
            return
         }

         employments[editState.index] = updatedEmployment

         log.debug('UPDATED EMPLOYMENTS: ' + JSON.stringify(employments))

         await updateUserEmployments(
            currentUserState.wrappedUser.value,
            employments,
            props.userInfo
         ).then(
            response =>
               setSnackbarState({ open: true, message: t('doneMsg'), severity: 'success' }),
            response =>
               setSnackbarState({ open: true, message: t('errorText1'), severity: 'error' })
         )
         reload()

         clearForm()
      } catch (err) {
         setSnackbarState({ open: true, message: t('errorText2') + ': ' + err, severity: 'error' })
      }
   }

   const openEmploymentForEdit = (Employment, index) => {
      setEditState({ Employment: Employment, index: index })

      setDirty(false)
      setFormState({
         ...formState,
         StartDate: Employment.StartDate != null ? parseJSON(Employment.StartDate) : null,
         EndDate: Employment.EndDate != null ? parseJSON(Employment.EndDate) : null,
         WorkTimePercent: Employment.WorkTimePercent,
         Title: Employment.Title,
         CostCenterID: Employment.CostCenterID != null ? '' + Employment.CostCenterID : '',
         Type: Employment.Type,
         VacationDays: Employment.VacationDays != null ? Employment.VacationDays : 0,
         InitialVacation: Employment.InitialVacation != null ? Employment.InitialVacation : 0,
         ReferredBy:
            Employment.ReferredBy !== null && Employment.ReferredBy !== undefined
               ? Employment.ReferredBy
               : '',
         Notes: Employment.Notes !== null && Employment.Notes !== undefined ? Employment.Notes : '',
      })
   }

   const deleteEmployment = async (Employment, index) => {
      const user = props.users.find(u => u.EmployeeID === currentUserState.wrappedUser.value)
      let employments = user.Employments

      employments.splice(index, 1)

      await updateUserEmployments(currentUserState.wrappedUser.value, employments, props.userInfo)

      reload()
      setDirty(false)
   }

   const reload = async () => {
      // TODO perhaps do not need to fetch ALL users after update only the particular
      // one that has been changed. Maybe just change the user in the users in the redux-store
      // without fetching from backend
      await fetchUsers(props.userInfo)
      fetchActiveUsers(props.userInfo)

      // Reload the user, this will get the new user object from the user list and
      // set it into the currentUserState
      setCurrentUserState({ wrappedUser: currentUserState.wrappedUser })
   }

   const toggleConfirm = (show, title, message, buttonTitle, buttonVariant, onChange) => {
      setConfirmState({
         showConfirm: show,
         confirmTitle: title,
         confirmMessage: message,
         confirmButtonTitle: buttonTitle,
         confirmButtonVariant: buttonVariant,
         confirmOnChange: onChange,
      })
   }

   const handleFormState = form => {
      setDirty(true)
      setFormState(form)
   }

   if (props.users == null) {
      return (
         <React.Fragment>
            <Typography variant="body2">{t('fetchingData')}</Typography>
         </React.Fragment>
      )
   }

   const getCurrentUser = () => {
      return props.users.find(u => u.EmployeeID === currentUserState.wrappedUser.value)
   }

   const CostCenterDropbox = () => {
      let options = []
      costCenters.forEach(costCenter => {
         options.push({
            label: costCenter.DisplayID + ' - ' + costCenter.Name,
            value: '' + costCenter.ID,
         })
      })
      log.debug('OPTIONS: ' + JSON.stringify(options))
      return (
         <Dropdown
            className={`${classes.dropdown} ${
               formState.CostCenterID !== '' ? classes.dropdownSelected : classes.dropdownUnselected
            }`}
            options={options}
            value={formState.CostCenterID}
            placeholder={t('Costcenter')}
            onChange={e => handleFormState({ ...formState, CostCenterID: e.value })}
         />
      )
   }

   const ReferredByDropbox = () => {
      return (
         <Autocomplete
            options={props.users
               .filter(user => user._id !== currentUserState.wrappedUser._id)
               .map(user => {
                  return `${user.FirstName}${
                     user.MiddleName !== '' ? ' ' + user.MiddleName + ' ' : ' '
                  }${user.LastName}`
               })}
            className={classes.autocomplete}
            getOptionLabel={option => option}
            value={formState.ReferredBy !== null ? formState.ReferredBy : ''}
            onChange={(e, newValue) => handleFormState({ ...formState, ReferredBy: newValue })}
            renderInput={params => (
               <TextField {...params} placeholder={t('referredBy')} variant="outlined" />
            )}
         />
      )
   }

   const getCostCenterLabel = CostCenterID => {
      // Find cost center based on id and return name
      const costCenter = costCenters.filter(cc => cc.ID === CostCenterID)

      if (costCenter.length < 1) {
         return t('errorNoId') + ' ' + CostCenterID
      } else {
         return costCenter[0].DisplayID + ' - ' + costCenter[0].Name
      }
   }

   const handleCancelButton = () => {
      setDirty(false)
      setFormState(initialFormState)
      setEditState({ index: null, Employment: null })
   }

   return (
      <React.Fragment>
         <ConfirmDialog
            {...{
               confirmTitle: confirmState.confirmTitle,
               confirmButtonTitle: confirmState.confirmButtonTitle,
               confirmButtonVariant: confirmState.confirmButtonVariant,
               showConfirm: confirmState.showConfirm,
               message: confirmState.confirmMessage,
               handleConfirm: confirmState.confirmOnChange,
               toggleConfirm: toggleConfirm,
            }}
         />
         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>
         <Paper className="contentPaper" style={{ maxWidth: 1200, boxShadow: 'none' }}>
            <div className={'contentWrapper'}>
               <div style={{ padding: 15 }}>
                  {t('employeeTitle')}
                  <div className={'mt-1'}>
                     <UserDropdown
                        users={props.users}
                        handleChangeEmployee={handleChangeEmployee}
                        value={
                           currentUserState.wrappedUser != null
                              ? currentUserState.wrappedUser
                              : null
                        }
                     />
                  </div>
               </div>

               <div style={{ padding: 10 }}>
                  {currentUserState.wrappedUser != null &&
                     currentUserState.wrappedUser.value != null && (
                        <React.Fragment>
                           <Grid
                              container
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={1}
                           >
                              <Grid item xs={4} className={'p-2'}>
                                 <Typography variant="body2">{t('From')}</Typography>
                                 <div className={classes.datepickerField}>
                                    <DatePicker
                                       selected={formState.StartDate}
                                       onChange={date =>
                                          handleFormState({ ...formState, StartDate: date })
                                       }
                                       dateFormat="yyyy-MM-dd"
                                       locale={t('datepickerLocale')}
                                       showWeekNumbers
                                       yearDropdownItemNumber={3}
                                       showMonthDropdown={true}
                                       showYearDropdown={true}
                                       className={'datepicker-field mt-1'}
                                       maxDate={formState.EndDate}
                                       openToDate={formState.StartDate}
                                       placeholderText={t('From')}
                                    />
                                 </div>
                              </Grid>
                              <Grid item xs={4} className={'p-2'}>
                                 <Typography variant="body2">{t('To')}</Typography>
                                 <div className={classes.datepickerField}>
                                    <DatePicker
                                       disabled={!formState.StartDate}
                                       selected={formState.EndDate}
                                       onChange={date =>
                                          handleFormState({ ...formState, EndDate: date })
                                       }
                                       dateFormat={'yyyy-MM-dd'}
                                       locale={t('datepickerLocale')}
                                       showWeekNumbers
                                       yearDropdownItemNumber={3}
                                       showMonthDropdown={true}
                                       showYearDropdown={true}
                                       className="datepicker-field mt-1"
                                       minDate={formState.StartDate}
                                       openToDate={
                                          formState.EndDate
                                             ? formState.EndDate
                                             : formState.StartDate
                                       }
                                       placeholderText={t('To')}
                                    />
                                 </div>
                              </Grid>
                              <Grid item xs={4} className={'p-2'}>
                                 <Typography variant="body2">{t('Worktitle')}</Typography>
                                 <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={formState.Title}
                                    onChange={e =>
                                       handleFormState({ ...formState, Title: e.target.value })
                                    }
                                    className={classes.textField}
                                    placeholder={t('Worktitle')}
                                 />
                              </Grid>

                              <Grid item xs={4} className={'p-2'}>
                                 <Typography variant="body2">{t('Type')}</Typography>
                                 <Select
                                    className={classes.select}
                                    value={formState.Type}
                                    variant={'outlined'}
                                    renderValue={() => {
                                       if (!formState.Type) return '...'
                                       return options.find(
                                          option => option.value === formState.Type
                                       ).label
                                    }}
                                    displayEmpty
                                    onChange={event =>
                                       handleFormState({
                                          ...formState,
                                          Type: event.target.value.value,
                                       })
                                    }
                                    placeholder={t('Type')}
                                 >
                                    {options.map((option, i) => (
                                       <MenuItem key={i} value={option}>
                                          {option.label}
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </Grid>
                              <Grid item xs={4} className={'p-2'}>
                                 <Typography variant="body2">{t('Costcenter')}</Typography>
                                 <CostCenterDropbox />
                              </Grid>
                              <Grid item xs={4} className={'p-2'}>
                                 <Typography variant="body2">{t('workPercent')}</Typography>
                                 <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={formState.WorkTimePercent}
                                    onChange={e =>
                                       handleFormState({
                                          ...formState,
                                          WorkTimePercent: e.target.value,
                                       })
                                    }
                                    className={classes.textField}
                                    placeholder={t('workPercent')}
                                 />
                              </Grid>

                              <Grid item xs={4} className={'p-2'}>
                                 <Typography variant="body2">{t('VacationDays')}</Typography>
                                 <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={formState.VacationDays}
                                    onChange={e =>
                                       handleFormState({
                                          ...formState,
                                          VacationDays: e.target.value,
                                       })
                                    }
                                    className={classes.textField}
                                    placeholder={t('VacationDays')}
                                 />
                              </Grid>
                              <Grid item xs={4} className={'p-2'}>
                                 <Typography variant="body2">{t('InitialVacation')}</Typography>
                                 <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={formState.InitialVacation}
                                    onChange={e =>
                                       handleFormState({
                                          ...formState,
                                          InitialVacation: e.target.value,
                                       })
                                    }
                                    className={classes.textField}
                                    placeholder={t('InitialVacation')}
                                 />
                              </Grid>
                              <Grid item xs={4} className={'p-2'}>
                                 <Typography variant="body2">{t('referredBy')}</Typography>
                                 <ReferredByDropbox />
                              </Grid>
                              <Grid item xs={12} className={'p-2'}>
                                 <Typography variant="body2">{t('notesTitle')}</Typography>
                                 <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    minRows={4}
                                    variant="outlined"
                                    className={classes.notes}
                                    value={formState.Notes}
                                    onChange={e =>
                                       handleFormState({
                                          ...formState,
                                          Notes: e.target.value,
                                       })
                                    }
                                    placeholder={t('notesTitle')}
                                 />
                              </Grid>

                              <Grid item xs={11} className={'pb-3'}>
                                 <Button
                                    size="small"
                                    disabled={
                                       !(
                                          formState.StartDate &&
                                          formState.WorkTimePercent &&
                                          editState.index === null
                                       )
                                    }
                                    variant="contained"
                                    color="primary"
                                    onClick={() => newEmployment()}
                                 >
                                    {t('saveNewEmploymentButton')}
                                 </Button>

                                 <Button
                                    size="small"
                                    style={{ marginLeft: 15 }}
                                    disabled={
                                       !dirty ||
                                       !(
                                          formState.StartDate &&
                                          formState.WorkTimePercent &&
                                          editState.index !== null
                                       )
                                    }
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => updateEmployment()}
                                 >
                                    {t('saveUpdateEmploymentButton')}
                                 </Button>
                              </Grid>
                              <Grid item xs={1} className={'pb-3'}>
                                 <Button
                                    size="small"
                                    style={{ marginLeft: 15 }}
                                    variant={'contained'}
                                    color={'secondary'}
                                    onClick={() => handleCancelButton()}
                                 >
                                    {t('clearButtonTitle')}
                                 </Button>
                              </Grid>
                           </Grid>

                           <TableContainer
                              className="employmentTable"
                              component={Paper}
                              style={{ maxHeight: 500 }}
                           >
                              <Table size="medium">
                                 <TableHead>
                                    <TableRow>
                                       <TableCell> </TableCell>
                                       <TableCell align="center">{t('From')}</TableCell>
                                       <TableCell align="center">{t('To')}</TableCell>
                                       <TableCell align="center">{t('Type')}</TableCell>
                                       <TableCell align="center">{t('Worktitle')}</TableCell>
                                       <TableCell align="center">{t('Costcenter')}</TableCell>
                                       <TableCell align="center">{t('workPercent')}</TableCell>
                                       <TableCell align="center">{t('VacationDays')}</TableCell>
                                       <TableCell align="center">{t('InitialVacation')}</TableCell>
                                       <TableCell></TableCell>
                                    </TableRow>
                                 </TableHead>

                                 <TableBody>
                                    {getCurrentUser().Employments != null &&
                                    getCurrentUser().Employments.length > 0
                                       ? getCurrentUser().Employments.map((Employment, index) => (
                                            <React.Fragment key={index}>
                                               <TableRow
                                                  key={'row_' + index}
                                                  onClick={() =>
                                                     openEmploymentForEdit(Employment, index)
                                                  }
                                               >
                                                  <TableCell
                                                     style={{ padding: 0, paddingLeft: '5px' }}
                                                  >
                                                     <FontAwesomeIcon
                                                        style={{ cursor: 'pointer' }}
                                                        icon={
                                                           editState.Employment != null &&
                                                           editState.index === index
                                                              ? faPen
                                                              : faEdit
                                                        }
                                                        color={
                                                           editState.Employment != null &&
                                                           editState.index === index
                                                              ? 'green'
                                                              : 'black'
                                                        }
                                                        onClick={() =>
                                                           openEmploymentForEdit(Employment, index)
                                                        }
                                                     />

                                                     <FontAwesomeIcon
                                                        style={{ marginLeft: 5, cursor: 'pointer' }}
                                                        icon={faTrash}
                                                        onClick={event => {
                                                           event.stopPropagation()
                                                           toggleConfirm(
                                                              true,
                                                              t('deleteEmploymentModalTitle'),
                                                              t('confirmDeleteEmploymentMsg') +
                                                                 ': [' +
                                                                 t('period') +
                                                                 ': ' +
                                                                 new Date(
                                                                    Employment.StartDate
                                                                 ).toLocaleDateString('sv-SE') +
                                                                 ' ~ ' +
                                                                 (Employment.EndDate !== null
                                                                    ? new Date(
                                                                         Employment.EndDate
                                                                      ).toLocaleDateString('sv-SE')
                                                                    : t('unspecifiedTime')) +
                                                                 '] ' +
                                                                 t('for') +
                                                                 ' ' +
                                                                 wrapUser(
                                                                    getUserByEmployeeID(
                                                                       getCurrentUser().EmployeeID,
                                                                       props.users
                                                                    )
                                                                 ).label +
                                                                 '?',
                                                              t('deleteEmploymentButtonTitle'),
                                                              'danger',
                                                              () =>
                                                                 deleteEmployment(Employment, index)
                                                           )
                                                        }}
                                                     />
                                                  </TableCell>
                                                  <TableCell
                                                     variant={
                                                        editState.index === index ? 'head' : 'body'
                                                     }
                                                     align="center"
                                                  >
                                                     {Employment.StartDate != null ? (
                                                        <Moment
                                                           date={Employment.StartDate}
                                                           format="YYYY-MM-DD"
                                                        />
                                                     ) : (
                                                        ''
                                                     )}
                                                  </TableCell>
                                                  <TableCell
                                                     variant={
                                                        editState.index === index ? 'head' : 'body'
                                                     }
                                                     align="center"
                                                  >
                                                     {Employment.EndDate != null ? (
                                                        <Moment
                                                           date={Employment.EndDate}
                                                           format="YYYY-MM-DD"
                                                        />
                                                     ) : (
                                                        ''
                                                     )}
                                                  </TableCell>
                                                  <TableCell
                                                     variant={
                                                        editState.index === index ? 'head' : 'body'
                                                     }
                                                     align="center"
                                                  >
                                                     {t(Employment.Type)}
                                                  </TableCell>
                                                  <TableCell
                                                     variant={
                                                        editState.index === index ? 'head' : 'body'
                                                     }
                                                     align="center"
                                                  >
                                                     {Employment.Title}
                                                  </TableCell>
                                                  <TableCell
                                                     variant={
                                                        editState.index === index ? 'head' : 'body'
                                                     }
                                                     align="center"
                                                  >
                                                     {getCostCenterLabel(Employment.CostCenterID)}
                                                  </TableCell>
                                                  <TableCell
                                                     variant={
                                                        editState.index === index ? 'head' : 'body'
                                                     }
                                                     align="center"
                                                  >
                                                     {Employment.WorkTimePercent}
                                                  </TableCell>
                                                  <TableCell
                                                     variant={
                                                        editState.index === index ? 'head' : 'body'
                                                     }
                                                     align="center"
                                                  >
                                                     {Employment.VacationDays}
                                                  </TableCell>
                                                  <TableCell
                                                     variant={
                                                        editState.index === index ? 'head' : 'body'
                                                     }
                                                     align="center"
                                                  >
                                                     {Employment.InitialVacation}
                                                  </TableCell>
                                               </TableRow>
                                            </React.Fragment>
                                         ))
                                       : null}
                                 </TableBody>
                              </Table>
                           </TableContainer>
                        </React.Fragment>
                     )}
               </div>
            </div>
         </Paper>
         <Prompt when={dirty} message={t('leavePagePrompt')} />
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      users: state.users,
   }
}

export default withStyles(styles)(
   withTranslation()(connect(mapStateToProps, { fetchUsers, fetchActiveUsers })(EmploymentList))
)
