import { Box, Button, CircularProgress, MenuItem, Modal, Paper, Select } from '@mui/material'
import styled from '@emotion/styled'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { createExcel } from '../../../../Api'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import moment from 'moment-timezone'

const StyledReportPaper = styled(Paper)({
   position: 'relative',
   maxWidth: '550px',
   backgroundColor: 'white',
   borderRadius: 1,
   top: `50%`,
   left: `50%`,
   transform: `translate(-50%, -50%)`,
   textAlign: 'center',
})

const StyledSaveButton = styled(Button)({
   width: 170,
   height: 40,
   padding: '8px 16px',
   borderRadius: 4,
   boxShadow: 'none',
   backgroundColor: '#008DC6',
   fontWeight: 700,
   fontSize: 12,
   lineHeight: '16px',
   letterSpacing: '0em',
   textAlign: 'left',
   color: '#fff',
   margin: 20,
   '&:hover': {
      backgroundColor: '#2D3A73',
      boxShadow: 'none',
   },
})
const validationSchema = Yup.object({
   year: Yup.number().required('Year is required'),
   month: Yup.string().required('Month is required'),
})

const TimesheetReportModal = ({ t, isReportModalOpen, toggleReportModal, userInfo }) => {
   const [loading, setLoading] = useState(false)
   const generateReport = async values => {
      const reportObj = { year: values.year, month: values.month }
      setLoading(prev => !prev)
      try {
         const url = await createExcel(reportObj, userInfo)
         window.open(url.data.folderUrl, '_blank')
         toggleReportModal(prev => !prev)
         setLoading(prev => !prev)
      } catch (error) {
         console.error('Error generating report:', error)
      }
   }
   require('moment/min/locales.min')
   moment.locale(t('locale'))
   let months = moment.months()
   months = months.map(month => month.charAt(0).toUpperCase() + month.slice(1))

   const currentYear = moment().year()
   const years = []

   for (let year = 2022; year <= currentYear; year++) {
      years.push(year)
   }

   return (
      <Modal
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
         open={isReportModalOpen}
         onClose={toggleReportModal}
      >
         <StyledReportPaper>
            <Formik
               initialValues={{ year: '', month: '' }}
               validationSchema={validationSchema}
               onSubmit={generateReport}
            >
               {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  errors,
                  touched,
               }) => (
                  <Form onSubmit={handleSubmit}>
                     <Box sx={{ pt: 2, fontWeight: 600, fontSize: 25 }}>Timesheet report</Box>

                     <Box>
                        <Form error={!!values.year}>
                           <Field
                              sx={{ width: 300, m: 2 }}
                              as={Select}
                              name="year"
                              displayEmpty
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.year}
                              error={errors.year && touched.year}
                           >
                              <MenuItem value="" disabled>
                                 Year
                              </MenuItem>
                              {years.map((year, index) => (
                                 <MenuItem key={index} value={year}>
                                    {year}
                                 </MenuItem>
                              ))}
                           </Field>
                        </Form>
                     </Box>

                     <Box>
                        <Form error={!!values.month}>
                           <Field
                              sx={{ width: 300, m: 2 }}
                              as={Select}
                              name="month"
                              displayEmpty
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.month}
                              error={errors.month && touched.month}
                           >
                              <MenuItem value="" disabled>
                                 Month
                              </MenuItem>
                              {months.map((m, index) => (
                                 <MenuItem key={index} value={index}>
                                    {m}
                                 </MenuItem>
                              ))}
                           </Field>
                        </Form>
                     </Box>

                     <Box>
                        {!loading ? (
                           <StyledSaveButton type="submit" disabled={isSubmitting}>
                              Generate report
                           </StyledSaveButton>
                        ) : (
                           <CircularProgress sx={{ m: 2 }} />
                        )}
                     </Box>
                  </Form>
               )}
            </Formik>
         </StyledReportPaper>
      </Modal>
   )
}
const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}

export default withTranslation()(connect(mapStateToProps)(TimesheetReportModal))
