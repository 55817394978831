import React from 'react'
import {
   Accordion,
   AccordionSummary,
   Typography,
   AccordionDetails,
   AccordionActions,
   Button,
   Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Skill from './Skill'
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
   marginTop: {
      marginTop: '20px',
   },
   accordionDetails: {
      display: 'block',
   },
   dividerPosition: {
      marginBottom: '10px',
      marginTop: '10px',
   },
   dividerColor: {
      backgroundColor: '#e6e6e6',
   },
}))

const DisplaySkillsGroups = ({
   skillGroups,
   handleCreateEditSkillGroup,
   handleDeleteSkillGroup,
   handleAddSkill,
   handleEditSkill,
   handleDeleteSkill,
   t,
}) => {
   const classes = useStyles()

   return (
      <div className={classes.marginTop}>
         {skillGroups.map(skillGroup => {
            return (
               <Accordion key={skillGroup._id}>
                  <AccordionSummary
                     expandIcon={<ExpandMoreIcon />}
                     aria-controls="panel1a-content"
                     id="panel1a-header"
                  >
                     <Typography>{skillGroup.Name}</Typography>
                  </AccordionSummary>
                  <Button
                     size="small"
                     variant="contained"
                     color="primary"
                     className={'ml-2'}
                     onClick={() => handleAddSkill(skillGroup._id, skillGroup.Name)}
                  >
                     {t('newSkillButtonTitle')}
                  </Button>
                  <Divider className={`${classes.dividerPosition} ${classes.dividerColor}`} />
                  <AccordionDetails className={classes.accordionDetails}>
                     <Skill
                        skills={skillGroup['Skills']}
                        groupID={skillGroup._id}
                        handleEditSkill={handleEditSkill}
                        handleDeleteSkill={handleDeleteSkill}
                     />
                  </AccordionDetails>
                  <AccordionActions>
                     <Button
                        size="small"
                        color="primary"
                        onClick={() => handleCreateEditSkillGroup(skillGroup)}
                     >
                        {t('editButtonTitle')}
                     </Button>
                     <Button size="small" onClick={() => handleDeleteSkillGroup(skillGroup)}>
                        {t('deleteButtonTitle')}
                     </Button>
                  </AccordionActions>
               </Accordion>
            )
         })}
      </div>
   )
}

export default withTranslation()(DisplaySkillsGroups)
