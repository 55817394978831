import React, { useCallback, useEffect, useState } from 'react'
import {
   Badge,
   Box,
   Button,
   Checkbox,
   FormControl,
   FormControlLabel,
   FormGroup,
   Grid,
   Menu,
   TextField,
} from '@mui/material'
import { Autocomplete } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import { withTranslation } from 'react-i18next'
import { deleteProject } from '../../../Api'
import CustomDeleteModal from '../../../components/modals/CustomDeleteModal'
import CreateProjectModal from '../../../components/modals/CreateProjectModal'
import DiscardChangesModal from '../../../components/modals/DiscardChangesModal'

const StyledMenu = withStyles({
   paper: {
      border: '1px solid #d3d4d5',
      borderRadius: '4px',
   },
})(props => (
   <Menu
      elevation={0}
      anchorOrigin={{
         vertical: 'bottom',
         horizontal: 'center',
      }}
      transformOrigin={{
         vertical: 'top',
         horizontal: 'center',
      }}
      {...props}
   />
))

const useStyles = makeStyles(theme => ({
   mainAutocomplete: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiInputBase-root': {
         height: '55px',
         padding: 0,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
         padding: 0,
         paddingLeft: '10px',
      },
   },
   settingsIcon: {
      cursor: 'pointer',
      backgroundColor: '#009be5',
      color: '#fff',
      borderRadius: '25px',
      padding: '5px',
      '&:hover': {
         color: '#fff',
         backgroundColor: '#006db3',
      },
   },
   settingsIconExpanded: {
      cursor: 'pointer',
      borderRadius: '25px',
      padding: '5px',
      color: '#fff',
      backgroundColor: '#006db3',
   },
}))

const ProjectManagementHeader = ({
   projects,
   selectedProject,
   setSelectedProject,
   haveUnsavedChanges,
   setHaveUnsavedChanges,
   customer,
   fetchProjects,
   showSuccessfulSnackbar,
   showErrorSnackBar,
   toggleManageMembersModal,
   t,
   userInfo,
}) => {
   const [filteredProjects, setFilteredProjects] = useState([])
   const [projectFilters, setProjectFilters] = useState([])
   const [filterAnchor, setFilterAnchor] = useState(null)
   const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false)
   const [openDeleteModal, setOpenDeleteModal] = useState(false)
   const [openDiscardChangesModal, setOpenDiscardChangesModal] = useState(false)
   const [callbackFunction, setCallbackFunction] = useState(() => {})

   const classes = useStyles()

   useEffect(() => {
      JSON.parse(localStorage.getItem('hrAppliedFilters')) &&
         JSON.parse(localStorage.getItem('hrAppliedFilters')).projects &&
         setProjectFilters(JSON.parse(localStorage.getItem('hrAppliedFilters')).projects)
   }, [])

   useEffect(() => {
      const allProjects = projects ? [...projects] : [];
      const activeProjects = allProjects.filter(project => project.Status === 'Active')
      if (projectFilters[0] === 'All') {
         setFilteredProjects(
            allProjects.sort((a, b) => {
               const nameA = a.Name.toUpperCase()
               const nameB = b.Name.toUpperCase()
               return nameA.localeCompare(nameB)
            })
         )
      } else {
         if (projectFilters.length) {
            let filteredAllProjects = []
            projectFilters.forEach(projectFilter => {
               filteredAllProjects = [
                  ...filteredAllProjects,
                  ...allProjects.filter(project => project.Status === projectFilter),
               ]
            })
            setFilteredProjects(
               filteredAllProjects.sort((a, b) => {
                  const nameA = a.Name.toUpperCase()
                  const nameB = b.Name.toUpperCase()
                  return nameA.localeCompare(nameB)
               })
            )
         } else
            setFilteredProjects(
               activeProjects.sort((a, b) => {
                  const nameA = a.Name.toUpperCase()
                  const nameB = b.Name.toUpperCase()
                  return nameA.localeCompare(nameB)
               })
            )
      }
   }, [projects, projectFilters])

   const handleSelectProject = useCallback(project => {
      setHaveUnsavedChanges(false)
      setSelectedProject(project)
   }, [])

   const addRemoveAdvancedFilterCriteria = newFilter => {
      let currentFilters = [...projectFilters]
      if (currentFilters.includes(newFilter)) {
         currentFilters.splice(currentFilters.indexOf(newFilter), 1)
      } else {
         setSelectedProject({})
         currentFilters.push(newFilter)
      }
      if (newFilter === 'All') {
         if (currentFilters.includes('All')) {
            currentFilters = [newFilter]
         } else {
            setSelectedProject({})
         }
         const newFilterObject =
            localStorage.getItem('hrAppliedFilters') &&
            !Array.isArray(JSON.parse(localStorage.getItem('hrAppliedFilters')))
               ? JSON.parse(localStorage.getItem('hrAppliedFilters'))
               : {}
         if (currentFilters.length > 0) {
            newFilterObject.projects = currentFilters
            localStorage.setItem('hrAppliedFilters', JSON.stringify(newFilterObject))
         } else {
            delete newFilterObject.projects
            if (Object.keys(newFilterObject).length)
               localStorage.setItem('hrAppliedFilters', JSON.stringify(newFilterObject))
            else localStorage.removeItem('hrAppliedFilters')
         }
         setProjectFilters(currentFilters)
      } else {
         if (currentFilters.includes('All')) {
            currentFilters.splice(currentFilters.indexOf('All'), 1)
         }

         const newFilterObject =
            localStorage.getItem('hrAppliedFilters') &&
            !Array.isArray(JSON.parse(localStorage.getItem('hrAppliedFilters')))
               ? JSON.parse(localStorage.getItem('hrAppliedFilters'))
               : {}
         if (currentFilters.length > 0) {
            newFilterObject.projects = currentFilters
            localStorage.setItem('hrAppliedFilters', JSON.stringify(newFilterObject))
         } else {
            delete newFilterObject.projects
            if (Object.keys(newFilterObject).length)
               localStorage.setItem('hrAppliedFilters', JSON.stringify(newFilterObject))
            else localStorage.removeItem('hrAppliedFilters')
         }
         setProjectFilters(currentFilters)
      }
   }

   const checkIfHasChangesBeforeChangeProject = useCallback(
      project => {
         if (haveUnsavedChanges) {
            setCallbackFunction(() => () => {
               handleSelectProject(project)
            })
            toggleDiscardModal()
         } else handleSelectProject(project)
      },
      [haveUnsavedChanges]
   )

   const handleFilterClick = event => {
      setFilterAnchor(event.currentTarget)
   }
   const handleFilterClose = () => {
      setFilterAnchor(null)
   }

   const checkTheCheckbox = newFilter => {
      return projectFilters.includes(newFilter)
   }

   const toggleCreateModal = () => {
      openCreateProjectModal && setHaveUnsavedChanges(false)
      setOpenCreateProjectModal(prevState => !prevState)
   }

   const toggleDeleteModal = () => {
      setOpenDeleteModal(prevState => !prevState)
   }

   const toggleDiscardModal = () => {
      setOpenDiscardChangesModal(prevState => !prevState)
   }

   const deleteProjectHandler = () => {
      deleteProject(selectedProject._id, userInfo)
         .then(() => {
            fetchProjects(customer)
            setSelectedProject({})
            showSuccessfulSnackbar(t('successToastDeletedProject'))
         })
         .catch(() => showErrorSnackBar(t('errorToastDeletedProject')))
   }

   return (
      <>
         <Grid container alignItems="flex-start" className={'align-items-center mb-4'}>
            <Grid
               item
               xs={7}
               className={'d-flex'}
               style={{ paddingRight: 74, alignItems: 'center' }}
            >
               <Autocomplete
                  value={selectedProject}
                  options={filteredProjects}
                  getOptionLabel={option => (Object.keys(option).length ? option.Name : '')}
                  onChange={(event, option) => {
                     if (option !== null) {
                        checkIfHasChangesBeforeChangeProject(option)
                     } else checkIfHasChangesBeforeChangeProject({})
                  }}
                  isOptionEqualToValue={(option, value) => option.Name === value.Name}
                  className={classes.mainAutocomplete}
                  renderInput={params => (
                     <TextField
                        {...params}
                        label={
                           Object.keys(selectedProject).length
                              ? t('projectManagementProjectTitleText')
                              : t('projectManagementProjectTitleTextMissing')
                        }
                        variant={'outlined'}
                     />
                  )}
               />
               <Badge badgeContent={projectFilters.length} overlap="rectangular" color="secondary">
                  <FilterListIcon
                     fontSize={'large'}
                     className={`${
                        filterAnchor ? classes.settingsIconExpanded : classes.settingsIcon
                     } ml-2`}
                     onClick={handleFilterClick}
                  />
               </Badge>
               <StyledMenu
                  id="customized-menu"
                  anchorEl={filterAnchor}
                  keepMounted
                  open={Boolean(filterAnchor)}
                  onClose={handleFilterClose}
               >
                  <Box display="flex" flexDirection="column" className={'p-2'}>
                     <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    color="primary"
                                    checked={checkTheCheckbox('All')}
                                    onChange={() => {
                                       addRemoveAdvancedFilterCriteria('All')
                                    }}
                                 />
                              }
                              label={t('filterCheckboxTextShowAll')}
                              labelPlacement="end"
                           ></FormControlLabel>
                        </FormGroup>
                     </FormControl>
                     <div style={{ border: '1px solid #9e9e9e', marginBottom: 9 }}></div>
                     <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    color="default"
                                    checked={checkTheCheckbox('Active Opportunity')}
                                    onChange={() => {
                                       addRemoveAdvancedFilterCriteria('Active Opportunity')
                                    }}
                                 />
                              }
                              label={t('filterCheckboxTextShowOpportunity')}
                              labelPlacement="end"
                           ></FormControlLabel>
                        </FormGroup>
                     </FormControl>
                     <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    color="default"
                                    checked={checkTheCheckbox('Canceled Opportunity')}
                                    onChange={() => {
                                       addRemoveAdvancedFilterCriteria('Canceled Opportunity')
                                    }}
                                 />
                              }
                              label={t('filterCheckboxTextShowCanceled')}
                              labelPlacement="end"
                           ></FormControlLabel>
                        </FormGroup>
                     </FormControl>

                     <FormControl component="fieldset">
                        <FormGroup aria-label="position">
                           <FormControlLabel
                              control={
                                 <Checkbox
                                    color="default"
                                    checked={checkTheCheckbox('Closed')}
                                    onChange={() => {
                                       addRemoveAdvancedFilterCriteria('Closed')
                                    }}
                                 />
                              }
                              label={t('filterCheckboxTextShowClosed')}
                              labelPlacement="end"
                           ></FormControlLabel>
                        </FormGroup>
                     </FormControl>
                  </Box>
               </StyledMenu>
            </Grid>
            <Grid item xs={5}>
               <div style={{ float: 'right' }}>
                  <Button
                     disabled={Object.keys(selectedProject).length === 0}
                     size="large"
                     variant="outlined"
                     color="secondary"
                     style={{ marginLeft: 10 }}
                     onClick={toggleDeleteModal}
                  >
                     <FontAwesomeIcon size="lg" icon={faTrash} />
                  </Button>
                  <Button
                     size="large"
                     variant="outlined"
                     color="primary"
                     style={{ marginLeft: 10 }}
                     onClick={() => {
                        if (haveUnsavedChanges) {
                           setCallbackFunction(() => () => {
                              toggleCreateModal()
                              setSelectedProject({})
                           })
                           toggleDiscardModal()
                        } else {
                           toggleCreateModal()
                        }
                     }}
                  >
                     <FontAwesomeIcon size="lg" icon={faPlus} />
                  </Button>
                  <Button
                     disabled={Object.keys(selectedProject).length === 0}
                     size="medium"
                     variant="contained"
                     color="primary"
                     style={{ marginLeft: 10 }}
                     onClick={toggleManageMembersModal}
                  >
                     {t('projectManagementManageMembersButtonText')}
                  </Button>
               </div>
            </Grid>
         </Grid>

         <CreateProjectModal
            openModal={openCreateProjectModal}
            toggleModal={toggleCreateModal}
            userInfo={userInfo}
            showSuccessfulSnackbar={showSuccessfulSnackbar}
            showErrorSnackbar={showErrorSnackBar}
            fetchProjects={id => fetchProjects(customer, id)}
         />
         <CustomDeleteModal
            title={t('deleteProjectModalTitle')}
            body={t('deleteProjectModalBody')}
            helperBody={t('deleteProjectModalHelperBody')}
            openModal={openDeleteModal}
            toggleModal={toggleDeleteModal}
            deleteAction={deleteProjectHandler}
         />
         <DiscardChangesModal
            openModal={openDiscardChangesModal}
            toggleModal={toggleDiscardModal}
            discardChanges={callbackFunction}
         />
      </>
   )
}

export default withTranslation()(ProjectManagementHeader)
