import {createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
//import AsyncStorage from '@react-native-community/async-storage';
//import storageSession from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga'
import reducer from './reducer';
import { watchFetchNotifs, watchAbsenceChange, watchFetchUsers, watchFetchActiveUsers, watchFetchFutureRequests, watchFetchSalaryReports, watchFetchCustomerData }  from '../redux-saga/sagas';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  debug: true,
}

export default () => {
  const persistedReducer = persistReducer(persistConfig, reducer)
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));

  const persistor = persistStore(store);

  // Add sagas
  sagaMiddleware.run(watchFetchNotifs);
  sagaMiddleware.run(watchAbsenceChange);
  sagaMiddleware.run(watchFetchUsers);
  sagaMiddleware.run(watchFetchActiveUsers);
  sagaMiddleware.run(watchFetchFutureRequests);
  sagaMiddleware.run(watchFetchSalaryReports);
  sagaMiddleware.run(watchFetchCustomerData);

  return { persistor, store };
};

