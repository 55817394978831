import * as types from './actions/actionTypes'
import log from '../util/log'

const initialState = {
   userInfo: null,
   loggedIn: false,
   notifs: {},
   users: [],
   activeUsers: [],
   requests: null,
   salaryReports: null,
   settings: null,
   error: '',
   timezone: 'Europe/Amsterdam',
   customerTimezone: 'Europe/Amsterdam',
   company: '',
   print: '',
   inactiveAbsences: [],
   workDay: {
      StartTime: '08:00',
      EndTime: '17:00',
      UseLunchBreak: true,
      LunchBreak: {
         StartTime: '12:00',
         EndTime: '13:00',
      },
   },
   hiddenMenuOptions: [],
   useVacationDays: false,
   VacationRefillMMDD: '01-01',
   ShowNextYearMMDD: '01-01',
   loginProvider: 'google',
   currentRole: {},
}

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case types.ERROR:
         return {
            ...state,
            error: action.error,
         }
      case types.TIMEZONE:
         return {
            ...state,
            timezone: action.timezone,
         }
      case types.AUTHENTICATE:
      case types.LOGOUT:
         return {
            ...state,
            userInfo: action.userInfo,
            loggedIn: action.loggedIn,
         }
      case types.FETCH_NOTIFS_SUCCEEDED:
         return {
            ...state,
            notifs: action.notifs,
         }
      case types.FETCH_USERS_SUCCEEDED:
         return {
            ...state,
            users: action.result.data,
         }
      case types.FETCH_ACTIVE_USERS_SUCCEEDED:
         return {
            ...state,
            activeUsers: action.result.data,
         }
      case types.FETCH_FUTURE_REQUESTS_SUCCEEDED:
         return {
            ...state,
            requests: action.result.requests,
         }
      case types.FETCH_SALARY_REPORTS_SUCCEEDED:
         return {
            ...state,
            salaryReports: action.result.reports,
         }
      case types.PERSIST_USERINFO:
         return {
            ...state,
            userInfo: action.userInfo,
         }
      case types.FETCH_CUSTOMER_DATA_SUCCEEDED:
         return {
            ...state,
            customerTimezone: action.result.customer.TimeZone,
            company: action.result.customer.Name,
            print: action.result.customer.Features.PrintFormVariant,
            inactiveAbsences: action.result.customer.InactiveAbsences.map(abs => abs.AbsenceType),
            workDay: action.result.customer.WorkDay,
            hiddenMenuOptions: action.result.customer.Features.MenuOptionsHidden,
            useVacationDays: action.result.customer.VacationSettings.UseVacationDays,
            VacationRefillMMDD: action.result.customer.VacationSettings.VacationRefillMMDD,
            ShowNextYearMMDD: action.result.customer.VacationSettings.VacationRefillMMDD,
            ServerVersion: action.result.serverVersion,
         }

      default:
         return state
   }
}

export default reducer
