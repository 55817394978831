const absenceCodes = {
   SJUKFULLDAG: {
      name: 'Sjukfrånvaro - heldag',
      code: 'SJUKFULLDAG',
      informal: 'Sick',
      approvalRequired: false,
      affectsSalary: true,
      fullDayOnly: true,
      allowHalfHour: false,
      quickReport: true,
      isWork: false,
      isPrintable: false,
      usesVacationDays: false,
      isHealthCare: false,
   },

   SEMESTERFULLDAG: {
      name: 'Semester - heldag',
      code: 'SEMESTERFULLDAG',
      approvalRequired: true,
      affectsSalary: true,
      fullDayOnly: true,
      allowHalfHour: false,
      quickReport: false,
      isWork: false,
      isPrintable: true,
      usesVacationDays: true,
      isHealthCare: false,
   },
   FORALDRALEDIG: {
      name: 'Föräldraledig',
      code: 'FORALDRALEDIG',
      approvalRequired: true,
      affectsSalary: true,
      fullDayOnly: false,
      allowHalfHour: false,
      quickReport: false,
      isWork: false,
      isPrintable: false,
      usesVacationDays: false,
      isHealthCare: false,
   },

   TJANSTLEDIGFULLDAG: {
      name: 'Tjänstledig - heldag',
      code: 'TJANSTLEDIGFULLDAG',
      approvalRequired: true,
      affectsSalary: true,
      fullDayOnly: true,
      allowHalfHour: false,
      quickReport: false,
      isWork: false,
      isPrintable: true,
      usesVacationDays: false,
      isHealthCare: false,
   },
   TJANSTERESA: {
      name: 'Tjänsteresa',
      code: 'TJANSTERESA',
      approvalRequired: true,
      affectsSalary: false,
      fullDayOnly: false,
      allowHalfHour: false,
      quickReport: false,
      isWork: false,
      isPrintable: true,
      usesVacationDays: false,
      isHealthCare: false,
   },
   DISTANS: {
      name: 'Distansarbete',
      code: 'DISTANS',
      approvalRequired: true,
      affectsSalary: false,
      fullDayOnly: false,
      allowHalfHour: true,
      quickReport: false,
      isWork: true,
      isPrintable: true,
      usesVacationDays: false,
      isHealthCare: false,
   },
   LEGAL: {
      name: 'Legal',
      code: 'LEGAL',
      approvalRequired: true,
      affectsSalary: true,
      fullDayOnly: true,
      allowHalfHour: false,
      quickReport: false,
      isWork: false,
      isPrintable: true,
      usesVacationDays: false,
      isHealthCare: false,
   },
}

export default absenceCodes
