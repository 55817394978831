import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Button, Modal } from '@mui/material'
import { withTranslation } from 'react-i18next'

function getModalStyle() {
   return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
   }
}

const useStyles = makeStyles(theme => ({
   paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
   },
   body: {
      marginBottom: '20px',
   },
   buttonsPosition: {
      float: 'right',
   },
}))

const DeleteModal = ({ skillGroup, openModal, toggleModal, deleteSkillGroup, deleteSkill, t }) => {
   const classes = useStyles()
   const [modalStyle] = React.useState(getModalStyle)

   return (
      <Modal
         open={openModal}
         onClose={toggleModal}
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
      >
         <div style={modalStyle} className={classes.paper}>
            <div className={classes.body}>
               <h6 id="simple-modal-title">
                  {skillGroup._id ? t('deleteModalSkillGroupTitle') : t('deleteModalSkillTitle')}
               </h6>
            </div>
            <div className={classes.buttonsPosition}>
               <Button
                  onClick={skillGroup._id ? deleteSkillGroup : deleteSkill}
                  size="small"
                  variant="contained"
                  color="primary"
               >
                  {t('deleteButtonTitle')}
               </Button>
               <Button onClick={toggleModal}>{t('cancelButtonTitle')}</Button>
            </div>
         </div>
      </Modal>
   )
}

export default withTranslation()(DeleteModal)
