import { Box, Divider, Tooltip, Typography } from '@mui/material'
import { WarningAmber } from '@mui/icons-material'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import DatePointer from '../../components/DatePointer'
import {
   calculateBarWidthPercent,
   calculateEffectiveMarginLeftPercent,
   calculateRoadmapStartAndEndDate,
} from '../../helper'
import externalProjectIcon from '../../../../../../components/icons/Polygon.svg'
import { calculateWorkTime, timeToNumber, numberToTime } from '../../../../../../util/helpers'

const AvailableEmployeesBody = ({ period, employees, projects }) => {
   const { roadmapStartDate, roadmapEndDate } = calculateRoadmapStartAndEndDate(period)

   const periodDaysLength = period.reduce((acc, { dates }) => {
      acc = Number(acc) + dates.length
      return acc
   }, [])

   const getEmployeeProjectAssignments = employeeId => {
      const employeeActiveProjects = projects.filter(project => {
         const member = project.MemberDetails.find(member => member._id == employeeId)
         return member?.Assignments.find(assignment => {
            return (
               new Date(assignment.StartDate) <= roadmapEndDate &&
               (new Date(assignment.EndDate) >= roadmapStartDate || assignment.EndDate == undefined) // if the end date is not defined, it means that the assignment is still active
            )
         })
      })

      const employeeProjectAssignments = employeeActiveProjects.map(project => {
         const member = project.MemberDetails.find(member => member._id == employeeId)
         let memberAssignment = member.Assignments.find(assignment => {
            return (
               new Date(assignment.StartDate) <= roadmapEndDate &&
               (new Date(assignment.EndDate) >= roadmapStartDate || assignment.EndDate == undefined) // if the end date is not defined, it means that the assignment is still active
            )
         })

         return {
            projectId: project._id,
            projectName: project.Name,
            projectType: project.Type,
            startDate: memberAssignment.StartDate,
            endDate: memberAssignment.EndDate,
            workPercent: memberAssignment?.WorkPercent,
         }
      })

      return employeeProjectAssignments
   }

   const calculateAvailableTime = employeeId => {
      const employeeProjectAssignments = getEmployeeProjectAssignments(employeeId)

      const now = new Date()

      const onlyActiveAssignmentsForToday = employeeProjectAssignments.filter(assignment => {
         if (assignment.endDate == undefined) return true
         if (new Date(assignment.startDate) <= now && new Date(assignment.endDate) >= now) {
            return true
         }
      })

      let percent = 0
      onlyActiveAssignmentsForToday.forEach(assignment => {
         if (assignment.workPercent) {
            percent += Number(assignment.workPercent)
         }
      })

      const percentInTime = calculateWorkTime(percent)
      const time = timeToNumber(percentInTime)
      const availableTime = 8.0 - time

      return availableTime
   }

   const sortEmployees = employees => {
      return employees.sort((emp1, emp2) => {
         return calculateAvailableTime(emp2._id) - calculateAvailableTime(emp1._id)
      })
   }

   const renderEmployeeAssignments = employeeId => {
      const employeeProjectAssignments = getEmployeeProjectAssignments(employeeId)

      return employeeProjectAssignments.map(assignment => {
         const projectWidthPercent = calculateBarWidthPercent(
            roadmapStartDate,
            roadmapEndDate,
            assignment.endDate
         )
         const effectiveMarginLeftPercent = calculateEffectiveMarginLeftPercent(
            roadmapStartDate,
            roadmapEndDate,
            assignment.startDate
         )

         const singleProject = employeeProjectAssignments.length == 1

         return (
            <Box
               key={assignment.projectId}
               sx={{
                  marginLeft: `${effectiveMarginLeftPercent}%`,
                  width: `${Math.abs(projectWidthPercent - effectiveMarginLeftPercent)}%`,
                  height: singleProject ? '40px' : '26px',
                  padding: '8px',
                  border: '1px solid #52B3DD',
                  bgcolor: 'white',
                  marginBottom: '6px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  overflow: 'hidden',
               }}
            >
               <Box
                  sx={{
                     alignItems: 'center',
                     display: singleProject ? 'block' : 'flex',
                  }}
               >
                  <Box
                     sx={{
                        display: 'flex',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                     }}
                  >
                     {assignment.projectType == 'External' && (
                        <img
                           style={{ marginRight: 3 }}
                           src={externalProjectIcon}
                           alt="Project icon"
                        />
                     )}
                     <Typography
                        sx={{
                           fontSize: '14px',
                           fontWeight: '600',
                           color: assignment.projectType == 'External' ? '#0083BB' : '#5C5C5C',
                        }}
                     >
                        {assignment.projectName}
                     </Typography>
                  </Box>
                  <Box
                     sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: singleProject ? '0' : '8px',
                     }}
                  >
                     <Typography component="span" sx={{ fontSize: '12px' }}>
                        End:
                     </Typography>
                     <Typography
                        component="span"
                        sx={{ fontSize: '12px', color: '#B9B9B9', marginLeft: '2px' }}
                     >
                        {assignment.endDate
                           ? moment(assignment.endDate).format('DD.MM.YYYY')
                           : 'N/A'}
                     </Typography>
                  </Box>
               </Box>
               {assignment.workPercent ? (
                  <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#0083BB' }}>
                     {`${calculateWorkTime(assignment.workPercent)}h/${assignment.workPercent}%`}
                  </Typography>
               ) : (
                  <Tooltip title="Please specify the assignment level in the Project Management page. This is required for accurate resource allocation and project management.">
                     <WarningAmber sx={{ color: '#FA8817' }} />
                  </Tooltip>
               )}
            </Box>
         )
      })
   }

   const renderEmployees = employees => {
      const sortedEmployees = sortEmployees(employees)
      return sortedEmployees.map(employee => {
         const skills = employee.Skills?.reduce((accumulatedSkills, skill) => {
            return accumulatedSkills + (accumulatedSkills ? ', ' : '') + skill.Name
         }, '')

         const availableTime = calculateAvailableTime(employee._id)

         return (
            <Box
               key={employee._id}
               sx={{
                  minHeight: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '10px',
               }}
            >
               <Box sx={{ display: 'flex', width: '240px' }}>
                  <Box sx={{ width: '162px' }}>
                     <Typography
                        sx={{
                           fontSize: '15px',
                           color: '#454545',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           whiteSpace: 'nowrap',
                        }}
                     >
                        {employee.FirstName} {employee.LastName}
                     </Typography>
                     <Box
                        sx={{
                           fontSize: '11px',
                           color: '#939393',
                           fontWeight: '600',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                           whiteSpace: 'nowrap',
                        }}
                        title={skills}
                     >
                        {skills}
                     </Box>
                  </Box>
                  <Box
                     sx={{
                        width: '75px',
                        height: '18px',
                        bgcolor: availableTime > 0 ? '#60C064' : '#ffffff',
                        borderRadius: '4px',
                        fontSize: '12px',
                        fontWeight: '600',
                        color: availableTime > 0 ? '#ffffff' : '#60C064',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #60C064',
                     }}
                  >
                     {numberToTime(availableTime)}h AVBL
                  </Box>
               </Box>
               <Box sx={{ flex: '1' }}>{renderEmployeeAssignments(employee._id)}</Box>
            </Box>
         )
      })
   }

   return (
      <Box sx={{ display: 'flex', position: 'relative' }}>
         <Box
            sx={{
               width: '100%',
               height: 500,
               p: 3,
               position: 'absolute',
               zIndex: '1',
               overflowY: 'scroll',
               '&::-webkit-scrollbar': {
                  display: 'none',
               },
            }}
         >
            {renderEmployees(employees)}
         </Box>
         <Box
            sx={{
               width: '100%',
               margin: '0 24px 15px 264px',
               position: 'relative',
            }}
         >
            <DatePointer startDate={roadmapStartDate} endDate={roadmapEndDate} />
            <Box
               sx={{
                  border: '1px solid #DDE6E9',
                  bgcolor: '#F1F4F5',
                  borderRadius: '8px',
                  height: 500,
                  display: 'flex',
               }}
            >
               {period.length <= 2 &&
                  periodDaysLength <= 31 &&
                  period.map((currentPeriod, index) => (
                     <>
                        <Box
                           key={index}
                           sx={{
                              width: '100%',
                              flex: currentPeriod.dates.length,
                           }}
                        >
                           <Box sx={{ display: 'flex', height: '100%' }}>
                              {currentPeriod.dates.map((date, index) => (
                                 <Box
                                    sx={{
                                       width: '100%',
                                       borderRight:
                                          index < currentPeriod.dates.length - 1
                                             ? '1px solid #ACC9D3'
                                             : 'none',
                                    }}
                                 />
                              ))}
                           </Box>
                        </Box>
                        {index < period.length - 1 && (
                           <Divider
                              orientation="vertical"
                              sx={{ height: '100%', backgroundColor: '#ACC9D3' }}
                           />
                        )}
                     </>
                  ))}
            </Box>
         </Box>
      </Box>
   )
}
export default withTranslation()(AvailableEmployeesBody)
