import {
   Box,
   Checkbox,
   FormControl,
   FormControlLabel,
   InputAdornment,
   InputLabel,
   MenuItem,
   Select,
   TextField,
   Tooltip,
   Typography,
} from '@mui/material'
import {
   Search as SearchIcon,
   FiberManualRecord as FiberManualRecordIcon,
   Error as ErrorIcon,
   ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import { withTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
const useStyles = makeStyles({
   checkbox: {
      margin: 0,
      '&:hover .MuiCheckbox-root': {
         color: '#8ACCE6',
      },
   },
})
const ProjectReportHeader = ({ setProjectFilters, projectFilters, setCharacterFilter, t }) => {
   const classes = useStyles()
   const statusFilterProjects = filter => {
      setProjectFilters(prev => ({
         ...prev,
         active: false,
         closed: false,
         notUpdated: false,
         [filter]: !prev[filter],
      }))
   }
   const sortProjects = sort => {
      setProjectFilters(prev => ({
         ...prev,
         sortBy: sort,
      }))
   }
   const filterByName = text => {
      setCharacterFilter(text)
   }
   return (
      <>
         <Typography
            sx={{ textAlign: 'center', fontSize: 24, fontWeight: 600, color: '#3D3D3D', pt: 5 }}
         >
            {t('selectProject')}
         </Typography>
         <Box
            sx={{
               display: 'flex',
               width: '100%',
               justifyContent: 'space-between',
               p: '20px 40px',
            }}
         >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
               <TextField
                  size="small"
                  onChange={e => filterByName(e.target.value)}
                  variant="outlined"
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position="start">
                           <SearchIcon />
                        </InputAdornment>
                     ),
                  }}
                  style={{
                     maxWidth: 290,
                     marginRight: 32,
                     paddingBottom: '5px',
                  }}
                  placeholder={t('projectNameSearch')}
                  fullWidth
               />
               <Box
                  sx={{
                     p: 1,
                     borderRadius: 1,
                     minWidth: 160,

                     '&:hover': {
                        bgcolor: '#EDF6FA',
                     },
                  }}
               >
                  <FormControl variant="standard" fullWidth>
                     <InputLabel variant="standard" htmlFor="uncontrolled-native">
                        {t('sortBy')}
                     </InputLabel>
                     <Select
                        onChange={(e, value) => sortProjects(value.props.value)}
                        disableUnderline
                        MenuProps={{
                           PaperProps: {
                              sx: {
                                 padding: '0 4px',
                                 boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                 bgcolor: '#fff',
                                 '& .MuiMenuItem-root': {
                                    padding: 1,
                                    m: '5px 0',
                                    color: '#6D6D6D',
                                 },
                                 '& .MuiMenuItem-root:hover': {
                                    backgroundColor: '#EDF6FA',
                                    color: '#6D6D6D',
                                    borderRadius: 1,
                                 },
                                 '& .Mui-selected': {
                                    backgroundColor: '#008DC6',
                                    color: '#fff',
                                    borderRadius: 1,
                                 },
                              },
                           },
                        }}
                        IconComponent={ExpandMoreIcon}
                        sx={{
                           mt: '9px !important',
                        }}
                        defaultValue={'recentlyUpdated'}
                        inputProps={{
                           name: 'filter',
                           id: 'uncontrolled-native',
                        }}
                     >
                        <MenuItem value={'recentlyUpdated'}>{t('recently')}</MenuItem>
                        <MenuItem value={'name'}>{t('name')}</MenuItem>
                        <MenuItem value={'projectManager'}>{t('projectManager')}</MenuItem>
                     </Select>
                  </FormControl>
               </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
               <Typography sx={{ color: '#454545' }}>{t('filterProjects')}</Typography>

               <FormControlLabel
                  sx={{ pl: '5px' }}
                  onChange={() => statusFilterProjects('active')}
                  checked={projectFilters.active}
                  className={classes.checkbox}
                  control={<Checkbox disableRipple sx={{ padding: 0 }} />}
                  label={
                     <Box sx={{ display: 'flex' }}>
                        <FiberManualRecordIcon sx={{ color: '#9BE58C' }} />
                        {t('active')}
                     </Box>
                  }
               />
               <FormControlLabel
                  sx={{ pl: '25px' }}
                  onChange={() => statusFilterProjects('closed')}
                  checked={projectFilters.closed}
                  className={classes.checkbox}
                  control={<Checkbox disableRipple sx={{ padding: 0 }} />}
                  label={
                     <Box sx={{ display: 'flex' }}>
                        <FiberManualRecordIcon sx={{ color: '#E58C9C' }} />
                        {t('closed')}
                     </Box>
                  }
               />
               <FormControlLabel
                  sx={{ pl: '25px' }}
                  onChange={() => statusFilterProjects('notUpdated')}
                  checked={projectFilters.notUpdated}
                  className={classes.checkbox}
                  control={
                     <Tooltip
                        componentsProps={{
                           tooltip: {
                              sx: {
                                 color: 'white',
                                 backgroundColor: '#8ACCE6',
                                 '& .MuiTooltip-arrow': {
                                    color: '#8ACCE6',
                                 },
                              },
                           },
                        }}
                        title={t('noChanges')}
                        arrow
                     >
                        <Checkbox disableRipple sx={{ padding: 0 }} />
                     </Tooltip>
                  }
                  label={
                     <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ErrorIcon sx={{ color: '#E58C9C', width: 18, height: 18 }} />
                        {t('notUpdated')}
                     </Box>
                  }
               />
            </Box>
         </Box>
      </>
   )
}

export default withTranslation()(ProjectReportHeader)
