import { Typography, Paper } from '@mui/material'
import moment from 'moment-timezone'
import React, { useCallback, useRef, useEffect, useState, Fragment } from 'react'
import { connect } from 'react-redux'
import { colourSchemes, iconSchemes } from '../scheme.js'
import {
   dateWithoutTime,
   getWeeks,
   isHoliday,
   isSameDate,
   dateIsBeforeOrEqualTo,
   setDateToEndOfWorkDay,
   setDateToStartOfWorkDay,
} from 'novarto-time'
import { sortByLabel, sortByNickThenLabel } from '../util/helpers'
import { wrapProject, wrapTeam, wrapUser } from '../util/wrappers'
import {
   fetchActiveUserOverview,
   fetchBankHolidays,
   fetchCustomerForAuthUser,
   getTeamsForOverview,
   getProjectsForOverview,
   getAbsencesForGivenPeriod,
   fetchAbsenceRequestsPeriodically,
} from '../Api'
import { getNumberOfWeeksShown } from '../util/userProps'
import log from '../util/log'
import { withTranslation } from 'react-i18next'
import { logout, persistUserInfo } from '../redux/actions/actions'
import UserAbsenceDialog from '../components/UserAbsenceDialog'
import absenceCodes from '../enums/absenceCode.js'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import bg from 'date-fns/locale/bg'
import { registerLocale } from 'react-datepicker'
import Header from './components/Header'
import TableOverview from './components/table/Table'
import Filters from './components/Filters'
import { CircleLoader } from 'react-spinners'

registerLocale('sv', sv)
registerLocale('en-GB', en)
registerLocale('bg', bg)

const AbsenceDialog = ({ ...props }) => (
   <UserAbsenceDialog
      absences={props.absences}
      user={props.user}
      open={props.show}
      date={props.date}
      handleClose={props.handleClose}
      t={props.t}
   />
)

const AbsenceOverview = ({ ...props }) => {
   const { userInfo, t, workDay, pushExercise } = props
   const [today, setToday] = useState(dateWithoutTime(new Date()))
   const [allAbsences, setAllAbsences] = useState([])
   const [holidays, setHolidays] = useState([])
   const [wrappedUserData, setWrappedUserData] = useState([])
   const [teams, setTeams] = useState([])
   const [selectedTeam, setSelectedTeam] = useState(null)
   const [colourState, setColourState] = useState({
      currentColourScheme: colourSchemes.trafficLight,
   })
   const [iconState, setIconState] = useState({ currentIconScheme: iconSchemes.dotsDefault })
   const [displayDate, setDisplayDate] = useState(today)
   const [showSpinner, setShowSpinner] = useState(true)
   const [weekState, setWeekState] = useState({
      weekDates: getWeeks(new Date(), getNumberOfWeeksShown(props.userInfo)).weekDates,
      weekNumber: getWeeks(new Date(), getNumberOfWeeksShown(props.userInfo)).weekNumber,
      weeksSorted: [],
   })
   const [absenceDialogState, setAbsenceDialogState] = useState({
      show: false,
      absences: [],
      user: '',
      date: '',
   })
   const [isFilterVisible, setIsFilterVisible] = useState(false)
   const [projects, setProjects] = useState([])
   const [filterArray, setFilterArray] = useState([])
   const [absenceRequests, setAbsenceRequests] = useState([])
   const [year, setYear] = useState(weekState.weekDates[0].getFullYear())

   const ref = useRef(null)

   const showRequestedAbsencesMainOverview =
      userInfo.admin || userInfo.backofficeAdmin || userInfo.manager

   const healthCareAbsenceTypes = [
      ...Object.values(absenceCodes)
         .filter(absence => absence.isHealthCare)
         .map(absence => absence.code),
   ]

   const teamOptions = [
      { label: t('defaultTeam'), value: null, id: null, members: [] },
      ...teams.map(wrapTeam).sort(sortByLabel),
   ]

   const projectOptions = [...projects.map(wrapProject).sort(sortByLabel)]

   useEffect(() => {
      document.addEventListener('click', handleClickOutside, true)
      return () => {
         document.removeEventListener('click', handleClickOutside, true)
      }
   }, [])

   useEffect(() => {
      loadUserSettings()
      fetchTeamsAndProjects()
      fetchHolidays()
      const weekInfo = calculateWeeksByDate(today)
      setWeekState(weekInfo)
   }, [])

   useEffect(() => {
      setShowSpinner(true)
      fetchUsers(weekState)
      getAbsencesForShownWeeks(weekState)

      const interval = setInterval(() => {
         log.info('Refreshed data at: ' + Date.now())

         fetchUsers(weekState)
         fetchTeamsAndProjects()
         getAbsencesForShownWeeks(weekState)
         setToday(dateWithoutTime(new Date()))
      }, 300000)
      return () => {
         clearInterval(interval)
      }
   }, [year])

   useEffect(() => {
      if (year !== weekState.weekDates[0].getFullYear()) {
         setYear(weekState.weekDates[0].getFullYear())
      }
   }, [weekState])

   useEffect(() => {
      if (
         localStorage.getItem('hrAppliedFilters') &&
         JSON.parse(localStorage.getItem('hrAppliedFilters')).overview
      ) {
         setFilterArray(JSON.parse(localStorage.getItem('hrAppliedFilters')).overview)
      }
   }, [])

   const getAbsencesForShownWeeks = useCallback(
      async selectedWeeks => {
         try {
            const response = await getAbsencesForGivenPeriod(userInfo, selectedWeeks)
            if (response.success) {
               handleAbsences(response.data, selectedWeeks)
            } else {
               log.error('Failed to get absence: ' + JSON.stringify(response))
            }
         } catch (error) {
            log.error('Error occured in getAbsencesForShownWeeks: ' + error)
         }
      },
      [userInfo.googleAccessToken, weekState]
   )

   const handleAbsences = async (data, selectedWeeks) => {
      try {
         const res = await fetchAbsenceRequestsPeriodically(userInfo, null, selectedWeeks)
         if (res.success) {
            setAbsenceRequests(res.requests.filter(absence => absence.Status === 'REQUESTED'))
            if (userInfo.admin || userInfo.backofficeAdmin || userInfo.manager) {
               const newData = data.map(absence => {
                  if (absence.RequestID) {
                     const absenceRequest = res.requests.find(
                        request => request._id === absence.RequestID
                     )
                     return {
                        ...absence,
                        userComment: absenceRequest?.CommentByUser
                           ? absenceRequest.CommentByUser
                           : '',
                     }
                  } else {
                     return absence
                  }
               })
               setAllAbsences(newData)
            } else {
               setAllAbsences(data)
            }
         }
      } catch (error) {
         log.error(error)
         setAllAbsences(data)
      }
      setShowSpinner(false)
   }

   const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
         setIsFilterVisible(false)
      }
   }

   const getTouchingAbsences = useCallback(
      (weekday, userID, weekInfo) => {
         if (!allAbsences) return []
         let currDate = dateWithoutTime(new Date(weekInfo.weekDates[weekday]))
         return allAbsences.filter(
            dat =>
               dat.EmployeeID === userID &&
               (!dat.EndDate || new Date(dat.EndDate) >= currDate) &&
               dateWithoutTime(new Date(dat.StartDate)) <= currDate
         )
      },
      [allAbsences]
   )

   const getTouchingRequested = useCallback(
      (weekday, userID, weekInfo) => {
         let currDate = dateWithoutTime(new Date(weekInfo.weekDates[weekday]))
         let requests
         if (props.requests) {
            requests = props.requests
         } else {
            requests = absenceRequests
         }
         return requests.filter(
            dat =>
               dat.EmployeeID === userID &&
               dat.Status === 'REQUESTED' &&
               dateWithoutTime(new Date(dat.AbsenceIntervals[0].StartDate)) <= currDate &&
               new Date(dat.AbsenceIntervals[0].EndDate) >= currDate
         )
      },
      [props.requests, absenceRequests]
   )

   const afterDayEnd = useCallback(
      (date, currDate) => {
         const datTime = new Date(date.StartDate)
         return (
            datTime.getDate() === currDate.getDate() &&
            datTime.getMonth() === currDate.getMonth() &&
            datTime.getFullYear() === currDate.getFullYear() &&
            moment(datTime).isSameOrAfter(setDateToEndOfWorkDay(datTime, workDay))
         )
      },
      [workDay]
   )

   const beforeDayStart = useCallback(
      (date, currDate) => {
         if (!date.EndDate) return false
         const datTime = new Date(date.EndDate)
         return (
            datTime.getDate() === currDate.getDate() &&
            datTime.getMonth() === currDate.getMonth() &&
            datTime.getFullYear() === currDate.getFullYear() &&
            moment(datTime).isSameOrBefore(setDateToStartOfWorkDay(datTime, workDay))
         )
      },
      [workDay]
   )

   const outsideWorkHours = useCallback(
      (date, currDate) => afterDayEnd(date, currDate) || beforeDayStart(date, currDate),
      [afterDayEnd, beforeDayStart]
   )

   const calcThreshholdProximity = useCallback(
      (startDate, approxDays, currDate) => {
         let firstThreshhold = dateWithoutTime(new Date(startDate))
         firstThreshhold.setDate(firstThreshhold.getDate() + approxDays)
         while (
            firstThreshhold.getDay() === 0 ||
            firstThreshhold.getDay() === 6 ||
            isHoliday(firstThreshhold, holidays ? holidays : [])
         ) {
            firstThreshhold.setDate(firstThreshhold.getDate() + 1)
         }

         if (currDate < firstThreshhold) return 2
         if (today === firstThreshhold) return 1

         let secondThreshhold = new Date(firstThreshhold)
         do {
            secondThreshhold.setDate(secondThreshhold.getDate() + 1)
         } while (
            secondThreshhold.getDay() === 0 ||
            secondThreshhold.getDay() === 6 ||
            isHoliday(secondThreshhold, holidays ? holidays : [])
         )

         if (today < firstThreshhold) return currDate < secondThreshhold ? 1 : 0

         let lastThreshhold = new Date(today)
         do {
            lastThreshhold.setDate(lastThreshhold.getDate() + 1)
         } while (
            lastThreshhold.getDay() === 0 ||
            lastThreshhold.getDay() === 6 ||
            isHoliday(lastThreshhold, holidays ? holidays : [])
         )

         return currDate <= today ? 2 : currDate <= lastThreshhold ? 1 : 0
      },
      [holidays, today]
   )

   const onTheHour = useCallback(dat => {
      let newDate = new Date(dat)
      newDate.setMinutes(0)
      newDate.setSeconds(0)
      newDate.setMilliseconds(0)
      return newDate
   }, [])

   // This is a temporary fix while novarto-time is updated
   const absenceEndIsAfterWorkDayOrOngoing = useCallback((date, WorkDay) => {
      if (!date) {
         // No enddate set, means an ongoing absence
         return true
      }
      const workDayEnd = setDateToEndOfWorkDay(date, WorkDay)
      return new Date(date) >= new Date(workDayEnd)
   }, [])
   const dateOrWorkDayEndIsAfterDate = useCallback((date1, WorkDay, date2) => {
      if (date1) {
         return new Date(date1) > new Date(date2)
      }
      const wdEnd = setDateToEndOfWorkDay(date2, WorkDay)
      return new Date(wdEnd) >= new Date(date2)
   }, [])
   // The above is a temporary fix; see comment

   const fullAbsence = useCallback(
      (dat, currDate) =>
         // Starts before or at start of this day
         (dateWithoutTime(new Date(dat.StartDate)) < currDate ||
            onTheHour(dat.StartDate) <= new Date(setDateToStartOfWorkDay(currDate, workDay))) &&
         // Ends before or at end of this day, or has no end
         (!dat.EndDate ||
            dateWithoutTime(new Date(dat.EndDate)) > currDate ||
            new Date(dat.EndDate) >= new Date(setDateToEndOfWorkDay(currDate, workDay))) &&
         // If approximateDays is relevant, this day is before approximate return
         (dat.EndDate ||
            !dat.ApproximateDays ||
            calcThreshholdProximity(dat.StartDate, dat.ApproximateDays, currDate) === 2),
      [calcThreshholdProximity, onTheHour, workDay]
   )

   const ongoingAbsence = useCallback(
      (dat, currDate, now) =>
         isSameDate(currDate, today) &&
         absenceEndIsAfterWorkDayOrOngoing(dat.EndDate, workDay) &&
         dateIsBeforeOrEqualTo(new Date(dat.StartDate), now) &&
         dateOrWorkDayEndIsAfterDate(dat.EndDate, workDay, now),
      [absenceEndIsAfterWorkDayOrOngoing, dateOrWorkDayEndIsAfterDate, today, workDay]
   )

   const remainingPresence = useCallback(
      (dat, currDate, now) =>
         currDate.getMonth() === today.getMonth() &&
         currDate.getDate() === today.getDate() &&
         dat.EndDate &&
         new Date(dat.EndDate) <= now,
      [today]
   )

   const absenceSeverity = useCallback(
      (weekday, userID, touchingAbsences, weekInfo) => {
         const currDate = dateWithoutTime(new Date(weekInfo.weekDates[weekday]))

         // Exit point if currDate is a holiday
         if (isHoliday(currDate, holidays ? holidays : [])) return 3

         if (
            props.showRequested ||
            showRequestedAbsencesMainOverview ||
            userID === userInfo.EmployeeID
         ) {
            let touchingRequested = getTouchingRequested(weekday, userID, weekInfo)
            // Exit point specifically for absence request view if this day is a requested absence
            if (touchingRequested.length > 0) return 4
         }

         if (!touchingAbsences) touchingAbsences = getTouchingAbsences(weekday, userID, weekInfo)

         // Exit point if there are no absences this day, or if they all end before workDay.StartTime or start past workDay.EndTime
         if (
            touchingAbsences.length < 1 ||
            touchingAbsences.every(date => outsideWorkHours(date, currDate))
         )
            return 0

         // Exit point if the absence this day counts as work time
         if (
            touchingAbsences.every(
               dat => Object.values(absenceCodes).find(abs => abs.code === dat.AbsenceType)?.isWork
            )
         )
            return 1

         const now = new Date(Date.now())

         // Exit point if currently doing health care
         if (
            pushExercise &&
            touchingAbsences.find(
               dat =>
                  healthCareAbsenceTypes.includes(dat.AbsenceType) &&
                  new Date(dat.StartDate) < now &&
                  new Date(dat.EndDate) > now
            )
         )
            return 5

         // Exit point if there is a clear full absence this day that does not count as work
         if (
            touchingAbsences.some(
               dat =>
                  fullAbsence(dat, currDate) ||
                  (ongoingAbsence(dat, currDate, now) &&
                     !Object.values(absenceCodes).find(abs => abs.code === dat.AbsenceType).isWork)
            )
         ) {
            return 2
         }

         // Final check gauges expected presence and if today's absence is over
         return touchingAbsences.filter(dat =>
            !remainingPresence(dat, currDate, now) && dat.EndDate
               ? new Date(dat.EndDate) >= new Date(setDateToStartOfWorkDay(dat.EndDate, workDay))
               : !(
                    dat.ApproximateDays &&
                    calcThreshholdProximity(dat.StartDate, dat.ApproximateDays, currDate) < 1
                 )
         ).length > 0
            ? 2
            : 0
      },
      [
         getTouchingAbsences,
         getTouchingRequested,
         holidays,
         healthCareAbsenceTypes,
         pushExercise,
         props.showRequested,
         showRequestedAbsencesMainOverview,
         workDay,
         calcThreshholdProximity,
         fullAbsence,
         ongoingAbsence,
         outsideWorkHours,
         remainingPresence,
      ]
   )

   const notSameDay = (start, end) => {
      return (
         start.getDate() !== end.getDate() ||
         start.getMonth() !== end.getMonth() ||
         start.getYear() !== end.getYear()
      )
   }

   const getTooltip = useCallback(
      (severity, indexOfDate, id, absences, weekInfo) => {
         if (severity < 1) return ''

         if (severity === 3) {
            let currDate = dateWithoutTime(new Date(weekInfo.weekDates[indexOfDate]))
            let holidayEntry = isHoliday(currDate, holidays ? holidays : [])
            let tip = holidayEntry.Name
            return [<Typography key={`${indexOfDate}-${id}`}>{tip}</Typography>]
         }

         if (severity === 4) {
            let touchingRequested = getTouchingRequested(indexOfDate, id, weekInfo)

            return touchingRequested.map(req => (
               <Typography key={`${req._id}`}>
                  {t('requestFor')} {t(req.AbsenceIntervals[0].Type)}
                  {req.AbsenceIntervals[0].Type !== 'LEGAL'
                     ? req.CommentByUser
                        ? ` ${t('withComment')} "${req.CommentByUser}"`
                        : ''
                     : ''}
               </Typography>
            ))
         }

         if (!absences) absences = getTouchingAbsences(indexOfDate, id, weekInfo)
         if (absences.length < 1) return ''

         const thisDate = dateWithoutTime(weekInfo.weekDates[indexOfDate])
         let tomorrow = moment()
         do {
            tomorrow.add(1, 'd')
         } while (
            tomorrow.day() === 0 ||
            tomorrow.day() === 6 ||
            isHoliday(new Date(tomorrow), holidays)
         )

         let tooltip = []
         absences.forEach(abs => {
            let back =
               abs.ApproximateDays && !abs.EndDate
                  ? moment(abs.StartDate).add(abs.ApproximateDays, 'd')
                  : null
            if (back && back < thisDate) {
               if (severity < 1) return
               const lastRelevantDay = moment(back).add(1, 'd')
               if (lastRelevantDay < thisDate && tomorrow < thisDate) return
            }

            if (back && back < tomorrow) back = tomorrow

            const startDate = new Date(abs.StartDate)
            const endDate = new Date(abs.EndDate)

            let start =
               abs.EndDate && severity < 2
                  ? notSameDay(startDate, endDate)
                     ? moment(abs.StartDate).format('D/M HH:mm')
                     : moment(abs.StartDate).format('HH:mm')
                  : ''
            let end =
               abs.EndDate && severity < 2
                  ? notSameDay(startDate, endDate)
                     ? ' ~ ' + moment(abs.EndDate).format('D/M HH:mm')
                     : ' ~ ' + moment(abs.EndDate).format('HH:mm')
                  : ''
            tooltip.push(
               <Typography key={`${abs._id}-info`}>
                  {t(abs.AbsenceType)} {start}
                  {end}
               </Typography>
            )
            if (abs.PublicComment)
               tooltip.push(<Typography key={`${abs._id}-public`}>{abs.PublicComment}</Typography>)
            if (
               abs.PrivateComment &&
               (props.userInfo.admin || props.userInfo.backofficeAdmin || props.userInfo.manager)
            )
               tooltip.push(
                  <Typography key={`${abs._id}-private`}>{abs.PrivateComment}</Typography>
               )
            if (
               abs.userComment &&
               (props.userInfo.admin || props.userInfo.backofficeAdmin || props.userInfo.manager) &&
               abs.AbsenceType !== 'LEGAL'
            )
               tooltip.push(
                  <Typography key={`${abs._id}-userComment`}>{abs.userComment}</Typography>
               )
            if (back) {
               tooltip.push(
                  <Typography key={`${abs._id}-prelBack`}>
                     {t('prelBack')}{' '}
                     {severity === 1 && back.date() === weekInfo.weekDates[indexOfDate].getDate()
                        ? t('thisDay')
                        : back.format('D/M')}
                  </Typography>
               )
            }
         })
         return tooltip
      },
      [getTouchingAbsences, getTouchingRequested, holidays, props.userInfo.admin, t]
   )

   const isToday = useCallback(
      date => {
         if (date.getDate() !== today.getDate()) return false
         if (date.getMonth() !== today.getMonth()) return false
         if (date.getFullYear() !== today.getFullYear()) return false
         return true
      },
      [today]
   )

   const fetchUsers = selectedWeeks => {
      fetchActiveUserOverview(userInfo, selectedWeeks)
         .then(res => {
            if (res.success) {
               setWrappedUserData(
                  res.data.map(wrapUser).sort((p1, p2) => sortByNickThenLabel(p1, p2))
               )
            } else {
               log.error('Failure to load data; try logging out and in again')
            }
         })
         .catch(error => log.error(error))
   }

   const fetchTeamsAndProjects = () => {
      fetchCustomerForAuthUser(userInfo).then(res => {
         if (res.success) {
            getProjectsForOverview(userInfo).then(response => {
               if (response.success) {
                  setProjects(response.data)
               }
            })

            if (res.customer && res.customer.Features && res.customer.Features.UseTeams) {
               getTeamsForOverview(userInfo).then(res => {
                  if (res.success) {
                     setTeams(res.data)
                     const userTeam = res.data.find(
                        team => team._id?.valueOf() === userInfo.Settings.TeamSelect
                     )

                     if (userTeam) {
                        setSelectedTeam(wrapTeam(userTeam))
                     } else {
                        setSelectedTeam(teamOptions[0])
                     }
                  }
               })
            } else {
               setSelectedTeam(null)
            }
         }
      })
   }

   const fetchHolidays = useCallback(async () => {
      try {
         const responseData = await fetchBankHolidays(userInfo)
         setHolidays(responseData)
      } catch (error) {
         log.error('Failure to load data; try logging out and in again: ')
      }
   }, [userInfo])

   const loadUserSettings = useCallback(() => {
      let userColourScheme = Object.values(colourSchemes).find(
         s => s.label === userInfo.Settings.ColourScheme
      )
      let userIconScheme = Object.values(iconSchemes).find(
         s => s.label === userInfo.Settings.IconScheme
      )
      if (userColourScheme) setColourState({ currentColourScheme: userColourScheme })
      if (userIconScheme) setIconState({ currentIconScheme: userIconScheme })
   }, [userInfo.Settings.ColourScheme, userInfo.Settings.IconScheme])

   const calculateWeeksByDate = useCallback(
      date => {
         const showWeeks = getNumberOfWeeksShown(userInfo)
         const newWeeks = getWeeks(new Date(date), showWeeks)
         const count = showWeeks
         const chunk = 5
         let sortedWeeks = []
         for (let i = 0; i < count; i++) {
            sortedWeeks.push({
               number: newWeeks.weekNumber[i],
               dates: newWeeks.weekDates.slice(chunk * i, chunk * i + chunk),
            })
         }
         return {
            weekDates: newWeeks.weekDates,
            weekNumber: newWeeks.weekNumber,
            weeksSorted: sortedWeeks,
         }
      },
      [userInfo]
   )

   const handleChangeDisplayDate = useCallback(
      date => {
         const newDate = new Date(date)
         setDisplayDate(newDate)
         const weekInfo = calculateWeeksByDate(newDate)
         setWeekState(weekInfo)
      },
      [calculateWeeksByDate]
   )

   return (
      <Fragment>
         <Paper
            style={{
               height: '100%',
               minHeight: 600,
               overflow: 'hidden',
               boxShadow: 'none',
            }}
            className="contentPaper"
         >
            <Header
               weekState={weekState}
               handleChangeDisplayDate={handleChangeDisplayDate}
               isFilterVisible={isFilterVisible}
               setIsFilterVisible={setIsFilterVisible}
               today={today}
               holidays={holidays}
               displayDate={displayDate}
               showRequested={props.showRequested}
               filterArray={filterArray}
               setFilterArray={setFilterArray}
            />
            <div
               className={'contentWrapper'}
               style={{
                  overflowX: 'auto',
                  overflowY: 'hidden',
                  height: 'calc(100% - 80px)',
                  padding: '4px 20px 20px 20px',
                  position: 'relative',
               }}
            >
               <TableOverview
                  weekState={weekState}
                  showSpinner={showSpinner}
                  filterArray={filterArray}
                  displayDate={displayDate}
                  handleChangeDisplayDate={handleChangeDisplayDate}
                  teamOptions={teamOptions}
                  wrappedUserData={wrappedUserData}
                  isToday={isToday}
                  setAbsenceDialogState={setAbsenceDialogState}
                  colourState={colourState}
                  iconState={iconState}
                  getTouchingAbsences={getTouchingAbsences}
                  absenceSeverity={absenceSeverity}
                  getTooltip={getTooltip}
                  projects={projects}
                  teams={teams}
               />

               {isFilterVisible && (
                  <Filters
                     reference={ref}
                     filterArray={filterArray}
                     setIsFilterVisible={setIsFilterVisible}
                     teamOptions={teamOptions}
                     projectOptions={projectOptions}
                     setFilterArray={setFilterArray}
                  />
               )}
            </div>
         </Paper>

         <AbsenceDialog
            absences={absenceDialogState.absences}
            user={absenceDialogState.user}
            show={absenceDialogState.show}
            date={absenceDialogState.date}
            t={t}
            handleClose={() => setAbsenceDialogState({ show: false })}
         />
      </Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      workDay: state.workDay,
      pushExercise: state.pushExercise,
   }
}

export default withTranslation()(
   connect(mapStateToProps, { logout, persistUserInfo })(AbsenceOverview)
)
