import makeStyles from '@mui/styles/makeStyles'
import { Tooltip, Typography, Button, IconButton, Box, Badge, Paper } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { FilterList as FilterListIcon, Event as EventIcon } from '@mui/icons-material'
import DatePicker from 'react-datepicker'
import React, { forwardRef, useState } from 'react'
import moment from 'moment/min/moment-with-locales'
import { highlightDaysInRed } from '../../../util/helpers'
import { withStyles } from '@mui/styles'
import TimesheetReportModal from './modal/TimesheetReportModal'
import { createExcelYearly } from '../../../Api'
const useStyles = makeStyles({
   filterIconExpanded: {
      color: '#fff',
      backgroundColor: '#0083bb',
      outline: 'none !important',
      '&:hover': {
         color: '#fff',
         backgroundColor: '#0083bb',
      },
   },
   datepickerPopper: {
      zIndex: 3,
   },
})
const StyledButton = withStyles({
   root: {
      backgroundColor: '#fff',
      padding: '3px 14px',
      color: '#008DC6',
      '&:hover': {
         backgroundColor: '#2D3A73',
         color: '#fff',
         border: '2px solid #2D3A73',
      },
   },
})(Button)

const DatePickerCustomIcon = forwardRef(({ value, onClick }, ref) => (
   <IconButton sx={{ color: '#454545' }} onClick={onClick} ref={ref} size="small">
      <EventIcon />
   </IconButton>
))

const TimesheetManagementHeader = React.memo(
   ({
      chosenDateWeek,
      changeDate,
      chosenDate,
      isFilterVisible,
      setIsFilterVisible,
      today,
      holidays,
      t,
      teamProjectFilters,
      userFilters,
      userInfo,
   }) => {
      const generateReport = async values => {
         const reportObj = {}

         try {
            const url = await createExcelYearly(reportObj, userInfo)
            window.open(url.data.folderUrl, '_blank')
         } catch (error) {
            console.error('Error generating report:', error)
         }
      }
      const classes = useStyles()
      const [isReportModalOpen, setIsReportModalOpen] = useState(false)
      moment.locale(t('momentLocale'))

      const setThisWeek = () => {
         const date = new Date(Date.now())
         date.setDate(date.getDate() - 7)
         changeDate(date)
      }

      const thisWeekButtonDisabled = () => {
         const date = new Date(Date.now())
         date.setDate(date.getDate() - 7)
         return moment(chosenDate).isSame(date, 'day')
      }

      const datePickerHandler = newDate => {
         const date = new Date(newDate)
         date.setDate(date.getDate() - 7)
         changeDate(date)
      }

      const badgeSum = (teamProjectFilters, userFilters) => {
         let totalSum = teamProjectFilters.length + userFilters.skills.length
         if (userFilters.onlyFreeEmployees || userFilters.missingLog) {
            totalSum += 1
         }
         return totalSum
      }

      const toggleReportModal = () => {
         setIsReportModalOpen(prev => !prev)
      }
      const returnWeekLabel = () => {
         const chosenWeekNumber = chosenDateWeek.weekNumber
         const firstDateOfTheChosenWeek = moment(chosenDateWeek.weekDates[0]).format('Do MMM')
         const lastDateOfTheChosenWeek = moment(
            chosenDateWeek.weekDates[chosenDateWeek.weekDates.length - 1]
         ).format('Do MMM')
         const year = moment(chosenDateWeek.weekDates[0]).year()

         return (
            <Box sx={{ display: 'flex', justifyContent: 'space-around', width: 300 }}>
               <Typography
                  style={{
                     display: 'flex',
                     fontWeight: 700,
                     color: '#454545',
                     textAlign: 'center',
                  }}
               >
                  {t('Week')} {chosenWeekNumber[0]} - {chosenWeekNumber[1]}
               </Typography>
               <Typography>|</Typography>
               <Typography style={{ display: 'inline', fontWeight: 700 }}>
                  {`${firstDateOfTheChosenWeek} - ${lastDateOfTheChosenWeek} ${year}`}
               </Typography>
            </Box>
         )
      }
      return (
         <>
            <Paper
               component={Box}
               display="flex"
               justifyContent="space-between"
               alignItems="center"
               sx={{
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                  boxShadow: 'none',
                  padding: '12px 25px 6px ',
               }}
            >
               <Typography style={{ fontWeight: 600 }}>{t('personnel')}</Typography>
               {returnWeekLabel()}
               <Box>
                  {(userInfo.EmployeeID === 1018 || userInfo.EmployeeID === 1021) && (
                     <StyledButton
                        size="small"
                        sx={{ borderRadius: '4px', border: '2px solid' }}
                        onClick={toggleReportModal}
                     >
                        REPORT
                     </StyledButton>
                  )}
                  <StyledButton
                     size="small"
                     sx={{ borderRadius: '4px', border: '2px solid' }}
                     onClick={generateReport}
                  >
                     REPORT 2023
                  </StyledButton>
                  <Tooltip
                     arrow
                     componentsProps={{
                        tooltip: {
                           sx: {
                              bgcolor: '#8ACCE6',
                              '& .MuiTooltip-arrow': {
                                 color: '#8ACCE6',
                              },
                           },
                        },
                     }}
                     title={moment(today).format('DD.MM.YYYY, dddd')}
                  >
                     <span>
                        <StyledButton
                           size="small"
                           sx={{ marginLeft: 2, borderRadius: '4px', border: '2px solid' }}
                           onClick={setThisWeek}
                           disabled={thisWeekButtonDisabled()}
                        >
                           {t('Today')}
                        </StyledButton>
                     </span>
                  </Tooltip>

                  <Tooltip
                     arrow
                     componentsProps={{
                        tooltip: {
                           sx: {
                              bgcolor: '#8ACCE6',
                              '& .MuiTooltip-arrow': {
                                 color: '#8ACCE6',
                              },
                           },
                        },
                     }}
                     title={t('filterButton')}
                  >
                     <IconButton
                        sx={{
                           marginLeft: 2,
                           marginRight: 2,
                           color: '#454545',
                           '&:hover ': {
                              backgroundColor: '#D0EBF6',
                              borderRadius: '8px',
                           },
                        }}
                        className={isFilterVisible ? classes.filterIconExpanded : classes.noOutline}
                        onClick={() => setIsFilterVisible(currentState => !currentState)}
                        size="small"
                     >
                        <Badge
                           badgeContent={badgeSum(teamProjectFilters, userFilters)}
                           overlap="rectangular"
                           color="secondary"
                        >
                           <FilterListIcon />
                        </Badge>
                     </IconButton>
                  </Tooltip>
                  <DatePicker
                     popperClassName={classes.datepickerPopper}
                     popperPlacement="bottom-end"
                     selected={chosenDate}
                     onChange={datePickerHandler}
                     dateFormat="d/M"
                     locale={t('datepickerLocale')}
                     highlightDates={highlightDaysInRed(holidays ?? [])}
                     showWeekNumbers
                     yearDropdownItemNumber={3}
                     customInput={<DatePickerCustomIcon />}
                  />
               </Box>
            </Paper>
            {isReportModalOpen && (
               <Box>
                  <TimesheetReportModal
                     isReportModalOpen={isReportModalOpen}
                     toggleReportModal={toggleReportModal}
                  />
               </Box>
            )}
         </>
      )
   }
)

export default withTranslation()(TimesheetManagementHeader)
