import makeStyles from '@mui/styles/makeStyles'
import { Tooltip, Typography, Button, IconButton, Box, Badge, Paper } from '@mui/material'
import { highlightDaysInRed } from '../../util/helpers'
import { withTranslation } from 'react-i18next'
import FilterListIcon from '@mui/icons-material/FilterList'
import LayersClearIcon from '@mui/icons-material/LayersClear'
import EventIcon from '@mui/icons-material/Event'
import DatePicker from 'react-datepicker'
import React, { forwardRef } from 'react'
import moment from 'moment/min/moment-with-locales'

const useStyles = makeStyles({
   filterIconExpanded: {
      color: '#fff',
      backgroundColor: '#0083bb',
      outline: 'none !important',
      '&:hover': {
         color: '#fff',
         backgroundColor: '#0083bb',
      },
   },
})

const DatePickerCustomIcon = forwardRef(({ value, onClick }, ref) => (
   <IconButton onClick={onClick} ref={ref} size="large">
      <EventIcon />
   </IconButton>
))

const Header = ({
   weekState,
   handleChangeDisplayDate,
   isFilterVisible,
   setIsFilterVisible,
   today,
   holidays,
   displayDate,
   showRequested,
   filterArray,
   t,
   setFilterArray,
}) => {
   const classes = useStyles()

   moment.locale(t('momentLocale'))

   const clearAllFilters = () => {
      const newFilterObject =
         localStorage.getItem('hrAppliedFilters') &&
         !Array.isArray(JSON.parse(localStorage.getItem('hrAppliedFilters')))
            ? JSON.parse(localStorage.getItem('hrAppliedFilters'))
            : {}
      if (Object.keys(newFilterObject).length) {
         delete newFilterObject.overview
         if (Object.keys(newFilterObject).length)
            localStorage.setItem('hrAppliedFilters', JSON.stringify(newFilterObject))
         else localStorage.removeItem('hrAppliedFilters')
      }
      setFilterArray([])
   }

   return (
      <Paper
         component={Box}
         display="flex"
         justifyContent="space-between"
         py={2.5}
         px={3}
         alignItems="center"
         style={{ height: 80 }}
      >
         <Typography style={{ fontWeight: 600 }}>{t('personnel')}</Typography>
         {weekState.weekDates && (
            <Typography>
               {moment(weekState.weekDates[0]).format('YYYY') !==
               moment(weekState.weekDates[weekState.weekDates.length - 1]).format('YYYY') ? (
                  <>
                     {' '}
                     <b>{moment(weekState.weekDates[0]).format('Do MMMM')}</b>{' '}
                     {moment(weekState.weekDates[0]).format('YYYY')}{' '}
                  </>
               ) : (
                  <b>{moment(weekState.weekDates[0]).format('Do MMMM')}</b>
               )}{' '}
               -{' '}
               <b>
                  {moment(weekState.weekDates[weekState.weekDates.length - 1]).format('Do MMMM')}
               </b>{' '}
               {moment(weekState.weekDates[weekState.weekDates.length - 1]).format('YYYY')}
            </Typography>
         )}
         <Box>
            <Tooltip arrow title={moment(today).format('DD.MM.YYYY, dddd')}>
               <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: 2 }}
                  onClick={() => handleChangeDisplayDate(today, true)}
                  className={classes.noOutline}
               >
                  {t('Today')}
               </Button>
            </Tooltip>
            <Tooltip arrow title={t('clearAllFilters')}>
               <span>
                  <IconButton
                     onClick={() => clearAllFilters()}
                     style={{ marginLeft: '10px' }}
                     disabled={!filterArray.length}
                     size="large"
                  >
                     <LayersClearIcon />
                  </IconButton>
               </span>
            </Tooltip>
            <Tooltip arrow title={t('filterPersonnelPlaceholder')}>
               <IconButton
                  style={{ margin: '0 6px' }}
                  className={isFilterVisible ? classes.filterIconExpanded : classes.noOutline}
                  onClick={() => setIsFilterVisible(currentState => !currentState)}
                  size="medium"
               >
                  <Badge badgeContent={filterArray.length} overlap="rectangular" color="secondary">
                     <FilterListIcon />
                  </Badge>
               </IconButton>
            </Tooltip>
            <DatePicker
               popperPlacement="bottom-end"
               className="datepickerMini"
               selected={displayDate}
               onChange={date => handleChangeDisplayDate(date, true)}
               dateFormat="d/M"
               locale={t('datepickerLocale')}
               highlightDates={highlightDaysInRed(holidays ?? [])}
               showWeekNumbers
               yearDropdownItemNumber={3}
               customInput={<DatePickerCustomIcon />}
            />
         </Box>
      </Paper>
   )
}

export default withTranslation()(Header)
