import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormControl, Input } from '@mui/material'
import Button from '@mui/material/Button'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import Moment from 'react-moment'
import {
   deleteAbsence,
   deleteRecurrence,
   fetchBankHolidays,
   postNewAbsence,
   updateAbsence,
   updateRecurrence,
} from '../Api'
import {
   highlightDaysInRed,
   sortByEndDate,
   sortByStartDate,
   useEffectOnlyOnce,
} from '../util/helpers'
import { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-US'
import bg from 'date-fns/locale/bg'
import log from '../util/log'
import { withTranslation } from 'react-i18next'
import { setDateToStartOfWorkDay, setDateToEndOfWorkDay } from 'novarto-time'

registerLocale('sv', sv)
registerLocale('en', en)
registerLocale('bg', bg)

const RecurrenceRow = ({ comment = '', ...props }) => {
   const { t, userInfo, workDay } = props

   const [holidays, setHolidays] = React.useState([])

   const [times, setTimes] = React.useState({
      now: Date().now,
      past: new Date().setMonth(new Date().getMonth() - 1),
      future: new Date().setMonth(new Date().getMonth() + 3),
      dayStart: new Date(setDateToStartOfWorkDay(new Date(), workDay)),
      dayEnd: new Date(setDateToEndOfWorkDay(new Date(), workDay)),
      periodEnd: new Date(props.recurrence.PeriodEndDate),
   })

   const isFlexOrDistance =
      props.recurrence.AbsenceType === 'FLEX' ||
      props.recurrence.AbsenceType === 'DISTANS' ||
      props.recurrence.AbsenceTypes === 'FRISKVARD'

   const fetchHolidays = React.useCallback(async () => {
      await fetchBankHolidays(userInfo).then(res => {
         if (res.success === 'false') {
            log.error('Failure to load data; try logging out and in again: ')
         } else {
            setHolidays(res)
         }
      })
   }, [userInfo])

   useEffectOnlyOnce(() => {
      async function loadData() {
         await fetchHolidays()
      }
      loadData()
   })

   const handleDeleteRecurrence = async () => {
      try {
         await deleteRecurrence(props.recurrence.RecurrenceID, props.absences, props.userInfo).then(
            response => props.popupMessage(t('scheduledAbsenceDeleted'), { quiet: true }),
            response => props.popupMessage(`${t('errorText1')}!`, { severity: 'error' })
         )
         props.reload()
      } catch (err) {
         props.popupMessage(`${t('errorText1')}: ${err}`, { severity: 'error' })
      }
   }

   const handleDeleteAbsence = async absence => {
      try {
         if (props.absences.filter(abs => abs._id !== absence._id).length < 1) {
            handleDeleteRecurrence()
         } else {
            await deleteAbsence(props.userInfo, absence._id).then(
               response => props.popupMessage(t('absenceDeleted'), { quiet: true }),
               response => props.popupMessage(`${t('errorText1')}!`, { severity: 'error' })
            )
            props.reload()
         }
      } catch (err) {
         log.error(err)
         props.popupMessage(`${t('errorText1')}: ${err}`, { severity: 'error' })
      }
   }

   const prolongPeriod = async () => {
      try {
         let lastAbsence = props.absences.sort(sortByEndDate).reverse()[0]
         const incrementedDate = date => {
            let newDate = new Date(date)
            if (props.recurrence.Periodicity === 'monthly') {
               newDate.setMonth(newDate.getMonth() + 1)
            } else {
               newDate.setDate(
                  newDate.getDate() +
                     (props.recurrence.Periodicity === 'weekly'
                        ? 7
                        : newDate.getDay() === 5
                        ? 3
                        : 1)
               )
            }
            return newDate
         }
         await postNewAbsence(props.userInfo, {
            EmployeeID: lastAbsence.EmployeeID,
            AbsenceType: lastAbsence.AbsenceType,
            StartDate: incrementedDate(lastAbsence.StartDate),
            EndDate: incrementedDate(lastAbsence.EndDate),
            RecurrenceID: lastAbsence.RecurrenceID,
            PublicComment: lastAbsence.PublicComment,
            PrivateComment: lastAbsence.PrivateComment,
         })
         if (times.periodEnd < incrementedDate(lastAbsence.EndDate)) {
            let newPeriodEnd = incrementedDate(times.periodEnd)
            await updateRecurrence(
               { RecurrenceID: props.recurrence.RecurrenceID, PeriodEndDate: newPeriodEnd },
               props.userInfo
            )
            setTimes({ ...times, periodEnd: newPeriodEnd })
         }
         props.popupMessage(t('absenceAdded'), { quiet: true })
         props.reload()
      } catch (err) {
         log.error(err)
         props.popupMessage(`${t('errorText1')}: ${err}`, { severity: 'error' })
      }
   }

   const startDateChanged = async (absence, date) => {
      let start = new Date(date)
      if (start > absence.EndDate) {
         this.setAlert(this.props.t('invalidIntervalDates'), 'error')
         return
      }
      if (start.getHours() === 0) start.setHours(new Date(absence.StartDate).getHours())

      try {
         await updateAbsence(props.userInfo, { id: absence._id, StartDate: start }).then(
            response => props.popupMessage(t('Updated!'), { quiet: true }),
            response => props.popupMessage(`${t('errorText1')}!`, { severity: 'error' })
         )
         props.reload()
      } catch (err) {
         log.error(err)
         props.popupMessage(`${t('errorText1')}: ${err}`, { severity: 'error' })
      }
   }

   const endDateChanged = async (absence, date) => {
      let end = new Date(date)
      if (end < absence.StartDate) {
         this.setAlert(this.props.t('invalidIntervalDates'), 'error')
         return
      }
      if (end.getHours() === 0) end.setHours(new Date(absence.EndDate).getHours())

      try {
         await updateAbsence(props.userInfo, { id: absence._id, EndDate: end }).then(
            response => props.popupMessage(t('Updated!'), { quiet: true }),
            response => props.popupMessage(`${t('errorText1')}!`, { severity: 'error' })
         )
         await updateRecurrence(
            { RecurrenceID: props.recurrence.RecurrenceID, PeriodEndDate: end },
            props.userInfo
         )
         props.reload()
      } catch (err) {
         log.error(err)
         props.popupMessage(`${t('errorText1')}: ${err}`, { severity: 'error' })
      }
   }

   const dateChanged = async (absence, date) => {
      let start = new Date(date)
      let end = new Date(date)
      start.setHours(new Date(absence.StartDate).getHours())
      start.setMinutes(new Date(absence.StartDate).getMinutes())
      end.setHours(new Date(absence.EndDate).getHours())
      end.setMinutes(new Date(absence.EndDate).getMinutes())
      if (end < start) {
         this.setAlert(this.props.t('invalidIntervalDates'), 'error')
         return
      }

      try {
         await updateAbsence(props.userInfo, {
            id: absence._id,
            StartDate: start,
            EndDate: end,
         }).then(
            response => props.popupMessage(t('Updated!'), { quiet: true }),
            response => props.popupMessage(`${t('errorText1')}!`, { severity: 'error' })
         )
         await updateRecurrence(
            { RecurrenceID: props.recurrence.RecurrenceID, PeriodEndDate: end },
            props.userInfo
         )
         props.reload()
      } catch (err) {
         log.error(err)
         props.popupMessage(`${t('errorText1')}: ${err}`, { severity: 'error' })
      }
   }

   const startTimeChanged = async (absence, date) => {
      if (date < new Date(setDateToStartOfWorkDay(date, workDay))) {
         date = new Date(setDateToStartOfWorkDay(date, workDay))
      }
      try {
         await updateAbsence(props.userInfo, { id: absence._id, StartDate: date }).then(
            response => props.popupMessage(t('Updated!'), { quiet: true }),
            response => props.popupMessage(`${t('errorText1')}!`, { severity: 'error' })
         )
         props.reload()
      } catch (err) {
         log.error(err)
         props.popupMessage(`${t('errorText1')}: ${err}`, { severity: 'error' })
      }
   }

   const endTimeChanged = async (absence, date) => {
      if (date > new Date(setDateToEndOfWorkDay(date, workDay))) {
         date = new Date(setDateToEndOfWorkDay(date, workDay))
      }
      try {
         await updateAbsence(props.userInfo, { id: absence._id, EndDate: date }).then(
            response => props.popupMessage(t('Updated!'), { quiet: true }),
            response => props.popupMessage(`${t('errorText1')}!`, { severity: 'error' })
         )
         props.reload()
      } catch (err) {
         log.error(err)
         props.popupMessage(`${t('errorText1')}: ${err}`, { severity: 'error' })
      }
   }

   const changePublicComment = async message => {
      try {
         await Promise.all(
            props.absences.map(absence =>
               updateAbsence(props.userInfo, {
                  id: absence._id,
                  PublicComment: message,
               })
            )
         ).then(task =>
            task.forEach(response => {
               log.debug(response)
               if (response.data.error) throw response
            })
         )
         props.popupMessage(t('Updated!'), { quiet: true })
         props.reload()
      } catch (err) {
         log.error(err)
         props.popupMessage(`${t('errorText1')}: ${err}`, { severity: 'error' })
      }
   }

   const changePrivateComment = async message => {
      try {
         await Promise.all(
            props.absences.map(absence =>
               updateAbsence(props.userInfo, {
                  id: absence._id,
                  PrivateComment: message,
               })
            )
         ).then(task =>
            task.forEach(response => {
               log.debug(response)
               if (response.data.error) throw response
            })
         )
         props.popupMessage(t('Updated!'), { quiet: true })
         props.reload()
      } catch (err) {
         log.error(err)
         props.popupMessage(`${t('errorText1')}: ${err}`, { severity: 'error' })
      }
   }

   const getBackgroundColor = recurrence => {
      let colours = [
         ['lemonchiffon', 'lightskyblue', 'aquamarine', 'hotpink'],
         ['gold', 'deepskyblue', 'chartreuse', 'deeppink'],
         ['cornsilk', 'darkturquoise', 'cyan', 'lightsalmon'],
         ['peachpuff', 'cornflowerblue', 'lime', 'plum'],
      ]
      let index =
         recurrence.Periodicity === 'monthly'
            ? 3
            : recurrence.Periodicity === 'weekly'
            ? 2
            : recurrence.Periodicity === 'daily'
            ? 1
            : 0
      return colours[
         recurrence.AbsenceType === 'FLEX'
            ? 0
            : recurrence.AbsenceType === 'DISTANS'
            ? 1
            : recurrence.AbsenceType === 'FRISKVARD'
            ? 3
            : 2
      ][index]
   }

   if (
      props.recurrence.PeriodEndDate < new Date() ||
      !props.absences.some(abs => new Date(abs.EndDate) > new Date())
   ) {
      return null
   }

   return (
      <React.Fragment>
         <Accordion
            style={
               props.recurrence.CreatedByAdmin
                  ? { backgroundColor: 'lightskyblue' }
                  : { backgroundColor: getBackgroundColor(props.recurrence) }
            }
         >
            <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               aria-controls={'recurrence-content-' + props.recurrence.RecurrenceID}
               id={'recurrence-header-' + props.recurrence.RecurrenceID}
            >
               <Typography variant="body2">
                  <b>
                     {props.recurrence.AbsenceType
                        ? t(props.recurrence.AbsenceType)
                        : t(props.absences[0].AbsenceType)}
                  </b>
                  {props.recurrence.Periodicity !== 'once'
                     ? (props.recurrence.Periodicity === 'monthly'
                          ? ` ${t('labelMonthly').toLowerCase()}`
                          : props.recurrence.Periodicity === 'weekly'
                          ? ` ${t('labelWeekly').toLowerCase()}`
                          : props.recurrence.Periodicity === 'daily'
                          ? ` ${t('labelDaily').toLowerCase()}`
                          : '') + ` ${t('until')} `
                     : ` ${t('at')} `}
                  <b>
                     <Moment date={props.recurrence.PeriodEndDate} format="YYYY-MM-DD" />
                  </b>
                  {props.absences[0].PublicComment ? ` ${t('withPublicMessage')} ` : ''}
                  <b>{props.absences[0].PublicComment}</b>
                  {props.absences[0].PrivateComment ? ` ${t('withPrivateMessage')} ` : ''}
                  <b>{props.absences[0].PrivateComment}</b>
               </Typography>
            </AccordionSummary>
            <AccordionDetails>
               <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={2}
               >
                  <Grid
                     container
                     direction="row"
                     justifyContent="flex-start"
                     alignItems="center"
                     spacing={1}
                  >
                     <Grid item xs={3} style={{ marginLeft: 10 }}>
                        <FormControl style={{ padding: 10, minWidth: 400 }} autoComplete="off">
                           <Input
                              placeholder={t('changePublicMessage')}
                              inputProps={{ 'aria-label': 'description' }}
                              onKeyPress={event => {
                                 if (event.key === 'Enter') changePublicComment(event.target.value)
                              }}
                           />
                        </FormControl>
                        <FormControl style={{ padding: 10, minWidth: 400 }} autoComplete="off">
                           <Input
                              placeholder={t('changePrivateMessage')}
                              inputProps={{ 'aria-label': 'description' }}
                              onKeyPress={event => {
                                 if (event.key === 'Enter') changePrivateComment(event.target.value)
                              }}
                           />
                        </FormControl>
                     </Grid>
                  </Grid>

                  <Grid
                     container
                     direction="row"
                     justifyContent="flex-start"
                     alignItems="center"
                     spacing={1}
                  >
                     <Grid item>
                        <Typography variant="body2" style={{ padding: 10 }}>
                           {t('changeSingularAbsences')}
                        </Typography>
                     </Grid>
                  </Grid>
                  <Grid
                     container
                     direction="column"
                     justifyContent="flex-start"
                     alignItems="flex-start"
                     spacing={1}
                  >
                     {props.absences
                        .filter(abs => !abs.Locked)
                        .sort((a1, a2) => sortByStartDate(a1, a2))
                        .map((absence, index) => (
                           <Grid
                              key={'interval_' + index + '_' + props.recurrence.RecurrenceID}
                              item
                              xs
                           >
                              <div style={{ marginLeft: 10 }}>
                                 <div variant="body2">
                                    {moment.duration(
                                       moment(absence.EndDate) - moment(absence.StartDate)
                                    ) < moment.duration(12, 'hours') ? (
                                       <Grid
                                          container
                                          justifyContent="flex-start"
                                          align-items="flex-start"
                                          item
                                          xs
                                       >
                                          <FontAwesomeIcon
                                             style={{ marginRight: 15, marginTop: 4 }}
                                             icon={faTrash}
                                             onClick={() => handleDeleteAbsence(absence)}
                                          />
                                          <Grid style={{ marginRight: 5 }} item xs>
                                             <DatePicker
                                                className="datepicker"
                                                dateFormat="d/M"
                                                locale="sv"
                                                highlightDates={highlightDaysInRed(holidays)}
                                                showWeekNumbers
                                                minDate={Date.now()}
                                                openToDate={new Date(absence.StartDate)}
                                                placeholderText={moment(absence.StartDate).format(
                                                   'ddd D/M'
                                                )}
                                                onChange={date => dateChanged(absence, date)}
                                             />
                                          </Grid>
                                          <Grid style={{ marginRight: 5 }} item xs>
                                             <DatePicker
                                                className="datepickerMini"
                                                showTimeSelect="true"
                                                showTimeSelectOnly
                                                timeIntervals={isFlexOrDistance ? '30' : '60'}
                                                highlightDates={highlightDaysInRed(holidays)}
                                                timeFormat="HH:mm"
                                                locale="sv"
                                                minTime={new Date(
                                                   setDateToStartOfWorkDay(new Date(), workDay)
                                                ).setHours(
                                                   new Date(
                                                      setDateToStartOfWorkDay(new Date(), workDay)
                                                   ).getHours() - 1
                                                )}
                                                maxTime={new Date(absence.EndDate)}
                                                openToDate={new Date(absence.StartDate)}
                                                placeholderText={moment(absence.StartDate).format(
                                                   'HH:mm'
                                                )}
                                                onChange={date => startTimeChanged(absence, date)}
                                             />
                                          </Grid>
                                          ~
                                          <Grid style={{ marginLeft: 5 }} item xs>
                                             <DatePicker
                                                className="datepickerMini"
                                                showTimeSelect="true"
                                                showTimeSelectOnly
                                                timeIntervals={isFlexOrDistance ? '30' : '60'}
                                                highlightDates={highlightDaysInRed(holidays)}
                                                dateFormat="d/M HH:mm"
                                                timeFormat="HH:mm"
                                                locale="sv"
                                                minTime={new Date(absence.StartDate)}
                                                maxTime={times.dayEnd}
                                                openToDate={new Date(absence.EndDate)}
                                                placeholderText={moment(absence.EndDate).format(
                                                   'HH:mm'
                                                )}
                                                onChange={date => endTimeChanged(absence, date)}
                                             />
                                          </Grid>
                                          <Typography
                                             style={{ paddingLeft: 8, paddingTop: 2 }}
                                             variant="caption"
                                             align="center"
                                          >
                                             {new Date(absence.EndDate) < Date.now()
                                                ? t('passe')
                                                : ''}
                                          </Typography>
                                       </Grid>
                                    ) : (
                                       <Grid
                                          container
                                          justifyContent="flex-start"
                                          align-items="flex-start"
                                          item
                                          xs
                                       >
                                          <FontAwesomeIcon
                                             style={{ marginRight: 15, marginTop: 4 }}
                                             icon={faTrash}
                                             onClick={() => handleDeleteAbsence(absence)}
                                          />
                                          <Grid style={{ marginRight: 5 }} item xs>
                                             <DatePicker
                                                className="datepickerExtend"
                                                dateFormat="yyyy-MM-dd HH:mm"
                                                locale="sv"
                                                showTimeSelect
                                                timeIntervals={isFlexOrDistance ? '30' : '60'}
                                                highlightDates={highlightDaysInRed(holidays)}
                                                showWeekNumbers
                                                minDate={Date.now()}
                                                maxDate={new Date(absence.EndDate)}
                                                minTime={times.dayStart}
                                                maxTime={times.dayEnd}
                                                openToDate={new Date(absence.StartDate)}
                                                placeholderText={moment(absence.StartDate).format(
                                                   'YYYY-MM-DD HH:mm'
                                                )}
                                                onChange={date => startDateChanged(absence, date)}
                                             />
                                          </Grid>
                                          ~
                                          <Grid style={{ marginRight: 5 }} item xs>
                                             <DatePicker
                                                className="datepickerExtend"
                                                dateFormat="yyyy-MM-dd HH:mm"
                                                locale="sv"
                                                showTimeSelect
                                                timeIntervals={isFlexOrDistance ? '30' : '60'}
                                                highlightDates={highlightDaysInRed(holidays)}
                                                showWeekNumbers
                                                minDate={new Date(absence.StartDate)}
                                                minTime={times.dayStart}
                                                maxTime={times.dayEnd}
                                                openToDate={new Date(absence.EndDate)}
                                                placeholderText={moment(absence.EndDate).format(
                                                   'YYYY-MM-DD HH:mm'
                                                )}
                                                onChange={date => endDateChanged(absence, date)}
                                             />
                                          </Grid>
                                          <Typography
                                             style={{ paddingLeft: 8, paddingTop: 2 }}
                                             variant="caption"
                                             align="center"
                                          >
                                             {new Date(absence.EndDate) < Date.now()
                                                ? t('passe')
                                                : ''}
                                          </Typography>
                                       </Grid>
                                    )}
                                 </div>
                              </div>
                           </Grid>
                        ))}
                  </Grid>
                  <div className="spacer20"></div>

                  <Grid
                     container
                     direction="row"
                     justifyContent="flex-end"
                     alignItems="center"
                     spacing={1}
                  >
                     <Grid item>
                        <Button
                           onClick={handleDeleteRecurrence}
                           color="secondary"
                           size="small"
                           variant="contained"
                        >
                           {t('removeAll')}
                        </Button>
                     </Grid>
                     <Grid item>
                        {props.recurrence.Periodicity !== 'once' ? (
                           <Button
                              onClick={() => prolongPeriod()}
                              color="primary"
                              size="small"
                              variant="contained"
                           >
                              {t('addOne')}
                           </Button>
                        ) : (
                           ''
                        )}
                     </Grid>
                  </Grid>
               </Grid>
            </AccordionDetails>
         </Accordion>
      </React.Fragment>
   )
}

export default withTranslation()(RecurrenceRow)
