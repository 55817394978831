import { Box, Divider, Paper, Typography } from '@mui/material'
import { withTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import {
   PersonOutlineOutlined as PersonOutlineOutlinedIcon,
   DescriptionOutlined as DescriptionOutlinedIcon,
} from '@mui/icons-material'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
const StyledBox = styled(Box)({
   cursor: 'pointer',
   width: '400px',
   height: '166px',
   padding: '20px',
   borderRadius: '8px',
   border: '2.4px solid',
   gap: '16px',
   borderColor: '#008DC6',
   margin: '0 25px',
   '&:hover': {
      borderColor: '#2D3A73',
   },
})

const ReportsAndPlaning = ({ t }) => {
   const history = useHistory()

   const [hovered, setHovered] = useState({ firstBox: false, secondBox: false })
   const redirect = link => {
      history.push(link)
   }
   const handleMouseEnter = box => {
      setHovered({ ...hovered, [box]: true })
   }
   const handleMouseLeave = box => {
      setHovered({ ...hovered, [box]: false })
   }

   return (
      <Paper sx={{ width: '100%', height: '100%', boxShadow: 'none' }}>
         <Typography
            sx={{ textAlign: 'center', p: 5, color: '#3D3D3D', fontWeight: 700, fontSize: 25 }}
         >
            {t('chooseOfTheFollowing')}
         </Typography>
         <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <StyledBox
               onClick={() => redirect('/reportsAndPlaning/projectReport')}
               onMouseEnter={() => handleMouseEnter('firstBox')}
               onMouseLeave={() => handleMouseLeave('firstBox')}
               sx={{ backgroundColor: hovered.firstBox ? '#2D3A73' : '' }}
            >
               <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <DescriptionOutlinedIcon
                     sx={{
                        fontSize: 45,
                        mr: 1,
                        color: hovered.firstBox ? '#fff' : '#5AAED0',
                     }}
                  />
                  <Typography
                     sx={{
                        color: hovered.firstBox ? '#fff' : '#5AAED0',
                        fontWeight: 500,
                        fontSize: 24,
                     }}
                  >
                     {t('projectReport')}
                  </Typography>
               </Box>
               <Divider
                  sx={{
                     backgroundColor: hovered.firstBox ? '#fff' : '#5AAED0',
                     height: '0.5px',
                     borderRadius: '2px',
                     margin: '12px 0',
                  }}
               />
               <Box>
                  <Typography sx={{ fontSize: 14, color: hovered.firstBox ? '#fff' : '' }}>
                     {t('projectReportInfo')}
                  </Typography>
               </Box>
            </StyledBox>

            <StyledBox
               onClick={() => redirect('/reportsAndPlaning/resourcePlanning')}
               onMouseEnter={() => handleMouseEnter('secondBox')}
               onMouseLeave={() => handleMouseLeave('secondBox')}
               sx={{ backgroundColor: hovered.secondBox ? '#2D3A73' : '' }}
            >
               <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <PersonOutlineOutlinedIcon
                     sx={{ fontSize: 45, mr: 1, color: hovered.secondBox ? '#fff' : '#5AAED0' }}
                  />
                  <Typography
                     sx={{
                        color: hovered.secondBox ? '#fff' : '#5AAED0',
                        fontWeight: 500,
                        fontSize: 24,
                     }}
                  >
                     {t('resourcePlanning')}
                  </Typography>
               </Box>
               <Divider
                  sx={{
                     backgroundColor: hovered.secondBox ? '#fff' : '#5AAED0',
                     height: '0.5px',
                     borderRadius: '2px',
                     margin: '12px 0',
                  }}
               />
               <Box>
                  <Typography sx={{ fontSize: 14, color: hovered.secondBox ? '#fff' : '' }}>
                     {t('resourcePlanningInfo')}
                  </Typography>
               </Box>
            </StyledBox>
         </Box>
      </Paper>
   )
}

export default withTranslation()(ReportsAndPlaning)
