import {
   FormControlLabel,
   Checkbox,
   Box,
   Divider,
   Paper,
   Button,
   Snackbar,
   Alert,
   Link,
} from '@mui/material'
import { withTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import styled from '@emotion/styled'
import { Save as SaveIcon, Close as CloseIcon } from '@mui/icons-material'
import TimesheetFilterAutocomplete from './UI/TimesheetFilterAutocomplete'
import React, { useEffect, useState } from 'react'
import TimesheetSaveFiltersModal from './modal/TimesheetSaveFiltersModal'

const useStyles = makeStyles({
   filterBox: {
      border: '1px solid #C8C8C8',
      borderRadius: '5px',
      height: '143px',
      width: '100%',
      boxShadow: 'none',
   },
   closeIcon: {
      outline: 'none !important',
   },
   checkbox: {
      margin: 0,
      '&:hover .MuiCheckbox-root': {
         color: '#8ACCE6',
      },
      '&:hover': {
         color: '#8ACCE6',
      },
   },
   expandedSummary: {
      color: '#fff',
      backgroundColor: '#0083bb',
      borderRadius: '4px 4px 0px 0px',
   },
   accordion: {
      boxShadow: 'none',
      '&::before': {
         backgroundColor: 'transparent',
      },
   },
})

const StyledSaveButton = styled(Button)({
   width: 230,
   height: 32,
   padding: '8px 16px',
   borderRadius: 4,
   boxShadow: 'none',
   backgroundColor: '#008DC6',
   fontWeight: 700,
   fontSize: 12,
   lineHeight: '16px',
   letterSpacing: '0em',
   textAlign: 'left',
   '&:hover': {
      backgroundColor: '#2D3A73',
      boxShadow: 'none',
   },
})

const StyledClearButton = styled(Button)({
   width: 180,
   height: 32,
   padding: '8px 16px',
   borderRadius: 4,
   border: '1.6px solid',
   fontWeight: 700,
   fontSize: 12,
   borderColor: '#E58C9C',
   color: '#E58C9C',
   marginRight: 25,
   '&:hover': {
      backgroundColor: '#2D3A73',
      borderColor: '#2D3A73',
      color: 'white',
   },
})

const TimesheetManagementFilters = ({
   t,
   reference,
   teamProjectFilters,
   teamOptions,
   projectOptions,
   setTeamProjectFilters,
   userFilters,
   setUserFilters,
   skills,
   setTabIndex,
}) => {
   const [openModal, setOpenModal] = useState(false)
   const [previousFilters, setPreviousFilters] = useState(null)
   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      duration: 3000,
      undo: false,
   })
   const classes = useStyles()
   useEffect(() => {
      setPreviousFilters(
         localStorage.getItem('hrAppliedFilters') &&
            !Array.isArray(JSON.parse(localStorage.getItem('hrAppliedFilters')))
            ? JSON.parse(localStorage.getItem('hrAppliedFilters'))
            : {}
      )
   }, [teamProjectFilters, userFilters])

   const teams = teamOptions.filter(
      option => option.label !== t('defaultTeam') || option.members.length > 0
   )
   const showSuccessToast = (message, undo, duration = 3000) => {
      setSnackbarState({ open: true, message, duration, undo })
   }
   const closeSnackBar = () => setSnackbarState({ open: false, message: '', duration: 3000 })

   const clearFilters = () => {
      setTeamProjectFilters([])
      setUserFilters({
         skills: [],
         onlyFreeEmployees: false,
         missingLog: false,
      })
   }
   const toggleModal = () => {
      setOpenModal(prev => !prev)
   }

   const handleCheckFreeEmployees = () => {
      setUserFilters(prev => ({
         ...prev,
         onlyFreeEmployees: !prev.onlyFreeEmployees,
         missingLog: false,
      }))
   }
   const handleCheckMissingLog = () => {
      setUserFilters(prev => ({
         ...prev,
         onlyFreeEmployees: false,
         missingLog: !prev.missingLog,
      }))
   }
   const undoSavedFilters = () => {
      localStorage.setItem('hrAppliedFilters', JSON.stringify(previousFilters))
      showSuccessToast('Successfully undo last action!', true)
   }

   return (
      <Paper className={classes.filterBox} square={true} ref={reference}>
         <Box
            sx={{
               p: '15px 25px',
               position: 'relative',
               textAlign: '-webkit-center',
            }}
         >
            <Box
               sx={{
                  maxWidth: 1000,
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
               }}
            >
               <TimesheetFilterAutocomplete
                  options={teams}
                  teamProjectFilters={teamProjectFilters}
                  setTeamProjectFilters={setTeamProjectFilters}
                  optionType={'team'}
                  setUserFilters={setUserFilters}
                  userFilters={userFilters}
                  setTabIndex={setTabIndex}
               />
               <TimesheetFilterAutocomplete
                  options={projectOptions}
                  teamProjectFilters={teamProjectFilters}
                  setTeamProjectFilters={setTeamProjectFilters}
                  optionType={'project'}
                  setUserFilters={setUserFilters}
                  userFilters={userFilters}
                  setTabIndex={setTabIndex}
               />
               <TimesheetFilterAutocomplete
                  options={skills}
                  teamProjectFilters={teamProjectFilters}
                  setTeamProjectFilters={setTeamProjectFilters}
                  optionType={'skills'}
                  setUserFilters={setUserFilters}
                  userFilters={userFilters}
                  setTabIndex={setTabIndex}
               />

               <FormControlLabel
                  onChange={handleCheckFreeEmployees}
                  checked={userFilters.onlyFreeEmployees}
                  className={classes.checkbox}
                  control={<Checkbox />}
                  label="Only free employees"
               />
               <FormControlLabel
                  onChange={handleCheckMissingLog}
                  checked={userFilters.missingLog}
                  className={classes.checkbox}
                  control={<Checkbox />}
                  label="Missing log"
               />
            </Box>
         </Box>
         <Divider style={{ background: '#E0E0E0', margin: '0px 18px' }} />
         <Box
            sx={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               p: '19px 0px',
            }}
         >
            <Box sx={{ maxWidth: 600 }}>
               <StyledClearButton
                  onClick={clearFilters}
                  variant="outlined"
                  startIcon={<CloseIcon />}
               >
                  {t('claerFiltersText')}
               </StyledClearButton>
               <StyledSaveButton onClick={toggleModal} variant="contained" startIcon={<SaveIcon />}>
                  {t('saveFiltersText')}
               </StyledSaveButton>
            </Box>
         </Box>
         <TimesheetSaveFiltersModal
            teamProjectFilters={teamProjectFilters}
            userFilters={userFilters}
            openModal={openModal}
            handleCloseModal={toggleModal}
            showSuccessToast={showSuccessToast}
         />

         <Snackbar
            sx={{
               boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
               left: '45% !important',
               borderRadius: '8px',
            }}
            open={snackbarState.open}
            autoHideDuration={snackbarState.duration}
            onClose={closeSnackBar}
         >
            <Alert sx={{ backgroundColor: 'white', fontWeight: 500 }} onClose={closeSnackBar}>
               {snackbarState.message}
               {!snackbarState.undo && (
                  <Link sx={{ ml: 2, cursor: 'pointer' }} onClick={() => undoSavedFilters()}>
                     Undo this action
                  </Link>
               )}
            </Alert>
         </Snackbar>
      </Paper>
   )
}

export default withTranslation()(TimesheetManagementFilters)
