import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Alert from '@mui/material/Alert'
import Moment from 'react-moment'
import { Typography, Grid } from '@mui/material'
import { wrapReportStatus } from '../../../util/wrappers'
import {
   sortAsc,
   employmentChangedDuringReportPeriod,
   getEmploymentsForDate,
   employmentIsMissingCostCenter,
   useEffectOnlyOnce,
} from '../../../util/helpers'
import {
   getAbsenceCalcLabel,
   setDateToEndOfWorkDay,
   calculateAbsenceMinsBetweenDates,
   calculateWorkDayMinutes,
   calculateMinutesToDaysBasedOnWorkDay,
} from 'novarto-time'
import moment from 'moment'
import log from '../../../util/log'

import { withTranslation } from 'react-i18next'

const SalaryReportHTMLNovartoBulgaria = ({ ...props }) => {
   const {
      t,
      i18n,
      report,
      info,
      costCenters,
      hasLackingCostCenter,
      hasUnemployed,
      holidays,
      userVerificationRelevant,
   } = props

   const userInfo = useSelector(state => state.userInfo)
   const loggedIn = useSelector(state => state.loggedIn)
   let { id, lng } = useParams()

   useEffectOnlyOnce(() => {
      // Set language from parameter.
      i18n.changeLanguage(lng)
   })

   if (!loggedIn) {
      return <Redirect to="/login" />
   }
   if (!(userInfo.admin || userInfo.backofficeAdmin)) {
      return t('unauthorizedPage')
   }
   if (report == null && info != null) {
      return (
         <React.Fragment>
            <Alert severity={'error'}>
               {t('reportFetchError')} {id}
            </Alert>
            {info}
         </React.Fragment>
      )
   } else if (report == null) {
      return ''
   }

   return (
      <React.Fragment>
         <div style={{ padding: 20 }}>
            <h3>
               {t('titleSalaryReports')} <Moment date={report.StartDate} format="DD.MM.YYYY" /> -{' '}
               <Moment date={report.EndDate} format="DD.MM.YYYY" />
            </h3>
            <Typography variant="body2">
               {t('Created')} <Moment date={report.CreatedDate} format="HH:mm DD.MM.YYYY" />{' '}
               {t('by')} {report.CreatedByName} (id: {report.CreatedByEmployeeID})
            </Typography>
            <div>
               {t('Status')}:{' '}
               <span style={{ color: wrapReportStatus(report).color }}>
                  {wrapReportStatus(report).label}
               </span>
            </div>
            <div>
               {`${t('workdayLabel')}`}: {report.WorkDay.StartTime} - {report.WorkDay.EndTime}
               {report.WorkDay.UseLunchBreak
                  ? ` (${t('lunchLabel')} ${report.WorkDay.LunchBreak.StartTime} - ${
                       report.WorkDay.LunchBreak.EndTime
                    })`
                  : ''}
            </div>

            {costCenters
               .sort((c1, c2) => sortAsc(c1.ID, c2.ID))
               .map((costCenter, index) => (
                  <React.Fragment key={'cc_' + index}>
                     <div style={{ marginTop: 20 }}></div>
                     <h4>
                        {costCenter.DisplayID} - {costCenter.Name}
                     </h4>

                     {report.Data.Users.sort((u1, u2) => sortAsc(u1.Name, u2.Name))
                        .filter(user => {
                           const employments = getEmploymentsForDate(
                              user.Employments,
                              report.EndDate
                           )
                           return employments != null &&
                              employments[0].CostCenterID === costCenter.ID
                              ? user
                              : null
                        })
                        .map((user, index) => (
                           <React.Fragment key={'user_' + user._id}>
                              <div style={{ marginLeft: 20 }}>
                                 <UserReport
                                    user={user}
                                    report={report}
                                    holidays={holidays}
                                    t={t}
                                    userVerificationRelevant={userVerificationRelevant}
                                 />
                              </div>
                           </React.Fragment>
                        ))}
                  </React.Fragment>
               ))}

            {report.Status === 'OPEN' ? (
               <React.Fragment>
                  {hasUnemployed ? (
                     <div style={{ marginTop: 20 }}>
                        <h4 style={{ color: 'red' }}>{t('missingEmploymentTitle')}</h4>
                        <Typography variant="body2">
                           {t('missingEmploymentForEndDate')}{' '}
                           <Moment date={report.EndDate} format="DD.MM.YYYY" />
                        </Typography>
                        {report.Data.Users.sort((u1, u2) => sortAsc(u1.Name, u2.Name))
                           .filter(user => {
                              const employments = getEmploymentsForDate(
                                 user.Employments,
                                 report.EndDate
                              )
                              return employments == null ? user : null
                           })
                           .map((user, index) => (
                              <React.Fragment key={'user_' + user._id}>
                                 <div style={{ marginLeft: 20 }}>
                                    <UserReport
                                       user={user}
                                       report={report}
                                       holidays={holidays}
                                       t={t}
                                       userVerificationRelevant={userVerificationRelevant}
                                    />
                                 </div>
                              </React.Fragment>
                           ))}
                     </div>
                  ) : null}
                  {hasLackingCostCenter ? (
                     <div style={{ marginTop: 20 }}>
                        <h4 style={{ color: 'red' }}>{t('missingCostCenterText')}</h4>
                        {report.Data.Users.sort((u1, u2) => sortAsc(u1.Name, u2.Name))
                           .filter(user => {
                              const employments = getEmploymentsForDate(
                                 user.Employments,
                                 report.EndDate
                              )
                              return employments != null &&
                                 employmentIsMissingCostCenter(employments[0], costCenters)
                                 ? user
                                 : null
                           })
                           .map((user, index) => (
                              <React.Fragment key={'user_' + user._id}>
                                 <div style={{ marginLeft: 20 }}>
                                    <UserReport
                                       user={user}
                                       report={report}
                                       holidays={holidays}
                                       t={t}
                                       userVerificationRelevant={userVerificationRelevant}
                                    />
                                 </div>
                              </React.Fragment>
                           ))}
                     </div>
                  ) : null}
               </React.Fragment>
            ) : (
               ''
            )}
         </div>
      </React.Fragment>
   )
}

const Sum = props => {
   const { sumOverride, sum, t, workDay, title } = props

   return (
      <React.Fragment>
         <Grid container direction="row">
            <Grid>
               <Typography variant="body2" style={{ width: 140 }}>
                  {t(title)}
               </Typography>
            </Grid>
            {sumOverride !== sum && sumOverride > 0 ? (
               <React.Fragment>
                  <Grid container direction="row" style={{ width: 180, color: 'deeppink' }}>
                     <Grid
                        item
                        style={{ width: 90 }}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                     >
                        <Typography variant="body2" style={{}}>
                           {sumOverride !== sum && sumOverride > 0
                              ? getAbsenceCalcLabel(
                                   calculateMinutesToDaysBasedOnWorkDay(sumOverride, workDay),
                                   t
                                )
                              : ' '}
                        </Typography>
                     </Grid>
                     <Grid
                        item
                        style={{ width: 90 }}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                     >
                        <Typography variant="body2">
                           {sumOverride !== sum && sumOverride > 0
                              ? `(${sumOverride}${t('minuteAbbrev')})`
                              : ' '}
                        </Typography>
                     </Grid>
                  </Grid>

                  <Grid container direction="row" style={{ width: 180 }}>
                     <Grid
                        item
                        style={{ width: 90 }}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                     >
                        <Typography variant="body2" style={{}}>
                           {getAbsenceCalcLabel(
                              calculateMinutesToDaysBasedOnWorkDay(sum, workDay),
                              t
                           )}
                        </Typography>
                     </Grid>
                     <Grid
                        item
                        style={{ width: 90 }}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                     >
                        <Typography variant="body2" style={{}}>
                           ({sum + t('minuteAbbrev')})
                        </Typography>
                     </Grid>
                  </Grid>
               </React.Fragment>
            ) : (
               <React.Fragment>
                  <Grid container direction="row" style={{ width: 180 }}>
                     <Grid
                        item
                        style={{ width: 90 }}
                        justifyContent="flex-start"
                        alignItems="center"
                     >
                        <Typography variant="body2" style={{}}>
                           {getAbsenceCalcLabel(
                              calculateMinutesToDaysBasedOnWorkDay(sum, workDay),
                              t
                           )}
                        </Typography>
                     </Grid>
                     <Grid
                        item
                        style={{ width: 90 }}
                        justifyContent="flex-start"
                        alignItems="center"
                     >
                        <Typography variant="body2" style={{}}>
                           ({sum + t('minuteAbbrev')})
                        </Typography>
                     </Grid>
                  </Grid>

                  <Grid container direction="row" style={{ width: 180, color: 'deeppink' }}>
                     <Grid
                        item
                        style={{ width: 90 }}
                        justifyContent="flex-start"
                        alignItems="center"
                     >
                        <Typography variant="body2" style={{}}>
                           {' '}
                        </Typography>
                     </Grid>{' '}
                     <Grid
                        item
                        style={{ width: 90 }}
                        justifyContent="flex-start"
                        alignItems="center"
                     >
                        <Typography
                           variant="body2"
                           style={{ marginLeft: 5, minWidth: 10 }}
                        ></Typography>
                     </Grid>
                  </Grid>
               </React.Fragment>
            )}
         </Grid>
      </React.Fragment>
   )
}

const UserReport = props => {
   const { user, report, t, userVerificationRelevant } = props

   const isOverZero = absence => !absence.EndDate || absence.StartDate < absence.EndDate

   // Check if we should show employments or not
   let showEmployments = false

   user.Employments.forEach(employment => {
      if (employmentChangedDuringReportPeriod(employment, report)) {
         showEmployments = true
      }
   })

   let sumSemester = 0
   let sumSjuk = 0
   let sumTjanstledig = 0
   let sumForaldraledig = 0

   let sumSemesterOverride = 0
   let sumSjukOverride = 0
   let sumTjanstledigOverride = 0
   let sumForaldraledigOverride = 0

   return (
      <React.Fragment>
         <div style={{ pageBreakInside: 'avoid', marginTop: 20 }}>
            <h5>{user.Name}</h5>
            <div style={{ marginLeft: 10 }}>
               <div>
                  {user.AdditionalInfo.filter(
                     info => !info.Expiry || new Date(info.Expiry) >= Date.now()
                  ).map(info => (
                     <Typography>{info.Message}</Typography>
                  ))}
               </div>
               <div>
                  {user.Employments.length < 1 ? (
                     <React.Fragment>
                        {report.Status === 'OPEN' ? (
                           <Typography variant="body2" style={{ color: 'red' }}>
                              {t('missingEmploymentForDates')}
                           </Typography>
                        ) : (
                           ''
                        )}
                     </React.Fragment>
                  ) : (
                     <React.Fragment>
                        {showEmployments ? (
                           <React.Fragment>
                              <div>
                                 <Typography variant="body2">{t('titleEmployment')}:</Typography>
                              </div>
                              {user.Employments.sort((e1, e2) =>
                                 sortAsc(e1.StartDate, e2.StartDate)
                              ).map((Employment, index) => (
                                 <React.Fragment key={'employment_' + index}>
                                    <div>
                                       <div style={{ float: 'left', width: 50 }}>
                                          <Typography variant="body2">
                                             {Employment.WorkTimePercent}%
                                          </Typography>
                                       </div>
                                       <div style={{ float: 'left', width: 160 }}>
                                          <Typography variant="body2">
                                             <Moment
                                                date={Employment.StartDate}
                                                format="DD.MM.YYYY"
                                             />{' '}
                                             -{' '}
                                             {Employment.EndDate != null ? (
                                                <Moment
                                                   date={Employment.EndDate}
                                                   format="DD.MM.YYYY"
                                                />
                                             ) : (
                                                ''
                                             )}
                                          </Typography>
                                       </div>
                                       <div style={{ float: 'left', marginLeft: 10, minWidth: 40 }}>
                                          <Typography variant="body2">
                                             {t('costCenterAbbrev')}: {Employment.CostCenterID}
                                          </Typography>
                                       </div>
                                       <div
                                          style={{ float: 'left', marginLeft: 10, minWidth: 120 }}
                                       >
                                          <Typography variant="body2">
                                             {Employment.Title}
                                          </Typography>
                                       </div>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                 </React.Fragment>
                              ))}
                           </React.Fragment>
                        ) : (
                           ''
                        )}
                     </React.Fragment>
                  )}
               </div>

               {report.Status === 'OPEN' ? (
                  <div>
                     <Typography variant="body2" style={{ color: 'orangered' }}>
                        {!userVerificationRelevant ||
                        (user.AcceptedReports &&
                           user.AcceptedReports.some(rep => rep.ID === moment().format('YYYYMM')))
                           ? ''
                           : t('notVerifiedReportMsg')}
                     </Typography>
                     <Typography variant="body2" style={{ color: 'red' }}>
                        {user.Absences.find(abs => !isOverZero(abs))
                           ? t('zeroOrNegativeReportMsg')
                           : ''}
                     </Typography>
                  </div>
               ) : (
                  ''
               )}

               <div style={{ marginTop: 10 }}>
                  {user.Absences.length < 1 ? (
                     <React.Fragment>
                        <Typography variant="body2">{t('noReportedAbsence')}</Typography>
                     </React.Fragment>
                  ) : (
                     <React.Fragment>
                        <div>
                           <Typography variant="body2">{t('absenceTitle')}:</Typography>
                        </div>

                        <Grid container direction="row" style={{ width: 1100 }}>
                           <Grid item style={{ width: 100, fontSize: 10 }}>
                              {t('startDay')}
                           </Grid>
                           <Grid item style={{ width: 70, fontSize: 10 }}>
                              {/* {t("startTime")} */}
                           </Grid>
                           <Grid item style={{ width: 100, fontSize: 10 }}>
                              {t('endDay')}
                           </Grid>
                           <Grid item style={{ width: 60, fontSize: 10 }}>
                              {/* {t("endTime")} */}
                           </Grid>
                           <Grid item style={{ width: 100, fontSize: 10 }}>
                              {t('sumOverride')}
                           </Grid>
                           <Grid item style={{ width: 90, fontSize: 10 }}>
                              {t('sumDates')}
                           </Grid>
                           <Grid item style={{ width: 200, fontSize: 10 }}>
                              {t('absenceTypePlaceholder2')}
                           </Grid>
                           <Grid item style={{ width: 80, fontSize: 10 }}>
                              {t('requestNumber')}
                           </Grid>
                        </Grid>

                        {user.Absences.sort((e1, e2) => sortAsc(e1.StartDate, e2.StartDate)).map(
                           (Absence, index) => {
                              let end = Absence.EndDate
                              if (end == null) {
                                 end = report.EndDate
                              }
                              let start = Absence.StartDate

                              const isZeroOrNegative = start >= end

                              // If the start is before the start of the report, we will use the start of the
                              // report to calculate days.

                              let truncStart = false
                              let startColor = 'black'
                              if (start < report.StartDate) {
                                 start = report.StartDate
                                 truncStart = true
                                 startColor = '#797979'
                              }

                              let truncEnd = false
                              let endColor = 'black'
                              const reportEndDate = setDateToEndOfWorkDay(
                                 new Date(report.EndDate),
                                 report.WorkDay
                              )
                              const absenceEndDate = new Date(end)
                              if (absenceEndDate > reportEndDate) {
                                 end = report.EndDate
                                 truncEnd = true
                                 endColor = '#797979'
                              }

                              let mins = calculateAbsenceMinsBetweenDates(
                                 start,
                                 end,
                                 props.holidays,
                                 report.WorkDay
                              )

                              const workDayMins = calculateWorkDayMinutes(report.WorkDay)

                              log.debug('isZeroOrNegative: ' + isZeroOrNegative)
                              log.debug('Calculated mins: ' + mins)

                              const overrideDays = Absence.DayOverride
                              const overrideHours = Absence.HourOverride

                              let overrideMins = 0

                              if (overrideDays && overrideDays > 0) {
                                 overrideMins += overrideDays * workDayMins
                              }
                              if (overrideHours && overrideHours > 0) {
                                 overrideMins += overrideHours * 60
                              }

                              if (isZeroOrNegative) {
                                 // Do not include errors in report, but warn
                                 mins = 0
                                 overrideMins = 0
                              }

                              switch (Absence.Type) {
                                 case 'TJANSTLEDIG':
                                 case 'TJANSTLEDIGFULLDAG':
                                    sumTjanstledig += mins
                                    sumTjanstledigOverride += overrideMins > 0 ? overrideMins : mins
                                    break
                                 case 'SJUK':
                                 case 'SJUKFULLDAG':
                                    sumSjuk += mins
                                    sumSjukOverride += overrideMins
                                    break
                                 case 'SEMESTER':
                                 case 'SEMESTERFULLDAG':
                                    sumSemester += mins
                                    sumSemesterOverride += overrideMins > 0 ? overrideMins : mins
                                    break
                                 case 'FORALDRALEDIG':
                                    sumForaldraledig += mins
                                    sumForaldraledigOverride += overrideMins
                                    break
                                 default:
                                    break
                              }

                              const overrideRelevant = mins !== overrideMins && overrideMins !== 0

                              return (
                                 <React.Fragment key={'absence_' + index}>
                                    <div
                                       style={
                                          isZeroOrNegative && !overrideRelevant
                                             ? { color: 'red' }
                                             : {}
                                       }
                                    >
                                       <div style={{ float: 'left', width: 320 }}>
                                          <div>
                                             <div
                                                style={{
                                                   float: 'left',
                                                   width: 150,
                                                   color: startColor,
                                                }}
                                             >
                                                <Typography variant="body2">
                                                   <b>
                                                      <Moment date={start} format="DD.MM.YYYY" />
                                                   </b>
                                                   {truncStart ? '*' : ''}
                                                </Typography>
                                             </div>
                                             {/* <div style={{ float: "left", width: 50 }}><Typography variant="body2"><Moment date={start} format="HH:mm" /></Typography></div> */}
                                             <div style={{ float: 'left', width: 20 }}>
                                                <Typography variant="body2">-</Typography>
                                             </div>

                                             <div
                                                style={{
                                                   float: 'left',
                                                   width: 150,
                                                   color: endColor,
                                                }}
                                             >
                                                <Typography variant="body2">
                                                   <b>
                                                      <Moment date={end} format="DD.MM.YYYY" />
                                                   </b>
                                                   {truncEnd ? '*' : ''}
                                                </Typography>
                                             </div>
                                             {/* <div style={{ float: "left", width: 30 }}><Typography variant="body2"><Moment date={end} format="HH:mm" /></Typography></div> */}
                                          </div>
                                          <div style={{ clear: 'both' }}></div>
                                       </div>

                                       <div style={{ float: 'left', width: 200, paddingLeft: 10 }}>
                                          <div style={{ float: 'left', width: 180 }}>
                                             <React.Fragment>
                                                <Grid container direction="row">
                                                   <Grid item style={{ width: 100 }}>
                                                      {overrideRelevant ? (
                                                         <span style={{ color: 'deeppink' }}>
                                                            <Typography variant="body2">
                                                               {getAbsenceCalcLabel(
                                                                  calculateMinutesToDaysBasedOnWorkDay(
                                                                     overrideMins,
                                                                     report.WorkDay
                                                                  ),
                                                                  t
                                                               )}
                                                            </Typography>
                                                         </span>
                                                      ) : (
                                                         ' '
                                                      )}
                                                   </Grid>
                                                   <Grid item>
                                                      <Typography variant="body2">
                                                         {overrideRelevant
                                                            ? ' (' +
                                                              getAbsenceCalcLabel(
                                                                 calculateMinutesToDaysBasedOnWorkDay(
                                                                    mins,
                                                                    report.WorkDay
                                                                 ),
                                                                 t
                                                              ) +
                                                              ')'
                                                            : getAbsenceCalcLabel(
                                                                 calculateMinutesToDaysBasedOnWorkDay(
                                                                    mins,
                                                                    report.WorkDay
                                                                 ),
                                                                 t
                                                              )}
                                                      </Typography>
                                                   </Grid>
                                                </Grid>
                                             </React.Fragment>
                                          </div>
                                       </div>

                                       <div style={{ float: 'left', width: 200 }}>
                                          <Typography variant="body2">{t(Absence.Type)}</Typography>
                                       </div>

                                       <div style={{ float: 'left', width: 200 }}>
                                          <Typography variant="body2">
                                             {Absence.Type === "DISTANS" ? Absence.RemoteRequestNumber : Absence.RemoteRequestNumber}
                                          </Typography>
                                       </div>
                                    </div>
                                    <div style={{ clear: 'both' }}></div>
                                 </React.Fragment>
                              )
                           }
                        )}

                        {sumTjanstledig > 0 ||
                        sumSjuk > 0 ||
                        sumSemester > 0 ||
                        sumForaldraledig > 0 ? (
                           <div style={{ marginTop: 10 }}>
                              <Grid container direction="row">
                                 <Grid item style={{ width: 140 }}>
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                       {t('Summary')}:
                                    </Typography>
                                 </Grid>
                                 <React.Fragment>
                                    <Grid item style={{ width: 180 }}>
                                       <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                          {t('Total')}
                                       </Typography>
                                    </Grid>
                                    <Grid item style={{ width: 180 }}>
                                       <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                          {t('withoutOverride')}
                                       </Typography>
                                    </Grid>
                                 </React.Fragment>
                              </Grid>

                              {sumSemester > 0 ? (
                                 <Sum
                                    sumOverride={sumSemesterOverride}
                                    sum={sumSemester}
                                    t={t}
                                    workDay={report.WorkDay}
                                    title={'SEMESTERFULLDAG'}
                                 />
                              ) : (
                                 ''
                              )}
                              {sumTjanstledig > 0 ? (
                                 <Sum
                                    sumOverride={sumTjanstledigOverride}
                                    sum={sumTjanstledig}
                                    t={t}
                                    workDay={report.WorkDay}
                                    title={'TJANSTLEDIGFULLDAG'}
                                 />
                              ) : (
                                 ''
                              )}
                              {sumForaldraledig > 0 ? (
                                 <Sum
                                    sumOverride={sumForaldraledigOverride}
                                    sum={sumForaldraledig}
                                    t={t}
                                    workDay={report.WorkDay}
                                    title={'FORALDRALEDIG'}
                                 />
                              ) : (
                                 ''
                              )}
                              {sumSjuk > 0 ? (
                                 <Sum
                                    sumOverride={sumSjukOverride}
                                    sum={sumSjuk}
                                    t={t}
                                    workDay={report.WorkDay}
                                    title={'SJUKFULLDAG'}
                                 />
                              ) : (
                                 ''
                              )}
                           </div>
                        ) : (
                           ''
                        )}
                     </React.Fragment>
                  )}
               </div>
            </div>
         </div>
      </React.Fragment>
   )
}

export default withTranslation()(SalaryReportHTMLNovartoBulgaria)
