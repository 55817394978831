import { Box, CircularProgress, Paper } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ProjectOverviewHeader from './components/ProjectOverviewHeader'
import ProjectOverviewStatistic from './components/ProjectOverviewStatistic'
import ProjectOverviewDetails from './components/ProjectOverviewDetails'
import ProjectOverviewMemberDetails from './components/ProjectOverviewMemberDetails'
import ProjectOverviewCharts from './components/ProjectOverviewCharts'
import React, { useState, useEffect } from 'react'
import { getProjectsForReport } from '../../../../Api'
import { connect } from 'react-redux'
import '../../../../css/projectPlanning.css'
const ProjectOverview = ({ userInfo }) => {
   const [selectedProject, setSelectedProject] = useState(null)
   const [selectedPeriod, setSelectedPeriod] = useState({
      startDate: new Date().setDate(new Date().getDate() - 7),
      endDate: new Date(),
   })
   const [isFetching, setIsFetching] = useState(false)
   const { id } = useParams()
   useEffect(() => {
      async function getData() {
         setIsFetching(true)
         try {
            const response = await getProjectsForReport(
               {
                  id,
                  StartDate: new Date(selectedPeriod.startDate),
                  EndDate: new Date(selectedPeriod.endDate),
               },
               userInfo
            )
            setSelectedProject(response.data)
            setIsFetching(false)
         } catch (err) {
            console.log(err)
         }
      }
      getData()
   }, [selectedPeriod])

   if (!selectedProject) {
      return (
         <Box
            sx={{
               width: '100%',
               height: '100%',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <CircularProgress />
         </Box>
      )
   }

   return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
         <Paper sx={{ width: '100%', maxWidth: 2000, height: '100%', boxShadow: 'none' }}>
            {selectedProject ? (
               <>
                  <ProjectOverviewHeader
                     project={selectedProject}
                     projectID={id}
                     selectedPeriod={selectedPeriod}
                  />
                  <ProjectOverviewStatistic project={selectedProject} />
                  <ProjectOverviewDetails
                     project={selectedProject}
                     selectedPeriod={selectedPeriod}
                     setSelectedPeriod={setSelectedPeriod}
                     isFetching={isFetching}
                  />
                  <ProjectOverviewMemberDetails
                     project={selectedProject}
                     selectedPeriod={selectedPeriod}
                     isFetching={isFetching}
                  />
                  <ProjectOverviewCharts
                     project={selectedProject}
                     selectedPeriod={selectedPeriod}
                     isFetching={isFetching}
                  />
               </>
            ) : (
               <Box
                  sx={{
                     width: '100%',
                     height: '100%',
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                  }}
               >
                  <CircularProgress />
               </Box>
            )}
         </Paper>
      </Box>
   )
}
const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}
export default withTranslation()(connect(mapStateToProps)(ProjectOverview))
