import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import { default as React, Fragment, useCallback, useEffect, useState } from 'react'
import 'react-dropdown/style.css'
import { connect } from 'react-redux'
import { fetchActiveUsers, fetchUsers } from '../redux/actions/actions'
import CreateTeamModal from '../components/modals/CreateTeamModal'
import CustomDeleteModal from '../components/modals/CustomDeleteModal'
import DiscardChangesModal from '../components/modals/DiscardChangesModal'
import ManageTeamMembersModal from '../components/modals/ManageTeamMembersModal'
import themeConfig from '../theme'
import { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import bg from 'date-fns/locale/bg'
import { withTranslation } from 'react-i18next'
import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Button,
   Grid,
   Snackbar,
   TextField,
   Tooltip,
} from '@mui/material'
import { filterProjectsByStartAndEndDate, sortByLabel, useEffectOnlyOnce } from '../util/helpers'
import {
   fetchCustomerForAuthUser,
   getTeamsForCustomer,
   deleteTeam,
   getProjectsForUser,
   updateTeam,
   fetchInactiveUsers,
} from '../Api'
import { wrapTeam, wrapUser } from '../util/wrappers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faStar } from '@fortawesome/free-solid-svg-icons'
import Alert from 'react-bootstrap/esm/Alert'
import { Autocomplete } from '@mui/material'
import moment from 'moment'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'

registerLocale('sv', sv)
registerLocale('en-GB', en)
registerLocale('bg', bg)

const { styles } = themeConfig()

const useStyles = makeStyles(theme => ({
   mainAutocomplete: {
      width: '97%',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiInputBase-root': {
         height: '55px',
         padding: 0,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
         padding: 0,
         paddingLeft: '10px',
      },
   },
   mainPaper: {
      width: '100%',
      padding: '10px',
      boxShadow: theme.shadows[2],
      borderRadius: '8px',
      marginTop: '-25px',
      boxShadow: 'none',
   },
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
   settingsIcon: {
      cursor: 'pointer',
      backgroundColor: '#009be5',
      color: '#fff',
      borderRadius: '25px',
      padding: '5px',
      '&:hover': {
         color: '#fff',
         backgroundColor: '#006db3',
      },
   },
   infoIcon: {
      fontSize: '18px',
      color: 'red',
      marginLeft: '5px',
      marginTop: '-5px',
   },
}))

const TeamManagement = ({ ...props }) => {
   const { userInfo, t, fetchUsers, fetchActiveUsers } = props

   const [teams, setTeams] = useState([])
   const [selectedTeam, setSelectedTeam] = useState(null)
   const [customer, setCustomer] = useState(null)
   const [filterMember, setFilterMember] = useState('')
   const [filterNonMember, setFilterNonMember] = useState('')
   const [loaded, setLoaded] = useState(false)
   const [openManageMembersModal, setOpenManageMembersModal] = useState(false)
   const [selectedTeamMemberForDetails, setSelectedTeamMemberForDetails] = useState(null)
   const [openDeleteModal, setOpenDeleteModal] = useState(false)
   const [openCreateTeamModal, setOpenCreateTeamModal] = useState(false)
   const [haveUnsavedChanges, setHaveUnsavedChanges] = useState(false)
   const [openDiscardChangesModal, setOpenDiscardChangesModal] = useState(false)
   const [callbackFunction, setCallbackFunction] = useState(() => {})
   const [inactiveUsers, setInactiveUsers] = useState(null)
   const [snackbarState, setSnackbarState] = useState({
      open: false,
      message: '',
      severity: 'success',
   })
   const classes = useStyles()

   const defaultSelection = () => selectedTeam.label === t('defaultTeam')

   const toggleManageMembersModal = () => {
      setOpenManageMembersModal(prevState => !prevState)
   }

   const toggleCreateModal = () => {
      openCreateTeamModal && setHaveUnsavedChanges(false)
      setOpenCreateTeamModal(prevState => !prevState)
   }

   const toggleDiscardModal = () => {
      setOpenDiscardChangesModal(prevState => !prevState)
   }
   useEffect(() => {
      fetchInactiveUsers(userInfo)
         .then(response => setInactiveUsers(response.data))
         .catch(err => console.log(err))
   }, [])
   const checkIfHasChangesBeforeChangeTeam = useCallback(
      team => {
         if (haveUnsavedChanges) {
            setCallbackFunction(() => () => {
               setSelectedTeam(team)
               setHaveUnsavedChanges(false)
            })
            toggleDiscardModal()
         } else {
            setSelectedTeam(team)
         }
      },
      [haveUnsavedChanges]
   )

   const toggleDeleteModal = () => {
      setOpenDeleteModal(prevState => !prevState)
   }

   const getUserProjects = async userID => {
      return await getProjectsForUser(userID, userInfo)
   }

   const showMemberDetails = async user => {
      const projects = await getUserProjects(user._id)

      const filteredProjects = filterProjectsByStartAndEndDate(projects.data, user._id)

      setSelectedTeamMemberForDetails({ ...user, ['projects']: filteredProjects })
   }

   const deleteTeamHandler = async () => {
      await deleteTeam(selectedTeam.id, userInfo)
      fetchTeams(customer)
      setSelectedTeam(teamOptions[0])
   }

   const showSuccessfulSnackbar = message => {
      setSnackbarState({ open: true, message, severity: 'success' })
   }

   const showErrorSnackBar = message => {
      setSnackbarState({ open: true, message, severity: 'danger' })
   }

   const closeSnackBar = () => {
      setSnackbarState({ open: false, message: '', severity: 'info' })
   }

   const fetchTeams = async (cust = customer) => {
      const teams = await getTeamsForCustomer(cust._id, userInfo)
      setTeams(teams.data)
      setLoaded(true)
   }

   const getAndWrapUser = useCallback(
      id => {
         const user = props.activeUsers.find(user => user._id === id)
         return user ? wrapUser(user) : null
      },
      [props.activeUsers]
   )

   const getAndWrapInactiveUsers = useCallback(
      id => {
         const user = inactiveUsers.find(user => user._id === id)

         return user ? wrapUser(user) : null
      },
      [inactiveUsers]
   )

   const characterFilter = useCallback((person, filter) => {
      return `${person?.label}|${person?.nick}`.toLowerCase().includes(filter)
   }, [])

   const teamOptions = [
      { label: t('defaultTeam'), value: null, id: null, members: [], pastMembers: [] },
      ...teams?.map(wrapTeam).sort(sortByLabel),
   ]

   const handleChangeTeam = team => {
      checkIfHasChangesBeforeChangeTeam(team)
      setSelectedTeamMemberForDetails(null)
      if (team?.label === t('defaultTeam')) {
         setFilterNonMember('')
      }
   }

   const isTeamLead = user => {
      if (user._id === selectedTeam.TeamLead) {
         return true
      }
      return false
   }

   const saveTeam = async () => {
      try {
         await updateTeam(
            {
               id: selectedTeam.id,
               Name: selectedTeam?.label,
               MemberIDs: selectedTeam.members,
               PastMemberIDs: selectedTeam.pastMembers,
               TeamLead: selectedTeam.TeamLead,
            },
            userInfo
         )
         showSuccessfulSnackbar(t('successfullyUpdatedTeam'))
         fetchTeams()
      } catch {
         showErrorSnackBar(t('errorUpdatingTeam'))
      }
      setHaveUnsavedChanges(false)
   }

   useEffectOnlyOnce(() => {
      const loadData = async () => {
         const result = await fetchCustomerForAuthUser(userInfo)
         if (result.success) {
            setCustomer(result.customer)
            fetchTeams(result.customer)
         }
         fetchTeams(result.customer)
         fetchActiveUsers(userInfo)
         await fetchUsers(userInfo)
      }

      loadData()
      handleChangeTeam(teamOptions[0])
   })

   const isPMActiveUser = useCallback(
      id => {
         return Boolean(props.activeUsers.find(user => user._id === id))
      },
      [props.activeUsers]
   )

   useEffect(() => {
      if (props.activeUsers && teamOptions[0].members.length < 1)
         teamOptions[0].members.push(...props.activeUsers.map(user => user._id))
   }, [props.activeUsers, teamOptions, customer])

   if (!loaded || !props.activeUsers || !selectedTeam) {
      return (
         <React.Fragment>
            <Paper className="contentPaper">{t('fetchingData')}</Paper>
         </React.Fragment>
      )
   }

   return (
      <React.Fragment>
         <Paper className={classes.mainPaper}>
            <Grid
               container
               direction="row"
               justifyContent="flex-start"
               alignItems="flex-start"
               spacing={1}
               style={{ padding: 10, paddingBottom: 10 }}
            >
               <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  className={'align-items-center mb-4'}
               >
                  <Grid item xs={6} className={'d-flex'} style={{ alignItems: 'center' }}>
                     <Autocomplete
                        value={selectedTeam}
                        options={teamOptions}
                        getOptionLabel={option => (Object.keys(option).length ? option?.label : '')}
                        onChange={(event, option) => handleChangeTeam(option)}
                        isOptionEqualToValue={(option, value) => option?.label === value?.label}
                        className={classes.mainAutocomplete}
                        disableClearable
                        renderInput={params => (
                           <TextField
                              {...params}
                              label={
                                 Object.keys(selectedTeam).length
                                    ? t('teamName')
                                    : t('teamNameMissing')
                              }
                              variant={'outlined'}
                           />
                        )}
                     />
                  </Grid>
                  <Grid item xs={6}>
                     <div style={{ float: 'right' }}>
                        <Button
                           size="large"
                           variant="outlined"
                           color="primary"
                           style={{ marginLeft: 10 }}
                           onClick={toggleCreateModal}
                        >
                           <FontAwesomeIcon size="lg" icon={faPlus} />
                        </Button>
                        <Button
                           disabled={defaultSelection()}
                           size="large"
                           variant="outlined"
                           color="secondary"
                           style={{ marginLeft: 10 }}
                           onClick={toggleDeleteModal}
                        >
                           <FontAwesomeIcon size="lg" icon={faTrash} />
                        </Button>
                        <Button
                           disabled={defaultSelection()}
                           size="medium"
                           variant="contained"
                           color="primary"
                           style={{ marginLeft: 10 }}
                           onClick={toggleManageMembersModal}
                        >
                           {t('manageTeam')}
                        </Button>
                     </div>
                  </Grid>
               </Grid>
            </Grid>
            <Grid
               container
               direction="row"
               justifyContent="flex-start"
               alignItems="flex-start"
               spacing={1}
               style={{ paddingBottom: 10 }}
               item
               xs={12}
            >
               <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  item
                  xs={6}
               >
                  {selectedTeam && (
                     <Typography style={{ paddingLeft: 10 }}>
                        <b>
                           {selectedTeam?.label} (
                           {
                              props.activeUsers.filter(user =>
                                 selectedTeam.members.includes(user._id)
                              ).length
                           }
                           )
                        </b>
                     </Typography>
                  )}
                  {selectedTeam?.label !== 'All users' && (
                     <Typography style={{ paddingLeft: 10, paddingTop: 10 }}>
                        Active members
                     </Typography>
                  )}
                  <Paper style={{ width: '97%', padding: '5px', margin: 10 }}>
                     <div
                        style={{
                           maxHeight: '60vh',
                           padding: '0 10px 5px 15px',
                           overflow: 'hidden auto',
                        }}
                     >
                        {selectedTeam.members.length ? (
                           <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              item
                              xs={6}
                              style={{ maxWidth: '100%' }}
                           >
                              {selectedTeam.members
                                 .map(user => getAndWrapUser(user))
                                 .filter(user => user && characterFilter(user, filterMember))
                                 .sort(sortByLabel)
                                 .map((user, i) => (
                                    <Grid
                                       key={`member-${i}`}
                                       item
                                       xs
                                       style={{ width: '100%', maxWidth: '100%' }}
                                    >
                                       <Button
                                          disabled={defaultSelection()}
                                          variant="outlined"
                                          color="primary"
                                          style={{
                                             width: '100%',
                                             justifyContent: 'space-between',
                                          }}
                                          onClick={() =>
                                             defaultSelection() ? null : showMemberDetails(user)
                                          }
                                       >
                                          <Typography key={`text-${i}`}>{user?.label}</Typography>
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                             {isTeamLead(user) && (
                                                <FontAwesomeIcon
                                                   key={`icon-${i}`}
                                                   size="2x"
                                                   icon={faStar}
                                                   title={t('TeamLead')}
                                                   style={{ marginRight: 10 }}
                                                />
                                             )}
                                             {defaultSelection()}
                                          </div>
                                       </Button>
                                    </Grid>
                                 ))}
                           </Grid>
                        ) : (
                           <Typography style={{ padding: 10, fontSize: '1rem', fontWeight: '300' }}>
                              {t('teamWithoutMembers')}
                           </Typography>
                        )}
                     </div>
                  </Paper>
                  {selectedTeam?.label !== 'All users' &&
                  selectedTeam.pastMembers?.length &&
                  selectedTeam.pastMembers[0] !== null ? (
                     <Typography style={{ paddingLeft: 10, paddingTop: 10 }}>
                        Past members
                     </Typography>
                  ) : (
                     ''
                  )}
                  {selectedTeam.pastMembers?.length && selectedTeam.pastMembers[0] !== null ? (
                     <Paper style={{ width: '97%', padding: '5px', margin: 10 }}>
                        <div
                           style={{
                              maxHeight: '60vh',
                              padding: '0 10px 5px 15px',
                              overflow: 'hidden auto',
                           }}
                        >
                           <Grid
                              container
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              spacing={1}
                              item
                              xs={6}
                              style={{ maxWidth: '100%' }}
                           >
                              {selectedTeam.pastMembers
                                 .map(user => getAndWrapInactiveUsers(user))
                                 .filter(user => user && characterFilter(user, filterMember))
                                 .sort(sortByLabel)
                                 .map((user, i) => (
                                    <Grid
                                       key={`member-${i}`}
                                       item
                                       xs
                                       style={{ width: '100%', maxWidth: '100%' }}
                                    >
                                       <Button
                                          disabled={defaultSelection()}
                                          variant="outlined"
                                          style={{
                                             width: '100%',
                                             justifyContent: 'space-between',
                                          }}
                                          onClick={() =>
                                             defaultSelection() ? null : showMemberDetails(user)
                                          }
                                       >
                                          <Typography key={`text-${i}`}>{user?.label}</Typography>
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                             {isTeamLead(user) && (
                                                <FontAwesomeIcon
                                                   key={`icon-${i}`}
                                                   size="2x"
                                                   icon={faStar}
                                                   title={t('TeamLead')}
                                                   style={{ marginRight: 10 }}
                                                />
                                             )}
                                             {defaultSelection()}
                                          </div>
                                       </Button>
                                    </Grid>
                                 ))}
                           </Grid>
                        </div>
                     </Paper>
                  ) : (
                     ''
                  )}
               </Grid>
               {defaultSelection() ? null : selectedTeamMemberForDetails ? (
                  <Grid
                     container
                     direction="column"
                     justifyContent="flex-start"
                     alignItems="flex-start"
                     spacing={1}
                     item
                     xs={6}
                  >
                     {selectedTeam && (
                        <Typography style={{ paddingLeft: 10 }}>
                           <b>{t('memberDetails')}</b>
                        </Typography>
                     )}
                     <Paper style={{ width: '100%', padding: '5px', margin: 10 }}>
                        <div
                           style={{
                              maxHeight: '60vh',
                              padding: '0 10px 5px 15px',
                              overflow: 'hidden auto',
                           }}
                        >
                           {selectedTeamMemberForDetails.projects.length ? (
                              selectedTeamMemberForDetails.projects.map((project, i) => (
                                 <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={1}
                                    item
                                    xs={6}
                                    style={{ maxWidth: '100%' }}
                                    key={`member-details-${i}`}
                                 >
                                    <Accordion
                                       style={{
                                          width: '100%',
                                          backgroundColor: '#dae8fc',
                                          margin: '5px 0',
                                       }}
                                    >
                                       <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                          <b>{project.Name}</b>
                                       </AccordionSummary>
                                       <AccordionDetails>
                                          <Grid
                                             container
                                             justifyContent="flex-start"
                                             alignItems="center"
                                             spacing={2}
                                          >
                                             <Grid item xs={12}>
                                                <div className="d-flex align-items-center">
                                                   <Typography variant="body2">
                                                      {t('ProjectManager')}
                                                   </Typography>
                                                   {!isPMActiveUser(project.ProjectManager) && (
                                                      <Tooltip
                                                         title={'This PM is not an active user'}
                                                         placement="top"
                                                         leaveDelay={700}
                                                         arrow
                                                      >
                                                         <InfoIcon
                                                            className={`${classes.infoIcon}`}
                                                         />
                                                      </Tooltip>
                                                   )}
                                                </div>

                                                <TextField
                                                   value={
                                                      project.ProjectManager
                                                         ? isPMActiveUser(project.ProjectManager)
                                                            ? getAndWrapUser(project.ProjectManager)
                                                                 ?.label
                                                            : getAndWrapInactiveUsers(
                                                                 project.ProjectManager
                                                              )?.label
                                                         : ''
                                                   }
                                                   variant="outlined"
                                                   className={classes.textField}
                                                   disabled
                                                   style={{ backgroundColor: 'white' }}
                                                />
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography variant="body2">
                                                   {t('projectManagementProjectStartText')}
                                                </Typography>
                                                <TextField
                                                   value={
                                                      project.StartDate
                                                         ? moment(project.StartDate).format(
                                                              'DD.MM.YYYY'
                                                           )
                                                         : ''
                                                   }
                                                   variant="outlined"
                                                   className={classes.textField}
                                                   disabled
                                                   style={{ backgroundColor: 'white' }}
                                                />
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography variant="body2">
                                                   {t('projectManagementProjectEndText')}
                                                </Typography>
                                                <TextField
                                                   value={
                                                      project.EndDate
                                                         ? moment(project.EndDate).format(
                                                              'DD.MM.YYYY'
                                                           )
                                                         : ''
                                                   }
                                                   variant="outlined"
                                                   className={classes.textField}
                                                   disabled
                                                   style={{ backgroundColor: 'white' }}
                                                />
                                             </Grid>
                                             {project.filteredMembers
                                                .filter(
                                                   member =>
                                                      member._id ===
                                                      selectedTeamMemberForDetails._id
                                                )
                                                .map(user => (
                                                   <Fragment key={user._id}>
                                                      <Grid item xs={12}>
                                                         <hr
                                                            style={{ borderTop: '1px solid black' }}
                                                         ></hr>
                                                         <Typography variant="body2">
                                                            {t(
                                                               'manageMemberDetailsProjectRoleField'
                                                            )}
                                                         </Typography>
                                                         <TextField
                                                            value={
                                                               user.ProjectRole
                                                                  ? user.ProjectRole
                                                                  : ''
                                                            }
                                                            variant="outlined"
                                                            className={classes.textField}
                                                            disabled
                                                            style={{ backgroundColor: 'white' }}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={6}>
                                                         <Typography variant="body2">
                                                            {t('startDate')}
                                                         </Typography>
                                                         <TextField
                                                            value={
                                                               user.StartDate
                                                                  ? moment(user.StartDate).format(
                                                                       'DD.MM.YYYY'
                                                                    )
                                                                  : ''
                                                            }
                                                            variant="outlined"
                                                            className={classes.textField}
                                                            disabled
                                                            style={{ backgroundColor: 'white' }}
                                                         />
                                                      </Grid>
                                                      <Grid item xs={6}>
                                                         <Typography variant="body2">
                                                            {t('endDate')}
                                                         </Typography>
                                                         <TextField
                                                            value={
                                                               user.EndDate
                                                                  ? moment(user.EndDate).format(
                                                                       'DD.MM.YYYY'
                                                                    )
                                                                  : ''
                                                            }
                                                            variant="outlined"
                                                            className={classes.textField}
                                                            disabled
                                                            style={{ backgroundColor: 'white' }}
                                                         />
                                                      </Grid>
                                                   </Fragment>
                                                ))}
                                          </Grid>
                                       </AccordionDetails>
                                    </Accordion>
                                 </Grid>
                              ))
                           ) : (
                              <Typography
                                 style={{ padding: 10, fontSize: '1rem', fontWeight: '300' }}
                              >
                                 {t('userWithoutProjects')}
                              </Typography>
                           )}
                        </div>
                     </Paper>
                  </Grid>
               ) : selectedTeam.members.length ? (
                  <Typography style={{ padding: 70, fontSize: '1rem', fontWeight: '300' }}>
                     {t('selectTeamMember')}
                  </Typography>
               ) : (
                  ''
               )}
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 50, marginRight: 10 }}>
               <div style={{ float: 'right', marginBottom: '20 !important' }}>
                  <Button
                     size="large"
                     variant="contained"
                     color="primary"
                     onClick={saveTeam}
                     disabled={!haveUnsavedChanges}
                  >
                     {t('saveButtonTitle')}
                  </Button>
               </div>
            </Grid>
         </Paper>

         {Object.keys(selectedTeam).length > 0 && (
            <ManageTeamMembersModal
               openModal={openManageMembersModal}
               toggleModal={toggleManageMembersModal}
               setHaveUnsavedChanges={setHaveUnsavedChanges}
               activeUsers={props.activeUsers}
               selectedTeam={selectedTeam}
               setSelectedTeam={setSelectedTeam}
               getAndWrapUser={getAndWrapUser}
               isTeamLead={isTeamLead}
               filterNonMember={filterNonMember}
               setFilterNonMember={setFilterNonMember}
               userInfo={userInfo}
               teams={teams}
            />
         )}

         <CreateTeamModal
            openModal={openCreateTeamModal}
            toggleModal={toggleCreateModal}
            userInfo={userInfo}
            showSuccessfulSnackbar={showSuccessfulSnackbar}
            showErrorSnackbar={showErrorSnackBar}
            fetchTeams={id => fetchTeams(customer)}
            setSelectedTeam={setSelectedTeam}
            activeUsers={props.activeUsers}
            setSelectedTeamMemberForDetails={setSelectedTeamMemberForDetails}
         />

         <CustomDeleteModal
            title={t('deleteTeamModalTitle')}
            body={t('deleteTeamModalBody')}
            openModal={openDeleteModal}
            toggleModal={toggleDeleteModal}
            deleteAction={deleteTeamHandler}
         />

         <DiscardChangesModal
            openModal={openDiscardChangesModal}
            toggleModal={toggleDiscardModal}
            discardChanges={callbackFunction}
         />

         <Snackbar open={snackbarState.open} autoHideDuration={3000} onClose={closeSnackBar}>
            <Alert onClose={closeSnackBar} variant={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      notifs: state.notifs,
      users: state.users,
      activeUsers: state.activeUsers,
   }
}

export default withStyles(styles)(
   withTranslation()(connect(mapStateToProps, { fetchUsers, fetchActiveUsers })(TeamManagement))
)
