import React from 'react'
import { Box, Button, Divider, Typography, Modal } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Save as SaveIcon, Close as CloseIcon } from '@mui/icons-material'
const StyledSaveButton = styled(Button)({
   width: 170,
   height: 32,
   padding: '8px 16px',
   borderRadius: 4,
   boxShadow: 'none',
   backgroundColor: '#008DC6',
   fontWeight: 700,
   fontSize: 12,
   lineHeight: '16px',
   letterSpacing: '0em',
   textAlign: 'left',
   '&:hover': {
      backgroundColor: '#2D3A73',
      boxShadow: 'none',
   },
})

const StyledClearButton = styled(Button)({
   width: 170,
   height: 32,
   padding: '8px 16px',
   borderRadius: 4,
   border: '1.6px solid',
   fontWeight: 700,
   fontSize: 12,
   borderColor: '#E58C9C',
   color: '#E58C9C',
   '&:hover': {
      backgroundColor: '#2D3A73',
      borderColor: '#2D3A73',
      color: 'white',
   },
})

const TimesheetSaveFiltersModal = ({
   t,
   teamProjectFilters,
   userFilters,
   openModal,
   handleCloseModal,
   showSuccessToast,
}) => {
   const handleAddOrRemoveFilterToLocalStorage = (teamProjectFilters, userFilters) => {
      // let newAppliedFilters
      // if (!localStorage.getItem('hrAppliedFilters')) {
      //    newAppliedFilters = [option]
      // } else {
      //    let jsonLocalStorageArray = JSON.parse(
      //       localStorage.getItem('hrAppliedFilters')
      //    ).timesheetManagement
      //    if (jsonLocalStorageArray !== undefined) {
      //       newAppliedFilters = jsonLocalStorageArray.find(
      //          arrayOption => arrayOption.id === option.id
      //       )
      //          ? jsonLocalStorageArray.filter(arrayOption => arrayOption.id !== option.id)
      //          : [...jsonLocalStorageArray, option]
      //    } else newAppliedFilters = [option]
      // }
      const newFilterObject =
         localStorage.getItem('hrAppliedFilters') &&
         !Array.isArray(JSON.parse(localStorage.getItem('hrAppliedFilters')))
            ? JSON.parse(localStorage.getItem('hrAppliedFilters'))
            : {}

      newFilterObject.timesheetManagement = teamProjectFilters
      newFilterObject.timesheetManagementEmployee = userFilters
      localStorage.setItem('hrAppliedFilters', JSON.stringify(newFilterObject))
      showSuccessToast('Successfully saved new filters.')
      handleCloseModal()
   }

   return (
      <Modal
         open={openModal}
         onClose={handleCloseModal}
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
         sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
         <Box
            sx={{
               width: '756px',
               height: '254px',
               padding: '50px 100px 50px 100px',
               borderRadius: '8px',
               gap: ' 32px',
               backgroundColor: 'white',
               ml: '240px',
            }}
         >
            <Typography
               sx={{
                  textAlign: 'center',
                  mb: 4,
                  fontFamily: 'sans-serif',
                  fontSize: '17px',
                  fontWeight: 700,
                  lineHeight: '25px',
                  letterSpacing: '0em',
                  color: '#454545',
               }}
            >
               {t('modalSaveText')}
            </Typography>
            <Divider style={{ background: '#E0E0E0', margin: '0px 18px' }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0 100px', mt: 4 }}>
               <StyledClearButton
                  onClick={handleCloseModal}
                  variant="outlined"
                  startIcon={<CloseIcon />}
               >
                  {t('modalSaveNo')}
               </StyledClearButton>
               <StyledSaveButton
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={() =>
                     handleAddOrRemoveFilterToLocalStorage(teamProjectFilters, userFilters)
                  }
               >
                  {t('modalSaveYes')}
               </StyledSaveButton>
            </Box>
         </Box>
      </Modal>
   )
}
const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}

export default withTranslation()(connect(mapStateToProps)(TimesheetSaveFiltersModal))
