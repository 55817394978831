import React, { Fragment } from 'react'
import TableRow from '@mui/material/TableRow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Tooltip, TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { connect } from 'react-redux'
import { getAndWrapUser } from '../../../../util/wrappers'
import { getNickOrLabel } from '../../../../util/userProps'
import Rows from './components/Rows'
import FilteredRows from './components/FilteredRows'

const useStyles = makeStyles({
   noBorder: {
      border: 'none',
      boxShadow: 'none',
      zIndex: 0,
   },
   crissHover: {
      '&:hover': {
         color: '#',
         backgroundColor: '#FFeedd',
      },
   },
   weekIndicator: {
      backgroundColor: '#f1f1fb',
      color: '#bbaaee',
      padding: 2,
      maxWidth: 16,
   },
})

const DataRows = props => {
   const {
      t,
      filter,
      weekInfo,
      filterArray,
      teamOptions,
      wrappedUserData,
      getRelevantColourClass,
      getColour,
      setAbsenceDialogState,
      iconState,
      getTouchingAbsences,
      absenceSeverity,
      getTooltip,
      projects,
      teams,
      state,
   } = props

   const classes = useStyles()

   const stripeRowCheck = userIndex => {
      return state.userInfo.Settings.StripedOverview
         ? userIndex % 2 ===
              (state.userInfo.Settings.GroupedOverview ? (Math.floor(userIndex / 5) % 2 === 0 ? 1 : 0) : 1)
         : false
   }

   const characterFilter = (person, filter) => {
      return person
         ? `${person?.label}|${person?.nick}|${person.email.split('@')[0]}`
              .toLowerCase()
              .includes(filter)
         : ''
   }

   const getIcon = severity => {
      return iconState.currentIconScheme.scheme[severity]
   }

   const getClickable = severity => {
      return state.userInfo.admin && severity > 0 && severity < 3 ? { cursor: 'pointer' } : null
   }

   const showAbsencesForDay = (severity, user, weekday, list) => {
      if (!state.userInfo.admin || severity < 1 || severity > 2) return
      if (list.length < 1) return
      const date = `${weekday.getDate()}/${weekday.getMonth() + 1}`
      const name = getNickOrLabel(user)
      setAbsenceDialogState({ show: true, absences: list, user: name, date: date })
   }

   const getWeekDayAbsence = (week, person, weekInfo) => {
      return (
         <Fragment key={`weekdayabsence${week.number}${person.id}`}>
            <TableCell
               align={'center'}
               className={clsx(classes.weekIndicator, classes.noBorder)}
               style={{ minWidth: 20 }}
            />

            {week.dates.map(weekDate => {
               const dateIndex = weekInfo.weekDates.indexOf(weekDate)
               const touchingAbsences = getTouchingAbsences(dateIndex, person.id, weekInfo)
               const severity = absenceSeverity(dateIndex, person.id, touchingAbsences, weekInfo)
               return (
                  <Tooltip
                     key={`tooltip${weekDate}${person.id}`}
                     style={{ whiteSpace: 'pre-line' }}
                     arrow={true}
                     title={getTooltip(severity, dateIndex, person.id, touchingAbsences, weekInfo)}
                  >
                     <TableCell
                        className={clsx(getRelevantColourClass(weekDate))}
                        align={'center'}
                        style={{ paddingLeft: 12, paddingRight: 12, minWidth: 36, maxWidth: 36 }}
                     >
                        <FontAwesomeIcon
                           style={getClickable(severity)}
                           icon={getIcon(severity)}
                           color={getColour(severity)}
                           onClick={() =>
                              showAbsencesForDay(severity, person, weekDate, touchingAbsences)
                           }
                        />
                     </TableCell>
                  </Tooltip>
               )
            })}
         </Fragment>
      )
   }

   const teamMembers =
      teamOptions.length === 1 || filterArray.length === 0
         ? null
         : filterArray
              .map(array =>
                 array.members
                    .map(person => getAndWrapUser(wrappedUserData, person))
                    .filter(person => person)
              )
              .flat()

   const filteredUsers = (teamMembers !== null ? teamMembers : wrappedUserData).filter(person =>
      characterFilter(person, filter)
   )

   if (filteredUsers.length === 0 && filter.length > 0) {
      return (
         <TableRow key="noresult" className={clsx(classes.crissHover)} style={{ height: 100 }}>
            <TableCell className={clsx(classes.noBorder)} style={{ minWidth: 200 }}>
               {t('noResultsFound')}
            </TableCell>
         </TableRow>
      )
   }

   const rowsProps = {
      teams,
      projects,
      weekInfo,
      wrappedUserData,
      stripeRowCheck,
      getWeekDayAbsence,
   }

   return filterArray.length !== 0 ? (
      <FilteredRows rowsProps={{ ...rowsProps, filter, filterArray, characterFilter }} />
   ) : (
      <Rows rowsProps={{ ...rowsProps, filteredUsers }} />
   )
}

const mapStateToProps = state => {
   return {
      state,
   }
}

export default withTranslation()(connect(mapStateToProps)(DataRows))
