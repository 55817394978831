import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { fetchBankHolidays, fetchCustomerForAdmin, fetchGenericData, fetchSalaryReport } from '../Api';
import SalaryReportHTMLNovartoBulgaria from './salaryreports/novartobulgaria/SalaryReportHTMLNovartoBulgaria';
import SalaryReportHTMLNovartoNordic from './salaryreports/novartonordic/SalaryReportHTMLNovartoNordic';
import DefaultHTMLReport from './salaryreports/default/DefaultHTMLReport';
import { employmentIsMissingCostCenter, getEmploymentsForDate, useEffectOnlyOnce } from '../util/helpers';
import log from '../util/log';

import { withTranslation } from 'react-i18next'


const SalaryReportHTML = (props) => {
  const { id } = useParams();

  const components = {
    NovartoNordic: SalaryReportHTMLNovartoNordic,
    NovartoBulgaria: SalaryReportHTMLNovartoBulgaria,
    Default: DefaultHTMLReport
  };

  const userInfo = useSelector(state => state.userInfo);
  const [customer, setCustomer] = React.useState(null);
  const [report, setReport] = React.useState(null);
  const [info, setInfo] = React.useState(null);
  const [costCenters, setCostCenters] = React.useState([]);
  const [hasLackingCostCenter, setHasLackingCostCenter] = React.useState(false);
  const [hasUnemployed, setHasUnemployed] = React.useState(false);
  const [holidays, setHolidays] = React.useState([]);

  useEffectOnlyOnce(() => {
    const loadData = async () => {
      try{
        log.info("Fetching customer...");
        const result = await fetchCustomerForAdmin(userInfo);
        log.info("Got result: "+JSON.stringify(result));
        setCustomer(result.data);
      }catch(err){
        log.error(err);
      }

      const result = await fetchSalaryReport(userInfo, id);
      if (result.data && result.data.success && result.data.reports) {
        let report = result.data.reports[0];
        setReport(report);
        checkNoEmployment(report);
        checkNoCostCenter(report);
      }
      else {
        setInfo(JSON.stringify(result));
      }

      const data = await fetchGenericData(userInfo, "costCenterModel");
      setCostCenters(data);
      await fetchHolidays();
    }
    loadData();
  });

  const checkNoCostCenter = useCallback((report) => {
    setHasLackingCostCenter(report.Data.Users
            .some((user) => {
              const employments = getEmploymentsForDate(user.Employments, report.EndDate);
              return employments != null && employmentIsMissingCostCenter(employments[0], costCenters) ? user : null;
            }));
  }, [costCenters]);

  const checkNoEmployment = (report) => {
    setHasUnemployed(report.Data.Users
            .some((user) => {
              const employments = getEmploymentsForDate(user.Employments, report.EndDate);
              return employments == null ? user : null;
            }));
  };

  const fetchHolidays = useCallback(async () => {
    await fetchBankHolidays(userInfo).then((res)=> {
        if(res.success === "false") {
            log.error("Failure to load data; try logging out and in again: ");
        } else {
            setHolidays(res);
        }
    });
  }, [userInfo]);

  if (!customer || !customer.Features) return '';

  const DynamicReportComponent = components[customer.Features.SalaryReportVariant];

  if(!DynamicReportComponent) return 'Report variant not found. Please contact Novarto super admin.';

  return (
      <DynamicReportComponent
        {...props}
        report={report}
        info={info}
        costCenters={costCenters}
        hasLackingCostCenter={hasLackingCostCenter}
        hasUnemployed={hasUnemployed} 
        holidays={holidays}
        userVerificationRelevant={customer.Features.VerifyAbsence}
      />
  );
};

export default withTranslation()(SalaryReportHTML);