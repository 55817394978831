import Badge from '@mui/material/Badge'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import withStyles from '@mui/styles/withStyles'
import HomeIcon from '@mui/icons-material/Home'
import AssessmentIcon from '@mui/icons-material/Assessment'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import DescriptionIcon from '@mui/icons-material/Description'
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { drawerWidth } from './theme'
import { fetchCustomerData } from './redux/actions/actions'
import { withTranslation } from 'react-i18next'
import {
   faAddressCard,
   faBookmark,
   faCalendar,
   faCalendarDay,
   faChartPie,
   faCogs,
   faCrown,
   faEdit,
   faEnvelope,
   faEnvelopeOpenText,
   faFileContract,
   faNotesMedical,
   faUserCog,
   faUserFriends,
   faUsers,
   faHatWizard,
   faHourglassHalf,
} from '@fortawesome/free-solid-svg-icons'
import PerformanceIcon from './components/icons/PerformanceIcon'

const styles = theme => ({
   categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
   categoryHeaderPrimary: {
      color: theme.palette.common.white,
   },
   item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: 'rgba(255, 255, 255, 0.7)',
      fontSize: '14px',
      '&:hover,&:focus': {
         backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
      '& .MuiSvgIcon-root': {
         color: 'rgba(255, 255, 255, 0.7)',
         fontSize: '18px',
      },
      '& .MuiListItemIcon-root': {
         color: 'rgba(255, 255, 255, 0.7)',
         fontSize: '14px',
      },
   },
   itemCategory: {
      backgroundColor: '#0d2540',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
   },
   headerTitle: {
      backgroundColor: '#0d2540',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: 16,
      color: theme.palette.common.white,
   },
   firebase: {
      fontSize: 24,
      color: theme.palette.common.white,
   },
   itemActiveItem: {
      color: '#4fc3f7',
      '& .MuiSvgIcon-root': {
         color: '#4fc3f7',
      },
      '& .MuiListItemIcon-root': {
         color: '#4fc3f7',
      },
   },
   itemPrimary: {
      fontSize: 'inherit',
   },
   itemIcon: {
      minWidth: 'auto',
      width: '1vw',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginRight: theme.spacing(1.5),
   },
   divider: {
      marginTop: theme.spacing(2),
   },
   navigatorAdminTag: {
      fontSize: '10px',
      color: '#8b8b8b',
   },
   logo: {
      margin: '10px 25px',
   },
})

const StyledBadge = withStyles(theme => ({
   badge: {
      right: '1vw',
      top: 3,
      //border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 2px',
   },
}))(Badge)

function Navigator(props) {
   const { navItem, classes, t, userInfo, fetchCustomerData } = props

   const overviewIcon = () => <HomeIcon />

   const myPageIcon = () => <FontAwesomeIcon icon={faUserCog} style={{ fontSize: '12px' }} />
   const requestAbsenceIcon = () => (
      <FontAwesomeIcon icon={faEnvelopeOpenText} style={{ fontSize: '14px' }} />
   )
   const reportAbsenceIcon = () => (
      <FontAwesomeIcon icon={faNotesMedical} style={{ fontSize: '14px' }} />
   )
   const myEmploymentIcon = () => <FontAwesomeIcon icon={faCalendar} style={{ fontSize: '14px' }} />

   const employeeManagementIcon = () => (
      <FontAwesomeIcon icon={faUserFriends} style={{ fontSize: '14px' }} />
   )
   const teamManagementIcon = () => <FontAwesomeIcon icon={faUsers} style={{ fontSize: '14px' }} />
   const projectIcon = () => <AssessmentIcon />
   const timesheetManagementIcon = () => <QueryBuilderIcon />
   const projectReportsIcon = () => <DescriptionIcon />
   const skillsIcon = () => <FontAwesomeIcon icon={faHatWizard} style={{ fontSize: '14px' }} />
   const employmentManagementIcon = () => (
      <FontAwesomeIcon icon={faAddressCard} style={{ fontSize: '14px' }} />
   )
   const absenceManagementIcon = () => (
      <FontAwesomeIcon icon={faEdit} style={{ fontSize: '12px' }} />
   )
   const absenceRequestManagementIcon = () => (
      <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '14px' }} />
   )
   const costCenterIcon = () => <FontAwesomeIcon icon={faChartPie} style={{ fontSize: '14px' }} />
   const holidaysIcon = () => <FontAwesomeIcon icon={faCalendarDay} style={{ fontSize: '14px' }} />
   const payIcon = () => <FontAwesomeIcon icon={faFileContract} style={{ fontSize: '14px' }} />
   const settingsIcon = () => <FontAwesomeIcon icon={faCogs} style={{ fontSize: '14px' }} />

   const customersIcon = () => (
      <FontAwesomeIcon icon={faBookmark} color="deeppink" style={{ fontSize: '15px' }} />
   )
   const superAdminIcon = () => (
      <FontAwesomeIcon icon={faCrown} color="gold" style={{ fontSize: '14px' }} />
   )

   const timesheetIcon = () => (
      <FontAwesomeIcon icon={faHourglassHalf} style={{ fontSize: '14px' }} />
   )

   const performanceIcon = () => <PerformanceIcon color={'rgba(255, 255, 255, 0.7)'} />

   useEffect(() => {
      // fetch the current customer.
      fetchCustomerData(userInfo)
   }, [userInfo, fetchCustomerData])

   // if(!props.customer) return '';

   return (
      <Drawer
         variant="permanent"
         PaperProps={{ style: { width: drawerWidth, backgroundColor: '#0d2540' } }}
      >
         <List disablePadding>
            <ListItem className={clsx(classes.headerTitle)}>
               <img
                  style={{ width: 150 }}
                  src="/novarto-logo.png"
                  className={clsx(classes.logo)}
               ></img>
            </ListItem>
            <Link to="/">
               <ListItem
                  className={clsx(
                     classes.item,
                     classes.itemCategory,
                     navItem === 'dashboard' ? classes.itemActiveItem : ''
                  )}
               >
                  <ListItemIcon className={classes.itemIcon}>{overviewIcon()}</ListItemIcon>
                  <ListItemText
                     classes={{
                        primary: classes.itemPrimary,
                     }}
                  >
                     {t('titleDashboard')}
                  </ListItemText>
               </ListItem>
            </Link>

            <ListItem className={classes.categoryHeader}>
               <ListItemText
                  classes={{
                     primary: classes.categoryHeaderPrimary,
                  }}
               >
                  {t('titleMyservices')}
               </ListItemText>
            </ListItem>
            <Link to={'/mypage/'}>
               <ListItem
                  button
                  className={clsx(classes.item, navItem === 'mypage' ? classes.itemActiveItem : '')}
               >
                  {props.notifs.myPageBadge > 0 ? (
                     <StyledBadge
                        variant={'dot'}
                        badgeContent={props.notifs.myPageBadge}
                        color="error"
                        overlap="rectangular"
                     >
                        <ListItemIcon className={classes.itemIcon}>{myPageIcon()}</ListItemIcon>
                     </StyledBadge>
                  ) : (
                     <ListItemIcon className={classes.itemIcon}>{myPageIcon()}</ListItemIcon>
                  )}

                  <ListItemText
                     classes={{
                        primary: classes.itemPrimary,
                     }}
                  >
                     {t('titleMypage')}
                  </ListItemText>
               </ListItem>
            </Link>
            <Link to={'/timesheet/'}>
               <ListItem
                  button
                  className={clsx(
                     classes.item,
                     navItem === 'timesheet' ? classes.itemActiveItem : ''
                  )}
               >
                  <ListItemIcon className={classes.itemIcon}>{timesheetIcon()}</ListItemIcon>
                  <ListItemText
                     classes={{
                        primary: classes.itemPrimary,
                     }}
                  >
                     {t('timesheetComponentName')}
                  </ListItemText>
               </ListItem>
            </Link>

            <Link to={'/requestabsence'}>
               <ListItem
                  button
                  className={clsx(
                     classes.item,
                     navItem === 'requestabsence' ? classes.itemActiveItem : ''
                  )}
               >
                  {props.notifs.myAbsenceRequestBadge > 0 ? (
                     <StyledBadge
                        variant={'dot'}
                        badgeContent={1}
                        overlap="rectangular"
                        color="error"
                     >
                        <ListItemIcon className={classes.itemIcon}>
                           {requestAbsenceIcon()}
                        </ListItemIcon>
                     </StyledBadge>
                  ) : (
                     <ListItemIcon className={classes.itemIcon}>
                        {requestAbsenceIcon()}
                     </ListItemIcon>
                  )}
                  <ListItemText
                     classes={{
                        primary: classes.itemPrimary,
                     }}
                  >
                     {t('titleRequestAbsence')}
                  </ListItemText>
               </ListItem>
            </Link>
            <Link to={'/reportabsence/'}>
               <ListItem
                  button
                  className={clsx(
                     classes.item,
                     navItem === 'reportabsence' ? classes.itemActiveItem : ''
                  )}
               >
                  <ListItemIcon className={classes.itemIcon}>{reportAbsenceIcon()}</ListItemIcon>
                  <ListItemText
                     classes={{
                        primary: classes.itemPrimary,
                     }}
                  >
                     {t('titleReportAbsence')}
                  </ListItemText>
               </ListItem>
            </Link>
            <Link to={'/myemployments/'}>
               <ListItem
                  button
                  className={clsx(
                     classes.item,
                     navItem === 'myemployments' ? classes.itemActiveItem : ''
                  )}
               >
                  {props.notifs.myEmploymentsBadge > 0 ? (
                     <StyledBadge
                        variant={'dot'}
                        badgeContent={1}
                        overlap="rectangular"
                        color="error"
                     >
                        <ListItemIcon className={classes.itemIcon}>
                           {myEmploymentIcon()}
                        </ListItemIcon>
                     </StyledBadge>
                  ) : (
                     <ListItemIcon className={classes.itemIcon}>{myEmploymentIcon()}</ListItemIcon>
                  )}
                  <ListItemText
                     classes={{
                        primary: classes.itemPrimary,
                     }}
                  >
                     {t('titleMyEmployments')}
                  </ListItemText>
               </ListItem>
            </Link>
            <a href="https://performance-platform.novarto.com/login" target="_blank">
               <ListItem className={clsx(classes.item)}>
                  <ListItemIcon className={classes.itemIcon}>{performanceIcon()}</ListItemIcon>
                  <ListItemText
                     classes={{
                        primary: classes.itemPrimary,
                     }}
                  >
                     {t('performancePlatform')}
                  </ListItemText>
               </ListItem>
            </a>

            <Divider className={classes.divider} />

            {(userInfo.admin || userInfo.manager || userInfo.backofficeAdmin) && (
               <ListItem className={classes.categoryHeader}>
                  <ListItemText
                     classes={{
                        primary: classes.categoryHeaderPrimary,
                     }}
                  >
                     {t('titleAdministration')}{' '}
                     <span className={clsx(classes.navigatorAdminTag)}>{t('adminTag')}</span>
                  </ListItemText>
               </ListItem>
            )}

            {userInfo.permissions['employeesManagement']['read'] && (
               <Link to={'/users/'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'users' ? classes.itemActiveItem : ''
                     )}
                  >
                     <ListItemIcon className={classes.itemIcon}>
                        {employeeManagementIcon()}
                     </ListItemIcon>
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('titleUsers')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {userInfo.permissions['teamsManagement']['read'] && (
               <Link to={'/teams/'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'teams' ? classes.itemActiveItem : ''
                     )}
                  >
                     <ListItemIcon className={classes.itemIcon}>
                        {teamManagementIcon()}
                     </ListItemIcon>
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('titleTeams')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {userInfo.permissions['projectsManagement']['read'] && (
               <Link to={'/projects/'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'projects' ? classes.itemActiveItem : ''
                     )}
                  >
                     <ListItemIcon className={classes.itemIcon}>{projectIcon()}</ListItemIcon>
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('projectManagement')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {userInfo.permissions['timesheetManagement']['read'] && (
               <Link to={'/timesheetManagement/'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'timesheetManagement' ? classes.itemActiveItem : ''
                     )}
                  >
                     <ListItemIcon className={classes.itemIcon}>
                        {timesheetManagementIcon()}
                     </ListItemIcon>
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('timesheetManagementTitle')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {userInfo.permissions['skillsManagement']['read'] && (
               <Link to={'/skills/'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'skills' ? classes.itemActiveItem : ''
                     )}
                  >
                     <ListItemIcon className={classes.itemIcon}>{skillsIcon()}</ListItemIcon>
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('titleSkills')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {userInfo.permissions['employmentsManagement']['read'] && (
               <Link to={'/employments/'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'employments' ? classes.itemActiveItem : ''
                     )}
                  >
                     <ListItemIcon className={classes.itemIcon}>
                        {employmentManagementIcon()}
                     </ListItemIcon>
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('titleEmployments')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {userInfo.permissions['absencesManagement']['read'] && (
               <Link to={'/absences/'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'absences' ? classes.itemActiveItem : ''
                     )}
                  >
                     {props.notifs.allOngoingAbsenceBadge > 0 ? (
                        <StyledBadge
                           overlap="rectangular"
                           variant={'dot'}
                           badgeContent={props.notifs.allOngoingAbsenceBadge}
                           color="error"
                        >
                           <ListItemIcon className={classes.itemIcon}>
                              {absenceManagementIcon()}
                           </ListItemIcon>
                        </StyledBadge>
                     ) : (
                        <ListItemIcon className={classes.itemIcon}>
                           {absenceManagementIcon()}
                        </ListItemIcon>
                     )}
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('titleAbsences')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {userInfo.permissions['absenceRequests']['read'] && (
               <Link to={'/absencerequests/'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'absencerequests' ? classes.itemActiveItem : ''
                     )}
                  >
                     {props.notifs.allAbsenceRequestBadge > 0 ? (
                        <StyledBadge
                           overlap="rectangular"
                           variant={'dot'}
                           badgeContent={props.notifs.allAbsenceRequestBadge}
                           color="error"
                        >
                           <ListItemIcon className={classes.itemIcon}>
                              {absenceRequestManagementIcon()}
                           </ListItemIcon>
                        </StyledBadge>
                     ) : (
                        <ListItemIcon className={classes.itemIcon}>
                           {absenceRequestManagementIcon()}
                        </ListItemIcon>
                     )}
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('titleAbsenceRequests')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {userInfo.permissions['reportsAndPlaning']['read'] && (
               <Link to={'/reportsAndPlaning/'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'reportsAndPlaning' ? classes.itemActiveItem : ''
                     )}
                  >
                     <BarChartOutlinedIcon className={classes.itemIcon}>
                        {absenceRequestManagementIcon()}
                     </BarChartOutlinedIcon>

                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('reportsAndPlaningTitle')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}
            {userInfo.permissions['costCenters']['read'] && (
               <Link to={'/costcenters/'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'costcenters' ? classes.itemActiveItem : ''
                     )}
                  >
                     <ListItemIcon className={classes.itemIcon}>{costCenterIcon()}</ListItemIcon>
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('titleCostCenters')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {userInfo.permissions['bankHolidays']['read'] && (
               <Link to={'/bankholidays/'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'bankholidays' ? classes.itemActiveItem : ''
                     )}
                  >
                     <ListItemIcon className={classes.itemIcon}>{holidaysIcon()}</ListItemIcon>
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('titleBankHolidays')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {userInfo.permissions['projectReports']['read'] && (
               <Link to={'/projectreports'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'projectreports' ? classes.itemActiveItem : ''
                     )}
                  >
                     <ListItemIcon className={classes.itemIcon}>
                        {projectReportsIcon()}
                     </ListItemIcon>
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('titleProjectReports')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {userInfo.permissions['salaryReports']['read'] && (
               <Link to={'/salaryreports'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'salaryreports' ? classes.itemActiveItem : ''
                     )}
                  >
                     <ListItemIcon className={classes.itemIcon}>{payIcon()}</ListItemIcon>
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('titleSalaryReports')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {userInfo.permissions['settings']['read'] && (
               <Link to={'/customersettings'}>
                  <ListItem
                     button
                     className={clsx(
                        classes.item,
                        navItem === 'customersettings' ? classes.itemActiveItem : ''
                     )}
                  >
                     <ListItemIcon className={classes.itemIcon}>{settingsIcon()}</ListItemIcon>
                     <ListItemText
                        classes={{
                           primary: classes.itemPrimary,
                        }}
                     >
                        {t('titleCustomerSettings')}
                     </ListItemText>
                  </ListItem>
               </Link>
            )}

            {(userInfo.admin || userInfo.manager || userInfo.backofficeAdmin) && (
               <Divider className={classes.divider + ' my-3'} />
            )}

            {props.userInfo.superAdmin && (
               <React.Fragment>
                  <ListItem className={classes.categoryHeader}>
                     <ListItemText
                        classes={{
                           primary: classes.categoryHeaderPrimary,
                        }}
                     >
                        {t('titleCockpit')}{' '}
                        <span className={clsx(classes.navigatorAdminTag)}>
                           {t('superAdminTag')}
                        </span>
                     </ListItemText>
                  </ListItem>
                  <Link to={'/customers'}>
                     <ListItem
                        button
                        className={clsx(
                           classes.item,
                           navItem === 'customers' ? classes.itemActiveItem : ''
                        )}
                     >
                        <ListItemIcon className={classes.itemIcon}>{customersIcon()}</ListItemIcon>
                        <ListItemText
                           classes={{
                              primary: classes.itemPrimary,
                           }}
                        >
                           {t('titleCustomers')}
                        </ListItemText>
                     </ListItem>
                  </Link>
                  <Link to={'/superadmins'}>
                     <ListItem
                        button
                        className={clsx(
                           classes.item,
                           navItem === 'superadmins' ? classes.itemActiveItem : ''
                        )}
                     >
                        <ListItemIcon className={classes.itemIcon}>{superAdminIcon()}</ListItemIcon>
                        <ListItemText
                           classes={{
                              primary: classes.itemPrimary,
                           }}
                        >
                           {t('titleSuperAdmins')}
                        </ListItemText>
                     </ListItem>
                  </Link>
                  <Divider className={classes.divider} style={{ marginBottom: 50 }} />
               </React.Fragment>
            )}
         </List>
      </Drawer>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      notifs: state.notifs,
      company: state.company,
      hiddenMenuOptions: state.hiddenMenuOptions,
   }
}

export default withStyles(styles)(
   withTranslation()(connect(mapStateToProps, { fetchCustomerData })(Navigator))
)
