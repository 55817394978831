import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   CircularProgress,
   Typography,
} from '@mui/material'
import { withTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ProjectBanner from '../UI/ProjectBanner'
import { useEffect, useState } from 'react'
import { isUpdated } from '../../../../../util/helpers'

const ProjectReportProjects = ({ projects, projectFilters, filter, isLoading, t }) => {
   const [filteredProjects, setFilteredProjects] = useState([])
   const [expanded, setExpanded] = useState({ external: true, internal: true })

   const handleChange = type => {
      setExpanded(prev => ({
         ...prev,
         [type]: !prev[type],
      }))
   }

   const activeOrClosedProjects = projects.filter(
      project => project.Status === 'Active' || project.Status === 'Closed'
   )
   useEffect(() => {
      let filtered = activeOrClosedProjects.filter(project => {
         const areFiltersSet = Object.values(projectFilters).some(value => value === true)

         if (!areFiltersSet) {
            return true
         }
         return (
            projectFilters[project.Status.toLowerCase()] ||
            (projectFilters.notUpdated && !isUpdated(project.UpdateDate))
         )
      })

      if (projectFilters.sortBy === 'recentlyUpdated') {
         filtered.sort((a, b) => new Date(b.UpdateDate) - new Date(a.UpdateDate))
      } else if (projectFilters.sortBy === 'name') {
         filtered.sort((a, b) => a.Name.localeCompare(b.Name))
      } else if (projectFilters.sortBy === 'projectManager') {
         filtered.sort((a, b) => a.PMname.localeCompare(b.PMname, 'bg'))
      }

      if (filter) {
         filtered = filtered.filter(project =>
            project.Name.toLowerCase().includes(filter.toLowerCase())
         )
      }

      setFilteredProjects(filtered)
   }, [projects, projectFilters, filter])

   const externalProjects = filteredProjects.filter(project => project.Type === 'External')

   const internalProjects = filteredProjects.filter(project => project.Type === 'Internal')

   return (
      <Box sx={{ p: '0 40px', width: '100%' }}>
         <Accordion
            elevation={0}
            expanded={expanded.external}
            onChange={() => handleChange('external')}
         >
            <AccordionSummary
               sx={{
                  p: '0 !important',
                  '&:hover': {
                     backgroundColor: '#EDF6FA99',
                  },
               }}
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#008DC6' }}>
                     {t('external')}
                  </Typography>
                  <Typography sx={{ color: '#B8B8B8', ml: '4px' }}>
                     ({externalProjects.length})
                  </Typography>
               </Box>
            </AccordionSummary>

            <AccordionDetails sx={{ p: '20px 0 !important', display: 'flex' }}>
               <Box
                  sx={{
                     display: 'flex',
                     flex: '0 0 100%',
                     justifyContent: 'flex-start',
                     flexWrap: 'wrap',
                     gap: '15px',
                  }}
               >
                  {externalProjects.length ? (
                     externalProjects.map(project => {
                        return <ProjectBanner key={project._id} project={project} type="external" />
                     })
                  ) : (
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           bgcolor: '#EDF6FA',
                           width: '100%',
                           height: '70px',
                        }}
                     >
                        {isLoading ? (
                           <CircularProgress />
                        ) : (
                           <Typography sx={{ color: '#008DC6', fontWeight: 600, fontSize: 15 }}>
                              {t('noExternal')}
                           </Typography>
                        )}
                     </Box>
                  )}
               </Box>
            </AccordionDetails>
         </Accordion>

         <Accordion
            elevation={0}
            expanded={expanded.internal}
            onChange={() => handleChange('internal')}
         >
            <AccordionSummary
               sx={{
                  p: '0 !important',
                  '&:hover': {
                     backgroundColor: '#EDF6FA99',
                  },
               }}
               expandIcon={<ExpandMoreIcon />}
               aria-controls="panel1a-content"
               id="panel1a-header"
            >
               <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 600, color: '#008DC6' }}>
                     {t('internal')}
                  </Typography>
                  <Typography sx={{ color: '#B8B8B8', ml: '4px' }}>
                     ({internalProjects.length})
                  </Typography>
               </Box>
            </AccordionSummary>

            <AccordionDetails sx={{ p: '20px 0 !important' }}>
               <Box
                  sx={{
                     display: 'flex',
                     flex: '0 0 100%',
                     justifyContent: 'flex-start',
                     flexWrap: 'wrap',
                     gap: '15px',
                  }}
               >
                  {internalProjects.length ? (
                     internalProjects.map(project => {
                        return <ProjectBanner key={project._id} project={project} type="internal" />
                     })
                  ) : (
                     <Box
                        sx={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           bgcolor: '#EDF6FA',
                           width: '100%',
                           height: '70px',
                        }}
                     >
                        {isLoading ? (
                           <CircularProgress />
                        ) : (
                           <Typography sx={{ color: '#008DC6', fontWeight: 600, fontSize: 15 }}>
                              {t('noInternal')}
                           </Typography>
                        )}
                     </Box>
                  )}
               </Box>
            </AccordionDetails>
         </Accordion>
      </Box>
   )
}

export default withTranslation()(ProjectReportProjects)
