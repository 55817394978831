import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Button, Divider, Modal } from '@mui/material'
import { withTranslation } from 'react-i18next'

function getModalStyle() {
   return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
   }
}

const useStyles = makeStyles(theme => ({
   paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '0px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: '10px',
   },
   body: {
      border: '0px',
      marginBottom: '20px',
   },
   buttonsPosition: {
      float: 'right',
   },
   marginBottom: {
      marginBottom: '10px',
   },
   dividerPosition: {
      marginBottom: '10px',
      marginTop: '10px',
   },
   dividerColor: {
      backgroundColor: '#e6e6e6',
   },
}))

const ErrorDeletionEmployeeModal = ({ openModal, teams, projects, toggleModal, t }) => {
   const classes = useStyles()
   const [modalStyle] = React.useState(getModalStyle)

   return (
      <Modal
         open={openModal}
         onClose={toggleModal}
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
      >
         <div style={modalStyle} className={classes.paper}>
            <h5 id="simple-modal-title" className={classes.marginBottom}>
               {t('DELETE_PERSONNEL')}
            </h5>
            <div className={classes.body}>
               <p>{t('errorDeletionEmployeeModalTitle')}</p>
            </div>
            {teams.length > 0 && (
               <div>
                  <h6>{t('errorDeletionEmployeeModalTeamsTitle')} </h6>
                  {teams.map(team => (
                     <ul key={team._id}>
                        <li>{`${team.Name}`}</li>
                     </ul>
                  ))}
               </div>
            )}
            {teams.length > 0 && (
               <Divider className={`${classes.dividerPosition} ${classes.dividerColor}`} />
            )}
            {projects.length > 0 && (
               <div>
                  <h6>{t('errorDeletionEmployeeModalProjectsTitle')}</h6>
                  {projects.map(project => (
                     <ul key={project._id}>
                        <li>{`${project.Name}`}</li>
                     </ul>
                  ))}
               </div>
            )}
            {projects.length > 0 && (
               <Divider className={`${classes.dividerPosition} ${classes.dividerColor}`} />
            )}
            <div>{t('errorDeletionEmployeeModalProjectsSubText')}</div>
            <div className={classes.buttonsPosition}>
               <Button size="small" variant="contained" color="secondary" onClick={toggleModal}>
                  {t('closeButtonTitle')}
               </Button>
            </div>
         </div>
      </Modal>
   )
}

export default withTranslation()(ErrorDeletionEmployeeModal)
