import { withTranslation } from 'react-i18next'
import { Box } from '@mui/material'

/**
 * Step component that represents an individual step in a multi-step process.
 *
 * @param {Object} props - The props object.
 * @param {number} props.stepNumber - The number associated with this step (e.g., 1, 2, 3).
 * @param {React.Element} props.icon - The icon to display alongside the step.
 * @param {'todo' | 'in-progress' | 'done'} props.status - The status of the step. Possible values are:
 * @param {string} props.children - The content or description to display for this step.
 * @returns {JSX.Element} The rendered step component.
 */
const Step = ({ stepNumber, icon, status, children }) => {
   let iconBackgroundColor, iconBorderColor, iconColor
   let stepTitleColor, stepDescriptionColor

   switch (status) {
      case 'todo':
         iconBackgroundColor = 'transparent'
         iconBorderColor = '#52B3DD'
         iconColor = '#52B3DD'
         stepTitleColor = '#B3B3B3'
         stepDescriptionColor = '#B3B3B3'
         break
      case 'in-progress':
         iconBackgroundColor = '#0083BB'
         iconBorderColor = '#0083BB'
         iconColor = '#FFFFFF'
         stepTitleColor = '#0083BB'
         stepDescriptionColor = '#454545'
         break
      case 'done':
         iconBackgroundColor = '#52B3DD'
         iconBorderColor = '#52B3DD'
         iconColor = '#FFFFFF'
         stepTitleColor = '#52B3DD'
         stepDescriptionColor = '#454545'
         break
   }

   return (
      <Box
         sx={{
            width: '240px',
            height: '120px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
         }}
      >
         <Box
            sx={{
               width: '60px',
               height: '60px',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               border: '4px solid',
               borderColor: iconBorderColor,
               borderRadius: '50px',
               backgroundColor: iconBackgroundColor,
               color: iconColor,
            }}
         >
            {icon}
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ fontSize: '20px', fontWeight: '600', color: stepTitleColor }}>
               Step {stepNumber}
            </Box>
            <Box
               sx={{
                  maxWidth: '250px',
                  fontSize: '15px',
                  fontWeight: '600',
                  color: stepDescriptionColor,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
               }}
               title={children}
            >
               {children}
            </Box>
         </Box>
      </Box>
   )
}
export default withTranslation()(Step)
