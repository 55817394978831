import React from 'react'
import Select from 'react-select'
import { wrapUser } from '../util/wrappers'
import log from '../util/log'
import { withTranslation } from 'react-i18next'

const UserDropdown = ({ ...props }) => {
   /**
    * Return an array of wrapped user objects also sorted
    */
   const getOptions = () => {
      const wraps = props.users.map(user => {
         return wrapUser(user)
      })
      log.debug('   wraps   ' + JSON.stringify(wraps))
      const sorted = wraps.sort((u1, u2) =>
         u1.label === u2.label ? 0 : u1.label < u2.label ? -1 : 1
      )
      log.debug('   sorted   ' + JSON.stringify(wraps))

      return sorted
   }
   const getInactiveOptions = () => {
      const wraps = props.inactiveUsers?.map(user => {
         return wrapUser(user)
      })
      log.debug('   wraps   ' + JSON.stringify(wraps))
      const sorted = wraps?.sort((u1, u2) =>
         u1.label === u2.label ? 0 : u1.label < u2.label ? -1 : 1
      )
      log.debug('   sorted   ' + JSON.stringify(wraps))

      return sorted
   }
   const options = [
      {
         label: '---- ACTIVE MEMBERS----',
         options: getOptions(),
      },
      {
         label: '---- PAST MEMBERS----',
         options: getInactiveOptions(),
      },
   ]
   return (
      <>
         <Select
            className="userfield"
            isSearchable
            isClearable
            options={props.inactiveUsers != null ? options : props.users ? getOptions() : []}
            value={props.value}
            placeholder={props.t('placeholderUserDropDown')}
            onChange={wrappedUser => props.handleChangeEmployee(wrappedUser)}
         />
      </>
   )
}

export default withTranslation()(UserDropdown)
