import { Box, Skeleton, Typography } from '@mui/material'
import { withTranslation } from 'react-i18next'
import 'react-datepicker/dist/react-datepicker.css'
import ColorSquare from './UI/ColorSquare'
import DateManipulator from '../components/DateManipulator'
import { formatNumber } from '../../../../../util/helpers'

const ProjectOverviewDetails = ({ project, selectedPeriod, setSelectedPeriod, isFetching, t }) => {
   const billableNonBillable = type => {
      let billable = 0
      let nonBillable = 0
      project.MemberDetails.forEach(member => {
         billable += member.billable
         nonBillable += member.nonBillable
      })
      return type === 'billable' ? formatNumber(billable) : formatNumber(nonBillable)
   }
   return (
      <Box sx={{ p: '25px 20px', display: 'flex', justifyContent: 'space-between' }}>
         <Box>
            <Typography sx={{ color: '#454545', m: '5px' }}>
               {t('teamDetailedInvolvment')}
            </Typography>
            {project.Type === 'External' && (
               <Box>
                  {isFetching ? (
                     <Skeleton sx={{ width: 125 }} />
                  ) : (
                     <Box
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           color: '#454545',
                           m: '5px',
                           width: 150,
                           justifyContent: 'space-between',
                        }}
                     >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                           <ColorSquare color={'#00B8B8'} />
                           <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                              {t('billable')}
                           </Typography>
                        </Box>
                        <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                           {billableNonBillable('billable')}
                        </Typography>
                     </Box>
                  )}
                  {isFetching ? (
                     <Skeleton sx={{ width: 150 }} />
                  ) : (
                     <Box
                        sx={{
                           display: 'flex',
                           alignItems: 'center',
                           color: '#454545',
                           m: '5px',
                           width: 150,
                           justifyContent: 'space-between',
                        }}
                     >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                           <ColorSquare color={'#2D3A73'} />

                           <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                              {t('nonBillable')}
                           </Typography>
                        </Box>
                        <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                           {billableNonBillable('nonBillable')}
                        </Typography>
                     </Box>
                  )}
               </Box>
            )}
         </Box>
         <DateManipulator selectedPeriod={selectedPeriod} setSelectedPeriod={setSelectedPeriod} />
      </Box>
   )
}

export default withTranslation()(ProjectOverviewDetails)
