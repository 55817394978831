import { Checkbox, Autocomplete, TextField } from '@mui/material'
import { useState } from 'react'
import {
   CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
   CheckBox as CheckBoxIcon,
   ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { withTranslation } from 'react-i18next'

const useStyles = makeStyles({
   paper: {
      border: '1.6px solid #8F8F8F',
      borderTop: 'none',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
   },

   option: {
      '&:hover': {
         backgroundColor: '#AADDEE33 !important',
      },
   },
})
const autocompleteOnOpenSx = {
   '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
         borderColor: '#8F8F8F',
         borderBottomLeftRadius: 0,
         borderBottomRightRadius: 0,
         borderBottom: 0,
      },
   },
}

const autocompleteOnOpenHover = {
   '& .MuiOutlinedInput-root ': {
      p: 0,
      '&:hover .MuiOutlinedInput-notchedOutline': {
         border: '1.6px solid #8ACCE6',
      },
   },
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const TimesheetAutocompleteFilter = ({
   options,
   teamProjectFilters,
   setTeamProjectFilters,
   optionType,
   setUserFilters,
   userFilters,
   setTabIndex,
}) => {
   const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false)
   const classes = useStyles()

   const toggleAutocompleteOpen = () => {
      setIsAutocompleteOpen(prevState => !prevState)
   }

   const handleCheckbox = optionArray => {
      if (optionType === 'skills') {
         setUserFilters(prev => {
            return { ...prev, skills: optionArray }
         })
      } else {
         const optionTypeMapper = { team: 'TeamLead', project: 'ProjectManager' }
         const nonOptionTypeOptions = teamProjectFilters.filter(
            filterOption => !filterOption.hasOwnProperty(optionTypeMapper[optionType])
         )
         setTabIndex(0)
         setTeamProjectFilters([...nonOptionTypeOptions, ...optionArray])
      }
   }

   const getAutocompleteValue = property => {
      if (property === 'team') {
         return teamProjectFilters.filter(filterOption => filterOption.hasOwnProperty('TeamLead'))
      }
      if (property === 'project') {
         return teamProjectFilters.filter(filterOption =>
            filterOption.hasOwnProperty('ProjectManager')
         )
      }
      if (property === 'skills') {
         return userFilters.skills
      }
   }

   const isFilterCheckboxChecked = option => {
      if (optionType === 'skills') {
         return !!userFilters.skills.find(skill => skill._id === option._id)
      } else {
         return !!teamProjectFilters.find(filterOption => filterOption.id === option.id)
      }
   }

   const placeHolder = option => {
      if (option === 'team') {
         const count = teamProjectFilters.filter(filterOption =>
            filterOption.hasOwnProperty('TeamLead')
         )
         return `By team ${count.length > 0 ? `(${count.length})` : ''}`
      }
      if (option === 'project') {
         const count = teamProjectFilters.filter(filterOption =>
            filterOption.hasOwnProperty('ProjectManager')
         )
         return `By project ${count.length > 0 ? `(${count.length})` : ''}`
      }
      if (option === 'skills') {
         return `By skills ${userFilters.skills.length > 0 ? `(${userFilters.skills.length})` : ''}`
      }
   }
   return (
      <Autocomplete
         multiple
         popupIcon={<ExpandMoreIcon />}
         onOpen={toggleAutocompleteOpen}
         onClose={toggleAutocompleteOpen}
         classes={{ paper: classes.paper, option: classes.option }}
         className={classes.autocomplete}
         clearIcon={null}
         renderTags={() => null}
         options={options}
         disableCloseOnSelect
         value={getAutocompleteValue(optionType)}
         isOptionEqualToValue={(option, value) =>
            optionType === 'skills' ? option._id === value._id : option.id === value.id
         }
         getOptionLabel={option => (optionType === 'skills' ? option.Name : option.label)}
         style={{ width: 200 }}
         slotProps={{
            popper: {
               sx: {
                  zIndex: 1000,
               },
            },
         }}
         onChange={(_, value) => handleCheckbox(value)}
         sx={[
            isAutocompleteOpen
               ? {
                    '& .MuiOutlinedInput-root ': {
                       p: 0,
                    },
                 }
               : autocompleteOnOpenHover,

            {
               mr: 1.5,
               '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                  p: '0 10px',
               },
               '& .MuiOutlinedInput-notchedOutline': {
                  border: '1.6px solid #B8B8B8',
                  borderRadius: '4px',
               },
            },
         ]}
         renderOption={(props, option) => (
            <li
               key={optionType === 'skills' ? option._id : option.id}
               style={{ padding: 0 }}
               {...props}
            >
               <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={isFilterCheckboxChecked(option)}
               />

               {optionType === 'skills' ? option.Name : option.label}
            </li>
         )}
         renderInput={params => (
            <TextField
               {...params}
               placeholder={placeHolder(optionType)}
               sx={[
                  {
                     '& .MuiInputBase-root': {
                        height: '32px',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 500,
                     },
                  },
                  isAutocompleteOpen && autocompleteOnOpenSx,
               ]}
            />
         )}
      />
   )
}
export default withTranslation()(TimesheetAutocompleteFilter)
