import {
   Box,
   Divider,
   MenuItem,
   Badge,
   Typography,
   Checkbox,
   ListItemText,
   Button,
} from '@mui/material'
import { FilterList, Close, FilterAlt } from '@mui/icons-material'
import { withTranslation } from 'react-i18next'
import DatePicker from '../../../../../../components/DatePicker'
import Select from '../../../../../../components/Select'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
   filtersIcon: {
      cursor: 'pointer',
      backgroundColor: '#009be5',
      color: '#fff',
      borderRadius: '25px',
      padding: '5px',
      '&:hover': {
         color: '#fff',
         backgroundColor: '#006db3',
      },
   },
   filtersIconExpanded: {
      cursor: 'pointer',
      borderRadius: '25px',
      padding: '5px',
      color: '#fff',
      backgroundColor: '#006db3',
   },
}))

const AvailableEmployeesHeader = ({
   selectedPeriod,
   filterBySkillGroup,
   filterBySkill,
   setSelectedPeriod,
   setFilterBySkillGroup,
   setFilterBySkill,
   setFilterByProject,
   skillGroups,
   skills,
   projects,
   employees,
}) => {
   const classes = useStyles()

   const [filtersExpanded, setFiltersExpanded] = useState(false)
   const [filteredSkills, setFilteredSkills] = useState([])

   const [filterBySkillGroupLocal, setFilterBySkillGroupLocal] = useState(filterBySkillGroup ?? [])
   const [filterBySkillLocal, setFilterBySkillLocal] = useState(filterBySkill ?? [])
   const [filterByProjectLocal, setFilterByProjectLocal] = useState(null)

   useEffect(() => {
      const filtered = skills.filter(skill => filterBySkillGroupLocal.includes(skill.GroupID))
      setFilteredSkills(filtered)
   }, [skills, filterBySkillGroupLocal])

   const handleStartDate = newDate => {
      setSelectedPeriod(prev => ({
         ...prev,
         startDate: new Date(newDate),
      }))
   }

   const handleEndDate = newDate => {
      setSelectedPeriod(prev => ({
         ...prev,
         endDate: new Date(newDate),
      }))
   }

   const toggleFilters = () => {
      setFiltersExpanded(prevState => !prevState)
   }

   const filterBySkillGroupChange = e => {
      const value = e.target.value
      setFilterBySkillGroupLocal(typeof value === 'string' ? value.split(',') : value)
      // remove the skills from the filter that does not belong to the selected groups
      setFilterBySkillLocal(prevState => {
         const skillsForSelectedGroups = []
         value.forEach(skillGroupId => {
            prevState.forEach(skillId => {
               const skill = skills.find(skill => skill._id == skillId)
               if (skill.GroupID == skillGroupId) {
                  skillsForSelectedGroups.push(skillId)
               }
            })
         })
         return skillsForSelectedGroups
      })
   }

   const filterBySkillChange = e => {
      const value = e.target.value
      setFilterBySkillLocal(typeof value === 'string' ? value.split(',') : value)
   }

   const filterByProjectChange = e => {
      const value = e.target.value
      setFilterByProjectLocal(value)
   }

   const applyFilters = () => {
      setFilterBySkillGroup(filterBySkillGroupLocal)
      setFilterBySkill(filterBySkillLocal)
      setFilterByProject(filterByProjectLocal)
   }

   const clearFilters = () => {
      setFilterBySkillGroupLocal([])
      setFilterBySkillLocal([])
      setFilterByProjectLocal(null)
   }

   return (
      <>
         <Box sx={{ padding: '0 30px 15px 20px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
               <Box sx={{ display: 'flex' }}>
                  <Typography sx={{ fontSize: 30, fontWeight: 600 }}>
                     Available Employees
                  </Typography>
                  <Typography
                     sx={{ fontSize: 30, fontWeight: 600, color: '#52B3DD', marginLeft: '10px' }}
                  >
                     ({employees.length})
                  </Typography>
               </Box>
               <Box sx={{ display: 'flex', width: '470px', justifyContent: 'space-between' }}>
                  <Box
                     sx={{
                        display: 'flex',
                        height: '40px',
                        width: '420px',
                        justifyContent: 'space-between',
                        position: 'relative',
                     }}
                  >
                     <DatePicker
                        selectedPeriod={selectedPeriod.startDate}
                        hover={1}
                        handleDate={handleStartDate}
                        max={selectedPeriod.endDate}
                        showYearDropdown={true}
                     />
                     <DatePicker
                        selectedPeriod={selectedPeriod.endDate}
                        hover={2}
                        handleDate={handleEndDate}
                        min={selectedPeriod.startDate}
                        showYearDropdown={true}
                     />
                  </Box>
                  <Box>
                     <Badge
                        // badgeContent={projectFilters.length}
                        overlap="rectangular"
                        color="secondary"
                     >
                        <FilterList
                           fontSize={'large'}
                           className={`${
                              filtersExpanded ? classes.filtersIconExpanded : classes.filtersIcon
                           } ml-2`}
                           onClick={toggleFilters}
                        />
                     </Badge>
                  </Box>
               </Box>
            </Box>
            <Box
               sx={{
                  border: '1px solid #c7c7c7',
                  borderRadius: '8px',
                  padding: '20px 18px',
                  display: filtersExpanded ? 'block' : 'none',
               }}
            >
               <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Select
                     title="Skill group"
                     placeholder="Select skill group"
                     width="200px"
                     margin="0 10px"
                     multiple={true}
                     value={filterBySkillGroupLocal}
                     handleChange={filterBySkillGroupChange}
                     renderValue={selectedIds => {
                        const selectedSkillGroupNames = []
                        selectedIds.forEach(selectedId => {
                           const skillGroup = skillGroups.find(
                              skillGroup => skillGroup._id == selectedId
                           )
                           if (skillGroup) {
                              selectedSkillGroupNames.push(skillGroup.Name)
                           }
                        })
                        return selectedSkillGroupNames.join(', ')
                     }}
                  >
                     {skillGroups.map(skillGroup => (
                        <MenuItem key={skillGroup._id} value={skillGroup._id}>
                           <Checkbox
                              checked={filterBySkillGroupLocal.indexOf(skillGroup._id) > -1}
                           />
                           <ListItemText primary={skillGroup.Name} />
                        </MenuItem>
                     ))}
                  </Select>
                  <Select
                     title="Skill set"
                     placeholder="Select skill"
                     width="200px"
                     margin="0 10px"
                     multiple={true}
                     value={filterBySkillLocal}
                     handleChange={filterBySkillChange}
                     renderValue={selectedIds => {
                        const selectedSkillNames = []
                        selectedIds.forEach(selectedId => {
                           const skill = skills.find(skill => skill._id == selectedId)
                           if (skill) {
                              selectedSkillNames.push(skill.Name)
                           }
                        })
                        return selectedSkillNames.join(', ')
                     }}
                  >
                     {filteredSkills.map(skill => (
                        <MenuItem key={skill._id} value={skill._id}>
                           <Checkbox checked={filterBySkillLocal.indexOf(skill._id) > -1} />
                           <ListItemText primary={skill.Name} />
                        </MenuItem>
                     ))}
                  </Select>
                  <Select
                     title="Project"
                     placeholder="Select project"
                     width="200px"
                     margin="0 10px"
                     value={filterByProjectLocal}
                     handleChange={filterByProjectChange}
                  >
                     {projects.map(project => (
                        <MenuItem key={project._id} value={project._id}>
                           <ListItemText primary={project.Name} />
                        </MenuItem>
                     ))}
                  </Select>
               </Box>
               <Divider sx={{ my: '10px' }} />
               <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                     sx={{ marginRight: '20px' }}
                     variant="outlined"
                     color="error"
                     startIcon={<Close />}
                     onClick={clearFilters}
                  >
                     Clear all filters
                  </Button>
                  <Button variant="contained" startIcon={<FilterAlt />} onClick={applyFilters}>
                     Apply filters
                  </Button>
               </Box>
            </Box>
            <Divider sx={{ my: '10px' }} />
         </Box>
      </>
   )
}
export default withTranslation()(AvailableEmployeesHeader)
