import React, { useState, useMemo, useEffect, Fragment } from 'react'
import { Collapse, Grid, IconButton } from '@mui/material'
import moment from 'moment/min/moment-with-locales'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import AddBoxIcon from '@mui/icons-material/AddBox'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import EditIcon from '@mui/icons-material/Edit'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import MoneyOffIcon from '@mui/icons-material/MoneyOff'
import DeleteIcon from '@mui/icons-material/Delete'
import {
   checkForNonWorkingOrSubtaskHours,
   convertTimeOnBlur,
   convertTimesheetHoursAndMinutes,
   isTimeMoreThan24h,
} from '../../util/helpers'
import TimesheetTimeInput from './TimesheetTimeInput'
import { withTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
const additionalWorkTypes = [
   { name: 'timesheetProjectWorkTypeIdleTime', id: 'A2' },
   { name: 'timesheetProjectWorkTypeTraining', id: 'A3' },
   { name: 'timesheetProjectWorkTypeTravelTime', id: 'A6' },
]

const centerAlign = {
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
}

const TimesheetRow = ({
   disabledDatesByAssignments,
   selectedView,
   userProjects,
   userId,
   expandedRows,
   setExpandedRows,
   hideCreateSubtaskStyles,
   setProjectSubtaskPermission,
   chosenDate,
   project,
   chosenDateWeek,
   timesheetData,
   updateTimesheetData,
   lockedDates,
   t,
   showErrorToast,
   getReportedHoursForDay,
}) => {
   const [temporaryInputValue, setTemporaryInputValue] = useState({})
   const [subtaskData, setSubtaskData] = useState(null)
   const [deleteSubtaskId, setDeleteSubtaskId] = useState(null)
   const [deleteSubtaskName, setDeleteSubtaskName] = useState('')
   const [createdSubtaskIdEffect, setCreatedSubtaskIdEffect] = useState(null)
   const [mainGridAnimationPlayed, setMainGridAnimationPlayed] = useState(false)
   const [collapseGridAnimationPlayed, setCollapseGridAnimationPlayed] = useState(true)

   const PM = useMemo(() => {
      const proj = (userProjects || []).find(p => p._id === project.ProjectID)

      if (proj.PMname) {
         return proj.PMname
      }

      const pmId = proj?.ProjectManager
      const pmInfo = (proj?.MemberDetails || []).find(md => md._id === pmId)
      return pmInfo?.Name || ''
   }, [project, userProjects])

   const [canCreateSubtask, canCreateBillableSubtask] = useMemo(() => {
      let [subtask, billable] = [false, false]
      if (selectedView !== 0) {
         return [subtask, billable]
      }

      const proj = (userProjects || []).find(p => p._id === project.ProjectID)
      let [userDetails] = [null]
      if (proj) {
         userDetails = (proj.MemberDetails || []).find(md => md?._id === userId)
      }

      if (userDetails) {
         const assignments = userDetails.Assignments || []
         const subtaskAssignments = assignments.filter(
            a =>
               moment(new Date(chosenDate)).isBetween(
                  new Date(a.StartDate),
                  new Date(a.EndDate || new Date(Date.now()).getTime() + 86400000),
                  'days',
                  '[]'
               ) && a.SubTasksPermission
         )

         if (subtaskAssignments.length) {
            subtask = true
         }

         const billableAssignments = subtaskAssignments.filter(a => a.TasksBillability)

         if (billableAssignments.length) {
            billable = true
         }
      }

      return [subtask, billable]
   }, [selectedView, userProjects, project, chosenDate, userId])

   const disableSubtaskSaveBtn = useMemo(() => {
      if (selectedView !== 0) {
         return true
      }

      if (
         !subtaskData?.Date ||
         !subtaskData?.WorkType ||
         !subtaskData?.Description ||
         !subtaskData?.ReportedHours
      ) {
         return true
      }

      if (subtaskData?.Billable === null) {
         return true
      }

      return false
   }, [selectedView, subtaskData])

   const disableSubtaskSaveEditBtn = useMemo(() => {
      if (!subtaskData?.Description || !subtaskData?._id || !project || !timesheetData?.Projects) {
         return true
      }

      const proj = timesheetData.Projects.find(p => p.ProjectID === project.ProjectID)
      const subtask = proj?.ReportedDates?.find?.(rd => rd._id === subtaskData._id)
      if (
         subtask.Description === subtaskData.Description &&
         subtask.Billable === subtaskData.Billable
      ) {
         return true
      }
   }, [subtaskData, timesheetData.Projects, project])

   useEffect(() => {
      if (createdSubtaskIdEffect) {
         setTimeout(() => {
            setCreatedSubtaskIdEffect(null)
         }, 1500)
      }
   }, [createdSubtaskIdEffect])

   useEffect(() => {
      setMainGridAnimationPlayed(false)
      setTimeout(() => {
         setMainGridAnimationPlayed(true)
      }, 200)
   }, [project.ProjectID, chosenDate])

   useEffect(() => {
      if (expandedRows[project.ProjectID]) {
         setCollapseGridAnimationPlayed(false)
         setTimeout(() => {
            setCollapseGridAnimationPlayed(true)
         }, 200)
      }
   }, [project.ProjectID, chosenDate, expandedRows[project.ProjectID]])

   useEffect(() => {
      setProjectSubtaskPermission(project.ProjectID, canCreateSubtask)
   }, [project.ProjectID, canCreateSubtask, setProjectSubtaskPermission])

   const deleteSubtask = (id, name = '') => {
      if (!deleteSubtaskId) {
         setDeleteSubtaskId(id)
         setDeleteSubtaskName(name)
      } else {
         if (id) {
            const copyTimesheetData = JSON.parse(JSON.stringify(timesheetData))
            const proj = copyTimesheetData.Projects.find(p => p.ProjectID === project.ProjectID)
            const index = proj.ReportedDates.findIndex(rd => rd._id === id)
            if (id.includes('-')) {
               proj.ReportedDates.splice(index, 1)
            } else {
               proj.ReportedDates[index].ReportedHours = '00:00'
               proj.ReportedDates[index].Billable = false
               proj.ReportedDates[index].Hidden = true
            }
            updateTimesheetData(copyTimesheetData)
         }
         setDeleteSubtaskId(null)
         setDeleteSubtaskName('')
      }
   }

   const isToday = date => moment(date).isSame(new Date(Date.now()), 'day')
   const isSameDay = (date1, date2) => moment(new Date(date1)).isSame(new Date(date2), 'day')

   const isDayAnAbsence = date =>
      lockedDates.find(
         lockedDate =>
            moment(date).isSameOrAfter(lockedDate.StartDate, 'day') &&
            moment(date).isSameOrBefore(lockedDate.EndDate, 'day')
      )

   const isInputDisabled = date => {
      return moment(date).isAfter(new Date(Date.now()), 'day') || isDayAnAbsence(date)
   }

   const calculateProjectActualHours = reportedDates => {
      let projectHours = 0
      let projectMinutes = 0
      reportedDates.forEach(reportedDate => {
         projectHours += Number(reportedDate.ReportedHours.split(':')[0]) || 0
         projectMinutes += Number(reportedDate.ReportedHours.split(':')[1]) || 0
      })

      return convertTimesheetHoursAndMinutes(projectHours, projectMinutes)
   }

   const getReportedInputHours = (projectName, date, workType, id) => {
      const foundReportedDate = timesheetData.Projects.find(
         project => project.Name === projectName
      ).ReportedDates.find(
         reportedDate =>
            moment(reportedDate.Date).isSame(date, 'day') &&
            reportedDate.WorkType === workType &&
            (id ? reportedDate._id === id : true)
      )
      return !!foundReportedDate ? foundReportedDate.ReportedHours : ''
   }

   const onInputChange = (input, projectName, date, workType, id) => {
      const re = /^$|^([01]?\d|2[0-3])(:[0-5]?\d?)?$/
      if (re.test(input.target.value)) {
         setTemporaryInputValue({
            value: input.target.value,
            projectName,
            date,
            workType,
            ...(id ? { _id: id } : {}),
         })
      } else {
         showErrorToast(t('timesheetInputErrorMessage'), 5000)
      }
   }

   const onSubtaskInputChange = input => {
      const re = /^$|^([01]?\d|2[0-3])(:[0-5]?\d?)?$/
      if (re.test(input.target.value)) {
         setSubtaskData(prev => ({ ...prev, ReportedHours: input.target.value }))
      } else {
         showErrorToast(t('timesheetInputErrorMessage'), 5000)
      }
   }

   const returnInputValue = (projectName, date, workType, id) => {
      const foundTemporaryInputValue = isTemporaryInputValue(projectName, date, workType, id)
         ? temporaryInputValue
         : null

      if (foundTemporaryInputValue) {
         return temporaryInputValue.value
      } else {
         return getReportedInputHours(projectName, date, workType, id)
      }
   }

   const isTemporaryInputValue = (projectName, date, workType, id) => {
      return (
         !!temporaryInputValue &&
         temporaryInputValue.projectName === projectName &&
         temporaryInputValue.date === date &&
         temporaryInputValue.workType === workType &&
         (id ? temporaryInputValue._id === id : true)
      )
   }

   const onInputOnBlur = (projectName, date, workType, id) => {
      if (temporaryInputValue.value) {
         const convertedTime = convertTimeOnBlur(temporaryInputValue.value)
         reportTime(projectName, date, convertedTime, workType, id)
         setTemporaryInputValue({})
      } else {
         setTemporaryInputValue({})
      }
   }

   const onSubtaskInputOnBlur = () => {
      if (subtaskData.ReportedHours) {
         setSubtaskData(prev => ({ ...prev, ReportedHours: convertTimeOnBlur(prev.ReportedHours) }))
      }
   }

   const calculatePreviousHours = (foundReportedDate, prevReportedTime) => {
      const foundHours = Number(foundReportedDate.ReportedHours.split(':')[0]) || 0
      const foundMinutes = Number(foundReportedDate.ReportedHours.split(':')[1]) || 0
      const prevHours = Number(prevReportedTime.split(':')[0])
      const prevMinutes = Number(prevReportedTime.split(':')[1])

      const dayHours = foundHours + prevHours
      const dayMinutes = foundMinutes + prevMinutes
      return convertTimesheetHoursAndMinutes(dayHours, dayMinutes)
   }

   const reportTime = (projectName, date, timeReported, workType, id) => {
      const copyTimesheetData = JSON.parse(JSON.stringify(timesheetData))

      copyTimesheetData.Projects.forEach(project => {
         if (project.Name === projectName) {
            if (project.ReportedDates.length) {
               const foundReportedDate = project.ReportedDates.find(
                  reportedDate =>
                     moment(reportedDate.Date).isSame(date, 'day') &&
                     reportedDate.WorkType === workType &&
                     (id ? reportedDate._id === id : true)
               )
               const reportedTimeForThisDay = getReportedHoursForDay(date)
               if (foundReportedDate) {
                  if (
                     isTimeMoreThan24h(
                        timeReported,
                        reportedTimeForThisDay,
                        foundReportedDate.ReportedHours
                     )
                  ) {
                     showErrorToast(
                        `${t('timesheet24hErrorText')} ${moment(date).format('ddd, D')}. ${t(
                           'pleaseReviseInput'
                        )}`
                     )
                  } else {
                     foundReportedDate.ReportedHours = timeReported
                     return true
                  }
               } else {
                  if (isTimeMoreThan24h(timeReported, reportedTimeForThisDay)) {
                     setTemporaryInputValue({})
                     showErrorToast(
                        `${t('timesheet24hErrorText')} ${moment(date).format('ddd, D')}. ${t(
                           'pleaseReviseInput'
                        )}`
                     )
                  } else {
                     project.ReportedDates.push({
                        Date: date,
                        ReportedHours: timeReported,
                        WorkType: workType,
                     })
                     return true
                  }
               }
            } else {
               const allReportedHoursForDay = copyTimesheetData.Projects.reduce(
                  (prevReportedTime, currValue) => {
                     const foundReportedDate = currValue.ReportedDates.find(reportedDate => {
                        return moment(reportedDate.Date).isSame(date, 'day')
                     })

                     if (foundReportedDate) {
                        return calculatePreviousHours(foundReportedDate, prevReportedTime)
                     } else {
                        return prevReportedTime
                     }
                  },
                  '00:00'
               )
               if (isTimeMoreThan24h(timeReported, allReportedHoursForDay)) {
                  showErrorToast(
                     `${t('timesheet24hErrorText')} ${moment(date).format('ddd, D')}. ${t(
                        'pleaseReviseInput'
                     )}`
                  )
               } else {
                  project.ReportedDates.push({
                     Date: date,
                     ReportedHours: timeReported,
                     WorkType: workType,
                  })
               }
            }
         }
      })

      updateTimesheetData(copyTimesheetData)
   }

   const originalWeekDates = chosenDateWeek.weekDates || []
   let weekDates = []
   if (selectedView === 0) {
      // day
      if (originalWeekDates?.length) {
         const selectedDay = originalWeekDates.find(d => isSameDay(chosenDate, d))
         weekDates = originalWeekDates.filter(d => !isSameDay(chosenDate, d))
         weekDates.push(selectedDay)
         weekDates.unshift(new Date(0))
      }
   } else if (selectedView === 1) {
      // week
      weekDates = originalWeekDates
   } else {
      // defaults to week
      weekDates = originalWeekDates
   }

   const dailyViewGridProps = selectedView === 0 ? { xs: 10.5 } : {}

   return (
      <Fragment key={`tsheet-fmnt-${project.ProjectID}-${chosenDate.toString()}`}>
         {/* Subtask Delete Dialog Start */}
         {!!deleteSubtaskId && (
            <Dialog
               onClose={() => deleteSubtask(null)}
               aria-labelledby="subtask-dialog-delete"
               open={true}
            >
               <div className="tsheet-subtask-delete-dialog-container">
                  <div className="tsheet-subtask-delete-dialog-header">
                     <div className="tsheet-subtask-delete-dialog-title">
                        {t('tsheet-subtask-delete-dialog-title', { projectName: project.Name })}
                     </div>
                     <div className="tsheet-subtask-delete-dialog-subtitle">
                        {deleteSubtaskName}
                     </div>
                  </div>
                  <div className="tsheet-subtask-dialog-container-inner-separator" />
                  <div className="tsheet-subtask-dialog-action-container">
                     <Stack direction="row" spacing={2}>
                        <Button
                           variant="outlined"
                           disableElevation
                           onClick={() => deleteSubtask(null)}
                        >
                           {t('tsheet-subtask-delete-dialog-action-cancel')}
                        </Button>
                        <Button
                           variant="contained"
                           color="error"
                           disableElevation
                           onClick={() => deleteSubtask(deleteSubtaskId)}
                        >
                           {t('tsheet-subtask-delete-dialog-action-delete')}
                        </Button>
                     </Stack>
                  </div>
               </div>
            </Dialog>
         )}
         {/* Subtask Delete Dialog End */}

         {/* Subtask Create Edit Dialog Start */}
         {!!subtaskData && (
            <Dialog
               onClose={() => setSubtaskData(null)}
               aria-labelledby="subtask-dialog"
               open={true}
            >
               <div className="tsheet-subtask-dialog-container">
                  <div className="tsheet-subtask-dialog-container-header">
                     {t('tsheet-subtask-dialog-header')}
                  </div>
                  <div className="tsheet-subtask-dialog-container-inner">
                     <div className="tsheet-subtask-dialog-title-container">
                        <div className="tsheet-subtask-dialog-title">
                           {t(
                              subtaskData._id
                                 ? 'tsheet-subtask-dialog-title-edit'
                                 : 'tsheet-subtask-dialog-title-create'
                           )}{' '}
                           - {project.Name}
                        </div>
                        <div className="tsheet-subtask-dialog-subtitle">
                           {moment(new Date(chosenDate)).format('Do MMM / YYYY')}
                        </div>
                     </div>
                     <div className="tsheet-subtask-dialog-row">
                        <div>
                           {t(
                              subtaskData._id
                                 ? 'tsheet-subtask-dialog-description-edit-label'
                                 : 'tsheet-subtask-dialog-description-label'
                           )}
                        </div>
                        <TextField
                           variant="outlined"
                           value={subtaskData.Description}
                           onChange={e =>
                              setSubtaskData(prev => ({ ...prev, Description: e.target.value }))
                           }
                           size="small"
                           inputProps={{
                              style: {
                                 fontSize: '12px',
                                 lineHeight: '14px',
                                 fontWeight: 500,
                              },
                           }}
                           style={{ width: '100%' }}
                           multiline={true}
                           minRows={3}
                           maxRows={5}
                           placeholder={t('tsheet-subtask-dialog-description-placeholder')}
                        />
                     </div>
                     {!subtaskData._id && (
                        <div className="tsheet-subtask-dialog-row">
                           <div>{t('tsheet-subtask-dialog-log-hours-label')}</div>
                           <TimesheetTimeInput
                              onChange={input => onSubtaskInputChange(input)}
                              value={subtaskData.ReportedHours}
                              // disabled={isInputDisabled(weekDate)}
                              onBlur={onSubtaskInputOnBlur}
                           />
                        </div>
                     )}
                     {canCreateBillableSubtask && (
                        <div className="tsheet-subtask-dialog-row">
                           <div>
                              {t(
                                 subtaskData._id
                                    ? 'tsheet-subtask-dialog-task-type-edit-label'
                                    : 'tsheet-subtask-dialog-task-type-label'
                              )}
                           </div>
                           <FormControlLabel
                              control={<Radio checked={subtaskData.Billable === true} />}
                              label={t('tsheet-task-Billable')}
                              onClick={e => {
                                 e.preventDefault()
                                 e.stopPropagation()
                                 e?.nativeEvent?.preventDefault?.()
                                 e?.nativeEvent?.stopPropagation?.()
                                 setSubtaskData(prev => ({
                                    ...prev,
                                    Billable: prev.Billable === true ? null : true,
                                 }))
                              }}
                              style={{ color: '#454545' }}
                           />
                           <FormControlLabel
                              control={<Radio checked={subtaskData.Billable === false} />}
                              label={t('tsheet-task-Unbilable')}
                              onClick={e => {
                                 e.preventDefault()
                                 e.stopPropagation()
                                 e?.nativeEvent?.preventDefault?.()
                                 e?.nativeEvent?.stopPropagation?.()
                                 setSubtaskData(prev => ({
                                    ...prev,
                                    Billable: prev.Billable === false ? null : false,
                                 }))
                              }}
                              style={{ color: '#454545' }}
                           />
                        </div>
                     )}

                     <div className="tsheet-subtask-dialog-container-inner-separator" />
                     <div className="tsheet-subtask-dialog-action-container">
                        <Stack direction="row" spacing={2}>
                           <Button
                              variant="outlined"
                              color="error"
                              onClick={() => setSubtaskData(null)}
                           >
                              {t('tsheet-subtask-dialog-action-cancel')}
                           </Button>
                           <Button
                              variant="contained"
                              disableElevation
                              disabled={
                                 subtaskData._id ? disableSubtaskSaveEditBtn : disableSubtaskSaveBtn
                              }
                              onClick={() => {
                                 if (!subtaskData._id) {
                                    const reportedTimeForThisDay = getReportedHoursForDay(
                                       subtaskData.Date
                                    )

                                    if (
                                       isTimeMoreThan24h(
                                          subtaskData.ReportedHours,
                                          reportedTimeForThisDay
                                       )
                                    ) {
                                       showErrorToast(
                                          `${t('timesheet24hErrorText')} ${moment(
                                             subtaskData.Date
                                          ).format('ddd, D')}. ${t('pleaseReviseInput')}`
                                       )
                                       return
                                    }
                                 }

                                 const copyTimesheetData = JSON.parse(JSON.stringify(timesheetData))
                                 const proj = copyTimesheetData.Projects.find(
                                    p => p.ProjectID === project.ProjectID
                                 )
                                 const copySubtaskData = JSON.parse(JSON.stringify(subtaskData))

                                 if (!subtaskData._id) {
                                    copySubtaskData._id = uuidv4()
                                    proj.ReportedDates.unshift(copySubtaskData)
                                    if (!expandedRows[project.ProjectID]) {
                                       setExpandedRows(prev => ({
                                          ...prev,
                                          [project.ProjectID]: !prev[project.ProjectID],
                                       }))
                                    }
                                    setCreatedSubtaskIdEffect(copySubtaskData._id)
                                 } else {
                                    const foundSubtask = proj.ReportedDates.find(
                                       rd => rd._id === subtaskData._id
                                    )
                                    foundSubtask.Description = copySubtaskData.Description
                                    foundSubtask.Billable = copySubtaskData.Billable || false
                                 }

                                 updateTimesheetData(copyTimesheetData)
                                 setSubtaskData(null)
                              }}
                           >
                              {t(
                                 subtaskData._id
                                    ? 'tsheet-subtask-dialog-action-save'
                                    : 'tsheet-subtask-dialog-action-create'
                              )}
                           </Button>
                        </Stack>
                     </div>
                  </div>
               </div>
            </Dialog>
         )}
         {/* Subtask Create Edit Dialog End */}

         <Grid
            container
            style={{
               borderBottom: '1px solid rgba(224, 224, 224, 1)',
               minHeight: 60,
               ...(!mainGridAnimationPlayed
                  ? { animation: 'tsheetFadeInFromNone 0.2s ease-out' }
                  : {}),
            }}
            key={`tsheet-row-g1-${project.ProjectID}-${chosenDate.toString()}`}
         >
            <Grid
               item
               xs={4}
               {...dailyViewGridProps}
               style={{ display: 'flex', alignItems: 'center', paddingLeft: 13 }}
            >
               {selectedView === 0 && canCreateSubtask && (
                  <Tooltip
                     title={t('tsheet-subtask-create-button-tooltip')}
                     placement="bottom-start"
                     arrow
                  >
                     <IconButton
                        onClick={() =>
                           setSubtaskData({
                              Date: new Date(chosenDate),
                              ReportedHours: '00:00',
                              WorkType: 'A10',
                              Description: '',
                              Billable: canCreateBillableSubtask ? null : false,
                           })
                        }
                        size="large"
                     >
                        <AddBoxIcon style={{ fill: '#52B3DD' }} />
                     </IconButton>
                  </Tooltip>
               )}
               {selectedView === 0 && !hideCreateSubtaskStyles && !canCreateSubtask && (
                  <div style={{ display: 'inline-block', width: '48px' }} />
               )}
               <IconButton
                  onClick={() =>
                     setExpandedRows(prev => ({
                        ...prev,
                        [project.ProjectID]: !prev[project.ProjectID],
                     }))
                  }
                  size="large"
               >
                  {expandedRows[project.ProjectID] ? (
                     <KeyboardArrowUpIcon />
                  ) : (
                     <KeyboardArrowDownIcon />
                  )}
               </IconButton>
               <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="timesheet-row-project-info">
                     <div className="timesheet-row-project-info-name">{project.Name}</div>
                     <div className="timesheet-row-project-info-pm">
                        {t('tsheet-PM-Abrev')}: {PM}
                     </div>
                  </div>

                  {checkForNonWorkingOrSubtaskHours(project.ReportedDates) && (
                     <Tooltip
                        componentsProps={{
                           tooltip: {
                              sx: {
                                 bgcolor: 'orange',
                                 '& .MuiTooltip-arrow': {
                                    color: 'orange',
                                 },
                              },
                           },
                        }}
                        arrow
                        title="There are reported hours in the drop down menu!"
                     >
                        <ErrorOutlineIcon sx={{ ml: 1, color: 'orange' }} />
                     </Tooltip>
                  )}
               </div>
            </Grid>
            {weekDates.map(weekDate => {
               const showInput =
                  (selectedView === 0 && isSameDay(weekDate, chosenDate)) || selectedView === 1
               const disableInputByAssignments = showInput
                  ? disabledDatesByAssignments?.[project.ProjectID]?.[new Date(weekDate).getDate()]
                  : false

               const todayStyle =
                  selectedView === 1 && isToday(weekDate)
                     ? {
                          backgroundColor: '#8ACCE6',
                          borderBottom: '1px solid #8ACCE6',
                          marginBottom: '-1px',
                       }
                     : {}

               const dailyGridProps =
                  selectedView === 0
                     ? {
                          xs: isSameDay(chosenDate, weekDate) ? 1.5 : 0,
                       }
                     : {}

               const isTodayInput =
                  (selectedView === 1 && isToday(weekDate)) ||
                  (selectedView === 0 && isSameDay(chosenDate, weekDate))

               return (
                  <Grid
                     key={new Date(weekDate).toDateString()}
                     item
                     xs={1}
                     {...dailyGridProps}
                     style={{
                        textAlign: 'center',
                        ...todayStyle,
                        ...centerAlign,
                     }}
                  >
                     {showInput ? (
                        <TimesheetTimeInput
                           onChange={input => onInputChange(input, project.Name, weekDate, 'A1')}
                           value={returnInputValue(project.Name, weekDate, 'A1')}
                           disabled={isInputDisabled(weekDate) || disableInputByAssignments}
                           onBlur={() => onInputOnBlur(project.Name, weekDate, 'A1')}
                           isPlaceholder={!isTemporaryInputValue(project.Name, weekDate, 'A1')}
                           {...(selectedView === 0 ? { style: { maxWidth: '70px' } } : {})}
                           isTodayInput={isTodayInput}
                        />
                     ) : (
                        <div />
                     )}
                  </Grid>
               )
            })}
            {selectedView === 1 && (
               <Grid
                  item
                  xs={1}
                  style={{
                     borderLeft: '1px solid rgba(224, 224, 224, 1)',
                     fontWeight: 700,
                     color: '#454545',
                     ...centerAlign,
                  }}
               >
                  {`${calculateProjectActualHours(project.ReportedDates)}${t('h')}`}
               </Grid>
            )}
         </Grid>
         <Collapse
            in={expandedRows[project.ProjectID]}
            timeout="auto"
            unmountOnExit
            style={{
               ...(!collapseGridAnimationPlayed
                  ? { animation: 'tsheetFadeInFromNone 0.2s ease-out' }
                  : {}),
            }}
            key={`tsheet-row-g2-${project.ProjectID}-${chosenDate.toString()}`}
         >
            {project.ReportedDates.filter(
               rd =>
                  rd.WorkType === 'A10' &&
                  !rd.Hidden &&
                  (selectedView === 0 ? isSameDay(rd.Date, chosenDate) : true)
            ).map(rd => (
               <Grid
                  key={rd._id}
                  container
                  style={{
                     borderBottom: '1px solid rgba(224, 224, 224, 1)',
                     minHeight: 60,
                  }}
               >
                  <Grid
                     item
                     xs={4}
                     {...dailyViewGridProps}
                     style={{
                        backgroundColor: '#def1fa',
                        ...centerAlign,
                        justifyContent: 'left',
                        paddingLeft: 52,
                        ...(createdSubtaskIdEffect === rd._id
                           ? { animation: 'tsheetCreateSubtaskBlink 1.25s ease-out' }
                           : {}),
                     }}
                  >
                     {selectedView === 0 && (
                        <IconButton
                           size="small"
                           style={{ marginLeft: '17px', marginRight: '7px' }}
                           onClick={() => deleteSubtask(rd._id, rd.Description)}
                        >
                           <DeleteIcon style={{ fill: '#E58C9C' }} />
                        </IconButton>
                     )}
                     {selectedView === 0 && (
                        <IconButton
                           size="small"
                           onClick={() =>
                              setSubtaskData({
                                 Date: new Date(rd.Date),
                                 ReportedHours: rd.ReportedHours,
                                 WorkType: rd.WorkType,
                                 Description: rd.Description,
                                 Billable: rd.Billable,
                                 _id: rd._id,
                              })
                           }
                        >
                           <EditIcon />
                        </IconButton>
                     )}
                     <div style={{ ...(selectedView === 0 ? { marginLeft: '4px' } : {}) }}>
                        {rd.Description}
                     </div>
                  </Grid>
                  {weekDates.map(weekDate => {
                     const showInput = isSameDay(weekDate, rd.Date)
                     const disableInputByAssignments = showInput
                        ? disabledDatesByAssignments?.[project.ProjectID]?.[
                             new Date(weekDate).getDate()
                          ]
                        : false
                     const todayStyles =
                        selectedView === 1 && isToday(weekDate)
                           ? {
                                backgroundColor: '#8ACCE6',
                                borderBottom: '1px solid #8ACCE6',
                                marginBottom: '-1px',
                             }
                           : {}

                     const dailyViewGridProps =
                        selectedView === 0
                           ? {
                                xs: isSameDay(chosenDate, weekDate) ? 1.5 : 0,
                             }
                           : {}

                     const isTodayInput =
                        (selectedView === 1 && isToday(weekDate)) ||
                        (selectedView === 0 && isSameDay(chosenDate, weekDate))

                     return (
                        <Grid
                           key={new Date(weekDate).toDateString()}
                           style={{
                              textAlign: 'center',
                              backgroundColor: '#def1fa',
                              ...(createdSubtaskIdEffect === rd._id
                                 ? { animation: 'tsheetCreateSubtaskBlink 1.25s ease-out' }
                                 : {}),
                              ...todayStyles,
                              ...centerAlign,
                           }}
                           item
                           xs={1}
                           {...dailyViewGridProps}
                        >
                           {showInput ? (
                              <div style={{ position: 'relative', width: '100%' }}>
                                 <TimesheetTimeInput
                                    onChange={input =>
                                       onInputChange(input, project.Name, weekDate, 'A10', rd._id)
                                    }
                                    value={returnInputValue(project.Name, weekDate, 'A10', rd._id)}
                                    disabled={
                                       isInputDisabled(weekDate) || disableInputByAssignments
                                    }
                                    onBlur={() =>
                                       onInputOnBlur(project.Name, weekDate, 'A10', rd._id)
                                    }
                                    isPlaceholder={
                                       !isTemporaryInputValue(project.Name, weekDate, 'A10', rd._id)
                                    }
                                    {...(selectedView === 0 ? { style: { maxWidth: '70px' } } : {})}
                                    isTodayInput={isTodayInput}
                                 />
                                 {selectedView === 0 &&
                                    (rd.Billable ? (
                                       <AttachMoneyIcon
                                          style={{
                                             position: 'absolute',
                                             top: '50%',
                                             transform: 'translateY(-50%)',
                                             fill: '#0083BB',
                                          }}
                                       />
                                    ) : (
                                       <MoneyOffIcon
                                          style={{
                                             position: 'absolute',
                                             top: '50%',
                                             transform: 'translateY(-50%)',
                                             fill: '#E58C9C',
                                          }}
                                       />
                                    ))}
                              </div>
                           ) : (
                              <div />
                           )}
                        </Grid>
                     )
                  })}
                  {selectedView === 1 && (
                     <Grid
                        item
                        xs={1}
                        style={{
                           borderLeft: '1px solid rgba(224, 224, 224, 1)',
                           fontWeight: 700,
                           color: '#454545',
                           ...centerAlign,
                        }}
                     />
                  )}
               </Grid>
            ))}
            {additionalWorkTypes.map(workType => {
               const addtionalWorkTypesInnerGridDailyStyles =
                  selectedView === 0
                     ? {
                          paddingLeft: hideCreateSubtaskStyles ? '100px' : '150px',
                       }
                     : {}
               return (
                  <Grid
                     key={workType.name}
                     container
                     style={{
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        minHeight: 60,
                     }}
                  >
                     <Grid
                        item
                        xs={4}
                        {...dailyViewGridProps}
                        style={{
                           backgroundColor: '#def1fa',
                           ...centerAlign,
                           justifyContent: 'left',
                           paddingLeft: 52,
                           ...addtionalWorkTypesInnerGridDailyStyles,
                        }}
                     >
                        {t(workType.name)}
                     </Grid>
                     {weekDates.map(weekDate => {
                        const showInput =
                           (selectedView === 0 && isSameDay(weekDate, chosenDate)) ||
                           selectedView === 1
                        const disableInputByAssignments = showInput
                           ? disabledDatesByAssignments?.[project.ProjectID]?.[
                                new Date(weekDate).getDate()
                             ]
                           : false
                        const todayStyle =
                           selectedView === 1 && isToday(weekDate)
                              ? {
                                   backgroundColor: '#8ACCE6',
                                   borderBottom: '1px solid #8ACCE6',
                                   marginBottom: '-1px',
                                }
                              : {}
                        const dailyGridProps =
                           selectedView === 0
                              ? {
                                   xs: isSameDay(chosenDate, weekDate) ? 1.5 : 0,
                                }
                              : {}

                        const isTodayInput =
                           (selectedView === 1 && isToday(weekDate)) ||
                           (selectedView === 0 && isSameDay(chosenDate, weekDate))

                        return (
                           <Grid
                              key={new Date(weekDate).toDateString()}
                              style={{
                                 textAlign: 'center',
                                 backgroundColor: '#def1fa',
                                 ...todayStyle,
                                 ...centerAlign,
                              }}
                              item
                              xs={1}
                              {...dailyGridProps}
                           >
                              {showInput ? (
                                 <TimesheetTimeInput
                                    onChange={input =>
                                       onInputChange(input, project.Name, weekDate, workType.id)
                                    }
                                    value={returnInputValue(project.Name, weekDate, workType.id)}
                                    disabled={
                                       isInputDisabled(weekDate) || disableInputByAssignments
                                    }
                                    onBlur={() =>
                                       onInputOnBlur(project.Name, weekDate, workType.id)
                                    }
                                    isPlaceholder={
                                       !isTemporaryInputValue(project.Name, weekDate, workType.id)
                                    }
                                    {...(selectedView === 0 ? { style: { maxWidth: '70px' } } : {})}
                                    isTodayInput={isTodayInput}
                                 />
                              ) : (
                                 <div />
                              )}
                           </Grid>
                        )
                     })}
                     {selectedView === 1 && (
                        <Grid
                           item
                           xs={1}
                           style={{
                              borderLeft: '1px solid rgba(224, 224, 224, 1)',
                              fontWeight: 700,
                              color: '#454545',
                              ...centerAlign,
                           }}
                        />
                     )}
                  </Grid>
               )
            })}
         </Collapse>
      </Fragment>
   )
}

export default React.memo(withTranslation()(TimesheetRow))
