import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Button, Grid, Modal, TextField } from '@mui/material'
import { withTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import { createProject } from '../../Api'

function getModalStyle() {
   return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
   }
}

const useStyles = makeStyles(theme => ({
   paper: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 2, 2),
   },
   body: {
      marginBottom: '20px',
   },
   buttonsPosition: {
      float: 'right',
   },
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
   select: {
      marginTop: '0.25rem',
      width: '100%',
      height: '40px',
      fontSize: '0.875rem',
      borderRadius: '4px',
      '& .MuiOutlinedInput-input': {
         padding: '5px',
         paddingBottom: '2px',
         paddingLeft: '10px',
      },
   },
   notes: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
   },
   autocomplete: {
      marginTop: '0.25rem',
      width: '100%',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiInputBase-root': {
         height: '40px',
         padding: 0,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
         padding: 0,
         paddingLeft: '10px',
      },
   },
   datepickerField: {
      '& .react-datepicker-wrapper': {
         width: '100%',
      },
   },
}))

const CreateProjectModal = ({
   openModal,
   toggleModal,
   userInfo,
   showSuccessfulSnackbar,
   showErrorSnackbar,
   fetchProjects,
   t,
}) => {
   const initialProjectData = {
      Name: '',
   }
   const [projectData, setProjectData] = useState(initialProjectData)
   const [errorField, setErrorField] = useState(false)
   const classes = useStyles()
   const [modalStyle] = React.useState(getModalStyle)

   const updateProjectData = (property, data) => {
      setProjectData(prevState => {
         return { ...prevState, [property]: data }
      })
   }

   const createProjectHandle = () => {
      if (projectData.Name.trim() === '') {
         setErrorField(true)
      } else {
         innerToggleModal()
         const data = {
            Name: projectData.Name,
         }
         createProject(data, userInfo)
            .then(res => {
               fetchProjects(res.data._id)
               showSuccessfulSnackbar(t('successToastCreatedProject'))
            })
            .catch(() => showErrorSnackbar(t('errorToastCreatedProject')))
      }
   }

   const innerToggleModal = () => {
      setProjectData(initialProjectData)
      errorField && setErrorField(false)
      toggleModal()
   }

   return (
      <Modal
         open={openModal}
         onClose={innerToggleModal}
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
         style={{ overflow: 'scroll' }}
      >
         <Paper className={classes.paper} style={modalStyle}>
            <h5 id="simple-modal-title">{t('createProjectModalTitle')}</h5>
            <Grid
               container
               direction="row"
               justifyContent="flex-start"
               alignItems="flex-start"
               spacing={1}
               style={{ padding: 10, paddingBottom: 10 }}
            >
               <Grid item xs={12} className={'pb-1 my-1'}>
                  <TextField
                     id="outlined-multiline-static"
                     variant="outlined"
                     className={classes.textField}
                     value={projectData.Name}
                     onChange={e => {
                        if (e.target.value.trim() === '') {
                           setErrorField(true)
                        } else {
                           setErrorField(false)
                        }
                        updateProjectData('Name', e.target.value)
                     }}
                     placeholder={
                        !errorField
                           ? t('projectManagementProjectTitleText')
                           : t('createProjectModalTitleErrorField')
                     }
                     error={errorField}
                  />
               </Grid>
            </Grid>
            <div className={classes.buttonsPosition}>
               <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => createProjectHandle()}
                  disabled={errorField}
               >
                  {t('saveButtonTitle')}
               </Button>
               <Button
                  color={'secondary'}
                  size="small"
                  variant="contained"
                  onClick={innerToggleModal}
                  className={'ml-2'}
               >
                  {t('cancelButtonTitle')}
               </Button>
            </div>
         </Paper>
      </Modal>
   )
}

export default withTranslation()(CreateProjectModal)
