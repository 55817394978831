import React from 'react'
import { Typography, Tooltip, Avatar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import CancelIcon from '@mui/icons-material/Cancel'
import { getAndWrapUser } from '../../../../../util/wrappers'
import { getNickOrLabel } from '../../../../../util/userProps'

const useStyles = makeStyles({
   tooltip: {
      backgroundColor: '#616161',
   },
   avatar: {
      color: '#fff',
      backgroundColor: '#0D2540',
      padding: '15px',
   },
})

const TeamLeadIcon = ({ userId, t, wrappedUserData, teams }) => {
   const classes = useStyles()

   const userTeam = () => {
      return teams
         .filter(team => team.MemberIDs.includes(userId))
         .filter(team => userId !== team.TeamLead)
   }

   const getInitials = person => {
      return person?.initials ? person.initials : ``
   }

   const UnassignedTooltip = () => {
      return (
         <div
            style={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'flex-start',
               paddingBottom: '8px',
            }}
         >
            <div>
               <CancelIcon fontSize="small" />
               <Typography
                  variant="overline"
                  style={{ fontSize: 10, fontWeight: 'bold', margin: '0px 4px' }}
               >
                  {t('unassignedToTeam')}
               </Typography>
            </div>
            <Typography variant="caption" style={{ fontSize: 10 }}>
               {t('noTeamLead')}
            </Typography>
         </div>
      )
   }

   return (
      <Tooltip
         classes={{ tooltip: classes.tooltip }}
         arrow
         title={
            userTeam().length ? (
               userTeam().map(team =>
                  team.TeamLead ? (
                     <div
                        key={team._id}
                        style={{
                           display: 'flex',
                           flexDirection: 'row',
                           alignItems: 'center',
                           padding: '8px 0px',
                        }}
                     >
                        <Typography variant="caption" style={{ fontSize: 8, margin: '0px 4px' }}>
                           {t('TL')}:
                        </Typography>
                        <Typography variant="h6" style={{ fontSize: 10 }}>
                           {getNickOrLabel(getAndWrapUser(wrappedUserData, team.TeamLead))}
                        </Typography>
                     </div>
                  ) : (
                     <UnassignedTooltip key={team._id} />
                  )
               )
            ) : (
               <UnassignedTooltip />
            )
         }
      >
         <Avatar className={classes.avatar}>
            {userTeam().length ? (
               userTeam().map(team =>
                  team.TeamLead ? (
                     <Typography key={team._id}>{getInitials(getAndWrapUser(wrappedUserData, team.TeamLead))}</Typography>
                  ) : (
                     <CloseIcon key={team._id}/>
                  )
               )
            ) : (
               <CloseIcon />
            )}
         </Avatar>
      </Tooltip>
   )
}

export default withTranslation()(TeamLeadIcon)
