import { Box } from '@mui/material'
import { withTranslation } from 'react-i18next'
import ReactDatePicker from 'react-datepicker'
import {
   CalendarToday as CalendarTodayIcon,
   KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'
const DatePicker = ({ hover, handleDate, selectedPeriod, min, max, showYearDropdown }) => {
   return (
      <Box sx={{ position: 'relative', zIndex: '10' }}>
         <ReactDatePicker
            dateFormat="dd.MM.yyyy"
            id={hover === 1 ? 'datepicker-field-report-1' : 'datepicker-field-report-2'}
            onChange={date => handleDate(date)}
            selected={selectedPeriod}
            minDate={min}
            maxDate={max}
            showYearDropdown={showYearDropdown}
            yearDropdownItemNumber={10}
            scrollableYearDropdown={true}
            dateFormatCalendar={showYearDropdown ? 'MMMM' : undefined} // show only the month when there is year select dropdown
         />

         <KeyboardArrowDownIcon
            className={hover === 1 ? 'hoverDatePicker-1' : 'hoverDatePicker-2'}
            sx={{ position: 'absolute', zIndex: 3, right: 5, bottom: 10 }}
         />
         <CalendarTodayIcon
            className={hover === 1 ? 'hoverDatePicker-1' : 'hoverDatePicker-2'}
            sx={{ position: 'absolute', zIndex: 3, right: 170, bottom: 10 }}
         />
      </Box>
   )
}

export default withTranslation()(DatePicker)
