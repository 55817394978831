import React, { useCallback } from 'react'
import { Grid, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import DatePicker from 'react-datepicker'
import { parseJSON } from 'date-fns'
import { Autocomplete } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import makeStyles from '@mui/styles/makeStyles'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
   textField: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '30px',
         padding: '5px',
         paddingLeft: '10px',
      },
   },
   select: {
      marginTop: '0.25rem',
      width: '100%',
      height: '40px',
      fontSize: '0.875rem',
      borderRadius: '4px',
      '& .MuiOutlinedInput-input': {
         padding: '5px',
         paddingBottom: '2px',
         paddingLeft: '10px',
      },
   },
   notes: {
      width: '100%',
      marginTop: '0.25rem',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
   },
   autocomplete: {
      marginTop: '0.25rem',
      width: '100%',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiInputBase-root': {
         height: '40px',
         padding: 0,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
         padding: 0,
         paddingLeft: '10px',
      },
   },
   datepickerField: {
      '& .react-datepicker-wrapper': {
         width: '100%',
      },
   },
   infoIcon: {
      fontSize: '18px',
      color: 'gray',
      marginLeft: '5px',
      marginTop: '-5px',
   },
   infoIconPM: {
      fontSize: '18px',
      color: 'red',
      marginLeft: '5px',
      marginTop: '-5px',
   },
}))

const ProjectManagementProjectForm = ({
   nameFieldError,
   selectedProject,
   setNameFieldError,
   updateSelectedProject,
   users,
   activeUsers,
   t,
}) => {
   const classes = useStyles()

   const isPMActiveUser = useCallback(
      id => {
         return Boolean(activeUsers.find(user => user._id === id))
      },
      [activeUsers]
   )

   return (
      <Grid container direction="row" alignItems="flex-start">
         <Grid item xs={12} className={'pr-3 my-2'}>
            <Typography variant="body2">{t('projectManagementProjectTitleText')}</Typography>
            <TextField
               className={classes.textField}
               placeholder={
                  !nameFieldError
                     ? t('projectManagementProjectTitleText')
                     : t('createProjectModalTitleErrorField')
               }
               variant={'outlined'}
               value={
                  selectedProject.Name !== undefined && selectedProject.Name !== null
                     ? selectedProject.Name
                     : ''
               }
               error={nameFieldError}
               onChange={event => {
                  if (event.target.value !== '') {
                     nameFieldError && setNameFieldError(false)
                  } else {
                     setNameFieldError(true)
                  }
                  updateSelectedProject('Name', event.target.value)
               }}
            />
         </Grid>
         <Grid item xs={6} className={'pr-3 my-2'}>
            <Typography variant="body2">{t('projectManagementProjectStartText')}</Typography>
            <div className={classes.datepickerField}>
               <DatePicker
                  selected={
                     selectedProject.StartDate !== null && selectedProject.StartDate !== undefined
                        ? parseJSON(selectedProject.StartDate)
                        : null
                  }
                  onChange={date => updateSelectedProject('StartDate', date)}
                  dateFormat="yyyy-MM-dd"
                  locale={t('datepickerLocale')}
                  showWeekNumbers
                  yearDropdownItemNumber={3}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  className={'datepicker-field mt-1'}
                  openToDate={
                     selectedProject.StartDate !== null && selectedProject.StartDate !== undefined
                        ? parseJSON(selectedProject.StartDate)
                        : null
                  }
                  placeholderText={t('projectManagementProjectStartText')}
               />
            </div>
         </Grid>
         <Grid item xs={6} className={'px-3 my-2'}>
            <Typography variant="body2">{t('projectManagementProjectEndText')}</Typography>
            <div className={classes.datepickerField}>
               <DatePicker
                  selected={
                     selectedProject.EndDate !== null && selectedProject.EndDate !== undefined
                        ? parseJSON(selectedProject.EndDate)
                        : null
                  }
                  onChange={date => updateSelectedProject('EndDate', date)}
                  dateFormat="yyyy-MM-dd"
                  locale={t('datepickerLocale')}
                  showWeekNumbers
                  yearDropdownItemNumber={3}
                  showMonthDropdown={true}
                  showYearDropdown={true}
                  className={'datepicker-field mt-1'}
                  openToDate={
                     selectedProject.EndDate !== null && selectedProject.EndDate !== undefined
                        ? parseJSON(selectedProject.EndDate)
                        : null
                  }
                  minDate={
                     selectedProject.StartDate !== null && selectedProject.StartDate !== undefined
                        ? parseJSON(selectedProject.StartDate)
                        : null
                  }
                  placeholderText={t('projectManagementProjectEndText')}
               />
            </div>
         </Grid>
         <Grid item xs={6} className={'pr-3 my-2'}>
            <Typography variant="body2">{t('projectManagementProjectTypeText')}</Typography>
            <Select
               value={
                  selectedProject.Type !== undefined && selectedProject.Type !== null
                     ? selectedProject.Type
                     : ''
               }
               onChange={event => updateSelectedProject('Type', event.target.value)}
               variant={'outlined'}
               className={classes.select}
               placeholder={t('projectManagementProjectTypeText')}
            >
               <MenuItem value={'External'}>External</MenuItem>
               <MenuItem value={'Internal'}>Internal</MenuItem>
            </Select>
         </Grid>
         <Grid item xs={6} className={'px-3 my-2'}>
            <Typography variant="body2">{t('projectManagementFixedPriceText')}</Typography>
            <Select
               value={
                  selectedProject.FixedPrice !== undefined && selectedProject.FixedPrice !== null
                     ? selectedProject.FixedPrice
                     : ''
               }
               onChange={event => updateSelectedProject('FixedPrice', event.target.value)}
               className={classes.select}
               variant={'outlined'}
               placeholder={t('projectManagementFixedPriceText')}
            >
               <MenuItem value={false}>No</MenuItem>
               <MenuItem value={true}>Yes</MenuItem>
            </Select>
         </Grid>
         <Grid item xs={6} className={'pr-3 my-2'}>
            <Typography variant="body2">
               {t('projectManagementProjectManagerText')}
               {selectedProject.ProjectManager &&
                  !isPMActiveUser(selectedProject.ProjectManager) && (
                     <Tooltip
                        title={'This PM is not an active user'}
                        placement="top"
                        leaveDelay={700}
                        arrow
                     >
                        <InfoIcon className={`${classes.infoIconPM}`} />
                     </Tooltip>
                  )}
            </Typography>

            <div>
               <Autocomplete
                  options={activeUsers}
                  className={classes.autocomplete}
                  getOptionLabel={option => {
                     if (option !== '' && option !== undefined && option !== null)
                        return `${option.FirstName}${
                           option.MiddleName !== '' ? ' ' + option.MiddleName + ' ' : ' '
                        }${option.LastName}`
                     else return ''
                  }}
                  isOptionEqualToValue={option =>
                     option !== '' && option !== undefined && option !== null
                  }
                  onChange={(e, newValue) => {
                     if (newValue !== null) {
                        if (selectedProject.MemberIDs.includes(newValue._id)) {
                           updateSelectedProject('ProjectManager', newValue._id)
                        } else {
                           const user = activeUsers.find(user => user._id === newValue._id)
                           const userDetails = {
                              _id: user._id,
                              Name: `${user.FirstName}${
                                 user.MiddleName !== '' ? ` ${user.MiddleName} ` : ' '
                              }${user.LastName}`,
                              Assignments: [],
                           }

                           updateSelectedProject('MemberIDs', [
                              ...selectedProject.MemberIDs,
                              user._id,
                           ])
                           updateSelectedProject('MemberDetails', [
                              ...selectedProject.MemberDetails,
                              userDetails,
                           ])
                           updateSelectedProject('ProjectManager', user._id)
                        }
                     } else {
                        updateSelectedProject('ProjectManager', null)
                     }
                  }}
                  value={
                     selectedProject.ProjectManager !== '' &&
                     selectedProject.ProjectManager !== undefined &&
                     selectedProject.ProjectManager !== null
                        ? users.find(user => user._id === selectedProject.ProjectManager)
                        : ''
                  }
                  renderInput={params => (
                     <TextField
                        {...params}
                        placeholder={t('projectManagementProjectManagerText')}
                        variant="outlined"
                     />
                  )}
               />
            </div>
         </Grid>
         <Grid xs={6} className={'px-3 my-2'}>
            <Typography variant="body2">
               Planned hours
               <Tooltip
                  title={'Planned hours for the project'}
                  placement="top"
                  leaveDelay={700}
                  arrow
               >
                  <InfoIcon className={`${classes.infoIcon}`} />
               </Tooltip>
            </Typography>
            <TextField
               className={classes.textField}
               placeholder={'Planned hours'}
               variant={'outlined'}
               value={selectedProject?.PlannedHours ? selectedProject.PlannedHours : ''}
               onChange={event => updateSelectedProject('PlannedHours', event.target.value)}
            />
         </Grid>
         <Grid item xs={12} className={'pr-3 my-2'}>
            <Typography variant="body2">
               {t('projectManagementProjectDocumentsText')}
               <Tooltip
                  title={t('projectManagementDocumentsTooltipText')}
                  placement="top"
                  leaveDelay={700}
                  arrow
               >
                  <InfoIcon className={`${classes.infoIcon}`} />
               </Tooltip>
            </Typography>
            <TextField
               className={classes.textField}
               placeholder={t('projectManagementProjectDocumentsText')}
               variant={'outlined'}
               value={
                  selectedProject.Documents !== undefined && selectedProject.Documents !== null
                     ? selectedProject.Documents
                     : ''
               }
               onChange={event => updateSelectedProject('Documents', event.target.value)}
            />
         </Grid>
         <Grid item xs={6} className={'pr-3 my-2'}>
            <Typography variant="body2">
               {t('projectManagementProjectStatusText')}
               <Tooltip
                  title={t('projectManagementStatusTooltipText')}
                  placement="top"
                  leaveDelay={700}
                  arrow
               >
                  <InfoIcon className={`${classes.infoIcon}`} />
               </Tooltip>
            </Typography>
            <Select
               value={
                  selectedProject.Status !== undefined && selectedProject.Status !== null
                     ? selectedProject.Status
                     : ''
               }
               variant={'outlined'}
               className={classes.select}
               placeholder={t('projectManagementProjectStatusText')}
               onChange={event => {
                  updateSelectedProject('Status', event.target.value)
                  if (event.target.value === 'Active')
                     updateSelectedProject('ProbabilityPercent', 100)
               }}
            >
               <MenuItem value={'Active Opportunity'}>Active Opportunity</MenuItem>
               <MenuItem value={'Canceled Opportunity'}>Canceled Opportunity</MenuItem>
               <MenuItem value={'Active'}>Active Project</MenuItem>
               <MenuItem value={'Closed'}>Closed</MenuItem>
            </Select>
         </Grid>
         <Grid item xs={6} className={'px-3 my-2'}>
            <Typography variant="body2">{t('projectManagementProbabilityPercentText')}</Typography>
            <Select
               value={
                  selectedProject.ProbabilityPercent !== undefined &&
                  selectedProject.ProbabilityPercent !== null
                     ? selectedProject.ProbabilityPercent
                     : ''
               }
               variant={'outlined'}
               className={classes.select}
               placeholder={t('projectManagementProbabilityPercentText')}
               onChange={event => {
                  updateSelectedProject('ProbabilityPercent', event.target.value)
               }}
               disabled={selectedProject.Status !== 'Active Opportunity'}
            >
               {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(percent => (
                  <MenuItem value={percent} key={percent}>{`${percent}%`}</MenuItem>
               ))}
            </Select>
         </Grid>
         <Grid item xs={12} className={'pr-3 my-2'}>
            <Typography variant="body2">{t('projectManagementCommentText')}</Typography>
            <TextField
               id="outlined-multiline-static"
               multiline
               minRows={4}
               variant="outlined"
               className={classes.notes}
               value={
                  selectedProject.Comment !== undefined && selectedProject.Comment !== null
                     ? selectedProject.Comment
                     : ''
               }
               onChange={e => updateSelectedProject('Comment', e.target.value)}
               placeholder={t('projectManagementCommentText')}
            />
         </Grid>
      </Grid>
   )
}
const mapStateToProps = state => {
   return {
      users: state.users,
      activeUsers: state.activeUsers,
   }
}

export default withTranslation()(connect(mapStateToProps)(ProjectManagementProjectForm))
