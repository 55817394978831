import React, { useEffect, useCallback } from 'react'
import themeConfig from '../theme'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles'
import { withTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { Typography, Select, MenuItem } from '@mui/material'
import { Input, InputAdornment } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'
import log from '../util/log'
import { updateCustomer, fetchCustomers } from '../Api'
import clsx from 'clsx'

const { styles } = themeConfig()

const CustomerFeatures = props => {
   const { userInfo, t, classes } = props

   const match = useRouteMatch('/customerfeatures/:id')
   const id = parseInt(match.params.id)

   const [customer, setCustomer] = React.useState(null)

   const [tenantId, setTenantId] = React.useState('')
   const [GIPLoginPath, setGIPLoginPath] = React.useState('')

   const tenantIdRef = React.useRef()
   const gipLoginPathRef = React.useRef()

   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      severity: 'info',
   })
   const closeSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return
      }
      setSnackbarState({ open: false })
   }

   const loadData = useCallback(async () => {
      try {
         const result = await fetchCustomers(userInfo)
         console.log('data: ' + JSON.stringify(result.data))
         const filteredCustomer = result.data.filter(c => c.CustomerID === id)[0]
         log.info('Customer: ' + JSON.stringify(filteredCustomer))
         setCustomer(filteredCustomer)
      } catch (err) {
         log.error(err)
      }
   }, [userInfo, id])

   useEffect(() => {
      loadData()
   }, [loadData])

   const saveTenantId = async () => {
      const update = { CustomerID: customer.CustomerID, TenantId: tenantId }

      setCustomer({ ...customer, TenantId: tenantId })
      await updateCustomer(update, props.userInfo).then(
         response =>
            setSnackbarState({
               open: true,
               message: t('Saved'),
               severity: 'success',
            }),
         response =>
            setSnackbarState({
               open: true,
               message: t('errorText1'),
               severity: 'error',
            })
      )
   }
   const saveGIPLoginPath = async () => {
      const update = { CustomerID: customer.CustomerID, GIPLoginPath: GIPLoginPath }

      setCustomer({ ...customer, GIPLoginPath: GIPLoginPath })
      await updateCustomer(update, props.userInfo).then(
         response =>
            setSnackbarState({
               open: true,
               message: t('Saved'),
               severity: 'success',
            }),
         response =>
            setSnackbarState({
               open: true,
               message: t('errorText1'),
               severity: 'error',
            })
      )
   }

   const updateFeatures = async features => {
      setCustomer({ ...customer, Features: features })
      const update = { CustomerID: customer.CustomerID, Features: features }

      await updateCustomer(update, props.userInfo).then(
         response =>
            setSnackbarState({
               open: true,
               message: t('Saved'),
               severity: 'success',
            }),
         response =>
            setSnackbarState({
               open: true,
               message: t('errorText1'),
               severity: 'error',
            })
      )
   }

   if (!customer) {
      return <React.Fragment>Loading...</React.Fragment>
   }
   return (
      <React.Fragment>
         <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar}>
            <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>

         <Paper className="contentPaper" style={{ maxWidth: 580, boxShadow: 'none' }}>
            <div className={'contentWrapper'}>
               <div style={{ padding: 10 }}>
                  <Typography>
                     <b>{t('customerNameLabel')}:</b> {customer.Name}
                  </Typography>
               </div>

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Typography>
                     <b>{t('salaryReportVariant')}:</b>
                  </Typography>
                  <Select
                     value={customer.Features.SalaryReportVariant}
                     onChange={event => {
                        updateFeatures({
                           ...customer.Features,
                           SalaryReportVariant: event.target.value,
                        })
                     }}
                  >
                     <MenuItem key={'NovartoNordic'} value={'NovartoNordic'}>
                        NovartoNordic
                     </MenuItem>
                     <MenuItem key={'NovartoBulgaria'} value={'NovartoBulgaria'}>
                        NovartoBulgaria
                     </MenuItem>
                     <MenuItem key={'Default'} value={'Default'}>
                        Default
                     </MenuItem>
                  </Select>
               </div>

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Typography>
                     <b>{t('printFormVariant')}:</b>
                  </Typography>
                  <Select
                     value={customer.Features.PrintFormVariant}
                     placeholder={t('doNotUsePrintFeature')}
                     displayEmpty
                     onChange={event => {
                        updateFeatures({
                           ...customer.Features,
                           PrintFormVariant: event.target.value,
                        })
                     }}
                  >
                     <MenuItem key={'doNotUsePrintFeature'} value={''}>
                        {t('doNotUsePrintFeature')}
                     </MenuItem>
                     <MenuItem key={'BulgariaPrintForm'} value={'BulgariaPrintForm'}>
                        BulgariaPrintForm
                     </MenuItem>
                     <MenuItem key={'HillsPrintForm'} value={'HillsPrintForm'}>
                        HillsPrintForm
                     </MenuItem>
                  </Select>
               </div>

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Typography>
                     <b>{'Google Identity Platform TenantId'}:</b> {customer.TenantId}
                  </Typography>

                  <Input
                     autoComplete="off"
                     style={{ width: 500 }}
                     placeholder={t('typeHereToChange')}
                     id="input_field_tenantId"
                     key="input_field_tenantId"
                     onFocus={() => {
                        if (tenantIdRef.current) {
                           tenantIdRef.current.style.visibility = 'visible'
                        }
                     }}
                     onBlur={() => {
                        if (tenantIdRef.current) {
                           tenantIdRef.current.style.visibility = 'hidden'
                        }
                     }}
                     endAdornment={
                        <InputAdornment
                           position="end"
                           className={clsx(classes.hidden)}
                           ref={tenantIdRef}
                        >
                           {t('enterToSave')}
                        </InputAdornment>
                     }
                     onChange={event => {
                        setTenantId(event.target.value)
                     }}
                     onKeyPress={event => {
                        if (event.key === 'Enter') {
                           saveTenantId()
                           event.target.value = null
                        }
                     }}
                  ></Input>
               </div>

               <div style={{ padding: 10, marginBottom: 10 }}>
                  <Typography>
                     <b>{'Google Identity Platform Login Path'}:</b> {customer.GIPLoginPath}
                  </Typography>

                  <Input
                     autoComplete="off"
                     style={{ width: 500 }}
                     placeholder={t('typeHereToChange')}
                     id="input_field_GIPLoginPath"
                     key="input_field_GIPLoginPath"
                     onFocus={() => {
                        if (gipLoginPathRef.current) {
                           gipLoginPathRef.current.style.visibility = 'visible'
                        }
                     }}
                     onBlur={() => {
                        if (gipLoginPathRef.current) {
                           gipLoginPathRef.current.style.visibility = 'hidden'
                        }
                     }}
                     endAdornment={
                        <InputAdornment
                           position="end"
                           className={clsx(classes.hidden)}
                           ref={gipLoginPathRef}
                        >
                           {t('enterToSave')}
                        </InputAdornment>
                     }
                     onChange={event => {
                        setGIPLoginPath(event.target.value)
                     }}
                     onKeyPress={event => {
                        if (event.key === 'Enter') {
                           saveGIPLoginPath()
                           event.target.value = null
                        }
                     }}
                  ></Input>
               </div>
            </div>
         </Paper>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
   }
}

export default withStyles(styles)(withTranslation()(connect(mapStateToProps)(CustomerFeatures)))
