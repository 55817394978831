import React, { useEffect, useRef, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {
   Button,
   Grid,
   Modal,
   TextField,
   Snackbar,
   Typography,
   Accordion,
   AccordionSummary,
   AccordionDetails,
} from '@mui/material'
import { Autocomplete, Rating } from '@mui/material'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Formik, Field, Form } from 'formik'
import Alert from '@mui/material/Alert'
import { fetchCustomerForAdmin, getSkillsForCustomer, createUpdateBasicUserInfo } from '../../Api'
import { Delete as DeleteIcon } from '@mui/icons-material'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import DatePicker from 'react-datepicker'
import { parseJSON } from 'date-fns'
import { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import bg from 'date-fns/locale/bg'
import { v4 as uuidv4 } from 'uuid'

registerLocale('sv', sv)
registerLocale('en-GB', en)
registerLocale('bg', bg)

function TabPanel(props) {
   const { children, value, index, ...other } = props

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`nav-tabpanel-${index}`}
         aria-labelledby={`nav-tab-${index}`}
         {...other}
      >
         {value === index && <Box p={3}>{children}</Box>}
      </div>
   )
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.any.isRequired,
   value: PropTypes.any.isRequired,
}

function getModalStyle() {
   return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
   }
}

const useStyles = makeStyles(theme => ({
   root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
   },
   paper: {
      position: 'absolute',
      width: 1000,
      height: 600,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(1, 1, 1),
   },
   body: {
      marginBottom: '20px',
   },
   buttonsPosition: {
      position: 'absolute',
      right: 10,
      bottom: 0,
   },
   textField: {
      minWidth: '100%',
      backgroundColor: 'white',
      '& .MuiOutlinedInput-root': {
         borderRadius: '4px',
      },
      '& .MuiOutlinedInput-input': {
         height: '22px',
         padding: '15px',
         paddingRight: '40px',
      },
   },
   skill: {
      border: '1px solid #e6e6e6',
      borderRadius: '4px',
      padding: '10px',
   },
   icon: {
      float: 'right',
      cursor: 'pointer',
      color: '#b3b3b3',
      '&:hover': {
         color: '#4d4d4d',
      },
   },
   tab: {
      position: 'relative',
      height: '520px',
   },
   deleteModalPaper: {
      zIndex: 200,
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
   },
   deleteModalBody: {
      marginBottom: '20px',
   },
   deleteModalButtonsPosition: {
      float: 'right',
   },
   addButtonEN: {
      padding: '15px 28px',
   },
   addButtonBG: {
      padding: '15px',
   },
   errorTextField: {
      borderColor: 'red',
   },
   datepickerField: {
      width: '100%',
      '& .react-datepicker-wrapper': {
         width: '100%',
         height: '50px',
      },
      '& .datepicker-field': {
         height: '50px',
      },
   },
}))

const CertificateRow = ({
   certificate,
   formState,
   setFormState,
   updateUpdateUserData,
   handleDeleteCertificate,
   newEntryFormState,
   setNewEntryFormState,
   showAddNewEntryAccordion,
   setShowAddNewEntryAccordion,
   setExpanded,
   existingEntryFormState,
   userData,
   classes,
   t,
}) => {
   const addCertificatesToUserData = () => {
      const newCertificates = [...userData.Certificates, newEntryFormState]
      updateUpdateUserData({ Certificates: newCertificates })
      setNewEntryFormState({
         Title: '',
         Url: '',
         DateOfIssue: null,
         ExpirationDate: null,
      })
      setShowAddNewEntryAccordion(false)
   }

   const updateExistingCertificate = id => {
      const updatedCertificates = userData.Certificates.map(certificate => {
         if (certificate._id === id) {
            return existingEntryFormState
         } else return certificate
      })
      updateUpdateUserData({ Certificates: updatedCertificates })
      setExpanded(false)
   }

   return (
      <>
         <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item xs={12}>
               <Typography variant="body2">{t('Title')}</Typography>
               <TextField
                  key={'title'}
                  value={formState.Title}
                  onChange={event => {
                     setFormState({ ...formState, Title: event.target.value })
                  }}
                  variant="outlined"
                  className={classes.textField}
               />
            </Grid>
            <Grid item xs={12}>
               <Typography variant="body2">{t('urlToCertificate')}</Typography>
               <TextField
                  key={'url'}
                  value={formState.Url}
                  variant="outlined"
                  className={classes.textField}
                  onChange={event => {
                     setFormState({ ...formState, Url: event.target.value })
                  }}
               />
            </Grid>
            <Grid item xs={6}>
               <div className={classes.datepickerField}>
                  <Typography variant="body2">{t('issueDate')}</Typography>
                  <DatePicker
                     selected={formState.DateOfIssue}
                     dateFormat="dd.MM.yyyy"
                     locale={t('datepickerLocale')}
                     className={'datepicker-field mt-1'}
                     onChange={date => {
                        setFormState({ ...formState, DateOfIssue: date })
                     }}
                  />
               </div>
            </Grid>
            <Grid item xs={6}>
               <div className={classes.datepickerField}>
                  <Typography variant="body2">{t('expirationDate')}</Typography>
                  <DatePicker
                     selected={formState.ExpirationDate}
                     dateFormat="dd.MM.yyyy"
                     locale={t('datepickerLocale')}
                     className={'datepicker-field mt-1'}
                     onChange={date => {
                        setFormState({ ...formState, ExpirationDate: date })
                     }}
                  />
               </div>
            </Grid>
            <Grid item xs={12}>
               <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                     size="small"
                     variant="contained"
                     color="primary"
                     disabled={!formState.Title || !formState.Url}
                     onClick={() =>
                        certificate !== undefined
                           ? updateExistingCertificate(certificate._id)
                           : addCertificatesToUserData()
                     }
                     style={{ marginRight: 10 }}
                  >
                     {t('saveButtonTitle')}
                  </Button>
                  <Button
                     size="small"
                     variant="contained"
                     color="secondary"
                     onClick={() =>
                        certificate !== undefined && certificate._id
                           ? handleDeleteCertificate({ certificateID: certificate._id })
                           : certificate !== undefined && certificate.Url
                           ? handleDeleteCertificate({ certificateUrl: certificate.Url })
                           : setFormState({
                                Title: '',
                                Url: '',
                                DateOfIssue: null,
                                ExpirationDate: null,
                             })
                     }
                  >
                     {t('deleteButtonTitle')}
                  </Button>
               </div>
            </Grid>
         </Grid>
      </>
   )
}

const CreateEditEmployeeModal = ({
   openModal,
   toggleModal,
   userData,
   performUpdate,
   reloadUsers,
   updateUpdateUserData,
   updatePerformUpdate,
   userInfo,
   t,
}) => {
   const classes = useStyles()
   const [modalStyle] = useState(getModalStyle)
   const [currentTab, setCurrentTab] = useState(0)
   const [initialSkills, setInitialSkills] = useState([])
   const [selectedSkills, setSelectedSkills] = useState([])
   const [skillGroups, setSkillGroups] = useState([])
   const [snackbarState, setSnackbarState] = useState({
      open: false,
      message: '',
      severity: 'info',
   })
   const [openDeleteModal, setOpenDeleteModal] = useState(false)
   const [skillIDToDelete, setSkillIDToDelete] = useState('')
   const [certificateIDToDelete, setCertificateIDToDelete] = useState('')
   const [temporaryCertificateToDelete, setTemporaryCertificateToDelete] = useState('')
   const [showAddNewEntryAccordion, setShowAddNewEntryAccordion] = useState(false)
   const [selectedCertificateIdToEdit, setSelectedCertificateIdToEdit] = useState('')
   const [expanded, setExpanded] = useState(false)
   const [newEntryFormState, setNewEntryFormState] = useState({
      Title: '',
      Url: '',
      DateOfIssue: null,
      ExpirationDate: null,
   })
   const [existingEntryFormState, setExistingEntryFormState] = useState({
      _id: '',
      Title: '',
      Url: '',
      DateOfIssue: null,
      ExpirationDate: null,
   })

   const levels = {
      en: {
         1: 'Trainee',
         2: 'Junior',
         3: 'Mid',
         4: 'Advanced',
         5: 'Senior',
      },
      bg: {
         1: 'Trainee',
         2: 'Junior',
         3: 'Mid',
         4: 'Advanced',
         5: 'Senior',
      },
   }

   const SignupSchema = Yup.object().shape({
      ID: Yup.string().required('Required'),
      FirstName: Yup.string().required('Required'),
      LastName: Yup.string().required('Required'),
      CompanyEmail: Yup.string().email('Invalid email').required('Required'),
   })

   useEffect(() => {
      if (performUpdate) {
         const fillSkills = async () => {
            const result = await fetchCustomerForAdmin(userInfo)
            let customer = result.data
            if (customer._id) {
               const result = await getSkillsForCustomer(customer._id, userInfo)
               setInitialSkills(result.data)
            }
         }
         fillSkills()
      }
   }, [performUpdate])

   useEffect(() => {
      setSkillGroups([...new Set(userData.Skills.map(skill => skill.GroupName))].sort())
   }, [userData])

   const handleChangeTabs = (event, newValue) => {
      setCurrentTab(newValue)
   }

   const showSuccessfulSnackbar = message => {
      setSnackbarState({ open: true, message, severity: 'success' })
   }

   const showErrorSnackbar = message => {
      setSnackbarState({ open: true, message, severity: 'error' })
   }

   const createUpdateUserBasicInfo = data => {
      createUpdateBasicUserInfo(data, userInfo, performUpdate)
         .then(response => {
            if (response.data.error) {
               showErrorSnackbar(
                  performUpdate ? t('errorToastUpdateUser') : t('errorToastCreateUser')
               )
            } else {
               updateUpdateUserData(data)
               if (!performUpdate) {
                  updateUpdateUserData({ EmployeeID: response.data.EmployeeID })
                  updatePerformUpdate()
               }
               reloadUsers()
               showSuccessfulSnackbar(
                  performUpdate ? t('successToastUpdateUser') : t('successToastCreateUser')
               )
            }
         })
         .catch(() =>
            showErrorSnackbar(performUpdate ? t('errorToastUpdateUser') : t('errorToastCreateUser'))
         )
   }

   const toggleSnackBar = () => {
      setSnackbarState({ open: false, message: '', severity: 'info' })
   }

   const updateUserByAddingSkills = () => {
      const data = {
         EmployeeID: userData.EmployeeID,
         Skills: userData.Skills,
      }
      createUpdateUserBasicInfo(data)
   }

   const addSelectedSkillsToUserSkillData = () => {
      const newSkills = [...userData.Skills, ...selectedSkills]
      updateUpdateUserData({ Skills: newSkills })
      setSelectedSkills([])
   }

   const handleDeleteIconClick = skillID => {
      setSkillIDToDelete(skillID)
      setOpenDeleteModal(prevState => !prevState)
   }
   const deleteSkillFromUserData = () => {
      toggleDeleteModal()
      updateUpdateUserData({
         Skills: userData.Skills.filter(skill => skill._id !== skillIDToDelete),
      })
   }

   const updateUserDataSkillLevel = (newSkillLevel, skillID) => {
      updateUpdateUserData({
         Skills: userData.Skills.map(skill => {
            if (skill._id === skillID) {
               return { ...skill, SkillLevel: newSkillLevel }
            } else return skill
         }),
      })
   }

   const closeModal = () => {
      setCurrentTab(0)
      toggleModal()
   }

   const toggleDeleteModal = () => {
      if (openDeleteModal) {
         setSkillIDToDelete('')
         setCertificateIDToDelete('')
         setTemporaryCertificateToDelete('')
      }
      setOpenDeleteModal(prevState => !prevState)
   }

   const refs =
      userData.Certificates &&
      userData.Certificates.length &&
      userData.Certificates.reduce((certificate, value) => {
         certificate[value.Url] = React.createRef()
         return certificate
      }, {})

   const scrollToCurrentCertificate = panel => {
      refs[panel].current.scrollIntoView({
         behavior: 'smooth',
         block: 'start',
      })
   }
   const handleChange = (panel, expanded) => {
      setExpanded(expanded ? panel : false)
      scrollToCurrentCertificate(panel)
   }

   const updateUserByAddingCertificates = () => {
      const data = {
         EmployeeID: userData.EmployeeID,
         Certificates: userData.Certificates,
      }
      createUpdateUserBasicInfo(data)
   }

   const onEditExistingCertificate = certificate => {
      setSelectedCertificateIdToEdit(certificate._id)
      setExistingEntryFormState({
         _id: certificate._id,
         Title: certificate.Title,
         Url: certificate.Url,
         DateOfIssue: certificate.DateOfIssue != null ? parseJSON(certificate.DateOfIssue) : null,
         ExpirationDate:
            certificate.ExpirationDate != null ? parseJSON(certificate.ExpirationDate) : null,
      })
   }

   const handleDeleteCertificate = ({ certificateID, certificateUrl }) => {
      if (certificateID) {
         setCertificateIDToDelete(certificateID)
      } else if (certificateUrl) {
         setTemporaryCertificateToDelete(certificateUrl)
         setShowAddNewEntryAccordion(false)
      }
      setOpenDeleteModal(prevState => !prevState)
   }

   const removeCertificateFromUserData = () => {
      let updatedCertificates
      toggleDeleteModal()
      if (certificateIDToDelete) {
         updatedCertificates = userData.Certificates.filter(
            certificate => certificate._id !== certificateIDToDelete
         )
      } else {
         updatedCertificates = userData.Certificates.filter(
            certificate => certificate.Url !== temporaryCertificateToDelete
         )
      }
      updateUpdateUserData({ Certificates: updatedCertificates })
   }

   const newEntryRef = useRef(null)

   const executeScroll = () => newEntryRef.current.scrollIntoView()

   const handleAddNewEntry = () => {
      executeScroll()
      setShowAddNewEntryAccordion(!showAddNewEntryAccordion)
   }

   return (
      <>
         <Modal
            open={openModal}
            onClose={closeModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
         >
            <div style={modalStyle} className={classes.paper}>
               <div className={classes.root}>
                  <AppBar position="static">
                     <Tabs
                        variant="fullWidth"
                        value={currentTab}
                        onChange={handleChangeTabs}
                        aria-label="nav tabs example"
                        selectionFollowsFocus
                        centered
                        TabIndicatorProps={{
                           style: {
                              backgroundColor: 'white',
                              height: '3px',
                              borderTopLeftRadius: '3px',
                              borderTopRightRadius: '3px',
                           },
                        }}
                     >
                        <Tab
                           label={t('employeeModalFirstTabLabel')}
                           value={0}
                           sx={{
                              color: 'lightgray',
                              '&.Mui-selected': {
                                 color: 'white', // color when the tab is active
                              },
                              '&.Mui-disabled': {
                                 color: 'gray',
                              },
                           }}
                        />
                        <Tab
                           label={t('employeeModalSecondTabLabel')}
                           disabled={!performUpdate}
                           value={1}
                           sx={{
                              color: 'lightgray',
                              '&.Mui-selected': {
                                 color: 'white', // color when the tab is active
                              },
                              '&.Mui-disabled': {
                                 color: 'gray',
                              },
                           }}
                        />
                        <Tab
                           label={t('employeeModalThirdTabLabel')}
                           value={2}
                           disabled={!performUpdate}
                           sx={{
                              color: 'lightgray',
                              '&.Mui-selected': {
                                 color: 'white', // color when the tab is active
                              },
                              '&.Mui-disabled': {
                                 color: 'gray',
                              },
                           }}
                        />
                        <Tab
                           label={t('employeeModalFourthTabLabel')}
                           value={3}
                           disabled={!performUpdate}
                           sx={{
                              color: 'lightgray',
                              '&.Mui-selected': {
                                 color: 'white', // color when the tab is active
                              },
                              '&.Mui-disabled': {
                                 color: 'gray',
                              },
                           }}
                        />
                     </Tabs>
                  </AppBar>
                  <TabPanel value={currentTab} index={0} className={classes.tab}>
                     <Formik
                        initialValues={{
                           ID: userData.ID,
                           FirstName: userData.FirstName,
                           MiddleName: userData.MiddleName,
                           LastName: userData.LastName,
                           CompanyEmail: userData.CompanyEmail,
                           PrivateEmail: userData.PrivateEmail,
                           CompanyMobile: userData.CompanyMobile,
                           Address: userData.Address,
                           CV: userData.CV,
                           ExternalUserID: userData.ExternalUserID,
                           ExternalUser: userData.ExternalUser,
                           ParentalLeave: userData.ParentalLeave,
                        }}
                        onSubmit={values => {
                           let data = {
                              ID: userInfo.permissions['employeesManagement']['tabFieldsID']
                                 ? values.ID
                                 : undefined,
                              EmployeeID: userData.EmployeeID,
                              FirstName: userInfo.permissions['employeesManagement'][
                                 'tabFieldsFirstName'
                              ]
                                 ? values.FirstName
                                 : undefined,
                              MiddleName: userInfo.permissions['employeesManagement'][
                                 'tabFieldsMiddleName'
                              ]
                                 ? values.MiddleName
                                 : undefined,
                              LastName: userInfo.permissions['employeesManagement'][
                                 'tabFieldsLastName'
                              ]
                                 ? values.LastName
                                 : undefined,
                              CompanyEmail: userInfo.permissions['employeesManagement'][
                                 'tabFieldsEmail'
                              ]
                                 ? values.CompanyEmail
                                 : undefined,
                              PrivateEmail: values.PrivateEmail,
                              CompanyMobile: values.CompanyMobile,
                              CV: values.CV,
                              Address: values.Address,
                              ExternalUserID: values.ExternalUserID,
                              ExternalUser: values.ExternalUser,
                              ParentalLeave: values.ParentalLeave,
                           }
                           Object.keys(data).forEach(
                              key => data[key] === undefined && delete data[key]
                           )
                           createUpdateUserBasicInfo(data)
                        }}
                        validationSchema={SignupSchema}
                     >
                        {({ errors, touched }) => (
                           <Form className={'mt-4'}>
                              <Grid
                                 container
                                 justifyContent="flex-start"
                                 alignItems="center"
                                 spacing={2}
                              >
                                 <Grid item xs={4}>
                                    <div>ID</div>
                                    <Field
                                       id="outlined-basic"
                                       name="ID"
                                       placeholder="ID"
                                       as={TextField}
                                       variant="outlined"
                                       className={classes.textField}
                                       label={errors.ID && touched.ID && errors.ID}
                                       error={errors.ID && touched.ID}
                                       disabled={
                                          !userInfo.permissions['employeesManagement'][
                                             'tabFieldsID'
                                          ]
                                       }
                                    />
                                 </Grid>
                                 <Grid item xs={8} />
                                 <Grid item xs={4}>
                                    <div>{t('employeeModalFirstName')}</div>
                                    <Field
                                       id="outlined-basic"
                                       name="FirstName"
                                       placeholder={t('employeeModalFirstName')}
                                       as={TextField}
                                       variant="outlined"
                                       className={classes.textField}
                                       error={errors.FirstName && touched.FirstName}
                                       label={
                                          errors.FirstName && touched.FirstName && errors.FirstName
                                       }
                                       disabled={
                                          !userInfo.permissions['employeesManagement'][
                                             'tabFieldsFirstName'
                                          ]
                                       }
                                    />
                                 </Grid>
                                 <Grid item xs={4}>
                                    <div>{t('employeeModalMiddleName')}</div>
                                    <Field
                                       id="outlined-basic"
                                       name="MiddleName"
                                       placeholder={t('employeeModalMiddleName')}
                                       as={TextField}
                                       variant="outlined"
                                       className={classes.textField}
                                       disabled={
                                          !userInfo.permissions['employeesManagement'][
                                             'tabFieldsMiddleName'
                                          ]
                                       }
                                    />
                                 </Grid>
                                 <Grid item xs={4}>
                                    <div>{t('employeeModalLastName')}</div>
                                    <Field
                                       id="outlined-basic"
                                       name="LastName"
                                       placeholder={t('employeeModalLastName')}
                                       as={TextField}
                                       variant="outlined"
                                       className={classes.textField}
                                       error={errors.LastName && touched.LastName}
                                       label={
                                          errors.LastName && touched.LastName && errors.LastName
                                       }
                                       disabled={
                                          !userInfo.permissions['employeesManagement'][
                                             'tabFieldsLastName'
                                          ]
                                       }
                                    />
                                 </Grid>
                                 <Grid item xs={4}>
                                    <div>{t('employeeModalEmail')}</div>
                                    <Field
                                       id="outlined-basic"
                                       name="CompanyEmail"
                                       placeholder={t('employeeModalEmail')}
                                       as={TextField}
                                       variant="outlined"
                                       className={classes.textField}
                                       error={errors.CompanyEmail && touched.CompanyEmail}
                                       label={
                                          errors.CompanyEmail &&
                                          touched.CompanyEmail &&
                                          errors.CompanyEmail
                                       }
                                       disabled={
                                          !userInfo.permissions['employeesManagement'][
                                             'tabFieldsEmail'
                                          ]
                                       }
                                    />
                                 </Grid>
                                 <Grid item xs={4}>
                                    <div>{t('employeeModalPrivateEmail')}</div>
                                    <Field
                                       id="outlined-basic"
                                       name="PrivateEmail"
                                       placeholder={t('employeeModalPrivateEmail')}
                                       as={TextField}
                                       variant="outlined"
                                       className={classes.textField}
                                    />
                                 </Grid>
                                 <Grid item xs={4}>
                                    <div>{t('employeeModalMobile')}</div>
                                    <Field
                                       id="outlined-basic"
                                       name="CompanyMobile"
                                       placeholder={t('employeeModalMobile')}
                                       as={TextField}
                                       variant="outlined"
                                       className={classes.textField}
                                    />
                                 </Grid>
                                 <Grid item xs={4}>
                                    <div>{t('employeeModalAddress')}</div>
                                    <Field
                                       id="outlined-basic"
                                       name="Address"
                                       placeholder={t('employeeModalAddress')}
                                       as={TextField}
                                       variant="outlined"
                                       className={classes.textField}
                                    />
                                 </Grid>
                                 <Grid item xs={4}>
                                    <div>CV</div>
                                    <Field
                                       id="outlined-basic"
                                       name="CV"
                                       placeholder="CV"
                                       as={TextField}
                                       variant="outlined"
                                       className={classes.textField}
                                    />
                                 </Grid>
                                 <Grid item xs={4}>
                                    <div>{t('ExternalUserID')}</div>
                                    <Field
                                       id="outlined-basic"
                                       name="ExternalUserID"
                                       placeholder="C-USER/S-USER"
                                       as={TextField}
                                       variant="outlined"
                                       className={classes.textField}
                                    />
                                 </Grid>
                                 <Grid item xs={2}>
                                    {t('employeeModalExternalUser')}
                                 </Grid>
                                 <Grid item xs={10}>
                                    <Field name="ExternalUser" color="primary" type="checkbox" />
                                 </Grid>
                                 <Grid item xs={2}>
                                    {t('employeeModalParentalLeave')}
                                 </Grid>
                                 <Grid item xs={10}>
                                    <Field name="ParentalLeave" color="primary" type="checkbox" />
                                 </Grid>
                              </Grid>
                              <div className={classes.buttonsPosition}>
                                 <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                 >
                                    {t('saveButtonTitle')}
                                 </Button>
                                 <Button
                                    color={'secondary'}
                                    size="small"
                                    variant="contained"
                                    onClick={toggleModal}
                                    className={'ml-2'}
                                 >
                                    {t('closeButtonTitle')}
                                 </Button>
                              </div>
                           </Form>
                        )}
                     </Formik>
                  </TabPanel>
                  <TabPanel value={currentTab} index={1} className={classes.tab}>
                     {userInfo.permissions['employeesManagement']['tabSkillsSearch'] && (
                        <Grid container justifyContent="flex-start" alignItems="center">
                           <Grid item xs={11}>
                              <Autocomplete
                                 multiple
                                 id="multiple-limit-tags"
                                 options={initialSkills.filter(
                                    skill =>
                                       !userData.Skills.find(
                                          userSkill => userSkill._id === skill._id
                                       )
                                 )}
                                 value={selectedSkills}
                                 getOptionLabel={option => option.Name}
                                 disableCloseOnSelect={true}
                                 onChange={(e, arrayOfSkills) => setSelectedSkills(arrayOfSkills)}
                                 renderInput={params => (
                                    <TextField
                                       {...params}
                                       variant="outlined"
                                       label={t('employeeModalAutocompleteText')}
                                       placeholder={t('employeeModalAutocompleteText')}
                                    />
                                 )}
                              />
                           </Grid>
                           <Grid item xs={1} className={'p-2'}>
                              <Button
                                 size="large"
                                 variant="contained"
                                 color="primary"
                                 className={
                                    t('locale') === 'en' ? classes.addButtonEN : classes.addButtonBG
                                 }
                                 onClick={addSelectedSkillsToUserSkillData}
                              >
                                 {t('addButtonText')}
                              </Button>
                           </Grid>
                        </Grid>
                     )}

                     <div style={{ height: '370px', overflowY: 'auto' }} className={'m-2'}>
                        {userData.Skills.length ? (
                           skillGroups.map(skillGroup => {
                              return (
                                 <div key={skillGroup} className={'mt-2'}>
                                    <h5 className={'mt-4'}>{skillGroup}</h5>
                                    {userData.Skills.filter(
                                       skill => skill.GroupName === skillGroup
                                    ).map((skill, index) => {
                                       return (
                                          <Grid
                                             container
                                             justifyContent="flex-start"
                                             alignItems="center"
                                             className={classes.skill + ' my-1'}
                                             key={skill._id}
                                          >
                                             <Grid item xs={5}>
                                                {skill.Name}
                                             </Grid>
                                             <Grid item xs={5}>
                                                <div
                                                   style={{ display: 'flex', alignItems: 'center' }}
                                                >
                                                   <Rating
                                                      name={`rating-${skill._id}`}
                                                      value={skill.SkillLevel}
                                                      onChange={(event, newValue) =>
                                                         updateUserDataSkillLevel(
                                                            newValue,
                                                            skill._id
                                                         )
                                                      }
                                                      readOnly={
                                                         !userInfo.permissions[
                                                            'employeesManagement'
                                                         ]['tabSkillsRating']
                                                      }
                                                   />
                                                   {skill.SkillLevel !== null && (
                                                      <Box ml={2}>
                                                         {levels[t('locale')][skill.SkillLevel]}
                                                      </Box>
                                                   )}
                                                </div>
                                             </Grid>
                                             {userInfo.permissions['employeesManagement'][
                                                'tabSkillsDeleteButton'
                                             ] && (
                                                <Grid item xs={2}>
                                                   <DeleteIcon
                                                      onClick={() => {
                                                         handleDeleteIconClick(skill._id)
                                                      }}
                                                      className={classes.icon}
                                                   />
                                                </Grid>
                                             )}
                                          </Grid>
                                       )
                                    })}
                                 </div>
                              )
                           })
                        ) : (
                           <div className={'text-center mt-5'}>
                              <h6>{t('employeeModalNoSkills')}</h6>
                           </div>
                        )}
                     </div>

                     <div className={classes.buttonsPosition}>
                        {userInfo.permissions['employeesManagement']['tabSkillsSaveButton'] && (
                           <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={updateUserByAddingSkills}
                           >
                              {t('saveButtonTitle')}
                           </Button>
                        )}

                        <Button
                           color={'secondary'}
                           size="small"
                           variant="contained"
                           onClick={toggleModal}
                           className={'ml-2'}
                        >
                           {t('closeButtonTitle')}
                        </Button>
                     </div>
                  </TabPanel>
                  <TabPanel value={currentTab} index={2} className={classes.tab}>
                     <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                        <Typography variant="h5" style={{ fontSize: '22px' }}>
                           {t('addCertificate')}
                        </Typography>
                        <Button
                           size="large"
                           variant="contained"
                           color="primary"
                           onClick={handleAddNewEntry}
                        >
                           <FontAwesomeIcon size="lg" icon={faPlus} />
                        </Button>
                     </Grid>
                     <div
                        style={{
                           height: '400px',
                           overflowY: 'auto',
                           overflowX: 'clip',
                           marginTop: 20,
                        }}
                     >
                        <div ref={newEntryRef}>
                           {showAddNewEntryAccordion && (
                              <div
                                 style={{ backgroundColor: '#ddffee', padding: 20, marginTop: 20 }}
                              >
                                 <Typography
                                    style={{
                                       fontSize: '1.1rem',
                                       fontWeight: '500',
                                       marginBottom: 20,
                                    }}
                                 >
                                    {t('newEntry')}
                                 </Typography>
                                 <CertificateRow
                                    formState={newEntryFormState}
                                    setFormState={setNewEntryFormState}
                                    updateUpdateUserData={updateUpdateUserData}
                                    handleDeleteCertificate={handleDeleteCertificate}
                                    newEntryFormState={newEntryFormState}
                                    setNewEntryFormState={setNewEntryFormState}
                                    showAddNewEntryAccordion={showAddNewEntryAccordion}
                                    setShowAddNewEntryAccordion={setShowAddNewEntryAccordion}
                                    setExpanded={setExpanded}
                                    userData={userData}
                                    existingEntryFormState={existingEntryFormState}
                                    classes={classes}
                                    t={t}
                                 />
                              </div>
                           )}
                        </div>
                        <div>
                           {userData.Certificates && userData.Certificates.length ? (
                              userData.Certificates.map(certificate => (
                                 <Accordion
                                    style={{ backgroundColor: '#ddffee', marginTop: 20 }} //
                                    expanded={expanded === certificate.Url}
                                    onChange={(e, expanded) => {
                                       handleChange(certificate.Url, expanded)
                                       if (expanded) onEditExistingCertificate(certificate)
                                    }}
                                    key={certificate._id ? certificate._id : uuidv4()}
                                 >
                                    <AccordionSummary
                                       ref={refs[certificate.Url]}
                                       expandIcon={<ExpandMoreIcon />}
                                    >
                                       <Typography
                                          style={{ fontSize: '1.1rem', fontWeight: '500' }}
                                       >
                                          {certificate.Title}
                                       </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                       {certificate._id === selectedCertificateIdToEdit && (
                                          <CertificateRow
                                             certificate={certificate}
                                             formState={existingEntryFormState}
                                             setFormState={setExistingEntryFormState}
                                             updateUpdateUserData={updateUpdateUserData}
                                             handleDeleteCertificate={handleDeleteCertificate}
                                             newEntryFormState={newEntryFormState}
                                             setNewEntryFormState={setNewEntryFormState}
                                             showAddNewEntryAccordion={showAddNewEntryAccordion}
                                             setShowAddNewEntryAccordion={
                                                setShowAddNewEntryAccordion
                                             }
                                             setExpanded={setExpanded}
                                             userData={userData}
                                             existingEntryFormState={existingEntryFormState}
                                             classes={classes}
                                             t={t}
                                          />
                                       )}
                                    </AccordionDetails>
                                 </Accordion>
                              ))
                           ) : (
                              <Typography>{t('missingCertificates')}</Typography>
                           )}
                        </div>

                        <div className={classes.buttonsPosition}>
                           <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={updateUserByAddingCertificates}
                           >
                              {t('saveButtonTitle')}
                           </Button>

                           <Button
                              color={'secondary'}
                              size="small"
                              variant="contained"
                              onClick={toggleModal}
                              className={'ml-2'}
                           >
                              {t('closeButtonTitle')}
                           </Button>
                        </div>
                     </div>
                  </TabPanel>
                  <TabPanel value={currentTab} index={3} className={classes.tab}>
                     <Formik
                        initialValues={{
                           Admin: userData.Admin,
                           Manager: userData.Manager,
                           BackofficeAdmin: userData.BackofficeAdmin,
                           LoginAllowed: userData.LoginAllowed,
                           ShowInSalaryReport: userData.ShowInSalaryReport,
                        }}
                        onSubmit={values => {
                           const data = {
                              EmployeeID: userData.EmployeeID,
                              Admin: values.Admin,
                              Manager: values.Manager,
                              BackofficeAdmin: values.BackofficeAdmin,
                              LoginAllowed: values.LoginAllowed,
                              ShowInSalaryReport: values.ShowInSalaryReport,
                           }
                           createUpdateUserBasicInfo(data)
                        }}
                     >
                        <Form className={'mt-4 ml-2'}>
                           <Grid
                              container
                              justifyContent="flex-start"
                              alignItems="center"
                              spacing={1}
                           >
                              <Grid item xs={2}>
                                 <h6>{t('employeeModalAdmin')}</h6>
                              </Grid>
                              <Grid item xs={10}>
                                 <Field
                                    name="Admin"
                                    color="primary"
                                    type="checkbox"
                                    disabled={
                                       !userInfo.permissions['employeesManagement'][
                                          'tabPermissionsAdmin'
                                       ]
                                    }
                                 />
                              </Grid>
                              <Grid item xs={2}>
                                 <h6>{t('employeeModalManager')}</h6>
                              </Grid>
                              <Grid item xs={10}>
                                 <Field
                                    name="Manager"
                                    color="primary"
                                    type="checkbox"
                                    disabled={
                                       !userInfo.permissions['employeesManagement'][
                                          'tabPermissionsManager'
                                       ]
                                    }
                                 />
                              </Grid>
                              <Grid item xs={2}>
                                 <h6>{t('employeeModalBackofficeAdmin')}</h6>
                              </Grid>
                              <Grid item xs={10}>
                                 <Field
                                    name="BackofficeAdmin"
                                    color="primary"
                                    type="checkbox"
                                    disabled={
                                       !userInfo.permissions['employeesManagement'][
                                          'tabPermissionsBackofficeAdmin'
                                       ]
                                    }
                                 />
                              </Grid>
                              <Grid item xs={2}>
                                 <h6>{t('employeeModalLoginAllowed')}</h6>
                              </Grid>
                              <Grid item xs={10}>
                                 <Field
                                    name="LoginAllowed"
                                    color="primary"
                                    type="checkbox"
                                    disabled={
                                       !userInfo.permissions['employeesManagement'][
                                          'tabPermissionsLoginAllowed'
                                       ]
                                    }
                                 />
                              </Grid>
                              <Grid item xs={2}>
                                 <h6>{t('employeeModalShowInSalaryReport')}</h6>
                              </Grid>
                              <Grid item xs={10}>
                                 <Field
                                    name="ShowInSalaryReport"
                                    color="primary"
                                    type="checkbox"
                                    disabled={
                                       !userInfo.permissions['employeesManagement'][
                                          'tabPermissionsShowInSalaryReport'
                                       ]
                                    }
                                 />
                              </Grid>
                           </Grid>
                           <div className={classes.buttonsPosition}>
                              {userInfo.permissions['employeesManagement'][
                                 'tabPermissionsSaveButton'
                              ] && (
                                 <Button
                                    type="submit"
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                 >
                                    {t('saveButtonTitle')}
                                 </Button>
                              )}

                              <Button
                                 color={'secondary'}
                                 size="small"
                                 variant="contained"
                                 onClick={toggleModal}
                                 className={'ml-2'}
                              >
                                 {t('closeButtonTitle')}
                              </Button>
                           </div>
                        </Form>
                     </Formik>
                  </TabPanel>
               </div>
            </div>
         </Modal>
         <Snackbar open={snackbarState.open} autoHideDuration={3000} onClose={toggleSnackBar}>
            <Alert onClose={toggleSnackBar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>
         <Modal
            open={openDeleteModal}
            onClose={toggleDeleteModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
         >
            <div style={modalStyle} className={classes.deleteModalPaper}>
               <div className={classes.deleteModalBody}>
                  <h6 id="simple-modal-title">
                     {skillIDToDelete ? t('deleteSkillModalText') : t('deleteCertificateModalText')}
                  </h6>
               </div>
               <div className={classes.deleteModalButtonsPosition}>
                  <Button
                     onClick={
                        skillIDToDelete ? deleteSkillFromUserData : removeCertificateFromUserData
                     }
                     size="small"
                     variant="contained"
                     color="primary"
                  >
                     {t('deleteButtonTitle')}
                  </Button>
                  <Button onClick={toggleDeleteModal}>{t('cancelButtonTitle')}</Button>
               </div>
            </div>
         </Modal>
      </>
   )
}

export default withTranslation()(CreateEditEmployeeModal)
