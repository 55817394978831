export const AUTHENTICATE = 'AUTHENTICATE';
export const LOGOUT = 'LOGOUT';
export const ERROR = 'ERROR';
export const TIMEZONE = 'TIMEZONE';


/**
 * For redux-saga
 */
export const FETCH_NOTIFS = "FETCH_NOTIFS";
export const FETCH_NOTIFS_SUCCEEDED = 'FETCH_NOTIFS_SUCCEEDED';
export const ABSENCE_CHANGE = "ABSENCE_CHANGE";
export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_SUCCEEDED = 'FETCH_USERS_SUCCEEDED';
export const FETCH_ACTIVE_USERS = "FETCH_ACTIVE_USERS";
export const FETCH_ACTIVE_USERS_SUCCEEDED = 'FETCH_ACTIVE_USERS_SUCCEEDED';
export const FETCH_FUTURE_REQUESTS = "FETCH_FUTURE_REQUEST";
export const FETCH_FUTURE_REQUESTS_SUCCEEDED = 'FETCH_FUTURE_REQUESTS_SUCCEEDED';
export const FETCH_SALARY_REPORTS = "FETCH_SALARY_REPORTS";
export const FETCH_SALARY_REPORTS_SUCCEEDED = "FETCH_SALARY_REPORTS_SUCCEEDED";
export const PERSIST_USERINFO = "PERSIST_USERINFO";
export const FETCH_CUSTOMER_DATA = "FETCH_CUSTOMER_DATA";
export const FETCH_CUSTOMER_DATA_SUCCEEDED = "FETCH_CUSTOMER_DATA_SUCCEEDED";