import React, { Fragment } from 'react'
import { filterProjectsByStartAndEndDate } from '../../../../../util/helpers'
import { Typography, Tooltip, Divider, IconButton } from '@mui/material'
import AssessmentIcon from '@mui/icons-material/Assessment'
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople'
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from 'react-i18next'
import { getAndWrapUser } from '../../../../../util/wrappers'
import { getNickOrLabel } from '../../../../../util/userProps'

const useStyles = makeStyles({
   noOutline: {
      outline: 'none !important',
   },
   projectsIcon: {
      color: '#0083bb',
   },
   noProject: {
      color: '#489D74',
   },
   tooltip: {
      backgroundColor: '#616161',
   },
})

const ProjectsIcon = ({ userId, t, wrappedUserData, projects }) => {
   const classes = useStyles()
   const userProjects = filterProjectsByStartAndEndDate(projects, userId)

   return (
      <Tooltip
         classes={{ tooltip: classes.tooltip }}
         arrow
         title={
            userProjects.length ? (
               <>
                  <ul style={{ margin: '8px 0px', paddingLeft: 0 }}>
                     {userProjects.map(project => (
                        <Fragment key={project._id}>
                           <li style={{ margin: '8px 0px' }}>
                              <AssessmentIcon fontSize="small" />
                              <Typography
                                 variant="overline"
                                 style={{ fontSize: 10, fontWeight: 'bold', margin: '0px 4px' }}
                              >
                                 {project.Name}
                              </Typography>
                              <div
                                 style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                 }}
                              >
                                 <Typography
                                    variant="caption"
                                    style={{ fontSize: 8, margin: '0px 4px' }}
                                 >
                                    {t('PM')}:
                                 </Typography>
                                 <Typography variant="h6" style={{ fontSize: 10 }}>
                                    {project.ProjectManager
                                       ? getNickOrLabel(
                                            getAndWrapUser(wrappedUserData, project.ProjectManager)
                                         )
                                       : t('notAssignedYet')}
                                 </Typography>
                              </div>
                           </li>
                           {project._id !== userProjects[userProjects.length - 1]._id ? (
                              <Divider style={{ background: '#E0E0E0' }} />
                           ) : null}
                        </Fragment>
                     ))}
                  </ul>
               </>
            ) : (
               <div
                  style={{
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'flex-start',
                     paddingBottom: '8px',
                  }}
               >
                  <div>
                     <AssessmentIcon fontSize="small" />
                     <Typography
                        variant="overline"
                        style={{ fontSize: 10, fontWeight: 'bold', margin: '0px 4px' }}
                     >
                        {t('unassignedToProject')}
                     </Typography>
                  </div>
                  <Typography variant="caption" style={{ fontSize: 10 }}>
                     {t('availableForNewProjects')}
                  </Typography>
               </div>
            )
         }
      >
         <IconButton className={classes.noOutline} size="large">
            {userProjects.length ? (
               <AssessmentIcon className={classes.projectsIcon} />
            ) : (
               <EmojiPeopleIcon className={classes.noProject} />
            )}
         </IconButton>
      </Tooltip>
   );
}

export default withTranslation()(ProjectsIcon)
