import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class ConfirmDialog extends Component {
   render() {
      return (
         <Dialog
            open={this.props.showConfirm}
            onClose={() => this.props.toggleConfirm(false, null, null, null, null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
         >
            <DialogTitle id="alert-dialog-title">{this.props.confirmTitle}</DialogTitle>
            <DialogContent>
               <DialogContentText id="alert-dialog-description">
                  {this.props.message}
               </DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                     this.props.handleConfirm()
                     this.props.toggleConfirm(false, null, null, null, null)
                  }}
                  autoFocus
               >
                  {this.props.confirmButtonTitle}
               </Button>
               <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => this.props.toggleConfirm(false, null, null, null, null, null)}
               >
                  {this.props.t('cancelButtonTitle')}
               </Button>
            </DialogActions>
         </Dialog>
      )
   }
}

export default withTranslation()(ConfirmDialog)
