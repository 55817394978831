import React from 'react'
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'
import DividerWithText from '../../../../../components/DividerWithText'
import clsx from 'clsx'
import { Tooltip, Typography, IconButton, Box, TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ProjectsIcon from './ProjectsIcon'
import TeamLeadIcon from './TeamLeadIcon'
import { withTranslation } from 'react-i18next'
import { getNickOrLabel } from '../../../../../util/userProps'

const useStyles = makeStyles({
   noOutline: {
      outline: 'none !important',
   },
   tableCellItems: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   tooltip: {
      backgroundColor: '#616161',
   },
   childIcon: {
      color: '#FF8038',
   },
   noBorder: {
      border: 'none',
      boxShadow: 'none',
      zIndex: 0,
   },
   crissHover: {
      '&:hover': {
         color: '#',
         backgroundColor: '#FFeedd',
      },
   },
   crossHover: {
      backgroundColor: '#f6f6fd',
      '&:hover': {
         color: '#',
         backgroundColor: '#FFeedd',
      },
   },
   weekIndicator: {
      backgroundColor: '#f1f1fb',
      color: '#bbaaee',
      padding: 2,
      maxWidth: 16,
   },
})

const Rows = ({ t, rowsProps }) => {
   const {
      teams,
      projects,
      weekInfo,
      filteredUsers,
      wrappedUserData,
      stripeRowCheck,
      getWeekDayAbsence,
   } = rowsProps

   const classes = useStyles()

   return <>
      <TableRow>
         <TableCell className={clsx(classes.noBorder)} style={{ padding: '16px 8px 8px' }}>
            <Box
               display="flex"
               alignItems="center"
               justifyContent="center"
               flexDirection="column"
            >
               <DividerWithText>
                  <Typography variant="h6">{t('defaultTeam')}</Typography>
               </DividerWithText>
            </Box>
         </TableCell>
      </TableRow>
      {filteredUsers.map((person, index) => (
         <TableRow
            key={person.id}
            className={clsx(stripeRowCheck(index) ? classes.crossHover : classes.crissHover)}
         >
            <TableCell
               className={(clsx(classes.noBorder), classes.tableCellItems)}
               style={{ minWidth: 200 }}
            >
               {getNickOrLabel(person)}
               <div className={classes.tableCellItems}>
                  {person.parentalLeave ? (
                     <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        arrow
                        title={
                           <Typography variant="caption" style={{ margin: '16px 8px' }}>
                              {t('inParentalLeave')}
                           </Typography>
                        }
                     >
                        <IconButton className={classes.noOutline} size="large">
                           <ChildFriendlyIcon className={classes.childIcon} />
                        </IconButton>
                     </Tooltip>
                  ) : (
                     <ProjectsIcon
                        userId={person._id}
                        projects={projects}
                        wrappedUserData={wrappedUserData}
                     />
                  )}
                  <TeamLeadIcon
                     userId={person._id}
                     teams={teams}
                     wrappedUserData={wrappedUserData}
                  />
               </div>
            </TableCell>

            {weekInfo.weeksSorted.map(week => getWeekDayAbsence(week, person, weekInfo))}

            <TableCell
               align={'center'}
               className={clsx(classes.weekIndicator, classes.noBorder)}
               style={{ minWidth: 20 }}
            />
         </TableRow>
      ))}
   </>;
}

export default withTranslation()(Rows)
