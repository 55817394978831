import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import React, { useEffect, useState } from 'react'
import 'react-dropdown/style.css'
import { connect } from 'react-redux'
import { fetchActiveUsers, fetchUsers } from '../../redux/actions/actions'
import themeConfig from '../../theme'
import { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-GB'
import bg from 'date-fns/locale/bg'
import { withTranslation } from 'react-i18next'
import { Grid, Snackbar } from '@mui/material'
import { fetchCustomerForAuthUser, fetchInactiveUsers, getProjectsForCustomer } from '../../Api'
import Alert from '@mui/material/Alert'
import 'react-datepicker/dist/react-datepicker.css'
import ProjectManagementHeader from './components/ProjectManagementHeader'
import ProjectManagementProjectView from './components/ProjectManagementProjectView'

registerLocale('sv', sv)
registerLocale('en-GB', en)
registerLocale('bg', bg)

const { styles } = themeConfig()

const useStyles = makeStyles(theme => ({
   mainPaper: {
      width: '100%',
      padding: '10px',
      boxShadow: theme.shadows[2],
      borderRadius: '8px',
      marginTop: '-25px',
      boxShadow: 'none',
   },
}))

const ProjectManagement = ({ ...props }) => {
   const { userInfo, fetchUsers, fetchActiveUsers } = props
   const [projects, setProjects] = useState([])
   const [selectedProject, setSelectedProject] = useState({})
   const [customer, setCustomer] = useState({})
   const [openManageMembersModal, setOpenManageMembersModal] = useState(false)

   const [snackbarState, setSnackbarState] = useState({
      open: false,
      message: '',
      severity: 'info',
   })
   const [haveUnsavedChanges, setHaveUnsavedChanges] = useState(false)
   const [inactiveUsers, setInactiveUsers] = useState(null)

   const classes = useStyles()

   useEffect(() => {
      fetchInactiveUsers(userInfo)
         .then(response => setInactiveUsers(response.data))
         .catch(err => console.log(err))
   }, [])

   useEffect(() => {
      const loadData = async () => {
         const result = await fetchCustomerForAuthUser(userInfo)
         if (result.success) {
            setCustomer(result.customer)
            fetchProjects(result.customer)
         }
         fetchActiveUsers(userInfo)
         await fetchUsers(userInfo)
      }
      loadData()
   }, [])

   const fetchProjects = async (cust = customer, createdProjectId = null) => {
      const { data } = await getProjectsForCustomer(cust._id, userInfo)
      setProjects(data)
      if (createdProjectId !== null) {
         setSelectedProject(data.find(project => project._id === createdProjectId))
      }
   }

   const showSuccessfulSnackbar = message => {
      setSnackbarState({ open: true, message, severity: 'success' })
   }

   const showErrorSnackBar = message => {
      setSnackbarState({ open: true, message, severity: 'error' })
   }

   const closeSnackBar = () => {
      setSnackbarState({ open: false, message: '', severity: 'info' })
   }

   const toggleManageMembersModal = () => {
      setOpenManageMembersModal(prevState => !prevState)
   }

   return (
      <React.Fragment>
         <Paper className={classes.mainPaper}>
            <Grid
               container
               direction="row"
               alignItems="flex-start"
               spacing={1}
               style={{ padding: 10, height: '90%' }}
            >
               <ProjectManagementHeader
                  projects={projects}
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  haveUnsavedChanges={haveUnsavedChanges}
                  setHaveUnsavedChanges={setHaveUnsavedChanges}
                  customer={customer}
                  fetchProjects={fetchProjects}
                  showErrorSnackBar={showErrorSnackBar}
                  showSuccessfulSnackbar={showSuccessfulSnackbar}
                  toggleManageMembersModal={toggleManageMembersModal}
                  userInfo={userInfo}
               />

               {Object.keys(selectedProject).length > 0 && (
                  <ProjectManagementProjectView
                     selectedProject={selectedProject}
                     setSelectedProject={setSelectedProject}
                     haveUnsavedChanges={haveUnsavedChanges}
                     setHaveUnsavedChanges={setHaveUnsavedChanges}
                     fetchProjects={fetchProjects}
                     showSuccessfulSnackbar={showSuccessfulSnackbar}
                     showErrorSnackBar={showErrorSnackBar}
                     openManageMembersModal={openManageMembersModal}
                     customer={customer}
                     toggleManageMembersModal={toggleManageMembersModal}
                     inactiveUsers={inactiveUsers}
                  />
               )}
            </Grid>
         </Paper>
         <Snackbar open={snackbarState.open} autoHideDuration={3000} onClose={closeSnackBar}>
            <Alert onClose={closeSnackBar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}

export default withStyles(styles)(
   withTranslation()(connect(mapStateToProps, { fetchUsers, fetchActiveUsers })(ProjectManagement))
)
