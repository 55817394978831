import { Box, Typography, Button } from '@mui/material'
import { InfoOutlined, ArrowForward } from '@mui/icons-material'
import Calendar from '../ui/Calendar'
import SkillBox from '../ui/SkillBox'
import ProjectPlanningSteps from './ProjectPlanningSteps'
import { withTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

const ProjectPlanningBody = ({ skillGroups, skills }) => {
   const history = useHistory()
   const redirect = (link, state) => {
      history.push(link, state)
   }

   const [selectedPeriod, setSelectedPeriod] = useState({
      startDate: undefined,
      endDate: undefined,
   })

   const [step, setStep] = useState(1)
   const [selectedSkillGroups, setSelectedSkillGroups] = useState([])
   const [selectedSkills, setSelectedSkills] = useState([])

   const [filteredSkillsGroupedMap, setFilteredSkillsGroupedMap] = useState(new Map())

   useEffect(() => {
      const newFilteredSkillsGroupedMap = new Map()

      const filteredSkills = skills.filter(skill => selectedSkillGroups.includes(skill.GroupID))
      filteredSkills.forEach(skill => {
         const existingSkillGroupSkills = newFilteredSkillsGroupedMap.get(skill.GroupName)
         if (existingSkillGroupSkills) {
            newFilteredSkillsGroupedMap.set(skill.GroupName, [skill, ...existingSkillGroupSkills])
         } else {
            newFilteredSkillsGroupedMap.set(skill.GroupName, [skill])
         }
      })

      setFilteredSkillsGroupedMap(newFilteredSkillsGroupedMap)
   }, [skills, selectedSkillGroups])

   const handleStartDate = newDate => {
      setSelectedPeriod(prev => ({
         ...prev,
         startDate: moment(newDate),
      }))
   }

   const handleEndDate = newDate => {
      setSelectedPeriod(prev => ({
         ...prev,
         endDate: moment(newDate),
      }))
   }

   const nextStep = () => {
      const newStep = step + 1
      setStep(newStep)

      if (newStep == 5) {
         const startDateIso = selectedPeriod.startDate.toISOString()
         const endDateIso = selectedPeriod.endDate
            ? selectedPeriod.endDate.toISOString()
            : selectedPeriod.startDate.add(2, 'years').toISOString()
         redirect(`/reportsAndPlaning/resourcePlanning/availableEmployees`, {
            startDate: startDateIso,
            endDate: endDateIso,
            skillGroups: selectedSkillGroups,
            skills: selectedSkills,
         })
      }
   }

   const renderSkillGroups = skillGroups => {
      const addOrRemoveSelectedSkillGroup = skillGroupId => {
         if (selectedSkillGroups.includes(skillGroupId)) {
            setSelectedSkillGroups(prevState => prevState.filter(s => s != skillGroupId))
         } else {
            setSelectedSkillGroups(prevState => [skillGroupId, ...prevState])
         }
      }

      return (
         <Box
            sx={{
               maxHeight: '100%',
               display: 'flex',
               gap: '25px',
               flexWrap: 'wrap',
               overflowY: 'scroll',
               '&::-webkit-scrollbar': {
                  display: 'none',
               },
            }}
         >
            {skillGroups.map(skillGroup => (
               <SkillBox
                  selectedSkills={selectedSkillGroups}
                  skill={skillGroup}
                  onClick={() => addOrRemoveSelectedSkillGroup(skillGroup._id)}
               >
                  {skillGroup.Name}
               </SkillBox>
            ))}
         </Box>
      )
   }

   const renderSkills = skillsGroupedMap => {
      const addOrRemoveSelectedSkill = skillId => {
         if (selectedSkills.includes(skillId)) {
            setSelectedSkills(prevState => prevState.filter(s => s != skillId))
         } else {
            setSelectedSkills(prevState => [skillId, ...prevState])
         }
      }

      return (
         <Box
            sx={{
               width: '100%',
               height: '100%',
               overflowY: 'scroll',
               '&::-webkit-scrollbar': {
                  display: 'none',
               },
            }}
         >
            {Array.from(skillsGroupedMap).map(([skillGroup, skills]) => (
               <>
                  <Box
                     sx={{
                        fontSize: '14px',
                        color: '#B7B7B7',
                        fontWeight: '600',
                        borderBottom: '1px solid #B7B7B7',
                        marginY: '10px',
                        paddingBottom: '5px',
                     }}
                  >
                     {skillGroup}
                  </Box>
                  <Box
                     sx={{
                        display: 'flex',
                        gap: '25px',
                        flexWrap: 'wrap',
                     }}
                  >
                     {skills.map(skill => (
                        <SkillBox
                           key={skill._id}
                           selectedSkills={selectedSkills}
                           skill={skill}
                           onClick={() => addOrRemoveSelectedSkill(skill._id)}
                        >
                           {skill.Name}
                        </SkillBox>
                     ))}
                  </Box>
               </>
            ))}
         </Box>
      )
   }

   return (
      <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '50px' }}>
         <Box
            sx={{
               width: '800px',
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'space-between',
               alignItems: 'center',
            }}
         >
            <ProjectPlanningSteps
               step={step}
               selectedPeriod={selectedPeriod}
               selectedSkillGroups={selectedSkillGroups}
               selectedSkills={selectedSkills}
               skillGroups={skillGroups}
               skills={skills}
            />
            <Box
               sx={{
                  width: '100%',
                  height: '400px',
                  display: 'flex',
                  alignItems: 'center',
               }}
            >
               {step == 1 && <Calendar onChange={handleStartDate} />}
               {step == 2 && (
                  <Calendar onChange={handleEndDate} minDate={selectedPeriod.startDate} />
               )}
               {step == 3 && renderSkillGroups(skillGroups)}
               {step == 4 && renderSkills(filteredSkillsGroupedMap)}
            </Box>
            <Box
               sx={{
                  width: '100%',
                  height: '105px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
               }}
            >
               {step == 1 && !selectedPeriod.startDate && (
                  <Typography
                     sx={{
                        color: '#0083BB',
                        fontSize: '18px',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'center',
                     }}
                  >
                     <InfoOutlined />
                     You need to select at least start date in order to continue
                  </Typography>
               )}

               {step == 2 && !selectedPeriod.endDate && (
                  <Typography
                     sx={{
                        color: '#0083BB',
                        fontSize: '18px',
                        fontWeight: '500',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '15px',
                     }}
                  >
                     <InfoOutlined />
                     End date is optional
                  </Typography>
               )}

               {selectedPeriod.startDate && (
                  <Button
                     variant="contained"
                     onClick={nextStep}
                     disabled={step == 3 && selectedSkillGroups.length == 0 ? true : false}
                     sx={{
                        height: '60px',
                        backgroundColor: '#0083BB',
                        fontSize: '20px',
                        fontWeight: '500',
                     }}
                  >
                     {step != 4 && 'Go to next step'}
                     {step == 4 &&
                        (selectedSkills.length == 0 ? 'Skip and show results' : 'Show results')}
                     <ArrowForward sx={{ marginLeft: '10px' }} />
                  </Button>
               )}
            </Box>
         </Box>
      </Box>
   )
}
export default withTranslation()(ProjectPlanningBody)
