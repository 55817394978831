import { withTranslation } from 'react-i18next'
import { Box } from '@mui/material'

const SkillBox = ({ selectedSkills, skill, children, onClick }) => {
   return (
      <Box
         sx={{
            width: '250px',
            height: '59px',
            border: '2px solid #0083BB',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: '600',
            cursor: 'pointer',

            '&:hover': {
               backgroundColor: '#52B3DD',
               color: '#FFFFFF',
            },

            backgroundColor: selectedSkills.includes(skill._id) ? '#0083BB' : 'transparent',
            color: selectedSkills.includes(skill._id) ? '#FFFFFF' : '#454545',
         }}
         onClick={onClick}
      >
         {children}
      </Box>
   )
}
export default withTranslation()(SkillBox)
