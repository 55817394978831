import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import {
   fetchBankHolidays,
   fetchAllUsers,
   fetchCustomerForAuthUser,
   fetchAbsenceByRequestId,
} from '../Api'
import {
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from '@mui/material'
import { useEffectOnlyOnce } from '../util/helpers'
import { calculateAbsenceBetweenDates } from 'novarto-time'
import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'
import log from '../util/log'
import { fetchCustomerData } from '../redux/actions/actions'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { withTranslation } from 'react-i18next'

const styles = theme => ({
   border: {
      borderBottom: '1px solid',
      borderStyle: 'solid',
      borderWidth: '1px',
      height: '90px',
   },
   openBottom: {
      borderBottom: '0px',
      borderStyle: 'solid',
      borderWidth: '1px',
      height: '90px',
   },
   text: {
      fontSize: '1.5rem',
   },
})

const PrintRequestHTML = ({ ...props }) => {
   const { t, i18n, userInfo, loggedIn, workDay } = props
   const [info, setInfo] = React.useState({
      request: null,
      user: null,
   })
   let { id, lng, imgindex } = useParams()

   const [totalWorkDays, setTotalWorkDays] = React.useState({})
   const [start, setStart] = React.useState(null)
   const [end, setEnd] = React.useState(null)
   const [requestedDate, setRequestedDate] = React.useState(null)
   const [negative, setNegative] = React.useState(false)
   const imgs = [
      <img
         style={{ width: 250, paddingBottom: 10, paddingLeft: 5 }}
         src="/novarto-BlueLetters.png"
         alt="novarto logo"
      ></img>,
      <img style={{ width: 250, paddingLeft: 5 }} src="/hills/hills4.jpg" alt="hills logo"></img>,
   ]

   useEffectOnlyOnce(() => {
      async function loadAbsenceRequest() {
         const { customer } = await fetchCustomerForAuthUser(userInfo)
         moment.tz.setDefault(customer.TimeZone)

         const json = await fetchAbsenceByRequestId(userInfo, id)
         const res = json.request

         const users = await fetchAllUsers({ userInfo: userInfo })
         const user = users.data.find(user => user.EmployeeID === res.EmployeeID)

         await fetchBankHolidays(userInfo).then(response => {
            if (response.success === 'false') {
               log.error('Failure to load data; try logging out and in again: ')
            } else {
               setNegative(res.EndDate && res.StartDate > res.EndDate)
               setTotalWorkDays(
                  calculateAbsenceBetweenDates(res.StartDate, res.EndDate, response, workDay)
               )
            }
         })

         await props.fetchCustomerData(userInfo)

         setStart(new Date(res.StartDate))
         setEnd(new Date(res.EndDate))
         setRequestedDate(new Date(res.CreatedAt))
         setInfo({ request: res, user: user })
      }

      loadAbsenceRequest()

      // Set language from parameter.
      lng = 'bg'
      i18n.changeLanguage(lng)
   })

   const isHomeOfficeRequest = info?.request?.AbsenceType === 'DISTANS'

   const formatDate = date => {
      let day = `${date.getDate()}`
      let month = `${date.getMonth() + 1}`
      const year = `${date.getFullYear()}`

      if (day.length < 2) day = '0' + day
      if (month.length < 2) month = '0' + month

      return `${day}.${month}.${year}`
   }

   if (!loggedIn) {
      return <Redirect to="/login" />
   }
   if (!info.request || !info.user) {
      return 'Fetching data...'
   }

   return (
      <React.Fragment>
         <div id="wholething" style={{ padding: 20, backgroundColor: 'white' }}>
            {imgindex ? imgs[imgindex] : ''}
            <TableContainer>
               <Table responsive="true">
                  <TableHead>
                     <TableRow>
                        <TableCell
                           className={clsx(props.classes.border, props.classes.text)}
                           align={'center'}
                           colSpan={2}
                        >
                           {
                              <div>
                                 <Typography className={clsx(props.classes.text)}>
                                    <b>
                                       МОЛБА ЗА{' '}
                                       {isHomeOfficeRequest ? 'ДИСТАНЦИОННА РАБОТА' : 'ОТПУСКА'}
                                    </b>
                                 </Typography>
                                 <Typography className={clsx(props.classes.text)}>
                                    <b>
                                       {!isHomeOfficeRequest
                                          ? info.request.RequestNumber
                                          : info.request.RemoteRequestNumber}
                                    </b>
                                 </Typography>
                              </div>
                           }
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     <TableRow>
                        <TableCell
                           className={clsx(props.classes.border, props.classes.text)}
                           style={{ width: '20%' }}
                        >
                           От /трите имена/
                        </TableCell>
                        <TableCell
                           className={clsx(props.classes.border, props.classes.text)}
                           align={'center'}
                        >
                           <Typography className={clsx(props.classes.text)}>
                              <b>{`${info.user.FirstName} ${
                                 info.user.MiddleName ? `${info.user.MiddleName} ` : ''
                              }${info.user.LastName}`}</b>
                           </Typography>
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={clsx(props.classes.openBottom, props.classes.text)}>
                           До
                        </TableCell>
                        <TableCell
                           className={clsx(props.classes.openBottom, props.classes.text)}
                           align={'center'}
                        >
                           <Typography className={clsx(props.classes.text)}>
                              <b>Борислав Минков</b>
                           </Typography>
                        </TableCell>
                     </TableRow>
                  </TableBody>
               </Table>
               <Table className={clsx(props.classes.border)} responsive="true">
                  <TableHead>
                     <TableRow>
                        <TableCell
                           className={clsx(props.classes.border, props.classes.text)}
                           align={'center'}
                           colSpan={2}
                        >
                           <Typography className={clsx(props.classes.text)}>
                              <b>
                                 Моля да ми бъде разрешена{' '}
                                 {isHomeOfficeRequest ? 'дистанционна работа' : 'отпуска'}
                              </b>
                           </Typography>
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     <TableRow>
                        <TableCell
                           className={clsx(props.classes.border, props.classes.text)}
                           style={{ width: '40%' }}
                        >
                           От дата
                        </TableCell>
                        <TableCell
                           className={clsx(props.classes.border, props.classes.text)}
                           align={'center'}
                        >
                           {formatDate(start)}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={clsx(props.classes.border, props.classes.text)}>
                           До дата
                        </TableCell>
                        <TableCell
                           className={clsx(props.classes.border, props.classes.text)}
                           align={'center'}
                        >
                           {formatDate(end)}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={clsx(props.classes.border, props.classes.text)}>
                           Основание{!isHomeOfficeRequest && ' за отпуска'}
                        </TableCell>
                        <TableCell
                           className={clsx(props.classes.border, props.classes.text)}
                           align={'center'}
                        >
                           {t(info.request.AbsenceType)}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={clsx(props.classes.border, props.classes.text)}>
                           Общо работни дни
                        </TableCell>
                        <TableCell
                           className={clsx(props.classes.border, props.classes.text)}
                           align={'center'}
                        >
                           {negative ? '-' : ''}
                           {totalWorkDays.days}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={clsx(props.classes.border, props.classes.text)}>
                           Дата
                        </TableCell>
                        <TableCell
                           className={clsx(props.classes.border, props.classes.text)}
                           align={'center'}
                        >
                           {formatDate(requestedDate)}
                        </TableCell>
                     </TableRow>
                     <TableRow>
                        <TableCell className={clsx(props.classes.border, props.classes.text)}>
                           Подпис на служителя
                        </TableCell>
                        <TableCell className={clsx(props.classes.border, props.classes.text)} />
                     </TableRow>
                     <TableRow>
                        <TableCell className={clsx(props.classes.openBottom, props.classes.text)}>
                           Одобрено от
                        </TableCell>
                        <TableCell className={clsx(props.classes.border, props.classes.text)} />
                     </TableRow>
                  </TableBody>
               </Table>
            </TableContainer>
         </div>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      timezone: state.timezone,
      workDay: state.workDay,
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
   }
}

const MappedObj = connect(mapStateToProps, { fetchCustomerData })(PrintRequestHTML)

export default withStyles(styles)(withTranslation()(MappedObj))
