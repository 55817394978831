import { Box, InputLabel, Typography, FormControl, MenuItem, Select, Skeleton } from '@mui/material'
import { withTranslation } from 'react-i18next'
import { PieChart } from 'react-minimal-pie-chart'
import ColorSquare from './UI/ColorSquare'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import React, { useState, useEffect, useRef } from 'react'
import Chart from 'react-apexcharts'
import {
   formatNumber,
   getMonthsBetween,
   getQuartersBetween,
   getYearsBetween,
   totalHours,
} from '../../../../../util/helpers'
import moment from 'moment-timezone'
import '../../../../../css/projectPlanning.css'

const ProjectOverviewCharts = ({ project, selectedPeriod, isFetching, t }) => {
   const [pieChartFilter, setPieChartFilter] = useState([])
   const [totalValue, setTotalValue] = useState(0)
   const [data, setData] = useState([])
   const [barChartValueType, setBarChartValueType] = useState('months')
   const [dataHours, setDataHours] = useState({ billable: [], nonBillable: [] })
   const selectType = useRef('employee')

   let state = {
      series:
         project.Type === 'External'
            ? [
                 {
                    name: 'Non-billable',
                    data: dataHours.nonBillable,
                 },
                 {
                    name: 'Billable',
                    data: dataHours.billable,
                 },
              ]
            : [
                 {
                    name: 'Non-billable',
                    data: dataHours.nonBillable,
                 },
              ],
      options: {
         legend: {
            position: 'top',
            horizontalAlign: 'left',
         },
         colors: ['#2D3A73', '#00B8B8'],
         chart: {
            type: 'bar',
            toolbar: {
               show: true,
            },
         },
         plotOptions: {
            bar: {
               borderRadius: 5,
               horizontal: false,
               columnWidth: '90%',
               endingShape: 'rounded',

               dataLabels: {
                  position: 'top', // top, center, bottom
               },
            },
         },
         dataLabels: {
            enabled: true,
            formatter: function (val) {
               return val > 0 ? val : '|'
            },
            offsetY: -20,
            style: {
               fontSize: '12px',
               colors: ['#939393'],
            },
         },

         xaxis: {
            categories: data,
            position: 'bottom',
            tickPlacement: 'on',
            axisBorder: {
               show: true,
            },
            axisTicks: {
               show: true,
            },

            tooltip: {
               enabled: true,
            },
         },
         stroke: {
            show: true,
            width: 5,
            colors: ['transparent'],
         },
         yaxis: {
            axisBorder: {
               show: false,
            },
            axisTicks: {
               show: false,
            },
            labels: {
               show: false,
               formatter: function (val) {
                  return val
               },
            },
         },
      },
   }

   const colorsChart = [
      '',
      '#C49CD3',
      '#435058',
      '#77A6B6',
      '#AFAD1D',
      '#7B904B',
      '#E09B74',
      '#F5BF6D',
      '#DFDFDF',
      '#BBBBBB',
      '#008080',
      '#708090',
      '#8FBC8F',
      '#00FF7F',
      '#20B2AA',
      '#7B68EE',
      '#4B0082',
      '#800000',
      '#E08',
      '#D2691E',
      '#FA8072',
      '#DB7093',
      '#FFC0CB',
      '#F4A460',
      '#FFFF00',
      '#EEE8AA',
      '#FFDEAD',
      '#FFF8DC',
   ]
   let colorIndex = 0

   const changePieChartFilter = type => {
      let total = 0
      selectType.current = type
      const chartData = []
      if (type === 'employee') {
         project.MemberDetails.forEach(member => {
            total += member.logged
            colorIndex++
            chartData.push({
               title: member.Name,
               value: formatNumber(member.logged),
               color: colorsChart[colorIndex],
            })
         })
         setPieChartFilter(chartData)
         setTotalValue(total)
      }
      if (type === 'projectRole') {
         const roleCounts = {}

         project.MemberDetails.forEach(member => {
            let assignment = member.AssignmentForPeriod
            if (assignment && assignment.ProjectRole) {
               const role = assignment.ProjectRole
               colorIndex++
               total += member.logged
               if (!roleCounts[role]) {
                  roleCounts[role] = {
                     title: role,
                     value: 0,
                     color: colorsChart[colorIndex],
                  }
               }
               roleCounts[role].value += member.logged
            }
         })

         const projectMembers = Object.values(roleCounts)
         setPieChartFilter(projectMembers)
         setTotalValue(total)
      }
   }

   useEffect(() => {
      changePieChartFilter(selectType.current)
   }, [selectedPeriod, project])

   const parseTimeData = (timePeriods, project, format) => {
      let billable = []
      let nonBillable = []

      timePeriods.forEach(period => {
         let periodBillable = 0
         let periodNonBillable = 0

         project.MemberDetails.forEach(member => {
            member.tasks.forEach(task => {
               const taskTime = moment(task.Date, 'YYYY-MM-DD').format(format)
               if (taskTime === period) {
                  if (task.Billable) {
                     periodBillable += totalHours(task.ReportedHours)
                  } else {
                     periodNonBillable += totalHours(task.ReportedHours)
                  }
               }
            })
         })

         billable.push(periodBillable)
         nonBillable.push(periodNonBillable)
      })

      return { billable, nonBillable }
   }
   useEffect(() => {
      const months = getMonthsBetween(selectedPeriod.startDate, selectedPeriod.endDate)

      const startDate = new Date(selectedPeriod.startDate)
      const endDate = new Date(selectedPeriod.endDate)

      const formatTimePeriodsMapper = {
         quarters: {
            format: 'YYYY [Q]Q',
            timePeriods: getQuartersBetween(startDate, endDate),
         },
         yearly: {
            format: 'YYYY',
            timePeriods: getYearsBetween(selectedPeriod.startDate, selectedPeriod.endDate),
         },
         months: {
            format: 'YYYY-MM',
            timePeriods: months.monthCodes,
            data: months.monthNames,
         },
      }

      const { format, timePeriods, data } = formatTimePeriodsMapper[barChartValueType]

      const { billable, nonBillable } = parseTimeData(timePeriods, project, format)

      setDataHours({ billable, nonBillable })
      setData(data ?? timePeriods)
   }, [selectedPeriod, project, barChartValueType])

   return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
         <Box>
            <Box>
               <Box sx={{ display: 'flex', maxWidth: 500, alignItems: 'center', p: ' 0 25px' }}>
                  <Typography
                     variant="body1"
                     sx={{
                        fontSize: 17,
                        fontWeight: 500,
                        color: '#454545',
                     }}
                  >
                     {t('spentHoursBy')}
                  </Typography>
                  <FormControl sx={{ width: 100, mb: '5px', ml: 1 }} variant="standard" fullWidth>
                     <Select
                        onChange={e => changePieChartFilter(e.target.value)}
                        disableUnderline
                        MenuProps={{
                           PaperProps: {
                              sx: {
                                 padding: '0 4px',
                                 boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                 bgcolor: '#fff',
                                 width: 150,
                                 p: 1,
                                 '& .MuiMenuItem-root': {
                                    padding: 1,
                                    m: '5px 0',
                                    color: '#6D6D6D',
                                 },
                                 '& .MuiMenuItem-root:hover': {
                                    backgroundColor: '#EDF6FA',
                                    color: '#6D6D6D',
                                    borderRadius: 1,
                                 },
                                 '& .Mui-selected': {
                                    backgroundColor: '#008DC6',
                                    color: '#fff',
                                    borderRadius: 1,
                                 },
                              },
                           },
                        }}
                        IconComponent={ExpandMoreIcon}
                        sx={{
                           mt: '9px !important',
                        }}
                        defaultValue={'employee'}
                        inputProps={{
                           name: 'filter',
                           id: 'uncontrolled-native',
                        }}
                     >
                        <MenuItem value={'employee'}>{t('selectEmployee')}</MenuItem>
                        <MenuItem value={'projectRole'}>{t('selectProjectRole')}</MenuItem>
                     </Select>
                  </FormControl>
               </Box>
            </Box>
            <Box sx={{ maxWidth: 500, display: 'flex', mt: 1, position: 'relative' }}>
               <PieChart
                  style={{
                     fontFamily: '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                     fontSize: '8px',
                     alignSelf: 'center',
                  }}
                  lineWidth={30}
                  radius={35}
                  data={pieChartFilter}
               />
               {project.MemberDetails.length > 0 ? (
                  <Box
                     sx={{
                        position: 'absolute',
                        bottom: '143px',
                        left: '78px',
                        width: '95px',
                     }}
                  >
                     <Typography
                        sx={{
                           textAlign: 'center',
                           fontSize: 33,
                           fontWeight: 500,
                           color: '#454545',
                           height: '37px',
                        }}
                     >
                        {formatNumber(totalValue)}
                     </Typography>
                     <Typography sx={{ textAlign: 'center', color: '#9E9E9E' }}>
                        {t('hours')}
                     </Typography>
                  </Box>
               ) : (
                  <Box sx={{ position: 'absolute', bottom: '50%', right: '50%' }}>
                     <Typography>{t('noInformation')}</Typography>
                  </Box>
               )}

               <Box
                  className="employeesChart"
                  sx={{ overflowY: 'scroll', height: '346px', width: '500px' }}
               >
                  {pieChartFilter.map(employee => {
                     return isFetching ? (
                        <Skeleton sx={{ mb: 1 }} />
                     ) : (
                        <Box
                           key={employee.title}
                           sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              width: 230,
                           }}
                        >
                           <Box sx={{ display: 'flex', alignItems: 'center', m: '6px 0' }}>
                              <ColorSquare color={employee.color} />
                              <Typography
                                 sx={{ fontSize: '13px', fontWeight: 600, lineHeight: '20px' }}
                              >
                                 {employee.title}
                              </Typography>
                           </Box>
                           <Typography sx={{ color: employee.value > 0 ? '#9F9F9F' : '#E58C9C' }}>
                              {formatNumber(employee.value)}
                           </Typography>
                        </Box>
                     )
                  })}
               </Box>
            </Box>
         </Box>

         <Box
            sx={{ display: 'flex', alignItems: 'end', flexFlow: 'column', pr: '23px', pt: '9.5px' }}
         >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
               <Typography
                  sx={{
                     width: 335,
                     fontSize: 17,
                     fontWeight: 500,
                     color: '#454545',
                  }}
               >
                  {t('teamStatisticByHours')}
               </Typography>
               <FormControl variant="standard" sx={{ width: 100 }}>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                     {t('timeline')}
                  </InputLabel>
                  <Select
                     disableUnderline
                     onChange={e => setBarChartValueType(e.target.value)}
                     MenuProps={{
                        PaperProps: {
                           sx: {
                              padding: '0 4px',
                              boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                              bgcolor: '#fff',
                              width: 150,
                              p: 1,
                              '& .MuiMenuItem-root': {
                                 padding: 1,
                                 m: '5px 0',
                                 color: '#6D6D6D',
                              },
                              '& .MuiMenuItem-root:hover': {
                                 backgroundColor: '#EDF6FA',
                                 color: '#6D6D6D',
                                 borderRadius: 1,
                              },
                              '& .Mui-selected': {
                                 backgroundColor: '#008DC6',
                                 color: '#fff',
                                 borderRadius: 1,
                              },
                           },
                        },
                     }}
                     IconComponent={ExpandMoreIcon}
                     sx={{
                        mt: '9px !important',
                     }}
                     defaultValue={'months'}
                     inputProps={{
                        name: 'filter',
                        id: 'uncontrolled-native',
                     }}
                  >
                     <MenuItem value={'months'}>{t('selectMonths')}</MenuItem>
                     {getMonthsBetween(selectedPeriod.startDate, selectedPeriod.endDate).monthCodes
                        .length > 4 && (
                        <MenuItem value={'quarters'}>{t('selectQuarters')}</MenuItem>
                     )}
                     {getYearsBetween(selectedPeriod.startDate, selectedPeriod.endDate).length >
                        2 && <MenuItem value={'yearly'}>{t('selectYearly')}</MenuItem>}
                  </Select>
               </FormControl>
            </Box>
            <Box>
               <Chart
                  options={state.options}
                  series={state.series}
                  type="bar"
                  height={360}
                  width={650}
               />
            </Box>
         </Box>
      </Box>
   )
}

export default withTranslation()(ProjectOverviewCharts)
