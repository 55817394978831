import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Button, Modal, TextField } from '@mui/material'
import { withTranslation } from 'react-i18next'

function getModalStyle() {
   return {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
   }
}

const useStyles = makeStyles(theme => ({
   paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
   },
   body: {
      marginBottom: '20px',
   },
   buttonsPosition: {
      float: 'right',
   },
}))

const SkillGroupModal = ({
   skillGroup,
   openModal,
   toggleModal,
   editSkillGroupName,
   createEditSkillGroup,
   t,
}) => {
   const classes = useStyles()
   const [modalStyle] = React.useState(getModalStyle)
   const [error, setError] = useState(false)

   const handleSubmit = () => {
      if (skillGroup.Name === '') {
         setError(true)
         return
      }
      createEditSkillGroup()
   }
   return (
      <Modal
         open={openModal}
         onClose={() => {
            setError(false)
            toggleModal()
         }}
         aria-labelledby="simple-modal-title"
         aria-describedby="simple-modal-description"
      >
         <div style={modalStyle} className={classes.paper}>
            <div className={classes.body}>
               <h5 id="simple-modal-title">
                  {skillGroup._id ? t('editModalSkillGroupTitle') : t('createModalSkillGroupTitle')}
               </h5>
               <TextField
                  id="standard-basic"
                  variant={'outlined'}
                  placeholder={t('namePlaceholder')}
                  value={skillGroup.Name}
                  onInput={e => {
                     editSkillGroupName(e.target.value)
                     if (e.target.value !== '') {
                        setError(false)
                     } else {
                        setError(true)
                     }
                  }}
                  error={error}
                  label={error ? t('createModalSkillGroupError') : t('namePlaceholder')}
                  style={{ width: '100%' }}
               />
            </div>
            <div className={classes.buttonsPosition}>
               <Button
                  onClick={handleSubmit}
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={error}
               >
                  {t('saveButtonTitle')}
               </Button>
               <Button
                  onClick={() => {
                     setError(false)
                     toggleModal()
                  }}
                  sx={{ color: 'black' }}
               >
                  {t('cancelButtonTitle')}
               </Button>
            </div>
         </div>
      </Modal>
   )
}

export default withTranslation()(SkillGroupModal)
