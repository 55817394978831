import Paper from '@mui/material/Paper'
import withStyles from '@mui/styles/withStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Alert from '@mui/material/Alert'
import React, { useEffect, useCallback } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-dropdown/style.css'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import '../css/vacation.css'
import { fetchUsers } from '../redux/actions/actions'
import themeConfig from '../theme'
import { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import en from 'date-fns/locale/en-US'
import bg from 'date-fns/locale/bg'
import { withTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
registerLocale('sv', sv)
registerLocale('en', en)
registerLocale('bg', bg)

const { styles } = themeConfig()

const MyEmployments = props => {
   const { userInfo, fetchUsers, t } = props

   const loadData = useCallback(async () => {
      await fetchUsers(userInfo)
   }, [userInfo, fetchUsers])

   useEffect(() => {
      loadData()
   }, [loadData])

   const getCurrentUser = () => {
      return props.users.find(u => u.EmployeeID === props.userInfo.EmployeeID)
   }

   if (props.users == null || props.users.length < 1) {
      return <Typography>{t('loadingEmploymentData')}</Typography>
   }

   return (
      <React.Fragment>
         <Paper className="contentPaper" style={{ maxWidth: 1200, boxShadow: 'none' }}>
            <div className={'contentWrapper'}>
               <div style={{ padding: 10 }}>
                  <Typography variant="h5" style={{ padding: 10, fontSize: '22px' }}>
                     {t('EmploymentHistory')}
                  </Typography>
                  <div style={{ padding: 5 }}>
                     {!getCurrentUser() ||
                     !getCurrentUser().Employments ||
                     getCurrentUser().Employments.length < 1 ? (
                        <React.Fragment>
                           <Alert severity={'error'}>{t('missingEmploymentText')}</Alert>
                        </React.Fragment>
                     ) : (
                        <React.Fragment>
                           <TableContainer
                              className="employmentTable"
                              component={Paper}
                              style={{ maxHeight: 500 }}
                           >
                              <Table size="small">
                                 <TableHead>
                                    <TableRow>
                                       <TableCell align="center">{t('From')}</TableCell>
                                       <TableCell align="center">{t('To')}</TableCell>
                                       <TableCell align="center">{t('Type')}</TableCell>
                                       <TableCell align="center">{t('Worktitle')}</TableCell>
                                       <TableCell align="center">{t('workPercent')}</TableCell>
                                       <TableCell align="center">{t('VacationDays')}</TableCell>
                                       <TableCell></TableCell>
                                    </TableRow>
                                 </TableHead>

                                 <TableBody>
                                    {getCurrentUser().Employments.map((Employment, index) => (
                                       <React.Fragment key={index}>
                                          <TableRow key={'row_' + index}>
                                             <TableCell align="center">
                                                {Employment.StartDate != null ? (
                                                   <Moment
                                                      date={Employment.StartDate}
                                                      format="YYYY-MM-DD"
                                                   />
                                                ) : (
                                                   ''
                                                )}
                                             </TableCell>
                                             <TableCell align="center">
                                                {Employment.EndDate != null ? (
                                                   <Moment
                                                      date={Employment.EndDate}
                                                      format="YYYY-MM-DD"
                                                   />
                                                ) : (
                                                   ''
                                                )}
                                             </TableCell>
                                             <TableCell align="center">
                                                {t(Employment.Type)}
                                             </TableCell>
                                             <TableCell align="center">
                                                {Employment.Title}
                                             </TableCell>
                                             <TableCell align="center">
                                                {Employment.WorkTimePercent}
                                             </TableCell>
                                             <TableCell align="center">
                                                {Employment.VacationDays}
                                             </TableCell>
                                          </TableRow>
                                       </React.Fragment>
                                    ))}
                                 </TableBody>
                              </Table>
                           </TableContainer>
                        </React.Fragment>
                     )}
                  </div>
               </div>
            </div>
         </Paper>
      </React.Fragment>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
      loggedIn: state.loggedIn,
      users: state.users,
   }
}

export default withStyles(styles)(
   withTranslation()(connect(mapStateToProps, { fetchUsers })(MyEmployments))
)
