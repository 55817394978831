import {
   Typography,
   Accordion,
   AccordionSummary,
   AccordionDetails,
   FormControl,
   FormGroup,
   FormControlLabel,
   Checkbox,
   IconButton,
   Box,
   DialogTitle,
   Divider,
   Paper,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { withTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'

const useStyles = makeStyles({
   filterBox: {
      padding: 10,
      position: 'absolute',
      right: 0,
      top: 0,
      height: '100%',
      maxWidth: '300px',
      width: '100%',
      overflowY: 'scroll',
      overflowX: 'hidden',
   },
   closeIcon: {
      outline: 'none !important',
   },
   expandedSummary: {
      color: '#fff',
      backgroundColor: '#0083bb',
      borderRadius: '4px 4px 0px 0px',
   },
   accordion: {
      boxShadow: 'none',
      '&::before': {
         backgroundColor: 'transparent',
      },
   },
})

const Filters = ({
   t,
   reference,
   filterArray,
   setIsFilterVisible,
   teamOptions,
   projectOptions,
   setFilterArray,
}) => {
   const classes = useStyles()

   const handleAddOrRemoveFilterToLocalStorage = option => {
      let newAppliedFilters
      if (!localStorage.getItem('hrAppliedFilters')) {
         newAppliedFilters = [option]
      } else {
         let jsonLocalStorageArray = JSON.parse(localStorage.getItem('hrAppliedFilters')).overview
         if (jsonLocalStorageArray !== undefined) {
            newAppliedFilters = jsonLocalStorageArray.find(
               arrayOption => arrayOption.id === option.id
            )
               ? jsonLocalStorageArray.filter(arrayOption => arrayOption.id !== option.id)
               : [...jsonLocalStorageArray, option]
         } else newAppliedFilters = [option]
      }
      const newFilterObject =
         localStorage.getItem('hrAppliedFilters') &&
         !Array.isArray(JSON.parse(localStorage.getItem('hrAppliedFilters')))
            ? JSON.parse(localStorage.getItem('hrAppliedFilters'))
            : {}
      if (newAppliedFilters.length > 0) {
         newFilterObject.overview = newAppliedFilters
         localStorage.setItem('hrAppliedFilters', JSON.stringify(newFilterObject))
      } else {
         delete newFilterObject.overview
         if (Object.keys(newFilterObject).length)
            localStorage.setItem('hrAppliedFilters', JSON.stringify(newFilterObject))
         else localStorage.removeItem('hrAppliedFilters')
      }
   }

   const handleCheckbox = option => {
      handleAddOrRemoveFilterToLocalStorage(option)
      setFilterArray(
         filterArray.find(initialOption => initialOption.id === option.id)
            ? filterArray.filter(initialOption => initialOption.id !== option.id)
            : [...filterArray, option]
      )
   }

   return (
      <Paper className={classes.filterBox} square={true} ref={reference}>
         <DialogTitle
            style={{
               display: 'flex',
               justifyContent: 'space-between',
               alignItems: 'center',
               padding: '8px 4px 16px 16px',
            }}>
            <Typography style={{ fontSize: '24px' }}>{t('filters')}</Typography>
            <IconButton
               className={classes.closeIcon}
               onClick={() => setIsFilterVisible(false)}
               size="large">
               <CloseIcon />
            </IconButton>
         </DialogTitle>
         <Accordion className={classes.accordion}>
            <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               classes={{ expanded: classes.expandedSummary }}
            >
               <Typography>
                  {t('filterBy')}{' '}
                  <b>
                     {t('team').toUpperCase()}
                     {`${
                        filterArray.filter(filter => filter.hasOwnProperty('TeamLead')).length !== 0
                           ? ` (${
                                filterArray.filter(filter => filter.hasOwnProperty('TeamLead'))
                                   .length
                             })`
                           : ''
                     }`}
                  </b>
               </Typography>
            </AccordionSummary>
            <AccordionDetails>
               <Box display="flex" flexDirection="column">
                  {teamOptions
                     .filter(
                        option => option.label !== t('defaultTeam') || option.members.length > 0
                     )
                     .map(option => (
                        <FormControl key={option.id} component="fieldset">
                           <FormGroup aria-label="position">
                              <FormControlLabel
                                 value={option}
                                 control={
                                    <Checkbox
                                       key={option.label}
                                       id={option.id}
                                       color="default"
                                       checked={filterArray
                                          .map(initialOption => initialOption.id)
                                          .includes(option.id)}
                                       onChange={() => {
                                          handleCheckbox(option)
                                       }}
                                    />
                                 }
                                 label={option.label}
                                 labelPlacement="end"
                              />
                           </FormGroup>
                        </FormControl>
                     ))}
               </Box>
            </AccordionDetails>
         </Accordion>
         <Divider style={{ background: '#E0E0E0' }} />
         <Accordion className={classes.accordion}>
            <AccordionSummary
               expandIcon={<ExpandMoreIcon />}
               classes={{ expanded: classes.expandedSummary }}
            >
               <Typography>
                  {t('filterBy')}{' '}
                  <b>
                     {t('project').toUpperCase()}{' '}
                     {`${
                        filterArray.filter(filter => filter.hasOwnProperty('ProjectManager'))
                           .length !== 0
                           ? ` (${
                                filterArray.filter(filter =>
                                   filter.hasOwnProperty('ProjectManager')
                                ).length
                             })`
                           : ''
                     }`}
                  </b>
               </Typography>
            </AccordionSummary>
            <AccordionDetails>
               <Box display="flex" flexDirection="column">
                  {projectOptions.map(option => (
                     <FormControl key={option.id} component="fieldset">
                        <FormGroup aria-label="position">
                           <FormControlLabel
                              value={option}
                              control={
                                 <Checkbox
                                    id={option.id}
                                    key={option.label}
                                    color="default"
                                    checked={filterArray
                                       .map(initialOption => initialOption.id)
                                       .includes(option.id)}
                                    onChange={() => {
                                       handleCheckbox(option)
                                    }}
                                 />
                              }
                              label={option.label}
                              labelPlacement="end"
                           ></FormControlLabel>
                        </FormGroup>
                     </FormControl>
                  ))}
               </Box>
            </AccordionDetails>
         </Accordion>
      </Paper>
   );
}

export default withTranslation()(Filters)
