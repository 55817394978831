import React from 'react';
import clsx from 'clsx';
import { Redirect } from "react-router-dom";
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { logout } from "../redux/actions/actions";
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faLongArrowAltRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import absenceCodes from '../enums/absenceCode';

const styles = theme => ({
    container: {
        marginTop: "5px",
        padding: "5px",
        fontColor: "deeppink",
        borderRadius: "5px",
        cursor: "pointer",
        textAlign: "right",
    },
})

const UserAbsenceDialog = ({...props}) => {
    const { user, absences, open, date, handleClose, t } = props;

    const [absenceID, setAbsenceID] = React.useState(null);

    const getColour = (absence) => {
        return Object.values(absenceCodes).filter((absence) => !props.inactiveAbsences.includes(absence.code)).filter(absence=>absence.approvalRequired).map(absence=>absence.code).includes(absence.AbsenceType)
            ? 'lightskyblue'
            : Object.values(absenceCodes).filter((absence) => !props.inactiveAbsences.includes(absence.code)).filter(absence=>absence.affectsSalary).map(absence=>absence.code).includes(absence.AbsenceType)
                ? 'hotpink'
                : 'lemonchiffon';
    }

    if (!open) return null;

    if (absenceID !== null) return <Redirect to={absenceID}/>

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{user} {date}</DialogTitle>
                <DialogContent>
                    {absences.map(abs=>
                        <div key={`${abs._id}`} className={clsx(props.classes.container)} onClick={()=>setAbsenceID(`/absences/${abs._id}`)}
                                style={{backgroundColor:`${getColour(abs)}`}}>
                            <Typography>
                                {t(abs.AbsenceType)} {moment(abs.StartDate).format('YY-MM-DD HH:mm')} {abs.EndDate ? `~ ${moment(abs.EndDate).format('YY-MM-DD HH:mm')}` : ''}
                            </Typography>
                            <Typography>
                                {abs.ApproximateDays && !abs.EndDate ? `${t("prelBack")} ${moment(abs.StartDate).add(abs.ApproximateDays, 'd').format("D/M")}` : '' }
                            </Typography>
                            <Typography>
                                {abs.PublicComment}
                            </Typography>
                            <Typography>
                                {abs.PrivateComment}
                            </Typography>
                            <FontAwesomeIcon icon={faLongArrowAltRight} size='1x'/> <FontAwesomeIcon icon={faEdit} size='1x'/>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' size='small' variant='contained' onClick={handleClose}><FontAwesomeIcon icon={faTimes}/></Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        userInfo: state.userInfo,
        users: state.users,
        inactiveAbsences: state.inactiveAbsences,
    };
};

export default withStyles(styles)(withTranslation()(connect(
    mapStateToProps,
    {logout}
  )(UserAbsenceDialog)))
