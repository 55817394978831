import React, { useCallback, useEffect, useState } from 'react'
import {
   createSkill,
   updateSkill,
   deleteSkill,
   createSkillGroup,
   deleteSkillGroup,
   fetchCustomerForAdmin,
   getSkillGroupsForCustomer,
   updateSkillGroup,
} from '../Api'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Paper from '@mui/material/Paper'
import { Snackbar } from '@mui/material'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import SkillGroupModal from '../components/skills/modals/SkillGroupModal'
import DisplaySkills from '../components/skills/DisplaySkills'
import DeleteModal from '../components/skills/modals/DeleteModal'
import SkillModal from '../components/skills/modals/SkillModal'
import RotateLoader from 'react-spinners/RotateLoader'

const SkillManagement = ({ ...props }) => {
   const initialSkillGroupState = {
      _id: null,
      Name: '',
      Skills: [],
   }
   const initialSkillState = {
      _id: null,
      Name: '',
      GroupID: null,
      GroupName: '',
   }
   const { userInfo, t } = props
   const [skillGroups, setSkillGroups] = useState([])
   const [openGroupModal, setOpenGroupModal] = useState(false)
   const [openSkillModal, setOpenSkillModal] = useState(false)
   const [openDeleteModal, setOpenDeleteModal] = useState(false)
   const [skillGroup, setSkillGroup] = useState(initialSkillGroupState)
   const [skill, setSkill] = useState(initialSkillState)
   const [dataFetching, setDataFetching] = useState(true)
   const [refetch, setRefetch] = useState(false)
   const [snackbarState, setSnackbarState] = useState({
      open: false,
      message: '',
      severity: 'info',
   })

   useEffect(() => {
      async function fetchSkillGroups() {
         const result = await fetchCustomerForAdmin(userInfo)
         let customer = result.data
         if (customer._id) {
            const result = await getSkillGroupsForCustomer(customer._id, userInfo)
            setSkillGroups(result.data)
            dataFetching && setDataFetching(false)
         }
         if (snackbarState.message !== '') {
            toggleSnackBar()
         }
      }

      fetchSkillGroups()
   }, [userInfo, refetch])

   const editSkillGroupName = nameValue => {
      setSkillGroup({ ...skillGroup, Name: nameValue })
   }

   const handleCreateEditSkillGroup = skillGroup => {
      setSkillGroup(skillGroup)
      toggleGroupModal()
   }

   const createEditSkillGroup = () => {
      toggleGroupModal()
      if (skillGroup._id) {
         updateSkillGroup(skillGroup, userInfo)
            .then(() => {
               fillSuccessfulSnackbar(t('successToastEditSkillGroup'))
               setRefetch(prevState => !prevState)
            })
            .catch(() => showErrorSnackBar(t('errorToastEditSkillGroup')))
      } else {
         createSkillGroup(skillGroup.Name, userInfo)
            .then(() => {
               fillSuccessfulSnackbar(t('successToastCreateSkillGroup'))
               setRefetch(prevState => !prevState)
            })
            .catch(() => showErrorSnackBar(t('errorToastCreateSkillGroup')))
      }
   }

   const handleDeleteSkillGroup = skillGroup => {
      setSkillGroup(skillGroup)
      toggleDeleteModal()
   }

   const deleteExistingSkillGroup = () => {
      toggleDeleteModal()
      deleteSkillGroup(skillGroup._id, userInfo)
         .then(() => {
            fillSuccessfulSnackbar(t('successToastDeleteSkillGroup'))
            setRefetch(prevState => !prevState)
         })
         .catch(() => showErrorSnackBar(t('errorToastDeleteSkillGroup')))
   }
   const toggleGroupModal = useCallback(() => {
      setOpenGroupModal(prevState => {
         if (prevState) setSkillGroup(initialSkillGroupState)
         return !prevState
      })
   }, [])

   const toggleDeleteModal = useCallback(() => {
      setOpenDeleteModal(prevState => {
         if (prevState) {
            setSkillGroup(initialSkillGroupState)
            setSkill(initialSkillState)
         }
         return !prevState
      })
   }, [])

   const editSkillName = nameValue => {
      setSkill({ ...skill, Name: nameValue })
   }

   const editSkillProperty = (property, newValue) => {
      setSkill(prevState => {
         return { ...prevState, [property]: newValue }
      })
   }

   const handleAddSkill = (GroupID, GroupName) => {
      editSkillProperty('GroupName', GroupName)
      editSkillProperty('GroupID', GroupID)
      toggleSkillModal()
   }
   const handleEditSkill = skill => {
      skillGroups.filter(group =>
         group.Skills.map(mappedSkill => {
            if (JSON.stringify(mappedSkill._id) === JSON.stringify(skill._id)) {
               skill = { ...skill, GroupName: group.Name, GroupID: group._id }
               return skill
            }
         })
      )
      setSkill(skill)
      toggleSkillModal()
   }

   const handleDeleteSkill = (skillForDeletion, groupID) => {
      skillForDeletion.groupID = groupID
      setSkill(skillForDeletion)
      toggleDeleteModal()
   }

   const createEditSkill = () => {
      toggleSkillModal()
      if (skill._id) {
         updateSkill(skill, userInfo)
            .then(() => {
               fillSuccessfulSnackbar(t('successToastEditSkill'))
               setRefetch(prevState => !prevState)
            })
            .catch(() => showErrorSnackBar(t('errorToastCreateSkill')))
      } else {
         createSkill(skill.Name, skill.GroupID, skill.GroupName, userInfo)
            .then(() => {
               fillSuccessfulSnackbar(t('successToastCreateSkill'))
               setRefetch(prevState => !prevState)
            })
            .catch(() => showErrorSnackBar(t('errorToastCreateSkill')))
      }
   }

   const deleteExistingSkill = () => {
      toggleDeleteModal()
      deleteSkill(skill._id, userInfo)
         .then(() => {
            fillSuccessfulSnackbar(t('successToastDeleteSkill'))
            setRefetch(prevState => !prevState)
         })
         .catch(() => showErrorSnackBar(t('errorToastDeleteSkill')))
   }

   const toggleSkillModal = () => {
      setOpenSkillModal(prevState => {
         if (prevState) setSkill(initialSkillState)
         return !prevState
      })
   }

   const fillSuccessfulSnackbar = message => {
      setSnackbarState({ open: false, message, severity: 'success' })
   }

   const showErrorSnackBar = message => {
      setSnackbarState({ open: true, message, severity: 'error' })
   }

   const toggleSnackBar = () => {
      if (snackbarState.open) {
         setSnackbarState({ open: false, message: '', severity: 'info' })
      } else {
         setSnackbarState({ ...snackbarState, open: true })
      }
   }

   return (
      <>
         <Paper className="contentPaper" style={{ padding: 20, maxWidth: 640, boxShadow: 'none' }}>
            <Button
               size="small"
               variant="contained"
               color="primary"
               onClick={() => toggleGroupModal()}
            >
               {t('newSkillGroupButtonTitle')}
            </Button>
            {dataFetching ? (
               <RotateLoader color="#4cbb17" loading={true} size={16} margin={8} />
            ) : skillGroups.length ? (
               <DisplaySkills
                  skillGroups={skillGroups}
                  handleCreateEditSkillGroup={handleCreateEditSkillGroup}
                  handleDeleteSkillGroup={handleDeleteSkillGroup}
                  handleAddSkill={handleAddSkill}
                  handleEditSkill={handleEditSkill}
                  handleDeleteSkill={handleDeleteSkill}
               />
            ) : (
               <h5 className={'mt-2'}>{t('noSkillGroupsFound')}</h5>
            )}
         </Paper>

         <Snackbar open={snackbarState.open} autoHideDuration={3000} onClose={toggleSnackBar}>
            <Alert onClose={toggleSnackBar} severity={snackbarState.severity}>
               {snackbarState.message}
            </Alert>
         </Snackbar>
         <SkillGroupModal
            skillGroup={skillGroup}
            openModal={openGroupModal}
            editSkillGroupName={editSkillGroupName}
            toggleModal={toggleGroupModal}
            createEditSkillGroup={createEditSkillGroup}
         />
         <SkillModal
            skill={skill}
            openModal={openSkillModal}
            editSkillName={editSkillName}
            editSkillProperty={editSkillProperty}
            toggleModal={toggleSkillModal}
            createEditSkill={createEditSkill}
            skillGroups={skillGroups}
         />
         <DeleteModal
            skillGroup={skillGroup}
            openModal={openDeleteModal}
            toggleModal={toggleDeleteModal}
            deleteSkillGroup={deleteExistingSkillGroup}
            deleteSkill={deleteExistingSkill}
         />
      </>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}
export default withTranslation()(connect(mapStateToProps)(SkillManagement))
