import axios from 'axios'
import { BACKEND_URL } from './util/dependencies'
import { absencePeriodDateFormatter } from './util/helpers'
import log from './util/log'

export const fetchDevices = async userInfo => {
   return await axios({
      method: 'GET',
      url: BACKEND_URL + '/user/getAccessData',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const switchCustomer = async (CustomerID, userInfo) => {
   return await axios({
      method: 'PUT',
      url: BACKEND_URL + '/superAdmin/switchCustomer',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: { CustomerID: CustomerID },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const newCustomer = async (userInfo, data) => {
   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/superAdmin/newCustomer',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const addSuperAdmin = async (email, userInfo) => {
   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/superAdmin',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: { Email: email },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const removeSuperAdmin = async (email, userInfo) => {
   return await axios({
      method: 'DELETE',
      url: BACKEND_URL + '/superAdmin',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: { Email: email },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const fetchSuperAdmins = async userInfo => {
   const response = await fetch(BACKEND_URL + '/superAdmin', {
      headers: { Authorization: userInfo.googleAccessToken },
   })

   const json = await response.json()
   return json
}

export const fetchCustomers = async userInfo => {
   const response = await fetch(BACKEND_URL + '/adminCustomer/all', {
      headers: { Authorization: userInfo.googleAccessToken },
   })

   const json = await response.json()
   return json
}

/**
 * Fetch the users Customer data.
 * @param {} userInfo
 */
export const fetchCustomerForAdmin = async userInfo => {
   const response = await fetch(BACKEND_URL + '/adminCustomer', {
      headers: { Authorization: userInfo.googleAccessToken },
   })

   const json = await response.json()
   return json
}

export const updateCustomer = async (data, userInfo) => {
   await axios({
      method: 'PUT',
      url: BACKEND_URL + '/adminCustomer',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

/**
 * Fetch the users Customer data.
 * @param {} userInfo
 */
export const fetchCustomerForAuthUser = async userInfo => {
   const response = await fetch(BACKEND_URL + '/customer/forAuthUser', {
      headers: { Authorization: userInfo.googleAccessToken },
   })

   const json = await response.json()
   return json
}

/**
 * Fetch notifs for user
 * @param {} params
 */
export const fetchNotifs = async params => {
   const response = await fetch(BACKEND_URL + '/notif/?EmployeeID=' + params.userInfo.EmployeeID, {
      headers: { Authorization: params.userInfo.googleAccessToken },
   }).catch(error => {
      log.error('Error occured in fetchNotifs: ' + error)
   })

   const json = await response.json()
   return json
}

/**
 * Fetch all absence requests for employee.
 * @param {} params
 */
export const fetchRequestsForEmployee = async userInfo => {
   const response = await fetch(
      BACKEND_URL + '/absenceRequest/?EmployeeID=' + userInfo.EmployeeID,
      {
         headers: { Authorization: userInfo.googleAccessToken },
      }
   )

   const json = await response.json()
   return json
}

/**
 * Fetch all absence requests with status REQUESTED
 * @param {} params
 */
export const fetchAllAbsenceRequests = async (userInfo, status) => {
   let statusParam = ''
   if (status != null) {
      statusParam = 'Status=' + status
   }
   const response = await fetch(BACKEND_URL + '/absenceRequest/?' + statusParam, {
      headers: { Authorization: userInfo.googleAccessToken },
   })
   const json = await response.json()
   return json
}

export const fetchAbsenceRequestsPeriodically = async (userInfo, status, selectedWeeks) => {
   const { startDate, endDate } = absencePeriodDateFormatter(selectedWeeks)
   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + `/absenceRequest`,
      params: {
         StartDate: startDate,
         EndDate: endDate,
         Status: status,
         EmployeeID: userInfo.admin ? null : userInfo.EmployeeID,
      },
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })

   return response.data
}

export const fetchAbsenceRequestById = async (userInfo, id) => {
   const response = await fetch(BACKEND_URL + '/absenceRequest/byId?id=' + id, {
      headers: { Authorization: userInfo.googleAccessToken },
   })
   const json = await response.json()
   return json
}

/**
 * Fetch all absence requests that have not yet been passed
 * @param {} params
 */
export const fetchFutureAbsenceRequests = async (userInfo, date) => {
   let timeParam = ''
   if (date != null) {
      timeParam = 'PeriodEnd=' + date
   }
   const response = await fetch(BACKEND_URL + '/absenceRequest/?' + timeParam, {
      headers: { Authorization: userInfo.googleAccessToken },
   })
   const json = await response.json()
   return json
}

/**
 * Fetch all absences for user
 * @param {} params
 */
export const fetchAllAbsencesForUser = async userInfo => {
   let statusParam = 'EmployeeID=' + userInfo.EmployeeID
   if (userInfo.admin) statusParam += '&IncludePrivate=1'
   const response = await fetch(BACKEND_URL + '/absence/?' + statusParam, {
      headers: { Authorization: userInfo.googleAccessToken },
   })
   const json = await response.json()
   return json
}

export const fetchAllAbsencesForCustomer = async (userInfo, includeLocked) => {
   const response = await fetch(
      BACKEND_URL + 'absence/getAbsence' + (includeLocked ? '/?IncludeLocked=1' : ''),
      {
         headers: { Authorization: userInfo.googleAccesstoken },
      }
   )
   const json = await response.json()
   return json
}

/**
 * Fetch all absences belonging to a recurrency container
 * @param {} params
 */
export const fetchAllAbsencesForRecurrence = async (userInfo, recurrenceInfo) => {
   let param = 'RecurrenceID=' + recurrenceInfo.RecurrenceID + '&IncludePrivate=1'
   const response = await fetch(BACKEND_URL + '/absence/?' + param, {
      headers: { Authorization: userInfo.googleAccessToken },
   })
   const json = await response.json()
   return json
}

export const fetchAbsenceByRequestId = async (userInfo, id) => {
   const response = await fetch(BACKEND_URL + '/absence/byId?id=' + id, {
      headers: { Authorization: userInfo.googleAccessToken },
   })
   const json = await response.json()
   return json
}

/**
 * Fetch all absence recurrency containers belonging to a user
 * @param {} params
 */
export const fetchAllRecurrencesForEmployee = async userInfo => {
   let statusParam = 'EmployeeID=' + userInfo.EmployeeID
   const response = await fetch(BACKEND_URL + '/absenceRecurrence/?' + statusParam, {
      headers: { Authorization: userInfo.googleAccessToken },
   })
   const json = await response.json()
   return json
}

/**
 * Delete recurrency container and all absences belonging to it
 * @param {} params
 */
export const deleteRecurrence = async (recurrenceID, absences, userInfo) => {
   let promises = []

   const response = await fetch(BACKEND_URL + '/absenceRecurrence/?RecurrenceID=' + recurrenceID, {
      method: 'DELETE',
      headers: { Authorization: userInfo.googleAccessToken },
   })
   absences.forEach(abs => promises.push(deleteAbsence(userInfo, abs._id)))

   promises.forEach(async promise => await promise.then(response => log.debug(response)))
   const json = await response.json()

   return json
}

/**
 * Update recurrency container (not absences)
 * @param {} params
 */
export const updateRecurrence = async (recurrenceData, userInfo) => {
   const response = await fetch(
      BACKEND_URL + '/absenceRecurrence/?RecurrenceID=' + recurrenceData.RecurrenceID,
      {
         method: 'PUT',
         headers: {
            Authorization: userInfo.googleAccessToken,
            'Content-Type': 'application/json',
         },
         body: JSON.stringify(recurrenceData),
      }
   )
   const json = await response.json()
   return json
}

/**
 * Fetch all users.
 * @param {} params
 */
export const fetchAllUsers = async params => {
   const response = await fetch(BACKEND_URL + '/user/getUsers', {
      method: 'GET',
      cache: 'no-cache',
      headers: {
         'Content-Type': 'application/json',
         Authorization: params.userInfo.googleAccessToken,
      },
   })

   if (!response.ok) {
      return { success: false, status: response.status }
   }
   const json = await response.json()
   return json
}

/**
 * Fetch all users for overview.
 * @param {} params
 */
export const fetchUserOverview = async userInfo => {
   const response = await fetch(BACKEND_URL + '/user/getUserOverview', {
      method: 'GET',
      cache: 'no-cache',
      headers: {
         'Content-Type': 'application/json',
         Authorization: userInfo.googleAccessToken,
      },
   })

   if (!response.ok) {
      return { success: false, status: response.status }
   }
   const json = await response.json()
   return json
}

/**
 * Fetch users with active employments for overview.
 * @param {} params
 */
export const fetchActiveUserOverview = async (userInfo, selectedWeeks) => {
   let periodStart
   let periodEnd

   if (selectedWeeks) {
      const { startDate, endDate } = absencePeriodDateFormatter(selectedWeeks)
      periodStart = startDate
      periodEnd = endDate
   }

   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + '/user/getActiveUserOverview',
      params: {
         StartDate: periodStart,
         EndDate: periodEnd,
      },
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })

   return response.data
}
export const fetchInactiveUsers = async userInfo => {
   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + '/user/getInactiveUsers',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })

   return response.data
}

export const deleteRequest = async (_id, userInfo, CommentByAdmin, AbsenceList) => {
   /*const response = await fetch(
    API_URL + '/absenceRequest/?_id=' + _id,
    { method: 'DELETE', headers: { 'Authorization': userInfo.googleAccessToken } }
  );
  const json = await response.json();
  return json;
  */
   const data = {
      _id: _id,
      CommentByAdmin: CommentByAdmin,
      AbsenceList,
   }
   await axios({
      method: 'DELETE',
      url: BACKEND_URL + '/absenceRequest',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug(response)
   })
}

export const updateRequest = async (userInfo, data) => {
   await axios({
      method: 'PUT',
      url: BACKEND_URL + '/absenceRequest/update',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug(response)
   })
}

export const approveRequest = async (userInfo, data) => {
   await axios({
      method: 'PUT',
      url: BACKEND_URL + '/absenceRequest/approve',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug(response)
   })
}

export const updateUserEmployments = async (EmployeeID, employments, userInfo) => {
   const data = {
      EmployeeID: EmployeeID,
      Employments: employments,
   }

   await axios({
      method: 'PUT',
      url: BACKEND_URL + '/user/updateEmployments',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
   })
}

export const updateUserAcceptedReports = async (EmployeeID, reports, userInfo) => {
   const data = {
      EmployeeID: EmployeeID,
      AcceptedReports: reports,
   }

   await axios({
      method: 'PUT',
      url: BACKEND_URL + '/user/updateAcceptedReports',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
   })
}

/**
 *
 * @param {} params
 */
export const fetchSalaryReports = async params => {
   const response = await fetch(BACKEND_URL + '/salaryReports/', {
      method: 'GET',
      cache: 'no-cache',
      headers: {
         'Content-Type': 'application/json',
         Authorization: params.userInfo.googleAccessToken,
      },
   })
   const json = await response.json()
   return json
}

/**
 *
 * @param {} params
 */
export const fetchCustomerTenantId = async loginPath => {
   const response = await fetch(BACKEND_URL + '/auth/customerTenantIdGIP?loginPath=' + loginPath, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
         'Content-Type': 'application/json',
      },
   })
   const json = await response.json()
   return json
}

/**
 *
 * @param {} params
 */
export const newSalaryReport = async (userInfo, data) => {
   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/salaryReports/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const updateSalaryReport = async (
   userInfo,
   id,
   spreadsheetId,
   spreadsheetUrl,
   driveFolderId,
   folderUrl
) => {
   const data = { id, spreadsheetId, spreadsheetUrl, driveFolderId, folderUrl }
   return await axios({
      method: 'PUT',
      url: BACKEND_URL + '/salaryReports/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

/**
 *
 * @param {} params
 */
export const deleteSalaryReport = async (userInfo, id, spreadsheetId) => {
   return await axios({
      method: 'DELETE',
      url: BACKEND_URL + '/salaryReports/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: { id: id, spreadsheetId: spreadsheetId },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const updateUserSettings = async (EmployeeID, settings, userInfo) => {
   const data = {
      EmployeeID: EmployeeID,
      Settings: settings,
   }
   await axios({
      method: 'PUT',
      url: BACKEND_URL + '/user/updateSettings',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
   })
}

export const updateUserLanguage = async (EmployeeID, language, userInfo) => {
   const data = {
      EmployeeID: EmployeeID,
      Language: language,
   }
   await axios({
      method: 'PUT',
      url: BACKEND_URL + '/user/updateLanguage',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
   })
}

export const updateUserInfo = async (data, userInfo) => {
   await axios({
      method: 'PUT',
      url: BACKEND_URL + '/user/updateUser',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

/**
 *
 * @param {} params
 */
export const lockSalaryReport = async (userInfo, id) => {
   return await axios({
      method: 'PUT',
      url: BACKEND_URL + '/salaryReports/lock',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: { id: id },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const postNewAbsence = async (userInfo, input) => {
   input.Locked = input.Locked ? input.Locked : false

   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/absence/putAbsence',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: input,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const splitAbsence = async (userInfo, input) => {
   const data = {
      id: input.id,
      splitDate: input.splitDate,
      pickupDate: input.pickupDate,
   }

   return await axios({
      method: 'PUT',
      url: BACKEND_URL + '/absence/splitAbsence',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const fetchSalaryReport = async (userInfo, id) => {
   return await axios({
      method: 'GET',
      url: BACKEND_URL + '/salaryReports/?id=' + id,
      headers: { Authorization: userInfo.googleAccessToken },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const fetchBankHolidays = async userInfo => {
   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + '/bankHoliday',
      headers: { Authorization: userInfo.googleAccessToken },
   })

   return response.data.data
}

export const fetchGenericData = async (userInfo, model) => {
   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + '/generic/' + model + '/',
      headers: { Authorization: userInfo.googleAccessToken },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
   return response.data.data
}

export const deleteAbsence = async (userInfo, id) => {
   return await axios({
      method: 'DELETE',
      url: BACKEND_URL + '/absence/deleteAbsence',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: { id: id },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}
export const fetchGenericDataById = async (userInfo, model, id) => {
   return await axios({
      method: 'GET',
      url: BACKEND_URL + '/generic/' + model + '/' + id,
      headers: { Authorization: userInfo.googleAccessToken },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}
export const deleteGenericDataById = async (userInfo, model, id) => {
   return await axios({
      method: 'DELETE',
      url: BACKEND_URL + '/generic/' + model + '/' + id,
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}
export const updateGenericDataById = async (userInfo, model, id, data) => {
   return await axios({
      method: 'PUT',
      url: BACKEND_URL + '/generic/' + model + '/' + id,
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}
export const createGenericData = async (userInfo, model, data) => {
   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/generic/' + model + '/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const updateAbsence = async (userInfo, absenceInfo) => {
   return await axios({
      method: 'PUT',
      url: BACKEND_URL + '/absence/updateAbsence',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: absenceInfo,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const updateBackendWithToken = async (email, type, token) => {
   return await axios({
      method: 'PUT',
      url: BACKEND_URL + '/auth/setAccessToken',
      headers: { 'Cache-Control': 'no-store' },
      data: {
         email: email,
         token: token,
         type: type,
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const getTeamsForCustomer = async (CustomerID, userInfo) => {
   const response = await fetch(BACKEND_URL + '/team/?customerID=' + CustomerID, {
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   const json = await response.json()
   return json
}

export const getTeamsForTL = async (employeeID, userInfo) => {
   const response = await fetch(BACKEND_URL + '/team/getTeamsByTeamLead/?userID=' + employeeID, {
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   const json = await response.json()
   return json
}

export const getTeamsForOverview = async userInfo => {
   const response = await fetch(BACKEND_URL + '/team/teamView', {
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   const json = await response.json()
   return json
}

export const createTeam = async (name, MemberIDs, TeamLead, userInfo) => {
   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/team/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: {
         name: name,
         MemberIDs: MemberIDs,
         TeamLead: TeamLead,
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const updateTeam = async (data, userInfo) => {
   return await axios({
      method: 'PUT',
      url: BACKEND_URL + '/team/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: {
         TeamID: data.id,
         Name: data.Name,
         MemberIDs: data.MemberIDs,
         TeamLead: data.TeamLead,
         PastMemberIDs: data.PastMemberIDs,
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const deleteTeam = async (id, userInfo) => {
   return await axios({
      method: 'DELETE',
      url: BACKEND_URL + '/team/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: {
         TeamID: id,
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const getProjectsForCustomer = async (CustomerID, userInfo) => {
   const response = await fetch(BACKEND_URL + '/project/?customerID=' + CustomerID, {
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   const json = await response.json()
   return json
}
export const getProjectsForReport = async (params, userInfo) => {
   return await axios({
      method: 'GET',
      url: BACKEND_URL + '/project/getProjectsForReportOverview',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      params: params,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const getProjectsForUser = async (userID, userInfo) => {
   const response = await fetch(BACKEND_URL + '/project/?userID=' + userID, {
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   const json = await response.json()
   return json
}

export const getProjectByID = async (projectID, userInfo) => {
   const response = await fetch(BACKEND_URL + '/project/?projectID=' + projectID, {
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   const json = await response.json()
   return json
}

export const getProjectsForPM = async (employeeID, userInfo) => {
   const response = await fetch(BACKEND_URL + '/project/getProjectsByPM/?userID=' + employeeID, {
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   const json = await response.json()
   return json
}

export const getProjectsForOverview = async userInfo => {
   const response = await fetch(BACKEND_URL + '/project/projectView', {
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   const json = await response.json()
   return json
}

export const createProject = async (data, userInfo) => {
   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/project/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const updateProject = async (data, userInfo) => {
   return await axios({
      method: 'PUT',
      url: BACKEND_URL + '/project/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const deleteProject = async (id, userInfo) => {
   return await axios({
      method: 'DELETE',
      url: BACKEND_URL + '/project/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: {
         ProjectID: id,
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const getSkillGroupsForCustomer = async (CustomerID, userInfo) => {
   const response = await fetch(BACKEND_URL + '/skillGroup/?customerID=' + CustomerID, {
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   const json = await response.json()
   return json
}

export const createSkillGroup = async (name, userInfo) => {
   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/skillGroup/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: {
         name: name,
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const updateSkillGroup = async (data, userInfo) => {
   return await axios({
      method: 'PUT',
      url: BACKEND_URL + '/skillGroup/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: {
         skillGroupID: data._id,
         Name: data.Name,
         Skills: data.Skills,
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const deleteSkillGroup = async (_id, userInfo) => {
   return await axios({
      method: 'DELETE',
      url: BACKEND_URL + '/skillGroup/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: {
         SkillGroupID: _id,
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const getSkillsForCustomer = async (CustomerID, userInfo) => {
   const response = await fetch(BACKEND_URL + '/skill/?customerID=' + CustomerID, {
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   const json = await response.json()
   return json
}

export const createSkill = async (name, GroupID, GroupName, userInfo) => {
   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/skill/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: {
         name: name,
         GroupID,
         GroupName,
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const updateSkill = async (data, userInfo) => {
   return await axios({
      method: 'PUT',
      url: BACKEND_URL + '/skill/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: {
         SkillID: data._id,
         Name: data.Name,
         GroupID: data.GroupID,
         GroupName: data.GroupName,
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const deleteSkill = async (_id, userInfo) => {
   return await axios({
      method: 'DELETE',
      url: BACKEND_URL + '/skill/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: {
         SkillID: _id,
      },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response.data
   })
}

export const getAllTeamLeads = async (CustomerID, userInfo) => {
   const response = await fetch(BACKEND_URL + '/team/getAllTeamLeads/?customerID=' + CustomerID, {
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   const json = await response.json()
   return json
}

export const getAllProjectManagers = async (CustomerID, userInfo) => {
   const response = await fetch(
      BACKEND_URL + '/project/getAllProjectManagers/?customerID=' + CustomerID,
      {
         headers: {
            Authorization: userInfo.googleAccessToken,
            'Cache-Control': 'no-store',
         },
      }
   )
   const json = await response.json()
   return json
}

export const newProjectReport = async (userInfo, data) => {
   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/projectReports/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const fetchProjectReports = async userInfo => {
   const response = await fetch(BACKEND_URL + '/projectReports/', {
      method: 'GET',
      cache: 'no-cache',
      headers: {
         'Content-Type': 'application/json',
         Authorization: userInfo.googleAccessToken,
      },
   })
   const json = await response.json()
   return json
}

export const deleteProjectReport = async (userInfo, spreadsheetId) => {
   return await axios({
      method: 'DELETE',
      url: BACKEND_URL + '/projectReports/',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: { spreadsheetId: spreadsheetId },
   }).then(response => {
      log.debug('AXIOS response' + JSON.stringify(response))
      return response
   })
}

export const deleteAbsenceRecurrence = async (recurrenceID, userInfo) => {
   const response = await axios(BACKEND_URL + '/absenceRecurrence/?RecurrenceID=' + recurrenceID, {
      method: 'DELETE',
      headers: { Authorization: userInfo.googleAccessToken },
   })
   const json = await response.json()

   return json
}

export const createAbsenceRequestByAdmin = async (data, userInfo) => {
   const response = await axios({
      method: 'POST',
      url: BACKEND_URL + '/absenceRequest/createdByAdmin',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   })
   return response
}

export const createAbsenceRecurrenceByAdmin = async (data, userInfo) => {
   const response = await axios({
      method: 'POST',
      url: BACKEND_URL + '/absenceRecurrence/createdByAdmin',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   })
   return response
}

export const getUserByEmail = async (email, userInfo) => {
   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + `/user/getUserByEmail?email=${email}`,
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   return response
}

export const getTimesheet = async (params, userInfo) => {
   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + `/timesheet`,
      params: params,
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   return response
}
export const getTimesheetForEmployee = async (params, userInfo) => {
   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + '/timesheet/getForEmployee',
      params: params,
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   return response
}
export const getActiveUserTimesheetManagement = async (params, userInfo) => {
   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + `/timesheet/management`,
      params: params,
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   return response
}

export const createExcel = async (params, userInfo) => {
   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + `/timesheet/excel`,
      params: params,
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   return response
}
export const createExcelYearly = async (params, userInfo) => {
   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + `/timesheet/excelYearly`,
      params: params,
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   return response
}
export const createProjectReportExcel = async (params, userInfo) => {
   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + `/project/projectReportExcel`,
      params: params,
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })
   return response
}

export const createTimesheet = async (userInfo, data) => {
   const response = await axios({
      method: 'POST',
      url: BACKEND_URL + '/timesheet',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   })

   return response
}

export const updateTimesheet = async (userInfo, data) => {
   const response = await axios({
      method: 'PUT',
      url: BACKEND_URL + '/timesheet',
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   })

   return response
}

export const getAbsencesForGivenPeriod = async (userInfo, selectedWeeks) => {
   const { startDate, endDate } = absencePeriodDateFormatter(selectedWeeks)

   const response = await axios({
      method: 'GET',
      url: BACKEND_URL + '/absence/getAbsence',
      params: {
         IncludeLocked: true,
         StartDate: startDate,
         EndDate: endDate,
      },
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
   })

   return response.data
}

export const fetchAbsencesForAbsenceList = async (inclusive = false, userInfo) => {
   const response = await fetch(
      BACKEND_URL + '/absence/getAbsence' + (inclusive ? '?IncludeLocked=true' : ''),
      {
         method: 'GET',
         cache: 'no-cache',
         headers: {
            'Content-Type': 'application/json',
            Authorization: userInfo.googleAccessToken,
         },
      }
   )

   return response.json()
}

export const deleteAbsenceFromAbsenceList = async (idToDelete, userInfo) => {
   return await axios({
      url: BACKEND_URL + '/absence/deleteAbsence',
      method: 'DELETE',
      cache: 'no-cache',
      headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-store',
         Authorization: userInfo.googleAccessToken,
      },
      data: {
         id: idToDelete,
      },
   })
}

export const updateAbsenceForAbsenceList = async (data, userInfo) => {
   return await axios({
      url: BACKEND_URL + '/absence/updateAbsence',
      method: 'PUT',
      cache: 'no-cache',
      headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-store',
         Authorization: userInfo.googleAccessToken,
      },
      data: data,
   })
}

export const deleteUserFromUserList = async (idToDelete, userInfo) => {
   return await axios({
      url: BACKEND_URL + '/user/deleteUser',
      method: 'DELETE',
      cache: 'no-cache',
      headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-store',
         Authorization: userInfo.googleAccessToken,
      },
      data: { id: idToDelete },
   })
}

export const createUpdateBasicUserInfo = async (data, userInfo, performUpdate) => {
   return await axios({
      method: performUpdate ? 'PUT' : 'POST',
      url: BACKEND_URL + (performUpdate ? '/user/updateUser' : '/user/putUser'),
      headers: {
         Authorization: userInfo.googleAccessToken,
         'Cache-Control': 'no-store',
      },
      data: data,
   })
}

export const getUserByEmailAddress = async userInfo => {
   const response = await fetch(BACKEND_URL + '/user/getUserByEmail/?email=' + userInfo.email, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
         'Content-Type': 'application/json',
         Authorization: userInfo.googleAccessToken,
      },
   })

   return response.json()
}

export const postReportedAbsence = async (data, userInfo) => {
   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/absenceRecurrence',
      cache: 'no-cache',
      headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-store',
         Authorization: userInfo.googleAccessToken,
      },
      data: data,
   })
}

export const newRecurrenceNotification = async (recurrenceID, userInfo) => {
   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/absenceRecurrence/notifyNewRecurrence',
      cache: 'no-cache',
      headers: {
         'Content-Type': 'application/json',
         'Cache-Control': 'no-store',
         Authorization: userInfo.googleAccessToken,
      },
      data: {
         RecurrenceID: recurrenceID,
         EmployeeID: userInfo.EmployeeID,
      },
   })
}

export const postAbsenceRequest = async (data, userInfo) => {
   return await axios({
      method: 'POST',
      url: BACKEND_URL + '/absenceRequest/',
      headers: { Authorization: userInfo.googleAccessToken, 'Cache-Control': 'no-store' },
      data: data,
   })
}

export const getProjectAvailabilityByUserID = async (userInfo, userID) => {
   const response = await fetch(
      BACKEND_URL + '/user/getUserProjectAvailability/?userID=' + userID,
      {
         method: 'GET',
         cache: 'no-cache',
         headers: {
            'Content-Type': 'application/json',
            Authorization: userInfo.googleAccessToken,
         },
      }
   )

   return response.json()
}
