import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import themeConfig from '../theme'
import { connect } from 'react-redux'
import { Box, Divider, Paper, Snackbar } from '@mui/material'
import TimesheetHeader from './components/TimesheetHeader'
import TimesheetBody from './components/TimesheetBody'
import {
   createTimesheet,
   getProjectsForUser,
   getTimesheet,
   getUserByEmail,
   updateTimesheet,
} from '../Api'
import Alert from '@mui/material/Alert'
import RotateLoader from 'react-spinners/RotateLoader'
import DiscardChangesModal from '../components/modals/DiscardChangesModal'
import moment from 'moment/min/moment-with-locales'
import { getAllDatesOfAWeek } from '../util/helpers'
import '../css/timesheet.css'

const { styles } = themeConfig()
const convertUserProjects = (chosenDateWeek, userId, userProjects, userTimesheet) => {
   const userProjectMap = new Map()
   userProjects.forEach(userProject => {
      const memberDetails = userProject.MemberDetails.find(member => member._id === userId)
      if (
         (!userProject.EndDate ||
            moment(userProject.EndDate).isSameOrAfter(chosenDateWeek.weekDates[0], 'day')) &&
         memberDetails &&
         memberDetails.Assignments.length
      ) {
         const foundAssignment = memberDetails.Assignments.find(
            assignment =>
               assignment.StartDate &&
               moment(assignment.StartDate).isSameOrBefore(
                  chosenDateWeek.weekDates[chosenDateWeek.weekDates.length - 1],
                  'day'
               ) &&
               (!assignment.EndDate ||
                  moment(assignment.EndDate).isSameOrAfter(chosenDateWeek.weekDates[0], 'day'))
         )
         if (foundAssignment) {
            userProjectMap.set(userProject._id, {
               Name: userProject.Name,
               WorkPercent: foundAssignment.WorkPercent ? foundAssignment.WorkPercent : 0,
               ReportedDates: [],
               ProjectID: userProject._id,
            })
         }
      }
   })

   return Array.from(userProjectMap.values())
}

const combineUserAndDBProjects = (dbProjects, userProjects) => {
   const combinedProjects = []
   dbProjects.forEach(dbProject => {
      const findInUserProject = userProjects.find(
         userProject => userProject?.ProjectID === dbProject?.ProjectID
      )
      if (findInUserProject) {
         combinedProjects.push({ ...dbProject, Name: findInUserProject.Name })
      } else {
         combinedProjects.push(dbProject)
      }
   })

   userProjects.forEach(userProject => {
      const isInCombinedProjects = combinedProjects.find(
         combinedProject => userProject?.ProjectID === combinedProject?.ProjectID
      )
      if (!isInCombinedProjects) {
         combinedProjects.push(userProject)
      }
   })
   return combinedProjects
}

const Timesheet = ({ userInfo, t }) => {
   const [selectedView, setSelectedView] = useState(0) // 0 = daily, 1 = weekly
   const [timesheetData, setTimesheetData] = useState(null)
   const [lockedDates, setLockedDates] = useState([])
   const [chosenDate, setChosenDate] = useState(new Date(Date.now()))
   const [chosenDateWeek, setChosenDateWeek] = useState(null)
   const [disabledDatesByAssignments, setDisabledDatesByAssignments] = useState({})
   const [snackbarState, setSnackbarState] = React.useState({
      open: false,
      message: '',
      severity: 'info',
      duration: 3000,
   })
   const [isDataFetching, setIsDataFetching] = useState(true)
   const [isUnsavedChanges, setIsUnsavedChanges] = useState(false)
   const [openDiscardChangesModal, setOpenDiscardChangesModal] = useState(false)
   const [callbackFunction, setCallbackFunction] = useState(() => {})
   const [userProjects, setUserProjects] = useState([])
   const [userId, setUserId] = useState(null)
   const [expandedRows, setExpandedRows] = useState({})
   const [lastChangedDateRequiresUpdate, setLastChangedDateRequiresUpdate] = useState(true)

   useEffect(() => {
      setChosenDateWeek(getAllDatesOfAWeek(new Date(chosenDate), 1))
   }, [chosenDate])

   useEffect(() => {
      if (!userId || !userProjects || !chosenDateWeek) {
         return
      }

      const unavailable = {}
      const defaultEndDate = new Date(chosenDateWeek.weekDates[chosenDateWeek.weekDates.length - 1])

      userProjects.forEach(p => {
         const assignments =
            (p.MemberDetails || []).find(md => md._id === userId)?.Assignments || []
         const proj = {}
         chosenDateWeek.weekDates.forEach(wd => {
            const foundAssignment = assignments.find(a =>
               moment(new Date(wd)).isBetween(
                  new Date(a.StartDate),
                  new Date(a.EndDate || defaultEndDate),
                  'days',
                  '[]'
               )
            )
            if (!foundAssignment) {
               proj[new Date(wd).getDate()] = true
            }
         })
         unavailable[p._id] = proj
      })

      setDisabledDatesByAssignments(unavailable)
   }, [chosenDateWeek, userProjects, userId])

   useEffect(() => {
      if (!lastChangedDateRequiresUpdate) {
         return
      }

      if (!!chosenDateWeek) {
         setIsDataFetching(true)

         async function getData() {
            try {
               const { _id: userId } = await getUserByEmail(userInfo.email, userInfo).then(
                  response => response.data.data
               )
               const { data: projectsForUser } = await getProjectsForUser(userId, userInfo)
               setUserId(userId)
               setUserProjects(projectsForUser || [])

               const firstDateOfTheChosenWeek = new Date(chosenDateWeek.weekDates[0])
               const lastDateOfTheChosenWeek = new Date(
                  chosenDateWeek.weekDates[chosenDateWeek.weekDates.length - 1]
               )

               const userTimesheet = await getTimesheet(
                  {
                     WeekStart: chosenDateWeek.weekDates[0],
                     WeekEnd: chosenDateWeek.weekDates[chosenDateWeek.weekDates.length - 1],
                  },
                  userInfo
               ).then(response => {
                  setLockedDates(response.data.data.lockedDates)
                  return response.data.data.timesheetData
               })

               // remove deleted projects from timesheet visualization start
               const existingProjectIDs = projectsForUser.map(p => p._id)
               const projectIDsToExclude = []
               ;(userTimesheet || []).forEach(td => {
                  ;(td.Projects || []).forEach(p => {
                     if (!existingProjectIDs.includes(p.ProjectID)) {
                        projectIDsToExclude.push(p.ProjectID)
                     }
                  })
               })
               ;(userTimesheet || []).forEach(td => {
                  if (td.Projects) {
                     td.Projects = td.Projects.filter(
                        p => !projectIDsToExclude.includes(p.ProjectID)
                     )
                  }
               })
               // remove deleted projects from timesheet visualization end

               if (userTimesheet.length) {
                  const convertedProjects = convertUserProjects(
                     chosenDateWeek,
                     userId,
                     projectsForUser,
                     userTimesheet
                  )
                  const combinedProjects = combineUserAndDBProjects(
                     userTimesheet[0].Projects,
                     convertedProjects
                  )
                  userTimesheet[0].Projects = combinedProjects
                  setTimesheetData(userTimesheet[0])
               } else {
                  const convertedProjects = convertUserProjects(
                     chosenDateWeek,
                     userId,
                     projectsForUser,
                     []
                  )

                  setTimesheetData({
                     WeekStart: firstDateOfTheChosenWeek,
                     WeekEnd: lastDateOfTheChosenWeek,
                     WeekNumber: chosenDateWeek.weekNumber[0],
                     Projects: convertedProjects,
                  })
               }
               setIsDataFetching(false)
            } catch {
               setIsDataFetching(false)
               showErrorToast(t('timesheetErrorGetDataMessage'))
            }
         }

         getData()
      }
   }, [chosenDateWeek])

   const showSuccessToast = (message, duration = 3000) => {
      setSnackbarState({ open: true, message, severity: 'success', duration })
   }

   const showErrorToast = (message, duration = 3000) => {
      setSnackbarState({ open: true, message, severity: 'error', duration })
   }

   const closeSnackBar = () =>
      setSnackbarState({ open: false, message: '', severity: 'info', duration: 3000 })

   const handleSave = () => {
      const copy = JSON.parse(JSON.stringify(timesheetData))
      let isSubtaskCreated = false
      copy.Projects.forEach(proj => {
         proj.ReportedDates.forEach(rd => {
            if (rd._id && rd._id.includes('-')) {
               delete rd._id
               isSubtaskCreated = true
            }
         })
      })
      if (copy._id) {
         updateTimesheet(userInfo, copy)
            .then(() => {
               showSuccessToast(t('timesheetSuccessUpdateMessage'))
               setIsUnsavedChanges(false)
               if (isSubtaskCreated) {
                  setChosenDate(new Date(chosenDate))
               }
            })
            .catch(() => showErrorToast(t('timesheetErrorUpdateMessage')))
      } else {
         createTimesheet(userInfo, copy)
            .then(response => {
               setTimesheetData({ ...copy, _id: response.data._id })
               setIsUnsavedChanges(false)
               showSuccessToast(t('timesheetSuccessCreateMessage'))
               if (isSubtaskCreated) {
                  setChosenDateWeek(JSON.parse(JSON.stringify(chosenDateWeek)))
               }
            })
            .catch(() => showErrorToast(t('timesheetErrorCreateMessage')))
      }
   }

   const updateTimesheetData = newTimesheetData => {
      if (!isUnsavedChanges && newTimesheetData) {
         setIsUnsavedChanges(true)
      } else if (!newTimesheetData) {
         setIsUnsavedChanges(false)
      }
      setTimesheetData(newTimesheetData)
   }

   const toggleDiscardModal = () => setOpenDiscardChangesModal(prevState => !prevState)

   const changeDate = newDate => {
      const today = new Date(Date.now())
      ;['setHours', 'setMinutes', 'setSeconds', 'setMilliseconds'].forEach(m => today[m](0))
      const dateToCheck = new Date(newDate)
      ;['setHours', 'setMinutes', 'setSeconds', 'setMilliseconds'].forEach(m => dateToCheck[m](0))

      const dateToChange = dateToCheck.getTime() > today.getTime() ? new Date(Date.now()) : newDate

      const firstDayOfWeek = new Date(chosenDateWeek.weekDates[0])
      ;['setHours', 'setMinutes', 'setSeconds', 'setMilliseconds'].forEach(m =>
         firstDayOfWeek[m](0)
      )
      const lastDayOfWeek = new Date(chosenDateWeek.weekDates[6])
      ;['setHours', 'setMinutes', 'setSeconds', 'setMilliseconds'].forEach(m => lastDayOfWeek[m](0))

      const selectedDate = new Date(chosenDate)
      ;['setHours', 'setMinutes', 'setSeconds', 'setMilliseconds'].forEach(m => selectedDate[m](0))

      const dateToChangeExact = new Date(dateToChange)
      ;['setHours', 'setMinutes', 'setSeconds', 'setMilliseconds'].forEach(m =>
         dateToChangeExact[m](0)
      )

      const shouldUpdate =
         isUnsavedChanges ||
         dateToChangeExact.getTime() < firstDayOfWeek.getTime() ||
         dateToChangeExact.getTime() > lastDayOfWeek.getTime() ||
         dateToChangeExact.getTime() === selectedDate.getTime()

      if (isUnsavedChanges) {
         setCallbackFunction(() => () => {
            if (shouldUpdate) {
               setIsDataFetching(true)
            }
            setLastChangedDateRequiresUpdate(shouldUpdate)
            setChosenDate(dateToChange)
            if (shouldUpdate) {
               updateTimesheetData(null)
            }
         })
         toggleDiscardModal()
      } else {
         if (shouldUpdate) {
            setIsDataFetching(true)
         }
         setLastChangedDateRequiresUpdate(shouldUpdate)
         setChosenDate(dateToChange)
         if (shouldUpdate) {
            updateTimesheetData(null)
         }
      }
   }

   return (
      <>
         <Paper
            style={{ minHeight: '300px', maxWidth: '2000px', margin: 'auto', boxShadow: 'none' }}
         >
            {!!chosenDateWeek && (
               <>
                  <Box p={1}>
                     <TimesheetHeader
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        chosenDate={chosenDate}
                        chosenDateWeek={chosenDateWeek}
                        handleSave={handleSave}
                        isUnsavedChanges={isUnsavedChanges}
                        changeDate={changeDate}
                     />
                  </Box>
                  <Divider style={{ background: 'rgba(224, 224, 224, 1)' }} />
                  {!!isDataFetching ? (
                     <Box
                        style={{
                           marginLeft: 'auto',
                           marginRight: 'auto',
                           marginTop: 120,
                           width: 0,
                        }}
                     >
                        <RotateLoader color="#4cbb17" loading={true} size={16} margin={20} />
                     </Box>
                  ) : (
                     !!timesheetData && (
                        <TimesheetBody
                           key={chosenDate.toString()}
                           disabledDatesByAssignments={disabledDatesByAssignments}
                           changeDate={changeDate}
                           selectedView={selectedView}
                           userProjects={userProjects}
                           userId={userId}
                           expandedRows={expandedRows}
                           setExpandedRows={setExpandedRows}
                           chosenDate={chosenDate}
                           chosenDateWeek={chosenDateWeek}
                           userInfo={userInfo}
                           timesheetData={timesheetData}
                           updateTimesheetData={updateTimesheetData}
                           lockedDates={lockedDates}
                           showErrorToast={showErrorToast}
                        />
                     )
                  )}
               </>
            )}
            <Snackbar
               open={snackbarState.open}
               autoHideDuration={snackbarState.duration}
               onClose={closeSnackBar}
            >
               <Alert onClose={closeSnackBar} severity={snackbarState.severity}>
                  {snackbarState.message}
               </Alert>
            </Snackbar>
         </Paper>
         <DiscardChangesModal
            openModal={openDiscardChangesModal}
            toggleModal={toggleDiscardModal}
            discardChanges={callbackFunction}
         />
      </>
   )
}

const mapStateToProps = state => {
   return {
      userInfo: state.userInfo,
   }
}
export default withStyles(styles)(withTranslation()(connect(mapStateToProps)(Timesheet)))
