import React, { useEffect, useState, useMemo } from 'react'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import moment from 'moment/min/moment-with-locales'
import TimesheetRow from './TimesheetRow'
import { convertTimesheetHoursAndMinutes } from '../../util/helpers'
import { withTranslation } from 'react-i18next'
import { fetchBankHolidays } from '../../Api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye, faCircle } from '@fortawesome/free-solid-svg-icons'

import { v4 as uuidv4 } from 'uuid'

const centerAlign = {
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
}

const TimesheetBody = ({
   disabledDatesByAssignments,
   changeDate,
   selectedView,
   userProjects,
   userId,
   expandedRows,
   setExpandedRows,
   chosenDate,
   chosenDateWeek,
   timesheetData,
   updateTimesheetData,
   lockedDates,
   t,
   userInfo,
   showErrorToast,
}) => {
   const [bankHoliday, setBankHoliday] = useState([])
   const [projectsSubtaskPermissions, setProjectsSubtaskPermissions] = useState({})

   const setProjectSubtaskPermission = (projectId = '', hasPermission = false) => {
      if (projectsSubtaskPermissions[projectId] !== hasPermission) {
         setProjectsSubtaskPermissions(prev => ({ ...prev, [projectId]: hasPermission }))
      }
   }

   const hideCreateSubtaskStyles = useMemo(() => {
      return !Object.values(projectsSubtaskPermissions).find(val => val)
   }, [projectsSubtaskPermissions])

   moment.locale(t('momentLocale'))

   useEffect(() => {
      fetchBankHolidays(userInfo)
         .then(res => {
            setBankHoliday(res)
         })
         .catch(() => console.log('Error fetching holidays.'))
   }, [userInfo])

   const getReportedHoursForDay = date => {
      let dayHours = 0
      let dayMinutes = 0

      timesheetData.Projects.forEach(project => {
         const foundAllReportedDates = project.ReportedDates.filter(reportedDate =>
            moment(reportedDate.Date).isSame(date, 'day')
         )
         foundAllReportedDates.forEach(reportedDate => {
            dayHours += Number(reportedDate.ReportedHours.split(':')[0]) || 0
            dayMinutes += Number(reportedDate.ReportedHours.split(':')[1]) || 0
         })
      })

      return convertTimesheetHoursAndMinutes(dayHours, dayMinutes)
   }

   const getReportedHoursForWeek = () => {
      let weekHours = 0
      let weekMinutes = 0

      timesheetData.Projects.forEach(project => {
         project.ReportedDates.forEach(reportedDate => {
            weekHours += Number(reportedDate.ReportedHours.split(':')[0]) || 0
            weekMinutes += Number(reportedDate.ReportedHours.split(':')[1]) || 0
         })
      })

      return convertTimesheetHoursAndMinutes(weekHours, weekMinutes)
   }

   const isToday = date => moment(date).isSame(new Date(Date.now()), 'day')
   const isSameDay = (date1, date2) => moment(new Date(date1)).isSame(new Date(date2), 'day')

   const isDayAnAbsence = date =>
      lockedDates.find(
         lockedDate =>
            moment(date).isSameOrAfter(lockedDate.StartDate, 'day') &&
            moment(date).isSameOrBefore(lockedDate.EndDate, 'day')
      )

   const isDateAHoliday = date =>
      bankHoliday.find(holiday => moment(date).isSame(holiday.Date, 'day'))

   const dayHeader = weekDate => {
      if (new Date(weekDate).getDay() === 6 || new Date(weekDate).getDay() === 0) {
         return (
            <div className="timesheet-table-head-hours">
               {`${getReportedHoursForDay(weekDate)}${t('h')}`}
               <Tooltip title={t('timesheetTooltipMessageWeekend')}>
                  <Box>
                     <FontAwesomeIcon icon={faCircle} color={'#ff7800'} style={{ marginLeft: 6 }} />
                  </Box>
               </Tooltip>
            </div>
         )
      }
      const foundHoliday = isDateAHoliday(weekDate)
      if (foundHoliday) {
         return (
            <div className="timesheet-table-head-hours">
               {`${getReportedHoursForDay(weekDate)}${t('h')}`}
               <Tooltip title={foundHoliday.Name}>
                  <Box>
                     <FontAwesomeIcon
                        icon={faBullseye}
                        color={'#e60000'}
                        style={{ marginLeft: 6 }}
                     />
                  </Box>
               </Tooltip>
            </div>
         )
      }
      if (isDayAnAbsence(weekDate)) {
         return (
            <div className="timesheet-table-head-hours">
               {`${getReportedHoursForDay(weekDate)}${t('h')}`}
               <Tooltip title={t('timesheetTooltipMessageAbsence')}>
                  <Box>
                     <FontAwesomeIcon icon={faCircle} color={'#e60000'} style={{ marginLeft: 6 }} />
                  </Box>
               </Tooltip>
            </div>
         )
      }

      return (
         <div className="timesheet-table-head-hours">
            {getReportedHoursForDay(weekDate)}
            {t('h')}
         </div>
      )
   }

   return (
      <>
         {timesheetData?.Projects.length > 0 ? (
            <>
               <Grid
                  container
                  style={{
                     borderBottom: '1px solid rgba(224, 224, 224, 1)',
                     minHeight: selectedView === 1 ? 60 : selectedView === 0 ? 52 : 60,
                  }}
               >
                  {selectedView === 1 && (
                     <Grid
                        item
                        xs={4}
                        style={{
                           display: 'flex',
                           justifyContent: 'left',
                           alignItems: 'center',
                           paddingLeft: 69,
                        }}
                     >
                        <Typography style={{ fontWeight: 700 }}>
                           {t('timesheetProjectsText')}
                        </Typography>
                     </Grid>
                  )}
                  {chosenDateWeek.weekDates.map(weekDate => {
                     const dateString = moment(weekDate).format('ddd')
                     const dateStringFirstCapital =
                        dateString.charAt(0).toUpperCase() + dateString.slice(1)
                     const isSelectable =
                        selectedView === 0 &&
                        !isSameDay(chosenDate, weekDate) &&
                        new Date().getTime() > new Date(weekDate).getTime()

                     const selectedDayStyle =
                        (selectedView === 1 && isToday(weekDate)) ||
                        (selectedView === 0 && isSameDay(chosenDate, weekDate))
                           ? {
                                backgroundColor: '#8ACCE6',
                                color: '#FFF',
                                borderBottom: '1px solid #8ACCE6',
                                marginBottom: '-1px',
                             }
                           : {}
                     const todayStyle =
                        selectedView === 0 && !isSameDay(chosenDate, weekDate) && isToday(weekDate)
                           ? { fontWeight: '500', color: '#008DC6' }
                           : isToday(weekDate)
                           ? { fontWeight: '500' }
                           : {}
                     const dailyViewStyle =
                        selectedView === 0 && !isSameDay(chosenDate, weekDate)
                           ? { borderRight: '1px solid rgba(224, 224, 224, 1)' }
                           : {}

                     let className = selectedView === 0 ? 'timesheet-table-item-view0' : ''
                     if (isSelectable) {
                        className += ' timesheet-selectable-table-item'
                     }

                     const dailyViewGridProps = selectedView === 0 ? { xs: 1.5 } : {}

                     return (
                        <Grid
                           key={new Date(weekDate).toDateString()}
                           onClick={() => isSelectable && changeDate(new Date(weekDate))}
                           style={{
                              ...selectedDayStyle,
                              ...todayStyle,
                              ...dailyViewStyle,
                              ...centerAlign,
                           }}
                           item
                           xs={1}
                           {...dailyViewGridProps}
                           className={className}
                        >
                           <div>
                              {dateStringFirstCapital}, {moment(weekDate).format('D')}
                           </div>
                           {selectedView === 0 && <div>{dayHeader(weekDate)}</div>}
                        </Grid>
                     )
                  })}
                  <Grid
                     item
                     xs={selectedView === 1 ? 1 : selectedView === 0 ? 1.5 : 1}
                     style={{
                        borderLeft:
                           selectedView === 1 ? '1px solid rgba(224, 224, 224, 1)' : 'none',
                        fontWeight: 700,
                        color: '#454545',
                        ...centerAlign,
                     }}
                     {...(selectedView === 0 ? { className: 'timesheet-table-item-view0' } : {})}
                  >
                     <div>{t('totalAmountHours')}</div>
                     {selectedView === 0 && <div>{`${getReportedHoursForWeek()}${t('h')}`}</div>}
                  </Grid>
               </Grid>
               {selectedView === 1 && (
                  <Grid
                     container
                     style={{
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        minHeight: 60,
                     }}
                  >
                     <Grid item xs={4} sx={{ alignSelf: 'center' }}>
                        <Typography sx={{ fontWeight: 700, ml: '68px' }}>
                           Total hours per day
                        </Typography>
                     </Grid>

                     {chosenDateWeek.weekDates.map(weekDate => {
                        const todayStyle = isToday(weekDate)
                           ? {
                                backgroundColor: '#8ACCE6',
                                color: '#FFF',
                                borderBottom: '1px solid #8ACCE6',
                                marginBottom: '-1px',
                                fontWeight: 500,
                             }
                           : {}
                        return (
                           <Grid
                              key={new Date(weekDate).toDateString()}
                              style={{
                                 textAlign: 'center',
                                 ...todayStyle,
                                 ...centerAlign,
                              }}
                              item
                              xs={1}
                           >
                              {dayHeader(weekDate)}
                           </Grid>
                        )
                     })}
                     <Grid
                        item
                        xs={1}
                        style={{
                           borderLeft: '1px solid rgba(224, 224, 224, 1)',
                           fontWeight: 700,
                           color: '#454545',
                           ...centerAlign,
                        }}
                     >
                        {`${getReportedHoursForWeek()}${t('h')}`}
                     </Grid>
                  </Grid>
               )}
               {timesheetData.Projects.map(project => (
                  <TimesheetRow
                     key={project.ProjectID || project._id || uuidv4()}
                     disabledDatesByAssignments={disabledDatesByAssignments}
                     selectedView={selectedView}
                     userProjects={userProjects}
                     userId={userId}
                     expandedRows={expandedRows}
                     setExpandedRows={setExpandedRows}
                     hideCreateSubtaskStyles={hideCreateSubtaskStyles}
                     setProjectSubtaskPermission={setProjectSubtaskPermission}
                     chosenDate={chosenDate}
                     project={project}
                     chosenDateWeek={chosenDateWeek}
                     timesheetData={timesheetData}
                     updateTimesheetData={updateTimesheetData}
                     lockedDates={lockedDates}
                     showErrorToast={showErrorToast}
                     getReportedHoursForDay={getReportedHoursForDay}
                  />
               ))}
               <div style={{ height: '60px' }} />
            </>
         ) : (
            <Box
               style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: 50,
                  width: 600,
               }}
            >
               {t('timesheetNoProjectsText')}
            </Box>
         )}
      </>
   )
}

export default withTranslation()(TimesheetBody)
