import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {version, date} from './version.js';

function Copyright(props) {
  
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="">
        Novarto Nordic AB
      </Link>{' '}
      {new Date().getFullYear()}
      {'. '}
      Version: {version} ({date})
      {'. '}
      <Link color="inherit" href="/privacy">
        {"Privacy and T&C"}
      </Link>
    </Typography>
  );
}

export default Copyright;